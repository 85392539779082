export const getPhoneNoZero = number => {
  if (number) {
    if (number[0] === '0') {
      return number.slice(1);
    }

    if (number.slice(0, 2) === '62') {
      return number.slice(2);
    }

    if (number.slice(0, 3) === '+62') {
      return number.slice(3);
    }
  }
};
