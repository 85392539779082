import React from 'react';
import { Button, Col, Form, FormGroup, Input, Modal, Row, Table } from 'reactstrap';

import moment from 'moment';
import 'moment/locale/id';
import { GREAT_TEAM } from '../../../../constants';
import BankService from '../../../../services/BankService';
import OrderService from '../../../../services/OrderService';
moment.locale('id');

class MoveToGreatModal extends React.Component {
  state = {
    loading: false,
    order: null,
    banks: [],
    selectedBankId: '',
    selectedAccountName: 'PT CoverSuper Indonesia Global'
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen && this.state.order === null && this.state.banks.length === 0) {
      // console.log('open', this.props);
      this.getData();
      this.getBankAccounts();
    } else if (!this.props.isOpen && this.state.order !== null) {
      // console.log('close', this.props);
      this.setState({ order: null, banks: [], selectedBankId: '' });
    }
  }

  getBankAccounts = async () => {
    try {
      const { data: banks } = await BankService.getLists({ params: { unit_id: GREAT_TEAM } });
      this.setState({ banks: banks.data });
    } catch (error) {
      console.log(error);
    }
  };

  getData = async () => {
    try {
      const { data: order } = await OrderService.findById(this.props.row.order.id);
      this.setState({ order: order });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { order, selectedBankId } = this.state;
    try {
      this.setState({ loading: true });
      const { data } = await OrderService.move(order.id, {
        bank_id: selectedBankId
      });

      if (data.status === 200) {
        this.props.toggle();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { row, toggle, isOpen } = this.props;
    const { order, banks, selectedBankId, loading } = this.state;
    return (
      <>
        {row && order ? (
          <>
            <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} size="lg">
              <Form onSubmit={this.handleSubmit} style={{ marginTop: 8 }}>
                <div className="modal-header">
                  <h5 className="modal-title" id="moveToGreatModal">
                    Pindah Pesanan Ke Tim Great ?
                  </h5>
                  <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col md="6">
                      <Table responsive borderless size="sm">
                        <tbody>
                          <tr>
                            <td width="20%">
                              <strong>No Order</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">
                              <strong>{order.order_number}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Dealmaker</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">{order.customer_service.name}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Customer</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">{order.customer.name}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Produk</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">
                              {order.details.map(item => (
                                <>
                                  {item.category.name} {item.product_name} <br />
                                </>
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col md="6">
                      <Table responsive borderless size="sm">
                        <tbody>
                          <tr>
                            <td colSpan="3">
                              <strong>Pembayaran</strong>
                            </td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Tipe Pembayaran</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">{order.payment_type}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Channel Pembayaran</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">{order.payment_channel}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Nomor Rekening</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">{order.bank.account_number}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Pemilik Rekening</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">{order.bank.account_name}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="text-center">
                        <img
                          width={'85%'}
                          src="https://coversuper.sgp1.digitaloceanspaces.com/assets/arrow-excellent-to-great.png"
                          alt="Move"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="text-center">
                        <h5 className="modal-title">Perubahan Data</h5>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Table responsive borderless size="sm">
                        <tbody>
                          <tr>
                            <td width="20%">
                              <strong>Tipe Pembayaran</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">{order.payment_type}</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>
                                Channel Pembayaran<span className="text-red">*</span>
                              </strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">
                              <FormGroup>
                                <Input
                                  required
                                  id="bank_id"
                                  placeholder="Pilih Bank"
                                  type="select"
                                  name="bank_id"
                                  value={selectedBankId}
                                  onChange={e => {
                                    this.setState({ selectedBankId: e.target.value });
                                  }}
                                >
                                  <option value="">-- Pilih Bank--</option>
                                  {banks.map((bank, key) => (
                                    <option value={bank.id} key={key}>
                                      {`${bank.provider.name}`}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col md="6">
                      <Table responsive borderless size="sm">
                        <tbody>
                          <tr>
                            <td width="20%">
                              <strong>Pemilik Rekening</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">PT CoverSuper Indonesia Global</td>
                          </tr>
                          <tr>
                            <td width="20%">
                              <strong>Dealmaker</strong>
                            </td>
                            <td width="5%">:</td>
                            <td className="text-left">Self Service</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>

                <div className="modal-footer">
                  <Button disabled={loading} color="success">
                    {loading ? (
                      <>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        &nbsp; Loading...
                      </>
                    ) : (
                      'Simpan'
                    )}
                  </Button>
                  <Button color="secondary" data-dismiss="modal" type="button" onClick={toggle}>
                    Close
                  </Button>
                </div>
              </Form>
            </Modal>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default MoveToGreatModal;
