import React from 'react'
import { Button, Navbar, NavbarBrand } from 'reactstrap'

const AffiliateNavbar = (props) => {
    return (
        <div>
            <Navbar
                // color="light"
                expand="md"
                light
                style={{ backgroundColor: '#000' }}
            >
                <NavbarBrand href="/" style={{ color: 'white', fontFamily: "Montserrat" }}>
                    {props.title}
                </NavbarBrand>

                <div style={{ textAlign: 'end', marginLeft: 'auto' }}>
                    <Button style={{
                        backgroundColor: '#000',
                        border: 'none',
                        color: 'white',
                        fontFamily: "Montserrat"
                    }}
                        href='/login'
                    >
                        {props.name}
                    </Button>
                </div>
            </Navbar>
        </div>
    )
}

export default AffiliateNavbar
