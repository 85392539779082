import React from 'react';
import { Col, FormGroup } from 'reactstrap';
import CurrencyInput from '../../../../helpers/CurrencyInput';
import * as Constants from '../../../../constants';

function PaymentAmountComponent(props) {
  if (
    props.platform === Constants.PLATFORM_REGULAR &&
    parseInt(props.payment_status_id) === parseInt(Constants.PAYMENT_STATUS_UNPAID)
  ) {
    return null;
  } else {
    return (
      <Col md="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="paymentAmount">
            Nominal Bayar
            <sup>
              <span className="text-red">*</span>
            </sup>
          </label>
          <CurrencyInput
            id="paymentAmount"
            type="text"
            className="form-control"
            name="payment_amount"
            ref={props.paymentAmountRef}
            readOnly={props.payment_status_id === props.STATUS_PAID ? true : false}
            value={
              props.payment_status_id === props.STATUS_PAID
                ? props.countGrandTotal().toString()
                : props.payment_amount.toString()
            }
            onChange={e => {
              // remove class invalid
              e.target.classList.remove('is-invalid');
              const amount = props.payment_status_id === props.STATUS_PAID ? props.countGrandTotal() : e.target.value;
              props.handleChange('payment_amount', amount);
            }}
          />
        </FormGroup>
      </Col>
    );
  }
}

export default PaymentAmountComponent;
