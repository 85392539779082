import React from 'react';
import { useEffect } from 'react';
import RefundPolicy from '../refund_policy/RefundPolicy';

function DetailPageManagement(props) {
  const slug = props.location.state.row.slug;

  useEffect(() => {}, []);

  if (slug === 'refund-policy') {
    return <RefundPolicy title={props.location.state.row.title} pageId={props.location.state.row.id} />;
  }
}

export default DetailPageManagement;
