import React, { useState, useRef } from 'react';
import { Button, Card, CardBody, Form, Input, Container, Row, Col, Label, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import { useEffect } from 'react';
import WebSettingService from '../../../../services/WebSettingService';

function WebSetting(props) {
  const [about, setAbout] = useState('');
  const [linkFacebook, setLinkFacebook] = useState('');
  const [linkTwitter, setLinkTwitter] = useState('');
  const [linkInstagram, setLinkInstagram] = useState('');
  const [linkYoutube, setLinkYoutube] = useState('');
  const [voucher, setVoucher] = useState('');
  const [salesEmail, setSalesEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const aboutRef = useRef();
  const linkFacebookRef = useRef();
  const linkTwitterRef = useRef();
  const linkInstagramRef = useRef();
  const linkYoutubeRef = useRef();
  const voucherRef = useRef();
  const salesEmailRef = useRef();

  useEffect(() => {
    get();
  }, []);

  async function get() {
    try {
      const response = await WebSettingService.get();

      if (response.status === 200) {
        const {
          about,
          link_facebook,
          link_twitter,
          link_instagram,
          link_youtube,
          voucher,
          sales_email
        } = response.data;

        setAbout(about);
        setLinkFacebook(link_facebook);
        setLinkTwitter(link_twitter);
        setLinkInstagram(link_instagram);
        setLinkYoutube(link_youtube);
        setVoucher(voucher);
        setSalesEmail(sales_email);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setInvalid({ current }) {
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  function isValid() {
    if (!about || about === '') {
      return setInvalid(aboutRef);
    }
    if (!linkFacebook || linkFacebook === '') {
      return setInvalid(linkFacebookRef);
    }
    if (!linkTwitter || linkTwitter === '') {
      return setInvalid(linkTwitterRef);
    }
    if (!linkInstagram || linkInstagram === '') {
      return setInvalid(linkInstagramRef);
    }
    if (!linkYoutube || linkYoutube === '') {
      return setInvalid(linkYoutubeRef);
    }
    if (!salesEmail || salesEmail === '') {
      return setInvalid(salesEmailRef);
    }
    if (!voucher || voucher === '') {
      return setInvalid(voucherRef);
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      const data = {
        about,
        link_facebook: linkFacebook,
        link_twitter: linkTwitter,
        link_instagram: linkInstagram,
        link_youtube: linkYoutube,
        voucher,
        sales_email: salesEmail
      };

      setLoading(true);
      try {
        const response = await WebSettingService.update(data);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: () => props.history.push('/')
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: 'Terjadi kesalahan',
          icon: 'error',
          text: error
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Website" parentName="Pengaturan Web" parentLink="/dashboard" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>About</Label>
                      <Input
                        type="textarea"
                        rows="5"
                        name="about"
                        placeholder="Coversuper adalah ..."
                        innerRef={aboutRef}
                        value={about}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setAbout(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Link Facebook</Label>
                      <Input
                        type="text"
                        value={linkFacebook}
                        placeholder="https://www.facebook.com/coversuper"
                        innerRef={linkFacebookRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setLinkFacebook(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Link Twitter</Label>
                      <Input
                        type="text"
                        value={linkTwitter}
                        placeholder="https://www.twitter.com/coversuper"
                        innerRef={linkTwitterRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setLinkTwitter(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Link Instagram</Label>
                      <Input
                        type="text"
                        value={linkInstagram}
                        placeholder="https://www.instagram.com/coversuper"
                        innerRef={linkInstagramRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setLinkInstagram(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Link Youtube</Label>
                      <Input
                        type="text"
                        value={linkYoutube}
                        placeholder="https://www.youtube.com/coversuper"
                        innerRef={linkYoutubeRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setLinkYoutube(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Voucher yang Aktif</Label>
                      <Input
                        type="text"
                        value={voucher}
                        placeholder="DISKONTERUS"
                        innerRef={voucherRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setVoucher(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Label>Email Sales</Label>
                      <Input
                        type="email"
                        value={salesEmail}
                        placeholder="sales@coversuper.com"
                        innerRef={salesEmailRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setSalesEmail(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button disabled={loading} color="primary" className="btn btn-sm" type="submit">
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default WebSetting;
