import React from 'react';
import { Card, CardBody, CardHeader, Row, Table } from 'reactstrap';

const ReportAffiliator = (props) => {
  const { data } = props;

  return (
    <Card>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">Laporan Partner</h3>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>Nama Partner</th>
              <th>Closing</th>
              <th>Omzet</th>
            </tr>
          </thead>
          <tbody>
            {
              data.closing && data.closing.map((res, i) => (
                <>
                  <tr>
                    <td>{i + 1}</td>
                    <td className="name-column">{res.name}</td>
                    <td>{res.closing}</td>
                    <td>{res.omzet}</td>
                  </tr>
                </>
              ))
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default ReportAffiliator
