import { request } from "../config";

class ProductionHistoryService {
  
  /**
   * 
   * @param {Object} params - Params for set the table
   * @param {number} params.page - Page
   * @param {number} params.limit - Data limit
   */
  static async getDatatable(params) {
    params.page = params.page > 0 ? params.page - 1 : 0

    const { data: response } = await request.get('/status-histories', { params });
    return ({
      ...response,
      page: response.page + 1 // karena defaultnya index = 0
    });
  };

}

export default ProductionHistoryService