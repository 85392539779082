import React, { useState, useEffect } from 'react';
import AffiliateService from '../../../../services/AffiliateService';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';

const UpdateAccountModal = props => {
  const [loading, setLoading] = useState(false);
  const [bank_options, setBankOptions] = useState(['BCA', 'BNI', 'BRI', 'Mandiri']);
  const [values, setValues] = useState({
    account_number: '100200100',
    bank_name: 'BCA',
    account_name: 'Affiliate User',
    balance: 0
  });

  useEffect(() => {
    setValues(props.getDataAffiliate());

    AffiliateService.getListBankAffiliate().then(res => {
      setBankOptions(res.data);
    });
  }, [props.isOpen]);

  const handleClose = () => {
    setLoading(false);
    props.toggle('accountModalOpen');
  };

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const isValid = () => {
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (isValid()) {
        setLoading(true);
        const res = await AffiliateService.updateAccount({
          bank_name: values.bank_name,
          account_name: values.account_name,
          account_number: values.account_number
        });

        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: `Informasi Akun berhasil diubah.`
          }).then(() => {
            handleClose();
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal size="lg" centered toggle={handleClose} isOpen={props.isOpen}>
        <ModalHeader>Informasi Akun Bank</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Nama Bank</Label>
                  <Input
                    required
                    name="bank_name"
                    type="select"
                    onChange={handleChange}
                    defaultValue={values.bank_name}
                  >
                    <option value="">Pilih Bank</option>
                    {bank_options.map((result, i) => (
                      <option value={result} key={i}>
                        {result}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Input
                    required
                    placeholder="No Rekening"
                    name="account_number"
                    type="text"
                    value={values.account_number}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    required
                    placeholder="Nama Pemilik Rekening"
                    name="account_name"
                    type="text"
                    value={values.account_name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button disabled={loading} color="primary">
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdateAccountModal;
