import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Form, Button } from 'reactstrap';
import { useState } from 'react';
import OrderService from '../../../../services/OrderService';
import Swal from 'sweetalert2';

function PicModal(props) {
  const [loading, setLoading] = useState(false);
  const [picList, setPicList] = useState([]);
  const [pic, setPic] = useState(undefined);

  useEffect(() => {
    if (picList.length === 0) {
      getPICList();
    }
  }, [picList]);

  const toggle = () => {
    setLoading(false);
    setPic(undefined);
    setPicList([]);

    props.toggleModal(props.row, 'isPicModalOpen');
  };

  const getPICList = async () => {
    setLoading(true);
    try {
      const response = await OrderService.getPICList();

      if (response.status === 200) {
        setPicList(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await OrderService.changePic(pic, props.row.order.id);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'PIC berhasil diubah'
        });
        setLoading(false);
        toggle();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Modal isOpen={props.isOpen} fade={false} backdrop="static" toggle={toggle}>
      <ModalHeader toggle={toggle}>Ganti PIC</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="length">Nama PIC</Label>
            <Input
              type="select"
              name="pic"
              id="pic"
              onChange={e => {
                setPic(e.target.value);
              }}
              value={pic}
            >
              <option value="">-- Pilih PIC --</option>
              {picList.map((pic, i) => (
                <option key={i} value={pic.id}>
                  {pic.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" disabled={loading} onClick={handleSubmit}>
          {loading ? (
            <>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              &nbsp; Loading...
            </>
          ) : (
            'Simpan'
          )}
        </Button>
        <Button color="secondary" disabled={loading} onClick={toggle}>
          Batalkan
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PicModal;
