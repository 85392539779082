import React from 'react';
// nodejs library that concatenates classes
// import classnames from "classnames";
// reactstrap components
import { Button, Card, CardBody, Form, Input, Container, Row, Col } from 'reactstrap';
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader';
// sweet alert
import Swal from 'react-bootstrap-sweetalert';
// service
import ColorService from '../../../services/ColorService';

class EditColor extends React.Component {
  state = {
    color: {
      id: this.props.match.params.id,
      name: ''
    },
    customStyles: {
      name: '',
      nameState: null
    },
    alert: null
  };

  getData = () => {
    const { id } = this.props.match.params;

    ColorService.findById(id)
      .then(response => {
        this.setState({ color: response.data });
      })
  };

  updateData = () => {
    const { id } = this.state.color;
    ColorService.update(id, { name: this.state.customStyles.name })
      .then(response => {
        this.successAlert();
      })
  };

  hideAlert = () => {
    this.setState({ alert: null });
    this.props.history.push('/colors');
  };

  successAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil diubah
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.nameState = newState.name === '' ? 'invalid' : 'valid';

    this.setState({ customStyles: newState });
    if (newState.nameState === 'valid') {
      return true;
    } else {
      return false;
    }
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    newState[stateName + 'State'] = e.target.value === '' ? 'invalid' : 'valid';

    this.setState({ customStyles: newState });
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.validateCustomStylesForm()) {
      this.updateData();
    }
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Ubah Warna" parentName="Warna" parentLink="/colors" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label className="form-control-label" htmlFor="validationCustom01">
                            Nama Warna
                          </label>
                          <Input
                            defaultValue={this.state.color.name}
                            id="name"
                            placeholder="Nama"
                            type="text"
                            name="name"
                            valid={this.state.customStyles.nameState === 'valid'}
                            invalid={this.state.customStyles.nameState === 'invalid'}
                            onChange={e => this.customStylesForm(e, 'name')}
                          />
                          <div className="invalid-feedback">Nama tidak boleh kosong!</div>
                        </Col>
                      </div>
                      <Button color="primary" type="submit">
                        Ubah
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditColor;
