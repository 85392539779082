import { request } from "../config";

class CoverTypeService {
  
  /**
   * Get bank lists
   */
  static async getLists () {
    const { data: response } = await request.get('/covertypes/list');
    return response
  };

}

export default CoverTypeService