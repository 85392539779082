import React from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function PaymentLinkModal(props) {
  // const linkRef = React.createRef();

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>

      <ModalBody>
        <Form>
          <FormGroup>
            <Label>{props.title}</Label>
            <Input id="linkElement" type="text" defaultValue={props.link} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            const temp = document.createElement('input');
            document.body.append(temp);
            temp.value = props.link ? props.link : '-';

            temp.select();
            document.execCommand('copy');
            temp.remove();
            alert(`${props.title} berhasil disalin`);
          }}
        >
          Copy Link
        </Button>
        <Button
          color="success"
          onClick={() => {
            window.open(props.link, '_blank');
          }}
        >
          Buka
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PaymentLinkModal;
