import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const NoInternetConnection = props => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component

  if (!isOnline) {
    Swal.fire({
      title: 'Koneksi Bermasalah',
      text: 'Tidak ada koneksi internet, Silakan coba lagi!',
      icon: 'warning',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
  } else {
    Swal.close();
  }
  return props.children;
};

export default NoInternetConnection;
