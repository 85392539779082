import { request } from '../config';

function ScoreboardService() {
  const getSummary = async () => {
    const response = await request.get('/scoreboards/summary');
    return response;
  };

  const getSummaryOmzet = async () => {
    const response = await request.get('/scoreboards/omzet');
    return response;
  };

  const getStpDatatable = async (page, limit, sort, order, filter) => {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get(`/stp-users`, {
      params: { page, limit, sort, order, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  };

  const removeStpUser = async id => {
    const response = await request.delete(`/stp-users/${id}`);
    return response;
  };

  const addStpUser = async data => {
    const response = await request.post(`/stp-users`, data);
    return response;
  };

  const getStpUserById = async id => {
    const response = await request.get(`/stp-users/${id}`);
    return response;
  };

  const editStpUser = async (id, data) => {
    const response = await request.put(`/stp-users/${id}`, data);
    return response;
  };

  const changeStatus = async id => {
    const response = await request.put(`/stp-users/change-status/${id}`);
    return response;
  };

  const getDataDetail = async () => {
    const response = await request.get('/scoreboards/detail');
    return response;
  };

  const getDataRank = async () => {
    const response = await request.get('/scoreboards/rank');
    return response;
  };

  const getScoreboardTeam = async () => {
    const response = await request.get('/scoreboards/team');
    return response;
  };

  const getScoreboardTarget = async () => {
    const response = await request.get('/scoreboards/target');
    return response;
  };

  const getScoreboardChart = async () => {
    const response = await request.get('/scoreboards/chart');
    return response;
  };

  const getScoreboardDealmaker = async () => {
    const response = await request.get('/scoreboards/dealmaker');
    return response;
  };

  const getScoreboardCsGroup = async () => {
    const response = await request.get('/scoreboards/csgroup');
    return response;
  };

  const changePoint = async (id, subject, point, target) => {
    const response = await request.put(`/scoreboards/${subject}/${target}`, {
      id,
      [subject]: point
    });

    return response;
  };

  const getDetailData = async (id, periodicity) => {
    const response = await request.get(`/scoreboards/${periodicity}/${id}`);
    return response;
  };

  const getDetailUserData = async (id, unit) => {
    const response = await request.get(`/scoreboards/${unit}/${id}`);
    return response;
  };

  const updateScoreboard = async (unit, data) => {
    const response = await request.put(`/scoreboards/${unit}`, data);
    return response;
  };

  return {
    updateScoreboard,
    getDetailData,
    getDataRank,
    getDetailUserData,
    getSummary,
    getSummaryOmzet,
    getStpDatatable,
    removeStpUser,
    addStpUser,
    getStpUserById,
    editStpUser,
    changeStatus,
    getDataDetail,
    changePoint,
    getScoreboardTeam,
    getScoreboardTarget,
    getScoreboardChart,
    getScoreboardDealmaker,
    getScoreboardCsGroup
  };
}

export default ScoreboardService();
