import { request } from '../config';

// versi class
class OrderService {
  // Get Data for Datatable
  static async getDatatable(params) {
    params.limit = params.sizePerPage;

    delete params.sizePerPage;

    const { data: response } = await request.get('/orders', { params });
    return response;
  }

  static async getPlatforms() {
    const res = await request.get('/platforms');
    return res;
  }

  static async getOrderSources() {
    const res = await request.get('/order-sources');
    return res;
  }

  static async getTemplateSetLunasBatch() {
    const res = await request.get('/orders/pay/batch');
    return res;
  }

  static async updateSetLunasBatch(data) {
    const response = await request.post(`/orders/pay/batch`, data);
    return response;
  }

  // Show List
  static async list() {
    const { data: response } = await request.get(`/orders/list`);
    return response;
  }

  static async pay(orderId, data) {
    const response = await request.put(`/orders/pay/${orderId}`, data);
    return response;
  }

  static async move(orderId, data) {
    const response = await request.put(`/orders/move/${orderId}`, data);
    return response;
  }

  // Show detail
  static async findById(id) {
    const { data: response } = await request.get(`/orders/${id}`);
    return response;
  }

  // Show detail
  static async findByOrderDetail(id) {
    const { data: response } = await request.get(`/orders/detail/${id}`);
    return response;
  }

  // update estimasi
  static async updateEstimation(id, data) {
    const { data: response } = await request.put(`/orders/detail/${id}`, data);
    return response;
  }

  // Show detail by order number
  static async findByOrderNumber(order_number) {
    const { data: response } = await request.get(`/orders/number/${order_number}`);
    return response;
  }

  static async trackingByOrderNumber(order_number) {
    const { data: response } = await request.get(`/tracking/${order_number}`);
    return response;
  }

  static async getStatusOptions() {
    const { data: response } = await request('status-options');
    return response;
  }

  static async getUnitOptions() {
    const { data: response } = await request('unit-options');
    return response;
  }

  static async getColumnOptions() {
    const { data: response } = await request('column-options');
    return response;
  }

  static async getPICList() {
    const response = await request('/pic');
    return response;
  }

  static async changePic(picId, orderId) {
    const response = await request.put(`/orders/change-pic/${orderId}`, {
      pic: picId
    });

    return response;
  }

  static async changeTypeStatus(statusId, flowId) {
    const response = await request.put(`/flow/change-status/${flowId}`, {
      status_type_id: statusId
    });

    return response;
  }

  static async getPaymentStatusOptions() {
    const { data: response } = await request('payment-options');
    return response;
  }

  static async save(data) {
    const { data: response } = await request.post(`/orders`, data);
    return response;
  }

  // update Data
  static async update(id, data) {
    const { data: response } = await request.put(`/orders/${id}`, data);
    return response;
  }

  static async remove(id) {
    const response = await request.delete(`/orders/${id}`);
    return response;
  }

  static async checkInvoice(invoiceNumber) {
    const response = await request.post(`/orders/check-invoice-number`, {
      invoice_number: invoiceNumber
    });
    return response;
  }

  static async uploadImage(image) {
    const { data: response } = await request.post(`/upload/order/evidence`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  }

  static async uploadNpwp(image) {
    const { data: response } = await request.post(`/upload/order/npwp`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  }

  static async uploadImageRefund(image) {
    const response = await request.post(`/upload/order/refund`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  }

  static async resiUpload(image) {
    const response = await request.post(`/upload/order/resi`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  }

  static async removeImage(url) {
    try {
      const response = await request.delete(`/upload`, {
        data: { url }
      });

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async refund(data) {
    const response = await request.post(`/orders/refund`, data);
    return response;
  }

  static async processRefund(data) {
    const response = await request.post(`/orders/refund/accept`, data);
    return response;
  }

  /**
   *
   * @param {number} flowId flow id order
   * @param {Object} resep resep object
   * @param {number} resep.length - resep length in cm
   * @param {number} resep.width - resep width in cm
   * @param {number} resep.height - resep height in cm
   */
  static async resep(flowId, resep) {
    const { data: response } = await request.put(`/orders/resep/${flowId}`, resep);

    return response;
  }
}

export default OrderService;
