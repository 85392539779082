import React, { useEffect, useState } from 'react';

import {
  FormGroup,
  Button,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Form
} from 'reactstrap';

import { getPhoneNoZero } from '../../../helpers/getPhoneNoZero';

import CustomerService from '../../../services/CustomerService';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import RegionalService from '../../../services/RegionalService';
import Swal from 'sweetalert2';
import CustomerTypeService from '../../../services/CustomerTypeService';

const EditCustomer = props => {
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    phone_alt: '',
    province_id: 0,
    district_id: 0,
    subdistrict_id: 0,
    postal_code: 0,
    customer_type_id: 0
  });
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProvinces();
    getCustomerType();
    getCustomer();
  }, []);

  const getCustomerType = async () => {
    const response = await CustomerTypeService.list();
    if (response.status === 200) {
      setCustomerTypes(response.data);
    }
  };

  const getCustomer = async () => {
    const response = await CustomerService.findById(props.match.params.id);

    if (response.status === 200) {
      setCustomer({
        name: response.data.name,
        email: response.data.email,
        address: response.data.address,
        phone: getPhoneNoZero(response.data.phone),
        phone_alt: getPhoneNoZero(response.data.phone_alt),
        province_id: response.data.province_id,
        district_id: response.data.district_id,
        subdistrict_id: response.data.subdistrict_id,
        postal_code: response.data.postal_code,
        customer_type_id: response.data.customer_type_id
      });

      getDistrict(response.data.province_id);
      getSubdistrict(response.data.district_id);
    }
  };

  const getProvinces = async () => {
    const response = await RegionalService.province();
    if (response.status === 200) {
      setProvinces(response.data);
      // setDistricts([]);
      // setSubdistricts([]);
    }
  };

  const getDistrict = async province_id => {
    let response = await RegionalService.districtByProvince(province_id);
    if (response.status === 200) {
      setDistricts(response.data);
      // setSubdistricts([]);
    }
  };

  const getSubdistrict = async district_id => {
    let response = await RegionalService.subdistrictByDistrict(district_id);
    setSubdistricts(response.data);
  };

  const isNewCustomerValidated = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'name' && value.length > 100) {
      return false;
    } else if (name === 'address' && value.length > 200) {
      return false;
    } else if ((name === 'phone' || name === 'phone_alt') && value.length > 15) {
      return false;
    } else if (name === 'postal_code' && value.length > 5) {
      return false;
    } else if (name === 'email' && value.length > 50) {
      return false;
    }

    return true;
  };

  const handleInputChange = e => {
    e.persist();

    if (isNewCustomerValidated(e)) {
      const newCustomer = customer;

      if (e.target.name === 'phone' || e.target.name === 'phone_alt' || e.target.name === 'postal_code') {
        newCustomer[e.target.name] = e.target.value.replace(/[^0-9]+/gi, '');

        if (e.target.name === 'phone' || e.target.name === 'phone_alt') {
          if (newCustomer[e.target.name][0] === '0') {
            newCustomer[e.target.name] = newCustomer[e.target.name].slice(1);
          }

          if (newCustomer[e.target.name].slice(0, 2) === '62') {
            newCustomer[e.target.name] = newCustomer[e.target.name].slice(2);
          }
        }
      } else {
        newCustomer[e.target.name] = e.target.value;
      }

      if (e.target.name === 'province_id') {
        if (e.target.value) {
          getDistrict(e.target.value);
        } else {
          setDistricts([]);
          setSubdistricts([]);
        }
      } else if (e.target.name === 'district_id') {
        if (e.target.value) {
          getSubdistrict(e.target.value);
        } else {
          setSubdistricts([]);
        }
      }

      setCustomer({
        ...customer,
        ...newCustomer
      });
    }
  };

  const isDataValid = data => {
    function alert(field) {
      Swal.fire({
        icon: 'warning',
        title: 'Terjadi Kesalahan',
        text: `Input ${field} tidak sesuai aturan`
      });
    }

    if (!data.name || data.name === '' || data.name === 0) {
      alert('Nama');
      return false;
    } else if (!data.email || data.email === '' || data.email === 0) {
      alert('email');
    } else if (!data.address || data.address === '' || data.address === 0) {
      alert('Alamat');
      return false;
    } else if (!data.phone || data.phone === '' || data.phone === 0) {
      alert('No telepon');
      return false;
    }
    // else if (!data.phone_alt || data.phone_alt === '' || data.phone_alt === 0) {
    //   alert('No telepon alternatif');
    //   return false;
    // }
    else if (!data.province_id || data.province_id === '' || data.province_id === 0) {
      alert('Provinsi');
      return false;
    } else if (!data.district_id || data.district_id === '' || data.district_id === 0) {
      alert('Kabupaten/Kota');
      return false;
    } else if (!data.subdistrict_id || data.subdistrict_id === '' || data.subdistrict_id === 0) {
      alert('Kecamatan');
      return false;
    } else if (!data.postal_code || data.postal_code === '' || data.postal_code === 0) {
      alert('Kode Pos');
      return false;
    } else if (!data.customer_type_id || data.customer_type_id === '' || data.customer_type_id === 0) {
      alert('Tipe Customer');
      return false;
    }

    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const data = customer;
    data.phone = `+62${data.phone}`;
    data.phone_alt = data.phone_alt ? `+62${data.phone_alt}` : '';

    if (isDataValid(data)) {
      setLoading(true);
      try {
        const response = await CustomerService.update(props.match.params.id, data);

        if (response.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: () => {
              props.history.push('/customers');
            }
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  return (
    <>
      <SimpleHeader name="Ubah Customer" parentName="Customer" parentLink="/customers" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <Form className="needs-validation" noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col md="6">
                        <Label className="form-control-label" htmlFor="name">
                          Nama
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Nama Customer"
                          value={customer.name}
                          onChange={handleInputChange}
                        // ref={props.customerSelectRef}
                        // loadOptions={props.loadCustomerOptions}
                        // onChange={props.handleInputCustomerChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Label className="form-control-label" htmlFor="email">
                          Email
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          placeholder="Email Customer"
                          value={customer.email}
                          onChange={handleInputChange}
                        // ref={props.customerSelectRef}
                        // loadOptions={props.loadCustomerOptions}
                        // onChange={props.handleInputCustomerChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="description">
                            Alamat
                          </label>
                          <Input
                            required
                            value={customer.address}
                            type="textarea"
                            name="address"
                            rows="3"
                            placeholder="Alamat Customer"
                            onChange={handleInputChange}
                          // valid={this.state.customStyles.descriptionState === 'valid'}
                          // invalid={this.state.customStyles.descriptionState === 'invalid'}
                          // onChange={e => this.customStylesForm(e, 'description')}
                          />
                          <div className="invalid-feedback">Deskripsi tidak boleh kosong!</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="shippingAddressPhone">
                            No. Telepon Utama
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+62</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="shippingAddressPhone"
                              placeholder="8123xxx"
                              type="text"
                              name="phone"
                              // innerRef={props.shippingAddressPhoneRef}
                              value={customer.phone}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                          <small className="form-text text-muted">No telepon maksimal 14 karakter</small>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="shippingAddressPhoneAlt">
                            No. Telepon Alternatif
                          </label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+62</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="shippingAddressPhoneAlt"
                              placeholder="8123xxx"
                              type="text"
                              name="phone_alt"
                              // innerRef={props.shippingAddressPhoneAltRef}
                              value={customer.phone_alt || ''}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                          <small className="form-text text-muted">
                            (optional) No telepon alternatif maksimal 14 karakter
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="province">
                            Provinsi
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="province"
                            value={customer.province_id}
                            type="select"
                            name="province_id"
                            onChange={handleInputChange}
                          >
                            <option value="">--Pilih Provinsi--</option>
                            {provinces.map((province, key) => (
                              <option value={province.id} key={key}>
                                {province.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="district">
                            Kota / Kabupaten
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="district"
                            value={customer.district_id}
                            type="select"
                            name="district_id"
                            onChange={handleInputChange}
                          >
                            <option value="">--Pilih Kota / Kabupaten--</option>
                            {districts.map((district, key) => (
                              <option value={district.id} key={key}>
                                {district.type === 'Kabupaten' ? 'Kab.' : district.type} {district.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="subdistrict">
                            Kecamatan
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="subdistrict"
                            value={customer.subdistrict_id}
                            type="select"
                            name="subdistrict_id"
                            onChange={handleInputChange}
                          >
                            <option value="">--Pilih Kecamatan--</option>
                            {subdistricts.map((subdistrict, key) => (
                              <option value={subdistrict.id} key={key}>
                                {subdistrict.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="postalcode">
                            Kode Pos
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="postalcode"
                            placeholder="12345"
                            type="text"
                            name="postal_code"
                            value={customer.postal_code}
                            onChange={handleInputChange}
                          />
                          <small className="form-text text-muted">Kode pos maksimal 5 karakter</small>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="customer_type_id">
                            Jenis Pelanggan
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            required
                            id="customer_type_id"
                            value={customer.customer_type_id}
                            type="select"
                            name="customer_type_id"
                            onChange={handleInputChange}
                          >
                            <option value="">--Pilih Jenis Pelanggan--</option>
                            {customerTypes.map((customer_type, key) => (
                              <option value={customer_type.id} key={key}>
                                {customer_type.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button color="primary" type="submit">
                      {loading ? '...' : 'Ubah'}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EditCustomer;
