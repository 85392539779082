import React, { useState, useEffect } from 'react';
import VoucherService from '../../../services/VoucherService';
import Axios from 'axios';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Container, Row, Card, CardHeader, Col } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SearchBar from '../../../components/Search';

function TableVoucherUsage(props) {
  const [id] = useState(props.match.params.id);
  const [voucherCode, setVoucherCode] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchText, setSearchText] = useState('');

  async function getData() {
    try {
      const response = await VoucherService.getVoucherUsage(id, page, sizePerPage, sortField, sortOrder, searchText);

      if (response.status === 200) {
        setData(response.data);
        setVoucherCode(response.voucher.code);
        setTotalSize(response.recordsFiltered);
        setPage(response.page);
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getData();

    console.log({ data });

    return () => {
      source.cancel();
    };
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  return (
    <>
      <SimpleHeader name="Voucher" parentName="Pengaturan Web" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Kode Voucher : {voucherCode}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="no"
                columns={[
                  {
                    dataField: 'no',
                    text: 'Nomor',
                    sort: false
                  },
                  {
                    dataField: 'order_number',
                    text: 'Nomor Order',
                    sort: true
                  },
                  {
                    dataField: 'order_date',
                    text: 'Tanggal Order',
                    sort: true,
                    formatter: cell => (
                      <>
                        {new Date(cell).toLocaleDateString('id-ID', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </>
                    )
                  },
                  {
                    dataField: 'customer_name',
                    text: 'Nama Customer',
                    sort: true
                  },
                  {
                    dataField: 'customer_phone',
                    text: 'HP Customer',
                    sort: false
                  },
                  {
                    dataField: 'category_name',
                    text: 'Produk',
                    sort: false
                  }
                ]}
                search
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                        <label>
                          Search:
                          <SearchBar className="form-control-sm" {...props.searchProps} />
                        </label>
                      </div>
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TableVoucherUsage;
