import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import CurrencyInput from '../../../../helpers/CurrencyInput';
import CustomerService from '../../../../services/CustomerService';
import debounce from '../../../../helpers/debounce';

function AddDepositModal(props) {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [transaction_date, setTransactionDate] = useState('');
  const [evidence, setEvidence] = useState('');

  const amountRef = useRef();
  const transactionDateRef = useRef();
  const evidenceRef = useRef();

  useEffect(() => {
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (props.isOpen) {
              if (blob.size / 1000000 <= 15) {
                handleImagePaste(blob);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
                });
              }
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }, [props.isOpen]);

  const handleImagePaste = debounce(async file => {
    const formData = new FormData();
    formData.append('file', file);

    if (file) {
      try {
        setLoading(true);
        const image = await CustomerService.uploadEvidenceDeposit(formData);

        if (image.status === 200) {
          setEvidence(image.data.data[0]);
          setLoading(false);
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, 2000);

  const handleClose = () => {
    setLoading(false);
    setAmount(0);
    setTransactionDate('');
    setEvidence('');
    props.toggle('depositModalOpen');
  };

  const handleImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    const formData = new FormData();
    for (const file of e.target.files) {
      formData.append('file', file);
    }

    try {
      setLoading(true);
      const res = await CustomerService.uploadEvidenceDeposit(formData);

      if (res.status === 200) {
        setEvidence(res.data.data[0]);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error('Terjadi kesalahan oada saat upload bukti transfer.');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formatDate = date => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const handleChange = e => {
    e.target.classList.remove('is-invalid');
    const date = formatDate(e.target.value);

    setTransactionDate(date);
  };

  const isValid = () => {
    const invalidRef = (ref, apa) => {
      ref.classList.add('is-invalid');
      ref.focus();
    };

    if (!amount || amount === 0 || amount === '0') {
      // amountRef.current.textInput.inputElement.classList.add('is-invalid'); // = amountRef.current.props.className + 'is-invalid';
      // this.props.ongkirCostRef.current.focusTextInput();
      invalidRef(amountRef.current.textInput.inputElement);
      return false;
    } else if (!transaction_date || transaction_date === '') {
      invalidRef(transactionDateRef.current, 'tangl');
      return false;
    } else if (!evidence || evidence === '') {
      invalidRef(evidenceRef.current, 'gambar');
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (isValid()) {
        setLoading(true);
        const res = await CustomerService.addDeposit({
          customer_id: props.customer_id,
          amount,
          transaction_date,
          evidence
        });
        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: 'Untuk Dapat Menggunakan Saldo, Mohon Tunggu Verifikasi Dari Finance.'
          }).then(() => {
            handleClose();
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal size="lg" centered toggle={handleClose} isOpen={props.isOpen}>
        <ModalHeader>Tambah Deposit</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Jumlah Deposit</Label>
                  <CurrencyInput
                    type="text"
                    name="amount"
                    className="form-control"
                    ref={amountRef}
                    value={amount}
                    onChange={async e => {
                      e.persist();
                      e.target.classList.remove('is-invalid');

                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 20) {
                        setAmount(value);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Tanggal Deposit</Label>
                  <Input
                    id="transaction_date"
                    type="date"
                    name="transaction_date"
                    max={new Date().toISOString().split('T')[0]}
                    innerRef={transactionDateRef}
                    onKeyDown={e => e.preventDefault()}
                    value={transaction_date}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="customFileLang">
                    Bukti Transfer
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </Label>
                  <div className="custom-file">
                    <Input
                      className="custom-file-input"
                      id="customFileLang"
                      lang="en"
                      innerRef={evidenceRef}
                      type="file"
                      accept="image/*"
                      onChange={handleImage}
                    />
                    <label className="custom-file-label" htmlFor="customFileLang">
                      Pilih file
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                {evidence !== '' && evidence && (
                  <img src={evidence} alt="order evidence url" width="200" style={{ marginRight: 5 }} />
                )}
              </Col>
            </Row>

            <Button disabled={loading} color="primary">
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Simpan'
              )}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddDepositModal;
