/* eslint-disable react/no-typos */
import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: 'Rp ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: false,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 10, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false
};

class CurrencyInput extends React.Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef();
  }

  focusTextInput = () => {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    // this.textInput.current.focus();
    this.textInput.inputElement.className = this.textInput.inputElement.className + ' is-invalid';
    this.textInput.inputElement.focus();
  };

  removeIsInvalid = () => {
    this.textInput.inputElement.classList.remove('is-invalid');
  };

  render() {
    const currencyMask = createNumberMask({
      ...defaultMaskOptions
    });

    return (
      <MaskedInput
        mask={currencyMask}
        {...this.props}
        ref={input => {
          this.textInput = input;
        }}
      />
    );
  }
}
// { maskOptions, ...inputProps }

CurrencyInput.defaultProps = {
  inputMode: 'numeric'
  // maskOptions: {},
};

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number
  })
};

export default CurrencyInput;
