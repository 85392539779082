import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import PricelistService from '../../../services/PricelistService';

function EditPricelist(props) {
  const [id] = useState(props.match.params.id);
  const [category, setCategory] = useState('');
  const [size, setSize] = useState('');
  const [weight, setWeight] = useState(0);
  const [hpp, setHpp] = useState(0);
  const [retailPrice, setRetailPrice] = useState(0);
  const [resellerPrice, setResellerPrice] = useState(0);
  const [bigSellerPrice, setBigSellerPrice] = useState(0);

  const hppRef = useRef();
  const weightRef = useRef();
  const retailPriceRef = useRef();
  const resellerPriceRef = useRef();
  const bigSellerPriceRef = useRef();

  useEffect(() => {
    async function getData() {
      try {
        const res = await PricelistService.findById(id);
        if (res.status === 200) {
          setCategory(res.data.name);
          setSize(res.data.size);
          setHpp(res.data.hpp);
          setWeight(res.data.weight);
          setRetailPrice(res.data.retail);
          setResellerPrice(res.data.reseller);
          setBigSellerPrice(res.data.big_seller);
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error.message,
          onClose: () => props.history.push('/pricelists')
        });
      }
    }
    getData();
  }, []);

  const setInvalid = ref => {
    ref.classList.add('is-invalid');
    ref.focus();
    return false;
  };

  function isValid() {
    if (hpp === '' || !hpp || hpp <= 0) {
      return setInvalid(hppRef.current);
    } else if (weight === '' || !weight || weight <= 0) {
      return setInvalid(weightRef.current);
    } else if (retailPrice === '' || !retailPrice || retailPrice <= 0) {
      return setInvalid(retailPriceRef.current);
    } else if (resellerPrice === '' || !resellerPrice || resellerPrice <= 0) {
      return setInvalid(resellerPriceRef.current);
    } else if (bigSellerPrice === '' || !bigSellerPrice || bigSellerPrice <= 0) {
      return setInvalid(bigSellerPriceRef.current);
    }

    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      try {
        const pricelist = await PricelistService.update(id, {
          weight,
          hpp,
          retail: retailPrice,
          reseller: resellerPrice,
          big_seller: bigSellerPrice
        });
        if (pricelist.status === 200 && pricelist.message === 'OK') {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push('/pricelists')
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => props.history.push('/pricelists'),
          onCancel: () => props.history.push('/pricelists'),
          text: error.message
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Edit Pricelist" parentName="Tabel Pricelist" parentLink="/pricelists" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={onSubmit}>
                <Row>
                  <Col md="6">
                    <Col>
                      <Label className="form-control-label" htmlFor="category">
                        Category Cover
                      </Label>
                      <Input id="category" name="category" defaultValue={category} readOnly={true} />
                    </Col>

                    <Col>
                      <Label className="form-control-label" htmlFor="size">
                        Ukuran
                      </Label>
                      <Input id="size" name="size" defaultValue={size} readOnly={true} />
                    </Col>

                    <Col>
                      <Label className="form-control-label" htmlFor="weight">
                        Berat
                      </Label>
                      <Input
                        id="weight"
                        placeholder="Berat"
                        type="number"
                        min="0"
                        innerRef={weightRef}
                        value={weight}
                        name="weight"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setWeight(e.target.value);
                        }}
                      />
                    </Col>

                    <Col>
                      <Label className="form-control-label" htmlFor="hpp">
                        Hpp
                      </Label>
                      <Input
                        id="hpp"
                        placeholder="Hpp"
                        type="number"
                        min="0"
                        innerRef={hppRef}
                        value={hpp}
                        name="hpp"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setHpp(e.target.value);
                        }}
                      />
                    </Col>
                  </Col>
                  <Col md="6">
                    <Col>
                      <Label className="form-control-label" htmlFor="retailPrice">
                        Harga Retail
                      </Label>
                      <Input
                        id="retailPrice"
                        placeholder="Harga Retail"
                        type="number"
                        min="0"
                        innerRef={retailPriceRef}
                        value={retailPrice}
                        name="retailPrice"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setRetailPrice(e.target.value);
                        }}
                      />
                    </Col>

                    <Col>
                      <Label className="form-control-label" htmlFor="resellerPrice">
                        Harga Reseller
                      </Label>
                      <Input
                        id="resellerPrice"
                        placeholder="Harga Reseller"
                        type="number"
                        min="0"
                        innerRef={resellerPriceRef}
                        value={resellerPrice}
                        name="resellerPrice"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setResellerPrice(e.target.value);
                        }}
                      />
                    </Col>

                    <Col>
                      <Label className="form-control-label" htmlFor="bigSellerPrice">
                        Harga Agen
                      </Label>
                      <Input
                        id="bigSellerPrice"
                        placeholder="Harga Agen"
                        type="number"
                        min="0"
                        innerRef={bigSellerPriceRef}
                        value={bigSellerPrice}
                        name="bigSellerPrice"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setBigSellerPrice(e.target.value);
                        }}
                      />
                    </Col>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button color="primary" className="btn btn-sm" type="submit">
                    Ubah
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default EditPricelist;
