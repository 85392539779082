import React from 'react';
import { Modal, CardGroup, Card, CardImg } from 'reactstrap';

const EvidenceModal = props => {
  const toggle = () => {
    props.toggleModal(props.row, 'evidenceModal');
  };

  return props.row ? (
    <>
      <Modal className="modal-dialog-centered" isOpen={props.isOpen} toggle={toggle} size="lg" fade={false}>
        <div className="modal-header">
          <h5 className="modal-title" id="detailModalLabel">
            Bukti Transfer
          </h5>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {props.row.order.payment_histories.length === 0 &&
            props.row &&
            props.row.order &&
            props.row.order.verification_type === 'auto' && <h4>Pembayaran menggunakan verifikasi otomatis</h4>}
          <CardGroup>
            {props.row.order.payment_histories.map((data, key) => (
              <Card key={key}>
                {data.evidences.map((evidence, i) => (
                  <CardImg
                    key={i}
                    width={300}
                    onClick={() => window.open(evidence.image, '_blank')}
                    alt="Gambar"
                    src={evidence.image}
                  />
                ))}
              </Card>
            ))}
          </CardGroup>
        </div>
      </Modal>
    </>
  ) : (
    ''
  );
};

export default EvidenceModal;
