import React, { useState, useRef } from 'react';
import { Button, Card, CardBody, Form, Input, Container, Row, Col, Label, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import { getYoutubeId } from '../../../../helpers/getYoutubeId';
import { useEffect } from 'react';
import TipService from '../../../../services/TipService';

function EditTips(props) {
  const [title, setTitle] = useState('');
  const [youtube_id, setYoutubeId] = useState('');
  const [is_active, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);

  const titleRef = useRef();
  const youtubeIdRef = useRef();

  useEffect(() => {
    getDetailData();
  }, []);

  async function getDetailData() {
    try {
      const response = await TipService.detail(props.match.params.id);

      if (response.status === 200) {
        setTitle(response.data.title);
        setYoutubeId(`https://youtube.com/watch?v=${response.data.youtube_id}`);
        setIsActive(!!response.data.is_active);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setNotValid({ current }) {
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  function isValid() {
    if (!title || title === '') {
      return setNotValid(titleRef);
    } else if (!youtube_id || youtube_id === '' || typeof youtube_id === 'object') {
      return setNotValid(youtubeIdRef);
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      const data = {
        title,
        youtube_id: getYoutubeId(youtube_id),
        is_active
      };

      setLoading(true);
      try {
        const response = await TipService.edit(props.match.params.id, data);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push('/tips')
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: 'Terjadi kesalahan',
          icon: 'error',
          text: error
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Edit Tips" parentName="Tabel Tips" parentLink="/tips" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Judul</Label>
                      <Input
                        type="text"
                        name="title"
                        placeholder="Menghangatkan Mesin Mobil"
                        innerRef={titleRef}
                        value={title}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setTitle(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Link Youtube</Label>
                      <Input
                        type="text"
                        value={youtube_id}
                        placeholder="https://www.youtube.com/watch?v=kX6hLACGvjI"
                        innerRef={youtubeIdRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setYoutubeId(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="validationStatus">
                        Status Aktif
                      </label>
                      <br />
                      <label className="custom-toggle">
                        <input
                          value={is_active}
                          checked={is_active}
                          type="checkbox"
                          name="is_active"
                          onChange={e => setIsActive(!is_active)}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Tidak"
                          data-label-on="Aktif"
                        />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button disabled={loading} color="primary" className="btn btn-sm" type="submit">
                    Ubah
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default EditTips;
