import { request } from '../config';

function DashboardService() {
  const getDashboard = async () => {
    const { data } = await request.get(`/dashboards`);
    return data;
  };

  const getDashboardLeads = async params => {
    const { data } = await request.get(`/leads/dashboard`, { params });
    return data;
  };

  return {
    getDashboard,
    getDashboardLeads
  };
}

export default DashboardService();
