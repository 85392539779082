import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../components/Headers/SimpleHeader';
import { FLOW_VERSION } from '../../constants';

const UpdateVersion = props => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [version, setVersion] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (props.socket.connected) {
      setIsConnected(true);
      setSocket(props.socket);
    }
  }, [props.socket]);

  const handleSubmit = e => {
    e.preventDefault();

    socket.emit('updateFlowVersion', { message, version }, response => {
      console.log('response',response);
      if (response) Swal.fire('Broadcast telah dikirim dengan payload : ', JSON.stringify(response));
    });
  };

  return (
    <>
      <SimpleHeader name="Version" parentName="Version Management" parentLink="/dashboard" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="3">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="connectionStatus">
                            Status Koneksi :
                          </Label>
                          <Input
                            id="connectionStatus"
                            type="text"
                            readOnly
                            value={isConnected ? 'connected' : 'disconnected'}
                            name="connectionStatus"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="version">
                            Versi saat ini :
                          </Label>
                          <Input id="version" type="text" readOnly value={FLOW_VERSION} name="version" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="message">
                            Pesan Broadcast
                          </Label>
                          <Input
                            id="message"
                            required
                            placeholder="Masukkan pesan broadcast"
                            type="text"
                            value={message}
                            name="message"
                            onChange={e => {
                              setMessage(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="version">
                            Versi Terbaru
                          </Label>
                          <Input
                            id="version"
                            required
                            placeholder="XX.YY.ZZ"
                            type="text"
                            value={version}
                            name="version"
                            onChange={e => {
                              setVersion(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button color="primary" className="btn" type="submit">
                    Update Versi
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    socket: state.socket.io
  };
};

export default connect(mapStateToProps, null)(UpdateVersion);
