import { request } from '../config';

// versi class
class ReviewService {
  // Get Data for Datatable
  static async getDatatable(params, categoryId, isPublished) {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    params.page = params.page > 0 ? params.page - 1 : 0;

    const { data: response } = await request.get('/reviews', {
      params: {
        page: params.page,
        limit: params.limit,
        filter: params.filter,
        category_id: categoryId,
        is_published: isPublished
      }
    });
    return {
      ...response,
      page: response.page + 1 // karena defaultnya index = 0
    };
  }

  static async changeStatus(id) {
    const { data: response } = await request.put(`/reviews/change-status/${id}`);
    return response;
  }

  // update Data
  static async update(id, data) {
    const { data: response } = await request.put(`/orders/${id}`, data);
    return response;
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/orders/${id}`);
    return response;
  }

  static async uploadImage(image) {
    const { data: response } = await request.post(`/upload/order/evidence`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  }

  /**
   *
   * @param {number} flowId flow id order
   * @param {Object} resep resep object
   * @param {number} resep.length - resep length in cm
   * @param {number} resep.width - resep width in cm
   * @param {number} resep.height - resep height in cm
   */
  static async resep(flowId, resep) {
    const { data: response } = await request.put(`/orders/resep/${flowId}`, resep);

    return response;
  }
}

export default ReviewService;
