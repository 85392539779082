import React, { useEffect, useState, useRef } from 'react';
import ScoreboardService from '../../../../services/ScoreboardService';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import { Container, Row, Card, CardBody, Form, FormGroup, Label, Input, Col, Button } from 'reactstrap';
import Swal from 'sweetalert2';

function AddStpUser(props) {
  const [name, setName] = useState('');
  const [is_active, setIsActive] = useState(false);
  const [loading] = useState(false);

  const nameRef = useRef();

  useEffect(() => {}, []);

  function isValid() {
    if (name === '' || name === 0) {
      const ref = nameRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    }

    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    const data = {
      name,
      is_active
    };

    if (isValid()) {
      try {
        const response = await ScoreboardService.addStpUser(data);
        console.log(response);
        if (response.status === 200 && response.statusText === 'OK') {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil ditambahkan',
            onClose: props.history.push('/stp-users')
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => props.history.push('/stp-users'),
          onCancel: () => props.history.push('/stp-users'),
          text: error.message
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Tambah STP User" parentName="Tabel STP User" parentLink="/stp-users" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Nama STP</Label>
                      <Input
                        type="text"
                        name="name"
                        innerRef={nameRef}
                        value={name}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setName(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="validationStatus">
                        Status Aktif
                      </label>
                      <br />
                      <label className="custom-toggle">
                        <input
                          value={is_active}
                          checked={is_active}
                          type="checkbox"
                          name="is_active"
                          onChange={e => setIsActive(!is_active)}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Tidak"
                          data-label-on="Aktif"
                        />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button disabled={loading} color="primary" className="btn btn-sm" type="submit">
                    Simpan
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default AddStpUser;
