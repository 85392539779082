import { request } from '../config';

// versi function
function CategoryService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/categories', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;
    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // Get Data for Datatable
  const list = async () => {
    const response = await request.get('/categories/list');

    return response.data;
  };

  const needVehicle = async () => {
    const response = await request.get('/categories/need-vehicle');

    return response.data;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/categories/${id}`);

    return response.data;
  };

  // insert data
  const save = async data => {
    const response = await request.post(`/categories`, data);

    return response;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/categories/${id}`, data);

    return response.data;
  };

  // remove data
  const remove = async id => {
    const response = await request.delete(`/categories/${id}`);

    return response.data;
  };

  return {
    getDatatable,
    list,
    needVehicle,
    findById,
    save,
    update,
    remove
  };
}

export default CategoryService();
