import React from 'react';
import { Container, UncontrolledAlert } from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';

import AddonModal from './modal/AddonModal';
import CustomerModal from './modal/CustomerModal';

import Swal from 'react-bootstrap-sweetalert';
import debounce from '../../../helpers/debounce';

import AddonService from '../../../services/AddonService';
import CourierService from '../../../services/CourierService';
import CustomerService from '../../../services/CustomerService';
import CustomerTypeService from '../../../services/CustomerTypeService';
import PaymentStatusService from '../../../services/PaymentStatusService';
import ProductService from '../../../services/ProductService';
import RegionalService from '../../../services/RegionalService';
import ShippingCostService from '../../../services/ShippingCost';

import { defaultState } from './state/defaultState';

import moment from 'moment';
import {
  CATEGORY_COVER_MOBIL_DELUXE,
  CATEGORY_COVER_MOBIL_SUPERIOR,
  COVER_TYPE_ELEGAN,
  TYPE_KARPET
} from '../../../constants';
import { getPhoneNoZero } from '../../../helpers/getPhoneNoZero';
import OrderService from '../../../services/OrderService';
import CustomerForm from './CustomerForm';
import PaymentForm from './PaymentForm';
import ProductForm from './ProductForm';

moment.locale('id');

// let TAX_RATE = 10 / 100; // 10% sebelum 1 April 2022

// if (moment().isSameOrAfter('2022-04-01 00:00:00')) {
let TAX_RATE = 11 / 100; // 11% per 1 April 2022
// }

class FormOrder extends React.Component {
  state = {
    loading: false,
    url: '',
    evidenceImagePaste: '',
    productImagePaste: '',
    request_tax_invoice: false,
    checkInsurance: false,
    npwp: null,
    productImagePasteFile: null,
    evidenceImagePasteFile: null,
    invoice_number: '',
    bonus: [],
    TAX_RATE,
    shipping_type: 'manual'
  };

  npwpImageRef = React.createRef();
  // customer ref
  customerSelectRef = React.createRef();
  invoiceNumberRef = React.createRef();
  shippingAddressNameRef = React.createRef();
  shippingAddressPhoneRef = React.createRef();
  shippingAddressPhoneAltRef = React.createRef();
  shippingAddressAddressRef = React.createRef();
  shippingAddressProvinceRef = React.createRef();
  shippingAddressDistrictRef = React.createRef();
  shippingAddressSubdistrictRef = React.createRef();
  shippingAddressPotalCodeRef = React.createRef();
  rowProductRef = [];

  orderDateRef = React.createRef();

  // product ref
  productSelectRef = React.createRef();

  // courier ref
  courierSelectRef = React.createRef();
  serviceSelectRef = React.createRef();
  insuranceFeeRef = React.createRef();
  nameSelectRef = React.createRef();
  ongkirCostRef = React.createRef();

  // payment ref
  paymentStatusRef = React.createRef();
  paymentDateRef = React.createRef();
  paymentAmountRef = React.createRef();
  bankRef = React.createRef();

  componentDidMount() {
    this.getProvince();
    this.getCustomerType();
    this.getCourier();
    this.getAddons();
    this.getPaymentStatus();
    this.customerSelectRef.current.focus();
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            console.log(blob.size / 1000000 <= 10);
            if (blob.size / 1000000 <= 10) {
              // product addon modal tidak terbuka
              if (!this.state.addonModal) {
                this.setState({
                  evidenceImagePaste: event.target.result,
                  evidenceImagePasteFile: blob
                });
              }

              // jika addon modal terbuka
              if (this.state.addonModal) {
                this.setState({
                  productImagePaste: event.target.result,
                  productImagePasteFile: blob
                });
              }
            } else {
              this.errorAlert('File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa');
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }

  resetEvidenceImagePasteFile = () => {
    this.setState({ productImagePaste: '', evidenceImagePasteFile: null });
  };

  componentWillMount() {
    this.resetState();
  }

  resetState = () => {
    this.setState({ ...JSON.parse(JSON.stringify(defaultState)) });
  };

  loadCustomerOptions = async inputValue => {
    if (inputValue.length >= 2) {
      const response = await CustomerService.searchCustomer({
        filter: inputValue
      });
      let options = [];
      for (let row of response.data) {
        options.push({ value: row.id, label: row.name + ' | ' + row.phone });
      }
      return options;
    }
  };

  handleInvoiceChange = debounce(async value => {
    try {
      const response = await OrderService.checkInvoice(value);

      if (response.status === 200 && !response.data.data.is_exist) {
        this.setState({
          invoice_number: value
        });
      }
    } catch (error) {
      this.setState({
        invoice_number: ''
      });
      this.errorAlert(error.response.data.message);
      const ref = this.invoiceNumberRef;
      ref.current.classList.add('is-invalid');
      ref.current.focus();
      console.log(error);
    }
  }, 1000);

  isChangeValid = (name, value) => {
    if (name === 'order_description' && value.length > 200) {
      return false;
    }

    return true;
  };

  handleChange = (name, value) => {
    const revertCurrencyFormat = ['payment_amount', 'additional_cost', 'discount', 'cost'];

    if (this.isChangeValid(name, value)) {
      if (revertCurrencyFormat.some(field => name === field)) {
        if (name === 'cost') {
          this.setState({
            courier: {
              ...this.state.courier,
              [name]: Number(value.slice(3).replace(/\./g, ''))
            }
          });
        } else {
          this.setState({ [name]: Number(value.slice(3).replace(/\./g, '')) }); // menghapus 'Rp ' dan titik
        }
      } else {
        if (name === 'order_source') {
          document.getElementById('orderSourceValidFeedback').style.display = 'none';
        }
        this.setState({ [name]: value });
      }
    }
  };

  handleChangeFakturPajak = () => {
    // console.log(request_tax_invoice)
    if (this.state.request_tax_invoice) {
      this.setState({
        request_tax_invoice: false,
        npwp: null
      });
    } else {
      this.setState({
        request_tax_invoice: true
      });
    }
  };

  setNpwp = npwp => {
    this.setState({ npwp });
  };

  removeNpwp = () => {
    this.setState({ npwp: null });
  };

  handleChangePlatform = (platform, invoice_required) => {
    document.getElementById('platformValidFeedback').style.display = 'none';

    if (invoice_required) {
      this.setState({ shipping_type: 'manual' });
    }

    this.setState({
      platform,
      invoice_required,
      // reset state payment
      payment_status_id: '',
      payment_date: '',
      order_date: '',
      payment_amount: '0',
      bank_id: 0
    });
  };

  loadProductOptions = debounce((inputValue, callback) => {
    if (inputValue.length >= 2) {
      ProductService.searchProduct({
        filter: inputValue,
        supplier_id: this.state.supplier.id // add filter supplier yang sama
      }).then(response => {
        let options = [];
        for (let row of response.data) {
          options.push({ value: row.variant_id, label: row.name });
        }
        callback(options);
      });
    }
  }, 500);

  handleInputCustomerChange = async ({ value, label }) => {
    let { data } = await CustomerService.findById(value);

    if (data.phone && data.phone[0] === '0') {
      data.phone = data.phone.slice(1);
    }

    if (data.phone_alt && data.phone_alt[0] === '0') {
      data.phone_alt = data.phone_alt.slice(1);
    }

    if (data.phone && data.phone.slice(0, 2) === '62') {
      data.phone = data.phone.slice(2);
    }

    if (data.phone && data.phone.slice(0, 3) === '+62') {
      data.phone = data.phone.slice(3);
    }

    if (data.phone_alt && data.phone_alt.slice(0, 2) === '62') {
      data.phone_alt = data.phone_alt.slice(2);
    }

    if (data.phone_alt && data.phone_alt.slice(0, 3) === '+62') {
      data.phone_alt = data.phone_alt.slice(3);
    }

    this.setShippingAddress(data);
  };

  handleInputProductChange = async ({ value: id }) => {
    // value as id
    const { shippingAddress } = this.state;

    // jika customer belum diisi
    if (!shippingAddress.customer_type_id) {
      this.setState(
        {
          warning: (
            <UncontrolledAlert color="warning" fade={false}>
              <span className="alert-inner--text">
                <strong>Peringatan!</strong> Data Pelanggan belum diisi
              </span>
            </UncontrolledAlert>
          )
        },
        () => this.customerSelectRef.current.focus()
      );
    } else {
      const { data } = await ProductService.findByVariantId(id);

      const supplier = data.product.supplier;
      this.setState({ supplier });

      const newVariant = {
        ...data,
        qty: 1,
        note: '',
        addons: [],
        color_id: 0,
        year: '',
        transmission_type: ''
      };

      if (![CATEGORY_COVER_MOBIL_SUPERIOR, CATEGORY_COVER_MOBIL_DELUXE].includes(data.product.category_id)) {
        newVariant.cover_type_id = COVER_TYPE_ELEGAN;
      }

      if (!!data.product.category.is_ready_stock) {
        newVariant.logo = 'TANPA LOGO';
        newVariant.year = new Date().getFullYear().toString();
      }

      // jika warna hanya 1, set otomatis ke warna tersebut
      if (data.available_colors.length === 1) {
        newVariant.color_id = data.available_colors[0].id;
      }

      // jika cuma ada 1 data, set ke data tersebut
      if (data.available_cover_types.length === 1) {
        newVariant.cover_type_id = data.available_cover_types[0].id;
      }

      this.setState(prevState => {
        let productVariants = prevState.productVariants;
        this.setWarningState(null);

        // mapping data product
        productVariants.push(newVariant);

        return { productVariants };
      });
    }
  };

  changeDefaultRegional = () => {
    if (this.state.shippingAddress.province.id) {
      this.getProvince();
      this.getDistrict(this.state.shippingAddress.province_id);
      this.getSubdistrict(this.state.shippingAddress.district_id);
    }
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  errorAlert = message => {
    this.setState({
      alert: (
        <Swal
          warning
          title="Terjadi Kesalahan"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </Swal>
      )
    });
  };

  dangerAlert = () => {
    this.setState({
      alert: (
        <Swal
          danger
          title="Pesanan Bermasalah"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Pesanan gagal disimpan, silakan cek kembali
        </Swal>
      )
    });
  };

  successAlert = orderNumber => {
    this.setState(
      {
        alert: (
          <Swal
            success
            title="Success"
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            onConfirm={() => {
              console.log('success');
            }}
            btnSize=""
          >
            Data berhasil ditambahkan
          </Swal>
        )
      },
      () => {
        setTimeout(() => {
          if (orderNumber) {
            this.resetState();
            this.props.history.push('/invoice/' + orderNumber);
          } else {
            this.hideAlert();
          }
        }, 1500);
      }
    );
  };

  getProvince = async () => {
    let provinceOptions = await RegionalService.province();
    this.setState({ provinceOptions: provinceOptions.data });
  };

  getDistrict = async province_id => {
    let districtOptions = await RegionalService.districtByProvince(province_id);
    this.setState({ districtOptions: districtOptions.data });
  };

  getSubdistrict = async district_id => {
    let subdistrictOptions = await RegionalService.subdistrictByDistrict(district_id);
    this.setState({ subdistrictOptions: subdistrictOptions.data });
  };

  getCustomerType = async () => {
    let customerTypeOptions = await CustomerTypeService.list();
    this.setState({ customerTypeOptions: customerTypeOptions.data });
  };

  getCourier = async () => {
    let courierOptions = await CourierService.courierList();
    this.setState({ courierOptions: courierOptions.data });
  };

  getAddons = async () => {
    let addonOptions = await AddonService.list();
    this.setState({ addonOptions: addonOptions.data });
  };

  getPaymentStatus = async () => {
    let paymentStatusOptions = await PaymentStatusService.list();
    this.setState({ paymentStatusOptions: paymentStatusOptions.data });
  };

  setDefaultCourier = () => {
    this.setState({
      courier: {
        code: '',
        name: '',
        service: '',
        description: '',
        cost: 0,
        insurance: {
          percent: 0,
          flat_fee: 0,
          minimum_fee: 0
        }
      }
    });
  };

  getCourierService = async courierCode => {
    const weight = this.countWeight();
    // const origin = this.state.supplier.subdistrict_id;
    const destination = this.state.shippingAddress.subdistrict_id;

    // jika kurir belum dipilih
    if (!courierCode) {
      this.setState(
        {
          warning: (
            <UncontrolledAlert color="warning" fade={false}>
              <span className="alert-inner--text">
                <strong>Peringatan!</strong> Silahkan pilih Kurir !
              </span>
            </UncontrolledAlert>
          )
        },
        () => this.setDefaultCourier()
      );
    } else if (weight === 0) {
      // jika produk masih kosong
      this.setState(
        {
          warning: (
            <UncontrolledAlert color="warning" fade={false}>
              <span className="alert-inner--text">
                <strong>Peringatan!</strong> Produk masih kosong, silahkan pilih produk !
              </span>
            </UncontrolledAlert>
          )
        },
        () => {
          this.setDefaultCourier();
          this.productSelectRef.current.focus();
          this.insuranceFeeRef.current.focus();
        }
      );
    } else if (!destination) {
      // jika alamat penerima masih kosong masih kosong
      this.setState(
        {
          warning: (
            <UncontrolledAlert color="warning" fade={false}>
              <span className="alert-inner--text">
                <strong>Peringatan!</strong> silahkan pilih Pelanggan !
              </span>
            </UncontrolledAlert>
          )
        },
        () => {
          this.setDefaultCourier();
          this.customerSelectRef.current.focus();
        }
      );
    } else {
      const data = { courier: courierCode, weight, destination };

      this.setState({ loading: true });
      let courierServiceOptions = await ShippingCostService.getOngkir(data);

      this.setState({
        courier: {
          ...this.state.courier,
          service: '',
          description: '',
          cost: 0,
          insurance: {
            ...this.state.courier.insurance
          }
        }
      });
      this.setState({ courierServiceOptions: courierServiceOptions.data, loading: false });
    }
  };

  handleChangeCourier = e => {
    const { value: code } = e.target;

    // penentuan shipping type yang disupport kirimin aja
    const courierItem = this.state.courierOptions.find(e => e.courier_code === code);
    if (courierItem && courierItem.default_gateway === 'kiriminaja' && !!!this.state.invoice_required) {
      console.log({ shipping: 'auto' });
      this.setState({ shipping_type: 'auto' });
    } else {
      console.log({ shipping: 'manual' });
      this.setState({ shipping_type: 'manual' });
    }

    e.target.classList.remove('is-invalid');

    if (code !== 'manual') {
      const index = e.nativeEvent.target.selectedIndex;
      const name = e.nativeEvent.target[index].text;

      this.getCourierService(code);
      this.setState({
        courier: {
          ...this.state.courier,
          code,
          name
        }
      });

      this.ongkirCostRef.current.removeIsInvalid();
      // console.log(this.ongkirCostRef.current)
    } else if (code === 'manual') {
      this.setState({
        courier: {
          code,
          name: '',
          service: '',
          description: '',
          cost: 0
        }
      });
    }
  };

  handleChangeCourierName = e => {
    e.target.classList.remove('is-invalid');
    this.setState({
      courier: {
        ...this.state.courier,
        [e.target.name]: e.target.value,
        description: e.target.value
      }
    });
  };

  handleChangeCourierService = e => {
    const { value: service } = e.target;

    const courier = this.state.courierServiceOptions.find(courier => courier.service === service);
    e.target.classList.remove('is-invalid');

    this.setState({
      courier: {
        ...this.state.courier,
        ...courier
      }
    });
  };

  changeInsurance = () => {
    if (this.state.checkInsurance) {
      this.setState({
        checkInsurance: false
      });
    } else {
      this.setState({
        checkInsurance: true
      });
    }
  };

  shippingAddressChange = e => {
    e.persist();

    if (this.isNewCustomerValidated(e)) {
      this.setState(prevState => {
        let shippingAddress = prevState.shippingAddress;

        if (e.target.name === 'phone' || e.target.name === 'phone_alt' || e.target.name === 'postal_code') {
          shippingAddress[e.target.name] = e.target.value.replace(/[^0-9]+/gi, '');

          if (e.target.name === 'phone' || e.target.name === 'phone_alt') {
            if (shippingAddress[e.target.name][0] === '0') {
              shippingAddress[e.target.name] = shippingAddress[e.target.name].slice(1);
            }

            if (shippingAddress[e.target.name].slice(0, 2) === '62') {
              shippingAddress[e.target.name] = shippingAddress[e.target.name].slice(2);
            }

            // shippingAddress[e.target.name] = `+62${shippingAddress[e.target.name]}`;
          }
        } else {
          shippingAddress[e.target.name] = e.target.value;
        }

        return { shippingAddress };
      });
    }
  };

  isNewCustomerValidated = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'name' && value.length > 100) {
      return false;
    } else if (name === 'address' && value.length > 200) {
      return false;
    } else if ((name === 'phone' || name === 'phone_alt') && value.length > 15) {
      return false;
    } else if (name === 'postal_code' && value.length > 5) {
      return false;
    } else if (name === 'email' && value.length > 50) {
      return false;
    }

    return true;
  };

  newCustomerChange = e => {
    e.persist();

    if (this.isNewCustomerValidated(e)) {
      this.setState(prevState => {
        let newCustomer = prevState.newCustomer;

        if (e.target.name === 'phone' || e.target.name === 'phone_alt' || e.target.name === 'postal_code') {
          newCustomer[e.target.name] = e.target.value.replace(/[^0-9]+/gi, '');

          if (e.target.name === 'phone' || e.target.name === 'phone_alt') {
            if (newCustomer[e.target.name][0] === '0') {
              newCustomer[e.target.name] = newCustomer[e.target.name].slice(1);
            }

            if (newCustomer[e.target.name].slice(0, 2) === '62') {
              newCustomer[e.target.name] = newCustomer[e.target.name].slice(2);
            }
          }
        } else {
          newCustomer[e.target.name] = e.target.value;
        }
        return { newCustomer };
      });
    }
  };

  handleChangeProvince = e => {
    this.shippingAddressChange(e);
    if (e.target.value) {
      this.getDistrict(e.target.value);
    } else {
      this.setState({
        districtOptions: [],
        subdistrictOptions: []
      });
    }
  };

  handleChangeProvinceCustomer = e => {
    this.newCustomerChange(e);
    if (e.target.value) {
      this.getDistrict(e.target.value);
    } else {
      this.setState({
        districtOptions: [],
        subdistrictOptions: []
      });
    }
  };

  handleChangeDistrict = e => {
    this.shippingAddressChange(e);
    if (e.target.value) {
      this.getSubdistrict(e.target.value);
    } else {
      this.setState({ subdistrictOptions: [] });
    }
  };

  handleChangeDistrictCustomer = e => {
    this.newCustomerChange(e);
    if (e.target.value) {
      this.getSubdistrict(e.target.value);
    } else {
      this.setState({ subdistrictOptions: [] });
    }
  };

  toggleModal = (state, productRow = this.state.productRow) => {
    if (state === 'addonModal') {
      this.setState({
        [state]: !this.state[state],
        productRow
      });
    } else {
      this.setState({
        [state]: !this.state[state]
      });
    }
  };

  resetNewCustomer = () => {
    this.setState({
      newCustomer: {
        name: '',
        phone: '',
        phone_alt: '',
        email: '',
        address: '',
        province_id: '',
        district_id: '',
        subdistrict_id: '',
        postal_code: '',
        customer_type_id: ''
      }
    });
  };

  setShippingAddress = data => {
    this.resetNewCustomer();
    this.setState(
      {
        customerModal: false,
        warning: null,
        shippingAddress: data,
        platform: '',
        customer_placeholder: `${data.name} | +62${data.phone}`,
        payment_status_id: ''
      },
      () => {
        this.changeDefaultRegional();
      }
    );

    document.getElementById('customerValidFeedback').style.display = 'none';
  };

  handleAddCustomer = async e => {
    e.preventDefault();
    const data = this.state.newCustomer;
    data.phone = `+62${data.phone}`;
    data.phone_alt = data.phone_alt ? `+62${data.phone_alt}` : '';

    this.setState({ loading: true });
    try {
      const response = await CustomerService.save(data);

      if (response.status === 200) {
        this.setState({ loading: false });
        this.successAlert();

        const shippingAddress = {
          ...response.data,
          phone: response.data.phone ? getPhoneNoZero(response.data.phone) : '',
          phone_alt: response.data.phone_alt ? getPhoneNoZero(response.data.phone_alt) : ''
        };

        this.setShippingAddress(shippingAddress);
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  handleChangeDiscount = (e, index) => {
    e.persist();
    this.setState(prevState => {
      const productVariants = prevState.productVariants;
      productVariants[index].discount = e.target.value;

      return { productVariants };
    });
  };

  isChangeProductVariantValid = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'note' && value.length > 200) {
      return false;
    }

    return true;
  };

  handleChangeProductVariant = (e, index) => {
    e.persist();

    if (this.isChangeProductVariantValid(e)) {
      e.target.classList.remove('is-invalid');

      this.setState(
        prevState => {
          const productVariants = prevState.productVariants;
          productVariants[index][e.target.name] = e.target.value;

          if (e.target.name === 'cover_type_id' && parseInt(e.target.value) === COVER_TYPE_ELEGAN) {
            document.getElementById(`cover_list-${index}`).classList.remove('is-invalid');

            productVariants[index].cover_list_id = '';
          }

          return { productVariants };
        },
        () => this.setDefaultCourier()
      );
    }
  };

  handleRemoveProduct = (e, index) => {
    e.persist();
    this.setState(
      prevState => {
        const oldProducts = prevState.productVariants;

        const productVariants = oldProducts.filter((value, key) => index !== key);

        return { productVariants };
      },
      () => {
        // reset kurir jika tidak ada produk
        if (this.state.productVariants.length === 0) {
          this.setState({
            courier: {
              code: '',
              name: '',
              service: '',
              description: '',
              cost: 0
            }
          });
        }
      }
    );
  };

  countTotal = variant => {
    let { price, qty } = variant;

    const addonsPrice = variant.addons.reduce((accumulator, addon) => accumulator + addon.price, 0);
    return (price + addonsPrice) * qty;
  };

  countSubtotal = () => {
    const subtotal = this.state.productVariants.reduce(
      (accumulator, variant) => accumulator + this.countTotal(variant),
      0
    );

    return subtotal;
  };

  countWeight = () => {
    const totalWeight = this.state.productVariants.reduce(
      (accumulator, variant) => accumulator + variant.weight * variant.qty,
      0
    );

    return totalWeight;
  };

  countInsuranceFee = () => {
    if (this.state.checkInsurance && this.state.courier.insurance) {
      const { percent, flat_fee, minimum_fee } = this.state.courier.insurance;
      const totalPrice = this.countForInsuranceTotal();

      const insurance_fee = Math.ceil(((percent / 100) * totalPrice) / 100) * 100;
      const insuranceTotal = insurance_fee + flat_fee;
      let finalInsuranceFee = Math.max(insuranceTotal, minimum_fee);

      return finalInsuranceFee;
    } else {
      return 0;
    }
  };

  countForInsuranceTotal = () => {
    const priceForInsurance =
      this.countSubtotal() + parseInt(this.state.additional_cost) - parseInt(this.state.discount) || 0;

    return priceForInsurance;
  };

  countGrossTotal = () => {
    const grossTotal =
      this.countSubtotal() +
        parseInt(this.state.courier.cost) +
        this.countInsuranceFee() +
        parseInt(this.state.additional_cost) -
        parseInt(this.state.discount) || 0;

    return grossTotal;
  };

  getProductName = variant => {
    let arr = [
      variant.product.category ? variant.product.category.name : null,
      variant.product.type_car ? variant.product.type_car.name : null,
      variant.product.manufacturer ? variant.product.manufacturer.name : null,
      variant.product ? variant.product.name : null
    ];

    if (variant.product.category && variant.product.category.vehicle_type_id === TYPE_KARPET) {
      arr.push(variant.size);
    }

    return arr.filter(e => e).join(' ');
  };

  changeBonus = (name, val) => {
    this.setState(prevState => {
      let { bonus } = prevState;

      if (bonus.includes(name)) {
        bonus = bonus.filter(e => e !== name);
      } else {
        bonus.push(name);
      }
      console.log(bonus);

      return { bonus };
    });
  };

  changeAddonImage = (response, i) => {
    this.setState(prevState => {
      let { productVariants } = prevState;

      // const variantIndex = productVariants.findIndex(variant => variant.id === productRow.id);

      // cek udah ada var images belum
      if (!productVariants[i].images) {
        productVariants[i].images = [];
      }

      let images = productVariants[i].images;

      images.push({ image: response.data[0] });
      productVariants[i].images = images;
      return { productVariants };
    });
  };

  removeAddonImage = url => {
    this.setState(prevState => {
      const { currentVariant, productVariants } = prevState;

      const index = productVariants.findIndex(variant => variant.id === currentVariant.id);

      console.log(productVariants);
      const imageIndex = productVariants[index].images.indexOf(url);

      if (imageIndex !== -1) {
        productVariants[index].images.splice(imageIndex, 1);

        if (productVariants[index].images.length < 1) {
          delete productVariants[index].images;
        }
        return { productVariants };
      }
    });
  };

  changeAddonProduct = (e, i, addon) => {
    e.persist();

    const { checked } = e.target;

    if (checked) {
      this.setState(prevState => {
        const productVariants = prevState.productVariants;
        let addonExisting = productVariants[i].addons.findIndex(addonExist => addonExist.id === addon.id);
        if (addonExisting < 0) {
          // not exist
          productVariants[i].addons.push({ ...addon });
        }

        return { productVariants };
      });
    } else {
      this.setState(prevState => {
        const productVariants = prevState.productVariants;
        let addonsAfterRemoved = productVariants[i].addons.filter(addonExist => addonExist.id !== addon.id);
        productVariants[i].addons = addonsAfterRemoved;

        return { productVariants };
      });
    }
  };

  countPPN = () => {
    // if (
    //   [PLATFORM_BLIBLI, PLATFORM_BUKALAPAK, PLATFORM_LAZADA, PLATFORM_SHOPEE, PLATFORM_TOKOPEDIA].includes(
    //     this.state.platform
    //   )
    // ) {
    //   return 0;
    // } else {

    return Math.ceil(this.countGrossTotal() * TAX_RATE);
    // }
  };

  countGrandTotal = () => {
    return this.countGrossTotal() + this.countPPN();
  };

  handleChangePaymentStatus = e => {
    const payment_status_id = e.target.value;
    const stateChanged = { payment_status_id, payment_date: '', bank_id: 0 };

    if (payment_status_id === this.state.STATUS_PAID) {
      stateChanged['payment_amount'] = this.countGrandTotal();
    } else if (payment_status_id === this.state.STATUS_DP) {
      stateChanged['payment_amount'] = Math.ceil((this.countGrandTotal() * 0.4) / 1000) * 1000;
    } else {
      stateChanged['payment_amount'] = 0;
    }

    // remove class invalid
    e.target.classList.remove('is-invalid');
    if (this.paymentAmountRef.current) {
      this.paymentAmountRef.current.classList.remove('is-invalid');
    }

    this.setState(stateChanged);
  };

  setRefRow = tr => {
    if (tr) {
      this.rowProductRef.push(tr);
    }
  };

  removeRow = rowId => {
    this.rowProductRef = this.rowProductRef.filter(row => row.id !== rowId);
  };

  rowProductClosed = () => {
    const variant = this.state.productVariants.filter(variant => variant.id === this.state.productRow.id)[0];

    if (variant.images) {
      document
        .getElementById(`row-product-${this.state.productRow.index}-${this.state.productRow.id}`)
        .removeAttribute('style');

      if (
        !this.state.productVariants
          .filter(oke => !!!oke.product.category.is_ready_stock)
          .map(ok => ok.images)
          .some(un => un === undefined)
      ) {
        this.setWarningState(null);
      }
    }
  };

  setWarningState = warning => {
    this.setState({ warning });
  };

  render() {
    return (
      <>
        {this.state.alert}
        {this.state.loading && <div className="loader"></div>}
        {/* Addon Modal */}
        <AddonModal
          {...this.state}
          toggleModal={this.toggleModal}
          changeAddonImage={this.changeAddonImage}
          changeAddonProduct={this.changeAddonProduct}
          rowProductClosed={this.rowProductClosed}
          removeAddonImage={this.removeAddonImage}
          errorAlert={this.errorAlert}
        />
        {/* Customer modal */}
        <CustomerModal
          {...this.state}
          toggleModal={this.toggleModal}
          setShippingAddress={this.setShippingAddress}
          resetNewCustomer={this.resetNewCustomer}
          newCustomerChange={this.newCustomerChange}
          handleChangeProvinceCustomer={this.handleChangeProvinceCustomer}
          handleChangeDistrictCustomer={this.handleChangeDistrictCustomer}
          handleChangeSubdistrictCustomer={this.handleChangeSubdistrictCustomer}
          handleAddCustomer={this.handleAddCustomer}
        />
        <SimpleHeader name="Form Order" parentName="Transaksi" parentLink="/orders" />
        <Container className="mt--6" fluid>
          <CustomerForm
            {...this.state}
            toggleModal={this.toggleModal}
            orderDateRef={this.orderDateRef}
            // customer ref
            customerSelectRef={this.customerSelectRef}
            invoiceNumberRef={this.invoiceNumberRef}
            shippingAddressNameRef={this.shippingAddressNameRef}
            shippingAddressPhoneRef={this.shippingAddressPhoneRef}
            shippingAddressPhoneAltRef={this.shippingAddressPhoneAltRef}
            shippingAddressAddressRef={this.shippingAddressAddressRef}
            shippingAddressProvinceRef={this.shippingAddressProvinceRef}
            shippingAddressDistrictRef={this.shippingAddressDistrictRef}
            shippingAddressSubdistrictRef={this.shippingAddressSubdistrictRef}
            shippingAddressPotalCodeRef={this.shippingAddressPotalCodeRef}
            npwpImageRef={this.npwpImageRef}
            // load customer
            loadCustomerOptions={this.loadCustomerOptions}
            // event handling
            setNpwp={this.setNpwp}
            removeNpwp={this.removeNpwp}
            handleChangeFakturPajak={this.handleChangeFakturPajak}
            handleChangePlatform={this.handleChangePlatform}
            handleInputCustomerChange={this.handleInputCustomerChange}
            shippingAddressChange={this.shippingAddressChange}
            handleChangeProvince={this.handleChangeProvince}
            handleChangeDistrict={this.handleChangeDistrict}
            handleChangeSubdistrict={this.handleChangeSubdistrict}
            handleChange={this.handleChange}
            handleInvoiceChange={this.handleInvoiceChange}
          />
          <ProductForm
            {...this.state}
            toggleModal={this.toggleModal}
            // ref
            productSelectRef={this.productSelectRef}
            courierSelectRef={this.courierSelectRef}
            serviceSelectRef={this.serviceSelectRef}
            insuranceFeeRef={this.insuranceFeeRef}
            nameSelectRef={this.nameSelectRef}
            ongkirCostRef={this.ongkirCostRef}
            // product
            loadProductOptions={this.loadProductOptions}
            getProductName={this.getProductName}
            // counting
            countTotal={this.countTotal}
            countSubtotal={this.countSubtotal}
            countInsuranceFee={this.countInsuranceFee}
            countWeight={this.countWeight}
            countGrossTotal={this.countGrossTotal}
            countPPN={this.countPPN}
            // event handling
            handleChange={this.handleChange}
            handleChangeProductVariant={this.handleChangeProductVariant}
            handleRemoveProduct={this.handleRemoveProduct}
            handleInputProductChange={this.handleInputProductChange}
            handleChangeCourier={this.handleChangeCourier}
            handleChangeCourierService={this.handleChangeCourierService}
            setRefRow={this.setRefRow}
            removeRow={this.removeRow}
            handleChangeCourierName={this.handleChangeCourierName}
            changeInsurance={this.changeInsurance}
            changeBonus={this.changeBonus}
          />

          <PaymentForm
            {...this.state}
            // customer ref
            npwpImageRef={this.npwpImageRef}
            customerSelectRef={this.customerSelectRef}
            orderDateRef={this.orderDateRef}
            invoiceNumberRef={this.invoiceNumberRef}
            shippingAddressNameRef={this.shippingAddressNameRef}
            shippingAddressPhoneRef={this.shippingAddressPhoneRef}
            shippingAddressPhoneAltRef={this.shippingAddressPhoneAltRef}
            shippingAddressAddressRef={this.shippingAddressAddressRef}
            shippingAddressProvinceRef={this.shippingAddressProvinceRef}
            shippingAddressDistrictRef={this.shippingAddressDistrictRef}
            shippingAddressSubdistrictRef={this.shippingAddressSubdistrictRef}
            shippingAddressPotalCodeRef={this.shippingAddressPotalCodeRef}
            // product ref
            productSelectRef={this.productSelectRef}
            // courier ref
            courierSelectRef={this.courierSelectRef}
            serviceSelectRef={this.serviceSelectRef}
            nameSelectRef={this.nameSelectRef}
            ongkirCostRef={this.ongkirCostRef}
            // payment ref
            paymentStatusRef={this.paymentStatusRef}
            paymentDateRef={this.paymentDateRef}
            paymentAmountRef={this.paymentAmountRef}
            bankRef={this.bankRef}
            // counting
            countInsuranceFee={this.countInsuranceFee}
            countTotal={this.countTotal}
            countSubtotal={this.countSubtotal}
            countWeight={this.countWeight}
            countPPN={this.countPPN}
            countGrossTotal={this.countGrossTotal}
            countGrandTotal={this.countGrandTotal}
            // payment
            handleChangePaymentStatus={this.handleChangePaymentStatus}
            resetEvidenceImagePasteFile={this.resetEvidenceImagePasteFile}
            // default handling
            handleChange={this.handleChange}
            // alert
            errorAlert={this.errorAlert}
            successAlert={this.successAlert}
            dangerAlert={this.dangerAlert}
            setWarningState={this.setWarningState}
            rowProductRef={this.rowProductRef}
            platformRef={this.platformRef}
          />
        </Container>
      </>
    );
  }
}

export default FormOrder;
