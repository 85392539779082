import { request } from '../config';

// versi function
function CouponService() {
  const exportDataCoupon = async () => {
    const response = await request.get(`/coupon/export`);
    return response.data;
  };

  return {
    exportDataCoupon
  };
}

export default CouponService();
