import { request } from '../config';

// versi function
function FlowService() {
  /**
   *
   * @param {Object} data order data
   * @param {number} data.order_detail_id flow id
   * @param {number} data.status_type_id id next status order (status.pos_target.id)
   * @param {string} data.description order note
   */
  const forward = async data => {
    const response = await request.post(`/flow/forward`, data);
    return response;
  };

  const moveExcellentToGreat = async data => {
    const response = await request.put(`/flow/move-to-great`, data);
    return response;
  };

  /**
   *
   * @param {number} flowId order flow id
   */
  const scan = async flowId => {
    const data = await request.post('/flow/scan', {
      flow_id: flowId
    });

    return data;
  };

  return {
    forward,
    scan,
    moveExcellentToGreat
  };
}

export default FlowService();
