import React, { useState } from 'react';
import { useEffect } from 'react';
import { Container, Row, Card, CardHeader, Col, CardBody, Button } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Editor } from '@tinymce/tinymce-react';
import PagesManagementService from '../../../services/PagesManagementService';

const API_URL = process.env.REACT_APP_API_URL;

function RefundPolicy(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await PagesManagementService.detail(props.pageId);

      console.log(response);
      if (response.status === 200) {
        setValue(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleEditorChange = (content, editor) => {
    setValue(content);
  };

  async function handleSubmit() {
    try {
      await PagesManagementService.update(props.pageId, {
        content: value,
        title: props.title
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <SimpleHeader name="Refund Policy" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Syarat Pengajuan Refund</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Editor
                  apiKey="qg8o1y5w8gjays6rktt8924b8kdvo8har02ajwpdfkavk42d"
                  initialValue={value}
                  value={value}
                  init={{
                    // init_instance_callback: editorInit,
                    images_upload_handler: async function(blobInfo, success, failure, progress) {
                      const xhr = new XMLHttpRequest();
                      xhr.open('POST', `${API_URL}/api/v1/upload/pages/refund`);
                      xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);

                      xhr.upload.onprogress = function(e) {
                        progress((e.loaded / e.total) * 100);
                      };

                      xhr.onload = function() {
                        let json;

                        if (xhr.status < 200 || xhr.status >= 300) {
                          failure('HTTP Error: ' + xhr.status);
                          return;
                        }

                        const response = {
                          location: JSON.parse(xhr.responseText).data[0]
                        };
                        json = response;

                        if (!json || typeof json.location != 'string') {
                          failure('Invalid JSON: ' + xhr.responseText);
                          return;
                        }

                        success(json.location);
                      };

                      xhr.onerror = function() {
                        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                      };

                      const formData = new FormData();
                      formData.append('file', blobInfo.blob(), blobInfo.filename());

                      xhr.send(formData);
                    },
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: `undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | image help`
                  }}
                  onEditorChange={handleEditorChange}
                />
                <Button onClick={handleSubmit} color="primary" className="mt-3">
                  Simpan
                </Button>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default RefundPolicy;
