import React, { useState, useEffect } from 'react';
import TestimonialService from '../../../../services/TestimonialService';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import { Container, Row, Card, CardHeader, Col, Button, CardBody } from 'reactstrap';
import Can from '../../../../config/Can';
import { Link } from 'react-router-dom';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ability from '../../../../config/ability';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
const { SearchBar } = Search;

function TableTestimonial(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getData();
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  async function getData() {
    try {
      const response = await TestimonialService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText);

      if (response.status === 200) {
        setData(response.data);
        setTotalSize(response.recordsFiltered);
        setPage(response.page);
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  async function onChangeStatus(id) {
    try {
      const response = await TestimonialService.changeStatus(id);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Status berhasil diubah',
          onClose: () => getData()
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi kesalahan',
        text: 'Terjadi kesalahan saat mengubah status banner slide'
      });
    }
  }

  return (
    <>
      <SimpleHeader name="Testimoni" parentName="Manajemen Web" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Testimoni</h3>
                  </Col>
                  <Can I="create" a="Testimonial">
                    <Col className="text-right" xs="6">
                      <Link to="/testimonials/add">
                        <Button className="btn-round btn-icon" color="primary" id="tooltip443412080" size="sm">
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Tambah</span>
                        </Button>
                      </Link>
                    </Col>
                  </Can>
                </Row>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'no',
                      text: 'Nomor',
                      sort: false
                    },
                    {
                      dataField: 'review',
                      text: 'Testimoni',
                      sort: false
                    },
                    {
                      dataField: 'youtube_id',
                      text: 'Youtube Testimoni',
                      sort: false,
                      formatter: cell => {
                        return (
                          <a href={`https://youtube.com/watch?v=${cell}`} target="_blank" rel="noopener noreferrer">
                            {`https://youtube.com/watch?v=${cell}`}
                          </a>
                        );
                      }
                    },
                    {
                      dataField: 'name',
                      text: 'Nama',
                      sort: false
                    },
                    {
                      dataField: 'profession',
                      text: 'Profesi',
                      sort: false
                    },
                    {
                      dataField: 'is_active',
                      text: 'Status Aktif',
                      sort: true,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        const btnClassName = row.is_active ? 'fas fa-check' : 'fas fa-times';
                        return (
                          <>
                            {ability.can('update', 'Testimonial') ? (
                              <Button size="sm" onClick={() => onChangeStatus(row.id)}>
                                <i className={btnClassName}></i>
                              </Button>
                            ) : (
                              <i className={btnClassName}></i>
                            )}
                          </>
                        );
                      }
                    },
                    {
                      dataField: 'action',
                      text: 'Aksi',
                      sort: false,
                      isDummyField: true,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        return (
                          <>
                            <Can I="update" a="Testimonial">
                              <Button
                                color="warning"
                                type="button"
                                size="sm"
                                outline
                                onClick={() => props.history.push('/testimonials/' + row.id)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-edit" />
                                </span>
                                <span className="btn-inner--text"> Ubah</span>
                              </Button>
                            </Can>{' '}
                            <Can I="delete" a="Testimonial">
                              <Button
                                color="danger"
                                type="button"
                                size="sm"
                                outline
                                onClick={() => {
                                  Swal.fire({
                                    icon: 'warning',
                                    title: `Apakah anda yakin akan menghapus gambar ini?`,
                                    cancelButtonText: 'Batal',
                                    confirmButtonColor: 'red',
                                    cancelButtonColor: 'green',
                                    showConfirmButton: true,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showCancelButton: true,
                                    showCloseButton: true
                                  }).then(async confirm => {
                                    if (confirm.value) {
                                      try {
                                        const response = await TestimonialService.remove(row.id);
                                        if (response.status === 200) {
                                          Swal.fire({
                                            icon: 'success',
                                            text: `Testimonial berhasil dihapus`
                                          });
                                          setData(data.filter(obj => obj.id !== row.id));
                                        }
                                      } catch (error) {
                                        Swal.fire({
                                          icon: 'error',
                                          title: 'terjadi kesalahan',
                                          text: error.message
                                        });
                                      }
                                    }
                                  });
                                }}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-trash" />
                                </span>
                                <span className="btn-inner--text"> Hapus</span>
                              </Button>
                            </Can>
                          </>
                        );
                      }
                    }
                  ]}
                  search
                >
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar delay={500} className="form-control-sm" placeholder="" {...props.searchProps} />
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TableTestimonial;
