import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Modal, Row, Label, Input, ModalBody, Col, Button } from 'reactstrap'

import Swal from 'sweetalert2';
import LeadsService from '../../../../services/LeadsService';

const FormLeadsReason = ({ isOpen, toggle, setDetailModal, getDataAfterUpdate }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    getDataAfterUpdate();
  }, [])

  const submitForm = e => {
    e.preventDefault();

    const data = {
      name
    }

    LeadsService.addLeadsReason(data)
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Alasan Berhasil Ditambahkan',
            onClose: setDetailModal(false)
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => toggle('detailModal')} size="lg">
        <div className="modal-header">
          <h5 className="modal-title" id="detailModalLabel">
            Input Treatment
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDetailModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody>
          <Form onSubmit={submitForm}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="name">
                    Alasan
                  </Label>
                  <Input
                    required
                    placeholder="Alasan Belum Beli"
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mt-4">
              <Button color="primary" className="btn" type="submit">
                Simpan
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FormLeadsReason