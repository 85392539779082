import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table
} from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';
import ReportService from '../../../services/ReportService';

import './AdvertiserReport.scss';

// import Can from '../../../config/Can';

import moment from 'moment';
import 'moment/locale/id';
import OrderService from '../../../services/OrderService';
moment.locale('id');

function AdvertiserReport() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unitOptions, setUnitOptions] = useState([]);
  const [picOptions, setPicOptions] = useState([]);
  const [unitId, setUnitId] = useState(undefined);
  const [pic, setPic] = useState(undefined);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [period, setPeriod] = useState('');

  const [data, setData] = useState(null);

  useEffect(() => {
    getUnitOptions();
    getPeriodOptions();
  }, []);

  const getUnitOptions = () => {
    setLoading(true);
    OrderService.getUnitOptions()
      .then(({ data }) => setUnitOptions(data))
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeUnit = e => {
    const unit_id = e.target.value;
    setUnitId(unit_id);
    if (unit_id) {
      getPicOptions(unit_id);
    } else {
      setPicOptions([]);
      setPic('');
    }
  };

  const getPeriodOptions = async () => {
    try {
      const res = await ReportService.getAdvertiserPeriodOptions();
      if (res.status === 200) {
        const responseData = res.data.data;
        setPeriodOptions(responseData);
        setPeriod(responseData[0].value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPicOptions = unitId => {
    setLoading(true);
    ReportService.getCsList({ unit_id: unitId })
      .then(({ data: res }) => {
        setPicOptions(res.data);
        setPic('');
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = async (pic, period) => {
    setError('');

    setLoading(true);

    const data = {
      unit_id: unitId,
      user_id: pic,
      period
    };

    try {
      const response = await ReportService.getAdvertiserReport(data);

      if (response.status === 200) {
        setLoading(false);
        setData(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleExportData = async () => {
    setLoading(true);
    try {
      const data = {
        unit_id: unitId,
        user_id: pic,
        period
      };

      const res = await ReportService.exportAdvertiserReport(data);

      if (res.status === 200) {
        setLoading(false);
        window.open(res.data.data.url, '_blank');
      }
    } catch (error) {
      setLoading(false);
      console.log();
    }
  };

  const toRupiah = nominal => {
    return nominal.toLocaleString('id', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    });
  };

  return (
    <>
      {!!loading && <Loader />}
      <SimpleHeader name="Advertiser" parentName="Laporan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cari Data Laporan Advertiser</h3>
              </CardHeader>
              <CardBody>
                {error && (
                  <Alert color="danger">
                    <ul>
                      <li>{error}</li>
                    </ul>
                  </Alert>
                )}
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    setLoading(true);

                    handleSearch(pic, period);
                  }}
                >
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="unit_id">Unit</label>
                        <Input id="unit_id" type="select" name="unit_id" value={unitId} onChange={handleChangeUnit}>
                          <option value="">-- Semua Unit --</option>
                          {unitOptions.map((unit, key) => (
                            <option key={key} value={unit.value}>
                              {unit.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="pic">List PIC</label>
                        <Input
                          type="select"
                          id="pic"
                          value={pic}
                          onChange={e => {
                            setPic(e.target.value);
                          }}
                        >
                          <option value="">--- Semua PIC ---</option>
                          {picOptions.map((status, i) => (
                            <option key={i} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="period">Periode</label>
                        <Input
                          type="select"
                          id="period"
                          value={period}
                          onChange={e => {
                            e.persist();
                            setError('');
                            // setDate('');
                            setPeriod(e.target.value);
                          }}
                        >
                          {/* <option value="">--- Semua PIC ---</option> */}
                          {periodOptions.map((status, i) => (
                            <option key={i} value={status.value}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button
                          onClick={async () => {
                            setUnitId('');
                            setPic('');
                            setPeriod(periodOptions[0].value);
                            setData(null);
                          }}
                          size="md"
                          disabled={loading}
                        >
                          Reset
                        </Button>
                        <Button size="md" disabled={loading} color="primary">
                          Cari
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {!!data && (
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3 className="mb-0">Laporan Advertiser</h3>
                    <Button size="sm" disabled={loading} color="success" onClick={handleExportData}>
                      Download
                    </Button>
                  </div>
                </CardHeader>

                <CardBody>
                  <Table bordered responsive>
                    <tbody>
                      <tr>
                        <td className="label-column">
                          <strong>{data.total.name}</strong>
                        </td>
                        <td></td>
                        {data.total.days.map((d, i) => {
                          return (
                            <td key={i}>
                              <strong>{d}</strong>
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>
                          <strong>Budget</strong>
                        </td>
                        <td className="number-column">{toRupiah(data.total.budget.total)}</td>
                        {data.total.budget.per_day.map((pd, i) => {
                          return (
                            <td key={i} className="number-column">
                              {toRupiah(pd)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>
                          <strong>Leads</strong>
                        </td>
                        <td className="number-column">
                          {data.total.leads.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        </td>
                        {data.total.leads.per_day.map((pd, i) => {
                          return (
                            <td key={i} className="number-column">
                              {pd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td>
                          <strong>Cost per Leads</strong>
                        </td>
                        <td className="number-column">{toRupiah(data.total.cost_per_leads.total)}</td>
                        {data.total.cost_per_leads.per_day.map((pd, i) => {
                          return (
                            <td key={i} className="number-column">
                              {toRupiah(pd)}
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td colSpan={data.total.days.length + 2}></td>
                      </tr>

                      {data.details.map((de, i) => {
                        return (
                          <Fragment key={i}>
                            <tr>
                              <td className="label-column">
                                <strong>{de.name}</strong>
                              </td>
                              <td>
                                <strong>RESULT</strong>
                              </td>
                              {data.total.days.map((d, i) => {
                                return (
                                  <td key={i}>
                                    <strong>{d}</strong>
                                  </td>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                <strong>Budget</strong>
                              </td>
                              <td className="number-column">{toRupiah(de.budget.total)}</td>
                              {de.budget.per_day.map((pd, i) => {
                                return (
                                  <td key={i} className="number-column">
                                    {toRupiah(pd)}
                                  </td>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                <strong>Leads</strong>
                              </td>
                              <td className="number-column">
                                {de.leads.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                              </td>
                              {de.leads.per_day.map((pd, i) => {
                                return (
                                  <td key={i} className="number-column">
                                    {pd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                  </td>
                                );
                              })}
                            </tr>
                            <tr>
                              <td>
                                <strong>Cost per Leads</strong>
                              </td>
                              <td className="number-column">{toRupiah(de.cost_per_leads.total)}</td>
                              {de.cost_per_leads.per_day.map((pd, i) => {
                                return (
                                  <td key={i} className="number-column">
                                    {toRupiah(pd)}
                                  </td>
                                );
                              })}
                            </tr>
                            <tr>
                              <td colSpan={data.total.days.length + 2}></td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default AdvertiserReport;
