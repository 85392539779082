import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Card, CardBody, Table } from 'reactstrap';
import ScoreboardService from '../../../services/ScoreboardService';
import { config } from './config';
am4core.useTheme(am4themes_animated);

const ChartRevenue = props => {
  const { all } = props;

  const [data, setData] = useState({
    omzet: {
      labels: [],
      datasets: []
    }
  });

  useEffect(() => {
    let isMounted = true; // add this line

    const getDataChart = async () => {
      try {
        const response = await ScoreboardService.getScoreboardChart();
        if (response.status === 200 && isMounted) {
          // check if component is still mounted
          let chart = response.data.data;

          if (chart) {
            setData({
              omzet: {
                labels: [
                  'Januari',
                  'Februari',
                  'Maret',
                  'April',
                  'Mei',
                  'Juni',
                  'Juli',
                  'Agustus',
                  'September',
                  'Oktober',
                  'November',
                  'Desember'
                ],
                datasets: [
                  {
                    label: 'Coversuper Excellent',
                    backgroundColor: config.excellent.backgroundColor,
                    borderColor: config.excellent.lineColor,
                    data: chart.excellent.datasets[0].data
                  },
                  {
                    label: 'Coversuper Great',
                    borderColor: config.great.lineColor,
                    backgroundColor: config.great.backgroundColor,
                    data: chart.great.datasets[0].data
                  },
                  {
                    label: 'Star Protection',
                    borderColor: config.stp.lineColor,
                    backgroundColor: config.stp.backgroundColor,
                    data: chart.stp.datasets[0].data
                  },
                  {
                    label: 'Emirate Car Mat',
                    borderColor: config.carmat.lineColor,
                    backgroundColor: config.carmat.backgroundColor,
                    data: chart.carmat.datasets[0].data
                  },
                  {
                    label: 'Emirate Seat Cover',
                    borderColor: config.seatcover.lineColor,
                    backgroundColor: config.seatcover.backgroundColor,
                    data: chart.seatcover.datasets[0].data
                  },
                  {
                    label: 'Karpet Force',
                    borderColor: config.force.lineColor,
                    backgroundColor: config.force.backgroundColor,
                    data: chart.force.datasets[0].data
                  }
                ]
              }
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isMounted) getDataChart();

    let interval = setInterval(() => {
      getDataChart();
    }, 1000 * 15 * 60); // 15 menit

    return function cleanup() {
      clearInterval(interval);
      isMounted = false; // set it to false on unmount
    };
  }, []);

  return (
    <>
      <Card>
        <Table>
          <tbody style={{ verticalAlign: 'middle', textAlign: 'center', color: '#fff', textTransform: 'uppercase' }}>
            <tr>
              <td
                colSpan="2"
                style={{
                  background: '#f06563',
                  borderTopRightRadius: '12px',
                  borderTopLeftRadius: '12px',
                  fontWeight: 'bolder',
                  fontSize: '1.2em',
                  padding: '5px'
                }}
              >
                REVENUE PER UNIT {all.year}
              </td>
            </tr>
          </tbody>
        </Table>
        <CardBody>
          <div className="chart" style={{ maxHeight: '100%' }}>
            <Line
              data={data.omzet}
              options={{
                // legend: { display: true, position: 'bottom' },
                responsive: true,
                interaction: {
                  mode: 'index',
                  intersect: false
                },
                stacked: false,
                scales: {
                  x: {
                    beginAtZero: true
                  },
                  y: {
                    beginAtZero: true
                  }
                },
                maintainAspectRatio: false
              }}
              width={200}
              height={150}
              id="chart-sales-dark"
              className="chart-canvas"
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ChartRevenue;
