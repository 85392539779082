import { request } from '../config';

// versi function
function CustomerService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/customers', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;
    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // Show List
  const list = async () => {
    const response = await request.get(`/customers/list`);
    return response.data;
  };

  const searchCustomer = async filter => {
    const response = await request.post(`customers/search`, filter);
    return response.data;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/customers/${id}`);
    return response.data;
  };

  // insert data
  const save = async data => {
    const response = await request.post(`/customers`, data);
    return response.data;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/customers/${id}`, data);
    return response.data;
  };

  // remove data
  const remove = async id => {
    const { data } = await request.delete(`/customers/orders/${id}`);
    return data;
  };

  const findOrderByCustomer = async id => {
    const { data } = await request.get(`/customers/history/${id}`);
    return data;
  };

  const checkCustomerExist = async phone => {
    const response = await request.post(`/customers/check`, {
      phone
    });

    return response;
  };

  const uploadEvidenceDeposit = async file => {
    const response = await request.post(`/upload/deposit/evidence`, file);
    return response;
  };

  const addDeposit = async data => {
    const response = await request.post(`/customers/balance`, data);
    return response;
  };

  const addBilling = async ({ customer_id, start_date, end_date }) => {
    const response = await request.post('/customers/billing', { customer_id, start_date, end_date });
    return response;
  };

  const verifyBilling = async (billing_id, data) => {
    const response = await request.put(`/customers/billing/${billing_id}`, data);

    return response;
  };

  const payBilling = async (billing_id, data) => {
    const response = await request.post(`/customers/billing/${billing_id}/pay`, data);

    return response;
  };

  const getDepositDatatable = async (customer_id, { page, limit, filter, start_date, end_date }) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    const params = {
      page: page > 0 ? page - 1 : 0,
      limit,
      filter,
      start_date,
      end_date
    };

    const response = await request.get(`/customers/balance/${customer_id}`, {
      params
    });
    return {
      ...response,
      data: {
        ...response.data,
        page: response.data.page + 1 // karena defaultnya index = 0
      }
    };
  };

  const getBillingDatatable = async (customer_id, { page, limit, filter, start_date, end_date }) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    const params = {
      page: page > 0 ? page - 1 : 0,
      limit,
      filter,
      start_date,
      end_date
    };

    const response = await request.get(`/customers/${customer_id}/billing`, {
      params
    });
    return {
      ...response,
      data: {
        ...response.data,
        page: response.data.page + 1 // karena defaultnya index = 0
      }
    };
  };

  const verifyDeposit = async deposit_id => {
    const response = await request.put(`/customers/balance/${deposit_id}`);
    return response;
  };

  const downloadDeposit = async (customer_id, { start_date, end_date, filter }) => {
    const response = await request.get(`/customers/balance/${customer_id}/export`, {
      params: {
        start_date,
        end_date,
        filter
      }
    });

    return response;
  };

  const uploadImageVerifyBilling = async data => {
    const response = await request.post(`/upload/billing/evidence`, data);
    return response;
  };

  async function getDetailBilling(billing_id) {
    const res = await request.get(`/customers/billing/${billing_id}`);
    return res;
  }

  async function removeBilling(billing_id) {
    const res = await request.delete(`/customers/billing/${billing_id}`);
    return res;
  }

  return {
    checkCustomerExist,
    getDatatable,
    searchCustomer,
    list,
    findById,
    save,
    update,
    remove,
    findOrderByCustomer,
    uploadEvidenceDeposit,
    addDeposit,
    getDepositDatatable,
    verifyDeposit,
    downloadDeposit,
    getBillingDatatable,
    addBilling,
    uploadImageVerifyBilling,
    verifyBilling,
    payBilling,
    getDetailBilling,
    removeBilling
  };
}

export default CustomerService();
