import { request } from "../config";

class CoverListService {
  
  /**
   * Get coverlist lists
   */
  static async list () {
    const { data: response } = await request.get('/coverlists/list');
    return response
  };

}

export default CoverListService