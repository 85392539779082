import { GET_ERRORS, CLEAR_ERRORS } from "../actions/types";

const initialState = {
  message: null,
  status: null,
  id: null
};

export default function(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case GET_ERRORS:
      return {
        message: payload.message,
        status: payload.status,
        id: payload.id
      };

    case CLEAR_ERRORS:
      return initialState;

    default:
      return state;
  }
}
