import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row, Table } from 'reactstrap';

import './ProductForm.css';
// select 2
import 'moment/locale/id';
import AsyncSelect from 'react-select/async';
import {
  CATEGORY_COVER_MOBIL_DELUXE,
  CATEGORY_COVER_MOBIL_SUPERIOR,
  CATEGORY_COVER_MOTOR,
  CATEGORY_COVER_VESPA_PRIME,
  CATEGORY_COVER_VESPA_SPORTY,
  CATEGORY_KARPET,
  CATEGORY_KARPET_BAGASI,
  COVER_TYPE_ELEGAN,
  COVER_TYPE_FANTASY,
  COVER_TYPE_SPORTY,
  COVER_TYPE_VIPER,
  TYPE_KARPET
} from '../../../constants';
import CurrencyInput from '../../../helpers/CurrencyInput';
import CoverListService from '../../../services/CoverListService';
import CoverTypeService from '../../../services/CoverTypeService';

class ProductForm extends React.Component {
  state = {
    coverTypes: [],
    coverLists: [],
    logoOptions: [
      'TANPA LOGO',
      'LOGO CUSTOM',
      'LOGO COVERSUPER',
      'LOGO MERK MOBIL',
      'LOGO MERK MOBIL + LOGO COVERSUPER'
    ],
    logoKarpet: ['LOGO COVERSUPER', 'TANPA LOGO'],
    transmissionTypes: ['Automatic', 'Manual'],
    logoKarpetBagasi: ['TANPA LOGO'],
    bonusOptions: ['PAYUNG', 'LAP MICROFIBER', 'SPIDOL ANTI BARET', 'TOOL BAG', 'BAGGAGE PROTECTOR'],
    tax: 0
  };

  additionalCostRef = React.createRef();
  ongkirCostRef = React.createRef();

  componentDidMount() {
    this.getCoverTypes();
    this.getCoverLists();
  }

  getCoverTypes = async () => {
    try {
      const coverTypes = await CoverTypeService.getLists();
      this.setState({
        coverTypes: coverTypes.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  getCoverLists = async () => {
    try {
      const coverLists = await CoverListService.list();
      this.setState({
        coverLists: coverLists.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const renderLogo = options => {
      return (
        <>
          {options.map((list, key) => (
            <option key={key} value={list}>
              {list}
            </option>
          ))}
        </>
      );
    };

    return (
      <Row>
        <Col lg="12">
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0 text-danger">Produk</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    {this.props.warning}
                    <FormGroup>
                      <AsyncSelect
                        value=""
                        id="productVariants"
                        name="product_search"
                        defaultOptions
                        placeholder="Nama Produk"
                        ref={this.props.productSelectRef}
                        loadOptions={this.props.loadProductOptions}
                        onChange={this.props.handleInputProductChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Table className="align-items-center table-flush product-name" responsive striped>
                    <thead className="thead-light">
                      <tr>
                        <th scope="column" width="15%">
                          Produk
                        </th>
                        <th scope="column">Warna</th>
                        <th scope="column">Tahun</th>
                        <th scope="column">Tipe</th>
                        <th scope="column">List</th>
                        <th scope="column">Logo</th>
                        <th scope="column">Jenis Transmisi</th>
                        <th scope="column">Harga (Rp)</th>
                        <th scope="column" width="20%">
                          Tambahan (RP)
                        </th>
                        <th scope="column">Kuantitas</th>
                        <th scope="column" width="20%">
                          Catatan
                        </th>
                        <th scope="column" className="text-right">
                          Total (Rp)
                        </th>
                        <th scope="column" width="10%">
                          Aksi
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {this.props.productVariants.length === 0 && (
                        <tr>
                          <td colSpan="13" className="text-center text-warning">
                            Belum ada Produk yang dipilih
                          </td>
                        </tr>
                      )}
                      {this.props.productVariants.map((variant, key) => {
                        if (variant.prices.length > 0) {
                          const filteredPrice = variant.prices.find(price => {
                            return price.customer_type_id === this.props.shippingAddress.customer_type_id;
                          });

                          if (filteredPrice) {
                            variant.price = filteredPrice.price - variant.discount;
                          }

                          // jika selain elegan dan single layer maka tambah harga
                          let typeWithPrice = [COVER_TYPE_FANTASY, COVER_TYPE_SPORTY, COVER_TYPE_VIPER].includes(
                            parseInt(variant.cover_type_id)
                          );

                          if (variant.cover_type_id && typeWithPrice) {
                            variant.price += 50000;
                          }
                        }

                        return (
                          <tr key={key} ref={this.props.setRefRow} id={`row-product-${key}-${variant.id}`}>
                            <td style={{ whiteSpace: 'unset' }} title="Produk">
                              {this.props.getProductName(variant)}
                            </td>
                            <td>
                              <Input
                                id={`color_id-${key}`}
                                type="select"
                                name="color_id"
                                value={variant.color_id}
                                onChange={e => {
                                  this.props.handleChangeProductVariant(e, key);
                                }}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Warna--</option>
                                {variant.available_colors.map(color => (
                                  <option key={color.id} value={color.id}>
                                    {color.name}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            <td>
                              <Input
                                id={`year-${key}`}
                                type="select"
                                name="year"
                                value={variant.year}
                                onChange={e => {
                                  this.props.handleChangeProductVariant(e, key);
                                }}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Tahun--</option>
                                {[...Array(parseInt(new Date().getFullYear()) - 1898).keys()]
                                  .map(key => key + 1900)
                                  .sort((a, b) => b - a)
                                  .map(year => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                              </Input>
                            </td>
                            <td>
                              <Input
                                id={`cover_type-${key}`}
                                type="select"
                                name="cover_type_id"
                                disabled={
                                  ![CATEGORY_COVER_MOBIL_SUPERIOR, CATEGORY_COVER_MOBIL_DELUXE].includes(
                                    variant.product.category_id
                                  ) && variant.product.category.vehicle_type_id !== TYPE_KARPET
                                }
                                value={variant.cover_type_id}
                                onChange={e => {
                                  this.props.handleChangeProductVariant(e, key);
                                }}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Tipe Cover--</option>
                                {variant.available_cover_types.map(type => (
                                  <option key={type.id} value={type.id}>
                                    {type.name}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            <td>
                              <Input
                                id={`cover_list-${key}`}
                                type="select"
                                name="cover_list_id"
                                value={variant.cover_list_id}
                                disabled={
                                  (![CATEGORY_COVER_MOBIL_SUPERIOR, CATEGORY_COVER_MOBIL_DELUXE].includes(
                                    variant.product.category_id
                                  ) &&
                                    variant.product.category.vehicle_type_id !== TYPE_KARPET) ||
                                  parseInt(variant.cover_type_id) === COVER_TYPE_ELEGAN
                                }
                                onChange={e => {
                                  this.props.handleChangeProductVariant(e, key);
                                }}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Cover List--</option>
                                {variant.available_cover_lists.map(list => (
                                  <option key={list.id} value={list.id}>
                                    {list.name}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            <td>
                              <Input
                                id={`logo-${key}`}
                                type="select"
                                name="logo"
                                disabled={!!variant.product.category.is_ready_stock}
                                value={variant.logo}
                                onChange={e => {
                                  this.props.handleChangeProductVariant(e, key);
                                }}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Logo--</option>
                                {[TYPE_KARPET].includes(variant.product.category.vehicle_type_id)
                                  ? renderLogo(this.state.logoKarpet)
                                  : renderLogo(this.state.logoOptions)}
                              </Input>
                            </td>
                            <td>
                              <Input
                                id={`transmission_type-${key}`}
                                type="select"
                                name="transmission_type"
                                disabled={variant.product.category.vehicle_type_id !== TYPE_KARPET}
                                value={variant.transmission_type}
                                onChange={e => {
                                  this.props.handleChangeProductVariant(e, key);
                                }}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Transmisi--</option>
                                {this.state.transmissionTypes.map((transmissionType, key) => (
                                  <option key={key} value={transmissionType}>
                                    {transmissionType}
                                  </option>
                                ))}
                              </Input>
                            </td>

                            <td className="text-right" title="Harga (Rp)">
                              {new Intl.NumberFormat('id-ID').format(variant.price)}
                            </td>
                            <td title="Tambahan (RP)">
                              {variant.addons.map((addon, key) => (
                                <React.Fragment key={key}>
                                  - {addon.name} ({new Intl.NumberFormat('id-ID').format(addon.price)}
                                  ) <br />
                                </React.Fragment>
                              ))}
                            </td>
                            <td className="text-right" title="Kuantitas">
                              <Input
                                readOnly={
                                  ![
                                    CATEGORY_KARPET,
                                    CATEGORY_KARPET_BAGASI,
                                    CATEGORY_COVER_MOTOR,
                                    CATEGORY_COVER_VESPA_PRIME,
                                    CATEGORY_COVER_VESPA_SPORTY
                                    // CATEGORY_COVER_MOBIL_SUPERIOR
                                  ].includes(variant.product.category_id) && !!!variant.product.category.is_ready_stock
                                }
                                onChange={e => this.props.handleChangeProductVariant(e, key)}
                                type="number"
                                min="1"
                                name="qty"
                                value={variant.qty}
                              />
                            </td>
                            <td title="Catatan">
                              <Input
                                type="textarea"
                                name="note"
                                onChange={e => this.props.handleChangeProductVariant(e, key)}
                                value={variant.note}
                                style={{ minWidth: '200px' }}
                              />
                              <small className="form-text text-muted">Catatan maksimal 200 karakter</small>
                            </td>
                            <td className="text-right" title="Total (Rp)">
                              <strong>{new Intl.NumberFormat('id-ID').format(this.props.countTotal(variant))}</strong>
                            </td>
                            <td title="Aksi">
                              {!!!variant.product.category.is_ready_stock &&
                                ![CATEGORY_KARPET, CATEGORY_KARPET_BAGASI].includes(variant.product.category_id) && (
                                  <Button
                                    style={{ margin: 0 }}
                                    color="info"
                                    size="sm"
                                    onClick={() => {
                                      this.props.handleChange('currentVariant', variant);
                                      // handle gambar dan index di gambar
                                      this.props.toggleModal('addonModal', {
                                        id: variant.id,
                                        index: key
                                      });
                                    }}
                                  >
                                    <span className="btn-inner--icon">
                                      {/* Addon / Tambahan */}
                                      <i className="fas fa-plus" />
                                    </span>
                                  </Button>
                                )}
                              &nbsp;
                              <Button
                                style={{ margin: 0 }}
                                color="danger"
                                type="button"
                                size="sm"
                                onClick={e => {
                                  this.props.removeRow(`row-product-${key}-${variant.id}`);
                                  this.props.handleRemoveProduct(e, key);
                                }}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-trash" />
                                </span>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
                <hr />
                <Row>
                  <Col xs="12" style={{ overflow: 'auto' }}>
                    <Table className="align-items-center table-flush product-price" striped>
                      <tbody>
                        {/* Subtotal */}
                        <tr>
                          <td width="50%" colSpan="8" className="text-red">
                            Subtotal
                          </td>
                          <td className="text-right">Rp</td>
                          <td width="20%" className="text-right" style={{ fontSize: '1rem' }}>
                            {new Intl.NumberFormat('id-ID').format(this.props.countSubtotal())}
                          </td>
                        </tr>
                        <tr>
                          <td width="50%" colSpan="8" className="text-red">
                            Total Berat
                          </td>
                          <td className="text-right"></td>
                          <td width="20%" className="text-right" style={{ fontSize: '1rem' }}>
                            {new Intl.NumberFormat('id-ID').format(this.props.countWeight() / 1000)} Kg
                          </td>
                        </tr>
                        {/* Ongkir */}
                        <tr>
                          <td
                            width="50%"
                            colSpan={this.props.courier.code === 'manual' ? '6' : '7'}
                            className="text-red"
                          >
                            Ongkir
                          </td>
                          <td>
                            <Input
                              innerRef={this.props.courierSelectRef}
                              type="select"
                              name="courier"
                              value={this.props.courier.code}
                              onChange={this.props.handleChangeCourier}
                              style={{ minWidth: '125px' }}
                            >
                              <option value="">--Kurir--</option>
                              <option value="manual">Input Manual</option>
                              {this.props.courierOptions.map((courier, key) => (
                                <option key={key} value={courier.courier_code}>
                                  {courier.name}
                                </option>
                              ))}
                            </Input>
                          </td>
                          {this.props.courier.code === 'manual' && (
                            <>
                              <td>
                                <Input
                                  type="text"
                                  name="name"
                                  placeholder="Nama Kurir"
                                  innerRef={this.props.nameSelectRef}
                                  value={this.props.courier.name}
                                  onChange={this.props.handleChangeCourierName}
                                  style={{ minWidth: '125px' }}
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  name="service"
                                  placeholder="Nama Service"
                                  innerRef={this.props.serviceSelectRef}
                                  value={this.props.courier.service}
                                  onChange={this.props.handleChangeCourierName}
                                  style={{ minWidth: '125px' }}
                                />
                              </td>
                            </>
                          )}
                          {this.props.courier.code !== 'manual' && (
                            <td>
                              <Input
                                type="select"
                                name="service"
                                innerRef={this.props.serviceSelectRef}
                                value={this.props.courier.service}
                                onChange={this.props.handleChangeCourierService}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Layanan--</option>
                                {this.props.courierServiceOptions.map((service, key) => (
                                  <option key={key} value={service.service}>
                                    {service.service} ({service.description})
                                  </option>
                                ))}
                              </Input>
                            </td>
                          )}

                          <td>
                            <CurrencyInput
                              type="text"
                              className="form-control"
                              name="discount"
                              ref={this.props.ongkirCostRef}
                              disabled={this.props.courier.code !== 'manual'}
                              value={this.props.courier.cost.toString()}
                              style={{ minWidth: '155px' }}
                              onChange={e => {
                                // remove class invalid
                                e.target.classList.remove('is-invalid');
                                this.props.handleChange('cost', e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        {/* Asuransi */}
                        {this.props.courier.code !== 'manual' && (
                          <tr>
                            <td width="50%" colSpan="7" className="text-red">
                              Asuransi
                            </td>
                            <td>
                              <div className="custom-control custom-checkbox">
                                <Input
                                  innerRef={this.props.insuranceFeeRef}
                                  className="custom-control-input"
                                  id={'customCheckInsurance'}
                                  type="checkbox"
                                  disabled={!this.props.courier.service}
                                  value={this.props.checkInsurance}
                                  onChange={e => {
                                    this.props.changeInsurance(e.target.value);
                                  }}
                                />
                                <label className="custom-control-label" htmlFor={'customCheckInsurance'}>
                                  +Asuransi
                                </label>
                              </div>
                            </td>
                            <td className="text-right">Rp</td>
                            <td width="20%" className="text-right" style={{ fontSize: '1rem' }}>
                              {new Intl.NumberFormat('id-ID').format(
                                this.props.checkInsurance ? this.props.countInsuranceFee() : 0
                              )}
                            </td>
                            {/* <td>
                          <CurrencyInput
                            type="text"
                            className="form-control"
                            name="discount"
                            ref={this.props.insuranceFeeRef}
                            disabled={this.props.courier.code !== 'manual'}
                            value={this.props.checkInsurance ? this.props.countInsuranceFee() : 0}
                          />
                        </td> */}
                          </tr>
                        )}
                        {/* Diskon Order */}
                        <tr>
                          <td width="50%" colSpan="9" className="text-red">
                            Diskon Order
                          </td>
                          <td>
                            <CurrencyInput
                              type="text"
                              className="form-control"
                              name="discount"
                              ref={this.additionalCostRef}
                              value={this.props.discount.toString()}
                              onChange={e => {
                                // remove class invalid
                                e.target.classList.remove('is-invalid');
                                this.props.handleChange('discount', e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        {/* Biaya Lain-lain */}
                        <tr>
                          <td width="50%" colSpan="5" className="text-red">
                            Biaya Lain-lain
                          </td>
                          <td colSpan="4">
                            <Input
                              type="text"
                              placeholder="Catatan Biaya"
                              value={this.props.additional_cost_note}
                              onChange={e => this.props.handleChange('additional_cost_note', e.target.value)}
                            />
                          </td>
                          <td>
                            <CurrencyInput
                              type="text"
                              className="form-control"
                              name="additional_cost"
                              ref={this.additionalCostRef}
                              value={this.props.additional_cost.toString()}
                              onChange={e => {
                                // remove class invalid
                                e.target.classList.remove('is-invalid');
                                this.props.handleChange('additional_cost', e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        {/* Keterangan / Bonus */}
                        <tr>
                          <td width="50%" colSpan="5" className="text-red">
                            Keterangan / Bonus
                          </td>
                          <td colSpan="4">
                            {this.state.bonusOptions.map((bonus, key) => {
                              let checked = false;

                              const bonuses = this.state.bonus;
                              if (bonuses && bonuses.includes(bonus)) {
                                checked = true;
                              }
                              return (
                                <div className="custom-control custom-checkbox" key={key}>
                                  <Input
                                    className="custom-control-input"
                                    id={'customCheckBonus' + key}
                                    type="checkbox"
                                    defaultChecked={checked}
                                    onChange={e => this.props.changeBonus(bonus, e.target.value)}
                                  />
                                  <label className="custom-control-label" htmlFor={'customCheckBonus' + key}>
                                    {bonus}
                                  </label>
                                </div>
                              );
                            })}
                          </td>
                          <td>
                            <Input
                              type="textarea"
                              placeholder="Keterangan Lainnya"
                              name="order_description"
                              rows={3}
                              value={this.props.order_description}
                              style={{ minWidth: '200px' }}
                              onChange={e => this.props.handleChange('order_description', e.target.value)}
                            />
                          </td>
                        </tr>
                        {/* Gross Total */}
                        <tr>
                          <td width="50%" colSpan="8" className="text-red">
                            Gross Total
                          </td>
                          <td className="text-right">Rp</td>
                          <td className="text-right" style={{ fontSize: '1rem' }}>
                            {new Intl.NumberFormat('id-ID').format(this.props.countGrossTotal())}
                          </td>
                        </tr>
                        {/* PPN */}
                        <tr>
                          <td width="50%" colSpan="8" className="text-red">
                            PPN {this.props.TAX_RATE * 100}%
                          </td>
                          <td className="text-right">Rp</td>
                          <td className="text-right" style={{ fontSize: '1rem' }}>
                            {new Intl.NumberFormat('id-ID').format(this.props.countPPN())}
                          </td>
                        </tr>
                        {/* Grand Total */}
                        <tr>
                          <td width="50%" colSpan="8" className="text-red">
                            Grand Total
                          </td>
                          <td className="text-right">Rp</td>
                          <td className="text-right" style={{ fontSize: '2rem' }}>
                            {new Intl.NumberFormat('id-ID').format(
                              this.props.countGrossTotal() + this.props.countPPN()
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ProductForm;
