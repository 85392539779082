import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';
import LeadsService from '../../../services/LeadsService';
import FormTreatment from './FormTreatment';

// import SearchBar from '../../../components/Search';
import Swal from 'sweetalert2';
import ExportCSVButton from '../../../components/ExportCSV';

import ability from '../../../config/ability';
import FormPurchased from './FormPurchased';
import FormUpdateLeads from './FormUpdateLeads';
import { defaultRow } from './defaultRow';
import UploadFile from './modal/UploadFile';

const TableLeads = props => {
  // const [user] = useState(props.user);

  const startDateRef = useRef();
  const endDateRef = useRef();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    isUploadFileModal: false
  });
  const [detailModal, setDetailModal] = useState(false);
  const [updateLeadsModal, setUpdateLeadsModal] = useState(false);
  const [purchasedModal, setPurchasedModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(defaultRow);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [sortField, setSortField] = useState('id');
  const [sortOrder, setSortOrder] = useState('desc');

  const [dataFilter, setDataFilter] = useState([]);
  const [filter_column, setFilterColumn] = useState('');
  const [filter_text, setFilterText] = useState('');

  const [purchased, setPurchased] = useState('');
  const purchasedType = [
    {
      value: 0,
      name: 'Belum Beli'
    },
    {
      value: 1,
      name: 'Sudah Beli'
    }
  ];

  const [contact_saved, setContactSaved] = useState('');
  const contactSavedType = [
    {
      value: 0,
      name: 'Belum Disimpan'
    },
    {
      value: 1,
      name: 'Sudah Disimpan'
    }
  ];

  const [can_be_contacted, setCanBeContacted] = useState('');
  const canBeContactType = [
    {
      value: 0,
      name: 'Tidak Dapat Dihubungi'
    },
    {
      value: 1,
      name: 'Dapat Dihubungi'
    }
  ];

  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');

  useEffect(() => {
    getData();
    getDataFilterDropdown();
  }, [page, sizePerPage, sortField, sortOrder]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await LeadsService.getDatatable(
        page,
        sizePerPage,
        start_date,
        end_date,
        filter_column,
        filter_text,
        purchased,
        contact_saved,
        can_be_contacted,
        sortField,
        sortOrder
      );

      setData(response.data);
      setTotalSize(response.recordsFiltered);
      setPage(response.page);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDataAfterUpdate = () => {
    getData();
  };

  const getDataFilterDropdown = async () => {
    try {
      setLoading(true);
      const response = await LeadsService.dropdownFilter();
      setDataFilter(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeStatusContact = async id => {
    LeadsService.changeStatusContact(id).then(() => getData());
  };

  const onChangeStatusCanBeContacted = async id => {
    LeadsService.changeCanBeContacted(id).then(() => getData());
  };

  const toggelModalPurchased = async (row = defaultRow) => {
    try {
      setPurchasedModal(!purchasedModal);
      setSelectedRow(row);
    } catch (error) {
      console.log(error);
    }
  };

  const toggelModalTreatment = async (row = defaultRow) => {
    try {
      setDetailModal(!detailModal);
      setSelectedRow(row);
    } catch (error) {
      console.log(error);
    }
  };

  const toggelModalUpdateLeads = async (row = defaultRow) => {
    try {
      setUpdateLeadsModal(!updateLeadsModal);
      setSelectedRow(row);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLeads = id => {
    Swal.fire({
      title: 'Anda Yakin?',
      text: 'Leads ini akan dihapus dan tidak dapat dipulihkan.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya',
      cancelButtonText: 'Tidak'
    }).then(result => {
      if (result.isConfirmed) {
        LeadsService.removeLeads(id).then(res => {
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Data Leads Berhasil Dihapus'
            });
          }
          getData();
        });
      }
    });
  };

  const onTableChange = async (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  const toggleModal = name => {
    setModal(prev => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <>
      {loading && <Loader />}
      <SimpleHeader name="Leads" parentName="Manajemen Leads" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Pencarian</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="start_date">Dari</label>
                      <Input
                        id="start_date"
                        type="date"
                        name="start_date"
                        innerRef={startDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={start_date}
                        onChange={e => setStartDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="end_date">Sampai</label>
                      <Input
                        id="end_date"
                        type="date"
                        name="end_date"
                        innerRef={endDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={end_date}
                        onChange={e => setEndDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="filter_column">Filter</label>
                      <Input
                        id="filter_column"
                        type="select"
                        name="filter_column"
                        value={filter_column}
                        onChange={e => setFilterColumn(e.target.value)}
                      >
                        <option value="">Semua</option>
                        {dataFilter.map((res, key) => (
                          <option key={key} value={res.value}>
                            {res.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="filter_text">&nbsp;</label>
                      <Input
                        id="filter_text"
                        type="text"
                        name="filter_text"
                        placeholder="Keyword"
                        value={filter_text}
                        onChange={e => setFilterText(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="purchased">Status Pembelian</label>
                      <Input
                        id="purchased"
                        type="select"
                        name="purchased"
                        value={purchased}
                        onChange={e => setPurchased(e.target.value)}
                      >
                        <option value="">Semua</option>
                        {purchasedType.map((status, key) => (
                          <option key={key} value={status.value}>
                            {status.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="contact_saved">Status Simpan Kontak</label>
                      <Input
                        id="contact_saved"
                        type="select"
                        name="contact_saved"
                        value={contact_saved}
                        onChange={e => setContactSaved(e.target.value)}
                      >
                        <option value="">Semua</option>
                        {contactSavedType.map((status, key) => (
                          <option key={key} value={status.value}>
                            {status.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <label htmlFor="can_be_contacted">Kontak Dapat Dihubungi</label>

                      <Input
                        id="can_be_contacted"
                        type="select"
                        name="can_be_contacted"
                        value={can_be_contacted}
                        onChange={e => setCanBeContacted(e.target.value)}
                      >
                        <option value="">Semua</option>
                        {canBeContactType.map((column, key) => (
                          <option key={key} value={column.value}>
                            {column.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-right">
                      <Button onClick={() => getData()} color="primary">
                        Cari
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Data Manajemen Leads</h3>
                  </Col>
                  <Col xs="6">
                    <Row style={{ float: 'right' }}>
                      <Link to="/leads/add">
                        <Button className="btn-round btn-icon mr-2" color="primary" id="tooltip443412080" size="sm">
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Tambah</span>
                        </Button>
                      </Link>

                      <Button
                        className="btn btn-sm"
                        onClick={() => {
                          toggleModal('isUploadFileModal');
                        }}
                        color="success"
                      >
                        Import Leads
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'first_chat',
                    text: 'Tanggal Chat Pertama',
                    sort: true,
                    formatter: cell => (
                      <>
                        {new Date(cell).toLocaleDateString('id-ID', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        })}
                      </>
                    )
                  },
                  {
                    dataField: 'dealmaker_name',
                    text: 'Dealmaker',
                    sort: true
                  },
                  {
                    dataField: 'dealmaker_phone',
                    text: 'Whatsapp CS',
                    sort: false
                  },
                  {
                    dataField: 'customer_phone',
                    text: 'No WA Konsumen',
                    sort: true
                  },
                  {
                    dataField: 'customer_name',
                    text: 'Nama Konsumen',
                    sort: true
                  },
                  {
                    dataField: 'customer_vehicle',
                    text: 'Merek/Type/Tahun Mobil',
                    sort: true
                  },
                  {
                    dataField: 'customer_vehicle_note',
                    text: 'Catatan',
                    sort: true
                  },
                  {
                    dataField: 'contact_saved',
                    text: 'Save Kontak',
                    sort: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      const btnClassName = row.contact_saved ? 'fas fa-check' : 'fas fa-times';
                      return (
                        <Button size="sm" onClick={() => onChangeStatusContact(row.id)}>
                          <i className={btnClassName} />
                        </Button>
                      );
                    }
                  },
                  {
                    dataField: 'can_be_contacted',
                    text: 'Nomor Dapat Dihubungi',
                    sort: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      const btnClassName = row.can_be_contacted ? 'fas fa-check' : 'fas fa-times';
                      return (
                        <Button size="sm" onClick={() => onChangeStatusCanBeContacted(row.id)}>
                          <i className={btnClassName} />
                        </Button>
                      );
                    }
                  },
                  {
                    dataField: 'purchased',
                    text: 'Sudah Beli ?',
                    sort: true,
                    formatter: (cell, row, rowIndex, formatExtraData) =>
                      row.purchased === 0 ? (
                        <Badge color="warning" pill>
                          Belum Beli
                        </Badge>
                      ) : (
                        <span className="badge badge-success">Sudah Beli</span>
                      )
                  },
                  {
                    dataField: 'reason',
                    text: 'Alasan Belum Beli',
                    sort: true
                  },
                  {
                    dataField: 'platform',
                    text: 'Platform',
                    sort: true
                  },
                  {
                    dataField: 'treatments',
                    text: 'Treatment yang dilakukan',
                    sort: false,
                    headerStyle: (colum, colIndex) => {
                      return { width: '200px', textAlign: 'center' };
                    },
                    csvFormatter: cell =>
                      cell
                        .map(
                          t =>
                            new Date(t.treatment_date).toLocaleDateString('id-ID', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            }) +
                            ' - ' +
                            t.treatment
                        )
                        .join(',\n'),
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <>
                          {cell.map((t, i) => (
                            <span key={i}>
                              {new Date(t.treatment_date).toLocaleDateString('id-ID', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                              })}{' '}
                              - {t.treatment}
                              <br />
                            </span>
                          ))}
                        </>
                      );
                    }
                  },
                  {
                    dataField: 'action',
                    text: 'Aksi',
                    sort: false,
                    isDummyField: true,
                    csvExport: false,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <>
                          {row.purchased ? null : (
                            <>
                              <Button
                                color="info"
                                type="button"
                                size="sm"
                                className="mb-2"
                                outline
                                onClick={() => toggelModalTreatment(row)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-plus" />
                                </span>
                                <span className="btn-inner--text"> Tambah Treatment</span>
                              </Button>
                              <br />
                              <Button
                                color="warning"
                                type="button"
                                size="sm"
                                className="mb-2"
                                outline
                                onClick={() => toggelModalUpdateLeads(row)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-edit" />
                                </span>
                                <span className="btn-inner--text"> Update Leads</span>
                              </Button>
                              <br />
                              <Button
                                color="success"
                                type="button"
                                size="sm"
                                className="mb-2"
                                outline
                                onClick={() => toggelModalPurchased(row)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-check" />
                                </span>
                                <span className="btn-inner--text"> Tandai Sudah Beli</span>
                              </Button>
                              <br />
                            </>
                          )}
                          {row.customer_phone && (
                            <>
                              <Button
                                color="success"
                                type="button"
                                size="sm"
                                className="mb-2"
                                outline
                                onClick={() => {
                                  let text =
                                    `Halo Mas/Mbak ${row.customer_name}, ` +
                                    `Perkenalkan saya ${row.dealmaker_name} dari Official Store CoverSuper Indonesia. `;

                                  let url = `https://wa.me/${row.customer_phone.replace(
                                    '+',
                                    ''
                                  )}?text=${encodeURIComponent(text)}`;

                                  window.open(url, '_blank');
                                }}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fab fa-whatsapp" />
                                </span>
                                <span className="btn-inner--text"> Whatsapp</span>
                              </Button>
                              <br />
                            </>
                          )}
                          <Button
                            color="danger"
                            type="button"
                            size="sm"
                            className="mb-2"
                            outline
                            onClick={() => deleteLeads(row.id)}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-trash" />
                            </span>
                            <span className="btn-inner--text"> Hapus</span>
                          </Button>
                        </>
                      );
                    }
                  }
                ]}
                exportCSV
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                        {ability.can('export', 'Leads') && <ExportCSVButton {...props.csvProps} />}
                      </div>
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>

      <FormTreatment
        isOpen={detailModal}
        row={selectedRow}
        toggle={toggelModalTreatment}
        setDetailModal={setDetailModal}
        getDataAfterUpdate={getDataAfterUpdate}
      />

      <FormUpdateLeads
        isOpen={updateLeadsModal}
        row={selectedRow}
        toggle={toggelModalUpdateLeads}
        setUpdateLeadsModal={setUpdateLeadsModal}
        getDataAfterUpdate={getDataAfterUpdate}
      />

      <FormPurchased
        isOpen={purchasedModal}
        row={selectedRow}
        toggle={toggelModalPurchased}
        setPurchasedModal={setPurchasedModal}
        getDataAfterUpdate={getDataAfterUpdate}
      />

      <UploadFile isOpen={modal.isUploadFileModal} toggle={toggleModal} getDataAfterUpdate={getDataAfterUpdate} />
    </>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(TableLeads);
