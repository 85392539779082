import moment from 'moment';
import 'moment/locale/id';
import React from 'react';
import Swal from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import Swal2 from 'sweetalert2';

import * as Constants from '../../../constants';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import FlowService from '../../../services/FlowService';
import OrderService from '../../../services/OrderService';

import Loader from '../../../components/Loader/Loader';
import { API_URL, request } from '../../../config';
import Can from '../../../config/Can';
import { PAYMENT_STATUS_COD_MP, PAYMENT_STATUS_MARKETPLACE } from '../../../constants';
import CouponService from '../../../services/CouponService';
import NextStatusButton from './NextStatusButton';
import './TableOrder.css';
import UbahButton from './components/UbahButton';
import ComplaintDetail from './modal/ComplaintDetail';
import ComplaintInputModal from './modal/ComplaintInputModal';
import ComplaintProcessModal from './modal/ComplaintProcessModal';
import CouponModal from './modal/CouponModal';
import DetailModal from './modal/DetailModal';
import EvidenceModal from './modal/EvidenceModal';
import InputRecipeModal from './modal/InputRecipeModal';
import MoveToGreatModal from './modal/MoveToGreatModal';
import PaymentLinkModal from './modal/PaymentLinkModal';
import PaymentVerificationModal from './modal/PaymentVerificationModal';
import PicModal from './modal/PicModal';
import ProcessRefundModal from './modal/ProcessRefundModal';
import RefundDetail from './modal/RefundDetail';
import RefundModal from './modal/RefundModal';
import ResiModal from './modal/ResiModal';
import SetLunasBulkModal from './modal/SetLunasBulkModal';
import SetLunasModal from './modal/SetLunasModal';
import StatusModal from './modal/StatusModal';

let baseURL;

if (API_URL === 'https://staging.coversuper.com') {
  baseURL = 'https://test.coversuper.com';
} else if (API_URL === 'https://api.coversuper.com') {
  baseURL = 'https://coversuper.com';
}

moment.locale('id');

// const { SearchBar, ClearSearchButton } = Search;

class TableOrder extends React.Component {
  state = {
    user: this.props.user,
    alert: null,
    detailModal: false,
    paymentVerificationModal: false,
    setLunasModal: false,
    couponModal: false,
    detail: {
      id: '',
      qty: '',
      note: '',
      current_desc: '',
      estimation: '',
      estimation_desc: '',
      order: {
        id: '',
        order_number: '',
        customer_id: '',
        customer_type_id: '',
        supplier_id: '',
        platform: '',
        courier_code: '',
        courier_name: '',
        service_code: '',
        service_name: '',
        resi: null,
        order_description: null,
        created_at: '',
        shipping_address: {
          name: '',
          email: '',
          phone: '',
          phone_alt: '',
          address: '',
          postal_code: '',
          province: { name: '' },
          district: { name: '', type: '' },
          subdistrict: { name: '' }
        },
        customer: { name: '', email: '', phone: '' },
        customer_type: { name: '' },
        customer_service: { name: '' },
        payment_status: { name: '' },
        supplier: { name: '' }
      },
      addons: [{ name: '' }],
      images: [{ image: '' }],
      product_name: '',
      color: {
        name: ''
      },
      color_id: 0,
      logo: '',
      year: '',
      status: { name: '' },
      pic: { name: '' },
      histories: [
        {
          created_at: '',
          description: null,
          status: { name: '' },
          pic: { name: '' }
        }
      ]
    },
    paymentStatusOptions: [],
    statusTypeOptions: [],
    filterByOptions: [],
    filter_column: '',
    filter_text: '',
    start_date: '',
    end_date: '',
    status_type_id: '',
    payment_status_id: '',
    unit_id: '',
    unitOptions: [
      { value: 1, name: 'Great' },
      { value: 2, name: 'Excellent' },
      { value: 3, name: 'Force' }
    ],
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortField: null,
    sortOrder: undefined,
    searchText: '',
    loading: false,
    isInputRecipeModalShow: false,
    isInputRefundModalShow: false,
    isInputProcessRefundModal: false,
    isSetLunasBulkModalShow: false,
    isRefundDetailModalShow: false,
    isComplaintInputModalShow: false,
    isComplaintProcessModalShow: false,
    isComplaintDetailModalShow: false,
    isPaymentLinkModalShow: false,
    linkUrl: '',
    isPicModalOpen: false,
    isResiModalShow: false,
    isStatusModalOpen: false,
    moveToGreatModal: false,
    selectedRow: null,
    evidenceModal: false,
    payment_histories: []
  };

  columns = [
    {
      dataField: 'id',
      text: 'ID PRODUKSI',
      sort: false,
      classes: 'freeze',
      headerClasses: 'header-freeze'
    },
    {
      dataField: 'order.order_number',
      text: 'Nomor Order',
      sort: false,
      classes: 'freeze',
      headerClasses: 'header-freeze',
      style: { whiteSpace: 'nowrap' }
    },
    {
      dataField: 'order.payment_status.name',
      text: 'Pembayaran',
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <span className={'badge badge-' + (cell === 'Lunas' ? 'success' : 'warning')}>{cell}</span>
      )
    },
    {
      dataField: 'order.unit.name',
      text: 'Unit/Team',
      sort: false
    },
    {
      dataField: 'order.customer_service.name',
      text: 'Dealmaker',
      sort: false,
      formatter: (col, row, rowIndex, formatExtraData) => {
        return (
          <>
            {col}
            <Can I="update" a="PIC">
              {((row.order.cs === Constants.CS_COVERSUPER_SELFSERVICE && !row.order.voucher) ||
                [Constants.ROLE_ADMIN, Constants.ROLE_ADMIN_INPUT].includes(this.props.user.role.id)) && (
                <>
                  <br />
                  <Button
                    onClick={() => this.toggleModal(row, 'isPicModalOpen')}
                    color="info"
                    size="sm"
                    className="mb-2"
                  >
                    Ganti PIC
                  </Button>
                </>
              )}
            </Can>
          </>
        );
      }
    },
    {
      dataField: 'affiliate_name',
      text: 'Affiliate',
      sort: false
    },
    {
      dataField: 'order.customer.name',
      text: 'Pemesan',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'order.customer.phone',
      text: 'No Telp Pemesan',
      sort: false
    },
    {
      dataField: 'order.shipping_address.name',
      text: 'Penerima',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'order.shipping_address.phone',
      text: 'No Telp Penerima',
      sort: false,
      formatter: col => {
        let phone;
        if (col[0] === '0') {
          phone = col.slice(1);
        }

        if (col.slice(0, 2) === '62') {
          phone = col.slice(2);
        }

        if (col.slice(0, 3) === '+62') {
          phone = col.slice(3);
        }
        return <>{`+62${phone}`}</>;
      }
    },
    {
      dataField: 'order.platform',
      text: 'Platform',
      sort: false
    },
    {
      dataField: 'order.invoice_number',
      text: 'Invoice Marketplace',
      sort: false
    },
    {
      dataField: 'product.category.name',
      text: 'Produk',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'variant.size',
      text: 'Size',
      sort: false
    },
    {
      dataField: 'color.name',
      text: 'Warna',
      sort: false
    },
    {
      dataField: 'product.manufacturer.name',
      text: 'Pabrikan',
      sort: false
    },
    {
      dataField: 'product.name',
      text: 'Merek/Tipe',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'courier',
      text: 'Jasa Pengiriman',
      sort: false,
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => row.order.courier_name + ' - ' + row.order.service_code
    },
    {
      dataField: 'addons',
      text: 'Tambahan Aksesoris',
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell.map(addon => {
          return (
            <>
              - {addon.name}
              <br />
            </>
          );
        });
      }
    },
    {
      dataField: 'note',
      text: 'Catatan Produk',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'order.order_description',
      text: 'Bonus / Keterangan',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'pic.name',
      text: 'PIC',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'current_desc',
      text: 'Catatan PIC',
      sort: false,
      classes: 'white-space-normal'
    },
    {
      dataField: 'estimation',
      text: 'Estimasi Selesai',
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) =>
        row.estimation ? moment(row.estimation).format('dddd, DD MMM YYYY') : ''
    },
    {
      dataField: 'estimation_desc',
      text: 'Catatan Estimasi',
      sort: false
    },
    {
      dataField: 'status.name',
      text: 'Status',
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <>
          <span className="badge badge-default mb-2">{row.status.name}</span>
          {row.status.id > Constants.STATUS_UNVERIFIED && row.status.id !== Constants.STATUS_SENT && (
            <Can I="update" a="Status">
              <br />
              <Button
                onClick={() => this.toggleModal(row, 'isStatusModalOpen')}
                color="warning"
                size="sm"
                className="mb-2"
              >
                <span className="btn-inner--text"> Ganti Status</span>
              </Button>
            </Can>
          )}
        </>
      )
    },
    {
      dataField: 'flow_actions',
      text: 'Next Status',
      sort: false,
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <NextStatusButton
            hideAlert={this.hideAlert}
            row={row}
            toggleModal={this.toggleModal}
            toggelModalWithDetail={this.toggelModalWithDetail}
            showInputEstimationAlert={this.showInputEstimationAlert}
            showConfirmActionAlert={this.showConfirmActionAlert}
            handleCancel={this.handleCancel}
            handlePending={this.handlePending}
            handleResume={this.handleResume}
            handleReupload={this.handleReupload}
          />
        );
      }
    },
    {
      dataField: 'action',
      text: 'Aksi',
      sort: false,
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // const currentDate = moment(row.created_at).format('LL');
        // const conditionCoupon = currentDate >= '11 April 2023' && currentDate <= '30 April 2023';

        const { PAYMENT_STATUS_UNPAID, PAYMENT_STATUS_CANCEL, PAYMENT_STATUS_REFUND } = Constants;
        return (
          <>
            <Button
              color="info"
              type="button"
              size="sm"
              className="mb-2"
              // outline
              onClick={() => this.toggelModalWithDetail(row, 'detailModal')}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-info-circle" />
              </span>
              <span className="btn-inner--text"> Detail</span>
            </Button>
            <br />

            {[Constants.STATUS_SENT].includes(row.status.id) && (
              <>
                <Button
                  color="info"
                  type="button"
                  size="sm"
                  className="mb-2"
                  // outline
                  onClick={() => {
                    const link = `${
                      process.env.REACT_APP_API_URL.split('//')[1].split('.')[0] === 'staging'
                        ? 'https://test.coversuper.com'
                        : 'https://coversuper.com'
                    }/review?order_number=${row.order.order_number}&resi=${row.order.resi}`;

                    this.setState({
                      isPaymentLinkModalShow: true,
                      linkUrl: link
                    });
                  }}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-link" />
                  </span>
                  <span className="btn-inner--text">&nbsp;Link Review</span>
                </Button>
                <br />
              </>
            )}
            {[Constants.PAYMENT_STATUS_DP, PAYMENT_STATUS_COD_MP, PAYMENT_STATUS_MARKETPLACE].includes(
              row.payment_status_id
            ) && (
              <Can I="update" a="Set Lunas">
                <Button
                  color="success"
                  type="button"
                  size="sm"
                  className="mb-2"
                  // outline
                  onClick={() => {
                    this.toggelModalWithDetail(row, 'setLunasModal');
                  }}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-money-bill-alt" />
                  </span>
                  <span className="btn-inner--text"> Set Lunas</span>
                </Button>
                <br />
              </Can>
            )}
            {row.order.tax_invoice && (
              <Can I="read" a="Tax Invoice">
                <Button
                  color="success"
                  type="button"
                  size="sm"
                  className="mb-2"
                  outline
                  onClick={() => window.open(row ? row.order.tax_invoice : '', '_blank')}
                >
                  <span className="btn-inner--text"> Faktur Pajak</span>
                </Button>
                <br />
              </Can>
            )}
            <Button
              color="info"
              type="button"
              size="sm"
              className="mb-2"
              // outline
              onClick={() => window.open(`${baseURL}/order-tracking/${row.order.order_number}`, '_blank')}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-search" />
              </span>
              <span className="btn-inner--text"> Lacak Order</span>
            </Button>
            <br />
            {!!row.order.refund && !!row.order.refund.approval_status_id && (
              <Can I="read" a="Refund">
                <Button
                  color="success"
                  size="sm"
                  className="mb-2"
                  onClick={() => this.toggelModalWithDetail(row, 'isRefundDetailModalShow')}
                >
                  Refund Detail
                </Button>
                <br />
              </Can>
            )}
            {!!row.is_complaint && (
              <>
                <Can I="read" a="Complaint">
                  <Button
                    color="success"
                    size="sm"
                    className="mb-2"
                    onClick={() => this.toggleModal(row, 'isComplaintDetailModalShow')}
                  >
                    Complaint Detail
                  </Button>
                  <br />
                </Can>
              </>
            )}
            {![PAYMENT_STATUS_UNPAID, PAYMENT_STATUS_CANCEL, PAYMENT_STATUS_REFUND].includes(
              row.order.payment_status.id
            ) &&
              row.order.verification_type === 'manual' && (
                <Can I="read" an="Order Evidence">
                  <span>
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      className="mb-2"
                      onClick={() => this.toggelModalWithDetail(row, 'evidenceModal')}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-file-image" />
                      </span>
                      <span className="btn-inner--text">&nbsp;Bukti Transfer</span>
                    </Button>
                    <br />
                  </span>
                </Can>
              )}
            {row.status.id !== Constants.STATUS_CANCEL && (
              <Can I="read" an="Invoice">
                <Button
                  color="success"
                  type="button"
                  size="sm"
                  className="mb-2"
                  // outline
                  onClick={() => this.props.history.push('/invoice/' + row.order.order_number)}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-receipt" />
                  </span>
                  <span className="btn-inner--text"> Invoice</span>
                </Button>
                <br />
              </Can>
            )}

            <Button
              color="success"
              type="button"
              size="sm"
              className="mb-2"
              // outline
              onClick={() => {
                let text =
                  `Halo Mas/Mbak ${row.order.customer.name}, ` +
                  `Perkenalkan saya ${row.order.customer_service.name} dari Official Store CoverSuper Indonesia. ` +
                  `Untuk orderannya sudah masuk ke sistem kami dengan nomor order ${row.order.order_number}. ` +
                  `Untuk cek progress orderan melalui web kami di https://coversuper.com/order-tracking/${row.order.order_number}`;

                let url = `https://wa.me/${row.order.customer.phone.replace('+', '')}?text=${encodeURIComponent(text)}`;

                window.open(url, '_blank');
              }}
            >
              <span className="btn-inner--icon">
                <i className="fab fa-whatsapp" />
              </span>
              <span className="btn-inner--text"> Whatsapp</span>
            </Button>
            <br />

            {row.estimation && (
              <Can I="update" an="Order Estimation">
                <Button
                  color="warning"
                  type="button"
                  size="sm"
                  className="mb-2"
                  // outline
                  onClick={() => this.showInputEstimationAlert(row.id, null, true)}
                >
                  <span className="btn-inner--icon">
                    <i className="far fa-calendar-check" />
                  </span>
                  <span className="btn-inner--text"> Ubah Estimasi</span>
                </Button>
                <br />
              </Can>
            )}
            {row.status.id >= Constants.STATUS_RESEP && row.status.id !== Constants.STATUS_CANCEL && (
              <Can I="print" a="Shipping Label">
                <Button
                  color="primary"
                  type="button"
                  size="sm"
                  className="mb-2"
                  // outline
                  onClick={() => window.open(`/label/${row.id}`, '_blank')}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-print" />
                  </span>
                  <span className="btn-inner--text"> Cetak Alamat & Resep</span>
                </Button>
                <br />
              </Can>
            )}
            {row.status.id >= Constants.STATUS_RESEP &&
              row.status.id !== Constants.STATUS_CANCEL &&
              row.order.invoice_number && (
                <Can I="print" a="Invoice">
                  <Button
                    color="info"
                    type="button"
                    size="sm"
                    className="mb-2"
                    // outline
                    onClick={() => window.open(`/print-invoice/${row.id}`, '_blank')}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-file-invoice" />
                    </span>
                    <span className="btn-inner--text"> Cetak Invoice Marketplace</span>
                  </Button>
                  <br />
                </Can>
              )}
            <UbahButton history={this.props.history} row={row} userRole={this.props.user.role.id} />
            {((row.status.id < Constants.STATUS_ANTRI_PRODUKSI && row.status.id !== Constants.STATUS_CANCEL) ||
              [Constants.ROLE_ADMIN, Constants.ROLE_OPERASIONAL].includes(this.props.user.role.id)) && (
              <Can I="delete" an="Order">
                <Button
                  color="danger"
                  type="button"
                  size="sm"
                  className="mb-2"
                  // outline
                  onClick={() => this.showConfirmDeleteAlert(row.order_id)}
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-trash" />
                  </span>
                  <span className="btn-inner--text"> Hapus</span>
                </Button>
              </Can>
            )}
          </>
        );
      }
    }
  ];

  interval; // handle order

  rowStyle = (row, rowIndex) => {
    let color = '';
    switch (row.order.platform) {
      default:
        color = 'white';
    }
    return { backgroundColor: color };
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCancel = (orderId, reason) => {
    request
      .put(`/orders/cancel/${orderId}`, {
        reason
      })
      .then(() => {
        this.getData();
      })
      .catch(e => {
        console.log(e);
      });
  };

  handlePending = (flowID, reason, previous_status) => {
    request
      .put(`/orders/pending/${flowID}`, {
        reason,
        previous_status
      })
      .then(() => {
        Swal2.fire({ icon: 'success', title: 'Berhasil' });
        this.getData();
      })
      .catch(e => {
        console.log(e);
      });
  };

  handleResume = flowID => {
    request
      .put(`/orders/resume/${flowID}`)
      .then(() => {
        Swal2.fire({ icon: 'success', title: 'Berhasil' });
        this.getData();
      })
      .catch(e => {
        console.log(e);
      });
  };

  handleReupload = row => {
    request
      .put(`/flow/remove-evidence/${row.order_id}`)
      .then(() => {
        this.getData();
        window.open(
          `${baseURL}/payment-link?order_number=${row.order.order_number}&transaction_token=${row.order.transaction_token}`,
          '_blank'
        );
      })
      .catch(e => {
        console.log(e);
      });
  };

  toggleSetLunasBulkModal = () => {
    this.getData();

    this.setState({
      isSetLunasBulkModalShow: !this.state.isSetLunasBulkModalShow
    });
  };

  toggelModalWithDetail = async (row, state) => {
    try {
      this.setState({ loading: true });
      if (this.state[state]) {
        this.setState(
          {
            [state]: !this.state[state],
            loading: false
          },
          () => this.getData()
        );
      } else {
        const detail = await request.get('/orders/detail/' + row.id);
        this.setState({
          loading: false,
          [state]: !this.state[state],
          selectedRow: detail.data.data
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  toggleModal = (row, name) => {
    this.setState(
      {
        [name]: !this.state[name],
        selectedRow: row
      },
      () => this.getData()
    );
  };

  onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;
    this.setState(
      prevState => ({
        ...prevState,
        page,
        sizePerPage,
        sortField,
        sortOrder,
        searchText
      }),
      () => this.getData()
    );
  };

  // get params (query string) for request
  getParams() {
    const qs = new URLSearchParams(window.location.search);

    this.setState(
      {
        sizePerPage: qs.get('sizePerPage') || this.state.sizePerPage,
        page: qs.get('page') || parseInt(this.state.page),
        start_date: qs.get('start_date') || this.state.start_date,
        end_date: qs.get('end_date') || this.state.end_date,
        filter_column: qs.get('filter_column') || this.state.filter_column,
        filter_text: qs.get('filter_text') || this.state.filter_text,
        payment_status_id: qs.get('payment_status_id') || this.state.payment_status_id,
        status_type_id: qs.get('status_type_id') || this.state.status_type_id,
        unit_id: qs.get('unit_id') || this.state.unit_id
      },
      () => this.getData()
    );
  }

  // new query string if get data
  setParams(params) {
    const newParams = {};

    Object.keys(params).forEach(key => {
      if (params[key]) {
        newParams[key] = params[key];
      }
    });

    const qs = new URLSearchParams(newParams);

    this.props.history.push({
      pathname: '/orders',
      search: '?' + qs
    });
  }

  componentDidMount() {
    this.getColumnOptions();
    this.getStatusOptions();
    this.getPaymentStatusOptions();
    this.getUnitOptions();

    this.getParams();

    this.interval = setInterval(() => {
      this.getData();
    }, 1000 * 15 * 60); // 15 menit
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getColumnOptions = () => {
    OrderService.getColumnOptions()
      .then(({ data }) => this.setState({ filterByOptions: data }))
      .catch(error => console.log(error));
  };

  getStatusOptions = () => {
    OrderService.getStatusOptions()
      .then(({ data }) => this.setState({ statusTypeOptions: data }))
      .catch(error => console.log(error));
  };

  getUnitOptions = () => {
    OrderService.getUnitOptions()
      .then(({ data }) => this.setState({ unitOptions: data }))
      .catch(error => console.log(error));
  };

  getPaymentStatusOptions = () => {
    OrderService.getPaymentStatusOptions()
      .then(({ data }) => this.setState({ paymentStatusOptions: data }))
      .catch(error => console.log(error));
  };

  getData = () => {
    const payload = {
      page: parseInt(this.state.page),
      sizePerPage: this.state.sizePerPage,
      filter_column: this.state.filter_column,
      filter_text: this.state.filter_text,
      status_type_id: this.state.status_type_id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      payment_status_id: this.state.payment_status_id,
      unit_id: this.state.unit_id
    };

    this.setParams(payload);

    this.setState({ loading: true });

    OrderService.getDatatable(payload)
      .then(response => {
        this.setState({
          data: response.data,
          totalSize: response.recordsTotal,
          page: response.page,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  hideAlert = () => {
    this.setState({ alert: null }, () => this.getData());
  };

  showConfirmDeleteAlert = id => {
    this.setState({
      alert: (
        <Swal
          warning
          title="Apakah anda yakin?"
          onCancel={this.hideAlert}
          onConfirm={() => this.handleDelete(id)}
          showCancel
          cancelBtnBsStyle="link"
          cancelBtnText="Batal"
          confirmBtnBsStyle="danger"
          confirmBtnText="Hapus"
          btnSize=""
          reverseButtons={false}
          showCloseButton={true}
          allowEscape={false}
          closeOnClickOutside={false}
        >
          Data tidak dapat dipulihkan
        </Swal>
      )
    });
  };

  confirmedDeleteAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          title="Berhasil!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil dihapus
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  showConfirmActionAlert = (id, next_status_id) => {
    this.setState({
      alert: (
        <Swal
          warning
          title="Apakah anda yakin?"
          onCancel={this.hideAlert}
          onConfirm={() => {
            this.handleAction(id, next_status_id);
          }}
          showCancel
          cancelBtnBsStyle="link"
          cancelBtnText="Batal"
          confirmBtnBsStyle="info"
          confirmBtnText="Ya"
          btnSize=""
          reverseButtons={false}
          showCloseButton={true}
          allowEscape={false}
          closeOnClickOutside={false}
        >
          Pastikan data yang dipilih telah sesuai
        </Swal>
      )
    });
  };

  showInputEstimationAlert = (id, next_status_id, with_desc = false) => {
    this.setState({
      alert: (
        <Swal
          input
          inputType="date"
          showCancel
          cancelBtnBsStyle="default"
          title="Perkiraan Selesai"
          placeHolder="Tanggal"
          onConfirm={estimation => {
            if (with_desc) {
              this.showInputEstimationDescAlert(id, estimation);
            } else {
              this.handleAction(id, next_status_id, estimation);
            }
          }}
          onCancel={this.hideAlert}
        ></Swal>
      )
    });
  };

  showInputEstimationDescAlert = (id, estimation) => {
    this.setState({
      alert: (
        <Swal
          input
          inputType="textarea"
          showCancel
          defaultValue=""
          cancelBtnBsStyle="default"
          title="Alasan"
          placeHolder="Alasan"
          onConfirm={estimation_desc => this.handleChangeEstimation(id, estimation, estimation_desc)}
          onCancel={this.hideAlert}
        ></Swal>
      )
    });
  };

  showInputActionAlert = (id, next_status_id) => {
    this.setState({
      alert: (
        <Swal
          input
          showCancel
          cancelBtnBsStyle="default"
          title="Resi"
          placeHolder="Deskripsi/Resi"
          onConfirm={response => this.handleAction(id, next_status_id, response)}
          onCancel={this.hideAlert}
        ></Swal>
      )
    });
  };

  confirmedActionAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          title="Berhasil!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Perubahan berhasil
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  handleChangeEstimation = (id, estimation, estimation_desc) => {
    this.setState({ alert: null, loading: true });
    OrderService.updateEstimation(id, {
      estimation,
      estimation_desc
    })
      .then(() => {
        this.confirmedActionAlert();
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  handleAction = (id, next_status_id, description) => {
    this.setState({ alert: null, loading: true });
    FlowService.forward({
      order_detail_id: id,
      status_type_id: next_status_id,
      description
    }).then(response => {
      // console.log(response);
      if (response) {
        this.setState({ loading: false });
        this.confirmedActionAlert();
      } else {
        this.setState({ loading: false });
        this.hideAlert();
      }
    });
  };

  handleExportCoupon = async () => {
    try {
      const response = await CouponService.exportDataCoupon();

      if (response.status === 200) {
        window.open(response.data.url, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleDelete = async id => {
    this.setState({ loading: true });
    try {
      const oke = await OrderService.remove(id);

      if (oke.status === 200) {
        this.setState({ loading: false });
        this.confirmedDeleteAlert();
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  render() {
    return (
      <>
        {this.state.alert}
        {this.state.loading && <Loader />}
        <SimpleHeader name="Order" parentName="Transaksi" />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Pencarian</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="start_date">Dari</label>
                        <Input
                          id="start_date"
                          type="date"
                          name="start_date"
                          innerRef={this.startDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={this.state.start_date}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="end_date">Sampai</label>
                        <Input
                          id="end_date"
                          type="date"
                          name="end_date"
                          innerRef={this.endDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={this.state.end_date}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="payment_status_id">Pembayaran</label>
                        <Input
                          id="payment_status_id"
                          type="select"
                          name="payment_status_id"
                          value={this.state.payment_status_id}
                          onChange={this.handleChange}
                        >
                          <option value="">Semua</option>
                          {this.state.paymentStatusOptions.map((status, key) => (
                            <option key={key} value={status.value}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="status_type_id">Status Produksi</label>
                        <Input
                          id="status_type_id"
                          type="select"
                          name="status_type_id"
                          value={this.state.status_type_id}
                          onChange={this.handleChange}
                        >
                          <option value="">Semua</option>
                          {this.state.statusTypeOptions.map((status, key) => (
                            <option key={key} value={status.value}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="unit_id">Unit</label>
                        <Input
                          id="unit_id"
                          type="select"
                          name="unit_id"
                          value={this.state.unit_id}
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih--</option>
                          {this.state.unitOptions.map((unit, key) => (
                            <option key={key} value={unit.value}>
                              {unit.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="filter_column">Cari Berdasarkan</label>

                        <Input
                          id="filter_column"
                          type="select"
                          name="filter_column"
                          value={this.state.filter_column}
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih Kolom Pencarian--</option>
                          {this.state.filterByOptions.map((column, key) => (
                            <option key={key} value={column.value}>
                              {column.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="filter_text">&nbsp;</label>
                        <Input
                          id="filter_text"
                          type="text"
                          name="filter_text"
                          placeholder="Keyword"
                          value={this.state.filter_text}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button onClick={() => this.getData()} color="primary">
                          Cari
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Tabel Order</h3>
                    </Col>
                    <Can I="update" a="Set Lunas">
                      <Col xs="6">
                        <Row style={{ float: 'right' }}>
                          {/* <Button className="btn btn-sm" onClick={this.handleExportCoupon} color="primary">
                            Export Kupon
                          </Button> */}
                          <Button
                            className="btn btn-sm"
                            onClick={() => {
                              this.setState({
                                isSetLunasBulkModalShow: true
                              });
                            }}
                            color="success"
                          >
                            Import Pelunasan
                          </Button>
                        </Row>
                      </Col>
                    </Can>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="id"
                  columns={this.columns}
                  // search
                >
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <BootstrapTable
                          id="table-order"
                          remote={{
                            filter_text: false,
                            pagination: true,
                            sort: false
                          }}
                          rowStyle={this.rowStyle}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: parseInt(this.state.page),
                            sizePerPage: parseInt(this.state.sizePerPage),
                            totalSize: parseInt(this.state.totalSize),
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={this.onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </Col>
          </Row>
        </Container>
        <InputRecipeModal
          isOpen={this.state.isInputRecipeModalShow}
          toggleInputRecipeModal={this.toggelModalWithDetail}
          row={this.state.selectedRow}
          confirmedActionAlert={this.confirmedActionAlert}
        />
        <ResiModal
          confirmedActionAlert={this.confirmedActionAlert}
          row={this.state.selectedRow}
          isResiModalShow={this.state.isResiModalShow}
          toggleModal={this.toggleModal}
        />
        <PicModal isOpen={this.state.isPicModalOpen} row={this.state.selectedRow} toggleModal={this.toggleModal} />
        <StatusModal
          isOpen={this.state.isStatusModalOpen}
          row={this.state.selectedRow}
          toggleModal={this.toggleModal}
        />
        <SetLunasModal
          isOpen={this.state.setLunasModal}
          row={this.state.selectedRow}
          toggle={this.toggelModalWithDetail}
        />
        <RefundModal
          isOpen={this.state.isInputRefundModalShow}
          row={this.state.selectedRow}
          toggle={this.toggelModalWithDetail}
        />
        <ProcessRefundModal
          isOpen={this.state.isInputProcessRefundModal}
          row={this.state.selectedRow}
          toggle={this.toggleModal}
        />
        <RefundDetail
          isOpen={this.state.isRefundDetailModalShow}
          row={this.state.selectedRow}
          toggle={this.toggelModalWithDetail}
        />
        <CouponModal row={this.state.selectedRow} isOpen={this.state.couponModal} toggle={this.toggelModalWithDetail} />
        <DetailModal row={this.state.selectedRow} isOpen={this.state.detailModal} toggle={this.toggelModalWithDetail} />
        <MoveToGreatModal
          row={this.state.selectedRow}
          isOpen={this.state.moveToGreatModal}
          toggle={this.toggelModalWithDetail}
        />
        <EvidenceModal isOpen={this.state.evidenceModal} toggleModal={this.toggleModal} row={this.state.selectedRow} />
        <PaymentVerificationModal
          row={this.state.selectedRow}
          isOpen={this.state.paymentVerificationModal}
          toggle={this.toggelModalWithDetail}
        />
        <ComplaintInputModal
          isOpen={this.state.isComplaintInputModalShow}
          toggle={this.toggleModal}
          row={this.state.selectedRow}
        />
        <ComplaintProcessModal
          isOpen={this.state.isComplaintProcessModalShow}
          toggle={this.toggleModal}
          row={this.state.selectedRow}
        />
        <ComplaintDetail
          isOpen={this.state.isComplaintDetailModalShow}
          toggle={this.toggleModal}
          row={this.state.selectedRow}
        />
        <SetLunasBulkModal isOpen={this.state.isSetLunasBulkModalShow} toggle={this.toggleSetLunasBulkModal} />
        <PaymentLinkModal
          // ini untuk link review
          isOpen={this.state.isPaymentLinkModalShow}
          title="Link Review"
          toggle={() => {
            this.setState({
              isPaymentLinkModalShow: !this.state.isPaymentLinkModalShow,
              linkUrl: ''
            });
          }}
          link={this.state.linkUrl}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, {})(TableOrder);
