import React, { useState, useEffect } from 'react';
import AffiliateVoucherService from '../../../../services/AffiliateVoucherService';
import Axios from 'axios';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import { Container, Row, Card, CardHeader, Col, Button } from 'reactstrap';
import Can from '../../../../config/Can';
import { Link } from 'react-router-dom';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Swal from 'sweetalert2';
import SearchBar from '../../../../components/Search';

function TableAffiliateVoucher(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  async function getData() {
    try {
      const response = await AffiliateVoucherService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText);

      if (response.status === 200) {
        setData(response.data);
        setTotalSize(response.recordsFiltered);
        setPage(response.page);
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getData();

    return () => {
      source.cancel();
    };
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  return (
    <>
      <SimpleHeader name="Voucher" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Tabel Voucher</h3>
                  </Col>
                  <Can I="create" a="Affiliate Voucher">
                    <Col className="text-right" xs="6">
                      <Link to="/affiliate/vouchers/add">
                        <Button className="btn-round btn-icon" color="primary" id="tooltip443412080" size="sm">
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Tambah</span>
                        </Button>
                      </Link>
                    </Col>
                  </Can>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'no',
                    text: 'Nomor',
                    sort: false
                  },
                  {
                    dataField: 'code',
                    text: 'Kode Voucher',
                    sort: true
                  },
                  {
                    dataField: 'stock',
                    text: 'Jumlah Voucher',
                    sort: true
                  },
                  {
                    dataField: 'start_date',
                    text: 'Tanggal Mulai',
                    sort: true,
                    formatter: cell => (
                      <>
                        {new Date(cell).toLocaleDateString('id-ID', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </>
                    )
                  },
                  {
                    dataField: 'end_date',
                    text: 'Tanggal Berakhir',
                    sort: true,
                    formatter: cell => (
                      <>
                        {new Date(cell).toLocaleDateString('id-ID', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </>
                    )
                  },
                  {
                    dataField: 'action',
                    text: 'Aksi',
                    sort: false,
                    isDummyField: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <>
                          <Can I="read" a="Affiliate Voucher">
                            <Button
                              color="info"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => props.history.push('/affiliate/vouchers/' + row.id + '/orders')}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-list" />
                              </span>
                              <span className="btn-inner--text"> Orderan</span>
                            </Button>
                          </Can>
                          <Can I="update" a="Affiliate Voucher">
                            <br />
                            <Button
                              color="warning"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => props.history.push('/affiliate/vouchers/edit/' + row.id)}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-edit" />
                              </span>
                              <span className="btn-inner--text"> Ubah</span>
                            </Button>
                          </Can>
                          <Can I="delete" a="Affiliate Voucher">
                            <br />
                            <Button
                              color="danger"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => {
                                Swal.fire({
                                  icon: 'warning',
                                  title: `Apakah anda yakin akan menghapus voucher ${row.code}?`,
                                  cancelButtonText: 'Batal',
                                  confirmButtonColor: 'red',
                                  cancelButtonColor: 'green',
                                  showConfirmButton: true,
                                  allowOutsideClick: false,
                                  allowEscapeKey: false,
                                  allowEnterKey: false,
                                  showCancelButton: true,
                                  showCloseButton: true
                                }).then(async confirm => {
                                  if (confirm.value) {
                                    try {
                                      const response = await AffiliateVoucherService.remove(row.id);
                                      if (response.status === 200) {
                                        Swal.fire({
                                          icon: 'success',
                                          text: `Voucher ${row.code} berhasil dihapus`
                                        });
                                        getData();
                                      }
                                    } catch (error) {
                                      Swal.fire({
                                        icon: 'error',
                                        title: 'terjadi kesalahan',
                                        text: error.message
                                      });
                                    }
                                  }
                                });
                              }}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-trash" />
                              </span>
                              <span className="btn-inner--text"> Hapus</span>
                            </Button>
                          </Can>
                        </>
                      );
                    }
                  }
                ]}
                search
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                        <label>
                          Search:
                          <SearchBar className="form-control-sm" {...props.searchProps} />
                        </label>
                      </div>
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TableAffiliateVoucher;
