import { request } from '../config';

class BannerSlideService {
  /**
   * Get bank lists
   */
  static async getLists() {
    const { data: response } = await request.get('/slides/list');
    return response;
  }

  static async getCsLists() {
    const { data: response } = await request.get('/slides/list-cs');
    return response;
  }

  static async getBankProviders() {
    const { data: response } = await request.get('/slides/providers');
    return response;
  }

  static async insert(data) {
    const { data: response } = await request.post('/slides', data);
    return response;
  }

  static async uploadImage(data) {
    const { data: response } = await request.post(`/upload/slide/image`, data);
    return response;
  }

  static async detail(id) {
    const { data: response } = await request.get(`/slides/${id}`);
    return response;
  }

  static async edit(id, data) {
    const { data: response } = await request.put(`/slides/${id}`, data);
    return response;
  }

  static async changeStatus(id) {
    const { data: response } = await request.put(`/slides/change-status/${id}`);
    return response;
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/slides/${id}`);
    return response;
  }

  static async getDatatable(page, limit, sort, order, filter) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/slides', {
      params: { page, limit, sort, order, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }
}

export default BannerSlideService;
