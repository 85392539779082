import html2canvas from 'html2canvas';
import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Logo from '../../../assets/img/brand/coversuper_icon.png';
import Graphic from '../../../assets/img/graphic.png';
import Force from '../../../assets/img/logo-force-new.png';
import { EXCELLENT_TEAM, FORCE_TEAM, GREAT_TEAM, TYPE_KARPET } from '../../../constants';
import OrderService from '../../../services/OrderService';

moment.locale('id');

const Invoice = props => {
  const { order_number } = props.match.params;

  const [data, setData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await OrderService.findByOrderNumber(order_number);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderDetailInvoice = () => {
    return data.details.map((item, index) => {
      return (
        <tr key={index} style={{ background: '#ddd' }}>
          <td style={{ padding: '0.5rem', border: '2px solid #fff', textAlign: 'center' }}>{item.qty}</td>
          <td style={{ padding: '0.5rem', border: '2px solid #fff' }}>
            {item.category.name} {item.product_name}{' '}
            {item.category.vehicle_type_id === TYPE_KARPET ? item.variant.size : ''}
          </td>
          <td style={{ padding: '0.5rem', border: '2px solid #fff', textAlign: 'center' }}>{item.year}</td>
          <td style={{ padding: '0.5rem', border: '2px solid #fff', textAlign: 'center' }}>{item.color.name}</td>
          <td style={{ padding: '0.5rem', border: '2px solid #fff', textAlign: 'center' }}>
            {item.cover_type && item.cover_type.name}
          </td>
          <td style={{ padding: '0.5rem', border: '2px solid #fff', textAlign: 'center' }}>
            {item.cover_list && item.cover_list.name}
          </td>
          <td style={{ padding: '0.5rem', border: '2px solid #fff', textAlign: 'center' }}>{item.logo}</td>
          <td style={{ padding: '0.5rem', border: '2px solid #fff', textAlign: 'center' }}>
            {item.addons.map((addon, key) => (
              <li key={key}>{addon.name}</li>
            ))}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {data && (
        <div className="container mt-4">
          <div className="card position-relative" id="section-to-print">
            <Row>
              <Col md="4">
                <img
                  alt={''}
                  src={[FORCE_TEAM].includes(data.unit.id) ? Force : Logo}
                  className={[FORCE_TEAM].includes(data.unit.id) ? `w-100` : `w-100 p-3`}
                />
              </Col>
              <Col md="8">
                <ul className="py-1 list-unstyled">
                  <li>
                    {data.supplier.address}, {data.supplier.subdistrict.name},{' '}
                    {data.supplier.district.type + ' ' + data.supplier.district.name}, {data.supplier.province.name}
                  </li>
                  <li className="mb-2">Telp. {data.supplier.phone}</li>
                  <li>
                    <strong>Yogyakarta,</strong>{' '}
                    <span className="text-underline">{moment(data.created_at).format('D MMMM YYYY')} </span>
                  </li>
                  <li>
                    <strong>Kepada Yth:</strong> <span className="text-underline">{data.shipping_address.name}</span>
                  </li>
                  <li>
                    <strong>Nota Kontan No:</strong> <span className="text-underline">{data.order_number}</span>
                  </li>
                </ul>
              </Col>
            </Row>

            {[GREAT_TEAM, EXCELLENT_TEAM, FORCE_TEAM].includes(data.unit.id) && (
              <table id="table-order-items" style={{ width: '100%', marginTop: '10px' }}>
                <thead>
                  <tr
                    style={{
                      background: [FORCE_TEAM].includes(data.unit.id)
                        ? '#1b4298'
                        : data.unit.id === GREAT_TEAM
                        ? '#f0112b'
                        : 'black',
                      color: 'white',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    <th style={{ width: '5%', borderRight: '2px solid #fff' }}>QTY</th>
                    <th style={{ width: '10%', borderRight: '2px solid #fff' }}>NAMA BARANG</th>
                    <th style={{ width: '10%', borderRight: '2px solid #fff' }}>TAHUN</th>
                    <th style={{ width: '10%', borderRight: '2px solid #fff' }}>WARNA</th>
                    <th style={{ width: '10%', borderRight: '2px solid #fff' }}>TIPE</th>
                    <th style={{ width: '10%', borderRight: '2px solid #fff' }}>LIST</th>
                    <th style={{ width: '10%', borderRight: '2px solid #fff' }}>LOGO</th>
                    <th style={{ width: '10%', borderRight: '2px solid #fff' }}>TAMBAHAN</th>
                  </tr>
                </thead>
                <tbody>
                  {renderDetailInvoice()}
                  <tr>
                    <td style={{ padding: '0.5rem' }}></td>
                    <td
                      colSpan={5}
                      style={{
                        textAlign: 'center',
                        // background: '#ddd',
                        color: '#E62131',
                        fontWeight: 'bolder'
                      }}
                    ></td>
                    <td style={{ background: '#ddd', padding: '0.5rem', border: '2px solid #fff' }}>
                      {' '}
                      <strong>GRAND TOTAL</strong>
                    </td>
                    <td style={{ background: '#ddd', padding: '0.5rem', border: '2px solid #fff', textAlign: 'right' }}>
                      <strong>
                        {new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          maximumFractionDigits: 0
                        }).format(data.grand_total)}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: '0.5rem' }}></td>
                    <td
                      colSpan={5}
                      style={{
                        textAlign: 'center',
                        // background: '#ddd',
                        color: '#E62131',
                        fontWeight: 'bolder'
                      }}
                    ></td>
                    <td style={{ background: '#ddd', padding: '0.5rem', border: '2px solid #fff' }}>
                      {' '}
                      <strong>TOTAL BAYAR</strong>
                    </td>
                    <td style={{ background: '#ddd', padding: '0.5rem', border: '2px solid #fff', textAlign: 'right' }}>
                      <strong>
                        {new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          maximumFractionDigits: 0
                        }).format(data.payment_amount)}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <Row className="mt-5">
              <Col md="6">
                <div className="text-center">
                  <h2
                    style={{
                      marginBottom: '10rem'
                    }}
                  >
                    Tanda Terima
                  </h2>
                  <p>{data.shipping_address.name}</p>
                </div>
              </Col>
              <Col md="6">
                <div className="text-center">
                  <h2
                    style={{
                      marginBottom: '10rem'
                    }}
                  >
                    Hormat Kami
                  </h2>
                  <p>PT Coversuper Indonesia Global</p>
                </div>
              </Col>
            </Row>
            <img alt={''} src={Graphic} width={200} className="position-absolute bottom-0 right-0" />
          </div>

          <div className="my-4">
            <Button
              className="btn btn-success"
              onClick={() => {
                window.scroll(0, 0);

                setTimeout(() => {
                  const content = document.getElementById('section-to-print');
                  const mywindow = window.open('', 'Print');
                  html2canvas(content).then(canvas => {
                    const border = document.createElement('div');
                    border.setAttribute('id', 'border');
                    mywindow.document.body.appendChild(border);
                    mywindow.document.getElementById('border').appendChild(canvas);
                    mywindow.print();
                  });
                }, 0);
              }}
            >
              Cetak
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Invoice;
