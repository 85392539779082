import { request } from '../config';

// versi function
function RoleService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/roles', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;
    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // Show List
  const list = async () => {
    const response = await request.get(`/roles/list`);
    return response;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/roles/${id}`);
    return response.data;
  };

  const save = async data => {
    const response = await request.post(`/roles`, data);
    return response.data;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/roles/${id}`, data);
    return response.data;
  };

  const remove = async id => {
    const response = await request.delete(`/roles/${id}`);
    return response.data;
  };

  return {
    getDatatable,
    list,
    findById,
    save,
    update,
    remove
  };
}

export default RoleService();
