import React from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row
} from 'reactstrap';

import { useEffect, useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import OrderService from '../../../services/OrderService';

const CustomerForm = props => {
  const [platformOptions, setPlatformOptions] = useState([]);
  const [orderSourceOptions, setOrderSourceOptions] = useState([]);

  const [npwp, setNpwp] = useState(null);

  useEffect(() => {
    getPlatforms();
    getOrderSource();
  }, [props.order_date]);

  async function getPlatforms() {
    try {
      const res = await OrderService.getPlatforms();
      if (res.status === 200) {
        setPlatformOptions(
          res.data.data.map(data => ({
            label: data.name,
            value: data.name,
            invoice_required: !!data.invoice_required
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getOrderSource() {
    try {
      const res = await OrderService.getOrderSources();
      if (res.status === 200) {
        setOrderSourceOptions(
          res.data.data.map(data => ({
            label: data.name,
            value: data.name
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleImageNpwp(e) {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    if (e.target.files.length > 0) {
      const formData = new FormData();
      for (const file of e.target.files) {
        formData.append('file', file);
      }

      try {
        const image = await OrderService.uploadNpwp(formData);

        if (image.message === 'OK') {
          setNpwp(image.data[0]);
          props.setNpwp(image.data[0]);
        } else {
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        // this.props.errorAlert(error.message);
      }
    }
  }

  function removeNpwp() {
    setNpwp(null);
    props.removeNpwp();
  }

  return (
    <>
      <Row>
        <Col lg="4">
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0 text-danger">Data Pemesan</h3>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="customerName">
                    Nama / No. Telepon
                  </Label>
                  <AsyncSelect
                    id="customerName"
                    name="customer_id"
                    defaultOptions
                    placeholder={props.customer_placeholder}
                    ref={props.customerSelectRef}
                    loadOptions={props.loadCustomerOptions}
                    onChange={props.handleInputCustomerChange}
                  />
                  <div id="customerValidFeedback" style={{ display: 'hidden' }} className="invalid-feedback">
                    customer wajib diisi
                  </div>
                </FormGroup>
                <FormGroup>
                  <Button className="btn btn-sm mr-0 btn-success" onClick={() => props.toggleModal('customerModal')}>
                    <div className="">Tambah Pelanggan</div>
                  </Button>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="orderSource">
                    Sumber Order
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </label>
                  <Select
                    // isClearable
                    placeholder="Sumber Order"
                    id="orderSource"
                    type="select"
                    name="orderSourceSelected"
                    onChange={select => {
                      props.handleChange('order_source', select.value);
                    }}
                    options={orderSourceOptions}
                  />
                  <div id="orderSourceValidFeedback" style={{ display: 'hidden' }} className="invalid-feedback">
                    Sumber order wajib diisi
                  </div>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="platformOptions">
                    Platform
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </label>
                  <Select
                    // isClearable
                    placeholder="Pilih Platform"
                    id="platformOptions"
                    type="select"
                    name="platformSelected"
                    value={[{ label: props.platform ? props.platform : 'Pilih Platform', value: props.platform }]}
                    onChange={select => {
                      const platform = platformOptions.find(element => element.value === select.value);
                      props.handleChangePlatform(select.value, platform.invoice_required);
                    }}
                    options={platformOptions}
                  />
                  <div id="platformValidFeedback" style={{ display: 'hidden' }} className="invalid-feedback">
                    platform wajib diisi
                  </div>
                </FormGroup>
                {props.invoice_required && (
                  <FormGroup>
                    <label className="form-control-label" htmlFor="platformOptions">
                      No. Invoice Marketplace
                      <sup>
                        <span className="text-red">*</span>
                      </sup>
                    </label>
                    <Input
                      type="text"
                      placeholder="INV/XX/YY/ZZ/123"
                      name="invoice_number"
                      // value={props.invoice_number}
                      innerRef={props.invoiceNumberRef}
                      onChange={e => {
                        e.target.classList.remove('is-invalid');
                        props.handleInvoiceChange(e.target.value);
                      }}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <label className="form-control-label" htmlFor="platformOptions">
                    Tanggal Order
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </label>
                  <Input
                    id="amountPaid"
                    type="date"
                    name="order_date"
                    innerRef={props.orderDateRef}
                    onKeyDown={e => e.preventDefault()}
                    onWheel={e => false}
                    max={new Date().toISOString().split('T')[0]}
                    min={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                    value={props.order_date}
                    onChange={e => {
                      // remove class invalid
                      e.target.classList.remove('is-invalid');
                      props.handleChange('order_date', e.target.value);
                    }}
                  />
                  <div id="platformValidFeedback" style={{ display: 'hidden' }} className="invalid-feedback">
                    tanggal order wajib diisi
                  </div>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      bsSize="sm"
                      type="checkbox"
                      value={props.request_tax_invoice}
                      onChange={e => {
                        props.handleChangeFakturPajak(e.target.value);
                      }}
                    />
                    <div style={{ fontWeight: '400', fontSize: '14px', marginTop: '9.5px' }}>Request Faktur Pajak</div>
                  </Label>
                </FormGroup>
                {props.request_tax_invoice && (
                  <FormGroup>
                    <label className="form-control-label" htmlFor="npwp">
                      Foto NPWP
                      <sup>
                        <span className="text-red">*</span>
                      </sup>
                    </label>
                    {npwp && (
                      <div>
                        <img src={npwp} alt="order evidence url" width="180" height="200" style={{ marginRight: 5 }} />
                        <Button size="sm" color="danger" onClick={() => removeNpwp()}>
                          <i className="fas fa-trash" />
                        </Button>
                      </div>
                    )}
                    {!npwp && (
                      <div className="custom-file">
                        <input
                          className="custom-file-input"
                          id="npwp"
                          lang="en"
                          accept="image/*"
                          ref={props.npwpImageRef}
                          type="file"
                          onChange={handleImageNpwp}
                        />
                        <label className="custom-file-label" htmlFor="npwp">
                          File NPWP
                        </label>
                      </div>
                    )}
                  </FormGroup>
                )}
              </CardBody>
            </Card>
          </div>
        </Col>
        <Col lg="8">
          <div className="card-wrapper">
            <Card>
              <CardHeader>
                <h3 className="mb-0 text-danger">Data Penerima</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="namaPenerima">
                        Nama
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <Input
                        id="namaPenerima"
                        placeholder="Nama Penerima"
                        type="text"
                        name="name"
                        innerRef={props.shippingAddressNameRef}
                        value={props.shippingAddress.name}
                        onChange={props.shippingAddressChange}
                      />
                      <small className="form-text text-muted">Nama maksimal 100 karakter</small>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="province">
                        Provinsi
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <Input
                        id="province"
                        value={props.shippingAddress.province_id}
                        type="select"
                        name="province_id"
                        onChange={props.handleChangeProvince}
                      >
                        <option value="">--Pilih Provinsi--</option>
                        {props.provinceOptions.map((province, key) => (
                          <option value={province.id} key={key}>
                            {province.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="shippingAddressPhone">
                        No. Telepon Utama
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+62</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="shippingAddressPhone"
                          placeholder="8123xxx"
                          type="text"
                          name="phone"
                          innerRef={props.shippingAddressPhoneRef}
                          value={props.shippingAddress.phone}
                          onChange={props.shippingAddressChange}
                        />
                      </InputGroup>
                      <small className="form-text text-muted">No telepon maksimal 14 karakter</small>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="district">
                        Kota / Kabupaten
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <Input
                        id="district"
                        value={props.shippingAddress.district_id}
                        type="select"
                        name="district_id"
                        onChange={props.handleChangeDistrict}
                      >
                        <option value="">--Pilih Kota / Kabupaten--</option>
                        {props.districtOptions.map((district, key) => (
                          <option value={district.id} key={key}>
                            {district.type === 'Kabupaten' ? 'Kab.' : district.type} {district.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="shippingAddressPhoneAlt">
                        No. Telepon Alternatif
                      </label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+62</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="shippingAddressPhoneAlt"
                          placeholder="8123xxx"
                          type="text"
                          name="phone_alt"
                          innerRef={props.shippingAddressPhoneAltRef}
                          value={props.shippingAddress.phone_alt || ''}
                          onChange={props.shippingAddressChange}
                        />
                      </InputGroup>
                      <small className="form-text text-muted">
                        (optional) No telepon alternatif maksimal 14 karakter
                      </small>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="subdistrict">
                        Kecamatan
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <Input
                        id="subdistrict"
                        value={props.shippingAddress.subdistrict_id || ''}
                        type="select"
                        name="subdistrict_id"
                        onChange={props.shippingAddressChange}
                      >
                        <option value="">--Pilih Kecamatan--</option>
                        {props.subdistrictOptions.map((subdistrict, key) => (
                          <option value={subdistrict.id} key={key}>
                            {subdistrict.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="email">
                        Email
                      </label>
                      <Input
                        id="email"
                        placeholder="email@email.com"
                        type="email"
                        name="email"
                        value={props.shippingAddress.email || ''}
                        onChange={props.shippingAddressChange}
                      />
                      <small className="form-text text-muted">Email maksimal 50 karakter</small>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="postalcode">
                        Kode Pos
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <Input
                        id="postalcode"
                        placeholder="12345"
                        type="text"
                        name="postal_code"
                        value={props.shippingAddress.postal_code}
                        onChange={props.shippingAddressChange}
                      />
                      <small className="form-text text-muted">Kode pos maksimal 5 karakter</small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="shippingAddressAdress">
                        Alamat Pengiriman
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <Input
                        id="shippingAddressAdress"
                        placeholder="Alamat"
                        type="textarea"
                        rows="2"
                        name="address"
                        value={props.shippingAddress.address}
                        onChange={props.shippingAddressChange}
                      />
                      <small className="form-text text-muted">Alamat Pengiriman maksimal 200 karakter</small>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomerForm;
