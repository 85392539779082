import React from 'react';

import { Button, Col, FormGroup, Input, ListGroup, ListGroupItem, Modal, Row } from 'reactstrap';

import Dropzone from 'dropzone';
import Swal from 'sweetalert2';
import { API_URL } from '../../../../config';
import { TYPE_KARPET } from '../../../../constants';
import AddonService from '../../../../services/AddonService';
// import './AddonModal.css';
// import OrderService from '../../../../services/OrderService';
const UploadURL = API_URL + '/api/v1/upload/order/image';

Dropzone.autoDiscover = false;

class AddonModal extends React.Component {
  state = {
    allowClose: false
  };

  initDropzone = () => {
    let currentMultipleFile = [];
    new Dropzone(document.getElementById('dropzone-multiple'), {
      url: UploadURL,
      createImageThumbnails: false,
      maxFiles: 10,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      acceptedFiles: 'image/*',
      init: function() {
        this.on('addedfile', file => {
          currentMultipleFile.push({ file });

          for (let i = 0; i < document.getElementsByClassName('dz-preview').length; i++) {
            document.getElementsByClassName('dz-preview')[i].innerHTML = '';
          }

          // console.log("added", file);
        });

        this.on('removedfile', file => {
          // console.log("removed", file);
        });
      },
      sending: (file, response) => {
        this.setState({ loading: true });
      },
      error: (file, error) => {
        this.setState({ loading: false });
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error.message
        });
        console.log(error);
      },
      success: (file, response) => {
        this.setState({ loading: false });
        this.props.changeAddonImage(response, this.props.productRow.index);
        for (let i = 0; i < document.getElementsByClassName('dz-preview').length; i++) {
          document.getElementsByClassName('dz-preview')[i].innerHTML = '';
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.productImagePasteFile !== this.props.productImagePasteFile) {
      this.handleImagePaste(this.props.productImagePasteFile);
    }
  }

  handleImagePaste = async file => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      this.setState({ loading: true });
      const image = await AddonService.uploadImage(formData);

      if (image.message === 'OK') {
        this.props.changeAddonImage(image, this.props.productRow.index);
        for (let i = 0; i < document.getElementsByClassName('dz-preview').length; i++) {
          document.getElementsByClassName('dz-preview')[i].innerHTML = '';
        }
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
      }
    } catch (error) {
      this.setState({ loading: false });
      // this.props.errorAlert(error.message);
    }
  };

  handleRemoveImage = async (url, i) => {
    try {
      this.setState({ loading: true });
      const image = await AddonService.removeImage(url.image);
      if (image.status === 200 && image.data.data) {
        this.setState({ loading: false });
        this.props.removeAddonImage(url);
      } else {
        this.setState({ loading: false });
        Swal.fire({
          title: 'error',
          icon: 'error',
          text: 'terjadi kesalahan pada saat menghapus foto.'
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      Swal.fire({
        title: 'error',
        icon: 'error',
        text: 'terjadi kesalahan pada saat menghapus foto. ' + error.message
      });
    }
  };

  render() {
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={this.props.addonModal}
        toggle={() => this.props.toggleModal('addonModal')}
        onOpened={this.initDropzone}
        onClosed={this.props.rowProductClosed}
      >
        {this.state.loading && <div className="loader"></div>}
        <div className="modal-header">
          <h5 className="modal-title" id="addonModalLabel">
            Tambahan
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.props.toggleModal('addonModal')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <label className="form-control-label" htmlFor="customFileLang">
              Upload Gambar
            </label>
            <div className="dropzone dropzone-multiple" id="dropzone-multiple">
              <div className="fallback">
                <div className="custom-file">
                  <input
                    className="custom-file-input"
                    id="customFileUploadMultiple"
                    multiple="multiple"
                    type="file"
                    name="image"
                  />
                  <label className="custom-file-label" htmlFor="customFileUploadMultiple">
                    Pilih Gambar
                  </label>
                </div>
              </div>
            </div>
          </FormGroup>

          {this.props.productVariants &&
            this.props.productRow &&
            this.props.productVariants[this.props.productRow.index] &&
            this.props.productVariants[this.props.productRow.index].images && (
              <div>
                <ListGroup>
                  {this.props.productVariants[this.props.productRow.index].images.map((image, i) => (
                    <ListGroupItem key={i}>
                      <Row className="align-items-center justify-content-between">
                        <Col className="col-auto">
                          <div>
                            <img alt={`product ${i}`} className="avatar-img" width="150" src={image.image} />
                          </div>
                        </Col>
                        <Col className=" col-auto">
                          <Button size="sm" color="danger" onClick={() => this.handleRemoveImage(image, i)}>
                            <i className="fas fa-trash" />
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            )}

          {this.props.productVariants &&
            this.props.productRow &&
            this.props.productVariants[this.props.productRow.index].product.category.vehicle_type_id !== TYPE_KARPET &&
            this.props.addonOptions.map((addon, key) => {
              // current variant
              const { productVariants } = this.props;
              let checked = false;
              let selectedProductVariantIndex = null;

              if (this.props.productRow) {
                selectedProductVariantIndex = this.props.productRow.index;

                if (
                  productVariants[selectedProductVariantIndex] &&
                  productVariants[selectedProductVariantIndex].addons
                ) {
                  let indexAddon = productVariants[selectedProductVariantIndex].addons.findIndex(
                    addonExist => addonExist.id === addon.id
                  );
                  checked = indexAddon > -1; // if exist
                }
              }

              return (
                <div className="custom-control custom-checkbox" key={key}>
                  <Input
                    className="custom-control-input"
                    id={'customCheckAddons' + key}
                    type="checkbox"
                    defaultChecked={checked}
                    onChange={e => this.props.changeAddonProduct(e, selectedProductVariantIndex, addon)}
                  />
                  <label className="custom-control-label" htmlFor={'customCheckAddons' + key}>
                    {addon.name} {'-'} Rp.
                    {new Intl.NumberFormat('id-ID').format(addon.price)}
                  </label>
                </div>
              );
            })}
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => this.props.toggleModal('addonModal')}
          >
            Tutup
          </Button>
        </div>
      </Modal>
    );
  }
}

export default AddonModal;
