import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import CustomerTypeService from '../../../services/CustomerTypeService';
import LeadsService from '../../../services/LeadsService';
import RegionalService from '../../../services/RegionalService';
import { defaultRow } from './defaultRow';

const FormPurchased = ({ isOpen, toggle, row, setPurchasedModal, getDataAfterUpdate }) => {
  const [values, setValues] = useState(defaultRow);
  const [customerType, setCustomerType] = useState([]);
  const [customer_type_id, setCustomerTypeId] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [postalMessage, setPostalMessage] = useState('');
  const [customer_phone, setCustomerPhone] = useState(row && row.customer_phone ? row.customer_phone : '');
  const [requiredPhone, setRequiredPhone] = useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const formatPhone = phone => {
    return phone
      .replace(/\D/g, '')
      .replace(/^0+/, '')
      .replace(/^62/g, '');
  };

  useEffect(() => {
    setValues(row);
    if (row.customer_phone) {
      setCustomerPhone(formatPhone(row.customer_phone));
      setRequiredPhone(false);
    } else {
      setRequiredPhone(true);
    }
    getProvince();
    getDataCustomerType();
  }, [row]);

  // useEffect(() => {
  //   console.log('values', values);
  // }, [values]);

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleCustomerPhone = phone => {
    setCustomerPhone(formatPhone(phone));
  };

  const getDataCustomerType = async () => {
    try {
      setLoading(true);
      const { data } = await CustomerTypeService.list();
      setCustomerType(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getProvince = async () => {
    try {
      const res = await RegionalService.province();
      setProvinces(res.data);
    } catch (error) {}
  };

  const provinceChange = e => {
    // console.log(e.target.value);
    setValues({ ...values, district_id: '', province_id: e.target.value });
    RegionalService.districtByProvince(e.target.value)
      .then(res => {
        // console.log(res);
        setDistrict(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const subDistrictChange = e => {
    // console.log(e.target.value);
    setValues({ ...values, subdistricts_id: '', district_id: e.target.value });
    RegionalService.subdistrictByDistrict(e.target.value)
      .then(res => {
        // console.log(res);
        setSubdistricts(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const cobaKec = e => {
    // console.log(e.target.value);
    setValues({ ...values, subdistrict_id: e.target.value });
  };

  const postalCodeValidator = e => {
    const kodePos = e.target.value;
    // eslint-disable-next-line
    const testNIK = /^[0-9]{5}$/;

    let kodePosvalid =
      kodePos === '' ? (
        true
      ) : testNIK.test(kodePos) ? (
        true
      ) : (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid Postal Code
        </div>
      );

    setValues({ ...values, postal_code: kodePos });
    setPostalMessage(kodePosvalid);
  };

  const closeModal = () => {
    console.log({ values });
    toggle();
  };

  const onSubmit = e => {
    e.preventDefault();

    const { address, province_id, district_id, subdistrict_id, postal_code } = values;

    let data = {
      customer_type_id,
      address,
      province_id,
      district_id,
      subdistrict_id,
      postal_code
    };

    if (requiredPhone) {
      data.customer_phone = '+62' + customer_phone;
    }

    LeadsService.changeStatusPurchased(row.id, data)
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Purchased Berhasil Ditambahkan',
            onClose: setPurchasedModal(false)
          });
          getDataAfterUpdate();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} size="lg">
        <div className="modal-header">
          <h5 className="modal-title" id="detailModalLabel">
            Nama Customer : {row && row.customer_name}
          </h5>
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => closeModal()}>
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Input
                    required
                    type="select"
                    name="customer_type_id"
                    onChange={e => setCustomerTypeId(e.target.value)}
                  >
                    <option value="">Pilih Jenis Customer</option>
                    {customerType.map((res, key) => (
                      <option value={res.id} key={key}>
                        {res.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>+62</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required={requiredPhone}
                      placeholder="888xxx"
                      name="customer_phone"
                      type="text"
                      value={customer_phone}
                      onChange={e => handleCustomerPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="6">
                {/* PROVINCE */}
                <FormGroup>
                  <Input required type="select" name="province_id" onChange={provinceChange}>
                    <option value="">Pilih Provinsi</option>
                    {provinces.map((prov, key) => (
                      <option value={prov.id} key={key}>
                        {prov.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col md="6">
                {/* District */}
                <FormGroup>
                  <Input required type="select" name="district_id" onChange={subDistrictChange}>
                    <option value="">Pilih Kota / Kabupaten</option>
                    {district.map((result, key) => (
                      <option value={result.id} key={key}>
                        {result.type === 'Kabupaten' ? 'Kab.' : result.type} {result.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col md="6">
                {/* Sub District */}
                <FormGroup>
                  <Input required type="select" name="subdistricts_id" onChange={cobaKec}>
                    <option value="">Pilih Kecamatan</option>
                    {subdistricts.map((result, key) => (
                      <option value={result.id} key={key}>
                        {result.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  {postalMessage}
                  <Input
                    required
                    placeholder="Kode Pos"
                    name="postal_code"
                    type="text"
                    value={values && values.postal_code ? values.postal_code.replace(/[^0-9]+/gi, '') : ''}
                    onChange={postalCodeValidator}
                  />
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Input
                    required
                    type="textarea"
                    placeholder="Alamat"
                    name="address"
                    id="exampleText"
                    value={values && values.address ? values.address : ''}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mt-4">
              <Button color="primary" className="btn" type="submit">
                Simpan
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FormPurchased;
