import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import CurrencyInput from '../../../../helpers/CurrencyInput';
import OrderService from '../../../../services/OrderService';

function RefundModal(props) {
  const [alert, setAlert] = useState('');
  const [refund_desc, setRefundDesc] = useState('');
  const [refund_amount, setRefundAmount] = useState('');
  const [bank_name, setBankName] = useState('');
  const [account_number, SetAccountNumber] = useState('');
  const [account_name, setAccountName] = useState('');
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setAlert('');
    setRefundDesc('');
    setRefundAmount('');
    setBankName('');
    SetAccountNumber('');
    setAccountName('');
    setLoading(false);

    props.toggle(props.row, 'isInputRefundModalShow');
  };

  const isValid = () => {
    if (!refund_desc || refund_desc === '') {
      setAlert('Deskripsi Refund harus diisi');
      return false;
    } else if (!refund_amount || refund_amount === '') {
      setAlert('Jumlah Refund harus diisi');
      return false;
    } else if (!bank_name || bank_name === '') {
      setAlert('Nama Bank harus diisi');
      return false;
    } else if (!account_number || account_number === '') {
      setAlert('Nomor Rekening harus diisi');
      return false;
    } else if (!account_name || account_name === '') {
      setAlert('Atas Nama Rekening harus diisi');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isValid()) {
        const data = {
          order_id: props.row.order.id,
          refund_desc,
          refund_amount,
          bank_name,
          account_number,
          account_name
        };

        const res = await OrderService.refund(data);

        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Pengajuan Refund Berhasil'
          }).then(() => {
            setLoading(false);
            toggle();
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal isOpen={props.isOpen} fade={false} backdrop="static" toggle={toggle}>
        <ModalHeader toggle={toggle}>Keterangan Pengajuan Refund</ModalHeader>
        <ModalBody>
          {alert !== '' && <Alert color="danger">{alert}</Alert>}

          <Form>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label for="length">Keterangan Refund</Label>
                  <Input
                    type="textarea"
                    placeholder="Keterangan refund"
                    onChange={e => {
                      e.persist();
                      setAlert('');
                      if (e.target.value.length <= 120) {
                        setRefundDesc(e.target.value);
                      }
                    }}
                    value={refund_desc}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="length">Jumlah Refund</Label>
                  <CurrencyInput
                    type="text"
                    id="paymentAmount"
                    name="payment_amount"
                    className="form-control"
                    value={refund_amount}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setRefundAmount(value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="length">Nama Bank</Label>
                  <Input
                    type="text"
                    placeholder="BCA, BNI, BRI, Mandiri"
                    onChange={e => {
                      e.persist();
                      setAlert('');

                      if (e.target.value.length <= 50) {
                        setBankName(e.target.value);
                      }
                    }}
                    value={bank_name}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="length">Nomor Rekening</Label>
                  <Input
                    type="text"
                    placeholder="45609xxx"
                    value={account_number}
                    onChange={e => {
                      e.persist();
                      setAlert('');
                      const value = e.target.value.replace(/[^0-9]+/gi, '');
                      if (value.length <= 30) {
                        SetAccountNumber(value);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="length">Atas Nama Rekening</Label>
                  <Input
                    type="text"
                    placeholder="Agus"
                    onChange={e => {
                      e.persist();
                      setAlert('');
                      const value = e.target.value.replace(/[0-9]+/gi, '');
                      if (value.length <= 50) {
                        setAccountName(value);
                      }
                    }}
                    value={account_name}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Submit'
            )}
          </Button>{' '}
          <Button color="secondary" disabled={loading} onClick={toggle}>
            Batalkan
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default RefundModal;
