import React from 'react';

const ExportCSV = props => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className="btn btn-sm btn-success" onClick={handleClick}>
        Export CSV!!
      </button>
    </div>
  );
};

export default ExportCSV;
