import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, Row, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import { request } from '../../../../config';

const UpdateModal = ({ row, toggle, isOpen, setDetailModal, name, setName, commission, setCommission, is_active, setIs_active, getDataAfterUpdate }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDataAfterUpdate()

    }, []);

    const isValid = () => {
        return true;
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isValid()) {
                setLoading(true)
                const result = await request.put(`/affiliates/products/${row.id}`, {
                    commission: commission,
                    is_active: is_active ? 1 : 0
                })

                if (result.status === 200) {
                    setLoading(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Berhasil',
                        text: `Update Komisi berhasil diubah.`,
                        showConfirmButton: false,
                        timer: 1500
                    }).then(() => setDetailModal(false))
                }
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    return (
        <>
            <Modal size="lg" centered toggle={() => toggle(row, "detailModal")} isOpen={isOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="detailModalLabel">
                        Update Komisi Product
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setDetailModal(false)}

                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <ModalBody>
                    <Form onSubmit={handleUpdateSubmit} style={{ marginTop: 8 }}>
                        <Row>
                            <Col>
                                <Label>Nama Product</Label>
                                <FormGroup>
                                    <Input
                                        readOnly
                                        required
                                        placeholder="Nama Product"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Komisi</Label>
                                <FormGroup>
                                    <Input
                                        required
                                        placeholder="Komisi"
                                        name="commission"
                                        type="text"
                                        value={commission}
                                        onChange={(e) => setCommission(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label>Status Aktif</Label>
                                <FormGroup>
                                    <label className="custom-toggle">
                                        <input
                                            checked={is_active}
                                            value={is_active}
                                            type="checkbox"
                                            name="is_active"
                                            onChange={e => setIs_active(!is_active)}
                                        />
                                        <span
                                            className="custom-toggle-slider rounded-circle"
                                            data-label-off="Tidak"
                                            data-label-on="Aktif"
                                        />
                                    </label>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Button disabled={loading} color="primary" >
                            {loading ? (
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    &nbsp; Loading...
                                </>
                            ) : (
                                'Update'
                            )}
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

export default UpdateModal
