import React from 'react';
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import BankService from '../../../services/BankService';

class EditBank extends React.Component {
  state = {
    id: this.props.match.params.id,
    account_name: '',
    account_number: 0,
    bank_provider_id: 0,
    unit_id: '',
    banks: [],
    units: [
      { id: 1, name: 'Great' },
      { id: 2, name: 'Excellent' },
      { id: 3, name: 'Force' }
    ]
  };

  accountNameRef = React.createRef();
  accountNumberRef = React.createRef();
  bankProviderIdRef = React.createRef();

  componentDidMount() {
    this.getData();
    this.getBanks();
  }

  getData = async () => {
    try {
      const res = await BankService.detail(this.state.id);
      this.setState({
        account_name: res.data.account_name,
        account_number: res.data.account_number,
        bank_provider_id: res.data.bank_provider_id,
        unit_id: res.data.unit_id
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.message,
        onClose: this.props.history.push('/banks')
      });
    }
  };

  getBanks = async () => {
    try {
      const banks = await BankService.getBankProviders();
      this.setState({
        banks: banks.data
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'warning',
        title: 'Terjadi Kesalahan',
        onConfirm: () => this.props.history.push('/banks'),
        onCancel: () => this.props.history.push('/banks'),
        text: error.message
      });
    }
  };

  handleChange = e => {
    e.target.classList.remove('is-invalid');
    this.setState({ [e.target.name]: e.target.value });
  };

  isValid() {
    const { account_name, account_number, bank_provider_id } = this.state;

    if (bank_provider_id === '' || bank_provider_id === 0) {
      const ref = this.bankProviderIdRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (account_name === '') {
      const ref = this.accountNameRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (account_number === '' || account_number === 0) {
      const ref = this.accountNumberRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    }

    return true;
  }

  onSubmit = async e => {
    e.preventDefault();

    const { account_name, account_number, bank_provider_id, unit_id } = this.state;

    if (this.isValid()) {
      const data = {
        account_name,
        account_number,
        bank_provider_id,
        unit_id
      };

      try {
        const bank = await BankService.edit(this.state.id, data);
        if (bank.status === 200 && bank.message === 'OK') {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: this.props.history.push('/banks')
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => this.props.history.push('/banks'),
          onCancel: () => this.props.history.push('/banks'),
          text: error.message
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Edit Bank Akun" parentName="Tabel Bank" parentLink="/banks" />
        <Container className="mt--6" fluid>
          <div className="card-wrapper">
            <Card>
              <CardBody>
                <Form noValidate onSubmit={this.onSubmit}>
                  <Row>
                    <Col md="6">
                      <Label className="form-control-label" htmlFor="unit_id">
                        Unit
                      </Label>
                      <Input
                        id="unit_id"
                        placeholder="Pilih Unit"
                        type="select"
                        value={this.state.unit_id}
                        name="unit_id"
                        onChange={this.handleChange}
                      >
                        <option value="">All</option>
                        {this.state.units.map((unit, i) => (
                          <option key={i} value={unit.id}>
                            {unit.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label" htmlFor="bank_provider_id">
                        Bank
                      </Label>
                      <Input
                        id="bank_provider_id"
                        placeholder="Bank"
                        type="select"
                        value={this.state.bank_provider_id}
                        innerRef={this.bankProviderIdRef}
                        name="bank_provider_id"
                        onChange={this.handleChange}
                      >
                        <option value="">--Pilih Bank--</option>
                        {this.state.banks.map((bank, i) => (
                          <option key={i} value={bank.id}>
                            {bank.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label" htmlFor="account_name">
                        Pemilik Akun Bank
                      </Label>
                      <Input
                        id="account_name"
                        placeholder="Atas Nama"
                        type="text"
                        name="account_name"
                        value={this.state.account_name}
                        onChange={this.handleChange}
                        innerRef={this.accountNameRef}
                      />
                    </Col>
                    <Col md="6">
                      <Label className="form-control-label" htmlFor="account_number">
                        Nomor Rekening
                      </Label>
                      <Input
                        id="account_number"
                        placeholder="Nomor Rekening"
                        type="number"
                        value={this.state.account_number}
                        name="account_number"
                        onChange={this.handleChange}
                        innerRef={this.accountNumberRef}
                      />
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Button color="primary" className="btn btn-sm" type="submit">
                      Simpan
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

export default EditBank;
