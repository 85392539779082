import React, { useEffect, useState } from 'react';
import { Container, Button } from 'reactstrap';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import CustomerService from '../../../../services/CustomerService';
import DetailBillingTable from './DetailBillingTable';
import html2canvas from 'html2canvas';

function DetailBilling(props) {
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState({
    id: props.match.params.billing_id,
    bank_id: 0,
    total: 0,
    customer_id: 0,
    is_paid: 0,
    is_verified: 0,
    payment_date: '',
    evidence: '',
    description: '',
    start_date: '',
    end_date: '',
    user_id: 0,
    created_at: '',
    customer: {
      name: ''
    },
    details: [
      {
        order_number: '',
        additional_cost: 0,
        shipping_cost: 0,
        discount: 0,
        gross_amount: 0,
        tax: 0,
        grand_total: 0,
        items: [
          {
            qty: 1,
            name: '',
            price: 0,
            subtotal: 0
          }
        ]
      }
    ]
  });

  useEffect(() => {
    getDetailBilling(billing.id);
  }, []);

  const getDetailBilling = async billing_id => {
    try {
      const res = await CustomerService.getDetailBilling(billing_id);
      if (res.status === 200) {
        setBilling(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SimpleHeader
        name={`Detail Tagihan ${billing.id}`}
        parentName="Tagihan"
        parentLink={`/customers/${billing.customer_id}/billing`}
      />
      <Container className="mt--6" fluid>
        <DetailBillingTable billing={billing} />
        <div className="my-4">
          <Button
            className="btn btn-success"
            disabled={loading}
            onClick={() => {
              window.scroll(0, 0);
              setLoading(true);
              setTimeout(() => {
                const content = document.getElementById('section-to-print');
                const mywindow = window.open('', 'Print');
                html2canvas(content).then(canvas => {
                  setLoading(false);
                  const border = document.createElement('div');
                  border.setAttribute('id', 'border');
                  mywindow.document.body.appendChild(border);
                  mywindow.document.getElementById('border').appendChild(canvas);
                  mywindow.print();
                });
              }, 0);
            }}
          >
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Cetak'
            )}
          </Button>
          {` `}
        </div>
      </Container>
    </>
  );
}

export default DetailBilling;
