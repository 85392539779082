import React from 'react';
import { Button } from 'reactstrap';
import Can from '../../../../config/Can';
import { ROLE_CS, STATUS_ANTRI_RESEP, STATUS_CANCEL } from '../../../../constants';

function UbahButton(props) {
  if (props.row.status.id !== STATUS_CANCEL) {
    if (props.userRole === ROLE_CS && props.row.status.id > STATUS_ANTRI_RESEP) {
      return '';
    } else {
      return (
        <>
          <Can I="update" an="Order">
            <Button
              color="warning"
              type="button"
              size="sm"
              className="mb-2"
              // outline
              onClick={() => props.history.push('/orders/edit/' + props.row.order_id)}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-edit" />
              </span>
              <span className="btn-inner--text"> Ubah</span>
            </Button>
            <br />
          </Can>
        </>
      );
    }
  } else {
    return '';
  }
}

export default UbahButton;
