import Dropzone from 'dropzone';
import React from 'react';
import Swal from 'react-bootstrap-sweetalert';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { API_URL } from '../../../config';
import { CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER, CUSTOMER_RETAIL } from '../../../constants';
import CategoryService from '../../../services/CategoryService';
import ColorService from '../../../services/ColorService';
import CustomerTypeService from '../../../services/CustomerTypeService';
import ManufacturerService from '../../../services/ManufacturerService';
import MaterialService from '../../../services/MaterialService';
import ProductService from '../../../services/ProductService';
import TypeCarService from '../../../services/TypeCarService';

const UploadURL = API_URL + '/api/v1/upload/product/image';
Dropzone.autoDiscover = false;

class EditProduct extends React.Component {
  state = {
    alert: null,
    manufacturerOptions: [],
    typeCarOptions: [],
    categoryOptions: [{ id: null, name: '', vehicle_type_id: '' }],
    customerTypeOptions: [],
    colorOptions: [],
    materialOptions: [],
    sizeOptions: [],
    id: '',
    name: '',
    description: '',
    category_id: '',
    manufacturer_id: '',
    type_car_id: '',
    supplier_id: '',
    is_published: '',
    variant: {
      id: '',
      product_id: '',
      material_id: '',
      size: '',
      weight: '',
      hpp: '',
      // discount: '',
      prices: [
        {
          id: '',
          customer_type_id: '',
          price: ''
        }
      ]
    }
  };

  hideAlert = () => {
    this.setState({ alert: null }, () => {
      this.props.history.push('/products');
    });
  };

  errorAlert = message => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Terjadi Kesalahan"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </Swal>
      )
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil diubah
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  async getManufacturers() {
    let manufacturerOptions = await ManufacturerService.list();
    this.setState({ manufacturerOptions: manufacturerOptions.data });
  }

  async getTypeCars(categoryId) {
    let typeCarOptions = await TypeCarService.list(categoryId);
    this.setState({ typeCarOptions: typeCarOptions.data });
  }

  async getCategories() {
    let categoryOptions = await CategoryService.list();
    this.setState({ categoryOptions: categoryOptions.data });
  }

  async getCustomerTypes() {
    let customerTypeOptions = await CustomerTypeService.list();
    this.setState({ customerTypeOptions: customerTypeOptions.data });
  }

  async getMaterials() {
    let materialOptions = await MaterialService.list();
    this.setState({ materialOptions: materialOptions.data });
  }

  async getColors() {
    let colorOptions = await ColorService.list();
    this.setState({ colorOptions: colorOptions.data });
  }

  async getSize() {
    let sizeOptions = await ProductService.sizeProduct();
    this.setState({ sizeOptions: sizeOptions.data });
  }

  async getDefaultPriceAndWeight() {
    const { customerTypeOptions, category_id, variant } = this.state;
    if (customerTypeOptions.length > 0 && category_id && variant && variant.size) {
      try {
        const { data: defaultPrice } = await ProductService.getDefaultPrice({ category_id, size: variant.size });
        if (defaultPrice) {
          let prices = variant.prices.map(price => {
            if (price.customer_type_id === CUSTOMER_RETAIL) {
              price.price = defaultPrice.retail;
            } else if (price.customer_type_id === CUSTOMER_RESELLER) {
              price.price = defaultPrice.reseller;
            } else if (price.customer_type_id === CUSTOMER_BIG_SELLER) {
              price.price = defaultPrice.big_seller;
            }
            return price;
          });

          const { hpp, weight } = defaultPrice;

          let variantData = { ...variant, hpp, weight, prices };

          this.setState({ variant: variantData });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  initDropzone = () => {
    // this variable is to delete the previous image from the dropzone state
    // it is just to make the HTML DOM a bit better, and keep it light
    let currentMultipleFile = [];
    // multiple dropzone file - accepts any type of file
    new Dropzone(document.getElementById('dropzone-multiple'), {
      url: UploadURL,
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName('dz-preview-multiple')[0],
      previewTemplate: document.getElementsByClassName('dz-preview-multiple')[0].innerHTML,
      maxFiles: 10,
      acceptedFiles: 'image/*',
      init: function() {
        this.on('addedfile', function(file) {
          currentMultipleFile.push(file);
        });
        this.on('removedfile', file => {
          console.log(file);
        });
      },
      success: (file, response) => {
        this.setState(prevState => {
          let images = prevState.images;
          let url = response.data[0];
          images.push({ url });
          return { images };
        });
      }
    });
    document.getElementsByClassName('dz-preview-multiple')[0].innerHTML = '';
  };

  getDefaultPrices = () => {
    if (this.state.customerTypeOptions.length > 0) {
      let prices = [];
      for (let type of this.state.customerTypeOptions) {
        prices.push({ customer_type_id: type.id, price: '' });
      }
      return prices;
    }
  };

  async componentDidMount() {
    await this.getManufacturers();
    await this.getTypeCars();
    await this.getCategories();
    await this.getCustomerTypes();
    await this.getMaterials();
    await this.getColors();
    await this.getSize();

    this.getDefaultPriceAndWeight();
    // init dropzone
    // this.initDropzone();

    this.getData();

    // default variant
    this.setState(prevState => {
      let variant = prevState.variant;
      if (this.state.customerTypeOptions.length > 0) {
        for (let type of this.state.customerTypeOptions) {
          variant.prices.push({ customer_type_id: type.id, price: 0 });
        }
        return { variant };
      }
    });
  }

  getData = () => {
    const { id } = this.props.match.params;
    ProductService.findById(id)
      .then(({ data }) => {
        data.manufacturer_id = data.manufacturer_id ? data.manufacturer_id : '';
        data.type_car_id = data.type_car_id ? data.type_car_id : '';
        data.description = data.description ? data.description : '';
        this.setState({ ...data });
      })
      .catch(error => {
        this.errorAlert(error.message);
      });
  };

  // handleChange = e => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  // };

  handleChangeVariant = e => {
    e.persist();

    // console.log({ targetName: e.target.name, value: e.target.value, index });
    this.setState(
      prevState => {
        let variant = prevState.variant;
        variant[e.target.name] = e.target.value;

        return { variant };
      },
      async () => {
        if (e.target.name === 'size') {
          await this.getDefaultPriceAndWeight();
        }
      }
    );
  };

  handleChangeVariantPrice = (price_key, customer_type_id, price) => {
    this.setState(prevState => {
      let variant = prevState.variant;
      variant.prices[price_key].customer_type_id = customer_type_id;
      variant.prices[price_key].price = price;

      return { variant };
    });
  };

  handleChange = e => {
    e.persist();

    this.setState(
      {
        [e.target.name]: e.target.value
      },
      async () => {
        if (e.target.name === 'category_id') {
          this.setState({ type_car_id: '' });
          this.getTypeCars(e.target.value);
          await this.getDefaultPriceAndWeight();
        }
      }
    );
  };

  isValid = () => {
    return true;
  };

  onSubmit = async e => {
    e.preventDefault();

    const { id } = this.props.match.params;

    const data = {
      name: this.state.name,
      category_id: this.state.category_id,
      manufacturer_id: this.state.manufacturer_id,
      type_car_id: this.state.type_car_id,
      description: this.state.description,
      variant: this.state.variant
    };

    try {
      await ProductService.update(id, data);
      this.successAlert();
    } catch (e) {
      this.errorAlert(e.message);
    }
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Ubah Produk" parentName="Produk" parentLink="/products" />
        <Container className="mt--6" fluid>
          <div className="card-wrapper">
            <Card>
              <Form className="needs-validation" onSubmit={this.onSubmit}>
                <CardHeader>
                  <h3 className="mb-0">Produk</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationCategory">
                          Produk
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          value={this.state.category_id}
                          id="validationCategory"
                          type="select"
                          name="category_id"
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih Produk--</option>
                          {this.state.categoryOptions.map(category => (
                            <option value={category.id} key={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Input>

                        <div className="invalid-feedback">Produk tidak boleh kosong!</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationManufacturer">
                          Pabrikan
                        </label>
                        <Input
                          value={this.state.manufacturer_id}
                          id="validationManufacturer"
                          type="select"
                          name="manufacturer_id"
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih Pabrikan--</option>
                          {this.state.manufacturerOptions.map(manufacturer => (
                            <option value={manufacturer.id} key={manufacturer.id}>
                              {manufacturer.name}
                            </option>
                          ))}
                        </Input>

                        <div className="invalid-feedback">Pabrikan tidak boleh kosong!</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationName">
                          Merek / Tipe
                          {/* <sup>
                            <span className="text-red">*</span>
                          </sup> */}
                        </label>
                        <Input
                          value={this.state.name}
                          id="validationName"
                          placeholder="Merek / Tipe"
                          type="text"
                          name="name"
                          onChange={this.handleChange}
                        />
                        {/* <div className="invalid-feedback">Nama tidak boleh kosong!</div> */}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationTypeCar">
                          Jenis Kendaraan
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          value={this.state.type_car_id}
                          id="validationTypeCar"
                          type="select"
                          name="type_car_id"
                          placeholder="--Jenis Kendaraan--"
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih Jenis Kendaraan--</option>
                          {this.state.typeCarOptions.map(typeCar => (
                            <option value={typeCar.id} key={typeCar.id}>
                              {typeCar.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    {/* Gambar Produk */}
                    {/* <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="customFileLang">
                          Gambar Produk
                        </label>
                        <div className="dropzone dropzone-multiple" id="dropzone-multiple">
                          <div className="fallback">
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="customFileUploadMultiple"
                                multiple="multiple"
                                type="file"
                                name="image"
                              />
                              <label className="custom-file-label" htmlFor="customFileUploadMultiple">
                                Pilih Gambar
                              </label>
                            </div>
                          </div>
                          <ListGroup className=" dz-preview dz-preview-multiple list-group-lg" flush>
                            <ListGroupItem className=" px-0">
                              <Row className=" align-items-center">
                                <Col className=" col-auto">
                                  <div className=" avatar">
                                    <img alt="..." className=" avatar-img rounded" data-dz-thumbnail src="..." />
                                  </div>
                                </Col>
                                <div className=" col ml--3">
                                  <h4 className=" mb-1" data-dz-name>
                                    ...
                                  </h4>
                                  <p className=" small text-muted mb-0" data-dz-size>
                                    ...
                                  </p>
                                </div>
                                <Col className=" col-auto">
                                  <Button size="sm" color="danger" data-dz-remove>
                                    <i className="fas fa-trash" />
                                  </Button>
                                </Col>
                              </Row>
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                      </FormGroup>
                    </Col>
                     */}
                    {/* Deskripsi */}
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationDesc">
                          Deskripsi Produk
                        </label>
                        <Input
                          value={this.state.description}
                          id="validationDesc"
                          type="textarea"
                          name="description"
                          rows="3"
                          placeholder="Deskripsi Produk"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* Variants */}
                </CardBody>

                <CardBody>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor={'validationMaterial'}>
                          Bahan
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          id={'validationMaterial'}
                          type="select"
                          name="material_id"
                          value={this.state.variant.material_id}
                          onChange={this.handleChangeVariant}
                        >
                          <option value="">--Pilih Bahan--</option>
                          {this.state.materialOptions.map(material => (
                            <option value={material.id} key={material.id}>
                              {material.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor={'validationColor'}>
                          Ukuran
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          id={'validationColor'}
                          type="select"
                          name="size"
                          value={this.state.variant.size}
                          onChange={this.handleChangeVariant}
                        >
                          <option value="">--Pilih Ukuran--</option>
                          {this.state.sizeOptions.map((size, key) => (
                            <option value={size.name} key={key}>
                              {size.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="inputWeight">
                          Berat (gr)
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          id="inputWeight"
                          placeholder="1000"
                          type="number"
                          name="weight"
                          value={this.state.variant.weight}
                          onChange={this.handleChangeVariant}
                        />
                      </FormGroup>
                    </Col>
                    {this.state.variant.length > 1 && (
                      <Col md="3">
                        <Button color="danger" type="button" size="sm" onClick={e => this.handleRemoveVariant(e)}>
                          <span className="btn-inner--icon">
                            <i className="fas fa-trash" />
                          </span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="inputHpp">
                          Hpp
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          id="inputHpp"
                          placeholder="50000"
                          type="number"
                          name="hpp"
                          value={this.state.variant.hpp}
                          onChange={this.handleChangeVariant}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="inputDiscount">
                          Diskon
                        </label>
                        <Input
                          id="inputDiscount"
                          placeholder="1000"
                          type="number"
                          name="discount"
                          value={this.state.variant.discount}
                          onChange={this.handleChangeVariant}
                        />
                      </FormGroup>
                    </Col> */}
                    {this.state.customerTypeOptions.map((customer_type, price_key) => {
                      // pick price by customer type id
                      let priceObject = this.state.variant.prices.find(e => e.customer_type_id === customer_type.id);

                      let price = priceObject ? priceObject.price : 0;

                      return (
                        <Col md="3" key={price_key}>
                          <FormGroup>
                            <label className="form-control-label" htmlFor={'example2cols2Input' + price_key}>
                              Harga {customer_type.name}
                              <sup>
                                <span className="text-red">*</span>
                              </sup>
                            </label>
                            <Input
                              required
                              id={'example2cols2Input' + price_key}
                              placeholder="50000"
                              type="number"
                              name="price"
                              value={price}
                              onChange={e => this.handleChangeVariantPrice(price_key, customer_type.id, e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col>
                      <sup>
                        <span className="text-red">*) Wajib diisi</span>
                      </sup>
                    </Col>
                  </Row>
                  {/* Harga Varian */}
                </CardBody>
                <CardBody>
                  <Button color="primary" type="submit">
                    Simpan
                  </Button>
                </CardBody>
              </Form>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

export default EditProduct;
