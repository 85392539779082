import { request } from '../config';

// versi function
function AddonService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/addons', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;
    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // list all data
  const list = async () => {
    const response = await request.get(`/addons/list`);
    return response.data;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/addons/${id}`);
    return response;
  };

  const save = async data => {
    const response = await request.post(`/addons`, data);
    return response;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/addons/${id}`, data);
    return response;
  };

  const remove = async id => {
    const response = await request.delete(`/addons/${id}`);
    return response.data;
  };

  const uploadImage = async image => {
    const { data: response } = await request.post(`/upload/order/image`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  };

  const removeImage = async url => {
    try {
      const response = await request.delete(`/upload`, {
        data: { url }
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  return { getDatatable, list, findById, save, update, remove, removeImage, uploadImage };
}

export default AddonService();
