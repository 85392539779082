import React, { useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
// import CurrencyInput from '../../../../helpers/CurrencyInput';
import CustomerService from '../../../../services/CustomerService';

function AddBillingModal(props) {
  const [loading, setLoading] = useState(false);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');

  const startDateRef = useRef();
  const endDateRef = useRef();

  const handleClose = () => {
    setLoading(false);
    setStartDate('');
    setEndDate('');
    props.toggle('billingModalOpen');
  };

  const formatDate = date => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const isValid = () => {
    const invalidRef = (ref, apa) => {
      ref.classList.add('is-invalid');
      ref.focus();
    };

    if (!start_date || start_date === '') {
      invalidRef(startDateRef.current);
      return false;
    } else if (!end_date || end_date === '') {
      invalidRef(endDateRef.current, 'tangl');
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (isValid()) {
        setLoading(true);
        const res = await CustomerService.addBilling({
          customer_id: props.customer.id,
          end_date,
          start_date
        });
        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: `Tagihan untuk customer ${props.customer.name} berhasil dibuat.`
          }).then(() => {
            handleClose();
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal size="lg" centered toggle={handleClose} isOpen={props.isOpen}>
        <ModalHeader>Buat Tagihan untuk {props.customer.name}</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Tanggal Mulai Tagihan</Label>
                  <Input
                    id="start_date"
                    type="date"
                    name="start_date"
                    max={new Date().toISOString().split('T')[0]}
                    innerRef={startDateRef}
                    onKeyDown={e => e.preventDefault()}
                    value={start_date}
                    onChange={e => {
                      e.target.classList.remove('is-invalid');
                      const date = formatDate(e.target.value);

                      setStartDate(date);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Sampai Tanggal</Label>
                  <Input
                    id="end_date"
                    type="date"
                    name="end_date"
                    min={new Date(start_date ? start_date : new Date()).toISOString().split('T')[0]}
                    max={new Date().toISOString().split('T')[0]}
                    innerRef={endDateRef}
                    onKeyDown={e => e.preventDefault()}
                    value={end_date}
                    onChange={e => {
                      e.target.classList.remove('is-invalid');
                      const date = formatDate(e.target.value);

                      setEndDate(date);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button disabled={loading} color="primary">
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Simpan'
              )}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddBillingModal;
