import React, { useState } from 'react';
import { Card, Table } from 'reactstrap';
import ability from '../../../config/ability';
import ScoreboardService from '../../../services/ScoreboardService';
import DetailModal from './modal/DetailModal';

function NewDashboardTable(props) {
  const { detail, unit, periodicity, updateData } = props;
  const [isDetailDataModalOpen, setIsDetailDataModalOpen] = useState(false);
  const [detailDailyUserData, setDetailDailyUserData] = useState(null);
  const [sc, setSc] = useState(null);
  const [loading, setLoading] = useState(false);

  function toggleModal(sc) {
    if (!sc) {
      setSc(null);
      // updateData();
    }

    // console.log('type', unit);
    setIsDetailDataModalOpen(!isDetailDataModalOpen);
  }

  // only for Emirate
  const getDetailData = async sc => {
    if (ability.can('update', 'Scoreboard')) {
      setLoading(true);
      setSc({ ...sc, type: 'emirate', periodicity });

      try {
        const userDataResponse = await ScoreboardService.getDetailUserData(sc.id, 'emirate');
        if (userDataResponse.status === 200) {
          setLoading(false);
          setDetailDailyUserData(userDataResponse.data.data);
          toggleModal(sc);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      console.log('tidak diizinkan');
    }
  };

  const renderTableData = () => {
    return detail.map((detail, i) => {
      if (detail[periodicity].length > 0) {
        return (
          <Card key={i} style={{ background: '#1a253f' }}>
            <h3 className="pt-2 px-3 text-white">{detail.level_name ? `Level ${detail.level_name}` : ''}</h3>
            <Table responsive borderless striped>
              <thead style={{ background: '#223358', color: '#fff' }}>
                <tr>
                  <th style={{ borderBottom: '0.5px solid #223358' }}>Rank</th>
                  <th style={{ borderBottom: '0.5px solid #223358', width: '25%' }}>Nama</th>
                  <th style={{ borderBottom: '0.5px solid #223358', textAlign: 'right' }}>OMZET</th>
                  <th style={{ borderBottom: '0.5px solid #223358' }}>Leads</th>
                  <th style={{ borderBottom: '0.5px solid #223358' }}>Deal</th>
                  <th style={{ borderBottom: '0.5px solid #223358' }}>Konversi</th>
                  <th style={{ borderBottom: '0.5px solid #223358' }}>Rasio Ads</th>
                </tr>
              </thead>

              <tbody style={{ color: '#fff', fontSize: '16px' }}>
                {detail[periodicity].map((sc, i) => {
                  return (
                    <tr key={i}>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          padding: 10,
                          fontSize: '0.9em',
                          textAlign: 'center'
                        }}
                      >
                        {i + 1}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          padding: 10,
                          fontSize: '0.9em',
                          width: '25%',
                          cursor:
                            ['carmat', 'seatcover'].includes(unit) && periodicity === 'daily' ? 'pointer' : 'cursor'
                        }}
                        onClick={() => {
                          if (!loading && periodicity === 'daily' && ['carmat', 'seatcover'].includes(unit)) {
                            getDetailData(sc);
                          }
                        }}
                      >
                        {loading ? (
                          <>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          </>
                        ) : (
                          <>
                            <img
                              className="rounded-circle"
                              width={30}
                              alt="..."
                              src={`https://api.dicebear.com/7.x/initials/svg?seed=${sc.name}&backgroundColor=3949ab,3499ab,00897b&backgroundType=gradientLinear`}
                            />{' '}
                            &nbsp; {sc.name}
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          padding: 10,
                          fontSize: '0.9em',
                          textAlign: 'center'
                        }}
                      >
                        {sc.omzet.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          padding: 10,
                          fontSize: '0.9em',
                          textAlign: 'center'
                        }}
                      >
                        {sc.leads}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          padding: 10,
                          fontSize: '0.9em',
                          textAlign: 'center'
                        }}
                      >
                        {sc.deals}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          padding: 10,
                          fontSize: '0.9em',
                          textAlign: 'center'
                        }}
                      >
                        {(() => {
                          const deals = sc.deals;
                          const leads = sc.leads;
                          const conversion = (deals / leads) * 100;
                          const isConversionNan = isNaN(conversion);
                          if (isConversionNan || conversion === Infinity) {
                            return `0 %`;
                          } else {
                            return `${conversion > 0 ? conversion.toFixed(2) : conversion} %`;
                          }
                        })()}
                      </td>
                      <td
                        style={{
                          verticalAlign: 'middle',
                          padding: 4,
                          fontSize: '0.9em',
                          textAlign: 'center'
                        }}
                      >
                        {(() => {
                          const budget = sc.budget;
                          const omzet = sc.omzet;
                          const bor = (budget / omzet) * 100;
                          const isBorNan = isNaN(bor);
                          if (isBorNan || bor === Infinity) {
                            return `0 %`;
                          } else {
                            return `${bor > 0 ? bor.toFixed(2) : bor} %`;
                          }
                        })()}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        );
      }
      return null;
    });
  };

  return (
    <div>
      <h2 className="text-white">{props.name}</h2>
      {renderTableData()}
      <DetailModal
        sc={sc}
        userData={detailDailyUserData}
        toggleModal={toggleModal}
        updateData={updateData}
        isDetailDataModalOpen={isDetailDataModalOpen}
      />
    </div>
  );
}

export default NewDashboardTable;
