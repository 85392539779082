import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table
} from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';
import ReportService from '../../../services/ReportService';

// import PieChart from '../../../components/Charts/PieChart';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import 'moment/locale/id';
import OrderService from '../../../services/OrderService';
import './ReportDealmaker.scss';
moment.locale('id');

am4core.useTheme(am4themes_material);

function ReportDealmaker() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unitOptions, setUnitOptions] = useState([]);
  const [picOptions, setPicOptions] = useState([]);
  const [unitId, setUnitId] = useState(undefined);
  const [pic, setPic] = useState(undefined);
  const [startDate, setStartDate] = useState(
    moment()
      .startOf('month')
      .format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [reportData, setReportData] = useState([]);
  const [totalData, setTotalData] = useState(null);

  const startDateRef = useRef();
  const endDateRef = useRef();

  useEffect(() => {
    getUnitOptions();
    // getListPic();
  }, []);

  const getUnitOptions = () => {
    setLoading(true);
    OrderService.getUnitOptions()
      .then(({ data }) => setUnitOptions(data))
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const createSeries = chart => {
    const series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.category = 'name';
    series.dataFields.value = 'value';
    series.labels.template.adapter.add('radius', function(radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return 0;
      }
      return radius;
    });

    series.labels.template.adapter.add('fill', function(color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return am4core.color('#000');
      }
      return color;
    });

    series.labels.template.text = "{category}: {value.percent.formatNumber('#.00')}%";
    series.legendSettings.valueText = "{value.percent.formatNumber('#.00')}%";
  };

  const amchartConfig = async data => {
    let productChart = am4core.create('product-charts', am4charts.PieChart);
    const categoryCoverData = data.category_covers.map(oke => ({
      name: oke.name,
      value: oke.qty
    }));

    productChart.data = categoryCoverData;
    const categoryChartTitle = productChart.titles.create();
    categoryChartTitle.text = 'Produk';
    categoryChartTitle.fontSize = 18;
    categoryChartTitle.wrap = true;
    productChart.paddingTop = 0;
    productChart.paddingBottom = 0;
    productChart.radius = am4core.percent(50);
    createSeries(productChart);
    productChart.legend = new am4charts.Legend();
    productChart.legend.valueLabels.template.align = 'left';
    productChart.legend.valueLabels.template.textAlign = 'start';
    // productChart.legend.position = 'bottom';

    let platformChart = am4core.create('platform-charts', am4charts.PieChart);
    const platformData = data.platforms.map(platform => ({
      name: platform.platform,
      value: platform.qty
    }));

    platformChart.data = platformData;
    const platformChartTitle = platformChart.titles.create();
    platformChartTitle.text = 'Platform';
    platformChartTitle.fontSize = 18;
    platformChart.paddingTop = 0;
    platformChart.paddingBottom = 0;
    platformChart.radius = am4core.percent(50);
    createSeries(platformChart);
    platformChart.legend = new am4charts.Legend();
    platformChart.legend.valueLabels.template.align = 'left';
    platformChart.legend.valueLabels.template.textAlign = 'start';
    // platformChart.legend.position = 'bottom';
  };

  const handleChangeUnit = e => {
    const unit_id = e.target.value;
    setUnitId(unit_id);
    if (unit_id) {
      getListPic(unit_id);
    } else {
      setPicOptions([]);
      setPic('');
    }
  };

  const getListPic = unitId => {
    setLoading(true);
    ReportService.getCsList({ unit_id: unitId })
      .then(({ data: res }) => {
        setPicOptions(res.data);
        setPic('');
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const validate = () => {
    if (!startDate) {
      setError('Mohon isi tanggal mulai');
      return false;
    } else if (!endDate) {
      setError('Mohon isi tanggal berakhir');
      return false;
    } else if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
      setError('Tanggal mulai tidak boleh lebih dari tanggal selesai');
      return false;
    }

    return true;
  };

  const handleSearch = async e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setError('');

      const data = {
        unit_id: unitId,
        start_date: startDate,
        end_date: endDate
      };

      if (pic) {
        data.user_id = pic;
      }

      try {
        const response = await ReportService.getDealmakerReport(data);
        setLoading(false);

        if (response.status === 200) {
          if (response.data.data.total) {
            setTotalData(response.data.data.total);
          } else {
            setTotalData(null);
          }
          setReportData(response.data.data.summary);
          amchartConfig(response.data.data.charts);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const handleDownload = async () => {
    const data = {
      unit_id: unitId,
      start_date: startDate,
      end_date: endDate
    };

    if (pic) {
      data.user_id = pic;
    }

    try {
      const response = await ReportService.downloadReportDealmaker(data);

      if (response.status === 200) {
        window.open(response.data.data.url, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMinEndDate = () => {
    if (startDate) {
      return new Date(new Date(startDate).setDate(new Date(startDate).getDate())).toISOString().split('T')[0];
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  const getMaxEndDate = () => {
    if (startDate) {
      const awal = new Date(startDate);
      const today = new Date();

      const diff = Math.abs(moment.duration(moment(today).diff(awal)).asDays()) + 1;

      if (diff > 185) {
        return new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 184)).toISOString().split('T')[0];
      } else {
        return new Date().toISOString().split('T')[0];
      }
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  return (
    <>
      {loading && <Loader />}
      <SimpleHeader name="Dealmaker" parentName="Laporan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cari Data Laporan</h3>
              </CardHeader>
              <CardBody>
                {error && (
                  <Alert color="danger">
                    <ul>
                      <li>{error}</li>
                    </ul>
                  </Alert>
                )}
                <Form onSubmit={handleSearch}>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="unit_id">Unit</label>
                        <Input id="unit_id" type="select" name="unit_id" value={unitId} onChange={handleChangeUnit}>
                          <option value="">-- Semua Unit --</option>
                          {unitOptions.map((unit, key) => (
                            <option key={key} value={unit.value}>
                              {unit.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="pic">List PIC</label>
                        <Input
                          type="select"
                          id="pic"
                          value={pic}
                          onChange={e => {
                            setPic(e.target.value);
                          }}
                        >
                          <option value="">--- Semua PIC ---</option>
                          {picOptions.map((status, i) => (
                            <option key={i} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="startDate">Dari</label>
                        <Input
                          id="startDate"
                          type="date"
                          name="startDate"
                          // min={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
                          max={new Date().toISOString().split('T')[0]}
                          innerRef={startDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={startDate}
                          onChange={e => {
                            e.persist();
                            setStartDate(e.target.value);
                            setEndDate('');
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="endDate">Sampai</label>
                        <Input
                          id="endDate"
                          type="date"
                          name="endDate"
                          // min={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
                          // max={new Date().toISOString().split('T')[0]}
                          min={getMinEndDate()}
                          max={getMaxEndDate()}
                          innerRef={endDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={endDate}
                          onChange={e => {
                            e.persist();
                            setEndDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button size="md" disabled={loading} color="primary">
                          Cari
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {reportData.length > 0 && (
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3 className="mb-0">Laporan Dealmaker</h3>
                    <Button size="sm" color="success" onClick={handleDownload}>
                      Download
                    </Button>
                  </div>
                </CardHeader>

                <CardBody>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama CS</th>
                        <th>Biaya Ads</th>
                        <th>Budget Ratio</th>
                        <th>KPI Leads</th>
                        <th>Leads</th>
                        <th>Cost / Lead</th>
                        <th>Closing</th>
                        <th>Konversi</th>
                        <th>Cost / Deal</th>
                        <th>KPI Omzet</th>
                        <th>Omzet</th>
                        <th>Belum Tercapai</th>
                        <th>Ketercapaian</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.map((report, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td className="name-column">{report.name}</td>
                          <td>
                            {report.budget.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </td>
                          <td>{(report.budget_ratio * 100).toFixed(2)} %</td>
                          <td>{report.kpi_leads}</td>
                          <td>{report.leads}</td>
                          <td>
                            {report.cost_per_leads.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </td>
                          <td>{report.deals}</td>
                          <td>{(report.convertion * 100).toFixed(2)} %</td>
                          <td>
                            {report.cost_per_deals.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </td>
                          <td>
                            {report.kpi_omzet.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </td>
                          <td>
                            {report.omzet.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </td>
                          <td>
                            {report.omzet_not_achieved.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </td>
                          <td>{(report.omzet_ratio * 100).toFixed(2)} %</td>
                        </tr>
                      ))}
                      {totalData && (
                        <tr>
                          <th colSpan="2" style={{ textTransform: 'uppercase' }}>
                            total
                          </th>
                          <th>
                            {totalData.budget.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </th>
                          <th>{(totalData.budget_ratio * 100).toFixed(2)} %</th>
                          <th>{totalData.kpi_leads}</th>
                          <th>{totalData.leads}</th>
                          <th>
                            {totalData.cost_per_leads.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </th>
                          <th>{totalData.deals}</th>
                          <th>{(totalData.convertion * 100).toFixed(2)} %</th>
                          <th>
                            {totalData.cost_per_deals.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </th>
                          <th>
                            {totalData.kpi_omzet.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </th>
                          <th>
                            {totalData.omzet.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </th>
                          <th>
                            {totalData.omzet_not_achieved.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </th>
                          <th>{(totalData.omzet_ratio * 100).toFixed(2)} %</th>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <Row style={{ marginTop: 18 }}>
                    <Col md={12}>
                      <div
                        id="product-charts"
                        style={{
                          width: '100%',
                          height: '70rem',
                          fontSize: '10px',
                          marginTop: '50px'
                        }}
                      ></div>
                    </Col>
                    <Col md={12} className="mt-7">
                      <div id="platform-charts" style={{ width: '100%', height: '500px', fontSize: '10px' }}></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default ReportDealmaker;
