import React, { useState, useRef } from 'react';
import { Button, Card, CardBody, Form, Input, Container, Row, Col, Label, FormGroup, Alert } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import FaqService from '../../../../services/FaqService';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL;

function EditFaq(props) {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const questionRef = useRef();
  const answerRef = useRef();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      const response = await FaqService.detailFaq(props.match.params.faq_id);
      if (response.status === 200) {
        setAnswer(response.data.data.answer);
        setQuestion(response.data.data.question);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setNotValid({ current }) {
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  function isValid() {
    if (!question || question === '') {
      return setNotValid(questionRef);
    } else if (!answer || answer === '') {
      setAlertOpen(true);
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      const data = {
        question,
        answer,
        faq_category_id: props.match.params.faq_category_id
      };

      setLoading(true);
      try {
        const response = await FaqService.updateFaq(data, props.match.params.faq_id);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push(`/faq-categories/${props.match.params.faq_category_id}/faqs`)
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: 'Terjadi kesalahan',
          icon: 'error',
          text: error
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader
        name="Ubah FAQ"
        parentName="Tabel FAQ"
        parentLink={`/faq-categories/${props.match.params.faq_category_id}/faqs`}
      />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Pertanyaan</Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Pembayaran"
                        innerRef={questionRef}
                        value={question}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setQuestion(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <Alert color="danger" isOpen={alertOpen}>
                      Silakan isi jawabannya
                    </Alert>
                    <FormGroup>
                      <Label>Jawaban</Label>
                      <Editor
                        ref={answerRef}
                        apiKey="qg8o1y5w8gjays6rktt8924b8kdvo8har02ajwpdfkavk42d"
                        initialValue={answer}
                        value={answer}
                        init={{
                          // init_instance_callback: editorInit,
                          images_upload_handler: async function(blobInfo, success, failure, progress) {
                            const xhr = new XMLHttpRequest();
                            xhr.open('POST', `${API_URL}/api/v1/upload/pages/faq`);
                            xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);

                            xhr.upload.onprogress = function(e) {
                              progress((e.loaded / e.total) * 100);
                            };

                            xhr.onload = function() {
                              let json;

                              if (xhr.status < 200 || xhr.status >= 300) {
                                failure('HTTP Error: ' + xhr.status);
                                return;
                              }

                              const response = {
                                location: JSON.parse(xhr.responseText).data[0]
                              };
                              json = response;

                              if (!json || typeof json.location != 'string') {
                                failure('Invalid JSON: ' + xhr.responseText);
                                return;
                              }

                              success(json.location);
                            };

                            xhr.onerror = function() {
                              failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
                            };

                            const formData = new FormData();
                            formData.append('file', blobInfo.blob(), blobInfo.filename());

                            xhr.send(formData);
                          },
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: `undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | image help`
                        }}
                        onEditorChange={(content, editor) => {
                          setAnswer(content);
                          setAlertOpen(false);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button disabled={loading} color="primary" className="btn btn-sm" type="submit">
                    Ubah
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default EditFaq;
