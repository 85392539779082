import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';

import ReportService from '../../../services/ReportService';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

class Report extends React.Component {
  state = {
    loading: false,
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    start_date: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    error: ''
  };

  startDateRef = React.createRef();
  endDateRef = React.createRef();

  interval; // handle order

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleSearch = () => {
    this.getData();
  };

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleChange = e => {
    e.target.classList.remove('is-invalid');
    const date = this.formatDate(e.target.value);

    if (e.target.name === 'start_date') {
      this.setState({
        end_date: ''
      });
    }

    this.setState({
      [e.target.name]: date,
      error: ''
    });

    // const firstDate = new moment(e.target.value);
    // const today = new moment();

    // // const diff = Math.abs(moment.duration(moment(today).diff(firstDate)).asDays()) + 1;

    // const durr = Math.abs(firstDate.diff(today, 'days'));
    // console.log(durr);
  };

  onTableChange = (type, newState) => {
    const { page, sizePerPage } = newState;
    this.setState(
      prevState => ({
        ...prevState,
        page,
        sizePerPage
      }),
      () => this.getData()
    );
  };

  // componentDidMount() {
  //   this.getData();
  //   this.interval = setInterval(() => {
  //     this.getData();
  //   }, 300000);
  // }

  validate() {
    const { start_date, end_date } = this.state;

    if (!start_date) {
      this.setState({
        error: 'Mohon isi tanggal mulai'
      });
      return false;
    } else if (!end_date) {
      this.setState({
        error: 'Mohon isi tanggal berakhir'
      });
      return false;
    } else if (new Date(start_date).getTime() > new Date(end_date).getTime()) {
      this.setState({
        error: 'Tanggal mulai tidak boleh lebih dari tanggal selesai'
      });
      return false;
    }

    return true;
  }

  getData = () => {
    const { page, sizePerPage: limit, start_date, end_date } = this.state;

    if (this.validate()) {
      this.setState({ loading: true });
      ReportService.getDatatable(this.props.url, {
        page,
        limit,
        start_date,
        end_date
      })
        .then(response => {
          this.setState({
            data: response.data,
            totalSize: response.recordsTotal,
            page: response.page,
            loading: false
          });
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    }
  };

  getMaxEndDate = () => {
    if (this.state.start_date) {
      const firstDate = moment(this.state.start_date);
      const today = moment();

      const diff = Math.abs(moment.duration(moment(today).diff(firstDate)).asDays()) + 1;

      if (diff > 185) {
        return moment(this.state.start_date)
          .add('185', 'days')
          .toISOString()
          .split('T')[0];
      } else {
        return new Date().toISOString().split('T')[0];
      }
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  getMinEndDate = () => {
    if (this.state.start_date) {
      return new Date(new Date(this.state.start_date).setDate(new Date(this.state.start_date).getDate()))
        .toISOString()
        .split('T')[0];
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        <SimpleHeader name={this.props.title} parentName="Laporan" />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Filter</h3>
                </CardHeader>
                <CardBody>
                  {this.state.error && (
                    <Alert color="danger">
                      <ul>
                        <li>{this.state.error}</li>
                      </ul>
                    </Alert>
                  )}
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="start_date">Dari</label>
                        <Input
                          id="start_date"
                          type="date"
                          name="start_date"
                          max={this.getMaxEndDate()}
                          innerRef={this.startDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={this.state.start_date}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="end_date">Sampai</label>
                        <Input
                          id="end_date"
                          type="date"
                          name="end_date"
                          min={this.getMinEndDate()}
                          max={this.getMaxEndDate()}
                          innerRef={this.endDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={this.state.end_date}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button size="md" onClick={this.handleSearch} disabled={this.state.loading} color="primary">
                          Filter
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.data.length > 0 && (
            <Row>
              <Col>
                <Card>
                  <CardHeader className="border-0">
                    <Row>
                      <Col xs="6">
                        <h3 className="mb-0">{this.props.title}</h3>
                      </Col>
                    </Row>
                  </CardHeader>

                  <ToolkitProvider
                    data={this.state.data}
                    keyField="no"
                    columns={this.props.columns}
                    // search
                  >
                    {props => {
                      return (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            remote={{
                              filter: false,
                              pagination: true,
                              sort: false
                            }}
                            loading={true}
                            {...props.baseProps}
                            bootstrap4={true}
                            pagination={paginationFactory({
                              page: this.state.page,
                              sizePerPage: this.state.sizePerPage,
                              totalSize: this.state.totalSize,
                              showTotal: true,
                              withFirstAndLast: true,
                              sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                <div className="dataTables_length" id="datatable-basic_length">
                                  <label>
                                    Show{' '}
                                    {
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm"
                                        onChange={e => onSizePerPageChange(e.target.value)}
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                    }{' '}
                                    entries.
                                  </label>
                                </div>
                              )
                            })}
                            bordered={false}
                            onTableChange={this.onTableChange}
                          />
                        </div>
                      );
                    }}
                  </ToolkitProvider>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default Report;
