import React from 'react';
import { Card, CardBody, CardHeader, Row, Table } from 'reactstrap';

const Platform = props => {
  return (
    <Card>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h5 className="h3 mb-0">{props.ads}</h5>
            <h5 className="h3 mb-0">{props.platform}</h5>
          </div>
        </Row>
      </CardHeader>

      <CardBody>
        <Table>
          <tbody
            style={{
              verticalAlign: 'middle',
              textAlign: 'center',
              color: '#000',
              textTransform: 'uppercase'
            }}
          >
            {!props.onlyLeads ? (
              <>
                <tr>
                  <td colSpan="2" style={{ border: '1px solid', fontWeight: '600', fontSize: '1em' }}>
                    Total Daily Budget + PPN
                  </td>
                  <td colSpan="4" style={{ border: '1px solid', fontWeight: '600', fontSize: '1em' }}>
                    Leads
                  </td>
                </tr>

                <tr>
                  <td colSpan="2" style={{ border: '1px solid', fontWeight: 'bold', fontSize: '2em' }}>
                    Rp {new Intl.NumberFormat('id-ID').format(props.dailyBudget)}
                  </td>
                  <td
                    colSpan="4"
                    rowSpan="3"
                    style={{
                      border: '1px solid',
                      fontWeight: 'bolder',
                      fontSize: '2em',
                      verticalAlign: 'middle'
                    }}
                  >
                    {new Intl.NumberFormat('id-ID').format(props.totalLeads)}
                  </td>
                </tr>

                <tr>
                  <td colSpan="2" style={{ border: '1px solid', fontWeight: '600', fontSize: '0.9em' }}>
                    Cost Per Leads + PPN
                  </td>
                </tr>

                <tr>
                  <td colSpan="2" style={{ border: '1px solid', fontWeight: 'bolder', fontSize: '2em' }}>
                    Rp {new Intl.NumberFormat('id-ID').format(props.costLeads)}
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td colSpan="6" style={{ border: '1px solid', fontWeight: '600', fontSize: '1em' }}>
                    Leads
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="6"
                    rowSpan="3"
                    style={{
                      border: '1px solid',
                      fontWeight: 'bolder',
                      fontSize: '2em',
                      verticalAlign: 'middle'
                    }}
                  >
                    {new Intl.NumberFormat('id-ID').format(props.totalLeads)}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default Platform;
