import { request } from '../config';

class TaxService {
  /**
   * Get bank lists
   */
  static async uploadFakturPajak(order_number, file) {
    const { data: response } = await request.post('/finance/tax/' + order_number, file);
    return response;
  }

  static async listBy() {
    const { data: response } = await request.get('/finance/tax/column-options');
    return response;
  }

  static async getDatatable(page, limit, filter_column, filter_text) {
    const response = await request.get('/finance/tax', {
      params: { page, limit, filter_column, filter_text }
    });

    return response.data;
  }
}

export default TaxService;
