import { request } from '../config';

// versi function
function RegionalService() {
  // Show List
  const province = async () => {
    const response = await request.get(`/provinces/list`);
    return response.data;
  };

  const districtByProvince = async id => {
    const response = await request.get(`/districts/by-province/${id}`);
    return response.data;
  };

  const subdistrictByDistrict = async id => {
    const response = await request.get(`/subdistricts/by-district/${id}`);
    return response.data;
  };

  return {
    province,
    districtByProvince,
    subdistrictByDistrict
  };
}

export default RegionalService();
