import axios from 'axios';
import Swal from 'sweetalert2';
import { FLOW_VERSION } from './constants';

// const source = axios.CancelToken.source();
// const controller = new AbortController();

export const API_URL = process.env.REACT_APP_API_URL;

const request = axios.create({
  baseURL: `${API_URL}/api/v1`,
  timeout: 300000, // 300s
  headers: {
    'Content-Type': 'application/json'
  }
  // signal: controller.signal,
  // cancelToken: source.token
});

request.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.params = { ...config.params, v: FLOW_VERSION };

    return config;
  },
  error => Promise.reject(error)
);

/**
 * refresh token and redirect to login page
 * */
request.interceptors.response.use(
  response => response,
  async error => {
    // console.log(error.message);
    if (error.message === 'Network Error') {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: 'Koneksi ke Server Bermasalah'
      });
    } else if (error.response && error.response.status === 401) {
      redirectLogin();
    } else if (
      error.response &&
      error.response.status === 400 &&
      error.response.data &&
      error.response.data.message
        .split(' ')
        .splice(0, 3)
        .join(' ')
        .toLowerCase() === 'customer sudah terdaftar.'
    ) {
      throw error;
    } else if (error.response && error.response.data) {
      Swal.fire({ icon: 'error', title: 'Terjadi Kesalahan', text: error.response.data.message });
    } else if (error.response.status === 400) {
      Swal.fire({ icon: 'error', title: 'Terjadi Kesalahan', text: error.response.data.message });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.message
      });
    }

    // return Error object with Promise
    // return Promise.reject(error.message)
  }
);

const redirectLogin = () => {
  localStorage.clear();
  window.location.href = 'login';
  // source.cancel();
  // controller.abort();
};

export { request };
