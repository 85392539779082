import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';
import ReportService from '../../../services/ReportService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import usePrevious from '../../../helpers/usePrevious';
import Can from '../../../config/Can';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

function ReportHistoryOrder(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [columnFilterOptions, setColumnFilterOptions] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [date, setDate] = useState('');
  const [filter_column, setFilterColumn] = useState('');

  const [data, setData] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(0);

  const prevPage = usePrevious(page);
  const prevLimit = usePrevious(limit);
  const prevRecordsTotal = usePrevious(recordsTotal);

  useEffect(() => {
    getColumnFilterOptions();

    if (prevPage !== page || prevLimit !== limit || prevRecordsTotal !== recordsTotal) {
      handleSearch(filter_column, date);
    }
  }, [page, limit]);

  const getColumnFilterOptions = async () => {
    try {
      const res = await ReportService.getHistoryOrderOptions();
      if (res.status === 200) {
        setColumnFilterOptions(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchValid = (filter_column, date) => {
    if (filter_column !== '' && date === '') {
      setError('Tanggal wajib diisi jika status diisi.');
      return false;
    }

    return true;
  };

  const handleSearch = async (filter_column, date) => {
    setError('');

    if (searchValid(filter_column, date)) {
      setLoading(true);

      const data = {
        page,
        limit,
        filter_column,
        filter_text: date
      };

      try {
        const response = await ReportService.getOrderHistoryReport(data);

        if (response.status === 200) {
          setLoading(false);
          setData(response.data.data);
          setRecordsTotal(response.data.recordsTotal);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const onTableChange = (type, newState) => {
    const { page, sizePerPage } = newState;

    setPage(page);
    setLimit(sizePerPage);
  };

  const handleExportData = async () => {
    try {
      const res = await ReportService.exportDataHistoryOrder({
        filter_column,
        filter_text: date
      });

      if (res.status === 200) {
        window.open(res.data.data.url, '_blank');
      }
    } catch (error) {
      setLoading(false);
      console.log();
    }
  };

  return (
    <>
      {loading && <Loader />}
      <SimpleHeader name="Riwayat Pesanan" parentName="Laporan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cari Data Laporan Riwayat Pesanan</h3>
              </CardHeader>
              <CardBody>
                {error && (
                  <Alert color="danger">
                    <ul>
                      <li>{error}</li>
                    </ul>
                  </Alert>
                )}
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    handleSearch(filter_column, date);
                  }}
                >
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="cs">Status Produk / Pesanan</label>
                        <Input
                          type="select"
                          value={filter_column}
                          onChange={e => {
                            e.persist();
                            setError('');
                            setDate('');
                            setFilterColumn(e.target.value);
                          }}
                        >
                          <option value="">--- Semua Status ---</option>
                          {columnFilterOptions.map((status, i) => (
                            <option key={i} value={status.value}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="startDate">Tanggal</label>
                        <Input
                          id="startDate"
                          type="date"
                          disabled={filter_column === '' ? true : false}
                          name="startDate"
                          max={new Date().toISOString().split('T')[0]}
                          onKeyDown={e => e.preventDefault()}
                          value={date}
                          onChange={e => {
                            e.persist();
                            setError('');
                            setDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button
                          onClick={async () => {
                            setFilterColumn('');
                            setDate('');
                            handleSearch('', '');
                          }}
                          size="md"
                          disabled={loading}
                        >
                          Reset
                        </Button>
                        <Button size="md" disabled={loading} color="primary">
                          Cari
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Tabel Order</h3>
                  </Col>
                  <Can I="export" a="History Order">
                    <Col xs="6">
                      <Button
                        className="btn btn-sm"
                        style={{ float: 'right' }}
                        onClick={handleExportData}
                        color="success"
                      >
                        Export Data
                      </Button>
                    </Col>
                  </Can>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="flow_id"
                columns={[
                  {
                    dataField: 'flow_id',
                    text: 'FLOW ID',
                    sort: false
                  },
                  {
                    dataField: 'order_number',
                    text: 'Nomor Order',
                    sort: false
                  },
                  {
                    dataField: 'product_name',
                    text: 'Produk (Nama, Tipe, Size)',
                    sort: false
                  },
                  {
                    dataField: 'qty',
                    text: 'QTY',
                    sort: false
                  },
                  {
                    dataField: 'grand_total',
                    text: 'TOTAL BAYAR',
                    sort: false,
                    formatter: cell =>
                      cell
                        ? cell.toLocaleString('id', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 })
                        : '-'
                  },
                  {
                    dataField: 'production_started',
                    text: 'PROSES PRODUKSI',
                    sort: false,
                    style: cell => {
                      return cell
                        ? ''
                        : {
                            textAlign: 'center'
                          };
                    },
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return cell ? moment(cell).format('dddd, DD/MM/YYYY HH:mm') : '-';
                    }
                  },
                  {
                    dataField: 'production_completed',
                    text: 'Produksi Selesai',
                    sort: false,
                    style: cell => {
                      return cell
                        ? ''
                        : {
                            textAlign: 'center'
                          };
                    },
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return cell ? moment(cell).format('dddd, DD/MM/YYYY HH:mm') : '-';
                    }
                  },
                  {
                    dataField: 'product_sent',
                    text: 'Produk dikirim',
                    sort: false,
                    style: cell => {
                      return cell
                        ? ''
                        : {
                            textAlign: 'center'
                          };
                    },
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return cell ? moment(cell).format('dddd, DD/MM/YYYY HH:mm') : '-';
                    }
                  }
                  // {
                  //   dataField: 'product_returned',
                  //   text: 'Produk Dikembalikan',
                  //   sort: false,
                  //   style: cell => {
                  //     return cell
                  //       ? ''
                  //       : {
                  //           textAlign: 'center'
                  //         };
                  //   },
                  //   formatter: (cell, row, rowIndex, formatExtraData) => {
                  //     return cell ? moment(cell).format('dddd, DD/MM/YYYY HH:mm') : '-';
                  //   }
                  // },
                  // {
                  //   dataField: 'product_as_stock',
                  //   text: 'Stok Produk',
                  //   sort: false,
                  //   style: cell => {
                  //     return cell
                  //       ? ''
                  //       : {
                  //           textAlign: 'center'
                  //         };
                  //   },
                  //   formatter: (cell, row, rowIndex, formatExtraData) => {
                  //     return cell ? moment(cell).format('dddd, DD/MM/YYYY HH:mm') : '-';
                  //   }
                  // },

                  // {
                  //   dataField: 'reproduction_started',
                  //   text: 'Reproduksi Dimulai',
                  //   sort: false,
                  //   style: cell => {
                  //     return cell
                  //       ? ''
                  //       : {
                  //           textAlign: 'center'
                  //         };
                  //   },
                  //   formatter: (cell, row, rowIndex, formatExtraData) => {
                  //     return cell ? moment(cell).format('dddd, DD/MM/YYYY HH:mm') : '-';
                  //   }
                  // }
                ]}
                // search
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote={{
                          filter: false,
                          pagination: true,
                          sort: false
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage: limit,
                          totalSize: recordsTotal,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                data.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ReportHistoryOrder;
