import React from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row, Table } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';

import ReportService from '../../../services/ReportService';

import moment from 'moment';
import 'moment/locale/id';
import OrderService from '../../../services/OrderService';
moment.locale('id');

class ReportOrderSource extends React.Component {
  state = {
    loading: false,
    data: [],
    summary: {
      totalLeads: 0,
      totalDeals: 0,
      totalOmzet: 0
    },
    start_date: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    error: '',
    unitOptions: [],
    picOptions: [],
    unit_id: '',
    pic: ''
  };

  startDateRef = React.createRef();
  endDateRef = React.createRef();

  interval; // handle order

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.getUnitOptions();
    // this.getListPic();
  }

  handleSearch = () => {
    this.getData();
  };

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleChangeDate = e => {
    e.target.classList.remove('is-invalid');
    const date = this.formatDate(e.target.value);

    if (e.target.name === 'start_date') {
      this.setState({
        end_date: ''
      });
    }

    this.setState({
      [e.target.name]: date,
      error: ''
    });
  };

  handleChangeUnit = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        error: ''
      },
      () => {
        if (this.state.unit_id) {
          this.getListPic();
        } else {
          this.setState({ pic: '', picOptions: [] });
        }
      }
    );
  };

  getListPic = () => {
    this.setState({ loading: true });
    ReportService.getCsList({ unit_id: this.state.unit_id })
      .then(({ data: res }) => {
        const data = res.data;

        this.setState({ pic: '', picOptions: data });
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getUnitOptions = () => {
    this.setState({ loading: true });
    OrderService.getUnitOptions()
      .then(({ data }) => this.setState({ unitOptions: data }))
      .catch(error => console.log(error))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  validate() {
    const { start_date, end_date } = this.state;

    if (!start_date) {
      this.setState({
        error: 'Mohon isi tanggal mulai'
      });
      return false;
    } else if (!end_date) {
      this.setState({
        error: 'Mohon isi tanggal berakhir'
      });
      return false;
    } else if (new Date(start_date).getTime() > new Date(end_date).getTime()) {
      this.setState({
        error: 'Tanggal mulai tidak boleh lebih dari tanggal berakhir'
      });
      return false;
    }

    return true;
  }

  getData = () => {
    const { start_date, end_date, unit_id, pic } = this.state;

    if (this.validate()) {
      this.setState({ loading: true });
      ReportService.getOrderSourceReport({
        start_date,
        end_date,
        unit_id,
        pic
      })
        .then(response => {
          const data = response.data;
          if (data.length > 0) {
            // count leads
            let totalLeads = data.reduce((a, b) => a + b.leads, 0);
            let totalDeals = data.reduce((a, b) => a + b.deals, 0);
            let totalOmzet = data.reduce((a, b) => a + b.omzet, 0);

            this.setState({
              loading: false,
              data,
              summary: {
                totalLeads,
                totalDeals,
                totalOmzet
              }
            });
          } else {
            this.setState({
              data: [],
              loading: false
            });
          }
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    }
  };

  getMaxEndDate = () => {
    if (this.state.start_date) {
      const firstDate = moment(this.state.start_date);
      const today = moment();

      const diff = Math.abs(moment.duration(moment(today).diff(firstDate)).asDays()) + 1;

      if (diff > 185) {
        return moment(this.state.start_date)
          .add('185', 'days')
          .toISOString()
          .split('T')[0];
      } else {
        return new Date().toISOString().split('T')[0];
      }
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  getMinEndDate = () => {
    if (this.state.start_date) {
      return new Date(new Date(this.state.start_date).setDate(new Date(this.state.start_date).getDate()))
        .toISOString()
        .split('T')[0];
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  render() {
    const { summary } = this.state;
    const { totalDeals, totalLeads, totalOmzet } = summary;
    return (
      <>
        {this.state.loading && <Loader />}
        <SimpleHeader name={this.props.title} parentName="Laporan" />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Filter</h3>
                </CardHeader>
                <CardBody>
                  {this.state.error && (
                    <Alert color="danger">
                      <ul>
                        <li>{this.state.error}</li>
                      </ul>
                    </Alert>
                  )}
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="start_date">Dari</label>
                        <Input
                          id="start_date"
                          type="date"
                          name="start_date"
                          max={this.getMaxEndDate()}
                          innerRef={this.startDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={this.state.start_date}
                          onChange={this.handleChangeDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="end_date">Sampai</label>
                        <Input
                          id="end_date"
                          type="date"
                          name="end_date"
                          min={this.getMinEndDate()}
                          max={this.getMaxEndDate()}
                          innerRef={this.endDateRef}
                          onKeyDown={e => e.preventDefault()}
                          value={this.state.end_date}
                          onChange={this.handleChangeDate}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="unit_id">Unit</label>
                        <Input
                          id="unit_id"
                          type="select"
                          name="unit_id"
                          value={this.state.unit_id}
                          onChange={this.handleChangeUnit}
                        >
                          <option value="">-- Semua Unit --</option>
                          {this.state.unitOptions.map((unit, key) => (
                            <option key={key} value={unit.value}>
                              {unit.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label htmlFor="pic">List PIC</label>
                        <Input
                          type="select"
                          id="pic"
                          value={this.state.pic}
                          onChange={e => {
                            this.setState({ pic: e.target.value });
                          }}
                        >
                          <option value="">--- Semua PIC ---</option>
                          {this.state.picOptions.map((status, i) => (
                            <option key={i} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button size="md" onClick={this.handleSearch} disabled={this.state.loading} color="primary">
                          Filter
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.data.length > 0 && (
            <Row>
              <Col>
                <Card>
                  <CardHeader className="border-0">
                    <Row>
                      <Col xs="6">
                        <h3 className="mb-0">{this.props.title}</h3>
                      </Col>
                    </Row>
                  </CardHeader>

                  <div className="react-bootstrap-table">
                    <Table className="table" bordered responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Sumber</strong>
                          </th>
                          <th>
                            <strong>Leads</strong>
                          </th>
                          <th>
                            <strong>Deal</strong>
                          </th>
                          <th>
                            <strong>Konversi</strong>
                          </th>
                          <th>
                            <strong>Omzet</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.map((item, i) => (
                          <tr key={i}>
                            <td>{item.order_source}</td>
                            <td style={{ textAlign: 'right' }}>{item.leads.toLocaleString('id')}</td>
                            <td style={{ textAlign: 'right' }}>{item.deals.toLocaleString('id')}</td>
                            <td style={{ textAlign: 'right' }}>
                              {item.leads > 0 ? ((item.deals / item.leads) * 100).toFixed(2) + '%' : '-'}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {item.omzet.toLocaleString('id', {
                                style: 'currency',
                                currency: 'IDR',
                                minimumFractionDigits: 0
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th style={{ textAlign: 'right' }}>
                            <strong>{totalLeads.toLocaleString('id')}</strong>
                          </th>
                          <th style={{ textAlign: 'right' }}>
                            <strong>{totalDeals.toLocaleString('id')}</strong>
                          </th>
                          <th style={{ textAlign: 'right' }}>
                            <strong>{totalLeads > 0 ? ((totalDeals / totalLeads) * 100).toFixed(2) + '%' : '-'}</strong>
                          </th>
                          <th style={{ textAlign: 'right' }}>
                            <strong>
                              {totalOmzet.toLocaleString('id', {
                                style: 'currency',
                                currency: 'IDR',
                                minimumFractionDigits: 0
                              })}
                            </strong>
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}

export default ReportOrderSource;
