import React from 'react';
import { Col, FormGroup } from 'reactstrap';
import * as Constants from '../../../../constants';

function PaymentEvidenceComponent(props) {
  if (
    props.platform === Constants.PLATFORM_REGULAR &&
    parseInt(props.payment_status_id) === parseInt(Constants.PAYMENT_STATUS_UNPAID)
  ) {
    return null;
  } else {
    return (
      <Col md="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="customFileLang">
            Bukti Transfer
            <sup>
              <span className="text-red">*</span>
            </sup>
          </label>
          <div className="custom-file">
            <input
              className="custom-file-input"
              id="customFileLang"
              lang="en"
              accept="image/*"
              ref={props.evidenceImageRef}
              type="file"
              multiple
              onChange={props.handleImage}
            />
            <label className="custom-file-label" htmlFor="customFileLang">
              Pilih file
            </label>
          </div>
        </FormGroup>
      </Col>
    );
  }
}

export default PaymentEvidenceComponent;
