import React, { useState } from 'react';

import { Modal, Button, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import Swal from 'sweetalert2';
import Dropzone from 'dropzone';
import CoverImageService from '../../../../services/CoverImageService';

Dropzone.autoDiscover = false;

function CoverImageModal(props) {
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState('');
  // const [imgPreview, setImgPreview] = useState('');

  const handleImage = async e => {
    e.preventDefault();
    e.persist();

    const item = e.target.files[0];

    // const reader = new FileReader();
    // reader.onload = event => {
    //   console.log(event.target.result);
    //   setImgPreview(event.target.result);
    // }; // data url!
    // reader.readAsDataURL(item);

    const formData = new FormData();
    formData.append('file', item);

    const params = {
      category_id: props.categoryId,
      type_car_id: props.row.type_car_id,
      color_id: props.row.color_id,
      cover_type_id: props.row.cover_type_id
    };

    if (props.row.cover_list_id) {
      params.cover_list_id = props.row.cover_list_id;
    }

    try {
      setLoading(true);

      const image = await CoverImageService.uploadImage(formData, params);

      if (image.status === 200) {
        setImageURL(image.data.data[0]);
        Swal.fire({
          icon: 'success',
          title: 'Gambar berhasil diupload'
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error, error.message);
    }
  };

  const closeModal = () => {
    setLoading(false);
    setImageURL('');
    props.toggleModal();
  };

  return (
    <Modal className="modal-dialog-centered" size="lg" isOpen={props.isOpen} toggle={closeModal}>
      <ModalHeader>
        {props.row &&
          [
            props.row.category_name,
            props.row.color_name,
            props.row.cover_type_name,
            props.row.cover_list_name,
            props.row.type_car_name
          ].join(' ')}
      </ModalHeader>
      <ModalBody>
        <div className="custom-file" style={{ maxWidth: '50%' }}>
          <input className="custom-file-input" id="customFileLang" lang="en" type="file" onChange={handleImage} />
          <label className="custom-file-label" htmlFor="customFileLang">
            Pilih file
          </label>
        </div>
        {(imageURL !== '' || imageURL.length > 0) && (
          <div>
            <img src={imageURL} alt={props.row && props.row.name} style={{ maxWidth: '100%', marginTop: 16 }} />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={loading} onClick={closeModal}>
          Tutup
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CoverImageModal;
