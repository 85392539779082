import moment from 'moment';
import React from 'react';
import Swal from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Can from '../../../config/Can';
import ProductService from '../../../services/ProductService';

import SearchBar from '../../../components/Search';
import ExportDataModal from './modal_export/ExportDataModal';

class TableProduct extends React.Component {
  state = {
    start_date: '',
    end_date: '',
    alert: null,
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortField: null,
    sortOrder: undefined,
    searchText: '',
    modalExportData: false
  };

  startDateRef = React.createRef();
  endDateRef = React.createRef();

  columns = [
    // {
    //   dataField: 'image',
    //   text: 'Gambar',
    //   sort: false,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //       <a className="avatar rounded-circle mr-3" href={cell} onClick={e => e.preventDefault()}>
    //         <img alt="..." src={cell} />
    //       </a>
    //     );
    //   }
    // },
    {
      dataField: 'no',
      text: 'Nomor',
      sort: false
    },
    {
      dataField: 'category_name',
      text: 'Produk',
      sort: true
    },
    {
      dataField: 'manufacturer_name',
      text: 'Pabrikan',
      sort: true
    },
    {
      dataField: 'name',
      text: 'Merek/Tipe',
      sort: true
    },
    {
      dataField: 'type_car_name',
      text: 'Jenis',
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell ? cell : '-';
      }
    },
    {
      dataField: 'variant.size',
      text: 'Ukuran',
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return cell ? cell : '-';
      }
    },
    {
      dataField: 'action',
      text: 'Aksi',
      sort: false,
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <Can I="update" a="Product">
              <Button color="warning" type="button" size="sm" outline onClick={() => this.handleUpdate(row.id)}>
                <span className="btn-inner--icon">
                  <i className="fas fa-user-edit" />
                </span>
                <span className="btn-inner--text"> Ubah</span>
              </Button>
            </Can>{' '}
            <Can I="delete" a="Product">
              <Button color="danger" type="button" size="sm" outline onClick={() => this.showConfirmAlert(row.id)}>
                <span className="btn-inner--icon">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text"> Hapus</span>
              </Button>
            </Can>
          </>
        );
      }
    }
  ];

  onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;
    this.setState(
      prevState => ({
        ...prevState,
        page,
        sizePerPage,
        sortField,
        sortOrder,
        searchText
      }),
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = this.state;
    ProductService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText).then(response => {
      this.setState({
        data: response.data,
        totalSize: response.recordsFiltered,
        page: response.page
      });
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
    this.getData();
  };

  showConfirmAlert = id => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Apakah anda yakin?"
          onCancel={this.hideAlert}
          onConfirm={() => this.handleDelete(id)}
          showCancel
          cancelBtnBsStyle="link"
          cancelBtnText="Batal"
          confirmBtnBsStyle="danger"
          confirmBtnText="Hapus"
          btnSize=""
          reverseButtons={false}
          showCloseButton={true}
          allowEscape={false}
          closeOnClickOutside={false}
        >
          Data tidak dapat dipulihkan
        </Swal>
      )
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Berhasil!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil dihapus
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  handleModalExportData = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  handleUpdate = id => {
    this.props.history.push('/products/edit/' + id);
  };

  handleDelete = id => {
    ProductService.remove(id)
      .then(response => {
        this.confirmedAlert();
      })
      .catch(error => {
        this.errorAlert(error.message);
      });
  };

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleChangeDate = e => {
    e.target.classList.remove('is-invalid');

    let value = e.target.value;
    if (['end_date', 'start_date'].includes(e.target.name)) {
      value = this.formatDate(e.target.value);
    }

    this.setState({
      [e.target.name]: value,
      error: ''
    });

    if (e.target.name === 'start_date') {
      this.setState({
        end_date: ''
      });
    }
  };

  getMinEndDate = () => {
    if (this.state.start_date) {
      return new Date(new Date(this.state.start_date).setDate(new Date(this.state.start_date).getDate()))
        .toISOString()
        .split('T')[0];
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  getMaxEndDate = () => {
    if (this.state.start_date) {
      const firstDate = moment(this.state.start_date);
      const today = moment();

      const diff = Math.abs(moment.duration(moment(today).diff(firstDate)).asDays()) + 1;

      if (diff > 90) {
        return moment(this.state.start_date)
          .add('90', 'days')
          .toISOString()
          .split('T')[0];
      } else {
        return new Date().toISOString().split('T')[0];
      }
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  validate = (start, end) => {
    if (!start || !end) {
      this.setState({
        error: 'Mohon isi rentang tanggal dengan benar'
      });
      return false;
    }

    return true;
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Produk - Merek/Tipe" parentName="Master Data" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Tabel Produk - Merek/Tipe</h3>
                    </Col>
                    <Can I="create" a="Product">
                      <Col className="text-right" xs="6">
                        <Link to="/products/add">
                          <Button className="btn-round btn-icon" color="primary" id="tooltip443412080" size="sm">
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">Tambah</span>
                          </Button>
                        </Link>{' '}
                        <Button
                          className="btn-round btn-icon"
                          color="danger"
                          id="tooltip443412080"
                          size="sm"
                          onClick={() => this.handleModalExportData('modalExportData')}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-file-export" />
                          </span>
                          <span className="btn-inner--text">Export Data</span>
                        </Button>
                      </Col>
                    </Can>
                  </Row>
                </CardHeader>
                <ToolkitProvider data={this.state.data} keyField="id" columns={this.columns} search>
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar className="form-control-sm" {...props.searchProps} />{' '}
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: this.state.page,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={this.onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
        <ExportDataModal
          toggle={this.handleModalExportData}
          isOpen={this.state.modalExportData}
          handleChangeDate={this.handleChangeDate}
          getMinEndDate={this.getMinEndDate}
          getMaxEndDate={this.getMaxEndDate}
          startDate={this.state.start_date}
          endDate={this.state.end_date}
          startDateRef={this.startDateRef}
          endDateRef={this.endDateRef}
          validate={this.validate}
          formatDate={this.formatDate}
        />
      </>
    );
  }
}

export default TableProduct;
