import { request } from "../config";

// versi function
function ShippingCostService() {

  // Show List Ongkir
  const getOngkir = async (data) => {
    const response = await request.post('/shipping-cost', data);
    return response.data;
  }

  return { getOngkir }
}

export default ShippingCostService()