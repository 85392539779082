import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { FLOW_VERSION } from './constants';

function ReceiveUpdate(props) {
  useEffect(() => {
    if (props.socket.connected) {
      const { socket, user } = props;
      console.log('ready to receive update', socket.id, user ? user.name : '');

      const alertReload = async data => {
        const notif = new Audio('https://coversuper.sgp1.cdn.digitaloceanspaces.com/audio/elegant-notification.mp3');
        await notif.play();

        Swal.fire({
          icon: 'info',
          title: `${data.message}. Versi ${data.version}`,
          showCancelButton: true,
          confirmButtonText: 'Reload Sekarang',
          cancelButtonText: `Ingatkan lagi dalam 1 menit`
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.location.reload();
          } else {
            console.log('reload nanti');
            setTimeout(() => alertReload(data), 60000);
          }
        });
      };

      socket.on('newFlowVersion', function(data) {
        // console.log(data);
        if (data.version !== FLOW_VERSION) {
          alertReload(data);
        }
      });

      return () => {
        socket.off('newFlowVersion');
      };
    }
  }, [props.socket]);

  return <div></div>;
}

const mapStateToProps = state => {
  return {
    socket: state.socket.io,
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(ReceiveUpdate);
