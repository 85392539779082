import html2canvas from 'html2canvas';
import React, { useEffect } from 'react';
import { Modal } from 'reactstrap';
// import Kupon2 from '../../../..//assets/img/kupon-dua.jpg';
// import Kupon4 from '../../../..//assets/img/kupon-empat.jpg';
// import Kupon1 from '../../../..//assets/img/kupon-satu.jpg';
// import Kupon3 from '../../../..//assets/img/kupon-tiga.jpg';
import {
  CATEGORY_COVER_MOBIL_DELUXE,
  CATEGORY_COVER_MOBIL_EXTREME_OUTDOOR,
  CATEGORY_COVER_MOBIL_PRIME_SOFT,
  CATEGORY_COVER_MOBIL_SUPERIOR,
  CATEGORY_COVER_MOBIL_SUPERIOR_RS,
  CATEGORY_KARPET
} from '../../../../constants';

const CouponModal = props => {
  const { isOpen, toggle, row } = props;

  useEffect(() => {
    if (row) getCanvas();
  }, [row]);

  const couponByCategory = data => {
    let image;

    const Kupon = '';

    switch (data.category.id) {
      case CATEGORY_COVER_MOBIL_DELUXE:
        image = Kupon;
        break;
      case CATEGORY_COVER_MOBIL_SUPERIOR:
        image = Kupon;
        break;
      case CATEGORY_COVER_MOBIL_SUPERIOR_RS:
        image = Kupon;
        break;
      case CATEGORY_COVER_MOBIL_PRIME_SOFT:
        image = Kupon;
        break;
      case CATEGORY_COVER_MOBIL_EXTREME_OUTDOOR:
        image = Kupon;
        break;
      case CATEGORY_KARPET:
        image = Kupon;
        break;
      default:
    }
    return image;
  };

  const getCanvas = async () => {
    setTimeout(() => {
      const content = document.getElementById('print-coupon-component');
      if (content) {
        content.setAttribute('style', 'padding: 1px');
        const mywindow = window.open('', 'Print');
        html2canvas(content).then(canvas => {
          const border = document.createElement('div');
          border.setAttribute('id', 'border');
          mywindow.document.body.appendChild(border);
          mywindow.document.getElementById('border').appendChild(canvas);
          mywindow.print();
          window.close();
        });
      }
    }, 1000);
  };

  return row ? (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => toggle(row, 'couponModal')} size="lg">
        <div id="print-coupon-component" className="position-relative">
          <h4
            className="position-absolute"
            style={{
              bottom: '5.5rem',
              left: '8rem',
              fontSize: '24px'
            }}
          >
            {row.id}
          </h4>
          <img src={couponByCategory(row)} width={'100%'} height={430} alt="" />
        </div>
      </Modal>
    </>
  ) : (
    ''
  );
};

export default CouponModal;
