import React from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import { STATUS_RESEP } from '../../../../constants';
import FlowService from '../../../../services/FlowService';
import OrderService from '../../../../services/OrderService';

class InputRecipeModal extends React.Component {
  state = {
    loading: false,
    length: 0,
    additional_length: 0,
    width: 0,
    height: 0,
    cover_height: 0,
    description: '',
    cover_model: '',
    alert: ''
  };

  lengthRef = React.createRef();
  widthRef = React.createRef();
  heightRef = React.createRef();
  coverHeightRef = React.createRef();
  coverModelRef = React.createRef();

  handleChange = e => {
    e.target.classList.remove('is-invalid');
    this.setState({
      [e.target.name]: e.target.value,
      alert: ''
    });
  };

  isValid = () => {
    const { length, width, height, cover_height, cover_model } = this.state;
    if (length === '' || length === 0) {
      this.setInvalid(this.lengthRef.current, 'Panjang');
    } else if (width === '' || width === 0) {
      this.setInvalid(this.widthRef.current, 'Lebar');
    } else if (height === '' || height === 0) {
      this.setInvalid(this.heightRef.current, 'Tinggi');
    } else if (cover_height === '' || cover_height === 0) {
      this.setInvalid(this.coverHeightRef.current, 'Tinggi cover');
    } else if (cover_model === '' || !cover_model) {
      this.setInvalid(this.coverModelRef.current, 'Model cover');
    } else {
      return true;
    }
  };

  setInvalid(current, field) {
    this.setState({
      alert: `${field} harus diisi`
    });
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  handleSubmit = async () => {
    this.setState({ loading: true });

    try {
      if (this.isValid()) {
        const resepObj = {
          length: this.state.length,
          additional_length: this.state.additional_length,
          width: this.state.width,
          height: this.state.height,
          cover_height: this.state.cover_height,
          cover_model: this.state.cover_model
        };

        const resep = await OrderService.resep(this.props.row.id, resepObj);

        const nextOrder = await FlowService.forward({
          order_detail_id: this.props.row.id,
          status_type_id: STATUS_RESEP,
          description: this.state.description
        });

        this.setState({ loading: false });
        this.toggle();
        if (resep && nextOrder && resep.status === 200 && nextOrder.status === 200) {
          this.props.confirmedActionAlert();
        }
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'terjadi kesalahan',
        text: error.message
      });
    }
  };

  toggle = () => {
    this.setState({
      loading: false,
      length: 0,
      additional_length: 0,
      width: 0,
      height: 0,
      cover_height: 0,
      description: '',
      cover_model: ''
    });
    this.props.toggleInputRecipeModal(this.props.row, 'isInputRecipeModalShow');
  };

  render() {
    const { row } = this.props;
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          fade={false}
          backdrop="static"
          toggle={this.toggle}
          style={{ maxWidth: '70%' }}
        >
          <ModalHeader toggle={this.toggle}>Masukkan Resep</ModalHeader>
          <ModalBody>
            {this.state.alert !== '' && <Alert color="danger">{this.state.alert}</Alert>}

            <div className="mb-4">
              <Row>
                <Col sm={4}>Kategori</Col>
                <Col sm={8}>:&#9;{row && row.category && row.category.name}</Col>
              </Row>
              <Row>
                <Col sm={4}>Nama Produk</Col>
                <Col sm={8}>:&#9;{row && row.product_name}</Col>
              </Row>
              <Row>
                <Col sm={4}>Tahun</Col>
                <Col sm={8}>:&#9;{row && row.year}</Col>
              </Row>
              <Row>
                <Col sm={4}>Warna Cover</Col>
                <Col sm={8}>:&#9;{row && row.color && row.color.name}</Col>
              </Row>
              <Row>
                <Col sm={4}>Catatan</Col>
                <Col sm={8}>:&#9;{row && row.note}</Col>
              </Row>
              {row && row.transmission_type && (
                <Row>
                  <Col sm={4}>Jenis Transmisi</Col>
                  <Col sm={8}>:&#9;{row && row.transmission_type}</Col>
                </Row>
              )}
            </div>

            <Form>
              <FormGroup row>
                <Label for="length" sm={4}>
                  Panjang (cm)
                </Label>
                <Col sm={8}>
                  <Row>
                    <Col>
                      <Input
                        innerRef={this.lengthRef}
                        type="number"
                        name="length"
                        id="length"
                        placeholder="masukkan panjang"
                        onChange={this.handleChange}
                        value={this.state.length}
                      />
                    </Col>
                    <Col style={{ lineHeight: '46px', flexGrow: 0, paddingRight: '5px', paddingLeft: '5px' }}>+</Col>
                    <Col>
                      <Input
                        type="number"
                        name="additional_length"
                        id="additional_length"
                        placeholder="masukkan panjang tambahan"
                        onChange={this.handleChange}
                        value={this.state.additional_length}
                      />
                    </Col>
                    <Col style={{ lineHeight: '46px', flexGrow: 0, paddingRight: '5px', paddingLeft: '5px' }}>=</Col>
                    <Col>
                      <Input
                        type="number"
                        value={`${Number(this.state.additional_length) + Number(this.state.length)}`}
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="width" sm={4}>
                  Lebar (cm)
                </Label>
                <Col sm={8}>
                  <Input
                    innerRef={this.widthRef}
                    type="number"
                    name="width"
                    id="width"
                    placeholder="masukkan lebar"
                    onChange={this.handleChange}
                    value={this.state.width}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} for="height">
                  Tinggi (cm)
                </Label>
                <Col sm={8}>
                  <Input
                    innerRef={this.heightRef}
                    type="number"
                    name="height"
                    id="height"
                    placeholder="masukkan tinggi"
                    onChange={this.handleChange}
                    value={this.state.height}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} for="cover_height">
                  Tinggi Cover (cm)
                </Label>
                <Col sm={8}>
                  <Input
                    innerRef={this.coverHeightRef}
                    type="number"
                    name="cover_height"
                    id="cover_height"
                    placeholder="masukkan tinggi"
                    onChange={this.handleChange}
                    value={this.state.cover_height}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} for="cover_model">
                  Cover Model
                </Label>
                <Col sm={8}>
                  <Input
                    innerRef={this.coverModelRef}
                    type="select"
                    name="cover_model"
                    id="cover_model"
                    onChange={this.handleChange}
                    value={this.state.cover_model}
                  >
                    <option value="">-- Pilih Model --</option>
                    <option value="REGULER">Reguler</option>
                    <option value="BODY FIT">Body Fit</option>
                    <option value="SUPER BODY FIT">Super Body Fit</option>
                    <option value="SUPER BODY FIT LUXURY">Super Body Fit Luxury</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} for="description">
                  Catatan Resep
                </Label>
                <Col sm={8}>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder="catatan"
                    onChange={this.handleChange}
                    value={this.state.description}
                  />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" disabled={this.state.loading} onClick={this.handleSubmit}>
              {this.state.loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Submit'
              )}
            </Button>{' '}
            <Button color="secondary" disabled={this.state.loading} onClick={this.toggle}>
              Batalkan
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default InputRecipeModal;
