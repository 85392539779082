import moment from 'moment';
import 'moment/locale/id';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import {
  SHIPPING_STATUS_MENUNGGU_KURIR,
  SHIPPING_STATUS_MENUNGGU_RESI,
  SHIPPING_STATUS_PENGIRIMAN_TERJADWAL,
  SHIPPING_STATUS_SEDANG_DIKIRIM,
  SHIPPING_STATUS_SELESAI,
  SHIPPING_STATUS_SIAP_PICKUP
} from '../../../constants';
import ShippingManagementService from '../../../services/ShippingManagementService';
import CancelModal from './Modal/CancelModal';
import RequestModal from './Modal/RequestModal';
import TrackingModal from './Modal/TrackingModal';

const ShippingManagement = () => {
  const [data, setData] = useState([]);
  const [nonselectable, setNonSelectable] = useState([]);
  const [selectShipping, setSelectShipping] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    isRequestModalOpen: false,
    isCancelModal: false,
    isTrackingModal: false
  });
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    recordsTotal: 0,
    shipping_status_id: '',
    filter_column: '',
    filter_text: ''
  });

  const handleSelect = (row, isSelect) => {
    console.log(row.id, isSelect);
    if (isSelect) {
      setSelectShipping([...selectShipping, row.id]);
    } else {
      const newData = selectShipping.filter((item, idx) => item !== row.id);
      setSelectShipping(newData);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);

    if (isSelect) {
      setSelectShipping(ids);
    } else {
      setSelectShipping([]);
    }
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgColor: '#84d8f5a3',
    nonSelectable: nonselectable,
    onSelect: handleSelect,
    onSelectAll: handleOnSelectAll
  };

  useEffect(() => {
    getParams();
    getData();
    getStatusShipping();
    getFilterOptions();
  }, []);

  useEffect(() => {
    getData();
  }, [params.page, params.limit]);

  useEffect(() => {
    const newData = data
      .map(item => (item.shipping_status_id > SHIPPING_STATUS_SIAP_PICKUP ? item.id : null))
      .filter(e => e);

    setNonSelectable(newData);
  }, [data]);

  const getData = async () => {
    const payload = {
      page: parseInt(params.page),
      limit: params.limit,
      shipping_status_id: params.shipping_status_id,
      filter_column: params.filter_column,
      filter_text: params.filter_text
    };

    try {
      setLoading(true);
      const response = await ShippingManagementService.datatableShipping(payload);

      if (response.status === 200) {
        setData(response.data);
        setParams({ ...params, recordsTotal: response.recordsTotal });
        setQueryString(payload);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusShipping = async () => {
    try {
      setLoading(true);
      const response = await ShippingManagementService.statusShipping();

      if (response.status === 200) {
        setStatusOptions(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getFilterOptions = async () => {
    try {
      setLoading(true);
      const response = await ShippingManagementService.filterOptions();

      if (response.status === 200) {
        setFilterOptions(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = e => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  const onTableChange = (type, newState) => {
    const { page, sizePerPage } = newState;
    console.log(newState);
    setParams({ ...params, page: page, limit: sizePerPage });
  };

  const getParams = () => {
    const qs = new URLSearchParams(window.location.search);

    setParams({
      limit: qs.get('limit') || params.limit,
      page: qs.get('page') || parseInt(params.page),
      filter_column: qs.get('filter_column') || params.filter_column,
      filter_text: qs.get('filter_text') || params.filter_text,
      shipping_status_id: qs.get('shipping_status_id') || params.shipping_status_id
    });
  };

  const setQueryString = params => {
    const newParams = {};

    Object.keys(params).forEach(key => {
      if (params[key]) {
        newParams[key] = params[key];
      }
    });

    // const qs = new URLSearchParams(newParams);

    // props.history.push({
    //   pathname: window.location.pathname,
    //   search: '?' + qs
    // });
  };

  const printAwb = async order_id => {
    const response = await ShippingManagementService.printAwb(order_id);
    if (response.status === 200) {
      Swal.fire('Berhasil!', '', 'success');
    }
    getData();
  };

  const toggleModal = name => {
    setModal(prev => ({ ...prev, [name]: !prev[name] }));
  };

  const showConfirmDeleteAlert = order_id => {
    Swal.fire({
      title: 'Do you want delete this order?',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then(async result => {
      if (result.isConfirmed) {
        const response = await ShippingManagementService.deleteShipping(order_id);
        if (response.status === 200) {
          Swal.fire('Berhasil!', '', 'success');
        }
        getData();
      }
    });
  };

  return (
    <>
      {loading && <div className="loader"></div>}
      <SimpleHeader name="Shipping" parentName="Shipping Management" />

      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Pencarian</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="shipping_status_id">Status Pengiriman</label>
                      <Input
                        id="shipping_status_id"
                        type="select"
                        name="shipping_status_id"
                        value={params.shipping_status_id}
                        onChange={handleChange}
                      >
                        <option value="">Semua</option>
                        {statusOptions.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="filter_column">Cari Berdasarkan</label>
                      <Input
                        id="filter_column"
                        type="select"
                        name="filter_column"
                        value={params.filter_column}
                        onChange={handleChange}
                      >
                        <option value="">--Pilih Kolom Pencarian--</option>
                        {filterOptions.map((item, idx) => (
                          <option key={idx} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="filter_text">&nbsp;</label>
                      <Input
                        id="filter_text"
                        type="text"
                        name="filter_text"
                        placeholder="Keyword"
                        value={params.filter_text}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-right">
                      <Button onClick={() => getData()} color="primary">
                        Cari
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Shipping Management</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      disabled={selectShipping.length <= 0}
                      onClick={() => {
                        toggleModal('isRequestModalOpen');
                      }}
                      className="btn-round btn-icon"
                      color="success"
                      id="tooltip443412080"
                      size="sm"
                    >
                      <span className="btn-inner--text">Request Pickup</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'order_number',
                    text: 'Nomor Order'
                  },
                  {
                    dataField: 'shipping_status_name',
                    text: 'Status Pengiriman',
                    formatter: (cell, row) => {
                      let backgroundColor = 'green';
                      switch (row.shipping_status_id) {
                        case SHIPPING_STATUS_SIAP_PICKUP:
                          backgroundColor = 'royalblue';
                          break;
                        case SHIPPING_STATUS_MENUNGGU_RESI:
                          backgroundColor = 'blueviolet';
                          break;
                        case SHIPPING_STATUS_PENGIRIMAN_TERJADWAL:
                          backgroundColor = 'brown';
                          break;
                        case SHIPPING_STATUS_MENUNGGU_KURIR:
                          backgroundColor = 'red';
                          break;
                        case SHIPPING_STATUS_SEDANG_DIKIRIM:
                          backgroundColor = 'lightseagreen';
                          break;
                        case SHIPPING_STATUS_SELESAI:
                          backgroundColor = 'green';
                          break;
                        default:
                          backgroundColor = 'gray';
                      }

                      return (
                        <>
                          <Badge
                            style={{
                              background: backgroundColor,
                              color: '#fff',
                              fontSize: '10px'
                            }}
                          >
                            {cell}
                          </Badge>
                        </>
                      );
                    }
                  },
                  {
                    dataField: 'pickup_number',
                    text: 'Request Pickup',
                    formatter: (cell, row) => (
                      <div className="text-center">
                        {cell}
                        <br />({row.schedule ? moment(row.schedule).format('DD/MM/YYYY HH:mm') : ''})
                      </div>
                    )
                  },
                  {
                    dataField: 'shipped_at',
                    text: 'Dipickup Tanggal',
                    formatter: cell => (cell ? moment(cell).format('DD/MM/YYYY HH:mm') : '-')
                  },
                  {
                    dataField: 'service_name',
                    text: 'Ekspedisi (Resi)',
                    formatter: (cell, row) => (
                      <div className="text-center">
                        <img src={row.courier_logo} alt={cell} width="75px" />
                        <br />
                        <span style={{ fontWeight: 'bold' }}>{cell}</span>
                        <br />
                        {row.awb && <span style={{ fontWeight: 'bold' }}>( {row.awb} )</span>}
                      </div>
                    )
                  },
                  // {
                  //   dataField: 'name',
                  //   text: 'Penerima'
                  // },
                  // {
                  //   dataField: 'phone',
                  //   text: 'No Telp Penerima'
                  // },
                  {
                    dataField: 'shipping_address',
                    text: 'Alamat Penerima',
                    headerStyle: { minWidth: '325px' }, // Set minWidth for the header
                    style: { minWidth: '325px', whiteSpace: 'normal' },
                    formatter: (cell, row) => {
                      let address = `${row.address}, \nKec ${row.subdistrict_name}, ${row.district_type} ${row.district_name}, \n${row.province_name}, ${row.postal_code}`;
                      return (
                        <>
                          <span style={{ fontWeight: 'bold' }}>{row.name}</span>
                          <br />
                          <span>{row.phone}</span>
                          <br />
                          <span>{address}</span>
                        </>
                      );
                    }
                  },
                  {
                    dataField: 'details',
                    text: 'Produk',
                    style: { whiteSpace: 'normal' },
                    formatter: cell => {
                      let products = cell && cell.length > 0 ? cell.map(p => p.product).join(', ') : '';

                      return products;
                    }
                  },
                  {
                    dataField: 'qty',
                    text: 'TOTAL QTY (Pcs)',
                    formatter: (cell, row) => {
                      let qty =
                        row.details && row.details.length > 0
                          ? row.details.map(p => p.qty).reduce((total, current) => (total += current), 0)
                          : '';

                      return qty;
                    }
                  },
                  {
                    dataField: 'weight',
                    text: 'Berat (gram)',
                    formatter: cell => `${new Intl.NumberFormat('id-ID').format(cell)}gr`
                  },
                  // {
                  //   dataField: 'dimention',
                  //   text: 'Dimensi (p x l x t)',
                  //   formatter: cell => '1cm x 1cm x 1cm'
                  // },
                  {
                    dataField: 'price',
                    text: 'Harga Produk',
                    formatter: cell => `Rp${new Intl.NumberFormat('id-ID').format(cell)}`
                  },

                  {
                    dataField: 'shipping_cost',
                    text: 'Ongkir',
                    formatter: cell => `Rp${new Intl.NumberFormat('id-ID').format(cell)}`
                  },
                  {
                    dataField: 'real_shipping_cost',
                    text: 'Real Ongkir',
                    formatter: cell => (cell ? `Rp${new Intl.NumberFormat('id-ID').format(cell)}` : '-')
                  },
                  {
                    dataField: 'insurance_fee',
                    text: 'Nominal Asuransi',
                    formatter: cell => (cell ? `Rp${new Intl.NumberFormat('id-ID').format(cell)}` : '-')
                  },
                  {
                    dataField: 'action',
                    text: 'Action',
                    classes: 'white-space-normal',
                    formatter: (cell, row, rowIndex, formatExtraData) => (
                      <>
                        {[SHIPPING_STATUS_PENGIRIMAN_TERJADWAL, SHIPPING_STATUS_MENUNGGU_KURIR].includes(
                          row.shipping_status_id
                        ) && (
                          <>
                            <Button
                              color="info"
                              type="button"
                              size="sm"
                              className="mb-2"
                              // outline
                              onClick={() => {
                                window.open(`/shipment-label/${row.id}`, '_blank');
                                printAwb(row.id);
                              }}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-info-circle" />
                              </span>
                              <span className="btn-inner--text">
                                {' '}
                                {row.shipping_status_id === SHIPPING_STATUS_MENUNGGU_KURIR
                                  ? `Print Ulang Label`
                                  : `Print Label`}
                              </span>
                            </Button>
                          </>
                        )}

                        {[
                          SHIPPING_STATUS_PENGIRIMAN_TERJADWAL,
                          SHIPPING_STATUS_MENUNGGU_KURIR,
                          SHIPPING_STATUS_SEDANG_DIKIRIM,
                          SHIPPING_STATUS_SELESAI
                        ].includes(row.shipping_status_id) && (
                          <Button
                            color="success"
                            type="button"
                            size="sm"
                            className="mb-2"
                            onClick={() => {
                              setSelectedRow(row);
                              toggleModal('isTrackingModal');
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-search" />
                            </span>
                            <span className="btn-inner--text"> Lacak Pengiriman</span>
                          </Button>
                        )}

                        {[SHIPPING_STATUS_SIAP_PICKUP].includes(row.shipping_status_id) && (
                          <Button
                            color="danger"
                            type="button"
                            size="sm"
                            className="mb-2"
                            // outline
                            onClick={() => showConfirmDeleteAlert(row.id)}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-trash" />
                            </span>
                            <span className="btn-inner--text"> Hapus</span>
                          </Button>
                        )}

                        {![
                          SHIPPING_STATUS_SIAP_PICKUP,
                          SHIPPING_STATUS_SEDANG_DIKIRIM,
                          SHIPPING_STATUS_SELESAI
                        ].includes(row.shipping_status_id) && (
                          <>
                            <Button
                              color="warning"
                              type="button"
                              size="sm"
                              className="mb-2"
                              // outline
                              onClick={() => {
                                toggleModal('isCancelModal');
                                setSelectedRow(row);
                              }}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-ban" />
                              </span>
                              <span className="btn-inner--text"> Cancel</span>
                            </Button>
                          </>
                        )}
                      </>
                    )
                  }
                ]}
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        selectRow={selectRow}
                        onTableChange={onTableChange}
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        pagination={paginationFactory({
                          page: parseInt(params.page),
                          sizePerPage: parseInt(params.limit),
                          totalSize: parseInt(params.recordsTotal),
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="25">50</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>

        <RequestModal
          isOpen={modal.isRequestModalOpen}
          selectShipping={selectShipping}
          getData={getData}
          toggle={() => {
            toggleModal('isRequestModalOpen');
          }}
        />

        <CancelModal
          isOpen={modal.isCancelModal}
          row={selectedRow}
          toggle={() => {
            toggleModal('isCancelModal');
            getData();
          }}
        />
        <TrackingModal
          isOpen={modal.isTrackingModal}
          row={selectedRow}
          toggle={() => {
            toggleModal('isTrackingModal');
            getData();
          }}
        />
      </Container>
    </>
  );
};

export default ShippingManagement;
