import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React from 'react';
import { Card, CardBody, Col, Progress, Table } from 'reactstrap';
import ChartRevenue from './ChartRevenue';
am4core.useTheme(am4themes_animated);

const Conclusion = props => {
  const { all } = props;

  return (
    <>
      <Col lg="6">
        <ChartRevenue all={props.all} />
      </Col>

      <Col lg="6">
        <Card style={{ borderRadius: '12px' }}>
          <Table responsive>
            <tbody style={{ verticalAlign: 'middle', textAlign: 'center', color: '#fff', textTransform: 'uppercase' }}>
              <tr>
                <td
                  colSpan="2"
                  style={{
                    background: '#f06563',
                    borderTopRightRadius: '12px',
                    borderTopLeftRadius: '12px',
                    fontWeight: 'bolder',
                    fontSize: '1.2em',
                    padding: '5px'
                  }}
                >
                  Total Omzet VS Target Omzet {all.year}
                </td>
              </tr>
            </tbody>
          </Table>
          <CardBody>
            <div className="chart text-center" style={{ maxHeight: '100%' }}>
              <div style={{ fontWeight: 'bold', color: '#000', fontSize: '2rem' }}>
                {all.omzet.toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0
                })}
              </div>

              <Progress
                animated
                color="success"
                style={{
                  width: '100%',
                  height: '1.5rem'
                }}
                value={all.achieved}
              >
                {all.achieved}%
              </Progress>
              <div style={{ fontWeight: '600', color: '#000', fontSize: '1.2rem' }}>
                {all.target.toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0
                })}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Conclusion;
