import React from 'react';

import Swal from 'sweetalert2';

import OrderService from '../../../services/OrderService';
import './PrintInvoice.css';
import html2canvas from 'html2canvas';

class PrintInvoice extends React.Component {
  state = {
    flow_id: this.props.match.params.flow_id,
    data: {
      order: {
        payment_histories: [
          {
            evidences: [{ image: '' }]
          }
        ]
      },
      invoice_image: ''
    }
  };

  componentDidMount() {
    this.getOrder();
    window.onscroll = function() {
      window.scrollTo(0, 0);
    };
  }

  getCanvas = async () => {
    setTimeout(() => {
      const content = document.getElementById('print-label-component');
      content.setAttribute('style', 'padding: 15px');
      const mywindow = window.open('', 'Print');
      html2canvas(content).then(canvas => {
        const border = document.createElement('div');
        border.setAttribute('id', 'border');
        mywindow.document.body.appendChild(border);
        mywindow.document.getElementById('border').appendChild(canvas);
        mywindow.print();
        window.close();
      });
    }, 1000);
  };

  getOrder = async () => {
    try {
      const order = await OrderService.findByOrderDetail(this.state.flow_id);
      this.setState(
        {
          data: order.data
        },
        () => {
          if (
            this.state.data.order.payment_histories.length > 0 &&
            this.state.data.order.payment_histories[0].evidences.length > 0
          ) {
            this.toDataURL(this.state.data.order.payment_histories[0].evidences[0].image, result => {
              this.setState(
                {
                  invoice_image: result
                },
                () => {
                  this.getCanvas();
                }
              );
            });
          }

          this.getCanvas();
        }
      );
    } catch (error) {
      console.log(error.message);
      Swal.fire({
        icon: 'error',
        title: 'terjadi kesalahan',
        text: error.message
      });
    }
  };

  toDataURL = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
      var reader = new FileReader();
      reader.onloadend = function() {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };

  render() {
    return (
      <>
        <div className="container">
          <div id="print-label-component">
            {this.state.data.order.invoice_number && (
              <div style={{ minHeight: '30cm' }}>
                <img
                  src={this.state.invoice_image}
                  style={{
                    width: '27cm',
                    height: 'auto',
                    position: 'absolute',
                    textAlign: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    left: 0,
                    right: 0
                  }}
                  alt="invoice"
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default PrintInvoice;
