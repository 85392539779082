import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import ProductService from '../../../../services/ProductService';

const ExportDataModal = (props) => {
    const {
        toggle,
        isOpen,
        handleChangeDate,
        getMinEndDate,
        getMaxEndDate,
        startDate,
        endDate,
        startDateRef,
        endDateRef,
        validate,
        formatDate
    } = props;

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        if (validate(startDate, endDate)) {
            const start_date = formatDate(new Date(startDate));
            const end_date = formatDate(new Date(endDate));

            setLoading(true)
            try {
                const report = await ProductService.exportData({
                    start_date,
                    end_date,
                });

                if (report && report.status === 200) {
                    window.open(report.data.data.url, '_blank');
                }

                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log(error.response);
                Swal.fire({
                    title: 'Terjadi Kesalahan',
                    text: error.message,
                    icon: 'error'
                });
            }
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={() => toggle('modalExportData')} size="lg">
                <ModalHeader>Export Data Produk</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="start_date">Dari</label>
                                <Input
                                    id="start_date"
                                    type="date"
                                    name="start_date"
                                    max={new Date().toISOString().split('T')[0]}
                                    innerRef={startDateRef}
                                    onKeyDown={e => e.preventDefault()}
                                    onWheel={e => e.preventDefault()}
                                    value={startDate}
                                    onChange={handleChangeDate}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <label htmlFor="end_date">Sampai</label>
                                <Input
                                    id="end_date"
                                    type="date"
                                    name="end_date"
                                    min={getMinEndDate()}
                                    max={getMaxEndDate()}
                                    innerRef={endDateRef}
                                    onKeyDown={e => e.preventDefault()}
                                    onWheel={e => e.preventDefault()}
                                    value={endDate}
                                    onChange={handleChangeDate}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={loading} color="primary" onClick={handleDownload}>
                        <span className="btn-inner--icon mr-2">
                            <i className="fas fa-download" />
                        </span>
                        {loading ? (
                            <>
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                &nbsp; Loading...
                            </>
                        ) : (
                            'Download'
                        )}
                    </Button>
                    <Button disabled={loading} onClick={() => toggle('modalExportData')}>
                        Tutup
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ExportDataModal
