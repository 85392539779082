import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Card, Container, Row, Button, CardHeader, Col } from 'reactstrap';
import RoleService from '../../../services/RoleService';
import SimpleHeader from '../../../components/Headers/SimpleHeader';

const { SearchBar } = Search;

class TableRole extends React.Component {
  state = {
    alert: null,
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortField: 'id',
    sortOrder: 'asc',
    searchText: ''
  };

  onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;
    this.setState(
      prevState => ({
        ...prevState,
        page,
        sizePerPage,
        sortField,
        sortOrder,
        searchText
      }),
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = this.state;
    RoleService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText)
      .then(response => {
        this.setState({
          data: response.data,
          totalSize: response.recordsFiltered,
          page: response.page
        });
      })
  };

  handleUpdate = id => {
    this.props.history.push(`/role-permissions/${id}`);
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Role" parentName="Master Data" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Tabel Role</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'no',
                      text: 'Nomor',
                      sort: false
                    },
                    {
                      dataField: 'name',
                      text: 'Nama',
                      sort: true
                    },
                    {
                      dataField: 'action',
                      text: 'Aksi',
                      sort: false,
                      isDummyField: true,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        return (
                          <>
                            <Button
                              color="warning"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => this.handleUpdate(row.id)}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-edit" />
                              </span>
                              <span className="btn-inner--text"> Manage Permissions</span>
                            </Button>{' '}
                          </>
                        );
                      }
                    }
                  ]}
                  search
                >
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar className="form-control-sm" placeholder="" {...props.searchProps} />
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: this.state.page,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={this.onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default TableRole;
