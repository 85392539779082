import React from 'react';

const TermAndCondition = () => {
  return (
    <>
      Hallo sob, selamat bergabung,
      <br />
      <br />
      Berikut syarat dan ketentuan Affiliate Partner CoverSuper :
      <br />
      <br />

      1. Affiliate partnership bisa diikuti grup institusi maupun individu yang memenuhi syarat menjadi partner
      <br /><br />
      2. Affiliater wajib mengisi formulir pendaftaran dengan selengkap-lengkapnya
      <br /><br />
      3. Affiliater tidak memiliki hubungan (saudara, keluarga, teman) dengan bagian penjualan di internal CoverSuper
      <br /><br />

      4. Mekanisme Pendaftaran:
      <br />
      <ul>a. Affiliater mengisi kelengkapan formulir</ul>
      <ul>b. Admin CoverSuper melakukan verifikasi data</ul>
      <ul>
        c. Data disetujui, Admin mengirimkan link affiliate dashboard via email
      </ul>
      <ul>d. Admin memberikan kontak Affiliater kepada Affiliate Support</ul>
      <ul>e. Affiliate Support mengirimkan informasi seputar detail produk dan pricelist via dashboard (informasi seputar affiliate partnership dapat diakses melalui dashboard dan WhatsApp Affiliate Support)</ul>

      5. Mekanisme Affiliate Partnership:
      <br />
      <ul>a. Affiliater memiliki akses khusus dashboard affiliate CoverSuper, berisi link affiliate, informasi produk, pricelist dan konten promosi</ul>
      <ul>b. Affiliater melakukan aktifitas promosi kepada calon customer</ul>
      <ul>
        c. Affiliater mencantumkan link affiliate pada konten atau dapat juga memberikan link affiliate kepada calon customer untuk melakukan transaksi pembelian
      </ul>
      <ul>
        d. Calon customer melakukan pembelian produk CoverSuper via link affiliate milik Affiliater
      </ul>
      <ul>
        e. Affiliater mendapatkan fee affiliate dari tiap transaksi berhasil via link milik affiliate
      </ul>
      <ul>
        d. Fee affiliate ditransfer ke rekening milik Affiliate setiap 1 minggu sekali setelah terjadinya transaksi
      </ul>

      6. Komisi Affiliate Partnership
      <br />
      <ul>
        a. Komisi affiliate cair setiap 1 minggu sekali setelah transaksi pembelian.
        Sistem akan melakukan request payout komisi secara otomatis ke rekening Affiliater
        (pastikan nomor rekening yang didaftarkan benar). Setelah request payout berhasil dibuat, komisi akan di transfer dalam waktu 3 x 24 jam hari kerja.
      </ul>
      <ul>
        b. Bupot akan dikirim maksimal 3 hari kerja setelah request pencairan atau jika terdampak kondisi di luar kendali PT CoverSuper Indonesia Global seperti error sistem aplikasi perpajakan, maka akan diberikan konfirmasi.
      </ul>
      <ul>c. Besaran komisi akan dikenai pajak sesuai aturan perpajakan yang berlaku.</ul>

      7. Pembatalan Transaksi Affiliates
      <br />
      <ul>
        a. Transaksi Affiliates dinyatakan batal jika customer mengajukan pembatalan transaksi dalam bentuk refund dan pihak CoverSuper telah mengembalikan biaya customer.
      </ul>

      8. Lain-lain
      <br />
      <ul>
        Perubahan harga produk akan diberitahukan minimal H-3 dan otomatis berubah di sistem
        Penawaran benefit oleh affiliate yang melebihi ketentuan berlaku dari coversuper menjadi kewajiban dan tanggung jawab affiliate
      </ul>
    </>
  );
};

export default TermAndCondition;
