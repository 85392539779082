import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import AffiliateService from '../../../services/AffiliateService';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Container, Row, Card, CardHeader, Col, Button, Badge } from 'reactstrap';
// import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Can from '../../../config/Can';
import SearchBar from '../../../components/Search';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { request } from '../../../config';
import DetailAffiliate from './modal/DetailAffiliate';

const TableAffiliate = () => {
  // const [alert, setAlert] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  // const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null);
  const [detailModal, setDetailModal] = useState(false);

  const getData = async () => {
    try {
      const response = await AffiliateService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText);

      if (response.status === 200) {
        setData(response.data);
        setTotalSize(response.recordsFiltered);
        setPage(response.page);
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getData();

    return () => {
      source.cancel();
    };
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  const onChangeStatus = id => {
    AffiliateService.changeStatus(id).then(() => getData());
  };

  const toggelModalWithDetail = async row => {
    try {
      const detail = await request.get('/affiliates/' + row.id);

      setDetailModal(true);
      setSelectedRow(detail.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const verifikasiAkun = (id, data) => {
    Swal.fire({
      icon: 'warning',
      title: `Apakah anda ingin mem-verifikasi?`,
      cancelButtonText: 'Batal',
      confirmButtonColor: 'green',
      cancelButtonColor: 'red',
      showConfirmButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: true,
      showCloseButton: true
    }).then(async confirm => {
      if (confirm.value) {
        try {
          const response = await AffiliateService.verifyAccount(id, data);
          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              text: `Akun berhasil di verifikasi`
            });
            getData();
            setDetailModal(false);
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'terjadi kesalahan',
            text: error.message
          });
        }
      }
    });
  };

  return (
    <>
      <SimpleHeader name="Affiliate" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Tabel Partner</h3>
                  </Col>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'no',
                    text: 'No',
                    sort: false
                  },
                  {
                    dataField: 'name',
                    text: 'Nama',
                    sort: true
                  },
                  {
                    dataField: 'email',
                    text: 'Email',
                    sort: true
                  },
                  {
                    dataField: 'affiliate_type',
                    text: 'Tipe',
                    sort: true
                  },
                  {
                    dataField: 'is_active',
                    text: 'Status Aktif',
                    sort: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      const btnClassName = row.is_active ? 'fas fa-check' : 'fas fa-times';
                      return (
                        <Button size="sm" onClick={() => onChangeStatus(row.id)}>
                          <i className={btnClassName} />
                        </Button>
                      );
                    }
                  },
                  {
                    dataField: 'is_verified',
                    text: 'Status Verifikasi',
                    sort: true,
                    formatter: (cell, row, rowIndex, formatExtraData) =>
                      row.is_verified === 0 ? (
                        <Badge color="warning" pill>
                          Belum Verifikasi
                        </Badge>
                      ) : (
                        <span className="badge badge-success">Verifikasi</span>
                      )
                  },
                  {
                    dataField: 'action',
                    text: 'Aksi',
                    sort: false,
                    isDummyField: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <>
                          {/* <Can I="read" a="Voucher"> */}
                          <Button
                            color="success"
                            type="button"
                            size="sm"
                            outline
                            onClick={() => toggelModalWithDetail(row, 'detailModal')}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-info-circle" />
                            </span>
                            <span className="btn-inner--text"> {row.is_verified === 0 ? 'Verifikasi' : 'Detail'} </span>
                          </Button>
                          {/* </Can> */}

                          <Can I="delete" a="Affiliate">
                            <Button
                              color="danger"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => {
                                Swal.fire({
                                  icon: 'warning',
                                  title: `Apakah anda yakin akan menghapus voucher ${row.code}?`,
                                  cancelButtonText: 'Batal',
                                  confirmButtonColor: 'red',
                                  cancelButtonColor: 'green',
                                  showConfirmButton: true,
                                  allowOutsideClick: false,
                                  allowEscapeKey: false,
                                  allowEnterKey: false,
                                  showCancelButton: true,
                                  showCloseButton: true
                                }).then(async confirm => {
                                  if (confirm.value) {
                                    try {
                                      const response = await AffiliateService.remove(row.id);
                                      if (response.status === 200) {
                                        Swal.fire({
                                          icon: 'success',
                                          text: `Voucher ${row.code} berhasil dihapus`
                                        });
                                        getData();
                                      }
                                    } catch (error) {
                                      Swal.fire({
                                        icon: 'error',
                                        title: 'terjadi kesalahan',
                                        text: error.message
                                      });
                                    }
                                  }
                                });
                              }}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-trash" />
                              </span>
                              <span className="btn-inner--text"> Hapus</span>
                            </Button>
                          </Can>
                        </>
                      );
                    }
                  }
                ]}
                search
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                        <label>
                          Search:
                          <SearchBar className="form-control-sm" {...props.searchProps} />
                        </label>
                      </div>
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>

      <DetailAffiliate
        row={selectedRow}
        isOpen={detailModal}
        toggle={toggelModalWithDetail}
        setDetailModal={setDetailModal}
        verifikasiAkun={verifikasiAkun}
      />
    </>
  );
};

export default TableAffiliate;
