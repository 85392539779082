import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import Avatar from 'react-avatar-edit';

class PhotoModal extends React.Component {
  state = {
    isOpen: true,
    preview: null,
    src: require('../../../assets/img/theme/user-profile.jpg')
  };

  /**
   * Convert a base64 string in a Blob according to the data and contentType.
   *
   * @param b64Data {String} Pure base64 string without contentType
   * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
   * @param sliceSize {Int} SliceSize to process the byteCharacters
   * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
   * @return Blob
   */
  b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  onClose = () => {
    this.setState({ preview: null });
  };

  onCrop = preview => {
    console.log(preview);
    this.setState({ preview });
  };

  onBeforeFileLoad = elem => {
    if (elem.target.files[0].size > 512000) {
      alert('File is too big!');
      elem.target.value = '';
    }
  };

  render() {
    const { isOpen, toggle } = this.props;
    return (
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Upload Photo</ModalHeader>
        <ModalBody>
          <Avatar
            label="Pilih Gambar"
            width={320}
            height={240}
            onCrop={this.onCrop}
            onClose={this.onClose}
            onBeforeFileLoad={this.onBeforeFileLoad}
            src={this.state.src}
          />
          Preview
          <br />
          <img className="rounded-circle" src={this.state.preview || this.state.src} width="150px" alt="Preview" />
        </ModalBody>
        <ModalFooter>
          <Button color="primary">Upload</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PhotoModal;
