import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ability from '../../../../config/ability';
import CurrencyInput from '../../../../helpers/CurrencyInput';
import ScoreboardService from '../../../../services/ScoreboardService';

function DetailModal(props) {
  const [loading, setLoading] = useState(false);
  const [additional_omzet, setAdditionalOmzet] = useState(undefined);
  const [additional_budget, setAdditionalBudget] = useState(undefined);
  const [additional_deals, setAdditionalDeals] = useState(undefined);
  const [additional_leads, setAdditionalLeads] = useState(undefined);

  const [leads_fb, setLeadsFb] = useState(undefined);
  const [leads_google, setLeadsGoogle] = useState(undefined);
  const [budget_fb, setBudgetFb] = useState(undefined);
  const [budget_google, setBudgetGoogle] = useState(undefined);

  useEffect(() => {
    if (props.userData) {
      setAdditionalOmzet(props.userData.omzet);
      setAdditionalDeals(props.userData.deals);
      setAdditionalBudget(props.userData.additional_budget);
      setAdditionalLeads(props.userData.additional_leads);

      setBudgetFb(props.userData.budget_fb);
      setBudgetGoogle(props.userData.budget_google);
      setLeadsFb(props.userData.leads_fb);
      setLeadsGoogle(props.userData.leads_google);
    }
  }, [props.userData]);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const data = {
        id: props.sc.id,
        additional_budget: additional_budget.toString().replace(/\D/g, '') || 0,
        additional_leads: additional_leads || 0,
        budget_fb: budget_fb.toString().replace(/\D/g, '') || 0,
        budget_google: budget_google.toString().replace(/\D/g, '') || 0,
        leads_fb: leads_fb || 0,
        leads_google: leads_google || 0
      };

      data['deals'] = additional_deals;
      data['omzet'] = additional_omzet.toString().replace(/\D/g, '');

      const response = await ScoreboardService.updateScoreboard('emirate', data);

      if (response.status === 200) {
        setLoading(false);
        props.toggleModal(null);
        props.updateData();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <Modal centered toggle={() => props.toggleModal(null)} isOpen={props.isDetailDataModalOpen}>
        <ModalHeader>
          Detail Data {props.sc && props.sc.periodicity === 'daily' ? 'Harian' : ''} {props.sc && props.sc.name}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.toggleModal(null)}
          >
            <span aria-hidden={true}>x</span>
          </button>
        </ModalHeader>

        <ModalBody>
          {ability.can('update', 'Scoreboard') && (
            <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Budget Facebook Ads</Label>
                    <CurrencyInput
                      type="text"
                      name="budget_fb"
                      className="form-control-sm form-control"
                      value={budget_fb === 0 ? '' : budget_fb}
                      onChange={async e => {
                        e.persist();
                        const value =
                          e.target.value.slice(3).replace(/\./g, '') === ''
                            ? e.target.value.slice(3).replace(/\./g, '')
                            : e.target.value.slice(3).replace(/\./g, '');

                        if (value.toString().length <= 10) {
                          setBudgetFb(value);
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Budget Google Ads</Label>
                    <CurrencyInput
                      type="text"
                      name="budget_google"
                      className="form-control-sm form-control"
                      value={budget_google === 0 ? '' : budget_google}
                      onChange={async e => {
                        e.persist();
                        const value =
                          e.target.value.slice(3).replace(/\./g, '') === ''
                            ? e.target.value.slice(3).replace(/\./g, '')
                            : e.target.value.slice(3).replace(/\./g, '');

                        if (value.toString().length <= 10) {
                          setBudgetGoogle(value);
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Budget Ads Tambahan</Label>
                    <CurrencyInput
                      type="text"
                      name="additional_budget"
                      className="form-control-sm form-control"
                      value={additional_budget === 0 ? '' : additional_budget}
                      onChange={async e => {
                        e.persist();
                        const value =
                          e.target.value.slice(3).replace(/\./g, '') === ''
                            ? e.target.value.slice(3).replace(/\./g, '')
                            : e.target.value.slice(3).replace(/\./g, '');

                        if (value.toString().length <= 10) {
                          setAdditionalBudget(value);
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Omzet {props.sc && !['emirate'].includes(props.sc.type) ? 'Tambahan' : ''}</Label>
                    <CurrencyInput
                      type="text"
                      name="additional_omzet"
                      className="form-control-sm form-control"
                      value={additional_omzet === 0 ? '' : additional_omzet}
                      onChange={async e => {
                        e.persist();
                        const value =
                          e.target.value.slice(3).replace(/\./g, '') === ''
                            ? e.target.value.slice(3).replace(/\./g, '')
                            : e.target.value.slice(3).replace(/\./g, '');

                        if (value.toString().length <= 10) {
                          setAdditionalOmzet(value);
                        }
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Leads Facebook Ads</Label>
                    <Input
                      bsSize="sm"
                      type="number"
                      min="0"
                      name="leads_fb"
                      value={leads_fb}
                      onChange={e => setLeadsFb(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Leads Google Ads</Label>
                    <Input
                      bsSize="sm"
                      type="number"
                      min="0"
                      name="leads_google"
                      value={leads_google}
                      onChange={e => setLeadsGoogle(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Leads Tambahan</Label>
                    <Input
                      bsSize="sm"
                      type="number"
                      min="0"
                      name="additional_leads"
                      value={additional_leads}
                      onChange={e => setAdditionalLeads(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Deal {props.sc && !['emirate'].includes(props.sc.type) ? 'Tambahan' : ''}</Label>
                    <Input
                      bsSize="sm"
                      type="number"
                      min="0"
                      name="additional_deals"
                      value={additional_deals}
                      onChange={e => setAdditionalDeals(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Button disabled={loading} color="primary">
                Simpan
              </Button>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default DetailModal;
