import React from 'react';
import { Card, CardBody, Row, CardHeader, NavItem, NavLink, Nav } from 'reactstrap';
import classnames from 'classnames';
import { Line } from 'react-chartjs-2';

const TotalPoint = props => {
  return (
    <Card className="bg-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-light text-uppercase ls-1 mb-1">Overview</h6>
            <h5 className="h3 text-white mb-0">Omzet</h5>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem className="mr-2 mr-md-0">
                <NavLink
                  className={classnames('py-2 px-3', {
                    active: 1 // this.state.activeNav === 1
                  })}
                  href="#"
                  // onClick={e => this.toggleNavs(e, 1)}
                >
                  <span className="d-none d-md-block">Bulan Ini</span>
                  <span className="d-md-none">M</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        {/* <div className="chart"> */}
        <Line
          data={props.points}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat('id-ID', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      }).format(context.parsed.y);
                    }
                    return label;
                  }
                }
              }
            },
            borderColor: 'rgb(53, 162, 235)',
            scales: {
              y: {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value, index, values) {
                    return value.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                      // maximumFractionDigits: 0
                    });
                  }
                }
              }
            }
          }}
          id="chart-sales-dark"
          className="chart-canvas"
        />
        {/* </div> */}
      </CardBody>
    </Card>
  );
};

export default TotalPoint;
