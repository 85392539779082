export const FLOW_VERSION = '1.17.1';

export const ROLE_ADMIN = 1;
export const ROLE_CS = 2;
export const ROLE_SUPPLIER = 3;
export const ROLE_RESEPER = 4;
export const ROLE_CUTTER = 5;
export const ROLE_TAILOR = 6;
export const ROLE_QC = 7;
export const ROLE_PACKING = 8;
export const ROLE_RESI = 9;
export const ROLE_ADMIN_PRODUCTION = 10;
export const ROLE_FINANCE = 11;
export const ROLE_MARKETING = 12;
export const ROLE_CS_SUPERVISOR = 13;
export const ROLE_PENJAHIT_EKSTERNAL = 14;
export const ROLE_ADMIN_PRODUCTION_2 = 15;
export const ROLE_CS_CARE = 16;
export const ROLE_OPERASIONAL = 17;
export const ROLE_AFFILIATE = 18;
export const ROLE_CS_EMIRATE = 19;
export const ROLE_CS_CMI = 20;
export const ROLE_ADMIN_INPUT = 21;
export const ROLE_CS_FORCE = 22;
export const ROLE_CUSTOMER = 100;

export const PAYMENT_STATUS_UNPAID = 1;
export const PAYMENT_STATUS_DP = 2;
export const PAYMENT_STATUS_PAID = 3;
export const PAYMENT_STATUS_REFUND = 4;
export const PAYMENT_STATUS_CANCEL = 5;
export const PAYMENT_STATUS_COD_MP = 6;
export const PAYMENT_STATUS_MARKETPLACE = 7;
export const PAYMENT_STATUS_BILLING = 8;

export const PAYMENT_TYPE_EWALLET = 'eWallet';
export const PAYMENT_TYPE_RETAIL = 'Retail';

export const STATUS_UNPAID = 1;
export const STATUS_UNVERIFIED = 2;
export const STATUS_ANTRI_PRODUKSI = 3;
export const STATUS_ANTRI_RESEP = 4;
export const STATUS_POLA_KARPET = 5;
export const STATUS_RESEP = 6;
export const STATUS_ANTRI_POTONG = 7;
// export const STATUS_POTONG_ULANG = 8;
export const STATUS_ANTRI_JAHIT = 9;
// export const STATUS_SELESAI_JAHIT = 10;
// export const STATUS_JAHIT_ULANG = 11;
export const STATUS_ANTRI_QC = 12;
// export const STATUS_QC_SELESAI = 13;
// export const STATUS_QC_ULANG = 14;
export const STATUS_QC = 15;
// export const STATUS_FINISHED_QC = 16;
// export const STATUS_REPACKING = 17;
export const STATUS_PACKING = 18;
// export const STATUS_FINISHED_PACKING = 19;
export const STATUS_SENDING = 20;
export const STATUS_SENT = 21;
export const STATUS_CANCEL = 22;
export const STATUS_REFUND_PROSES = 23;
export const STATUS_REFUND_DONE = 24;
export const STATUS_COMPLAINT_PROCESS = 25;
export const STATUS_COMPLAINT_DONE = 26;
export const STATUS_PENDING = 27;

export const PLATFORM_SELFSERVICE = 'Self Service Web';
export const PLATFORM_BUKALAPAK = 'Bukalapak';
export const PLATFORM_REGULAR = 'Regular';
export const PLATFORM_TOKOPEDIA = 'Tokopedia';
export const PLATFORM_SHOPEE = 'Shopee';
export const PLATFORM_LAZADA = 'Lazada';
export const PLATFORM_BLIBLI = 'Blibli';

export const CUSTOMER_RETAIL = 1;
export const CUSTOMER_RESELLER = 2;
export const CUSTOMER_BIG_SELLER = 3;

export const CATEGORY_COVER_MOBIL_DELUXE = 1;
export const CATEGORY_COVER_MOBIL_SUPERIOR = 2;
export const CATEGORY_COVER_MOBIL_EXTREME_OUTDOOR = 3;
export const CATEGORY_COVER_MOBIL_OUTDOOR_PRESTIGE = 4;
export const CATEGORY_COVER_MOBIL_LUXURY = 5;
export const CATEGORY_COVER_SEPEDA = 6;
export const CATEGORY_COVER_MOTOR = 7;
export const CATEGORY_COVER_SETIR = 8;
export const CATEGORY_KAIN = 9;
export const CATEGORY_COVER_MOBIL_SPECIAL_EDITION = 11;
export const CATEGORY_COVER_MOBIL_PRIME_SOFT = 13;
export const CATEGORY_COVER_VESPA_SPORTY = 28;
export const CATEGORY_COVER_VESPA_PRIME = 29;
export const CATEGORY_BAGASI_PROTECTOR = 30;
export const CATEGORY_COVER_MOTOR_DELUXE = 31;
export const CATEGORY_COVER_MOTOR_SUPERIOR = 32;
export const CATEGORY_COVER_MOTOR_EXTREME = 33;
export const CATEGORY_COVER_MOBIL_SUPERIOR_RS = 34;
export const CATEGORY_COVER_MOTOR_PRIME_SOFT = 35;
export const CATEGORY_KARPET = 36;
export const CATEGORY_KARPET_BAGASI = 37;
export const CATEGORY_COVER_MOBIL_COVERSUPER_RS = 59;

export const COVER_TYPE_ELEGAN = 1;
export const COVER_TYPE_VIPER = 2;
export const COVER_TYPE_SPORTY = 3;
export const COVER_TYPE_FANTASY = 4;
export const COVER_TYPE_SINGLE_LAYER = 5;

export const ADDON_SABLON = 22;
export const ADDON_SABLON2 = 29;
export const ADDON_SABLON3 = 30;
export const CS_COVERSUPER_SELFSERVICE = 1;

export const BANK_MANDIRI = 3;
export const PEMBAYARAN_DEPOSIT = 14;

export const CS_LEVEL_TRAINING = 1;
export const CS_LEVEL_MEGA_A = 2;
export const CS_LEVEL_MEGA_B = 3;
export const CS_LEVEL_GIGA = 4;

export const GREAT_TEAM = 1;
export const EXCELLENT_TEAM = 2;
export const FORCE_TEAM = 3;

export const TYPE_MERCHANDISE = 7;
export const TYPE_MOBIL = 1;
export const TYPE_KARPET = 9;

export const SHIPPING_STATUS_SIAP_PICKUP = 1;
export const SHIPPING_STATUS_MENUNGGU_RESI = 2;
export const SHIPPING_STATUS_PENGIRIMAN_TERJADWAL = 3;
export const SHIPPING_STATUS_MENUNGGU_KURIR = 4;
export const SHIPPING_STATUS_SEDANG_DIKIRIM = 5;
export const SHIPPING_STATUS_DITOLAK = 6;
export const SHIPPING_STATUS_RETUR = 7;
export const SHIPPING_STATUS_SELESAI = 8;
