import React from 'react';
import { Button, Card, CardGroup, CardImg, Col, Modal, Row, Table } from 'reactstrap';

import moment from 'moment';
import 'moment/locale/id';
import { STATUS_SENDING } from '../../../../constants';
import '../EditOrder.css';
moment.locale('id');

class DetailModal extends React.Component {
  modalPreviewRef = React.createRef();
  ImgPreviewRef = React.createRef();

  previewImage = url => {
    this.modalPreviewRef.current.style.display = 'block';
    this.ImgPreviewRef.current.src = url;
  };

  componentDidMount() {
    const imgModal = document.getElementById('modal-image-preview');
    window.onclick = function(event) {
      if (event.target === imgModal) {
        imgModal.style.display = 'none';
      }
    };
  }

  render() {
    const { row, toggle, isOpen } = this.props;

    return (
      <>
        {row ? (
          <>
            <Modal
              className="modal-dialog-centered"
              isOpen={isOpen}
              toggle={() => toggle(row, 'detailModal')}
              size="lg"
            >
              <div className="modal-header">
                <h5 className="modal-title" id="detailModalLabel">
                  Detail Pesanan
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => toggle(row, 'detailModal')}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col md="6">
                    <Table responsive borderless size="sm">
                      <tbody>
                        <tr>
                          <td width="20%">
                            <strong>No Order</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>{row.order.order_number}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Customer Service</td>
                          <td width="5%">:</td>
                          <td className="text-left" style={{ whiteSpace: 'normal' }}>
                            {row.order.customer_service.name}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Unit/Team</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.unit.name}</td>
                        </tr>
                        <tr>
                          <td width="20%">Produk</td>
                          <td width="5%">:</td>
                          <td className="text-left" style={{ whiteSpace: 'normal' }}>
                            {row.category ? row.category.name : ''}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Size</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.variant ? row.variant.size : ''}</td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <strong>Merk / Tipe</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>{row.product_name}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Tahun</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.year}</td>
                        </tr>
                        <tr>
                          <td width="20%">Transmisi</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.transmission_type}</td>
                        </tr>
                        <tr>
                          <td width="20%">Warna</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.color ? row.color.name : ''}</td>
                        </tr>
                        <tr>
                          <td width="20%">Cover Tipe</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.cover_type ? row.cover_type.name : ''}</td>
                        </tr>
                        <tr>
                          <td width="20%">Cover List</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.cover_list ? row.cover_list.name : ''}</td>
                        </tr>
                        <tr>
                          <td width="20%">Logo</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.logo}</td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <strong>Jumlah</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>{row.qty}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Tambahan</td>
                          <td width="5%">:</td>
                          <td className="text-left" style={{ whiteSpace: 'normal' }}>
                            {row.addons && row.addons.map((addon, key) => <li key={key}>{addon.name}</li>)}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Catatan</td>
                          <td width="5%">:</td>
                          <td className="text-left" style={{ whiteSpace: 'normal' }}>
                            {row.note}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Keterangan Order / Bonus</td>
                          <td width="5%">:</td>
                          <td className="text-left" style={{ whiteSpace: 'normal' }}>
                            {row.order.order_description}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <strong>Tipe Pembayaran</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>{row.order.payment_type}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <strong>Channel Pembayaran</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>{row.order.payment_channel}</strong>
                          </td>
                        </tr>
                        {row.order.voucher && (
                          <tr>
                            <td width="20%">Voucher</td>
                            <td width="5%">:</td>
                            <td className="text-left">{row.order.voucher.code}</td>
                          </tr>
                        )}
                        <tr>
                          <td width="20%">
                            <strong>Status Produksi</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>{row.status.name}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Penanggung Jawab</td>
                          <td width="5%">:</td>
                          <td className="text-left" style={{ whiteSpace: 'normal' }}>
                            {row.pic.name}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Catatan PIC</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.current_desc}</td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <strong>Estimasi Produksi</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Selesai Tanggal</td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            {row.estimation ? moment(row.estimation).format('dddd, DD MMMM YYYY') : ''}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Catatan Estimasi</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.estimation_desc}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col md="6">
                    <Table responsive borderless size="sm">
                      <tbody>
                        <tr>
                          <td colSpan="3">
                            <strong>Customer</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Nama</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.customer.name}</td>
                        </tr>
                        <tr>
                          <td width="20%">Email</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.customer.email}</td>
                        </tr>
                        <tr>
                          <td width="20%">Telepon</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.customer.phone}</td>
                        </tr>
                        <tr>
                          <td width="20%">Telepon Alternatif</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.customer.phone_alt}</td>
                        </tr>
                        <tr>
                          <td width="20%">Jenis Customer</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.customer_type.name}</td>
                        </tr>
                        <tr>
                          <td colSpan="3">
                            <strong>Pengiriman</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Nama Penerima</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.shipping_address.name}</td>
                        </tr>
                        <tr>
                          <td width="20%">Telepon</td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            {row.order.shipping_address.phone}
                            {/* ? this.handlePhoneFormat(row.order.shipping_address.phone)
                          : ''} */}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Telepon Alternatif</td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            {row.order.shipping_address.phone_alt}
                            {/* ? this.handlePhoneFormat(row.order.shipping_address.phone_alt)
                          : ''} */}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Alamat</td>
                          <td width="5%">:</td>
                          <td className="text-left" style={{ whiteSpace: 'normal' }}>
                            {row.order.shipping_address.address}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Kecamatan</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.shipping_address.subdistrict.name}</td>
                        </tr>
                        <tr>
                          <td width="20%">Kabupaten/Kota</td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            {row.order.shipping_address.district.type} {row.order.shipping_address.district.name}
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">Provinsi</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.shipping_address.province.name}</td>
                        </tr>
                        <tr>
                          <td width="20%">Kode Pos</td>
                          <td width="5%">:</td>
                          <td className="text-left">{row.order.shipping_address.postal_code}</td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <strong>Jasa Pengiriman</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>
                              {row.order.courier_name} - {row.order.service_code}
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <strong>Resi</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>{row.order.resi}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td width="20%">
                            <strong>Tanggal Kirim</strong>
                          </td>
                          <td width="5%">:</td>
                          <td className="text-left">
                            <strong>
                              {row.order.shipping_date ? moment(row.order.shipping_date).format('DD/MM/YYYY') : ''}
                            </strong>
                          </td>
                        </tr>
                        {row.order.resi_image !== '' && row.order.resi_image && (
                          <tr>
                            <td width="20%"></td>
                            <td width="5%">:</td>
                            <td className="text-left">
                              <img
                                alt="resi"
                                src={row.order.resi_image}
                                width="70"
                                height="70"
                                style={{
                                  cursor: 'pointer'
                                }}
                                onClick={() => window.open(row.order.resi_image, '_blank')}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>

              <div className="modal-header">
                <strong>Gambar Referensi</strong>
              </div>
              <div className="modal-body">
                {row.images && row.images.length === 0 && <p>Tidak ada gambar</p>}
                <CardGroup>
                  {row.images &&
                    row.images.map((resep, key) => (
                      <div key={key}>
                        <Card>
                          <CardImg alt="Gambar" onClick={() => this.previewImage(resep.image)} src={resep.image} />
                        </Card>
                      </div>
                    ))}
                </CardGroup>
              </div>

              <div className="modal-header">
                <strong>History Status</strong>
              </div>
              <div className="modal-body">
                <Table responsive striped size="sm">
                  <thead>
                    <tr>
                      <td>Tanggal</td>
                      <td>Status</td>
                      <td style={{ whiteSpace: 'normal' }}>Desc</td>
                      <td>Penanggung Jawab</td>
                    </tr>
                  </thead>
                  <tbody>
                    {row.histories &&
                      row.histories.map((history, key) => (
                        <tr key={key}>
                          <td>{moment(history.created_at).format('dddd, DD MMM YYYY HH:mm')}</td>
                          <td>{history.status.name}</td>
                          <td style={{ whiteSpace: 'normal' }}>
                            {history.status.id === STATUS_SENDING ? (
                              <>
                                Tgl :{' '}
                                {row.order.shipping_date ? moment(row.order.shipping_date).format('DD MMM YYYY') : ''}{' '}
                                <br />
                                Resi : <strong>{row.order.resi}</strong>
                                <br />
                                {history && history.tracking && (
                                  <>
                                    Riwayat : <br />
                                    <div
                                      className="timeline timeline-one-side"
                                      data-timeline-axis-style="dashed"
                                      data-timeline-content="axis"
                                    >
                                      {history.tracking.map((item, index) => {
                                        return (
                                          <div className="timeline-block" key={index}>
                                            <span className="timeline-step badge-success">
                                              <i className="ni ni-delivery-fast" />
                                            </span>
                                            <div className="timeline-content">
                                              <small className="text-muted font-weight-bold">
                                                {moment(item.created_at).format('DD MMM YYYY HH:mm')}
                                              </small>
                                              {/* <h5 className="mt-3 mb-0">New message</h5> */}
                                              <p className="text-sm mt-1 mb-0">{item.status}</p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              history.description
                            )}
                          </td>
                          <td>{history.pic.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>

              <div className="modal-footer">
                <Button color="secondary" data-dismiss="modal" type="button" onClick={() => toggle(row, 'detailModal')}>
                  Close
                </Button>
              </div>
            </Modal>
            <div ref={this.modalPreviewRef} className="modal-image-preview-edit-order" id="modal-image-preview">
              <span
                className="close-image-preview-edit-order"
                onClick={() => {
                  this.modalPreviewRef.current.style.display = 'none';
                }}
              >
                &times;
              </span>
              <img className="modal-content-image-preview-edit-order" alt="preview" ref={this.ImgPreviewRef} />
            </div>
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default DetailModal;
