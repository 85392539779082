import { request } from '../config';

class CoverImageService {
  static async getCoverImagesByCategoryId(categoryId) {
    const response = await request.get('/coverimages/options', {
      params: {
        category_id: categoryId
      }
    });
    return response;
  }

  static async updateColor(id, data) {
    const response = await request.put(`/coverimages/category/${id}`, data);

    return response;
  }

  static async getCsLists() {
    const { data: response } = await request.get('/vouchers/list-cs');
    return response;
  }

  static async getBankProviders() {
    const { data: response } = await request.get('/vouchers/providers');
    return response;
  }

  static async insert(data) {
    const { data: response } = await request.post('/testimonials', data);
    return response;
  }

  static async changeStatus(id) {
    const { data: response } = await request.put(`/testimonials/change-status/${id}`);
    return response;
  }

  static async uploadImage(data, params) {
    const response = await request.post(`/coverimages`, data, {
      params
    });
    return response;
  }

  static async detail(id) {
    const { data: response } = await request.get(`/testimonials/${id}`);
    return response;
  }

  static async edit(id, data) {
    const { data: response } = await request.put(`/testimonials/${id}`, data);
    return response;
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/testimonials/${id}`);
    return response;
  }

  static async getDatatable(categoryId, page, limit, filter) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/coverimages', {
      params: { category_id: categoryId, page, limit, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }
}

export default CoverImageService;
