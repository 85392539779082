import React, { useState } from 'react';
import { Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import ComplaintService from '../../../../services/ComplaintService';

function ComplaintProcessModal(props) {
  const [alert, setAlert] = useState('');

  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setAlert('');
    setLoading(false);

    props.toggle(props.row, 'isComplaintProcessModalShow');
  };

  const isValid = () => {
    // if (!imageURL || imageURL === '') {
    //   setAlert('Harap upload bukti transfer untuk refund');
    //   return false;
    // } else if (!transfer_date || transfer_date === '') {
    //   setAlert('Harap isi tanggal transfer untuk refund');
    //   return false;
    // }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (isValid()) {
        const data = {
          complaint_id: props.row.complaint.id
        };

        const res = await ComplaintService.accept(data);

        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Komplain Berhasil Diproses'
          }).then(() => {
            toggle();
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal isOpen={props.isOpen} fade={false} backdrop="static" size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>Proses Pengajuan Komplain</ModalHeader>
        <ModalBody>
          {alert !== '' && <Alert color="danger">{alert}</Alert>}

          <div className="mb-4">
            {props.row && props.row.complaint && (
              <>
                <Row>
                  <Col sm={4}>Order Detail ID</Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.id && props.row.id}</div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Komplain Kategori</Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.complaint && props.row.complaint.category.name}</div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Divisi</Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.complaint && props.row.complaint.division}</div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Komplain</Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.complaint && props.row.complaint.complaint}</div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Solusi</Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.complaint && props.row.complaint.solution}</div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Aksi</Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.complaint && props.row.complaint.action.name}</div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Waktu Lead</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        new Date(props.row.complaint.lead_time).toLocaleDateString('id-ID', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Biaya Customer</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.customer_charge.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Pajak Biaya Customer</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.customer_charge_tax.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Biaya Pengiriman Customer</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.customer_shipping_cost.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Biaya CS</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.cs_charge.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Pajak Biaya CS</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.cs_charge_tax.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Biaya Pengiriman CS</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.cs_shipping_cost.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Biaya Produksi</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.production_charge.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Pajak Biaya Produksi</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.production_charge_tax.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                <Row>
                  <Col sm={4}>Biaya Pengiriman Produksi</Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.complaint &&
                        props.row.complaint.production_shipping_cost.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <hr style={{ margin: 8 }} />
                {props.row.complaint.images.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    {props.row.complaint.images.map((oke, i) => (
                      <span key={i}>
                        <img
                          onClick={() => window.open(oke.image, '_blank')}
                          style={{ marginRight: 10, marginTop: 10, cursor: 'pointer' }}
                          width="200"
                          className="img-responsive"
                          src={oke.image}
                          alt={`gambar ${i}`}
                        />
                      </span>
                    ))}
                  </div>
                )}
                <hr style={{ margin: 8 }} />
              </>
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Setujui'
            )}
          </Button>{' '}
          <Button color="secondary" disabled={loading} onClick={toggle}>
            Batalkan
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ComplaintProcessModal;
