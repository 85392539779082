import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Container, Card, CardBody, Form, Row, Col, Label, Input, Button, FormGroup } from 'reactstrap';
import ComplaintCategoryService from '../../../services/ComplaintCategoryService';

function EditComplaintCategory(props) {
  const [name, setName] = useState('');
  const [complaint_code, setComplaintCode] = useState('');

  useEffect(() => {
    getData(props.match.params.id);
  }, []);

  async function getData(id) {
    try {
      const res = await ComplaintCategoryService.detail(id);

      if (res.status === 200) {
        setName(res.data.data.name);
        setComplaintCode(res.data.data.complaint_code);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.message,
        onClose: props.history.push('/vouchers')
      });
    }
  }

  function isValid() {
    if (name === '' || !name) {
      return false;
    } else if (complaint_code === '' || !complaint_code) {
      return false;
    }

    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    const data = {
      name,
      complaint_code
    };

    if (isValid()) {
      try {
        const response = await ComplaintCategoryService.edit(props.match.params.id, data);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push('/complaint-category')
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => props.history.push('/complaint-category'),
          onCancel: () => props.history.push('/complaint-category'),
          text: error.message
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Silakan isi form dengan benar'
      });
    }
  }

  return (
    <>
      <SimpleHeader
        name="Ubah Kategori Komplain"
        parentName="Tabel Kategori Komplain"
        parentLink="/complain-category"
      />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={onSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="code">
                        Nama Kategori Komplain
                      </Label>
                      <Input
                        placeholder="Kekecilan, Kebesaran, Refund"
                        value={name}
                        name="name"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          const value = e.target.value;
                          if (value.length <= 30) {
                            setName(value);
                          }
                        }}
                      />
                      <small className="form-text text-muted">Nama Kategori Komplain maksimal 30 karakter</small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="discount">
                        Kode Komplain
                      </Label>
                      <Input
                        placeholder="CC, ZZ, KK"
                        type="text"
                        value={complaint_code}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          const value = e.target.value;
                          if (value.length <= 10) {
                            setComplaintCode(e.target.value);
                          }
                        }}
                      />
                      <small className="form-text text-muted">Kode Kategori Komplain maksimal 10 karakter</small>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button color="primary" className="btn" type="submit">
                    Simpan
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default EditComplaintCategory;
