import React from 'react';
import { Card, CardBody, Row, Col, CardTitle } from 'reactstrap';

const CardAffiliate = props => (
  <Card className="card-stats">
    <CardBody>
      <Row>
        <Col className="col-auto">
          <div className={`icon icon-shape bg-gradient-${props.gradient} text-white rounded-circle shadow`}>
            <i className={props.icon} />
          </div>
        </Col>
        <div className="col">
          <CardTitle tag="h5" className="text-uppercase mt-3">
            {props.name}
          </CardTitle>
        </div>
        <span className="h2 font-weight-bold mt-2 mr-2">
          {props.point ? <>{new Intl.NumberFormat('id-ID').format(props.value)}</> : <>{props.value}</>}
        </span>
      </Row>
    </CardBody>
  </Card>
);

export default CardAffiliate;
