import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import CustomerTypeService from '../../../services/CustomerTypeService';
import LeadsService from '../../../services/LeadsService';
import OrderService from '../../../services/OrderService';
import RegionalService from '../../../services/RegionalService';

const state = {
  address: '',
  province_id: '',
  district_id: '',
  subdistrict_id: '',
  postal_code: ''
};

const FormLeads = props => {
  const user = useSelector(state => state.auth.user);

  const [values, setValues] = useState(state);
  const [first_chat, setFirstChat] = useState('');
  const [dealmaker, setDealmaker] = useState(user.name);
  const [whatsappCs, setWhatsappCs] = useState(user.phone);

  const [customer_phone, setCustomerPhone] = useState('');
  const [requiredPhone, setRequiredPhone] = useState(true);
  const [customer_name, setCustomerName] = useState('');
  // const [customer_vehicle, setCustomerVehicle] = useState('');
  const [contact_saved, setContactSaved] = useState(false);
  const [can_be_contacted, setCanBeContacted] = useState(false);

  const [purchased, setPurchased] = useState(false);

  const [platform, setPlatform] = useState('');

  const [manufacturer, setManufacturer] = useState('');
  const [brand, setBrand] = useState('');
  const [year, setYear] = useState('');
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [orderSourceOptions, setOrderSourceOptions] = useState([]);
  const [reasonType, setReasonType] = useState([]);
  const [reason, setReason] = useState('');

  const [customerType, setCustomerType] = useState([]);
  const [customer_type_id, setCustomerTypeId] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [postalMessage, setPostalMessage] = useState('');

  const { address, province_id, district_id, subdistrict_id, postal_code } = values;

  useEffect(() => {
    getDataReason();
    getProvince();
    getDataCustomerType();
    getDataPlatform();
  }, []);

  const getDataPlatform = async () => {
    try {
      setLoading(true);
      // order source sebagai platform di leads
      const res = await OrderService.getOrderSources();
      if (res.status === 200) {
        setOrderSourceOptions(
          res.data.data.map(data => ({
            label: data.name,
            value: data.name
          }))
        );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getDataReason = async () => {
    try {
      setLoading(true);
      const { data } = await LeadsService.getDataReasonManagementLeads();
      setReasonType(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const getDataCustomerType = async () => {
    try {
      setLoading(true);
      const { data } = await CustomerTypeService.list();
      setCustomerType(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getProvince = async () => {
    try {
      const res = await RegionalService.province();
      setProvinces(res.data);
    } catch (error) {}
  };

  const provinceChange = e => {
    // console.log(e.target.value);
    setValues({ ...values, district_id: '', province_id: e.target.value });
    RegionalService.districtByProvince(e.target.value)
      .then(res => {
        // console.log(res);
        setDistrict(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const subDistrictChange = e => {
    // console.log(e.target.value);
    setValues({ ...values, subdistricts_id: '', district_id: e.target.value });
    RegionalService.subdistrictByDistrict(e.target.value)
      .then(res => {
        // console.log(res);
        setSubdistricts(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const cobaKec = e => {
    // console.log(e.target.value);
    setValues({ ...values, subdistrict_id: e.target.value });
  };

  const postalCodeValidator = e => {
    const kodePos = e.target.value;
    // eslint-disable-next-line
    const testNIK = /^[0-9]{5}$/;

    let kodePosvalid =
      kodePos === '' ? (
        true
      ) : testNIK.test(kodePos) ? (
        true
      ) : (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid Postal Code
        </div>
      );

    setValues({ ...values, postal_code: kodePos });
    setPostalMessage(kodePosvalid);
  };

  function formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const handleCustomerPhone = phone => {
    phone = phone
      .replace(/\D/g, '')
      .replace(/^0+/, '')
      .replace(/^62/g, '');
    setCustomerPhone(phone);
  };

  const loadVehicleOptions = async inputValue => {
    if (inputValue.length >= 2) {
      const response = await LeadsService.searchVehicle(inputValue);
      let options = [];

      for (let row of response.data) {
        options.push({ manufacturer: row.manufacturer, brand: row.brand, label: row.vehicle_name });
      }
      return options;
    }
  };

  const handleInputVehicleChange = async inputValue => {
    setManufacturer(inputValue.manufacturer);
    setBrand(inputValue.brand);
  };

  const onSubmit = e => {
    e.preventDefault();

    const data = {
      first_chat,
      customer_phone: customer_phone ? '+62' + customer_phone : '',
      customer_name,
      // customer_vehicle,
      manufacturer,
      brand,
      year,
      platform,
      contact_saved,
      can_be_contacted,
      reason,
      purchased,
      customer_type_id,
      address,
      province_id,
      district_id,
      subdistrict_id,
      postal_code
    };

    LeadsService.inputLeads(data)
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Leads Berhasil Ditambahkan',
            onClose: props.history.push('/manajemen/leads')
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      {loading && <div className="loader"></div>}
      <SimpleHeader name="Form Leads" parentName="Manajemen Leads" parentLink="/manajemen/leads" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col md="5">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="whatsappCs">
                            No. WhatsApp CS
                          </Label>
                          <Input
                            readOnly
                            id="whatsappCs"
                            placeholder="Whatsapp CS"
                            name="whatsappCs"
                            value={whatsappCs}
                            onChange={e => {
                              setWhatsappCs(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="first_chat">
                            Tanggal Leads Masuk
                          </Label>
                          <Input
                            id="first_chat"
                            required
                            type="date"
                            name="first_chat"
                            onKeyDown={e => e.preventDefault()}
                            value={first_chat}
                            onChange={e => {
                              setFirstChat(formatDate(e.target.value));
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="customer_phone">
                            No. WhatsApp Customer
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+62</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              required={requiredPhone}
                              placeholder="888xxx"
                              name="customer_phone"
                              type="text"
                              value={customer_phone}
                              onChange={e => handleCustomerPhone(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        {!purchased && (
                          <FormGroup>
                            <Label className="form-control-label" htmlFor="reason">
                              Alasan Belum Beli
                            </Label>
                            <Input required name="reason" type="select" onChange={e => setReason(e.target.value)}>
                              <option value="">--Pilih Alasan--</option>
                              {reasonType.map(res => (
                                <option key={res} value={res}>
                                  {res}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        )}
                        {purchased && (
                          <>
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <Input
                                    required
                                    type="select"
                                    name="customer_type_id"
                                    onChange={e => setCustomerTypeId(e.target.value)}
                                  >
                                    <option value="">Pilih Jenis Customer</option>
                                    {customerType.map((res, key) => (
                                      <option value={res.id} key={key}>
                                        {res.name}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>

                              <Col md="6">
                                {/* PROVINCE */}
                                <FormGroup>
                                  <Input required type="select" name="province_id" onChange={provinceChange}>
                                    <option value="">Pilih Provinsi</option>
                                    {provinces.map((prov, key) => (
                                      <option value={prov.id} key={key}>
                                        {prov.name}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>

                              <Col md="6">
                                {/* District */}
                                <FormGroup>
                                  <Input required type="select" name="district_id" onChange={subDistrictChange}>
                                    <option value="">Pilih Kota / Kabupaten</option>
                                    {district.map((result, key) => (
                                      <option value={result.id} key={key}>
                                        {result.type === 'Kabupaten' ? 'Kab.' : result.type} {result.name}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>

                              <Col md="6">
                                {/* Sub District */}
                                <FormGroup>
                                  <Input required type="select" name="subdistricts_id" onChange={cobaKec}>
                                    <option value="">Pilih Kecamatan</option>
                                    {subdistricts.map((result, key) => (
                                      <option value={result.id} key={key}>
                                        {result.name}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>

                              <Col md="6">
                                <FormGroup>
                                  {postalMessage}
                                  <Input
                                    required
                                    placeholder="Kode Pos"
                                    name="postal_code"
                                    type="text"
                                    value={postal_code.replace(/[^0-9]+/gi, '')}
                                    onChange={postalCodeValidator}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup>
                                  <Input
                                    type="textarea"
                                    placeholder="Alamat"
                                    name="address"
                                    id="exampleText"
                                    value={address}
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={e => {
                                setPurchased(!purchased);
                              }}
                              value={purchased}
                            />
                            <div style={{ fontWeight: '600', fontSize: '14px' }}>Sudah Beli</div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="contact_saved"
                              onChange={e => {
                                setContactSaved(!contact_saved);
                              }}
                              value={contact_saved}
                            />
                            <div style={{ fontWeight: '600', fontSize: '14px' }}>Save Kontak</div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              name="can_be_contacted"
                              onChange={e => {
                                setCanBeContacted(!can_be_contacted);
                              }}
                              value={can_be_contacted}
                            />
                            <div style={{ fontWeight: '600', fontSize: '14px' }}>Nomor Dapat Dihubungi</div>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="5">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="dealmaker">
                            Dealmaker
                          </Label>
                          <Input
                            readOnly
                            id="dealmaker"
                            placeholder="Dealmaker"
                            name="dealmaker"
                            value={dealmaker}
                            onChange={e => {
                              setDealmaker(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="customer_name">
                            Nama Customer
                          </Label>
                          <Input
                            id="customer_name"
                            required
                            placeholder="Nama Customer"
                            type="text"
                            value={customer_name}
                            name="customer_name"
                            onChange={e => {
                              setCustomerName(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="vehicleName">
                            Kendaraan Customer
                          </Label>
                          <Row>
                            <Col md="8">
                              <AsyncSelect
                                id="vehicleName"
                                name="vehicle_id"
                                // defaultOptions
                                placeholder="Pilih Kendaraan"
                                // ref={props.customerSelectRef}
                                loadOptions={loadVehicleOptions}
                                onChange={handleInputVehicleChange}
                              />
                              <div id="vehicleValidFeedback" style={{ display: 'hidden' }} className="invalid-feedback">
                                Merek Kendaraan wajib diisi
                              </div>
                            </Col>

                            <Col md="4">
                              <Input
                                id="year"
                                type="select"
                                name="year"
                                value={year}
                                onChange={e => {
                                  setYear(e.target.value);
                                }}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Tahun--</option>
                                {[...Array(parseInt(new Date().getFullYear()) - 1898).keys()]
                                  .map(key => key + 1900)
                                  .sort((a, b) => b - a)
                                  .map(year => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-control-label" htmlFor="platformName">
                            Platform
                          </Label>
                          <Row>
                            <Col md="12">
                              <Select
                                id="platformName"
                                name="platform_name"
                                placeholder="Pilih Platform"
                                options={orderSourceOptions}
                                onChange={select => {
                                  setPlatform(select.value);
                                  if (['DM Instagram', 'Messenger Facebook', 'DM TikTok'].includes(select.value)) {
                                    setRequiredPhone(false);
                                  } else {
                                    setRequiredPhone(true);
                                  }
                                }}
                              />
                              <div id="vehicleValidFeedback" style={{ display: 'hidden' }} className="invalid-feedback">
                                Platform wajib diisi
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button color="primary" className="btn" type="submit">
                    Simpan
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default FormLeads;
