import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
import '../../reports/AdvertiserReport.scss';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

function DetailPayoutTable(props) {
  return (
    <Card id="section-to-print">
      <CardHeader>
        <Row>
          <Col md="8">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>ID Payout</strong>
                  </td>
                  <td>: {props.payout.id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Periode</strong>
                  </td>
                  <td>
                    : {moment(props.payout.start_date).format('LL')} - {moment(props.payout.end_date).format('LL')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Nama</strong>
                  </td>
                  <td>: {props.payout.user.name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Dibuat</strong>
                  </td>
                  <td>: {moment(props.payout.created_at).format('LL')}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col md="4">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Bank</strong>
                  </td>
                  <td>: {props.payout.user.affiliate.bank_name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>No. Rek</strong>
                  </td>
                  <td>: {props.payout.user.affiliate.account_number}</td>
                </tr>
                <tr>
                  <td>
                    <strong>A/N</strong>
                  </td>
                  <td>: {props.payout.user.affiliate.account_name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>NPWP</strong>
                  </td>
                  <td>: {props.payout.user.affiliate.npwp}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: '100%' }}>Tanggal</th>
                  <th style={{ width: '100%' }}>Nomor Order</th>
                  <th>Barang</th>
                  <th>Merek</th>
                  <th style={{ width: '100px' }}>Komisi</th>
                </tr>
              </thead>
              <tbody>
                {props.payout.details.map((detail, key) => {
                  return (
                    <tr key={key}>
                      <td>{moment(detail.affiliate_order.created_at).format('DD-MM-YYYY')}</td>
                      <td>{detail.item.order.order_number}</td>
                      <td>{detail.item.category.name}</td>
                      <td>{detail.item.product_name}</td>
                      <td className="number-column">
                        {detail.affiliate_order.commission.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="3"></td>
                  <td style={{ fontSize: '1.5rem' }}>
                    <strong>Total</strong>
                  </td>
                  <td style={{ fontSize: '1.5rem' }}>
                    <strong>
                      {props.payout.total.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default DetailPayoutTable;
