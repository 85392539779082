import React from 'react';
import { Card, CardBody, Row, CardTitle } from 'reactstrap';
import nominalBilangan from 'nominal-bilangan';

const CardComparation = props => {
  // console.log(props.change);
  return (
    <Card className="card-stats">
      <CardBody className="text-center">
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {props.name}
            </CardTitle>
          </div>
        </Row>
        <div className="h2 font-weight-bold mb-0">
          {props.omzet.toLocaleString('id', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
          })}
        </div>
        <div>
          <span className={`text-${props.change < 0 ? 'danger' : props.change === 0 ? 'warning' : 'success'} mr-2`}>
            <p className="mt-2 mb-0" style={{ fontSize: '0.9rem' }}>
              <strong>
                {props.change ? (
                  <>
                    <i
                      className={`fa fa-${props.change < 0 ? 'caret-down' : props.change === 0 ? 'sort' : 'caret-up'}`}
                    />{' '}
                    {`${props.change < 0 ? '-' : '+'}Rp ${nominalBilangan(Math.abs(props.change))} (${
                      props.change_percent > 0 ? '+' + props.change_percent : props.change_percent
                    }%)`}
                  </>
                ) : (
                  <>
                    {props.change.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                    {` `}({props.change_percent > 0 ? '+' + props.change_percent : props.change_percent}%)
                  </>
                )}
              </strong>
            </p>
          </span>
          <span>
            <p style={{ marginTop: '-1rem' }}>{props.comparation}</p>
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

export default CardComparation;
