import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import ScoreboardService from '../../../../services/ScoreboardService';

import './ScoreboardTeam.scss';
import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

// const colors = [
//   '#1b5e20',
//   '#e65100',
//   '#6200ea',
//   '#aa00ff',
//   '#d50000',
//   '#c51162',
//   '#2196f3',
//   '#009688',
//   '#00b8d4',
//   '#3f51b5',
//   '#03a9f4'
// ];

const colors = ['#006400', '#e85008'];

function ScoreboardTeam() {
  const [data, setData] = useState({
    month_name: '',
    total_omzet: 0,
    teams: [
      {
        id: 0,
        name: '',
        members: [
          {
            id: 0,
            name: '',
            omzet_daily: 0,
            omzet_monthly: 0
          }
        ],
        omzet_daily: 0,
        omzet_monthly: 0
      }
    ]
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await ScoreboardService.getScoreboardTeam();

      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toRP = n => {
    return n.toLocaleString('id', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col
          lg="12"
          style={{ textAlign: 'center', margin: 0, padding: '16px 0 0 0', background: 'rgba(234, 153, 153, 0.75)' }}
        >
          {/* <Card>
            <CardBody style={{  background: 'rgba(234, 153, 153, 0.5)' }}> */}
          <h1>SCORE GREAT TEAM COVERSUPER</h1>
          <h4>{moment().format('dddd, Do MMMM YYYY')}</h4>
          <h1>Total Omzet Bulanan</h1>
          <h1>{toRP(data.total_omzet)}</h1>
          {/* </CardBody>
          </Card> */}
        </Col>
      </Row>
      <Row>
        {data.teams.map((tim, i) => {
          return (
            <Col lg="6" xs="12" key={i} style={{ margin: 0, padding: 0 }}>
              <Card>
                <CardBody
                  style={{
                    // background: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                    color: 'white',
                    fontWeight: 'bold',
                    background: colors[i]
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <h1 style={{ color: 'white' }}>{tim.name}</h1>
                  </div>
                  <div>
                    <Row>
                      <Col xs="6">
                        <h1 style={{ color: 'white' }}>Omzet Bulanan</h1>
                      </Col>
                      <Col>
                        <h1 style={{ color: 'white' }}>{toRP(tim.omzet_monthly)}</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <h2 style={{ color: 'white' }}>Omzet Harian</h2>
                      </Col>
                      <Col>
                        <h2 style={{ color: 'white' }}>{toRP(tim.omzet_daily)}</h2>
                      </Col>
                    </Row>
                  </div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <td colSpan="4" style={{ textAlign: 'center' }}>
                          <strong>DEALMAKER</strong>
                        </td>
                      </tr>
                      <tr>
                        <th className="tim">No</th>
                        <th className="tim">Nama</th>
                        <th className="tim">Omzet Harian</th>
                        <th className="tim">Omzet Bulanan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tim.members.map((member, k) => {
                        return (
                          <tr key={k}>
                            <td className="tim">{k + 1}</td>
                            <td className="tim">{member.name}</td>
                            <td className="tim number-column">{toRP(member.omzet_daily)}</td>
                            <td className="tim number-column">{toRP(member.omzet_monthly)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default ScoreboardTeam;
