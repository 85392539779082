import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

// import Swal from 'sweetalert2';
// import CustomerService from '../../../../services/CustomerService';
import Can from '../../../../config/Can';

// import AddDepositModal from './AddDepositModal';
import DepositVerificationModal from './DepositVerificationModal';

import moment from 'moment';
import 'moment/locale/id';
import Loader from '../../../../components/Loader/Loader';
// import CardToday from '../../dashboards/components/CardToday';
import FinanceService from '../../../../services/FinanceService';
moment.locale('id');

// const { SearchBar } = Search;

function FinanceDeposit(props) {
  const [downloadButtonShow, setDownloadButtonShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    depositModalOpen: false,
    depositVerificationModalOpen: false
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const [totalSize, setTotalSize] = useState(undefined);

  const startDateRef = useRef();
  const endDateRef = useRef();

  useEffect(() => {
    getData({ page, limit, filter, start_date, end_date });
  }, []);

  const isValid = () => {
    const setRef = ref => {
      ref.current.classList.add('is-invalid');
    };

    if (!start_date || start_date === '') {
      setRef(startDateRef);
      return false;
    } else if (!end_date || end_date === '') {
      setRef(endDateRef);
      return false;
    }
    return true;
  };

  const getData = async ({ page, limit, sort = null, order = null, filter, start_date, end_date }) => {
    setLoading(true);
    try {
      const res = await FinanceService.getDepositDatatable(page, limit, sort, order, filter, start_date, end_date);
      if (res.status === 200) {
        setLoading(false);
        setData(res.data.data);
        setPage(res.data.page);
        setLimit(res.data.limit);
        setTotalSize(res.data.recordsFiltered);
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  const onTableChange = (type, newState) => {
    getData(props.match.params.customer_id, newState.page, newState.sizePerPage, newState.searchText);
  };

  const toggleModal = (name, row = null) => {
    setSelectedRow(row);
    if (modal[name]) {
      getData({ page, limit, filter, start_date, end_date });
      setModal({ ...modal, [name]: !modal[name] });
    } else {
      setModal({ ...modal, [name]: !modal[name] });
    }
  };

  const handleDownload = async () => {
    try {
      const res = await FinanceService.downloadDeposit({
        start_date,
        end_date,
        filter
      });

      console.log(res);
      if (res.status === 200) {
        window.open(res.data.data.url, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <SimpleHeader name="Tabel Deposit" parentName="Finance" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="start_date">Dari</label>
                      <Input
                        id="start_date"
                        type="date"
                        name="start_date"
                        max={new Date().toISOString().split('T')[0]}
                        // min={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
                        innerRef={startDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={start_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setStartDate(e.target.value);
                          setEndDate('');
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="end_date">Sampai</label>
                      <Input
                        id="end_date"
                        type="date"
                        name="end_date"
                        min={new Date(start_date ? start_date : new Date()).toISOString().split('T')[0]}
                        max={new Date().toISOString().split('T')[0]}
                        innerRef={endDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={end_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');

                          setEndDate(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="end_date">Filter</label>
                      <Input
                        id="filter"
                        type="text"
                        name="filter"
                        value={filter}
                        onChange={e => {
                          setFilter(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => {
                        if (isValid()) {
                          setDownloadButtonShow(true);
                          getData({ page, limit, filter, start_date, end_date });
                        }
                      }}
                    >
                      Cari
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              {downloadButtonShow && (
                <CardHeader style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Button size="sm" color="success" onClick={handleDownload}>
                    Download
                  </Button>
                </CardHeader>
              )}
              <Row>
                <CardBody>
                  <ToolkitProvider
                    data={data}
                    keyField="id"
                    columns={[
                      // {
                      //   dataField: 'no',
                      //   text: 'Nomor',
                      //   sort: false
                      // }
                      {
                        dataField: 'customer',
                        text: 'Nama Customer',
                        sort: true
                      },
                      {
                        dataField: 'description',
                        text: 'Deskripsi',
                        sort: true
                      },
                      {
                        dataField: 'amount',
                        text: 'Jumlah',
                        sort: true,
                        formatter: cell => {
                          return cell.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          });
                        }
                      },
                      {
                        dataField: 'transaction_date',
                        text: 'Tanggal Transaksi',
                        sort: true,
                        formatter: cell => {
                          return moment(cell).format('dddd, LL');
                        }
                      },
                      // {
                      //   dataField: 'mutation_type',
                      //   text: 'Tipe Mutasi',
                      //   // style: {
                      //   //   textAlign: 'center'
                      //   // },
                      //   sort: true,
                      //   formatter: cell => {
                      //     return cell === 'K' ? (
                      //       <span className="badge badge-pill badge-success">Kredit</span>
                      //     ) : (
                      //       <span className="badge badge-pill badge-danger">Debit</span>
                      //     );
                      //   }
                      // },
                      {
                        dataField: 'is_verified',
                        text: 'Terverifikasi',
                        // style: {
                        //   textAlign: 'center'
                        // },
                        sort: true,
                        formatter: cell => {
                          return cell ? '✅' : '❌';
                        }
                      },
                      {
                        dataField: 'action',
                        text: 'Aksi',
                        sort: false,
                        isDummyField: true,
                        formatter: (cell, row, rowIndex, formatExtraData) => {
                          return (
                            <>
                              {!!!row.is_verified && (
                                <Can I="update" a="Verify Deposit">
                                  <Button
                                    color="warning"
                                    type="button"
                                    size="sm"
                                    onClick={() => toggleModal('depositVerificationModalOpen', row)}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-edit" />
                                    </span>
                                    <span className="btn-inner--text"> Verifikasi Deposit</span>
                                  </Button>
                                </Can>
                              )}
                            </>
                          );
                        }
                      }
                    ]}
                    // search
                  >
                    {props => {
                      return (
                        <div className="table-responsive">
                          <BootstrapTable
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: true
                            }}
                            loading={true}
                            {...props.baseProps}
                            bootstrap4={true}
                            pagination={paginationFactory({
                              page: page,
                              sizePerPage: limit,
                              totalSize: totalSize,
                              showTotal: true,
                              withFirstAndLast: true,
                              sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                <div className="dataTables_length" id="datatable-basic_length">
                                  <label>
                                    Show{' '}
                                    {
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm"
                                        onChange={e => onSizePerPageChange(e.target.value)}
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                    }{' '}
                                    entries.
                                  </label>
                                </div>
                              )
                            })}
                            bordered={false}
                            onTableChange={onTableChange}
                          />
                        </div>
                      );
                    }}
                  </ToolkitProvider>
                </CardBody>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
      <DepositVerificationModal row={selectedRow} toggle={toggleModal} isOpen={modal.depositVerificationModalOpen} />{' '}
    </>
  );
}

export default FinanceDeposit;
