import React from 'react';
import { Card, CardBody, Container, Row, Table } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import CustomerService from '../../../services/CustomerService';

import moment from 'moment';

class OrderDetailCustomer extends React.Component {
  state = {
    alert: null,
    orders: []
  };

  getData = () => {
    const { id } = this.props.match.params;

    CustomerService.findOrderByCustomer(id).then(response => {
      this.setState({ orders: response.data });
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
    this.props.history.push('/customers');
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Order Customer" parentName="Customer" parentLink="/customers" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>Tanggal</th>
                          <th>Nomor Order</th>
                          <th>Nama Customer</th>
                          <th>Nama Produk (Status)</th>
                          <th>Pembayaran</th>
                          <th>Dealmaker</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.orders.map((order, key) => (
                          <tr key={key}>
                            <td>{moment(order.created_at).format('DD/MM/YYYY')}</td>
                            <td>{order.order_number}</td>
                            <td>{order.customer.name}</td>
                            <td>
                              {order.details.map((detail, key) => (
                                <li key={key}>
                                  {detail.category.name} {detail.product_name} (<b>{detail.status.name}</b>)
                                </li>
                              ))}
                            </td>
                            <td>{order.payment_status.name}</td>
                            <td>{order.customer_service.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default OrderDetailCustomer;
