import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

function ComplaintDetail(props) {
  const [row, setRow] = useState({
    id: 0,
    order_detail_id: 0,
    complaint_category_id: 0,
    division: '',
    action_id: 0,
    complaint: '',
    solution: '',
    lead_time: '',
    customer_charge: 0,
    customer_charge_tax: 0,
    customer_shipping_cost: 0,
    cs_charge: 0,
    cs_charge_tax: 0,
    cs_shipping_cost: 0,
    production_charge: 0,
    production_charge_tax: 0,
    production_shipping_cost: 0,
    approval_status_id: 0,
    approval_by: 0,
    created_at: '',
    images: [],
    action: {
      id: 0,
      name: ''
    },
    approved_by: {
      id: 39,
      name: ''
    },
    category: {
      id: 2,
      complaint_code: '',
      name: ''
    }
  });

  useEffect(() => {
    if (props.row && props.row.order && !!props.row.is_complaint) {
      setRow(props.row.complaint);
    }
  }, [props.row]);

  const toggle = () => {
    setRow({
      id: 0,
      order_detail_id: 0,
      complaint_category_id: 0,
      division: '',
      action_id: 0,
      complaint: '',
      solution: '',
      lead_time: '',
      customer_charge: 0,
      customer_charge_tax: 0,
      customer_shipping_cost: 0,
      cs_charge: 0,
      cs_charge_tax: 0,
      cs_shipping_cost: 0,
      production_charge: 0,
      production_charge_tax: 0,
      production_shipping_cost: 0,
      approval_status_id: 0,
      approval_by: 0,
      created_at: '',
      images: [],
      action: {
        id: 0,
        name: ''
      },
      approved_by: {
        id: 39,
        name: ''
      },
      category: {
        id: 2,
        complaint_code: '',
        name: ''
      }
    });

    props.toggle(null, 'isComplaintDetailModalShow');
  };

  return row ? (
    <Modal isOpen={props.isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Complaint Detail</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12">
            <div>
              <Table style={{ tableLayout: 'fixed', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                <tbody>
                  <tr>
                    <td>Order ID</td>
                    <td>
                      :&nbsp;<strong>{props.row && props.row.order && props.row.order.id}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Lead Time</td>
                    <td>
                      :&nbsp;
                      {new Date(row.lead_time).toLocaleDateString('id-ID', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Kategori Komplain</td>
                    <td>
                      :&nbsp;
                      {row.category.name}
                    </td>
                  </tr>
                  <tr>
                    <td>Divisi</td>
                    <td>
                      :&nbsp;
                      {row.division}
                    </td>
                  </tr>
                  <tr>
                    <td>Aksi</td>
                    <td>
                      :&nbsp;
                      {row.action.name}
                    </td>
                  </tr>
                  <tr>
                    <td>Komplain</td>
                    <td>
                      :&nbsp;
                      {row.complaint}
                    </td>
                  </tr>
                  <tr>
                    <td>Solusi</td>
                    <td>
                      :&nbsp;
                      {row.solution}
                    </td>
                  </tr>
                  <tr>
                    <td>Biaya Customer</td>
                    <td>
                      :&nbsp;
                      {row.customer_charge.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Pajak Biaya Customer</td>
                    <td>
                      :&nbsp;
                      {row.customer_charge_tax.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Biaya Pengiriman Customer</td>
                    <td>
                      :&nbsp;
                      {row.customer_shipping_cost.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Biaya CS</td>
                    <td>
                      :&nbsp;
                      {row.cs_charge.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Pajak Biaya CS</td>
                    <td>
                      :&nbsp;
                      {row.cs_charge_tax.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Biaya Pengiriman CS</td>
                    <td>
                      :&nbsp;
                      {row.cs_shipping_cost.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Biaya Produksi</td>
                    <td>
                      :&nbsp;
                      {row.production_charge.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Pajak Biaya Produksi</td>
                    <td>
                      :&nbsp;
                      {row.production_charge_tax.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Biaya Pengiriman Produksi</td>
                    <td>
                      :&nbsp;
                      {row.production_shipping_cost.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Status Persetujuan</td>
                    <td>
                      :&nbsp;
                      {!!row.approval_status_id ? 'Disetujui' : 'Belum disetujui'}
                    </td>
                  </tr>
                  <tr>
                    <td>Disetujui Oleh</td>
                    <td>
                      :&nbsp;
                      {row.approved_by ? row.approved_by.name : ''}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          {row.images.length > 0 &&
            row.images.map((gambar, i) => (
              <Col key={i} xs="4">
                <img src={gambar.image} alt={`complaint ${i + 1}`} style={{ maxWidth: '100%' }} />
              </Col>
            ))}
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Tutup
        </Button>
      </ModalFooter>
    </Modal>
  ) : (
    'Loading...'
  );
}

export default ComplaintDetail;
