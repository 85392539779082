import { request } from "../config";

// versi function
function CustomerTypeService() {

  // Get Data for Datatable
  const list = async () => {
    const response = await request.get('/customertypes/list');
    return response.data;
  };

  return {
    list
  }
}

export default CustomerTypeService()