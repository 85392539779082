import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';

function RefundDetail(props) {
  const [row, setRow] = useState({
    refund_desc: '',
    refund_amount: 0,
    bank_name: '',
    account_number: '',
    account_name: '',
    evidence_image: '',
    transfer_date: ''
  });

  useEffect(() => {
    if (props.row && props.row.order && props.row.order.refund) {
      setRow(props.row.order.refund);
    }
  }, [props.row]);

  const toggle = () => {
    setRow({
      refund_desc: '',
      refund_amount: 0,
      bank_name: '',
      account_number: '',
      account_name: '',
      evidence_image: '',
      transfer_date: ''
    });

    props.toggle(props.row, 'isRefundDetailModalShow');
  };

  return row ? (
    <Modal isOpen={props.isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Refund Detail</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12">
            <div>
              <Table style={{ tableLayout: 'fixed', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                <tbody>
                  <tr>
                    <td>Order ID</td>
                    <td>
                      :&nbsp;<strong>{props.row && props.row.order && props.row.order.id}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Tanggal Transfer</td>
                    <td>
                      :&nbsp;
                      {new Date(row.transfer_date).toLocaleDateString('id-ID', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Nomor Rekening</td>
                    <td>
                      :&nbsp;
                      {row.account_number}
                    </td>
                  </tr>
                  <tr>
                    <td>Atas Nama Rekening</td>
                    <td>
                      :&nbsp;
                      {row.account_name}
                    </td>
                  </tr>
                  <tr>
                    <td>Bank</td>
                    <td>
                      :&nbsp;
                      {row.bank_name}
                    </td>
                  </tr>
                  <tr>
                    <td>Nominal</td>
                    <td>
                      :&nbsp;
                      {row.refund_amount.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Keterangan Refund</td>
                    <td>
                      :&nbsp;
                      {row.refund_desc}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={row.evidence_image} alt="bukti transfer refund" style={{ maxWidth: '100%' }} />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Tutup
        </Button>
      </ModalFooter>
    </Modal>
  ) : (
    'Loading...'
  );
}

export default RefundDetail;
