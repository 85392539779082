import React from 'react';
import { Col, FormGroup, Input } from 'reactstrap';
import * as Constants from '../../../../constants';

function PaymentDateComponent(props) {
  if (
    props.platform === Constants.PLATFORM_REGULAR &&
    parseInt(props.payment_status_id) === parseInt(Constants.PAYMENT_STATUS_UNPAID)
  ) {
    return null;
  } else {
    return (
      <Col md="4">
        <FormGroup>
          <label className="form-control-label" htmlFor="amountPaid">
            Tanggal Bayar
            <sup>
              <span className="text-red">*</span>
            </sup>
          </label>
          <Input
            id="amountPaid"
            type="date"
            name="payment_date"
            innerRef={props.paymentDateRef}
            min={new Date(props.order_date ? props.order_date : Date.now()).toISOString().split('T')[0]}
            onKeyDown={e => e.preventDefault()}
            onWheel={e => false}
            value={props.payment_date}
            onChange={e => {
              // remove class invalid
              e.target.classList.remove('is-invalid');
              props.handleChange('payment_date', e.target.value);
            }}
          />
        </FormGroup>
      </Col>
    );
  }
}

export default PaymentDateComponent;
