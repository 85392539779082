import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap';
import Swal from 'sweetalert2';
import * as Constants from '../../../constants';
import OrderService from '../../../services/OrderService';

import { CapitalizeFirstLetter } from '../../../helpers/CapitalizeFirstLetter';
import BankService from '../../../services/BankService';
import PaymentAmountComponent from './form_order/PaymentAmountComponent';
import PaymentDateComponent from './form_order/PaymentDateComponent';
import PaymentEvidenceComponent from './form_order/PaymentEvidenceComponent';
import PaymentOrderComponent from './form_order/PaymentOrderComponent';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      imageURLs: [],
      payment_statuses: [],
      verification_type: '',
      channel: '',
      channels: [],
      banks: []
    };
  }

  paymentRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.evidenceImagePasteFile && prevProps.evidenceImagePasteFile !== this.props.evidenceImagePasteFile) {
      this.handleImagePaste(this.props.evidenceImagePasteFile);
    }

    if (this.props.platform !== prevProps.platform) {
      this.handlePaymentStatus();
    }
  }

  componentDidMount() {
    this.getPaymentChannels();
    this.getBanks();
  }

  paymentAmountRef = React.createRef();
  evidenceImageRef = React.createRef();

  isValid = () => {
    const {
      request_tax_invoice,
      npwp,
      shippingAddress,
      productVariants,
      courier,
      payment_amount,
      order_source,
      platform,
      payment_date,
      payment_status_id,
      invoice_required,
      invoice_number,
      order_date
    } = this.props;

    if (invoice_required && invoice_number === '') {
      const ref = this.props.invoiceNumberRef;
      return this.setInvalid(ref, 'invoice_number');
    } else if (!order_source || order_source === '') {
      const orderSource = document.getElementById('orderSource');
      document.getElementById('orderSourceValidFeedback').style.display = 'block';

      orderSource.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      return false;
    } else if (!platform || platform === '') {
      const platform = document.getElementById('platformOptions');
      document.getElementById('platformValidFeedback').style.display = 'block';

      platform.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      return false;
    } else if (request_tax_invoice && npwp === null) {
      return this.setInvalid(this.props.npwpImageRef, 'npwp');
    } else if (!order_date || order_date === '') {
      const ref = this.props.orderDateRef;
      return this.setInvalid(ref, 'order_date');
    } else if (shippingAddress.id === '') {
      const customerNameEl = document.getElementById('customerName');
      document.getElementById('customerValidFeedback').style.display = 'block';
      customerNameEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      return false;
    } else if (shippingAddress.name === '') {
      return this.setInvalid(this.props.shippingAddressNameRef, 'shippingAddress name');
    } else if (shippingAddress.phone === '') {
      return this.setInvalid(this.props.shippingAddressPhoneRef, 'shipping address phone');
    } else if (shippingAddress.address === '') {
      return this.setInvalid(this.props.shippingAddressAddressRef, 'shipping address address');
    } else if (shippingAddress.province_id === '') {
      return this.setInvalid(this.props.shippingAddressProvinceRef, 'ship province');
    } else if (shippingAddress.district_id === '') {
      return this.setInvalid(this.props.shippingAddressDistrictRef, 'ship district');
    } else if (shippingAddress.subdistrict_id === '') {
      return this.setInvalid(this.props.shippingAddressSubdistrictRef, 'ship subdistrict');
    } else if (shippingAddress.postal_code === '') {
      return this.setInvalid(this.props.shippingAddressPostalCodeRef, 'ship postal');
    }

    const productVariantCar = productVariants.filter(
      oke => !!!oke.product.category.is_ready_stock
      // && ![Constants.CATEGORY_KARPET, Constants.CATEGORY_KARPET_BAGASI].includes(oke.product.category_id)
    );

    const productVariantKarpet = productVariants.filter(
      oke => Constants.TYPE_KARPET === oke.product.category.vehicle_type_id
    );

    // belum ada produk
    if (productVariants.length <= 0) {
      const oke = document.getElementById('productVariants');
      oke.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

      this.props.setWarningState(
        <UncontrolledAlert color="warning" fade={false}>
          <span className="alert-inner--text">
            <strong>Peringatan!</strong> Produk Belum Ada
          </span>
        </UncontrolledAlert>
      );
      console.log('error tak ada produk');

      return false;
    } else if (productVariantCar.map(ok => ok.images).some(un => un === undefined)) {
      // ^^  belum ada gambar
      const variantMap = productVariants
        .map((ok, i) => {
          if (typeof ok.images === 'undefined') {
            return {
              id: ok.id,
              category_id: ok.product.category_id,
              index: i,
              is_ready_stock: ok.product.category.is_ready_stock
            };
          }
          return ok;
        })
        .filter(oke => oke);

      // filter lagi khusus mobil
      const productEmpty = variantMap.filter(
        oke => !!!oke.is_ready_stock
        // && ![Constants.CATEGORY_KARPET, Constants.CATEGORY_KARPET_BAGASI].includes(oke.product.category_id)
      );

      for (let i = 0; i < productEmpty.length; i++) {
        const product = productEmpty[i];

        // if (this.props.rowProductRef[i].id === `row-product-${i}-${product.id}`) {
        document.getElementById(`row-product-${product.index}-${product.id}`).style.background = '#ffbaba';
        document
          .getElementById(`row-product-${product.index}-${product.id}`)
          .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        // }
      }

      this.props.setWarningState(
        <UncontrolledAlert color="warning" fade={false}>
          <span className="alert-inner--text">
            <strong>Peringatan!</strong> Gambar Belum Diupload
          </span>
        </UncontrolledAlert>
      );
      console.log('error tak ada gambar');

      return false;
    } else if (productVariants.map(ok => ok.color_id).some(un => un === undefined || un === '' || un === 0)) {
      productVariants.forEach((ok, i) => {
        if (ok.color_id === undefined || ok.color_id === 0 || ok.color_id === '') {
          const oke = document.getElementById(`color_id-${i}`);
          oke.classList.add('is-invalid');
          oke.focus();
        }
      });

      return false;
    } else if (productVariantCar.map(ok => ok.year).some(y => y === undefined || y === '' || !y)) {
      const variantMap = productVariants
        .map((ok, i) => {
          if (ok.y === undefined || ok.y === '' || !ok.y) {
            return {
              id: ok.id,
              category_id: ok.product.category_id,
              index: i,
              is_ready_stock: ok.product.category.is_ready_stock
            };
          }
          return ok;
        })
        .filter(oke => oke);

      const productEmpty = variantMap.filter(oke => !!!oke.is_ready_stock);

      for (let i = 0; i < productEmpty.length; i++) {
        const product = productEmpty[i];

        const oke = document.getElementById(`year-${product.index}`);
        oke.classList.add('is-invalid');
        oke.focus();

        // if (this.props.rowProductRef[i].id === `row-product-${i}-${product.id}`) {
        // document.getElementById(`row-product-${product.index}-${product.id}`).style.background = '#ffbaba';
        // document
        //   .getElementById(`row-product-${product.index}-${product.id}`)
        //   .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        // }
      }

      console.log('error tak ada tahun');

      return false;
    } else if (productVariants.map(ok => ok.cover_type_id).some(un => un === undefined || un === '' || un === 0)) {
      productVariants.forEach((ok, i) => {
        if (ok.cover_type_id === undefined || ok.cover_type_id === 0 || ok.cover_type_id === '') {
          const oke = document.getElementById(`cover_type-${i}`);
          oke.classList.add('is-invalid');
          oke.focus();
        }
      });

      console.log('error tak ada cover type');

      return false;
    } else if (
      productVariants
        .map(ok => {
          return {
            cover_type_id: ok.cover_type_id,
            cover_list_id: ok.cover_list_id
          };
        })
        .some(
          un =>
            parseInt(un.cover_type_id) !== 1 &&
            (un.cover_list_id === undefined || un.cover_list_id === '' || un.cover_list_id === 0)
        )
    ) {
      productVariants.forEach((ok, i) => {
        if (
          parseInt(ok.cover_type_id) !== 1 &&
          (ok.cover_list_id === undefined || ok.cover_list_id === 0 || ok.cover_list_id === '')
        ) {
          const oke = document.getElementById(`cover_list-${i}`);
          oke.classList.add('is-invalid');
          oke.focus();
        }
      });

      console.log('error tak ada cover list');

      return false;
    } else if (productVariants.map(ok => ok.logo).some(un => un === undefined || un === '' || un === 0)) {
      productVariants.forEach((ok, i) => {
        if (ok.logo === undefined || ok.logo === '') {
          const oke = document.getElementById(`logo-${i}`);
          oke.classList.add('is-invalid');
          oke.focus();
        }
      });
      console.log('error tak ada logo');

      return false;
    } else if (
      productVariantKarpet.map(ok => ok.transmission_type).some(un => un === undefined || un === '' || un === 0)
    ) {
      const variantKarpet = productVariants
        .map((ok, i) => {
          return { ...ok, index: i };
        })
        .filter(oke => oke.product.category.vehicle_type_id === Constants.TYPE_KARPET);

      console.log({ variantKarpet });

      variantKarpet.forEach(ok => {
        if (ok.transmission_type === undefined || ok.transmission_type === '') {
          const oke = document.getElementById(`transmission_type-${ok.index}`);
          oke.classList.add('is-invalid');
          oke.focus();
          console.log('error tak ada transmission_type' + ok.index);
        }
      });

      return false;
    } else if (courier.code === '') {
      return this.setInvalid(this.props.courierSelectRef, 'courier');
    } else if (courier.code === 'manual' && courier.name === '') {
      return this.setInvalid(this.props.nameSelectRef, 'courier code');
    } else if (courier.service === '') {
      return this.setInvalid(this.props.serviceSelectRef, 'courier service');
    } else if (courier.cost === '' || courier.cost === '0' || courier.cost === 0) {
      this.props.ongkirCostRef.current.focusTextInput();
      return false;
    }

    // pembayaran
    if (payment_status_id === '') {
      return this.setInvalid(this.props.paymentStatusRef, 'payment status');
    } else if (
      payment_date === '' &&
      ![
        Constants.PAYMENT_STATUS_COD_MP,
        Constants.PAYMENT_STATUS_MARKETPLACE,
        Constants.PAYMENT_STATUS_UNPAID,
        Constants.PAYMENT_STATUS_BILLING
      ].includes(parseInt(payment_status_id))
    ) {
      return this.setInvalid(this.props.paymentDateRef, 'payment_date');
    } else if (
      (payment_amount === '' || payment_amount < this.props.countGrandTotal() * 0.4) &&
      ![
        Constants.PAYMENT_STATUS_COD_MP,
        Constants.PAYMENT_STATUS_MARKETPLACE,
        Constants.PAYMENT_STATUS_UNPAID,
        Constants.PAYMENT_STATUS_BILLING
      ].includes(parseInt(payment_status_id))
    ) {
      this.paymentAmountRef.current.focusTextInput();

      return false;
    } else if (
      [Constants.PAYMENT_STATUS_PAID, Constants.PAYMENT_STATUS_DP].includes(parseInt(payment_status_id)) &&
      this.props.bank_id === 0
    ) {
      return this.setInvalid(this.props.bankRef, 'bank id');
    } else if (
      this.state.imageURLs === '' &&
      this.state.imageURLs.length === 0 &&
      ![Constants.PAYMENT_STATUS_UNPAID, Constants.PAYMENT_STATUS_BILLING].includes(parseInt(payment_status_id)) &&
      this.props.bank_id !== Constants.PEMBAYARAN_DEPOSIT
    ) {
      return this.setInvalid(this.evidenceImageRef, 'bukti transfer');
    } else if (parseInt(payment_status_id) === parseInt(Constants.PAYMENT_STATUS_UNPAID)) {
      if (!this.state.channel || this.state.channel === '') {
        return this.setInvalid(this.paymentRef, 'pembayaran belum lunas');
      }
    }

    return true;
  };

  setInvalid = (ref, apa) => {
    const current = ref.current;
    if (current) {
      current.classList.add('is-invalid');
      current.focus();
      return false;
    } else {
      Swal.fire({
        icon: 'error',
        title: `errornya di ${apa}`
      });
    }
  };

  handleImagePaste = async file => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      this.setState({ loading: true });
      const image = await OrderService.uploadImage(formData);

      if (image.message === 'OK') {
        this.setState({
          imageURLs: [...this.state.imageURLs, image.data],
          loading: false
        });
        this.props.resetEvidenceImagePasteFile();
      } else {
        this.setState({ loading: false });
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
      }
    } catch (error) {
      this.setState({ loading: false });
      // this.props.errorAlert(error.message);
    }
  };

  handleRemoveImage = async (url, i) => {
    try {
      this.setState({ loading: true });
      const image = await OrderService.removeImage(url);
      if (image.status === 200 && image.data.data) {
        this.setState({ loading: false });
        this.setState({ imageURLs: this.state.imageURLs.filter(el => el !== url) });
      } else {
        this.setState({ loading: false });
        Swal.fire({
          title: 'error',
          icon: 'error',
          text: 'terjadi kesalahan pada saat menghapus foto.'
        });
      }
    } catch (error) {
      this.setState({ loading: false });
      Swal.fire({
        title: 'error',
        icon: 'error',
        text: 'terjadi kesalahan pada saat menghapus foto. ' + error.message
      });
    }
  };

  handleImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    if (e.target.files.length > 0) {
      const formData = new FormData();
      for (const file of e.target.files) {
        formData.append('file', file);
      }

      try {
        this.setState({ loading: true });
        const image = await OrderService.uploadImage(formData);

        if (image.message === 'OK') {
          this.setState({
            imageURLs: [...this.state.imageURLs, image.data],
            loading: false
          });
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        this.setState({ loading: false });
        // this.props.errorAlert(error.message);
      }
    }
  };

  handleSubmit = () => {
    if (this.isValid()) {
      const { courier, shippingAddress, productVariants } = this.props;
      const {
        platform,
        order_source,
        invoice_number,
        discount,
        additional_cost,
        additional_cost_note,
        order_description,
        bonus,
        order_date,
        request_tax_invoice,
        npwp,
        shipping_type
      } = this.props;

      console.log({ order_description });

      // bonus dimasukkan ke order description
      if (order_description.trim()) {
        bonus.push(order_description.trim());
        bonus.filter(e => e);
      }

      const { payment_status_id, payment_date, bank_id } = this.props; // payment

      const payment_amount =
        this.props.payment_status_id === this.props.STATUS_PAID
          ? this.props.countGrandTotal()
          : this.props.payment_amount;

      const total_weight = this.props.countWeight() || 0;
      const subtotal = this.props.countSubtotal() || 0;
      const gross_amount = this.props.countGrossTotal() || 0;
      const tax = this.props.countPPN();
      const tax_rate = this.props.TAX_RATE;
      const grand_total = gross_amount + tax || 0;

      const shipping_address = {
        name: shippingAddress.name,
        email: shippingAddress.email,
        phone: shippingAddress.phone ? `+62${shippingAddress.phone}` : shippingAddress.phone,
        phone_alt: shippingAddress.phone_alt ? `+62${shippingAddress.phone_alt}` : shippingAddress.phone_alt,
        address: shippingAddress.address,
        subdistrict_id: shippingAddress.subdistrict_id,
        district_id: shippingAddress.district_id,
        province_id: shippingAddress.province_id,
        postal_code: shippingAddress.postal_code,
        customer_type_id: shippingAddress.customer_type_id
      };

      let details = [];

      for (let variant of productVariants) {
        let addons = [];

        for (let addon of variant.addons) {
          addons.push({
            addon_id: addon.id,
            name: addon.name,
            hpp: addon.hpp,
            price: addon.price
          });
        }

        details.push({
          images: variant.images,
          addons,
          product_id: variant.product_id,
          product_variant_id: variant.id,
          qty: Number(variant.qty),
          hpp: variant.hpp,
          price: variant.price,
          subtotal: this.props.countTotal(variant), // total(subtotal) per variant
          note: variant.note,
          year: variant.year,
          color_id: Number(variant.color_id),
          cover_type_id: variant.cover_type_id ? variant.cover_type_id : null,
          cover_list_id: variant.cover_list_id ? variant.cover_list_id : null,
          logo: variant.logo,
          transmission_type: variant.transmission_type
        });
      }

      const payment = {};

      if (parseInt(payment_status_id) === parseInt(Constants.PAYMENT_STATUS_UNPAID)) {
        payment.payment_status_id = payment_status_id;

        if (this.state.verification_type === 'manual') {
          payment.bank_id = this.state.channel;
        } else if (this.state.verification_type === 'auto') {
          payment.channel = this.state.channel;
        }
      } else {
        payment.evidences = this.state.imageURLs.map(url => ({
          image: url
        }));

        payment.payment_date = payment_date;
        payment.payment_amount = payment_amount;
        payment.payment_status_id = payment_status_id;
        payment.bank_id = ![Constants.PAYMENT_STATUS_COD_MP, Constants.PAYMENT_STATUS_MARKETPLACE].includes(
          Number(payment_status_id)
        )
          ? Number(bank_id) !== 0
            ? Number(bank_id)
            : null
          : null;
      }

      let orderData = {
        customer_id: shippingAddress.id,
        customer_type_id: shippingAddress.customer_type_id,
        // supplier_id: supplier.id,
        shipping_address,
        payment,
        verification_type: this.state.verification_type,
        platform,
        order_source,
        invoice_number,
        courier_code: courier.code === 'manual' ? courier.name.toLowerCase().replace(/ /g, '_') : courier.code, // kalo manual code sama dengan nama
        courier_name: courier.code === 'manual' ? CapitalizeFirstLetter(courier.name) : courier.name,
        service_code: courier.code === 'manual' ? CapitalizeFirstLetter(courier.service) : courier.service,
        service_name: courier.code === 'manual' ? CapitalizeFirstLetter(courier.service) : courier.description,
        shipping_cost: courier.cost,
        discount,
        additional_cost,
        additional_cost_note,
        total_weight,
        subtotal,
        gross_amount,
        tax,
        insurance_fee: this.props.countInsuranceFee(),
        shipping_type,
        tax_rate,
        grand_total,
        details,
        order_description: bonus.join(', '),
        order_date
      };

      if (request_tax_invoice) {
        orderData.request_tax_invoice = true;
        orderData.npwp = npwp;
      }

      this.setState({ loading: true });

      OrderService.save(orderData)
        .then(({ data }) => {
          this.setState({ loading: false });
          this.props.successAlert(data.order_number);
        })
        .catch(e => {
          if (e.response && e.response.data) {
            // this.props.errorAlert(e.response.data.message);
            console.log(e.response.data.message);
          } else {
            console.log(e.message);
          }
          this.setState({ loading: false });
        });
    }
  };

  handlePaymentStatus = () => {
    let options = this.props.paymentStatusOptions;
    const isMarketplace = [
      Constants.PLATFORM_LAZADA,
      Constants.PLATFORM_BLIBLI,
      Constants.PLATFORM_BUKALAPAK,
      Constants.PLATFORM_SHOPEE,
      Constants.PLATFORM_TOKOPEDIA
    ].includes(this.props.platform);

    if (isMarketplace) {
      options = options.filter(payment =>
        [Constants.PAYMENT_STATUS_COD_MP, Constants.PAYMENT_STATUS_MARKETPLACE].includes(payment.id)
      );
    } else {
      options = options.filter(payment => {
        const reguler = [Constants.PAYMENT_STATUS_DP, Constants.PAYMENT_STATUS_PAID, Constants.PAYMENT_STATUS_UNPAID];

        if (
          [Constants.CUSTOMER_RESELLER, Constants.CUSTOMER_BIG_SELLER].includes(
            parseInt(this.props.shippingAddress.customer_type_id)
          )
        ) {
          reguler.push(Constants.PAYMENT_STATUS_BILLING);
        }

        return reguler.includes(payment.id);
      });
    }

    this.setState({ payment_statuses: options });
  };

  handleChangePayment = (verification_type, channel) => {
    this.setState({
      verification_type,
      channel
    });
  };

  getPaymentChannels = async () => {
    const res = await BankService.getPaymentChannels();
    if (res.status === 200) {
      this.setState({ channels: res.data.data });
    }
  };
  getBanks = async () => {
    const res = await BankService.getLists();
    if (res.status === 200) {
      this.setState({ banks: res.data.data });
    }
  };

  render() {
    return (
      <>
        <Row>
          <Col lg="12">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0 text-danger">Pembayaran</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="paymentStatus">
                          Status Pembayaran
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          id="paymentStatus"
                          placeholder="Pilih Pembayaran"
                          type="select"
                          name="payment_status_id"
                          innerRef={this.props.paymentStatusRef}
                          value={this.props.payment_status_id}
                          onChange={this.props.handleChangePaymentStatus}
                        >
                          <option value="">--Pilih Status Pembayaran--</option>
                          {this.state.payment_statuses.map((status, key) => (
                            <option value={status.id} key={key}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {/* jika bukan COD / Marketplace -- 6 = COD/Marketplace -- 7 = Marketplace */}
                    {this.props.payment_status_id &&
                      ![
                        Constants.PAYMENT_STATUS_COD_MP,
                        Constants.PAYMENT_STATUS_MARKETPLACE,
                        Constants.PAYMENT_STATUS_BILLING
                      ].includes(parseInt(this.props.payment_status_id)) && (
                        <>
                          <PaymentDateComponent
                            // basic handler
                            order_date={this.props.order_date}
                            paymentDateRef={this.props.paymentDateRef}
                            payment_date={this.props.payment_date}
                            handleChange={this.props.handleChange}
                            // condition
                            payment_status_id={this.props.payment_status_id}
                            platform={this.props.platform}
                          />
                          <PaymentAmountComponent
                            paymentAmountRef={this.paymentAmountRef}
                            STATUS_PAID={this.props.STATUS_PAID}
                            countGrandTotal={this.props.countGrandTotal}
                            payment_amount={this.props.payment_amount}
                            handleChange={this.props.handleChange}
                            // condition
                            payment_status_id={this.props.payment_status_id}
                            platform={this.props.platform}
                          />
                          <PaymentOrderComponent
                            // ref
                            bankRef={this.props.bankRef}
                            paymentRef={this.paymentRef}
                            // handler
                            handleChange={this.props.handleChange}
                            handleChangePayment={this.handleChangePayment}
                            countGrandTotal={this.props.countGrandTotal}
                            // data
                            channels={this.state.channels}
                            payment_amount={this.props.payment_amount}
                            banks={this.state.banks}
                            STATUS_PAID={this.props.STATUS_PAID}
                            bank_id={this.props.bank_id}
                            payment_status_id={this.props.payment_status_id}
                            platform={this.props.platform}
                            customer={this.props.shippingAddress}
                          />
                        </>
                      )}
                  </Row>
                  {Number(this.props.bank_id) !== Constants.PEMBAYARAN_DEPOSIT &&
                    ![Constants.PAYMENT_STATUS_BILLING].includes(parseInt(this.props.payment_status_id)) && (
                      <Row>
                        <PaymentEvidenceComponent
                          evidenceImageRef={this.evidenceImageRef}
                          handleImage={this.handleImage}
                          payment_status_id={this.props.payment_status_id}
                          platform={this.props.platform}
                        />
                      </Row>
                    )}
                  <Row className="mb-4">
                    <Col xs="12">
                      {(this.state.imageURLs !== '' || this.state.imageURLs.length > 0) &&
                        this.state.imageURLs.map((image, i) => (
                          <div key={i}>
                            <img
                              key={i}
                              src={image}
                              alt="order evidence url"
                              width="180"
                              height="200"
                              style={{ marginRight: 5 }}
                            />
                            <Button size="sm" color="danger" onClick={() => this.handleRemoveImage(image, i)}>
                              <i className="fas fa-trash" />
                            </Button>
                          </div>
                        ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {this.state.loading ? (
                        <>
                          <Button disabled={this.state.loading} color="warning" size="md">
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            &nbsp; Loading...
                          </Button>
                        </>
                      ) : (
                        <Button color="primary" size="md" onClick={this.handleSubmit}>
                          Simpan
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default PaymentForm;
