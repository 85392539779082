import { request } from '../config';

class ComplaintService {
  static async getDatatableFaqByCategoryId(page, limit, sort, order, filter, faqCategoryId) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/faqs', {
      params: { page, limit, sort, order, filter, faq_category_id: faqCategoryId }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }

  static async uploadImage(image) {
    const response = await request.post(`/upload/order/complaint`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  }

  static async getActions() {
    const response = await request.get('/complaint/actions');
    return response;
  }

  static async getComplaintCategories() {
    const response = await request.get('/complaint/categories');
    return response;
  }

  static async getDivisions() {
    const response = await request.get('/complaint/divisions');
    return response;
  }

  static async getDatatableCategory(page, limit, sort, order, filter) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/faq-categories', {
      params: { page, limit, sort, order, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }

  /**
   * Get coverlist lists
   */
  static async listCategory() {
    const response = await request.get('/faq-categories');
    return response;
  }

  static async detailFaq(id) {
    const response = await request.get('/faqs/' + id);
    return response;
  }

  static async insertCategory(data) {
    const response = await request.post('/faq-categories', data);
    return response;
  }

  static async post(data) {
    const response = await request.post('/complaint', data);

    return response;
  }

  static async accept(data) {
    const response = await request.post('/complaint/accept', data);

    return response;
  }

  static async updateFaq(data, id) {
    const response = await request.put('/faqs/' + id, data);
    return response;
  }

  static async detailCategory(id) {
    const response = await request.get(`/faq-categories/${id}`);
    return response;
  }

  static async deleteFaq(id) {
    const response = await request.delete(`/faqs/${id}`);
    return response;
  }

  static async editCategory(id, data) {
    const response = await request.put(`/faq-categories/${id}`, data);
    return response;
  }

  static async removeCategory(id) {
    const response = await request.delete(`/faq-categories/${id}`);
    return response;
  }
}

export default ComplaintService;
