import { debounce } from '@fullcalendar/core';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import {
  BANK_MANDIRI,
  CUSTOMER_BIG_SELLER,
  CUSTOMER_RESELLER,
  PAYMENT_STATUS_PAID,
  PEMBAYARAN_DEPOSIT
} from '../../../../constants';
import CurrencyInput from '../../../../helpers/CurrencyInput';
import BankService from '../../../../services/BankService';
import OrderService from '../../../../services/OrderService';

function SetLunasModal(props) {
  const [banks, setBanks] = useState([]);
  const [bankId, setBankid] = useState(undefined);
  const [paymentDate, setPaymentDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState('');

  const paymentDateRef = useRef();
  const bankRef = useRef();
  const imageRef = useRef();

  useEffect(() => {
    getBanks();
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (props.isOpen) {
              if (blob.size / 1000000 <= 15) {
                handleImagePaste(blob);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
                });
              }
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }, [props.isOpen]);

  const handleImagePaste = debounce(async file => {
    const formData = new FormData();
    formData.append('file', file);

    if (file) {
      try {
        setLoading(true);
        const image = await OrderService.uploadImage(formData);

        if (image.message === 'OK') {
          setImageURL(image.data[0]);
          setLoading(false);
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, 2000);

  const getBanks = async () => {
    if (banks.length === 0 && props.row) {
      try {
        const banks = await BankService.getLists();
        if (banks.status === 200) {
          let data = banks.data.data;

          if ([CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER].includes(props.row.order.customer.customer_type_id)) {
            data.forEach(d => {
              if (d.id === PEMBAYARAN_DEPOSIT) {
                d.account_number = (props.row.order.customer.deposit.balance
                  ? props.row.order.customer.deposit.balance
                  : 0
                ).toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0
                });
              }
            });
          } else {
            data = data.filter(d => d.id !== PEMBAYARAN_DEPOSIT);
          }

          setBanks(data);
          setBankid(BANK_MANDIRI);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const setNotValid = dom => {
    dom.classList.add('is-invalid');
    dom.focus();
  };

  const isValid = () => {
    if (!paymentDate || paymentDate === '') {
      setNotValid(paymentDateRef.current);
      return false;
    } else if ((!imageURL || imageURL === '') && !isDepositAgen()) {
      setNotValid(imageRef.current);
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (isValid()) {
      const data = {
        payment: {
          bank_id: parseInt(bankId),
          payment_status_id: PAYMENT_STATUS_PAID,
          payment_date: paymentDate,
          payment_amount: props.row.order.grand_total - props.row.order.payment_amount,
          evidences: [
            {
              image: imageURL
            }
          ]
        }
      };

      try {
        const response = await OrderService.pay(props.row.order.id, data);
        if (response.status === 200) {
          Swal.fire({
            title: 'Order berhasil diset lunas',
            icon: 'success'
          }).then(() => {
            closeModal();
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const closeModal = () => {
    setBankid(undefined);
    setBanks([]);
    setImageURL('');
    setPaymentDate('');

    props.toggle(props.row, 'setLunasModal');
  };

  const handleUploadImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    if (e.target.files.length > 0) {
      try {
        setLoading(true);
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append('file', file);

        const image = await OrderService.uploadImage(formData);

        if (image.message === 'OK') {
          setImageURL(image.data[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const isDepositAgen = (paymentId = bankId) => {
    return (
      [CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER].includes(props.row.order.customer.customer_type_id) &&
      Number(paymentId) === PEMBAYARAN_DEPOSIT
    );
  };

  const isOptionDisabled = id => {
    if (id === PEMBAYARAN_DEPOSIT) {
      const cost = props.row && props.row.order.grand_total - props.row.order.payment_amount;

      if (props.row.order.customer.deposit.balance < cost) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {props.isOpen && props.row && (
        <Modal isOpen={props.isOpen} toggle={closeModal} centered style={{ minWidth: '70%' }}>
          <ModalHeader toggle={closeModal} charCode="x">
            Pelunasan Order {props.row.order.id}
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row className="mt-4">
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="payment_date">
                          Tanggal Bayar
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          id="paid_date"
                          type="date"
                          name="paid_date"
                          min={
                            new Date(
                              new Date(props.row.order.created_at).setHours(
                                new Date(props.row.order.created_at).getHours() + 7
                              )
                            )
                              .toISOString()
                              .split('T')[0]
                          }
                          max={new Date().toISOString().split('T')[0]}
                          value={paymentDate}
                          innerRef={paymentDateRef}
                          onKeyDown={e => e.preventDefault()}
                          onWheel={e => e.preventDefault()}
                          onChange={e => {
                            console.log();
                            e.persist();
                            e.target.classList.remove('is-invalid');
                            // this.handleChange('paid_date', e.target.value);
                            setPaymentDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="paymentAmount">
                          Nominal Bayar
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <CurrencyInput
                          type="text"
                          className="form-control"
                          name="payment_amount"
                          // ref={this.ongkirCostRef}
                          disabled={true}
                          // value={this.state.courier.cost.toString()}
                          // style={{ minWidth: '125px' }}
                          // onChange={e => {
                          //   // remove class invalid
                          //   e.target.classList.remove('is-invalid');
                          //   this.handleChange('cost', e.target.value);
                          // }}
                          value={props.row && props.row.order.grand_total - props.row.order.payment_amount}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="bank_id">
                          Metode Pembayaran
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          id="bank_id"
                          placeholder="Pilih Bank"
                          type="select"
                          name="bank_id"
                          innerRef={bankRef}
                          value={bankId}
                          onChange={e => {
                            e.target.classList.remove('is-invalid');
                            setBankid(e.target.value);
                          }}
                        >
                          {banks.map((bank, key) => (
                            <option value={bank.id} key={key} disabled={isOptionDisabled(bank.id)}>
                              {`${bank.provider.name} - ${bank.account_number} - ${bank.account_name}`}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    {!isDepositAgen(bankId) && (
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="customFileLang">
                            Bukti Transfer
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <div className="custom-file">
                            <input
                              className="custom-file-input"
                              type="file"
                              accept="image/*"
                              ref={imageRef}
                              id="customFileLang"
                              lang="en"
                              onChange={handleUploadImage}
                            />
                            <label className="custom-file-label" htmlFor="customFileLang">
                              Pilih file
                            </label>
                          </div>
                          <div>
                            {imageURL !== '' && (
                              <img
                                src={imageURL}
                                alt="order evidence url"
                                width="200"
                                height="200"
                                style={{ paddingTop: 5 }}
                              />
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button disabled={loading} onClick={handleSubmit} color="primary">
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Simpan'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default SetLunasModal;
