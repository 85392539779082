import axios from 'axios';
import { API_URL } from '../config';
import {
  AUTH_ERROR,
  CLEAR_ERRORS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  USER_LOADED,
  USER_LOADING
} from './types';

import Swal from 'sweetalert2';
import UserService from '../services/UserService';
import { returnErrors } from './errorActions';

// check token and load user
export const loadUser = () => dispatch => {
  // User Loading
  dispatch({ type: USER_LOADING });

  UserService.getProfile()
    .then(res => {
      dispatch({ type: USER_LOADED, payload: res.data });
    })
    .catch(err => {
      // get error message
      dispatch(returnErrors(err.response.data, err.response.status));
      //remove token
      dispatch({ type: AUTH_ERROR });
    });
};

// Login User
export const login = (email, password) => (dispatch, getState) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ email, password });

  axios
    .post(`${API_URL}/login`, body, config)
    .then(res => {
      console.log(res.data);
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch(err => {
      console.log('LOGIN', err.message);
      Swal.fire({
        title: 'Terjadi kesalahan',
        text: err.response ? err.response.data.message : err.message
      });
      dispatch(
        returnErrors(
          err.response ? err.response.data.message : err.message,
          err.response ? err.response.status : 400,
          'LOGIN_FAIL'
        )
      );
      dispatch({ type: LOGIN_FAIL });
    });
};
// Logout User
export const logout = () => (dispatch, getState) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  let refresh_token = localStorage.getItem('refreshToken');
  if (!refresh_token) {
    return dispatch({ type: LOGOUT_SUCCESS });
  }

  const body = JSON.stringify({ refresh_token });

  axios
    .post(`${API_URL}/logout`, body, config)
    .then(res => {
      dispatch({ type: LOGOUT_SUCCESS });
    })
    .catch(err => {
      dispatch(
        returnErrors(
          err.response ? err.response.data.message : err.message,
          err.response ? err.response.status : 400,
          'AUTH_ERROR'
        )
      );
      dispatch({ type: AUTH_ERROR });
    });
};

export const tokenConfig = getState => {
  const token = getState().auth.token;
  //Headers
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // If Token, add to Headers
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};
