import { request } from '../config';

class AffiliateService {
  static async getDatatable(page, limit, sort, order, filter) {
    const response = await request.get('/affiliates', {
      params: { page, limit, sort, order, filter }
    });

    return response.data;
  }

  static async getSummary() {
    const response = await request.get('/affiliates/summary');

    return response.data;
  }

  static async getDataTableSelling(page, limit, sort, order, filter) {
    const response = await request.get('/affiliates/sales', {
      params: { page, limit, sort, order, filter }
    });

    return response;
  }

  static async getDataHistoryPayout(page, limit, sort, order, filter) {
    const response = await request.get('/affiliates/payout/history', {
      params: { page, limit, sort, order, filter }
    });

    return response;
  }

  static async requestPayout({ start_date, end_date }) {
    const { data: response } = await request.post(`/affiliates/payout`, { start_date, end_date });
    return response;
  }

  static async updateAccount(data) {
    const { data: response } = await request.put(`/affiliates/account`, data);
    return response;
  }

  static async getListProductsAffiliate() {
    const { data: response } = await request.get(`/affiliates/products`);
    return response;
  }
  static async getListBankAffiliate() {
    const { data: response } = await request.get(`/affiliates/bank`);
    return response;
  }

  static async detail(id) {
    const { data: response } = await request.get(`/affiliates/${id}`);
    return response;
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/affiliates/${id}`);
    return response;
  }

  static async verifyAccount(id, data) {
    const { data: response } = await request.put(`/affiliates/verify/${id}`, data);
    return response;
  }

  static async changeStatus(id) {
    const { data: response } = await request.put(`/affiliates/toggle/${id}`);
    return response;
  }

  static async getDataChart() {
    const { data } = await request.get(`/affiliates/tracking`);
    return data;
  }

  // PRODUCT UNTUK DI UPDATE
  static async getListProductsAffiliateUpdate() {
    const { data: response } = await request.get(`/affiliates/products/list`);
    return response;
  }

  static async detailProductsUpdate(id) {
    const { data: response } = await request.get(`/affiliates//products/${id}`);
    return response;
  }

  static async updateProductsAffiliate(id) {
    const { data: response } = await request.put(`/affiliates/products/${id}`);
    return response;
  }

  // AFFILIATE OVERVIEW
  static async affiliateOverview() {
    const { data: response } = await request.get(`/affiliates/overview`);
    return response;
  }

  static async getDataLaporanAffiliate(filter, start_date, end_date, page, limit) {
    const response = await request.get('/affiliates/report', {
      params: { filter, start_date, end_date, page, limit }
    });

    return response;
  }
}

export default AffiliateService;
