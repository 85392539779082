// import Login from './views/pages/auth/Login';
// import Dashboard from './views/pages/dashboards';
// import TableCategory from './views/pages/category/TableCategory';
// import AddCategory from './views/pages/category/AddCategory';
// import EditCategory from './views/pages/category/EditCategory';
// import AddColor from './views/pages/color/AddColor';
// import EditColor from './views/pages/color/EditColor';
// import TableColor from './views/pages/color/TableColor';
// import TableManufacturer from './views/pages/manufacturer/TableManufacturer';
// import AddManufacturer from './views/pages/manufacturer/AddManufacturer';
// import EditManufacturer from './views/pages/manufacturer/EditManufacturer';
// import AddTypeCar from './views/pages/typecar/AddTypeCar';
// import EditTypeCar from './views/pages/typecar/EditTypeCar';
// import TableTypeCar from './views/pages/typecar/TableTypeCar';
// import AddMaterial from './views/pages/material/AddMaterial';
// import EditMaterial from './views/pages/material/EditMaterial';
// import TableMaterial from './views/pages/material/TableMaterial';
// import AddProduct from './views/pages/product/AddProduct';
// import EditProduct from './views/pages/product/EditProduct';
// import TableProduct from './views/pages/product/TableProduct';
// import Test from './views/pages/Test';
// import FormOrder from './views/pages/order/FormOrder';
// import TableOrder from './views/pages/order/TableOrder';
// import AddUser from './views/pages/user/AddUser';
// import EditUser from './views/pages/user/EditUser';
// import TableUser from './views/pages/user/TableUser';
// import TableAddon from './views/pages/addon/TableAddon';
// import AddAddon from './views/pages/addon/AddAddon';
// import EditAddon from './views/pages/addon/EditAddon';
// import InvoiceOrder from './views/pages/order/InvoiceOrder';
// import EditOrder from './views/pages/order/EditOrder';
// import TableCustomer from './views/pages/customers/TableCustomer';
// import OrderDetailCustomer from './views/pages/customers/OrderDetailCustomer';
// import Profile from './views/pages/auth/Profile';
// import ProductionHistory from './views/pages/production_history/ProductionHistory';
// import ScanOrder from './views/pages/order/ScanOrder';
// import ReportFinance from './views/pages/reports/ReportFinance';
// import Report from './views/pages/reports';
// import TableBank from './views/pages/bank/TableBank';
// import AddBank from './views/pages/bank/AddBank';
// import EditBank from './views/pages/bank/EditBank';
// import TableRole from './views/pages/role/TableRole';
// import EditPermission from './views/pages/permission/EditPermission';
// import TableVoucher from './views/pages/voucher/TableVoucher';
// import AddVoucher from './views/pages/voucher/AddVoucher';
// import EditVoucher from './views/pages/voucher/EditVoucher';
// import TablePricelist from './views/pages/pricelist/TablePricelist';
// import EditPricelist from './views/pages/pricelist/EditPricelist';
// import TableEmagazine from './views/pages/emagazine/TableEmagazine';
// import EditEmagazine from './views/pages/emagazine/EditEmagazine';
// import AddEmagazine from './views/pages/emagazine/AddEmagazine';

let routes = [];

// routes = [
//   { path: '/', name: 'Test', component: Test, layout: '/test', invisible: true },
//   { layout: '/auth', path: '/login', name: 'Login', component: Login, invisible: true },
//   {
//     layout: '/global',
//     path: '/profile',
//     icon: 'ni ni-shop text-primary',
//     name: 'Profile',
//     component: Profile,
//     invisible: true
//   },
//   { layout: '/global', path: '/dashboard', icon: 'ni ni-shop text-primary', name: 'Dashboard', component: Dashboard }
// ];

// const reports = [
//   {
//     collapse: true,
//     layout: '/global',
//     name: 'Laporan',
//     icon: 'fas fa-chart-line text-primary',
//     state: 'ReportCollapse',
//     views: [
//       {
//         layout: '/global',
//         path: '/reports/finance',
//         name: 'Keuangan',
//         component: ReportFinance,
//         icon: 'fas fa-money-bill text-primary',
//         invisible: false
//       },
//       {
//         layout: '/global',
//         path: '/reports/cover',
//         name: 'Kategori Cover',
//         component: Report,
//         icon: 'fas fa-list text-primary',
//         invisible: false
//       },
//       {
//         layout: '/global',
//         path: '/reports/product',
//         name: 'Produk',
//         component: Report,
//         icon: 'fas fa-car text-primary',
//         invisible: false
//       },
//       {
//         layout: '/global',
//         path: '/reports/color',
//         name: 'Warna',
//         component: Report,
//         icon: 'fas fa-adjust text-primary',
//         invisible: false
//       },
//       {
//         layout: '/global',
//         path: '/reports/cities',
//         name: 'Kota Customer',
//         component: Report,
//         icon: 'fas fa-building text-primary',
//         invisible: false
//       },
//       {
//         layout: '/global',
//         path: '/reports/order-source',
//         name: 'Sumber Order',
//         component: Report,
//         icon: 'fas fa-arrow-down text-primary',
//         invisible: false
//       }
//     ]
//   }
// ];

// const master = [
//   {
//     layout: '/global',
//     path: '/role-permissions/:id',
//     name: 'Manage Permissions',
//     component: EditPermission,
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/users/add',
//     name: 'Tambah Pengguna',
//     component: AddUser,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   { layout: '/global', path: '/users/edit/:id', name: 'Ubah Pengguna', component: EditUser, invisible: true },
//   {
//     layout: '/global',
//     path: '/categories/add',
//     name: 'Tambah Kategori',
//     component: AddCategory,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   { layout: '/global', path: '/categories/edit/:id', name: 'Ubah Kategori', component: EditCategory, invisible: true },
//   {
//     layout: '/global',
//     path: '/colors/add',
//     name: 'Tambah Warna',
//     component: AddColor,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   { layout: '/global', path: '/colors/edit/:id', name: 'Ubah Warna', component: EditColor, invisible: true },
//   {
//     layout: '/global',
//     path: '/materials/add',
//     name: 'Tambah Bahan',
//     component: AddMaterial,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   { layout: '/global', path: '/materials/edit/:id', name: 'Ubah Bahan', component: EditMaterial, invisible: true },
//   {
//     layout: '/global',
//     path: '/banks/add',
//     name: 'Tambah Bank',
//     component: AddBank,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   { layout: '/global', path: '/banks/edit/:id', name: 'Ubah Bahan', component: EditBank, invisible: true },
//   {
//     layout: '/global',
//     path: '/manufacturers/add',
//     name: 'Tambah Pabrikan',
//     component: AddManufacturer,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/manufacturers/edit/:id',
//     name: 'Ubah Pabrikan',
//     component: EditManufacturer,
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/typecars/add',
//     name: 'Tambah Jenis Kendaraan',
//     component: AddTypeCar,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/typecars/edit/:id',
//     name: 'Ubah Jenis Kendaraan',
//     component: EditTypeCar,
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/products/add',
//     name: 'Tambah Produk',
//     component: AddProduct,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/pricelists/edit/:id',
//     name: 'Edit Pricelist',
//     component: EditPricelist,
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/vouchers/edit/:id',
//     name: 'Edit Voucher',
//     component: EditVoucher,
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/emagazine/edit/:id',
//     name: 'Edit Emagazine',
//     component: EditEmagazine,
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/vouchers/add',
//     name: 'Tambah Voucher',
//     component: AddVoucher,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   {
//     layout: '/global',
//     path: '/emagazine/add',
//     name: 'Tambah Emagazine',
//     component: AddEmagazine,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   { layout: '/global', path: '/products/edit/:id', name: 'Ubah Produk', component: EditProduct, invisible: true },
//   {
//     layout: '/global',
//     path: '/addons/add',
//     name: 'Tambah Addon',
//     component: AddAddon,
//     icon: 'fas fa-plus text-default',
//     invisible: true
//   },
//   { layout: '/global', path: '/addons/edit/:id', name: 'Ubah Addon', component: EditAddon, invisible: true },
//   // Master Data
//   {
//     collapse: true,
//     layout: '/admin',
//     name: 'Master Data',
//     icon: 'fas fa-database text-primary',
//     state: 'MasterCollapse',
//     views: [
//       // Pengguna
//       {
//         name: 'Pengguna',
//         icon: 'fas fa-user text-primary',
//         layout: '/global',
//         path: '/users',
//         exact: true,
//         component: TableUser
//       },
//       {
//         name: 'Role',
//         icon: 'fas fa-user text-primary',
//         layout: '/global',
//         path: '/roles',
//         exact: true,
//         component: TableRole
//       },
//       // Kategori
//       {
//         name: 'Kategori',
//         icon: 'fas fa-list text-primary',
//         layout: '/global',
//         path: '/categories',
//         exact: true,
//         component: TableCategory
//       },
//       // Warna
//       {
//         name: 'Warna',
//         icon: 'fas fa-adjust text-primary',
//         layout: '/global',
//         path: '/colors',
//         exact: true,
//         component: TableColor
//       },
//       // Bahan
//       {
//         name: 'Bahan',
//         icon: 'fas fa-layer-group text-primary',
//         layout: '/global',
//         path: '/materials',
//         exact: true,
//         component: TableMaterial
//       },
//       // Bank
//       {
//         name: 'Bank',
//         icon: 'fas fa-money-bill text-primary',
//         layout: '/global',
//         path: '/banks',
//         exact: true,
//         component: TableBank
//       },
//       {
//         name: 'Voucher',
//         icon: 'fas fa-sticky-note-o  text-primary',
//         layout: '/global',
//         path: '/vouchers',
//         exact: true,
//         component: TableVoucher
//       },
//       {
//         name: 'E-Magazine',
//         icon: 'fas fa-newspaper text-primary',
//         layout: '/global',
//         path: '/emagazine',
//         exact: true,
//         component: TableEmagazine
//       },
//       {
//         name: 'Pricelist',
//         icon: 'fas fa-usd  text-primary',
//         layout: '/global',
//         path: '/pricelists',
//         exact: true,
//         component: TablePricelist
//       },
//       // Pabrikan
//       {
//         name: 'Pabrikan',
//         icon: 'fas fa-industry text-primary',
//         layout: '/global',
//         path: '/manufacturers',
//         exact: true,
//         component: TableManufacturer
//       },
//       // Jenis Kendaraan
//       {
//         name: 'Jenis Kendaraan',
//         icon: 'fas fa-truck text-primary',
//         layout: '/global',
//         path: '/typecars',
//         exact: true,
//         component: TableTypeCar
//       },
//       // Produk
//       {
//         name: 'Produk',
//         icon: 'fas fa-car text-primary',
//         layout: '/global',
//         path: '/products',
//         exact: true,
//         component: TableProduct
//       },
//       // Produk
//       {
//         name: 'Addon',
//         icon: 'fas fa-motorcycle text-primary',
//         layout: '/global',
//         path: '/addons',
//         exact: true,
//         component: TableAddon
//       }
//     ]
//   }
// ];

// // routes.push(...reviews);
// routes.push(...master);
// routes.push(...reports);

// routes.push({
//   name: 'Order',
//   icon: 'fas fa-list text-primary',
//   layout: '/global',
//   path: '/orders',
//   exact: true,
//   component: TableOrder,
//   invisible: false
// });

// routes.push(
//   {
//     name: 'Form Order',
//     icon: 'fas fa-cart-plus text-primary',
//     layout: '/global',
//     path: '/orders/add',
//     exact: true,
//     component: FormOrder
//   },
//   {
//     name: 'Edit Order',
//     icon: 'fas fa-user-edit text-primary',
//     layout: '/global',
//     path: '/orders/edit/:id',
//     exact: true,
//     component: EditOrder,
//     invisible: true
//   },
//   {
//     name: 'Invoice',
//     icon: 'fas fa-file-invoice text-primary',
//     layout: '/global',
//     path: '/invoice/:order_number',
//     component: InvoiceOrder,
//     invisible: true
//   },
//   {
//     name: 'Customer',
//     icon: 'fas fa-users text-primary',
//     layout: '/global',
//     path: '/customers',
//     exact: true,
//     component: TableCustomer,
//     invisible: false
//   },
//   {
//     name: 'Riwayat Produksi',
//     icon: 'fas fa-file-invoice text-primary',
//     layout: '/global',
//     path: '/production-history',
//     component: ProductionHistory,
//     invisible: false
//   },
//   {
//     name: 'Scan',
//     layout: '/global',
//     icon: 'fas fa-camera text-primary',
//     path: '/scan',
//     component: ScanOrder,
//     invisible: false
//   },
//   {
//     name: 'Customer Detail Order',
//     icon: 'fas fa-users text-primary',
//     layout: '/global',
//     path: '/customers/orders/:id',
//     component: OrderDetailCustomer,
//     invisible: true
//   }
// );

export default routes;
