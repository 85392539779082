import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  Form,
  FormGroup,
  Label
} from 'reactstrap';
import Swal from 'sweetalert2';
import PayoutService from '../../../../services/PayoutService';
import DetailPayoutTable from './DetailPayoutTable';
// import CurrencyInput from '../../../../helpers/CurrencyInput';
import debounce from '../../../../helpers/debounce';

const defaultPayout = {
  id: null,
  total: 0,
  user_id: null,
  status: '',
  payment_date: '',
  tax_evidence: null,
  payment_evidence: '',
  description: '',
  start_date: Date(),
  end_date: Date(),
  created_at: Date(),
  details: [
    {
      affiliate_order: {
        created_at: '',
        commission: 0
      },
      item: {
        product_name: '',
        category: {
          name: ''
        },
        order: {
          order_number: ''
        }
      }
    }
  ],
  user: {
    name: '',
    affiliate: {
      bank_name: '',
      account_number: '',
      account_name: '',
      npwp: ''
    }
  }
};

function PayoutVerificationModal(props) {
  const [loading, setLoading] = useState(false);
  const [payout, setPayout] = useState(defaultPayout);
  const [payment_date, setPaymentDate] = useState('');
  const [evidence, setEvidence] = useState('');

  const paymentDateRef = useRef();
  const evidenceRef = useRef();

  useEffect(() => {
    if (props.row) {
      getPayoutDetail(props.row.id);
    }
  }, [props.row]);

  const getPayoutDetail = async id => {
    try {
      const res = await PayoutService.getDetailPayout(id);
      if (res.status === 200) {
        setPayout(res.data.data);
      }
    } catch (error) {
      console.log();
    }
  };

  const toggle = () => {
    setLoading(false);
    setPayout(defaultPayout);
    props.toggle('payoutVerificationModalOpen');
  };

  useEffect(() => {
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (props.isOpen) {
              if (blob.size / 1000000 <= 15) {
                handleImagePaste(blob);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
                });
              }
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }, [props.isOpen]);

  const handleImagePaste = debounce(async file => {
    const formData = new FormData();
    formData.append('file', file);

    if (file) {
      try {
        setLoading(true);
        const image = await PayoutService.uploadPaymentEvidence(formData);

        if (image.status === 200) {
          setEvidence(image.data.data[0]);
          setLoading(false);
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, 2000);

  // const handleClose = () => {
  //   setLoading(false);
  //   setPaymentDate('');
  //   setEvidence('');
  //   props.toggle('payoutVerificationModalOpen');
  // };

  const handleImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    const formData = new FormData();
    for (const file of e.target.files) {
      formData.append('file', file);
    }

    try {
      setLoading(true);
      const res = await PayoutService.uploadPaymentEvidence(formData);

      if (res.status === 200) {
        setEvidence(res.data.data[0]);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error('Terjadi kesalahan oada saat upload bukti transfer.');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formatDate = date => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const handleChange = e => {
    e.target.classList.remove('is-invalid');
    const date = formatDate(e.target.value);

    setPaymentDate(date);
  };

  const isValid = () => {
    const invalidRef = (ref, apa) => {
      ref.classList.add('is-invalid');
      ref.focus();
    };

    if (!payment_date || payment_date === '') {
      invalidRef(paymentDateRef.current, 'tanggal');
      return false;
    } else if (!evidence || evidence === '') {
      invalidRef(evidenceRef.current, 'gambar');
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);
    if (isValid()) {
      try {
        const res = await PayoutService.verifyPayout(props.row.id, {
          payment_date,
          payment_evidence: evidence
        });

        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Payout Berhasil',
            timer: 5000
          }).then(() => toggle());

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const payment =
    props.row && props.row.status === 'paid' ? (
      <div style={{ textAlign: 'center' }}>
        <Label>Bukti Transfer</Label>
        <br />
        <img
          onClick={() => window.open(props.row.payment_evidence, '_blank')}
          alt={props.row.description}
          style={{ maxWidth: '100%' }}
          src={props.row.payment_evidence}
        />
      </div>
    ) : (
      <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
        <Col md={12}>
          <FormGroup>
            <Label>Tanggal Transfer</Label>
            <Input
              id="payment_date"
              type="date"
              name="payment_date"
              max={new Date().toISOString().split('T')[0]}
              innerRef={paymentDateRef}
              onKeyDown={e => e.preventDefault()}
              value={payment_date}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label className="form-control-label" htmlFor="customFileLang">
              Bukti Transfer
              <sup>
                <span className="text-red">*</span>
              </sup>
            </Label>
            <div className="custom-file">
              <Input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                innerRef={evidenceRef}
                type="file"
                accept="image/*"
                onChange={handleImage}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                Pilih file
              </label>
            </div>
          </FormGroup>
        </Col>
        <Col md={12}>
          {evidence !== '' && evidence && (
            <img src={evidence} alt="order evidence url" width="200" style={{ marginRight: 5 }} />
          )}
        </Col>
      </Form>
    );

  return props.row ? (
    <>
      <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
        <ModalHeader>Transfer Payout {payout.id}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <DetailPayoutTable payout={payout} />
            </Col>
          </Row>
          <Row>{payment}</Row>
        </ModalBody>
        <ModalFooter>
          {props.row && props.row.status !== 'paid' && (
            <Button disabled={loading} color="primary" onClick={handleSubmit}>
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Simpan'
              )}
            </Button>
          )}
          <Button disabled={loading} onClick={toggle}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    ''
  );
}

export default PayoutVerificationModal;
