import { request } from '../config';

class FaqService {
  static async getDatatableFaqByCategoryId(page, limit, sort, order, filter, faqCategoryId) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/faqs', {
      params: { page, limit, sort, order, filter, faq_category_id: faqCategoryId }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }

  static async getDatatableCategory(page, limit, sort, order, filter) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/faq-categories', {
      params: { page, limit, sort, order, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }

  /**
   * Get coverlist lists
   */
  static async listCategory() {
    const response = await request.get('/faq-categories');
    return response;
  }

  static async detailFaq(id) {
    const response = await request.get('/faqs/' + id);
    return response;
  }

  static async insertCategory(data) {
    const response = await request.post('/faq-categories', data);
    return response;
  }

  static async insertFaq(data) {
    const response = await request.post('/faqs', data);

    return response;
  }

  static async updateFaq(data, id) {
    const response = await request.put('/faqs/' + id, data);
    return response;
  }

  static async detailCategory(id) {
    const response = await request.get(`/faq-categories/${id}`);
    return response;
  }

  static async deleteFaq(id) {
    const response = await request.delete(`/faqs/${id}`);
    return response;
  }

  static async editCategory(id, data) {
    const response = await request.put(`/faq-categories/${id}`, data);
    return response;
  }

  static async removeCategory(id) {
    const response = await request.delete(`/faq-categories/${id}`);
    return response;
  }
}

export default FaqService;
