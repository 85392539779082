import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import ShippingManagementService from '../../../../services/ShippingManagementService';
import './CheckShipping.css';

const CheckShipping = () => {
  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [weight, setWeight] = useState('');
  const [loading, setLoading] = useState(false);
  const [destination, setDestination] = useState('');

  const defaultValueOriginCity = {
    value: '543',
    label: 'Kasihan, Kab Bantul, DI Yogyakarta'
  };

  const tabData = [
    {
      id: 1,
      title: 'Ekonomi',
      content: data.economy || []
    },
    {
      id: 2,
      title: 'Reguler',
      content: data.regular || []
    },
    {
      id: 3,
      title: 'Next Day',
      content: data.next_day || []
    },
    {
      id: 4,
      title: 'Lainnya',
      content: data.others || []
    }
  ];

  const loadDestinationOptions = async inputValue => {
    if (inputValue.length >= 3) {
      const response = await ShippingManagementService.searchDestination({
        filter: inputValue
      });
      let options = [];
      for (let row of response.data) {
        options.push({ value: row.id, label: row.name });
      }
      return options;
    }
  };

  const handleDestination = ({ value }) => {
    setDestination(value);
  };

  const handleWeight = e => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setWeight(e.target.value);
    }
  };

  const isValid = () => {
    if (destination === '') {
      return false;
    }
    if (weight === '' || Number(weight) === 0) {
      return false;
    }
    return true;
  };

  const handleCheckShipping = async () => {
    setLoading(true);
    const data = {
      destination: parseInt(destination),
      weight: parseInt(weight)
    };

    try {
      const response = await ShippingManagementService.checkShipping(data);
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      for (let group of tabData) {
        if (group.content.length > 0) {
          handleTabs(group.content, group.id - 1);
          return;
        }
      }
    }
  }, [data]);

  const handleTabs = (content, index) => {
    setActiveTab(index);
    setItems(content.sort((a, b) => a.cost - b.cost));
  };

  return (
    <>
      {loading && <div className="loader"></div>}
      <SimpleHeader name="Cek Ongkir" parentName="Shipping" />

      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cek Ongkos Kirim</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <AsyncSelect
                        isDisabled={true}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            minHeight: '47px'
                          })
                        }}
                        id="origin_city"
                        name="origin_city"
                        placeholder="Kota asal pengiriman"
                        defaultValue={defaultValueOriginCity}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <AsyncSelect
                        id="destination_city"
                        type="text"
                        name="destination_city"
                        placeholder="Kecamatan tujuan pengiriman"
                        loadOptions={loadDestinationOptions}
                        onChange={handleDestination}
                      />
                      <div className="invalid-feedback" style={{ display: destination === '' ? 'block' : 'none' }}>
                        Tujuan wajib diisi
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <InputGroup>
                        <Input
                          id="shipment_weight"
                          type="text"
                          name="shipment_weight"
                          placeholder="Berat kiriman"
                          value={weight}
                          onChange={handleWeight}
                          suffix="gr"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>gr</InputGroupText>
                        </InputGroupAddon>
                        <div
                          className="invalid-feedback"
                          style={{ display: weight === '' || Number(weight) === 0 ? 'block' : 'none' }}
                        >
                          Berat wajib diisi &gt; 0
                        </div>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <div className="text-right">
                      <Button
                        className="w-75"
                        color="danger"
                        disabled={!isValid()}
                        onClick={() => handleCheckShipping()}
                      >
                        Cari
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <div className="col">
            {Object.keys(data).length > 0 && (
              <>
                <Card>
                  <CardHeader className="border-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3 className="mb-0">Hasil Pencarian</h3>

                      <Row className="text-right">
                        {tabData.map((tab, index) => (
                          <Button
                            className={tab.content.length === 0 ? `d-none` : `d-block`}
                            color={activeTab === index ? 'danger' : 'secondary'}
                            key={tab.id}
                            onClick={() => handleTabs(tab.content, index)}
                          >
                            {tab.title}
                          </Button>
                        ))}
                      </Row>
                    </div>
                  </CardHeader>

                  {items.length > 0 && (
                    <CardBody>
                      <Row className="text-center">
                        <Col md="3">
                          <h4>Kurir</h4>
                        </Col>
                        <Col md="3">
                          <h4>Layanan</h4>
                        </Col>
                        <Col md="3">
                          <h4>Estimasi</h4>
                        </Col>
                        <Col md="3">
                          <h4>Tarif</h4>
                        </Col>
                      </Row>

                      <hr
                        style={{
                          border: '1px solid #f5365c',
                          margin: '5px 0'
                        }}
                      />

                      {items.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            width: '100%',
                            padding: '1.3rem',
                            borderRadius: '0.375rem',
                            backgroundColor: '#d9d9d9',
                            marginBottom: '5px'
                          }}
                        >
                          <Row className="text-center">
                            <Col md="3" className="flex-center">
                              <img height={35} src={item.logo} alt="logo" />
                            </Col>
                            <Col md="3" className="flex-center">
                              <h3> {item.service_name}</h3>
                            </Col>
                            <Col md="3" className="flex-center">
                              <h3>
                                {item.etd.toLowerCase().replace('hari', '')} {item.etd ? 'Hari' : '-'}
                              </h3>
                            </Col>
                            <Col md="3" className="flex-center">
                              <h3 className="text-red text-bold">
                                {/* Rp {new Intl.NumberFormat('id-ID').format(item.cost)} */}
                                {item.cost.toLocaleString('id', {
                                  style: 'currency',
                                  currency: 'IDR',
                                  minimumFractionDigits: 0
                                })}
                              </h3>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </CardBody>
                  )}
                </Card>
              </>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CheckShipping;
