import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import AffiliateNavbar from '../../../components/Navbars/AffiliateNavbar'
import AffiliateLogo from "../../../assets/img/affiliate.png"
import AuthFooter from '../../../components/Footers/AuthFooter'

const LandingPage = (props) => {
    return (
        <>
            <AffiliateNavbar title="Coversuper Partner Programs" name="Masuk Sekarang" />
            <Container>
                <Row>
                    <div style={{ marginLeft: '-150px' }}>
                        <Col md="3">
                            <img src={AffiliateLogo} alt="cover" width="750" height="660" />
                        </Col>
                    </div>

                    <Col md="4">
                        <div style={{ marginTop: '200px', marginLeft: '100px', fontFamily: "Montserrat" }}>
                            <h4 style={{ fontSize: '25px', width: '324px' }}>CoverSuper Partner</h4>
                            <p style={{ width: '492px', height: '275px', fontSize: '15px', fontFamily: "Lato" }}>
                                Dapatkan komisi dengan bergabung ke CoverSuper Partnership Program.
                                <br /><br />
                                CoverSuper Partnership Program memberikan komisi untuk setiap pembelian dari link-mu atau menggunakan kode referral yang memenuhi syarat.
                                Bagikan Produk yang kamu inginkan untuk dapatkan keuntungannya!
                                <br /><br />
                                Daftarkan dirimu ke CoverSuper Partnership Program sekarang!
                                Jika belum memiliki akun Partnership CoverSuper, kamu dapat mendaftarkan akun terlebih dahulu
                            </p>
                            <Button style={{
                                backgroundColor: "#cf1517",
                                color: 'white',
                                marginTop: '-50px'
                            }}
                                href='/register'
                            >
                                Daftar sekarang
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            <AuthFooter />
        </>
    )
}

export default LandingPage
