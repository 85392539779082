import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  Form,
  FormGroup,
  Label
} from 'reactstrap';
import Swal from 'sweetalert2';
import PayoutService from '../../../../services/PayoutService';
import debounce from '../../../../helpers/debounce';

const TaxEvidenceModal = props => {
  const [loading, setLoading] = useState(false);
  const [evidence, setEvidence] = useState('');

  const evidenceRef = useRef();

  const toggle = () => {
    setLoading(false);
    props.toggle('taxVerificationModalOpen');
  };

  useEffect(() => {
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (props.isOpen) {
              if (blob.size / 1000000 <= 15) {
                handleImagePaste(blob);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
                });
              }
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }, [props.isOpen]);

  const handleImagePaste = debounce(async file => {
    const formData = new FormData();
    formData.append('file', file);

    if (file) {
      try {
        setLoading(true);
        const image = await PayoutService.uploadTaxEvidence(formData);

        if (image.status === 200) {
          setEvidence(image.data.data[0]);
          setLoading(false);
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, 2000);

  // const handleClose = () => {
  //   setLoading(false);
  //   setPaymentDate('');
  //   setEvidence('');
  //   props.toggle('payoutVerificationModalOpen');
  // };

  const handleImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    const formData = new FormData();
    for (const file of e.target.files) {
      formData.append('file', file);
    }

    try {
      setLoading(true);
      const res = await PayoutService.uploadTaxEvidence(formData);

      if (res.status === 200) {
        setEvidence(res.data.data[0]);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error('Terjadi kesalahan pada saat upload bukti potong pajak.');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const isValid = () => {
    const invalidRef = (ref, apa) => {
      ref.classList.add('is-invalid');
      ref.focus();
    };

    if (!evidence || evidence === '') {
      invalidRef(evidenceRef.current, 'gambar');
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    if (isValid()) {
      try {
        const res = await PayoutService.verifyTaxEvidence(props.row.id, {
          tax_evidence: evidence
        });

        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Upload Bukti Potong Pajak Berhasil',
            timer: 5000
          }).then(() => toggle());

          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const payment =
    props.row && props.row.tax_evidence ? (
      <div style={{ textAlign: 'center' }}>
        <br />
        <img
          onClick={() => window.open(props.row.tax_evidence, '_blank')}
          alt=""
          style={{ maxWidth: '100%' }}
          src={props.row.tax_evidence}
        />
      </div>
    ) : (
      <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
        <Col md={12}>
          <FormGroup>
            <Label className="form-control-label" htmlFor="customFileLang">
              Bukti Potong Pajak
              <sup>
                <span className="text-red">*</span>
              </sup>
            </Label>
            <div className="custom-file">
              <Input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                innerRef={evidenceRef}
                type="file"
                accept="image/*"
                onChange={handleImage}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                Pilih file
              </label>
            </div>
          </FormGroup>
        </Col>
        <Col md={12}>
          {evidence !== '' && evidence && (
            <img src={evidence} alt="order evidence url" width="200" style={{ marginRight: 5 }} />
          )}
        </Col>
      </Form>
    );

  return (
    <>
      <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
        <ModalHeader>Upload Bukti Potong Pajak</ModalHeader>
        <ModalBody>
          <Row>{payment}</Row>
        </ModalBody>
        <ModalFooter>
          {props.row && props.row.tax_evidence === null && (
            <Button disabled={loading} color="primary" onClick={handleSubmit}>
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Simpan'
              )}
            </Button>
          )}
          <Button disabled={loading} onClick={toggle}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TaxEvidenceModal;
