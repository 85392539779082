import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import OrderService from '../../../../services/OrderService';
import debounce from '../../../../helpers/debounce';

function ProcessRefundModal(props) {
  const [alert, setAlert] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [transfer_date, setTransferDate] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (props.isOpen) {
              if (blob.size / 1000000 <= 15) {
                handleImagePaste(blob);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
                });
              }
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }, [props.isOpen]);

  const handleImagePaste = debounce(async file => {
    const formData = new FormData();
    console.log('masuk handle image');
    formData.append('file', file);

    if (file) {
      try {
        setLoading(true);
        const image = await OrderService.uploadImageRefund(formData);
        console.log(image);

        if (image.status === 200) {
          console.log(image.data.data[0]);
          setImageURL(image.data.data[0]);
          setLoading(false);
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, 2000);

  const toggle = () => {
    setAlert('');
    setImageURL('');
    setLoading(false);

    props.toggle(props.row, 'isInputProcessRefundModal');
  };

  const isValid = () => {
    if (!imageURL || imageURL === '') {
      setAlert('Harap upload bukti transfer untuk refund');
      return false;
    } else if (!transfer_date || transfer_date === '') {
      setAlert('Harap isi tanggal transfer untuk refund');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (isValid()) {
        setLoading(true);
        const data = {
          order_id: props.row.order.id,
          evidence_image: imageURL,
          transfer_date: transfer_date
        };

        const res = await OrderService.processRefund(data);

        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Refund Berhasil Diproses'
          }).then(() => {
            setLoading(false);
            toggle();
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleUploadImage = async e => {
    e.preventDefault();
    setAlert('');

    if (e.target.files.length > 0) {
      try {
        setLoading(true);
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append('file', file);

        const image = await OrderService.uploadImageRefund(formData);

        if (image.status === 200) {
          setImageURL(image.data.data[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal isOpen={props.isOpen} fade={false} backdrop="static" toggle={toggle}>
        <ModalHeader toggle={toggle}>Proses Pengajuan Refund</ModalHeader>
        <ModalBody>
          {alert !== '' && <Alert color="danger">{alert}</Alert>}

          <div className="mb-4">
            {props.row && (
              <>
                <Row>
                  <Col sm={4} style={{ fontSize: '13px' }}>
                    Order ID
                  </Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.order && props.row.order.id}</div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} style={{ fontSize: '13px' }}>
                    Atas Nama Rekening
                  </Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.order.refund && props.row.order.refund.account_name}</div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} style={{ fontSize: '13px' }}>
                    Nomor Rekening
                  </Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.order.refund && props.row.order.refund.account_number}</div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} style={{ fontSize: '13px' }}>
                    Bank
                  </Col>
                  <Col sm={8}>
                    <div>:&#9;{props.row.order.refund && props.row.order.refund.bank_name}</div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} style={{ fontSize: '13px' }}>
                    Jumlah
                  </Col>
                  <Col sm={8}>
                    <div>
                      :&nbsp;
                      {props.row.order.refund &&
                        props.row.order.refund.refund_amount.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        })}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} style={{ fontSize: '13px' }}>
                    Keterangan Refund
                  </Col>
                  <Col sm={8}>
                    <div style={{ wordBreak: 'break-all' }}>
                      :&#9;{props.row.order.refund && props.row.order.refund.refund_desc}
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>

          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="customFileLang">
                    Bukti Transfer
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </label>
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="customFileLang"
                      lang="en"
                      accept="image/*"
                      type="file"
                      multiple
                      onChange={handleUploadImage}
                    />
                    <label className="custom-file-label" htmlFor="customFileLang">
                      Pilih file
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {imageURL !== '' && (
              <Row>
                <Col xs="12">
                  <div>
                    <img src={imageURL} alt="order evidence url" width="180" height="200" style={{ marginRight: 5 }} />
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="amountPaid">
                    Tanggal Bayar
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </label>
                  <Input
                    id="amountPaid"
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    name="payment_date"
                    onKeyDown={e => e.preventDefault()}
                    onWheel={e => false}
                    value={transfer_date}
                    onChange={e => {
                      // remove class invalid
                      setAlert('');
                      setTransferDate(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Setujui'
            )}
          </Button>{' '}
          <Button color="secondary" disabled={loading} onClick={toggle}>
            Batalkan
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ProcessRefundModal;
