import React from 'react';
// react library for routing
import { Route, Switch } from 'react-router-dom';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar';
import AuthFooter from '../components/Footers/AuthFooter';

import Login from '../views/pages/auth/Login';
import Register from '../views/pages/auth/Register';

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default');
  }

  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AuthNavbar />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
          </Switch>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
