import React from 'react';
import Swal from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import UserService from '../../../services/UserService';

import SearchBar from '../../../components/Search';

class TableUser extends React.Component {
  state = {
    alert: null,
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortField: null,
    sortOrder: undefined,
    searchText: ''
  };

  columns = [
    {
      dataField: 'no',
      text: 'Nomor',
      sort: false
    },
    {
      dataField: 'name',
      text: 'Nama',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'role_name',
      text: 'Sebagai',
      sort: true
    },
    {
      dataField: 'unit_name',
      text: 'Unit',
      sort: true,
      formatter: cell => (cell ? cell : 'All')
    },
    {
      dataField: 'is_active',
      text: 'Status Aktif',
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const btnClassName = row.is_active ? 'fas fa-check' : 'fas fa-times';
        return (
          <Button size="sm" onClick={() => this.onChangeStatus(row.id)}>
            <i className={btnClassName}></i>
          </Button>
        );
      }
    },
    {
      dataField: 'action',
      text: 'Aksi',
      sort: false,
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <Button color="warning" type="button" size="sm" outline onClick={() => this.handleUpdate(row.id)}>
              <span className="btn-inner--icon">
                <i className="fas fa-user-edit" />
              </span>
              <span className="btn-inner--text"> Ubah</span>
            </Button>{' '}
            <Button
              color="danger"
              type="button"
              size="sm"
              outline
              onClick={() => this.showConfirmAlert(row.id)}
              title="Hapus Data"
            >
              <span className="btn-inner--icon">
                <i className="fas fa-trash" />
              </span>
              <span className="btn-inner--text"> Hapus</span>
            </Button>
          </>
        );
      }
    }
  ];

  onChangeStatus = id => {
    UserService.changeStatus(id).then(() => this.getData());
  };

  onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;
    this.setState(
      prevState => ({
        ...prevState,
        page,
        sizePerPage,
        sortField,
        sortOrder,
        searchText
      }),
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = this.state;
    UserService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText)
      .then(response => {
        this.setState({
          data: response.data,
          totalSize: response.recordsFiltered,
          page: response.page
        });
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          this.props.history.push('/login'); // redirect login
        }
        this.errorAlert(error.message);
      });
  };

  errorAlert = message => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Terjadi Kesalahan"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </Swal>
      )
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
    this.getData();
  };

  showConfirmAlert = id => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Apakah anda yakin?"
          onCancel={this.hideAlert}
          onConfirm={() => this.handleDelete(id)}
          showCancel
          cancelBtnBsStyle="link"
          cancelBtnText="Batal"
          confirmBtnBsStyle="danger"
          confirmBtnText="Hapus"
          btnSize=""
          reverseButtons={false}
          showCloseButton={true}
          allowEscape={false}
          closeOnClickOutside={false}
        >
          Data tidak dapat dipulihkan
        </Swal>
      )
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Berhasil!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil dihapus
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  handleUpdate = id => {
    this.props.history.push('/users/edit/' + id);
  };

  handleDelete = id => {
    UserService.remove(id)
      .then(response => {
        this.confirmedAlert();
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          this.props.history.push('/login'); // redirect login
        }
        this.errorAlert(error.message);
      });
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Pengguna" parentName="Master Data" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Tabel Pengguna</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Link to="/users/add">
                        <Button className="btn-round btn-icon" color="primary" id="tooltip443412080" size="sm">
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Tambah</span>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider data={this.state.data} keyField="id" columns={this.columns} search>
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar className="form-control-sm" {...props.searchProps} />
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: this.state.page,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={this.onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default TableUser;
