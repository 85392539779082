import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import TaxService from '../../../../services/TaxService';
import TaxModal from './AddTaxModal/TaxModal';

const source = Axios.CancelToken.source();

const TableTax = props => {
  const [modal, setModal] = useState({
    taxModalOpen: false,
    npwpModalOpen: false
  });
  const [selectedRow, setSelectedRow] = useState(null);

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [listBy, setListBy] = useState([]);
  const [filter_column, setFilter_Column] = useState('');
  const [filter_text, setFilter_text] = useState('');

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    getData();
    getColumnOptions();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    getData();
  }, [page, sizePerPage, modal.taxModalOpen]);

  async function getData() {
    setLoading(true);
    try {
      const response = await TaxService.getDatatable(page, sizePerPage, filter_column, filter_text);

      if (response.status === 200) {
        setData(response.data);
        setTotalSize(response.recordsTotal);
        setPage(response.page);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      setLoading(false);
    }
  }

  const getColumnOptions = () => {
    TaxService.listBy()
      .then(res => {
        setListBy(res.data);
      })
      .catch(error => console.log(error));
  };

  const onTableChange = (type, newState) => {
    console.log(props.match.params);
    const { page, sizePerPage } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
  };

  const toggleModal = (name, row = null) => {
    setSelectedRow(row);
    setModal({ ...modal, [name]: !modal[name] });
  };

  return (
    <>
      <SimpleHeader name="Faktur Pajak" parentName="Finance" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Pencarian</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label htmlFor="filter_column">Cari Berdasarkan</label>

                      <Input
                        id="filter_column"
                        type="select"
                        name="filter_column"
                        value={filter_column}
                        onChange={e => {
                          setFilter_Column(e.target.value);
                        }}
                      >
                        <option value="">--Pilih Kolom Pencarian--</option>
                        {listBy.map((column, key) => (
                          <option key={key} value={column.value}>
                            {column.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label htmlFor="filter_text">&nbsp;</label>
                      <Input
                        id="filter_text"
                        type="text"
                        name="filter_text"
                        placeholder="Keyword"
                        value={filter_text}
                        onChange={e => {
                          setFilter_text(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-right">
                      <Button onClick={() => getData()} color="primary">
                        Cari
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Tabel Faktur Pajak</h3>
                  </Col>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'id',
                    text: 'No',
                    sort: true
                  },
                  {
                    dataField: 'order_number',
                    text: 'Nomor Order',
                    sort: true
                  },
                  {
                    dataField: 'customer_name',
                    text: 'Nama Pemesan',
                    sort: true
                  },
                  {
                    dataField: 'phone',
                    text: 'Hp',
                    sort: true
                  },
                  {
                    dataField: 'platform',
                    text: 'Platform',
                    sort: true
                  },
                  {
                    dataField: 'receiver_name',
                    text: 'Nama Penerima',
                    sort: true
                  },
                  {
                    dataField: 'gross_amount',
                    text: 'DPP',
                    sort: true,
                    formatter: cell => {
                      return cell.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      });
                    }
                  },
                  {
                    dataField: 'tax',
                    text: 'Pajak',
                    sort: true,
                    formatter: cell => {
                      return cell.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      });
                    }
                  },
                  {
                    dataField: 'grand_total',
                    text: 'Total Pesanan',
                    sort: true,
                    formatter: cell => {
                      return cell.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                      });
                    }
                  },
                  {
                    dataField: 'action',
                    text: 'Aksi',
                    sort: false,
                    isDummyField: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <>
                          {row.npwp && (
                            <>
                              <Button
                                color="info"
                                type="button"
                                size="sm"
                                outline
                                onClick={() => toggleModal('npwpModalOpen', row)}
                              >
                                <span className="btn-inner--text">Lihat NPWP</span>
                              </Button>
                              <br />
                              <br />
                            </>
                          )}
                          {row.tax_invoice === null ? (
                            <Button
                              color="primary"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => toggleModal('taxModalOpen', row)}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-edit" />
                              </span>
                              <span className="btn-inner--text"> Upload Faktur Pajak</span>
                            </Button>
                          ) : (
                            <Button
                              color="success"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => window.open(row ? row.tax_invoice : '', '_blank')}
                            >
                              <span className="btn-inner--text">Faktur Pajak</span>
                            </Button>
                          )}
                        </>
                      );
                    }
                  }
                ]}
                search
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      <TaxModal row={selectedRow} toggle={toggleModal} isOpen={modal.taxModalOpen} />
      <Modal
        isOpen={modal.npwpModalOpen}
        toggle={() => {
          // setLoading(false);
          toggleModal('npwpModalOpen');
        }}
      >
        <ModalBody>
          <Card>
            <CardBody>
              <Row>
                <CardImg
                  width={300}
                  onClick={() => window.open(selectedRow ? selectedRow.npwp : '', '_blank')}
                  alt="NPWP"
                  src={selectedRow ? selectedRow.npwp : ''}
                />
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} onClick={() => toggleModal('npwpModalOpen')}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TableTax;
