import React from 'react';

import { Alert, Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, Row } from 'reactstrap';

import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';
import OrderService from '../../../services/OrderService';
import ReportService from '../../../services/ReportService';

import moment from 'moment';
import 'moment/locale/id';
import 'react-daterange-picker/dist/css/react-calendar.css'; // For some basic styling. (OPTIONAL)
moment.locale('id');

class ReportFinance extends React.Component {
  state = {
    start_date: moment()
      .subtract(1, 'day')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    loading: false,
    status_type_id: '',
    payment_status_id: '',
    filter_column: '',
    filter_text: '',
    with_deleted: false,
    error: '',
    paymentStatusOptions: [],
    statusTypeOptions: [],
    filterByOptions: [],
    unit_id: '',
    unitOptions: [
      { value: 1, name: 'Great' },
      { value: 2, name: 'Excellent' },
      { value: 3, name: 'Force' },
    ]
  };

  startDateRef = React.createRef();
  endDateRef = React.createRef();

  componentDidMount() {
    this.getColumnOptions();
    this.getStatusOptions();
    this.getPaymentStatusOptions();
    this.getUnitOptions();
  }

  getColumnOptions = () => {
    OrderService.getColumnOptions()
      .then(({ data }) => this.setState({ filterByOptions: data }))
      .catch(error => console.log(error));
  };

  getStatusOptions = () => {
    OrderService.getStatusOptions()
      .then(({ data }) => this.setState({ statusTypeOptions: data }))
      .catch(error => console.log(error));
  };

  getPaymentStatusOptions = () => {
    OrderService.getPaymentStatusOptions()
      .then(({ data }) => this.setState({ paymentStatusOptions: data }))
      .catch(error => console.log(error));
  };

  getUnitOptions = () => {
    OrderService.getUnitOptions()
      .then(({ data }) => this.setState({ unitOptions: data }))
      .catch(error => console.log(error));
  };

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  handleChange = e => {
    e.target.classList.remove('is-invalid');

    let value = e.target.value;
    if (['end_date', 'start_date'].includes(e.target.name)) {
      value = this.formatDate(e.target.value);
    }

    this.setState({
      [e.target.name]: value,
      error: ''
    });

    if (e.target.name === 'start_date') {
      this.setState({
        end_date: ''
      });
    }
  };

  validate(start, end) {
    if (!start || !end) {
      this.setState({
        error: 'Mohon isi rentang tanggal dengan benar'
      });
      return false;
    }

    return true;
  }

  handleDownload = async e => {
    const { payment_status_id, filter_column, filter_text, status_type_id, with_deleted, unit_id } = this.state;

    if (this.validate(this.state.start_date, this.state.end_date)) {
      const start_date = this.formatDate(new Date(this.state.start_date));
      const end_date = this.formatDate(new Date(this.state.end_date));

      this.setState({ loading: true });
      try {
        const report = await ReportService.downloadReportFinance({
          start_date,
          end_date,
          payment_status_id,
          filter_column,
          filter_text,
          status_type_id,
          with_deleted,
          unit_id
        });

        if (report && report.status === 200) {
          window.open(report.data.data.url, '_blank');
        }

        this.setState({ loading: false });
      } catch (error) {
        this.setState({ loading: false });
        console.log(error.response);
        Swal.fire({
          title: 'Terjadi Kesalahan',
          text: error.message,
          icon: 'error'
        });
      }
    }
  };

  getMinEndDate = () => {
    if (this.state.start_date) {
      return new Date(new Date(this.state.start_date).setDate(new Date(this.state.start_date).getDate()))
        .toISOString()
        .split('T')[0];
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  getMaxEndDate = () => {
    if (this.state.start_date) {
      const firstDate = moment(this.state.start_date);
      const today = moment();

      const diff = Math.abs(moment.duration(moment(today).diff(firstDate)).asDays()) + 1;

      if (diff > 185) {
        return moment(this.state.start_date)
          .add('185', 'days')
          .toISOString()
          .split('T')[0];
      } else {
        return new Date().toISOString().split('T')[0];
      }
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  render() {
    return (
      <>
        {this.state.loading && <Loader />}
        <SimpleHeader name="Laporan Keuangan" />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Cari Laporan</h3>
                </CardHeader>
                <CardBody>
                  {this.state.error && (
                    <Alert color="danger">
                      <ul>
                        <li>{this.state.error}</li>
                      </ul>
                    </Alert>
                  )}
                  <Row>
                    <Col md="12" lg="12">
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="start_date">Dari</label>
                            <Input
                              id="start_date"
                              type="date"
                              name="start_date"
                              max={new Date().toISOString().split('T')[0]}
                              innerRef={this.startDateRef}
                              onKeyDown={e => e.preventDefault()}
                              onWheel={e => e.preventDefault()}
                              value={this.state.start_date}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="end_date">Sampai</label>
                            <Input
                              id="end_date"
                              type="date"
                              name="end_date"
                              min={this.getMinEndDate()}
                              max={this.getMaxEndDate()}
                              innerRef={this.endDateRef}
                              onKeyDown={e => e.preventDefault()}
                              onWheel={e => e.preventDefault()}
                              value={this.state.end_date}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="payment_status_id">Pembayaran</label>
                            <Input
                              id="payment_status_id"
                              type="select"
                              name="payment_status_id"
                              value={this.state.payment_status_id}
                              onChange={this.handleChange}
                            >
                              <option value="">Semua</option>
                              {this.state.paymentStatusOptions.map((status, key) => (
                                <option key={key} value={status.value}>
                                  {status.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="status_type_id">Status Produksi</label>
                            <Input
                              id="status_type_id"
                              type="select"
                              name="status_type_id"
                              value={this.state.status_type_id}
                              onChange={this.handleChange}
                            >
                              <option value="">Semua</option>
                              {this.state.statusTypeOptions.map((status, key) => (
                                <option key={key} value={status.value}>
                                  {status.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="unit_id">Unit</label>
                            <Input
                              id="unit_id"
                              type="select"
                              name="unit_id"
                              value={this.state.unit_id}
                              onChange={this.handleChange}
                            >
                              <option value="">--Pilih--</option>
                              {this.state.unitOptions.map((unit, key) => (
                                <option key={key} value={unit.value}>
                                  {unit.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="filter_column">Cari Berdasarkan</label>

                            <Input
                              id="filter_column"
                              type="select"
                              name="filter_column"
                              value={this.state.filter_column}
                              onChange={this.handleChange}
                            >
                              <option value="">--Pilih Kolom Pencarian--</option>
                              {this.state.filterByOptions.map((column, key) => (
                                <option key={key} value={column.value}>
                                  {column.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label htmlFor="filter_text">Keyword</label>
                            <Input
                              id="filter_text"
                              type="text"
                              name="filter_text"
                              placeholder="Kata Kunci"
                              value={this.state.filter_text}
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <label className="form-control-label" htmlFor="with_deleted">
                            Termasuk yang dihapus ?
                          </label>
                          <br />
                          <label className="custom-toggle">
                            <Input
                              id="with_deleted"
                              defaultChecked={this.state.with_deleted}
                              type="checkbox"
                              name="with_deleted"
                              onChange={e => {
                                this.setState(prevState => ({
                                  with_deleted: !prevState.with_deleted
                                }));
                              }}
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button onClick={this.handleDownload} disabled={this.state.loading} color="primary">
                          Download
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ReportFinance;
