import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import LeadsService from '../../../services/LeadsService';

const FormTreatment = ({ isOpen, toggle, row, setDetailModal, getDataAfterUpdate }) => {
  const [treatment, setTreatment] = useState('');
  const [treatment_date, setTreatmentDate] = useState('');

  useEffect(() => {
    getDataAfterUpdate();
  }, []);

  function formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const resetState = () => {
    setTreatment('');
    setTreatmentDate('');
  };

  const submitForm = e => {
    e.preventDefault();

    const data = {
      treatment,
      treatment_date
    };

    LeadsService.insert(row.id, data)
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Treatment Berhasil Ditambahkan',
            onClose: setDetailModal(false)
          });
          getDataAfterUpdate();
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => toggle()} size="lg" onOpened={() => resetState()}>
        <div className="modal-header">
          <h5 className="modal-title" id="detailModalLabel">
            Input Treatment
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDetailModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody>
          <Form onSubmit={submitForm}>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="treatment_date">
                    Tanggal Treatment
                  </Label>
                  <Input
                    id="treatment_date"
                    // min={new Date().toISOString().split('T')[0]}
                    type="date"
                    name="treatment_date"
                    onKeyDown={e => e.preventDefault()}
                    value={treatment_date}
                    onChange={e => {
                      setTreatmentDate(formatDate(e.target.value));
                    }}
                  />
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="treatment">
                    Treatment
                  </Label>
                  <Input
                    required
                    placeholder="Treatment"
                    name="treatment"
                    type="text"
                    value={treatment}
                    onChange={e => setTreatment(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="mt-4">
              <Button color="primary" className="btn" type="submit">
                Simpan
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FormTreatment;
