import React, { useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import LeadsService from '../../../../services/LeadsService';

const UploadFile = props => {
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);

  const fileRef = useRef();

  const closeModal = () => {
    setLoading(false);
    setFile(null);

    props.toggle('isUploadFileModal');
  };

  const handleFile = e => {
    e.preventDefault();

    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const data = new FormData();
    data.append('file', file);

    try {
      setLoading(true);
      const response = await LeadsService.importLeads(data);
      if (response.status === 200) {
        Swal.fire({
          title: `${
            response.data
              ? `Data terbaca: ${response.data.prepared}, berhasil diimport: ${response.data.inserted}`
              : 'Sukses import data'
          }`,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500
        });
        props.getDataAfterUpdate();
        closeModal();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {props.isOpen && (
        <Modal isOpen={props.isOpen} toggle={() => props.toggle('isUploadFileModal')} centered size="md">
          <ModalHeader toggle={closeModal} charCode="x">
            Import Leads
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="bank_id">
                      File{' '}
                      {/* <span
                        style={{ color: '#b01d1d', cursor: 'pointer' }}
                        //   onClick={handleDownloadLinkTemplate}
                      >
                        (download template)
                      </span> */}
                      <sup>
                        <span className="text-red">*</span>
                      </sup>
                    </label>
                    <div className="custom-file">
                      <input
                        className="custom-file-input"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx"
                        ref={fileRef}
                        id="customFileLang"
                        lang="en"
                        onChange={handleFile}
                      />
                      <label className="custom-file-label" htmlFor="customFileLang">
                        Pilih file
                      </label>
                    </div>
                    <p>{file && file.name}</p>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            {file ? (
              <Button disabled={loading} onClick={handleSubmit} color="primary">
                {loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    &nbsp; Loading...
                  </>
                ) : (
                  'Upload'
                )}
              </Button>
            ) : (
              <Button disabled={loading} onClick={closeModal} color="primary">
                {loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    &nbsp; Loading...
                  </>
                ) : (
                  'Tutup'
                )}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default UploadFile;
