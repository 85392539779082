import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import Loading from '../../../components/Loader/Loader';
import ScoreboardService from '../../../services/ScoreboardService';
import CardTarget from './CardTarget';
import Conclusion from './Conclusion';
import StandingTable from './StandingTable';

const formatDetail = [
  {
    level_name: '',
    daily: [],
    monthly: []
  }
];

const defaultDetail = {
  great: formatDetail,
  excellent: formatDetail,
  stp: formatDetail,
  seatcover: formatDetail,
  carmat: formatDetail,
  force: formatDetail
};

const formatSummaryPerUnit = {
  title: '',
  today: {
    omzet: 0,
    leads: 0,
    deals: 0
  },
  yesterday: {
    omzet: 0
  },
  monthly: {
    month: '',
    omzet: 0,
    target: 0,
    leads: 0,
    deals: 0,
    budget_ratio: 0,
    cost_per_lead: 0
  }
};

const defaultSummary = {
  great: formatSummaryPerUnit,
  excellent: formatSummaryPerUnit,
  force: formatSummaryPerUnit,
  stp: formatSummaryPerUnit,
  seatcover: formatSummaryPerUnit,
  carmat: formatSummaryPerUnit
};

const formatScoreboardPerUnit = {
  title: '',
  today: {
    omzet: 0,
    date: ''
  },
  yesterday: {
    omzet: 0,
    date: ''
  },
  thisYear: {
    year: 0,
    omzet: 0,
    target: 0,
    achieved: 0
  }
};

const NewScoreboard = props => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    unit: {
      excellent: formatScoreboardPerUnit,
      great: formatScoreboardPerUnit,
      stp: formatScoreboardPerUnit,
      seatcover: formatScoreboardPerUnit,
      carmat: formatScoreboardPerUnit,
      force: formatScoreboardPerUnit
    },
    all: {
      year: 0,
      omzet: 0,
      target: 0,
      achieved: 0
    }
  });

  const unitOptions = [];
  if (Object.keys(data.unit).length > 0) {
    Object.keys(data.unit).forEach(key => {
      unitOptions.push({
        value: key,
        label: data.unit[key].title
      });
    });
  }

  const qs = new URLSearchParams(window.location.search);

  const [params, setParams] = useState({ unit: '' });
  const [detail, setDetail] = useState(defaultDetail);
  const [summary, setSummary] = useState(defaultSummary);
  const [selectedUnit, setSelectedUnit] = useState(qs.get('unit') || '');

  useEffect(() => {
    reloadData();
    getParams();

    let interval = setInterval(() => {
      reloadData();
    }, 1000 * 15 * 60); // 15 menit

    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const reloadData = () => {
    getDataTarget();
    getDetail();
    getSummary();
  };

  // Event listener untuk menanggapi perubahan riwayat peramban (back button)
  useEffect(() => {
    const handlePopState = () => {
      // Kembali mengambil dan memperbarui query parameters
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = {};
      for (const [key, value] of urlSearchParams) {
        params[key] = value;
      }
      setParams(params);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    generateParams(selectedUnit);
  }, [selectedUnit]);

  useEffect(() => {
    if (params.unit) {
      setSelectedUnit(params.unit);
    }
  }, [params]);

  function getParams() {
    const qs = new URLSearchParams(window.location.search);
    const unit = qs.get('unit');
    if (unit) {
      setParams({ unit });
      setSelectedUnit(unit);

      generateParams();
    }
  }

  // new query string if get data
  function generateParams(selectedUnit) {
    const qs = new URLSearchParams({ unit: selectedUnit });

    props.history.push({
      pathname: '/scoreboard',
      search: '?' + qs
    });
  }

  async function getDataTarget() {
    try {
      setLoading(true);
      const response = await ScoreboardService.getScoreboardTarget();
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getDetail() {
    try {
      setLoading(true);
      const response = await ScoreboardService.getDataRank();
      if (response.status === 200) {
        setDetail(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getSummary() {
    try {
      setLoading(true);
      const response = await ScoreboardService.getSummaryOmzet();
      if (response.status === 200) {
        setSummary(response.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleSelectUnit = unit => {
    setSelectedUnit(unit);
  };

  return (
    <>
      {loading && <Loading />}
      {selectedUnit === '' && (
        <Container fluid className="mt-2">
          <Row>
            <CardTarget unit="excellent" data={data.unit.excellent} handleSelectUnit={handleSelectUnit} />
            <CardTarget unit="great" data={data.unit.great} handleSelectUnit={handleSelectUnit} />
            <CardTarget unit="stp" data={data.unit.stp} handleSelectUnit={handleSelectUnit} />
          </Row>

          <Row>
            <CardTarget unit="carmat" data={data.unit.carmat} handleSelectUnit={handleSelectUnit} />
            <CardTarget unit="seatcover" data={data.unit.seatcover} handleSelectUnit={handleSelectUnit} />
            <CardTarget unit="force" data={data.unit.force} handleSelectUnit={handleSelectUnit} />
          </Row>

          <Row>
            <Conclusion all={data.all} />
          </Row>
        </Container>
      )}

      {selectedUnit !== '' && (
        <StandingTable
          updateData={reloadData}
          unitOptions={unitOptions}
          handleSelectUnit={handleSelectUnit}
          unit={selectedUnit}
          unitName={data.unit[selectedUnit].title}
          detail={detail[selectedUnit]}
          summary={summary[selectedUnit]}
          year={data.all.year}
        />
      )}
    </>
  );
};

export default NewScoreboard;
