import { SOCKET_CONNECTED, SOCKET_DISCONNECTED } from '../actions/types';

const initialState = {
  io: {
    connected: false,
    id: null
  }
};

export default function(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SOCKET_CONNECTED:
      return { ...state, io: payload.socket };
    case SOCKET_DISCONNECTED:
      console.log('disconnected');
      return { ...state, io: null };
    default:
      return state;
  }
}
