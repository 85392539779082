import html2canvas from 'html2canvas';
import React, { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { Card, Col, Row } from 'reactstrap';
import iconSrc from '../../../../assets/img/brand/coversuper_icon.png';
import OrderService from '../../../../services/OrderService';

const ShipmentLabel = props => {
  const [data, setData] = useState({
    id: props.match.params.order_id,
    courier_code: '',
    service_code: '',
    service_name: '',
    resi: '',
    insurance_fee: 0,
    total_weight: 0,
    details: [{ qty: 1 }],
    description: '',
    courier_logo: '',
    supplier: {
      name: 'Cover Super',
      address: '',
      subdistrict: { name: '' },
      district: { name: '', type: '' },
      province: { name: '' },
      phone: ''
    },
    shipping_address: {
      name: '',
      address: '',
      subdistrict: { name: '' },
      district: { name: '', type: '' },
      province: { name: '' },
      phone: '',
      postal_code: ''
    }
  });
  useEffect(() => {
    getData(props.match.params.order_id);
  }, []);

  const getData = async orderId => {
    const res = await OrderService.findById(orderId);
    console.log({ data: res.data });
    setData(res.data);
    getCanvas();
  };

  const getCanvas = async () => {
    setTimeout(() => {
      const content = document.getElementById('print-label-component');
      content.setAttribute('style', 'padding: 15px');
      const mywindow = window.open('', 'Print');
      if (mywindow) {
        html2canvas(content, { useCORS: true }).then(canvas => {
          const border = document.createElement('div');
          border.setAttribute('id', 'border');
          mywindow.document.body.appendChild(border);
          mywindow.document.getElementById('border').appendChild(canvas);
          mywindow.print();
          window.close();
        });
      }
    }, 1000);
  };

  return (
    <div className="container">
      <div id="print-label-component">
        <Card
          className="border p-2"
          style={{
            width: '400px'
          }}
        >
          <div>
            <Row className="px-3">
              <Col
                md="12"
                className="border d-flex justify-content-between align-items-center"
                style={{
                  height: '4.5rem'
                }}
              >
                <img width="30%" height="55%" src={iconSrc} alt="..." />
                <img height="55%" src={data.courier_logo} alt="..." />
              </Col>
            </Row>

            <Row className="px-3">
              <Col md="12" className="border border-bottom-0 d-flex justify-content-center">
                <Barcode format="CODE128" value={data.resi} height={25} fontSize={10} displayValue={false} margin={5} />
              </Col>
              <Col md="12" className="border border-top-0 d-flex justify-content-center">
                <h6 className="fs-2 mt-1">{data.resi}</h6>
              </Col>
            </Row>

            <Row className="px-3">
              <Col md="12" className="border border-top-0 d-flex justify-content-center align-items-center">
                <h5 className="fs-2 mt-1">Non-COD</h5>
              </Col>
            </Row>

            <Row className="px-3">
              <Col md="12" className="border border-top-0 border-bottom-0 d-flex justify-content-center">
                <h5 className="fs-2 mt-1">Jenis Layanan: {data.service_name}</h5>
              </Col>
            </Row>

            <Row className="px-3">
              <Col md="6" className="border border-right-0">
                <Barcode
                  format="CODE128"
                  value={`CSIG-${data.id}`}
                  width={1}
                  height={45}
                  marginTop={10}
                  fontSize={12}
                />
              </Col>

              <Col md="6" className="border">
                <Row className="border border-top-0 border-left-0 border-right-0">
                  <h6 className="ml-2 mt-2">
                    Asuransi: Rp {new Intl.NumberFormat('id-ID').format(data.insurance_fee)},-
                  </h6>
                </Row>
                <Row className="border border-top-0 border-left-0 border-right-0">
                  <h6 className="ml-2 mt-2">Berat: {new Intl.NumberFormat('id-ID').format(data.total_weight)} gr</h6>
                </Row>
                <Row>
                  <h6 className="ml-2 mt-2">
                    Quantity: {data.details.reduce((total, item) => total + item.qty, 0)} Pcs
                  </h6>
                </Row>
              </Col>
            </Row>

            <Row className="px-3">
              <Col md="12" className="border border-top-0">
                <h5>Isi Paket: Cover Super</h5>
              </Col>
            </Row>

            <Row
              className="px-3"
              style={{
                height: '7rem'
              }}
            >
              <Col md="6" className="border border-right-0 border-top-0">
                <p style={{ fontWeight: 'bold', lineHeight: 2, fontSize: '10px', margin: 0 }}>Penerima:</p>
                <p style={{ fontWeight: 'bold', lineHeight: 2, fontSize: '10px', margin: 0 }}>
                  {data.shipping_address.name}
                </p>
                <p style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '10px', margin: 0 }}>
                  {data.shipping_address.phone}
                </p>
                <p style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '10px', margin: 0 }}>
                  {data.shipping_address.address}, {data.shipping_address.subdistrict.name},{' '}
                  {data.shipping_address.district.type === 'Kabupaten' ? 'Kab' : 'Kota'}{' '}
                  {data.shipping_address.district.name}, {data.shipping_address.province.name},{' '}
                  {data.shipping_address.postal_code}
                </p>
              </Col>

              <Col md="6" className="border border-left-0 border-top-0">
                <p style={{ fontWeight: 'bold', lineHeight: 2, fontSize: '10px', margin: 0 }}>Pengirim:</p>
                <p style={{ fontWeight: 'bold', lineHeight: 2, fontSize: '10px', margin: 0 }}>{data.supplier.name}</p>
                <p style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '10px', margin: 0 }}>{data.supplier.phone}</p>
                <p style={{ fontWeight: 'bold', lineHeight: 1, fontSize: '10px', margin: 0 }}>
                  {data.supplier.address}, {data.supplier.subdistrict.name},{' '}
                  {data.supplier.district.type === 'Kabupaten' ? 'Kab' : 'Kota'} {data.supplier.district.name},{' '}
                  {data.supplier.province.name}
                </p>
              </Col>
            </Row>

            <Row className="px-3">
              <Col md="12" className="border border-top-0 py-2">
                <h6>Catatan: {data.description || '-'}</h6>
              </Col>
            </Row>

            <Row className="px-3">
              <Col md="12" className="border border-top-0">
                <p style={{ fontSize: '10px', fontStyle: 'italic', marginBottom: 0 }}>
                  * Pengirim wajib meminta bukti serah terima paket ke kurir.
                </p>
                <p style={{ fontSize: '10px', fontStyle: 'italic', marginBottom: 0 }}>
                  * Jika paket ini retur, pengirim tetap dikenakan biaya keberangkatan dan biaya retur sesuai ekspedisi.
                </p>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ShipmentLabel;
