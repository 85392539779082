import React, { useState } from 'react';

import { Button, Card, CardBody, CardHeader, CardImg, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';

import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { loadUser } from '../../../actions/authActions';
import UserService from '../../../services/UserService';
import PhotoModal from './PhotoModal';

function Profile(props) {
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const [name, setName] = useState(user.name);
  const [password, setPassword] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [nameValid, setNameValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);

  function toggle() {
    setIsOpen(!isOpen);
  }

  function handleChange(e) {
    let name = e.target.value;
    let nameValid = name !== '';
    setName(name);
    setNameValid(nameValid);
  }

  function handleChangePassword(e) {
    let password = e.target.value;
    let passwordValid = password === '' ? true : password.length < 4 ? false : true;

    setPassword(password);
    setPasswordValid(passwordValid);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (nameValid && passwordValid) {
      const user = { name, password };
      try {
        const { status } = await UserService.changeProfile(user);
        if (status === 200) {
          setPassword('');
          Swal.fire({
            icon: 'success',
            title: 'Berhasil perubahan data.',
            showConfirmButton: true,
            timer: 1500,
            onClose: () => {
              dispatch(loadUser());
            }
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Terjadi kesalahan',
          icon: 'error',
          text: error.message
        });
      }
    }
  }

  return (
    <>
      <PhotoModal isOpen={isOpen} toggle={toggle} />
      <SimpleHeader name="Profil Saya" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-2" xl="4">
            <Card className="card-profile">
              <CardImg
                className="rounded-circle"
                alt="..."
                src={`https://api.dicebear.com/7.x/initials/svg?seed=${
                  user ? user.name : ''
                }&backgroundColor=3949ab,00897b&backgroundType=gradientLinear,solid`}
                top
              />
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        // toggle();
                      }}
                    >
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={`https://api.dicebear.com/7.x/initials/svg?seed=${
                          user ? user.name : ''
                        }&backgroundColor=3949ab,00897b&backgroundType=gradientLinear,solid`}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
              <CardBody className="pt-0">
                <Row>
                  <Col>
                    <div className="card-profile-stats d-flex justify-content-center"></div>
                  </Col>
                </Row>
                <div className="text-center">
                  <h5 className="h3">{user.name}</h5>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {user.role.name}
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Cover Super
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit profile</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} noValidate method="POST">
                  <h6 className="heading-small text-muted mb-4">User information</h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-email">
                            Email
                          </label>
                          <Input
                            id="input-email"
                            readOnly
                            placeholder="jesse@example.com"
                            defaultValue={user.email}
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            Nama Lengkap
                          </label>
                          <Input
                            disabled
                            id="input-username"
                            placeholder="Username"
                            value={name}
                            valid={nameValid}
                            invalid={!nameValid}
                            type="text"
                            name="name"
                            onChange={handleChange}
                          />
                          <div className="invalid-feedback">Nama tidak boleh kosong!</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="validationEmail">
                            Password <i className="text-danger">(Kosongkan jika tidak diubah)</i>
                          </label>
                          <Input
                            id="validationPassword"
                            placeholder="Password"
                            type="password"
                            valid={passwordValid}
                            invalid={!passwordValid}
                            name="password"
                            value={password}
                            onChange={handleChangePassword}
                          />
                          <div className="invalid-feedback">Password minimal 4 karakter!</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Button color="primary" type="submit">
                            Simpan
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
