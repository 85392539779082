/* eslint-disable */
import React from 'react';
import * as Constants from '../../../constants';
import { connect } from 'react-redux';

import Dashboard from './Dashboard';

class index extends React.Component {
  render() {
    const user = this.props.user;
    if (!user) return null;

    return (
      <>
        <Dashboard role={user.role.name} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {})(index);
