import React from 'react';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import AuthLayout from './layouts/Auth';
import GlobalLayout from './layouts/Global';
import NotFound from './views/pages/NotFound';
import PrintLabel from './views/pages/order/PrintLabel';
// import CheckOrder from './views/pages/order/CheckOrder';
import NoInternetConnection from './components/NoInternetConnection';
import ability from './config/ability';
import LandingPage from './views/pages/affiliate/LandingPage';
import PrintInvoice from './views/pages/order/PrintInvoice';
import ScoreboardCsGroupTeam from './views/pages/scoreboard/csgroup';
import ScoreboardDealmaker from './views/pages/scoreboard/dealmaker';
import ScoreboardTeam from './views/pages/scoreboard/team';
// import Register from './views/pages/auth/Register';
import { loadSocket } from './actions/socketAction';
import ReceiveUpdate from './ReceiveUpdate';
import UpdateVersion from './views/pages/UpdateVersion';

import { connect } from 'react-redux';
import io from 'socket.io-client';
import { logout } from './actions/authActions';
import { API_URL } from './config';
import NewScoreboard from './views/pages/scoreboard/NewScoreboard';
import ShipmentLabel from './views/pages/shipping/ShipmentLabel';
const socket = io(API_URL);

class App extends React.Component {
  componentDidMount() {
    const ref = window.location.hostname;

    socket.on('connect', () => {
      // untuk set socket supaya bisa diakses di manapun
      // trigger ketika connect ke server
      socket.emit('user_connected', this.props.user, response => {
        console.log(response);
        this.props.loadSocket(socket);
      });
    });

    if (ref === 'affiliate.coversuper.com' && this.props.location.pathname === '/') {
      this.props.history.push('/home');
    } else {
      if (
        ability.rules.length === 0 &&
        this.props.location.pathname !== '/login' &&
        this.props.location.pathname !== '/register' &&
        this.props.location.pathname !== '/scoreboard' &&
        this.props.location.pathname !== '/scoreboard-team' &&
        this.props.location.pathname !== '/home'
      ) {
        this.redirectLogin();
      }
    }
  }

  redirectLogin() {
    localStorage.clear();
    this.props.logout();
    this.props.history.push('/login');
  }

  render() {
    return (
      <NoInternetConnection>
        <ReceiveUpdate />
        <Switch>
          <Redirect from="/" exact to="/dashboard" />
          <Route path="/login" render={props => <AuthLayout {...props} />} />
          <Route path="/register" render={props => <AuthLayout {...props} />} />
          <Route path="/home" exact component={LandingPage} />
          {/* <Route path="/scoreboard" exact render={props => <Scoreboard {...props} />} /> */}
          <Route path="/scoreboard" exact render={props => <NewScoreboard {...props} />} />
          <Route path="/scoreboard-team" exact component={ScoreboardTeam} />
          <Route path="/scoreboard-dealmaker" exact component={ScoreboardDealmaker} />
          <Route path="/scoreboard-csgroup" exact component={ScoreboardCsGroupTeam} />
          {/* <Route path="/tracking" exact component={CheckOrder} /> */}
          {/* <Route path="/tracking/:order_number" exact component={CheckOrder} /> */}
          <Route path="/label/:flow_id" component={PrintLabel} />
          <Route path="/shipment-label/:order_id" component={ShipmentLabel} />
          <Route path="/print-invoice/:flow_id" component={PrintInvoice} />
          <Route path="/not-found" component={NotFound} />
          <Route path="/update-version" render={props => <UpdateVersion {...props} />} />
          <Route path="/logout" render={() => this.redirectLogin()} />
          <Route render={props => <GlobalLayout {...props} />} />
        </Switch>
      </NoInternetConnection>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadSocket: socket => dispatch(loadSocket(socket)),
    logout: () => dispatch(logout())
  };
};

const mapStateToProps = state => {
  return { socket: state.socket.io, user: state.auth.user };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
