import React from 'react';
// react component for creating dynamic tables
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Card, Container, Row, Button, CardHeader, Col } from 'reactstrap';

import { CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER } from '../../../constants';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Swal from 'react-bootstrap-sweetalert';
import CustomerService from '../../../services/CustomerService';
import Can from '../../../config/Can';

import SearchBar from '../../../components/Search';

class TableCustomer extends React.Component {
  state = {
    alert: null,
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortField: null,
    sortOrder: undefined,
    searchText: ''
  };

  onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;
    this.setState(
      prevState => ({
        ...prevState,
        page,
        sizePerPage,
        sortField,
        sortOrder,
        searchText
      }),
      () => this.getData()
    );
  };

  componentDidMount() {
    // this.getData();
  }

  getData = () => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = this.state;
    if (searchText.length >= 3) {
      CustomerService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText).then(response => {
        this.setState({
          data: response.data,
          totalSize: response.recordsFiltered,
          page: response.page
        });
      });
    } else {
      this.setState({
        alert: null,
        data: [],
        page: 1,
        sizePerPage: 10,
        totalSize: 0,
        sortField: null,
        sortOrder: undefined,
        searchText: ''
      });
    }
  };

  hideAlert = () => {
    this.setState({ alert: null });
    this.getData();
  };

  showConfirmAlert = id => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Apakah anda yakin?"
          onCancel={this.hideAlert}
          onConfirm={() => this.handleDelete(id)}
          showCancel
          cancelBtnBsStyle="link"
          cancelBtnText="Batal"
          confirmBtnBsStyle="danger"
          confirmBtnText="Hapus"
          btnSize=""
          reverseButtons={false}
          showCloseButton={true}
          allowEscape={false}
          closeOnClickOutside={false}
        >
          Data tidak dapat dipulihkan
        </Swal>
      )
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Berhasil!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil dihapus
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  handleDetailOrder = id => {
    this.props.history.push('/customers/orders/' + id);
  };

  handleDelete = id => {
    CustomerService.remove(id).then(response => {
      this.confirmedAlert();
    });
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Pelanggan" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Tabel Pelanggan</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'no',
                      text: 'Nomor',
                      sort: false
                    },
                    {
                      dataField: 'name',
                      text: 'Nama',
                      sort: true
                    },
                    {
                      dataField: 'phone',
                      text: 'Telepon',
                      sort: true
                    },
                    {
                      dataField: 'customer_type_name',
                      text: 'Jenis Pelanggan',
                      sort: true
                    },
                    {
                      dataField: 'action',
                      text: 'Aksi',
                      sort: false,
                      isDummyField: true,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        return (
                          <>
                            <Can I="update" a="Customer">
                              <Button
                                color="warning"
                                type="button"
                                size="sm"
                                outline
                                onClick={() => this.props.history.push('/customers/edit/' + row.id)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-edit" />
                                </span>
                                <span className="btn-inner--text"> Ubah</span>
                              </Button>
                            </Can>
                            <Can I="read" a="Customer Detail">
                              <Button
                                color="info"
                                type="button"
                                size="sm"
                                onClick={() => this.handleDetailOrder(row.id)}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-eye" />
                                </span>
                                <span className="btn-inner--text"> Lihat Order</span>
                              </Button>{' '}
                            </Can>
                            {[CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER].includes(row.customer_type_id) && (
                              <>
                                <Can I="read" a="Deposit">
                                  <Button
                                    color="success"
                                    type="button"
                                    size="sm"
                                    onClick={() => this.props.history.push(`/customers/${row.id}/deposit`)}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-money" />
                                    </span>
                                    <span className="btn-inner--text"> Deposit</span>
                                  </Button>{' '}
                                </Can>
                                <Can I="read" a="Billing">
                                  <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    onClick={() => this.props.history.push(`/customers/${row.id}/billing`)}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-money" />
                                    </span>
                                    <span className="btn-inner--text"> Tagihan</span>
                                  </Button>{' '}
                                </Can>
                              </>
                            )}
                          </>
                        );
                      }
                    }
                  ]}
                  search
                >
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              {...props.searchProps}
                              placeholder="Minimal 3 Karakter"
                              min={3}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: this.state.page,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={this.onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default TableCustomer;
