import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import CategoryService from '../../../../services/CategoryService';
import CoverImageService from '../../../../services/CoverImageService';
import CoverImageModal from './CoverImageModal';

const { SearchBar } = Search;

function CoverImage() {
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [datatable, setDatatable] = useState([]);
  const [page, setPage] = useState(0);
  const [filter] = useState('');
  const [sizePerPage, setSizePerPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [rowOpen, setRowOpen] = useState(null);

  const [isCoverImageModalOpen, setIsCoverImageModalOpen] = useState(false);

  const [color_options, setColorOptions] = useState([]);
  const [coverListOptions, setCoverListOptions] = useState([]);
  const [coverTypeOptions, setCoverTypeOptions] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  // const [selectedCoverLists, setSelectedCoverLists] = useState([]);
  // const [selectedCoverTypes, setSelectedCoverTypes] = useState([]);

  useEffect(() => {
    getCoverCategories();
  }, []);

  const getCoverCategories = async () => {
    try {
      const response = await CategoryService.list();
      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDatatable = async (categoryId, page, sizePerPage, filter) => {
    try {
      const datatableRes = await CoverImageService.getDatatable(categoryId, page, sizePerPage, filter);
      if (datatableRes.status === 200) {
        setDatatable(
          datatableRes.data.map((data, i) => ({
            i,
            ...data
          }))
        );
        setPage(datatableRes.page);
        setSizePerPage(datatableRes.limit);
        setTotalSize(datatableRes.recordsTotal);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCoverChange = async e => {
    e.persist();
    setCategoryId(e.target.value);

    try {
      if (e.target.value) {
        const response = await CoverImageService.getCoverImagesByCategoryId(e.target.value);
        if (response.status === 200) {
          getDatatable(e.target.value, page, sizePerPage, filter);
          setColorOptions(response.data.data.color_options);
          setCoverListOptions(response.data.data.cover_list_options);
          setCoverTypeOptions(response.data.data.cover_type_options);
          setSelectedColors(response.data.data.selected_colors);
          // setSelectedCoverLists(response.data.data.selected_cover_lists);
          // setSelectedCoverTypes(response.data.data.selected_cover_types);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const onChangeStatus = rowId => {};

  const onTableChange = (oke, newo) => {
    getDatatable(categoryId, newo.page, newo.sizePerPage, newo.searchText);
  };

  const handleCheckboxInput = e => {
    e.persist();
    const value = parseInt(e.target.value);
    const name = e.target.name;

    if (name === 'color_options') {
      const isIdExist = selectedColors.map(s => s.id).includes(value);
      if (isIdExist) {
        setSelectedColors(selectedColors.filter(color => color.id !== value));
      } else {
        setSelectedColors([
          ...selectedColors,
          {
            id: parseInt(e.target.value)
          }
        ]);
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const data = {
        colors: selectedColors.map(color => ({ id: color.id })),
        cover_types: coverTypeOptions.map(type => ({ id: type.id })),
        cover_lists: coverListOptions.map(list => ({ id: list.id }))
      };

      const response = await CoverImageService.updateColor(categoryId, data);

      if (response.status === 200) {
        getDatatable(categoryId, page, sizePerPage, filter);
        Swal.fire({
          icon: 'success',
          title: `Pilihan warna berhasil diperbarui`
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = row => {
    setIsCoverImageModalOpen(true);
    setRowOpen(row);
  };

  return (
    <>
      <SimpleHeader name="Gambar Cover" parentName="Master Data" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label for="cover_id">Pilih Cover</Label>
                        <Input type="select" name="select" id="cover_id" onChange={handleCoverChange}>
                          <option value="">--- Pilih Kategori Cover ---</option>
                          {categories.map((category, i) => (
                            <option key={i} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {color_options.length > 0 && (
                      <Col lg={12}>
                        <>
                          <FormGroup>
                            <Label>Pilihan Warna</Label>
                            <Row>
                              {(() => {
                                function chunkArray(myArray, chunk_size) {
                                  let index = 0;
                                  let arrayLength = myArray.length;
                                  let tempArray = [];

                                  for (index = 0; index < arrayLength; index += chunk_size) {
                                    let myChunk = myArray.slice(index, index + chunk_size);
                                    // Do something if you want with the group
                                    tempArray.push(myChunk);
                                  }

                                  return tempArray;
                                }

                                const maxRowPerColumn = Math.round(color_options.length / 4);

                                const splitedArray = chunkArray(color_options, maxRowPerColumn);

                                return splitedArray.map((color, i) => (
                                  <Col key={i} md={3} xs={6}>
                                    {color.map((color, i) => (
                                      <CustomInput
                                        key={i}
                                        checked={selectedColors.map(s => s.id).includes(color.id)}
                                        id={`color-options-${color.id}`}
                                        onChange={handleCheckboxInput}
                                        name="color_options"
                                        type="checkbox"
                                        value={color.id}
                                        label={color.name}
                                      />
                                    ))}
                                  </Col>
                                ));
                              })()}
                            </Row>
                          </FormGroup>
                        </>
                      </Col>
                    )}
                  </Row>
                  {color_options.length > 0 && (
                    <Row>
                      <Col lg="12">
                        <Button style={{ float: 'right' }} color="primary">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Form>
              </CardHeader>

              <CardBody>
                {datatable.length > 0 && (
                  <ToolkitProvider
                    data={datatable}
                    keyField="i"
                    columns={[
                      {
                        dataField: 'category_name',
                        text: 'Nama Kategori',
                        sort: false
                      },
                      {
                        dataField: 'color_name',
                        text: 'Warna',
                        sort: false
                      },
                      {
                        dataField: 'cover_type_name',
                        text: 'Tipe Cover',
                        sort: false
                      },
                      {
                        dataField: 'cover_list_name',
                        text: 'List Cover',
                        sort: false
                      },
                      {
                        dataField: 'type_car_name',
                        text: 'Tipe Kendaraan',
                        sort: false
                      },
                      {
                        dataField: 'image',
                        text: 'Gambar',
                        sort: false,
                        formatter: (cell, row, rowIndex, formatExtraData) => {
                          // console.log(cell, row, rowIndex, formatExtraData);
                          return (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={cell}
                              //  onClick={e => e.preventDefault()}
                            >
                              <img
                                id={`image-${rowIndex}`}
                                alt={`warna gambar cover ${cell}`}
                                onError={() =>
                                  (document.getElementById(`image-${rowIndex}`).src =
                                    'https://coversuper.sgp1.cdn.digitaloceanspaces.com/default/product/DEFAULT.jpg')
                                }
                                width="100"
                                src={cell}
                              />
                            </a>
                          );
                        }
                      },
                      {
                        dataField: 'action',
                        text: 'Aksi',
                        sort: false,
                        isDummyField: true,
                        formatter: (cell, row, rowIndex, formatExtraData) => {
                          return (
                            <>
                              {/* <Can I="update" a="Media Partner"> */}
                              <Button color="warning" type="button" size="sm" outline onClick={() => openModal(row)}>
                                <span className="btn-inner--icon">
                                  <i className="fas fa-edit" />
                                </span>
                                <span className="btn-inner--text"> Ubah Gambar</span>
                              </Button>
                            </>
                          );
                        }
                      }
                    ]}
                    search
                  >
                    {props => {
                      return (
                        <div className="py-4 table-responsive">
                          <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                            <label>
                              Search:
                              <SearchBar
                                delay={500}
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                          <BootstrapTable
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: true
                            }}
                            loading={true}
                            {...props.baseProps}
                            bootstrap4={true}
                            pagination={paginationFactory({
                              page,
                              sizePerPage,
                              totalSize,
                              showTotal: true,
                              withFirstAndLast: true,
                              sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                <div className="dataTables_length" id="datatable-basic_length">
                                  <label>
                                    Show{' '}
                                    {
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm"
                                        onChange={e => onSizePerPageChange(e.target.value)}
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                    }{' '}
                                    entries.
                                  </label>
                                </div>
                              )
                            })}
                            bordered={false}
                            onTableChange={onTableChange}
                          />
                        </div>
                      );
                    }}
                  </ToolkitProvider>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <CoverImageModal
        isOpen={isCoverImageModalOpen}
        categoryId={categoryId}
        toggleModal={() => {
          if (isCoverImageModalOpen) {
            setRowOpen(null);
          }
          getDatatable(categoryId, page, sizePerPage, filter);
          setIsCoverImageModalOpen(!isCoverImageModalOpen);
        }}
        row={rowOpen}
      />
    </>
  );
}

export default CoverImage;
