import React from 'react';
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import TermAndCondition from '../term & condition/TermAndCondition';

const UserRegister = ({
  addSosmed,
  urls,
  modal,
  toggle,
  handleChange,
  values,
  email,
  name,
  password,
  confirmPassword,
  submitForm,
  provinceChange,
  subDistrictChange,
  cobaKec,
  provinces,
  district,
  subdistricts,
  phoneValidator,
  message,
  err,
  nameMessage,
  emailMessage,
  phoneMessage,
  identity_image_url,
  npwp_image_url,
  account_image_url,
  identity_number_url,
  community_image_url,
  onImagesValidationAccountImage,
  onImagesValidationNpwpImage,
  onImagesValidationKtpImage,
  onImagesValidationCommunity,
  validatorPassword,
  validatorConfirm,
  validatorEmail,
  validatorName,
  nikValidator,
  postalCodeValidator,
  validatorAccountName,
  accountNameMessage,
  postalMessage,
  nikMessage,
  imageRef,
  agree,
  checkboxHandler,
  handleUrlChange,
  aktif,
  checkBoxHandlerTeamYes,
  checkBoxHandlerTeamNo,
  yes,
  no
}) => {
  // Destructute
  const {
    phoneNumber,
    address,
    postal_code,
    identity_number,
    npwp,
    bank_option,
    account_number,
    account_name,
    affiliate_type_option,
    affiliate_type,
    frequency_channel,
    blog_url,
    related_person_name
  } = values;

  return (
    <Form role="form" onSubmit={submitForm}>
      <Row>
        <Col md="6">
          <FormGroup>
            {emailMessage}
            <Input
              required
              placeholder="email@email.com"
              name="email"
              type="email"
              value={email}
              onChange={validatorEmail}
            />
          </FormGroup>

          <FormGroup>
            {nameMessage}
            <Input
              required
              placeholder="Nama Lengkap"
              name="name"
              type="text"
              value={name.replace(/[^a-zA-Z]/gi, ' ')}
              onChange={validatorName}
            />
          </FormGroup>

          <FormGroup>
            {message}
            <Input
              required
              placeholder="Password"
              name="password"
              type="password"
              value={password}
              onChange={validatorPassword}
            />
          </FormGroup>

          <FormGroup>
            {err}
            <Input
              required
              placeholder="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={validatorConfirm}
            />
          </FormGroup>

          <FormGroup>
            {phoneMessage}
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>+62</InputGroupText>
              </InputGroupAddon>
              <Input
                required
                placeholder="82100xxx"
                name="phoneNumber"
                type="text"
                value={phoneNumber.replace(/[^0-9]+/gi, '')}
                onChange={phoneValidator}
              />
            </InputGroup>
          </FormGroup>

          {/* NAMA BANK */}
          <FormGroup>
            <Input required name="bank_name" type="select" onChange={handleChange}>
              <option value="">Pilih Bank</option>
              {bank_option.map(result => (
                <option value={result} key={result}>
                  {result}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            {accountNameMessage}
            <Input
              required
              placeholder="Nama Rekening"
              name="account_name"
              type="text"
              value={account_name.replace(/[^a-zA-Z]/gi, ' ')}
              onChange={validatorAccountName}
            />
          </FormGroup>

          <FormGroup>
            <Input
              required
              placeholder="No Rekening"
              name="account_number"
              type="text"
              value={account_number.replace(/[^0-9]+/gi, '')}
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup>
            <CustomInput
              id="account-image"
              required
              type="file"
              innerRef={imageRef}
              label="Upload buku tabungan"
              accept="image/*"
              onChange={onImagesValidationAccountImage}
            />
            {account_image_url && (
              <img alt="Foto Buku Tabungan" src={account_image_url} style={{ maxHeight: '100px' }} />
            )}
          </FormGroup>
        </Col>

        <Col md="6">
          {/* PROVINCE */}
          <FormGroup>
            <Input required type="select" name="province id" onChange={provinceChange}>
              <option value="">Pilih Provinsi</option>
              {provinces.map((prov, key) => (
                <option value={prov.id} key={key}>
                  {prov.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          {/* District */}
          <FormGroup>
            <Input required type="select" name="district id" onChange={subDistrictChange}>
              <option value="">Pilih Kota / Kabupaten</option>
              {district.map((result, key) => (
                <option value={result.id} key={key}>
                  {result.type === 'Kabupaten' ? 'Kab.' : result.type} {result.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          {/* Sub District */}
          <FormGroup>
            <Input required type="select" name="subdistricts_id" onChange={cobaKec}>
              <option value="">Pilih Kecamatan</option>
              {subdistricts.map((result, key) => (
                <option value={result.id} key={key}>
                  {result.name}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Input
              type="text"
              placeholder="Alamat"
              name="address"
              id="exampleText"
              value={address}
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup>
            {postalMessage}
            <Input
              required
              placeholder="Kode Pos"
              name="postal_code"
              type="text"
              value={postal_code.replace(/[^0-9]+/gi, '')}
              onChange={postalCodeValidator}
            />
          </FormGroup>

          <FormGroup>
            {nikMessage}
            <Input
              required
              placeholder="NIK"
              name="identity_number"
              type="text"
              value={identity_number.replace(/[^0-9]+/gi, '')}
              onChange={nikValidator}
            />
          </FormGroup>

          <FormGroup>
            <CustomInput
              id="identity-image"
              required
              type="file"
              innerRef={imageRef}
              accept="image/*"
              label="Upload KTP"
              onChange={onImagesValidationKtpImage}
            />
            {identity_image_url && <img alt="Foto KTP" src={identity_image_url} style={{ maxHeight: '100px' }} />}
          </FormGroup>

          <FormGroup>
            <Input placeholder="NPWP" name="npwp" type="text" value={npwp} onChange={handleChange} />
          </FormGroup>

          <FormGroup>
            <CustomInput
              id="npwp-image"
              type="file"
              innerRef={imageRef}
              accept="image/*"
              label="Upload NPWP"
              onChange={onImagesValidationNpwpImage}
            />
            {npwp_image_url && <img alt="Foto NPWP" src={npwp_image_url} style={{ maxHeight: '100px' }} />}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <FormGroup>
            <Input required name="affiliate_type" type="select" onChange={handleChange}>
              <option value="">Type Affiliate</option>
              {affiliate_type_option.map(result => (
                <option value={result} key={result}>
                  {result}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md="6">
          {affiliate_type === 'Radio' && affiliate_type !== 'Influencer / Public Figure' && (
            <FormGroup>
              <Input
                placeholder="Coversuper FM 110.1 Mhz"
                name="frequency_channel"
                type="text"
                value={frequency_channel}
                onChange={handleChange}
              />
            </FormGroup>
          )}
          {affiliate_type === 'Blogger' && affiliate_type !== 'Influencer / Public Figure' && (
            <FormGroup>
              <Input
                placeholder="Link Blog: https://blog.coversuper.com"
                name="blog_url"
                type="text"
                value={blog_url}
                onChange={handleChange}
              />
            </FormGroup>
          )}
          {affiliate_type === 'Community' && affiliate_type !== 'Influencer / Public Figure' && (
            <FormGroup>
              <CustomInput
                id="community-image"
                required
                type="file"
                innerRef={imageRef}
                label="Upload Kartu Anggota"
                accept="image/*"
                onChange={onImagesValidationCommunity}
              />
              {community_image_url && (
                <img alt="Kartu Anggota" src={community_image_url} style={{ maxHeight: '100px' }} />
              )}
            </FormGroup>
          )}
        </Col>
      </Row>

      {affiliate_type === 'Influencer / Public Figure' && (
        <>
          <Row>
            {urls.map((res, i) => (
              <>
                <Col md="6" key={i}>
                  <FormGroup>
                    <Input
                      placeholder="https://www.instagram.com/username/"
                      name="url"
                      type="text"
                      value={res.url}
                      onChange={e => {
                        handleUrlChange(e, i);
                      }}
                    />
                  </FormGroup>
                </Col>
              </>
            ))}
          </Row>

          <Row>
            <Col md="6">
              <Button disabled={aktif} type="button" color="primary" className="btn btn-sm mb-3" onClick={addSosmed}>
                + Social Media
              </Button>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col md="8">
          <FormGroup>
            <p style={{ fontSize: '13px', marginTop: '8px', marginLeft: '2px' }}>
              Apakah Anda memiliki hubungan dengan tim di internal coversuper?
            </p>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <Label className="ml-4" check>
              <Input disabled={no} bsSize="sm" type="checkbox" onChange={checkBoxHandlerTeamYes} />
              <p style={{ fontSize: '13px', marginTop: '9px' }}>Ya</p>
            </Label>
            <Label className="ml-5" check>
              <Input disabled={yes} bsSize="sm" type="checkbox" onChange={checkBoxHandlerTeamNo} />
              <p style={{ fontSize: '13px', marginTop: '9px' }}>Tidak</p>
            </Label>
          </FormGroup>
        </Col>
        <Col md="6">
          {yes && (
            <FormGroup>
              <Input
                placeholder="Isi nama yang dikenal"
                name="related_person_name"
                type="text"
                value={related_person_name}
                onChange={handleChange}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="ml-4" check>
              <Input bsSize="sm" type="checkbox" onChange={checkboxHandler} />
              <div style={{ fontSize: '13px', marginTop: '11px' }}>Saya menyetujui</div>
            </Label>
            <span className="ml-1 mb-1" type="button" onClick={toggle} style={{ cursor: 'pointer', color: 'red' }}>
              <p style={{ fontSize: '13px' }}>
                <strong>Syarat & Ketentuan</strong>
              </p>
            </span>
          </FormGroup>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
              <ul style={{ marginLeft: '280px' }}>Syarat & Ketentuan</ul>
            </ModalHeader>
            <ModalBody>
              <TermAndCondition />
            </ModalBody>
          </Modal>
        </Col>
      </Row>
      <div className="text-center">
        <Button disabled={!agree} className="my-4" color="info" type="submit">
          Sign up
        </Button>
      </div>
    </Form>
  );
};

export default UserRegister;
