import React from 'react';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CurrencyInput from '../../../helpers/CurrencyInput';

function KpiOmzetModal(props) {
  return (
    <Modal className="modal-dialog-centered" isOpen={props.isOpen} toggle={() => props.toggle()}>
      <ModalHeader>Ubah KPI Omzet</ModalHeader>

      <ModalBody>
        {props.isOpen && (
          <Form>
            <FormGroup>
              <Label>KPI Omzet</Label>
              <CurrencyInput
                type="text"
                className="form-control"
                name="kpi_omzet"
                value={props.kpi.kpi_omzet.toString()}
                onChange={e => {
                  // remove class invalid
                  e.target.classList.remove('is-invalid');
                  props.handleChange(e.target.value);
                }}
              />
            </FormGroup>
          </Form>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.handleSubmit}>
          Simpan
        </Button>{' '}
        <Button color="secondary" onClick={props.toggle}>
          Batalkan
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default KpiOmzetModal;
