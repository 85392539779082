import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { request } from '../../../config';
import ReportService from '../../../services/ReportService';
import KpiOmzetModal from './KpiOmzetModal';

function KpiOmzet(props) {
  const [data, setData] = useState([]);

  const [isKpiModalOpen, setIsKpiModalOpen] = useState(false);
  const [selectedKpi, setSelectedKpi] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await ReportService.getKpiOmzetData();
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateKpi = row => {
    setSelectedKpi(row);
    toggleModal();
  };

  const toggleModal = () => {
    setIsKpiModalOpen(!isKpiModalOpen);
  };

  const handleChange = value => {
    const omzet = Number(value.slice(3).replace(/\./g, ''));
    setSelectedKpi({
      ...selectedKpi,
      kpi_omzet: omzet
    });
  };

  const handleSubmit = async () => {
    try {
      const source = [selectedKpi];

      // replace data dengan data yang sudah diubah
      const okok = data.map(obj => source.find(o => o.id === obj.id) || obj);

      const kpi = {
        kpi_omzets: okok
      };

      const response = await request.put(`/reports/kpi-omzet`, kpi);

      if (response.status === 200) {
        Swal.fire({
          title: 'Data berhasil diperbarui',
          onClose: () => {
            setIsKpiModalOpen(false);
            setSelectedKpi(null);
            getData();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SimpleHeader name="KPI Omzet" parentName="Laporan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h3 className="mb-0">KPI Omzet</h3>
                </div>
              </CardHeader>

              <CardBody>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>KPI Omzet</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((report, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{report.name}</td>
                        <td>
                          {report.kpi_omzet.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })}
                        </td>
                        <td>
                          <Button size="sm" color="warning" onClick={() => handleUpdateKpi(report)}>
                            Ubah KPI Omzet
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <KpiOmzetModal
        isOpen={isKpiModalOpen}
        handleSubmit={handleSubmit}
        toggle={toggleModal}
        kpi={selectedKpi}
        handleChange={handleChange}
      />
    </>
  );
}

export default KpiOmzet;
