import { request } from '../config';

class ReportService {
  /**
   *
   * @param {Object} params - Params for date pick
   * @param {number} params.start_date - Date start the report
   * @param {number} params.end_date - Date end the report
   */
  static async downloadReportFinance(params) {
    const data = await request.get('/orders/export', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      params
    });
    return data;
  }

  static async getHistoryOrderOptions() {
    const res = await request.get('/order-histories/options');
    return res;
  }

  static async getKpiOmzetData() {
    const response = await request.get('/reports/kpi-omzet');
    return response;
  }

  // Get Data for Datatable
  /**
   *
   * @param {string} report - url type report to get data
   * @param {Object} params - params
   */
  static async getDatatable(report, params) {
    const { data } = await request.get(`/reports/${report}`, { params });
    return data;
  }

  /**
   * Get cs lists
   */
  static async getCsList(params) {
    const response = await request.get(`/pic`, { params });
    return response;
  }

  static async getPeriodOptions() {
    const response = await request.get('/reports/budget-daily/period-options');
    return response;
  }

  static async getAdvertiserPeriodOptions() {
    const response = await request.get('/reports/advertiser/period-options');
    return response;
  }

  static async getOrderSourceReport(params) {
    const { data } = await request.get('/reports/order-sources', { params });

    return data;
  }

  static async getDealmakerReport(params) {
    const response = await request.get('/reports/dealmaker', { params });

    return response;
  }

  static async getOrderHistoryReport(params) {
    const response = await request.get('/order-histories', { params });

    return response;
  }

  static async getDailyBudgetReport(params) {
    const response = await request.get('/reports/budget-daily', { params });

    return response;
  }

  static async getDetailBudgetDaily(date, user_id) {
    const response = await request.get(`/reports/budget-daily/detail`, {
      params: { date, user_id }
    });

    return response;
  }

  static async updateBudget(data) {
    const response = await request.put(`/reports/budget-daily/update`, data);

    return response;
  }

  static async getAdvertiserReport(params) {
    const response = await request.get('/reports/advertiser', {
      params
    });

    return response;
  }

  static async getBudgetRatioReport(params) {
    const response = await request.get('/reports/budget-ratio', {
      params
    });

    return response;
  }

  static async exportDailyBudget(params) {
    const response = await request.get('/reports/budget-daily/export', {
      params
    });

    return response;
  }

  static async exportAdvertiserReport(params) {
    const response = await request.get('/reports/advertiser/export', {
      params
    });

    return response;
  }

  static async exportBudgetRatioReport(params) {
    const response = await request.get('/reports/budget-ratio/export', {
      params
    });

    return response;
  }

  static async exportDataHistoryOrder(params) {
    const response = await request.get('/order-histories/export', {
      params
    });

    return response;
  }

  static async downloadReportDealmaker(data) {
    const response = await request.get(`/reports/dealmaker/export`, {
      params: data
    });

    return response;
  }
}

export default ReportService;
