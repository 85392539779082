import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, Container, Row, Button, CardHeader, Col } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import Swal from "react-bootstrap-sweetalert";
import AddonService from "../../../services/AddonService";
import Can from "../../../config/Can";

const { SearchBar } = Search;

class TableAddon extends React.Component {
  state = {
    alert: null,
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortField: null,
    sortOrder: undefined,
    searchText: ""
  };

  columns = [
    {
      dataField: "no",
      text: "No",
      sort: false
    },
    {
      dataField: "name",
      text: "Nama",
      sort: true
    },
    {
      dataField: "hpp",
      text: "HPP",
      sort: false,
      classes: "text-right",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return new Intl.NumberFormat("id-ID").format(row.hpp);
      }
    },
    {
      dataField: "price",
      text: "Harga",
      sort: false,
      classes: "text-right",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return new Intl.NumberFormat("id-ID").format(row.price);
      }
    },
    {
      dataField: "action",
      text: "Aksi",
      sort: false,
      isDummyField: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <Can I="update" an="Addon">
              <Button
                color="warning"
                type="button"
                size="sm"
                outline
                onClick={() => this.handleUpdate(row.id)}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-edit" />
                </span>
                <span className="btn-inner--text"> Ubah</span>
              </Button>
            </Can>
            {" "}
            <Can I="delete" an="Addon">
              <Button
                color="danger"
                type="button"
                size="sm"
                outline
                onClick={() => this.showConfirmAlert(row.id)}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-trash" />
                </span>
                <span className="btn-inner--text"> Hapus</span>
              </Button>
            </Can>
          </>
        );
      }
    }
  ];

  onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;
    this.setState(
      prevState => ({
        ...prevState,
        page,
        sizePerPage,
        sortField,
        sortOrder,
        searchText
      }),
      () => this.getData()
    );
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = this.state;
    AddonService.getDatatable(
      page,
      sizePerPage,
      sortField,
      sortOrder,
      searchText
    )
      .then(response => {
        this.setState({
          data: response.data,
          totalSize: response.recordsFiltered,
          page: response.page
        });
      })
  };

  hideAlert = () => {
    this.setState({ alert: null });
    this.getData();
  };

  showConfirmAlert = id => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Apakah anda yakin?"
          onCancel={this.hideAlert}
          onConfirm={() => this.handleDelete(id)}
          showCancel
          cancelBtnBsStyle="link"
          cancelBtnText="Batal"
          confirmBtnBsStyle="danger"
          confirmBtnText="Hapus"
          btnSize=""
          reverseButtons={false}
          showCloseButton={true}
          allowEscape={false}
          closeOnClickOutside={false}
        >
          Data tidak dapat dipulihkan
        </Swal>
      )
    });
  };

  confirmedAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Berhasil!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="primary"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil dihapus
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  handleUpdate = id => {
    this.props.history.push("/addons/edit/" + id);
  };

  handleDelete = id => {
    AddonService.remove(id)
      .then(response => {
        this.confirmedAlert();
      })
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Addon" parentName="Master Data" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Tabel Addon</h3>
                    </Col>
                    <Can I="create" an="Addon">
                      <Col className="text-right" xs="6">
                        <Link to="/addons/add">
                          <Button
                            className="btn-round btn-icon"
                            color="primary"
                            id="tooltip443412080"
                            size="sm"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">Tambah</span>
                          </Button>
                        </Link>
                      </Col>
                    </Can>
                  </Row>
                </CardHeader>

                <ToolkitProvider
                  data={this.state.data}
                  keyField="id"
                  columns={this.columns}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            delay={500}
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page: this.state.page,
                          sizePerPage: this.state.sizePerPage,
                          totalSize: this.state.totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({
                            options,
                            currSizePerPage,
                            onSizePerPageChange
                          }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{" "}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{" "}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={this.onTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default TableAddon;
