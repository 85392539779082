import React from 'react';
import { Card, CardBody, Row, Col, CardTitle } from 'reactstrap';

const CardToday = props => (
  <Card className="card-stats">
    <CardBody>
      <Row>
        <div className="col">
          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
            {props.name}
          </CardTitle>
          <span className="h2 font-weight-bold mb-0">
            {props.point ? <>{new Intl.NumberFormat('id-ID').format(props.value)}</> : <>{props.value}</>}
          </span>
        </div>
        <Col className="col-auto">
          <div className={`icon icon-shape bg-gradient-${props.gradient} text-white rounded-circle shadow`}>
            <i className={props.icon} />
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default CardToday;
