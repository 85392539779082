import React from 'react';
import { Card, Col, Progress, Table } from 'reactstrap';
import { config } from './config';

import 'moment/locale/id';

const CardTarget = props => {
  const unit = props.unit;
  const { handleSelectUnit } = props;
  const { backgroundColor, textColor } = config[unit];
  const { title, today, yesterday, thisYear } = props.data;

  return (
    <Col lg="4">
      <Card
        style={{ background: backgroundColor, borderRadius: '12px', cursor: 'pointer' }}
        onClick={() => handleSelectUnit(unit)}
      >
        <Table responsive bordered>
          <tbody style={{ verticalAlign: 'middle', textAlign: 'center', color: textColor, textTransform: 'uppercase' }}>
            <tr>
              <td colSpan="2" style={{ fontWeight: 'bolder', fontSize: '1.2em', padding: '5px' }}>
                {title}
              </td>
            </tr>

            <tr>
              <td colSpan="2" style={{ fontWeight: 'bold', color: textColor, listStyle: 'none', paddingTop: '5px' }}>
                <li className="mb-3">Goal Omzet VS Total Omzet</li>

                <div
                  className="d-flex justify-content-between"
                  style={{
                    fontSize: '18px'
                  }}
                >
                  <li>
                    {thisYear.omzet.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </li>
                  <li>
                    {thisYear.target.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </li>
                </div>
                <Progress
                  animated
                  color="success"
                  style={{
                    width: '100%',
                    height: '0.8rem'
                  }}
                  value={thisYear.achieved}
                >
                  {thisYear.achieved}%
                </Progress>
              </td>
            </tr>

            <tr>
              <td style={{ fontWeight: 'bolder', fontSize: '15px', listStyle: 'none' }}>
                Omzet Yesterday
                <li
                  style={{
                    fontSize: '10px',
                    marginBottom: '10px'
                  }}
                >
                  {yesterday.date}
                </li>
                <li>
                  {yesterday.omzet.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </li>
              </td>

              <td style={{ fontWeight: 'bolder', fontSize: '15px', listStyle: 'none' }}>
                Omzet Today{' '}
                <li
                  style={{
                    fontSize: '10px',
                    marginBottom: '10px'
                  }}
                >
                  {today.date}
                </li>
                <li>
                  {today.omzet.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </li>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Col>
  );
};

export default CardTarget;
