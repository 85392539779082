import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
// import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import AffiliateService from '../../../services/AffiliateService';
import XLSX from 'xlsx';
moment.locale('id');

const DataReportAffiliasi = () => {
  const [data, setData] = useState([]);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const [totalSize, setTotalSize] = useState(undefined);
  const [sizePerPage, setSizePerPage] = useState(10);
  // const [searchText, setSearchText] = useState('');

  const startDateRef = useRef();
  const endDateRef = useRef();

  useEffect(() => {
    getData();
  }, [page, sizePerPage]);

  const getData = async () => {
    try {
      const data = await AffiliateService.getDataLaporanAffiliate(filter, start_date, end_date, page, sizePerPage);
      setData(data.data.data);
      setPage(data.data.page);
      setLimit(data.data.limit);
      setTotalSize(data.data.recordsTotal);
    } catch (error) {
      console.log(error);
    }
  };

  const isValid = () => {
    const setRef = ref => {
      ref.current.classList.add('is-invalid');
    };

    if (!start_date || start_date === '') {
      setRef(startDateRef);
      return false;
    } else if (!end_date || end_date === '') {
      setRef(endDateRef);
      return false;
    }
    return true;
  };

  const onTableChange = (type, newState) => {
    const { page, sizePerPage } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
  };

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(data);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Affiliasi');
    // eslint-disable-next-line
    let buf = XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });

    XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });

    XLSX.writeFile(workBook, 'Laporan Affiliasi.xlsx');
  };

  return (
    <>
      <SimpleHeader name="Data Laporan Partner" parentName="Partner" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="end_date">Cari Berdasarkan</label>
                      <Input
                        id="filter"
                        type="text"
                        name="filter"
                        value={filter}
                        onChange={e => {
                          setFilter(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="start_date">Dari</label>
                      <Input
                        id="start_date"
                        type="date"
                        name="start_date"
                        max={new Date().toISOString().split('T')[0]}
                        // min={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
                        innerRef={startDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={start_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setStartDate(e.target.value);
                          setEndDate('');
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="end_date">Sampai</label>
                      <Input
                        id="end_date"
                        type="date"
                        name="end_date"
                        min={new Date(start_date ? start_date : new Date()).toISOString().split('T')[0]}
                        max={new Date().toISOString().split('T')[0]}
                        innerRef={endDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={end_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setEndDate(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => {
                        if (isValid()) {
                          // setDownloadButtonShow(true);
                          getData();
                        }
                      }}
                    >
                      Cari
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <ToolkitProvider
                  data={data}
                  keyField="id-emp"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nama Partner',
                      sort: true
                    },
                    {
                      dataField: 'impression',
                      text: 'Impression',
                      sort: true
                    },
                    {
                      dataField: 'closing',
                      text: 'Closing',
                      sort: true
                    },
                    {
                      dataField: 'omzet',
                      text: 'Omzet',
                      sort: true,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        return (
                          <>
                            {cell.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            })}
                          </>
                        );
                      }
                    }
                  ]}
                >
                  {props => {
                    return (
                      <div className="table-responsive">
                        <div className="d-flex justify-content-end align-items-end mt-1">
                          <Button color="success" onClick={() => downloadExcel()}>
                            Export Data
                          </Button>
                        </div>
                        <br />
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: page,
                            sizePerPage: limit,
                            totalSize: totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </CardBody>
            </Card>

            {/* <Card>
              <CardBody>
                <h4>Kategori Cover</h4>
                <Bar
                  data={{
                    labels: [
                      'Cover Mobil Deluxe',
                      'Cover Mobil Superior',
                      'Cover Mobil Extreme Outdoor',
                      'Green',
                      'Purple',
                      'Orange'
                    ],
                    datasets: [
                      {
                        label: '',
                        data: [22, 19, 20, 20, 22, 18],
                        backgroundColor: ['red', '#EFCA08', 'purple', 'green', 'orange', 'black'],
                        borderWidth: 1
                      }
                    ]
                  }}
                  className="chart-canvas"
                />
              </CardBody>
            </Card> */}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DataReportAffiliasi;
