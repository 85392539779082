import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import socketReducer from './socketReducer';

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  socket: socketReducer
});
