import React, { useState, useEffect, useRef } from 'react';
import iconSrc from '../../../assets/img/brand/coversuper_icon.png';
import AuthHeader from '../../../components/Headers/AuthHeader';
import RegionalService from '../../../services/RegionalService';
import AffiliateService from '../../../services/AffiliateService';
import Axios from 'axios';
import UserRegister from './form_register/UserRegister';

import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import Swal from 'sweetalert2';

const state = {
  phoneNumber: '',
  address: '',
  province_id: '',
  district_id: '',
  subdistrict_id: '',
  postal_code: '',
  identity_number: '',
  npwp: '',
  bank_option: [],
  bank_name: '',
  affiliate_type_option: ['Influencer / Public Figure', 'Radio', 'Community', 'Blogger'],
  affiliate_type: '',
  blog_url: '',
  frequency_channel: '',
  account_number: '',
  account_name: '',
  related_person_name: ''
};

const Register = () => {
  const [values, setValues] = useState(state);
  const [provinces, setProvinces] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);

  const imageRef = useRef();

  const [urls, setUrls] = useState([{ url: '' }]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // State Validation
  const [message, setMessage] = useState('');
  const [err, setErr] = useState('');
  const [nameMessage, setNameMessage] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [phoneMessage, setPhoneMessage] = useState('');
  const [nikMessage, setNikMessage] = useState('');
  const [postalMessage, setPostalMessage] = useState('');
  const [accountNameMessage, setAccountNameMessage] = useState('');

  const [agree, setAgree] = useState(false);
  const [yes, setYes] = useState(false);
  const [no, setNo] = useState(false);
  const [aktif, setAktif] = useState(false);
  const [modal, setModal] = useState(false);

  const [identity_image, setIdentity_image] = useState('');
  const [identity_image_url, setIdentity_image_url] = useState('');
  const [npwp_image, setNpwp_image] = useState('');
  const [npwp_image_url, setNpwp_image_url] = useState('');
  const [account_image, setAccount_image] = useState('');
  const [account_image_url, setAccount_image_url] = useState('');
  const [community_image, setCommunity_image] = useState('');
  const [community_image_url, setCommunity_image_url] = useState('');

  const source = Axios.CancelToken.source();

  useEffect(() => {
    getProvince();
    getDataBank();
  }, []);

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toggle = () => setModal(!modal);

  const getProvince = async () => {
    try {
      const res = await RegionalService.province({
        cancelToken: source.token
      });
      setProvinces(res.data);
    } catch (error) {}
  };

  const getDataBank = async () => {
    try {
      const res = await AffiliateService.getListBankAffiliate({
        cancelToken: source.token
      });
      setValues({ ...values, bank_option: res.data });
    } catch (error) {}
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const checkBoxHandlerTeamYes = () => {
    setYes(!yes);
  };

  const checkBoxHandlerTeamNo = () => {
    setNo(!no);
  };

  const provinceChange = e => {
    // console.log(e.target.value);
    setValues({ ...values, district_id: '', province_id: e.target.value });
    RegionalService.districtByProvince(e.target.value)
      .then(res => {
        // console.log(res);
        setDistrict(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const subDistrictChange = e => {
    // console.log(e.target.value);
    setValues({ ...values, subdistricts_id: '', district_id: e.target.value });
    RegionalService.subdistrictByDistrict(e.target.value)
      .then(res => {
        // console.log(res);
        setSubdistricts(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const cobaKec = e => {
    // console.log(e.target.value);
    setValues({ ...values, subdistrict_id: e.target.value });
  };

  // VALIDATOR PASSWORD
  const validatorPassword = e => {
    let password = e.target.value;

    let passwordValid =
      password === '' ? (
        true
      ) : password.length < 6 ? (
        <div className="text-red" style={{ fontSize: '9px' }}>
          Password minimal 6 character..!
        </div>
      ) : (
        ''
      );

    let confirmValid =
      confirmPassword === '' ? (
        true
      ) : confirmPassword !== password ? (
        <div className="text-red" style={{ fontSize: '9px' }}>
          Password harus sama..!
        </div>
      ) : (
        ''
      );

    setPassword(password);
    setConfirmPassword(confirmPassword);
    setErr(confirmValid);
    setMessage(passwordValid);
  };

  const validatorConfirm = e => {
    let confirmPassword = e.target.value;

    let confirmValid =
      confirmPassword === '' ? (
        true
      ) : confirmPassword !== password ? (
        <div className="text-red" style={{ fontSize: '9px' }}>
          Password harus sama..!
        </div>
      ) : (
        ''
      );

    setConfirmPassword(confirmPassword);
    setErr(confirmValid);
  };

  const validatorEmail = e => {
    let email = e.target.value;
    // eslint-disable-next-line
    const testEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let emailValid =
      email === '' ? (
        true
      ) : testEmail.test(email) ? (
        true
      ) : (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid email
        </div>
      );

    setEmail(email);
    setEmailMessage(emailValid);
  };

  const validatorName = e => {
    let name = e.target.value;

    let nameValid =
      name === '' ? (
        true
      ) : name.length < 3 ? (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid Name min 3 character
        </div>
      ) : (
        true
      );

    setName(name);
    setNameMessage(nameValid);
  };

  const validatorAccountName = e => {
    let accountName = e.target.value;

    let accountNameValid =
      accountName === '' ? (
        true
      ) : accountName.length < 3 ? (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid Account Name
        </div>
      ) : (
        true
      );

    setValues({ ...values, account_name: accountName });
    setAccountNameMessage(accountNameValid);
  };

  const phoneValidator = e => {
    const phone = e.target.value;
    // eslint-disable-next-line
    const testPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    let phoneValid =
      phone === '' ? (
        true
      ) : testPhone.test(phone) ? (
        true
      ) : (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid phone number
        </div>
      );

    setValues({ ...values, phoneNumber: phone });
    setPhoneMessage(phoneValid);
  };

  const postalCodeValidator = e => {
    const kodePos = e.target.value;
    // eslint-disable-next-line
    const testNIK = /^[0-9]{5}$/;

    let kodePosvalid =
      kodePos === '' ? (
        true
      ) : testNIK.test(kodePos) ? (
        true
      ) : (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid Postal Code
        </div>
      );

    setValues({ ...values, postal_code: kodePos });
    setPostalMessage(kodePosvalid);
  };

  const nikValidator = e => {
    const nik = e.target.value;
    // eslint-disable-next-line
    const testNIK = /^[0-9]{16}$/;

    let nikValid =
      nik === '' ? (
        true
      ) : testNIK.test(nik) ? (
        true
      ) : (
        <div className="text-red" style={{ fontSize: '9px' }}>
          *Please enter a valid Identity Number
        </div>
      );

    setValues({ ...values, identity_number: nik });
    setNikMessage(nikValid);
  };

  // VALIDATOR IMAGE KTP
  const onImagesValidationKtpImage = e => {
    const files = e.target.files;
    console.log(files);

    try {
      if (e.target.files.length > 5) throw new Error('Select 5 Images Only');

      for (var i = 0; i < files.length; i++) {
        if (Math.round(files[i].size / 1000) >= 15000) throw new Error('File size lebih dari ' + 15 + 'MB');
      }
      setIdentity_image(files);
      setIdentity_image_url(URL.createObjectURL(files[0]));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      });
    }
  };

  // VALIDATOR NPWP IMAGE
  const onImagesValidationNpwpImage = e => {
    const files = e.target.files;

    try {
      if (e.target.files.length > 5) throw new Error('Select 5 Images Only');

      for (var i = 0; i < files.length; i++) {
        if (Math.round(files[i].size / 1000) >= 15000) throw new Error('File size lebih dari ' + 15 + 'MB');
      }

      setNpwp_image(files);
      setNpwp_image_url(URL.createObjectURL(files[0]));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      });
    }
  };

  // VALIDATOR IMAGE Account
  const onImagesValidationAccountImage = e => {
    const files = e.target.files;

    try {
      if (e.target.files.length > 5) throw new Error('Select 5 Images Only');

      for (var i = 0; i < files.length; i++) {
        if (Math.round(files[i].size / 1000) >= 15000) throw new Error('File size lebih dari ' + 15 + 'MB');
      }

      setAccount_image(files);
      setAccount_image_url(URL.createObjectURL(files[0]));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      });
    }
  };

  // VALIDATOR IMAGE COMMUNITY
  const onImagesValidationCommunity = e => {
    const files = e.target.files;

    try {
      if (e.target.files.length > 5) throw new Error('Select 5 Images Only');

      for (var i = 0; i < files.length; i++) {
        if (Math.round(files[i].size / 1000) >= 15000) throw new Error('File size lebih dari ' + 15 + 'MB');
      }

      setCommunity_image(files);
      setCommunity_image_url(URL.createObjectURL(files[0]));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      });
    }
  };

  const handleUrlChange = (e, i) => {
    setUrls(
      urls.map((v, index) => {
        console.log(index);
        if (index === i) {
          v.url = e.target.value;
        }
        return v;
      })
    );

    console.log(urls, e.target.value, i);
  };

  const addSosmed = () => {
    // console.log(urls.length)
    if (urls.length < 5) {
      setUrls([...urls, { url: '' }]);
    } else {
      setAktif(!aktif);
    }
  };

  const submitForm = e => {
    e.preventDefault();

    const {
      phoneNumber,
      address,
      province_id,
      district_id,
      subdistrict_id,
      postal_code,
      identity_number,
      npwp,
      bank_name,
      account_number,
      account_name,
      affiliate_type,
      blog_url,
      frequency_channel,
      related_person_name
    } = values;

    let userRegister = new FormData();

    userRegister.append('email', email);
    userRegister.append('name', name);
    userRegister.append('password', password);
    userRegister.append('password_confirmation', confirmPassword);
    userRegister.append('phone', `+62${phoneNumber}`);
    userRegister.append('address', address);
    userRegister.append('province_id', province_id);
    userRegister.append('district_id', district_id);
    userRegister.append('subdistrict_id', subdistrict_id);
    userRegister.append('postal_code', postal_code);

    userRegister.append('identity_number', identity_number);
    // eslint-disable-next-line
    for (var i = 0; i < identity_image.length; i++) {
      userRegister.append('identity_image', identity_image[i]);
    }

    userRegister.append('npwp', npwp);
    // eslint-disable-next-line
    for (var i = 0; i < npwp_image.length; i++) {
      userRegister.append('npwp_image', npwp_image[i]);
    }

    userRegister.append('bank_name', bank_name);
    userRegister.append('account_number', account_number);

    userRegister.append('account_name', account_name);
    // eslint-disable-next-line
    for (var i = 0; i < account_image.length; i++) {
      userRegister.append('account_image', account_image[i]);
    }

    // eslint-disable-next-line
    for (var i = 0; i < community_image.length; i++) {
      userRegister.append('community_image', community_image[i]);
    }

    userRegister.append('affiliate_type', affiliate_type);
    userRegister.append(
      'social_media_url',
      urls.map(res => res.url)
    );
    userRegister.append('blog_url', blog_url);
    userRegister.append('frequency_channel', frequency_channel);
    userRegister.append('related_person_name', related_person_name);

    Axios.post(`${process.env.REACT_APP_API_URL}/register`, userRegister)
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'Pendaftaran Berhasil',
            showConfirmButton: false,
            timer: 1500
          });
        }
        window.location.href = '/login';
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'Pendaftaran Gagal...',
          text: err.response ? err.response.data.message : err.message
        });
      });
  };

  return (
    <>
      <AuthHeader />
      <Container className="mt--9 pb-5">
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center">
                  <br />
                  <img width="25%" src={iconSrc} alt="..." />
                  <br />
                  <br />
                  <h1>Form Pendaftaran Affiliate Partner</h1>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <UserRegister
                  aktif={aktif}
                  handleUrlChange={handleUrlChange}
                  urls={urls}
                  addSosmed={addSosmed}
                  validatorPassword={validatorPassword}
                  validatorConfirm={validatorConfirm}
                  validatorEmail={validatorEmail}
                  validatorName={validatorName}
                  nikValidator={nikValidator}
                  phoneValidator={phoneValidator}
                  postalCodeValidator={postalCodeValidator}
                  validatorAccountName={validatorAccountName}
                  email={email}
                  name={name}
                  password={password}
                  confirmPassword={confirmPassword}
                  handleChange={handleChange}
                  provinceChange={provinceChange}
                  values={values}
                  setValues={setValues}
                  subDistrictChange={subDistrictChange}
                  submitForm={submitForm}
                  provinces={provinces}
                  district={district}
                  subdistricts={subdistricts}
                  message={message}
                  err={err}
                  nameMessage={nameMessage}
                  emailMessage={emailMessage}
                  phoneMessage={phoneMessage}
                  nikMessage={nikMessage}
                  postalMessage={postalMessage}
                  accountNameMessage={accountNameMessage}
                  onImagesValidationAccountImage={onImagesValidationAccountImage}
                  onImagesValidationNpwpImage={onImagesValidationNpwpImage}
                  onImagesValidationKtpImage={onImagesValidationKtpImage}
                  onImagesValidationCommunity={onImagesValidationCommunity}
                  cobaKec={cobaKec}
                  identity_image={identity_image}
                  npwp_image={npwp_image}
                  account_image={account_image}
                  imageRef={imageRef}
                  account_image_url={account_image_url}
                  identity_image_url={identity_image_url}
                  npwp_image_url={npwp_image_url}
                  community_image_url={community_image_url}
                  checkboxHandler={checkboxHandler}
                  checkBoxHandlerTeamYes={checkBoxHandlerTeamYes}
                  checkBoxHandlerTeamNo={checkBoxHandlerTeamNo}
                  agree={agree}
                  yes={yes}
                  no={no}
                  toggle={toggle}
                  modal={modal}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Register;
