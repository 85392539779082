import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import AffiliateVoucherService from '../../../../services/AffiliateVoucherService';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import { Container, Card, CardBody, Form, Row, Col, Label, Input, Button, FormGroup, Table } from 'reactstrap';
import CurrencyInput from '../../../../helpers/CurrencyInput';

function EditAffiliateVoucher(props) {
  const [id] = useState(props.match.params.id);
  const [code, setCode] = useState('');
  const [stock, setStock] = useState(0);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [terms, setTerms] = useState([]);

  const codeRef = useRef();
  const stockRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();

  useEffect(() => {
    const source = Axios.CancelToken.source();

    getProducts();
    return () => {
      source.cancel();
    };
  }, []);

  async function getData(products) {
    try {
      const response = await AffiliateVoucherService.detail(id);
      if (response.status === 200) {
        const data = response.data;
        setCode(data.code);
        setStock(data.stock);
        setStartDate(data.start_date);
        setEndDate(data.end_date);

        setTerms(
          products.map(product => {
            for (let term of data.terms) {
              if (product.category_id === term.category_id) {
                product.discount = term.discount;
              }
            }
            return product;
          })
        );
      }
    } catch (error) {
      Swal.fire({
        title: 'Terjadi kesalahan saat mengambil data',
        icon: 'error',
        text: error.response.message
      });
      console.log(error);
    }
  }

  async function getProducts() {
    try {
      const response = await AffiliateVoucherService.getProducts();
      if (response.status === 200) {
        let categories = response.data.data;

        categories = categories.map(c => ({
          category_id: c.category_id,
          name: c.name,
          commission: c.commission
        }));

        getData(categories);
      }
    } catch (error) {
      Swal.fire({
        title: 'terjadi kesalahan saat mengambil data cs',
        icon: 'error',
        text: error.response.message
      });
      console.log(error);
    }
  }

  function formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const setDiscount = (index, max, e) => {
    let value = Number(e.target.value.slice(3).replace(/\./g, ''));
    if (value >= max) {
      Swal.fire('Terjadi Kesalahan', 'Nominal Diskon harus lebih kecil dari komisi', 'error');
      let tmpTerms = terms;
      tmpTerms[index].discount = 0;
      e.target.value = ''.toString();
      const elemen = document.getElementById(`discount_${index}`);
      elemen.classList.add('is-invalid');

      setTerms(tmpTerms);
    } else {
      let tmpTerms = terms;
      tmpTerms[index].discount = value;

      setTerms(tmpTerms);
    }
  };

  function isValid() {
    if (code === '' || code === 0 || code.length < 3) {
      const ref = codeRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (stock === '' || stock === 0) {
      const ref = stockRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (start_date === '' || start_date === 0) {
      const ref = startDateRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (end_date === '' || end_date === 0) {
      const ref = endDateRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (terms.map(term => term.discount).some(term => term === '' || !term)) {
      let valid = false;
      for (let t of terms) {
        if (Number(t.discount) > 0) {
          valid = true;
          break;
        }
      }
      if (!valid) {
        terms.forEach((term, i) => {
          const elemen = document.getElementById(`discount_${i}`);
          elemen.classList.add('is-invalid');
          elemen.focus();
        });
        Swal.fire({
          title: 'Terjadi kesalahan',
          text: 'Diskon tidak boleh kosong semua',
          icon: 'error'
        });
        return false;
      }
    }

    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    const data = {
      code,
      stock,
      start_date,
      end_date,
      terms: terms.map(term => ({
        category_id: term.category_id,
        discount: term.discount || 0
      }))
    };

    if (isValid()) {
      try {
        const voucher = await AffiliateVoucherService.edit(id, data);
        if (voucher && voucher.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push('/affiliate/vouchers')
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error.message
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Tambah Voucher" parentName="Tabel Voucher" parentLink="/affiliate/vouchers" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={onSubmit}>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="code">
                        Kode Voucher
                      </Label>
                      <Input
                        id="code"
                        placeholder="Minimal 3 Karakter"
                        name="code"
                        value={code}
                        max="20"
                        min="3"
                        innerRef={codeRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setCode(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="stock">
                        Stok Voucher
                      </Label>
                      <Input
                        id="stock"
                        placeholder="Stok Voucher"
                        min="0"
                        type="number"
                        innerRef={stockRef}
                        value={stock}
                        name="stock"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setStock(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="start_date">
                        Berlaku Dari
                      </Label>
                      <Input
                        id="start_date"
                        min={new Date().toISOString().split('T')[0]}
                        type="date"
                        name="start_date"
                        innerRef={startDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={start_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setStartDate(formatDate(e.target.value));
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="end_date">
                        Sampai
                      </Label>
                      <Input
                        id="end_date"
                        type="date"
                        min={new Date().toISOString().split('T')[0]}
                        name="end_date"
                        innerRef={endDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={end_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setEndDate(formatDate(e.target.value));
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <div>
                      <FormGroup>
                        <Label className="form-control-label" htmlFor="terms">
                          Ketentuan
                        </Label>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Produk</th>
                              <th>Komisi</th>
                              <th>Diskon</th>
                            </tr>
                          </thead>
                          <tbody>
                            {terms.length > 0 ? (
                              terms.map((term, i) => (
                                <tr key={i}>
                                  <td>
                                    <strong>{term.name}</strong>
                                  </td>
                                  <td>Rp {new Intl.NumberFormat('id-ID').format(term.commission)}</td>
                                  <td>
                                    <CurrencyInput
                                      id={`discount_${i}`}
                                      type="text"
                                      className="form-control"
                                      name="discount"
                                      placeholder={'Rp 10.000'}
                                      value={term.discount.toString()}
                                      onFocus={e => []}
                                      onChange={e => {
                                        e.target.classList.remove('is-invalid');
                                        setDiscount(i, term.commission, e);
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={4}>Tidak ada ketentuan</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button color="primary" className="btn" type="submit">
                    Simpan
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default EditAffiliateVoucher;
