import React from 'react';
import Swal from 'react-bootstrap-sweetalert';
import { Button, Card, CardBody, Col, Container, Form, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { ROLE_CS, ROLE_CS_EMIRATE, ROLE_CS_SUPERVISOR, ROLE_FINANCE } from '../../../constants';
import RoleService from '../../../services/RoleService';
import UserService from '../../../services/UserService';

class AddUser extends React.Component {
  state = {
    roles: [],
    user: {
      name: '',
      email: '',
      password: '',
      roleId: '',
      is_active: true,
      nameState: '',
      emailState: '',
      passwordState: '',
      roleIdState: '',
      csLevelId: '',
      csLevelIdState: 'valid',
      teamId: '',
      unitId: '',
      teamIdState: 'valid'
    },
    units: [
      { id: 1, name: 'Great' },
      { id: 2, name: 'Excellent' },
      { id: 3, name: 'Force' }
    ],
    csLevelList: [],
    teamList: [],
    teamCsEmirateList: ['jok', 'karpet'],
    alert: null
  };

  hideAlert = (isRedirect = true) => {
    this.setState({ alert: null }, () => this.props.history.push('/users'));
  };

  errorAlert = (message, isRedirect = true) => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Terjadi Kesalahan"
          onConfirm={() => this.hideAlert(isRedirect)}
          onCancel={() => this.hideAlert(isRedirect)}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </Swal>
      )
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil ditambahkan
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  validateUserForm = () => {
    let newState = this.state.user;
    newState.nameState = newState.name === '' ? 'invalid' : 'valid';
    newState.emailState = newState.email === '' ? 'invalid' : 'valid';
    newState.roleIdState = newState.roleId === '' ? 'invalid' : 'valid';
    newState.passwordState = newState.password.length < 4 ? 'invalid' : 'valid';

    this.setState({ user: newState });
    if (
      newState.nameState === 'valid' &&
      newState.emailState === 'valid' &&
      newState.passwordState === 'valid' &&
      newState.roleIdState === 'valid'
    ) {
      return true;
    } else {
      return false;
    }
  };

  userForm = (e, stateName) => {
    let newState = this.state.user;
    newState[stateName] = e.target.value;

    if (stateName === 'password') {
      newState[stateName + 'State'] = e.target.value.length < 4 ? 'invalid' : 'valid';
    } else {
      newState[stateName + 'State'] = e.target.value === '' ? 'invalid' : 'valid';
    }

    if (stateName === 'roleId' && parseInt(e.target.value) === ROLE_CS) {
      this.getCsLevel();
      this.getTeamList();
    }

    this.setState({ user: newState });
  };

  getCsLevel = async () => {
    try {
      const response = await UserService.getCsLevel();

      if (response.status === 200) {
        this.setState({ csLevelList: response.data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getTeamList = async () => {
    try {
      const response = await UserService.teamList();

      if (response.status === 200) {
        this.setState({ teamList: response.data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  componentDidMount() {
    RoleService.list().then(res => {
      this.setState({ roles: res.data.data });
    });
  }

  handleToggleStatus = () => {
    this.setState(prevState => {
      const newState = prevState.user;
      newState['is_active'] = !prevState.user.is_active;
      return { user: newState };
    });
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.validateUserForm()) {
      const payload = {
        name: this.state.user.name,
        email: this.state.user.email,
        password: this.state.user.password,
        role_id: this.state.user.roleId,
        is_active: this.state.user.is_active,
        cs_level_id: this.state.user.csLevelId,
        team_id: this.state.user.teamId,
        unit_id: parseInt(this.state.user.roleId) === ROLE_CS ? this.state.user.teamId : this.state.user.unitId
      };
      UserService.save(payload).then(() => {
        this.successAlert();
      });
    }
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Tambah Pengguna" parentName="Pengguna" parentLink="/user" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      // noValidate
                      onSubmit={this.onSubmit}
                    >
                      <Row>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label" htmlFor="validationCustom01">
                            Nama
                          </label>
                          <Input
                            defaultValue=""
                            required
                            id="validationCustom01"
                            placeholder="Nama"
                            type="text"
                            name="name"
                            valid={this.state.user.nameState === 'valid'}
                            invalid={this.state.user.nameState === 'invalid'}
                            onChange={e => this.userForm(e, 'name')}
                          />
                          <div className="invalid-feedback">Nama tidak boleh kosong!</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label" htmlFor="validationEmail">
                            Email
                          </label>
                          <Input
                            defaultValue=""
                            required
                            id="validationEmail"
                            placeholder="Email"
                            type="email"
                            name="email"
                            valid={this.state.user.emailState === 'valid'}
                            invalid={this.state.user.emailState === 'invalid'}
                            onChange={e => this.userForm(e, 'email')}
                          />
                          <div className="invalid-feedback">Email tidak boleh kosong!</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label" htmlFor="validationEmail">
                            Password
                          </label>
                          <Input
                            defaultValue=""
                            required
                            id="validationPassword"
                            placeholder="Password"
                            type="password"
                            name="password"
                            valid={this.state.user.passwordState === 'valid'}
                            invalid={this.state.user.passwordState === 'invalid'}
                            onChange={e => this.userForm(e, 'password')}
                          />
                          <div className="invalid-feedback">Password minimal 4 karakter!</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label" htmlFor="validationRole">
                            Sebagai
                          </label>
                          <Input
                            defaultValue=""
                            required
                            id="validationRole"
                            placeholder=""
                            type="select"
                            name="roleId"
                            valid={this.state.user.roleIdState === 'valid'}
                            invalid={this.state.user.roleIdState === 'invalid'}
                            onChange={e => this.userForm(e, 'roleId')}
                          >
                            <option value=""> -- Pilih --</option>
                            {this.state.roles.map((role, key) => (
                              <option key={key} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </Input>
                          <div className="invalid-feedback">Data tidak boleh kosong!</div>
                        </Col>
                      </Row>

                      {parseInt(this.state.user.roleId) === ROLE_CS && (
                        <>
                          <Row>
                            <Col className="mb-3" md="4">
                              <label className="form-control-label" htmlFor="validationCSLevel">
                                CS Level
                              </label>
                              <Input
                                defaultValue=""
                                required
                                id="validationCSLevel"
                                placeholder=""
                                type="select"
                                name="cs_level_id"
                                valid={this.state.user.csLevelIdState === 'valid'}
                                invalid={this.state.user.csLevelIdState === 'invalid'}
                                onChange={e => this.userForm(e, 'csLevelId')}
                              >
                                <option value="">-- Pilih --</option>
                                {this.state.csLevelList.map((level, key) => (
                                  <option key={key} value={level.id}>
                                    {level.name}
                                  </option>
                                ))}
                              </Input>
                              <div className="invalid-feedback">Data tidak boleh kosong!</div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mb-3" md="4">
                              <label className="form-control-label" htmlFor="validationTeam">
                                Team
                              </label>
                              <Input
                                defaultValue=""
                                required
                                id="validationTeam"
                                placeholder=""
                                type="select"
                                name="team_id"
                                valid={this.state.user.teamIdState === 'valid'}
                                invalid={this.state.user.teamIdState === 'invalid'}
                                onChange={e => this.userForm(e, 'teamId')}
                              >
                                <option value="">-- Pilih --</option>
                                {this.state.teamList.map((team, key) => (
                                  <option key={key} value={team.id}>
                                    {team.name}
                                  </option>
                                ))}
                              </Input>
                              <div className="invalid-feedback">Data tidak boleh kosong!</div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {[ROLE_FINANCE, ROLE_CS_SUPERVISOR].includes(parseInt(this.state.user.roleId)) && (
                        <>
                          <Row>
                            <Col className="mb-3" md="4">
                              <label className="form-control-label" htmlFor="validationUnit">
                                Unit
                              </label>
                              <Input
                                defaultValue=""
                                id="validationUnit"
                                placeholder=""
                                type="select"
                                name="unit_id"
                                valid={this.state.user.unitIdState === 'valid'}
                                invalid={this.state.user.unitIdState === 'invalid'}
                                onChange={e => this.userForm(e, 'unitId')}
                              >
                                <option value="">-- Pilih --</option>
                                {this.state.units.map((unit, key) => (
                                  <option key={key} value={unit.id}>
                                    {unit.name}
                                  </option>
                                ))}
                              </Input>
                              <div className="invalid-feedback">Data tidak boleh kosong!</div>
                            </Col>
                          </Row>
                        </>
                      )}
                      {parseInt(this.state.user.roleId) === ROLE_CS_EMIRATE && (
                        <Row>
                          <Col className="mb-3" md="4">
                            <label className="form-control-label" htmlFor="validationTeam">
                              Team
                            </label>
                            <Input
                              defaultValue=""
                              required
                              id="validationTeam"
                              placeholder=""
                              type="select"
                              name="team_id"
                              valid={this.state.user.teamIdState === 'valid'}
                              invalid={this.state.user.teamIdState === 'invalid'}
                              onChange={e => this.userForm(e, 'teamId')}
                            >
                              <option value="">-- Pilih --</option>
                              {this.state.teamCsEmirateList.map((team, key) => (
                                <option key={key} value={team}>
                                  {team}
                                </option>
                              ))}
                            </Input>
                            <div className="invalid-feedback">Data tidak boleh kosong!</div>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label" htmlFor="validationStatus">
                            Status Aktif
                          </label>
                          <br />
                          <label className="custom-toggle">
                            <Input
                              defaultChecked={this.state.user.is_active}
                              type="checkbox"
                              name="is_active"
                              onChange={e => this.handleToggleStatus()}
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </Col>
                      </Row>

                      <Button color="primary" type="submit">
                        Simpan
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddUser;
