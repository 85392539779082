import React, { useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Col, Input, ModalFooter, Button } from 'reactstrap';
import OrderService from '../../../../services/OrderService';
import Swal from 'sweetalert2';
import FlowService from '../../../../services/FlowService';
import CurrencyInput from '../../../../helpers/CurrencyInput';

function ResiModal(props) {
  const [resi, setResi] = useState('');
  const [imageURL, setimageURL] = useState('');
  const [realOngkir, setRealOngkir] = useState('');
  const [shippingDate, setShippingDate] = useState('');
  const [loading, setLoading] = useState(false);

  const resiImageRef = useRef();
  const resiRef = useRef();
  const realOngkirRef = useRef();
  const shippingDateRef = useRef();

  const handleImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    if (e.target.files.length > 0) {
      const formData = new FormData();
      for (const file of e.target.files) {
        formData.append('file', file);
      }

      try {
        setLoading(true);
        const image = await OrderService.resiUpload(formData);

        if (image.status === 200) {
          setimageURL(image.data.data[0]);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          text: error.response.message
        });
      }
    }
  };

  const isValid = () => {
    if (resi === '' || !resi) {
      setNotValid(resiRef.current);
    } else if (shippingDate === '' || !shippingDate) {
      setNotValid(shippingDateRef.current);
    } else {
      return true;
    }
  };

  function setNotValid(current) {
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  const resetDataState = () => {
    setResi('');
    setimageURL('');
    setRealOngkir('');
    setShippingDate('');
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      if (isValid()) {
        const resiObj = {
          order_detail_id: props.row.id,
          status_type_id: props.row.status.pos_target.id,
          description: resi,
          real_shipping_cost: realOngkir,
          shipping_date: shippingDate,
          resi_image: imageURL
        };

        const response = await FlowService.forward(resiObj);
        let resep = response ? response.data : null;
        if (resep && resep.status === 200) {
          props.confirmedActionAlert();
        }
        resetDataState();
        toggleResiModal();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'terjadi kesalahan',
        text: error.message
      });
    }
  };

  function toggleResiModal() {
    setLoading(false);
    setimageURL('');
    setResi('');

    props.toggleModal(props.row, 'isResiModalShow');
  }

  async function handleRemoveImage(image) {
    try {
      const oke = await OrderService.removeImage(image);

      if (oke.status === 200) {
        setimageURL('');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'terjadi kesalahan',
        text: error.message
      });
    }
  }

  return (
    <Modal
      isOpen={props.isResiModalShow}
      fade={false}
      backdrop="static"
      centered
      toggle={toggleResiModal}
      // style={{ maxWidth: '70%' }}
    >
      <ModalHeader toggle={toggleResiModal}>Input Resi</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label sm={4} for="resi">
              Nomor Resi
              <sup>
                <span className="text-red">*</span>
              </sup>
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="resi"
                innerRef={resiRef}
                id="resi"
                placeholder="Nomor Resi"
                onChange={e => {
                  e.target.classList.remove('is-invalid');
                  setResi(e.target.value);
                }}
                value={resi}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} for="resi">
              Real Ongkir
              {/* <sup>
                <span className="text-red">*</span>
              </sup> */}
            </Label>
            <Col sm={8}>
              <CurrencyInput
                type="text"
                name="realOngkir"
                className="form-control"
                value={realOngkir.toString()}
                ref={realOngkirRef}
                placeholder="Real Ongkir"
                onChange={e => {
                  e.target.classList.remove('is-invalid');
                  const value = e.target.value;
                  setRealOngkir(Number(value.slice(3).replace(/\./g, '')));
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} for="resi">
              Tanggal Resi Kirim
              <sup>
                <span className="text-red">*</span>
              </sup>
            </Label>
            <Col sm={8}>
              <Input
                type="date"
                name="shippingDate"
                innerRef={shippingDateRef}
                onKeyDown={e => e.preventDefault()}
                onWheel={e => false}
                max={new Date().toISOString().split('T')[0]}
                // min={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                value={shippingDate}
                onChange={e => {
                  // remove class invalid
                  e.target.classList.remove('is-invalid');
                  setShippingDate(e.target.value);
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4} for="resi_images">
              Foto Resi
            </Label>
            <Col sm={8}>
              <div className="custom-file">
                <input
                  className="custom-file-input"
                  id="customFileLang"
                  lang="en"
                  accept="image/*"
                  ref={resiImageRef}
                  type="file"
                  onChange={handleImage}
                />
                <label className="custom-file-label" htmlFor="customFileLang">
                  Pilih file
                </label>
                <small id="emailHelp" className="form-text text-muted">
                  (opsional)
                </small>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={{ offset: 4, size: 8 }}>
              {(imageURL !== '' || imageURL) && (
                <div>
                  <img src={imageURL} alt="resi" width="180" height="200" style={{ marginRight: 5 }} />
                  <Button size="sm" color="danger" onClick={() => handleRemoveImage(imageURL)}>
                    <i className="fas fa-trash" />
                  </Button>
                </div>
              )}
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" disabled={loading} onClick={handleSubmit}>
          {loading ? (
            <>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              &nbsp; Loading...
            </>
          ) : (
            'Submit'
          )}
        </Button>{' '}
        <Button color="secondary" disabled={loading} onClick={toggleResiModal}>
          Batalkan
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ResiModal;
