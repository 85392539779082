import React from 'react';
import { Button } from 'reactstrap';

const ButtonSosmed = props => {
  return (
    <Button
      style={{
        background: props.background,
        color: props.color
      }}
      type="button"
      size="sm"
      className="mb-2 mt-2"
      // outline
      onClick={props.onClick}
    >
      <span className="btn-inner--icon">
        <i className={props.iconSosmed} />
      </span>
      <span className="btn-inner--text">{props.name}</span>
    </Button>
  );
};

export default ButtonSosmed;
