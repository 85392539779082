import React, { useState, useRef } from 'react';
import { Button, Card, CardBody, Form, Input, Container, Row, Col, Label, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import FaqService from '../../../../services/FaqService';

function AddFaqCategory(props) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const nameRef = useRef();

  function setNotValid({ current }) {
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  function isValid() {
    if (!name || name === '') {
      return setNotValid(nameRef);
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      const data = {
        name
      };

      setLoading(true);
      try {
        const response = await FaqService.insertCategory(data);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil ditambahkan',
            onClose: props.history.push('/faq-categories')
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: 'Terjadi kesalahan',
          icon: 'error',
          text: error
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Tambah Kategory FAQ" parentName="Tabel Kategory FAQ" parentLink="/faq-categories" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Nama Kategori</Label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Pembayaran"
                        innerRef={nameRef}
                        value={name}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setName(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button disabled={loading} color="primary" className="btn btn-sm" type="submit">
                    Tambah
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default AddFaqCategory;
