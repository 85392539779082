import React, { useEffect, useRef, useState } from 'react';
// nodejs library that concatenates classes
// import classnames from "classnames";
// reactstrap components
import { Button, Card, CardBody, Col, Container, CustomInput, Form, FormGroup, Input, Row } from 'reactstrap';
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader';
// sweet alert
// import Swal from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2';
// service
import CategoryService from '../../../services/CategoryService';
import ColorService from '../../../services/ColorService';
import TypeCarService from '../../../services/TypeCarService';
import UploadService from '../../../services/UploadService';

function AddCategory(props) {
  const TYPE_MERCHANDISE = '7';
  const TYPE_CS_AUTOLIFE = '8';
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [is_ready_stock, setReadyStock] = useState(false);
  const [is_published, setPublished] = useState(false);
  const [vehicle_type_id, setVehicleType] = useState('');
  const [pathName, setPathName] = useState('merchandise');

  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);

  const [colorError, setColorError] = useState(false);

  const [weight, setWeight] = useState('');
  const [hpp, setHpp] = useState('');
  const [previous_price, setPreviousPrice] = useState('');
  const [retail_price, setRetailPrice] = useState('');
  const [reseller_price, setResellerPrice] = useState('');
  const [big_seller_price, setBigSellerPrice] = useState('');

  const nameRef = useRef();
  const descriptionRef = useRef();
  const vehicleTypeRef = useRef();
  const previousPriceRef = useRef();
  const weightRef = useRef();
  const hppRef = useRef();
  const retailRef = useRef();
  const resellerRef = useRef();
  const bigSellerRef = useRef();

  const getColors = async () => {
    let colorOptions = await ColorService.list();
    setColorOptions(
      colorOptions.data.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
    );
  };

  const getVehicleTypes = async () => {
    try {
      const response = await TypeCarService.getVehicleTypes();
      if (response.status === 200) {
        setVehicleTypeOptions(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVehicleTypes();
    getColors();
  }, []);

  const handleCheckboxInput = e => {
    e.persist();
    setColorError(false);
    const value = parseInt(e.target.value);
    const name = e.target.name;

    if (name === 'color_options') {
      const isIdExist = selectedColors.map(s => s.id).includes(value);
      if (isIdExist) {
        setSelectedColors(selectedColors.filter(color => color.id !== value));
      } else {
        let c = colorOptions.find(s => s.id === parseInt(value));
        setSelectedColors([...selectedColors, { ...c, image: '' }]);
      }
    }
  };

  const isValid = () => {
    if (name === '' || name === 0) {
      const ref = nameRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (description === '') {
      const ref = descriptionRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (vehicle_type_id === '' || vehicle_type_id === 0) {
      const ref = vehicleTypeRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    }

    if ([TYPE_MERCHANDISE, TYPE_CS_AUTOLIFE].includes(vehicle_type_id)) {
      if (weight === '' || weight === 0) {
        const ref = weightRef.current;
        ref.classList.add('is-invalid');
        ref.focus();
        return false;
      } else if (hpp === '' || hpp === 0) {
        const ref = hppRef.current;
        ref.classList.add('is-invalid');
        ref.focus();
        return false;
      } else if (retail_price === '' || retail_price === 0) {
        const ref = retailRef.current;
        ref.classList.add('is-invalid');
        ref.focus();
        return false;
      } else if (reseller_price === '' || reseller_price === 0) {
        const ref = resellerRef.current;
        ref.classList.add('is-invalid');
        ref.focus();
        return false;
      } else if (big_seller_price === '' || big_seller_price === 0) {
        const ref = bigSellerRef.current;
        ref.classList.add('is-invalid');
        ref.focus();
        return false;
      }

      if (selectedColors.length === 0) {
        setColorError(true);
        return false;
      } else {
        setColorError(false);
      }
    }

    return true;
  };

  const slugify = text => {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeeiiiiooooouuuunc------';

    const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));

    return newText
      .toString() // Cast to string
      .toLowerCase() // Convert the string to lowercase letters
      .trim() // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/&/g, '-y-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-'); // Replace multiple - with single -
  };

  const onSubmit = e => {
    e.preventDefault();

    if (isValid()) {
      const common = {
        colors: selectedColors,
        weight,
        hpp,
        retail_price,
        reseller_price,
        big_seller_price
      };

      const defaultData = {
        name,
        description,
        vehicle_type_id,
        is_published,
        is_ready_stock,
        previous_price
      };

      let data = defaultData;

      if ([TYPE_MERCHANDISE, TYPE_CS_AUTOLIFE].includes(vehicle_type_id)) {
        data = { ...defaultData, ...common };
      }

      try {
        CategoryService.save(data).then(response => {
          console.log(response);
          if (response.status === 200)
            Swal.fire({
              title: 'Berhasil disimpan',
              icon: 'success',
              onClose: () => {
                props.history.push('/categories');
              }
            });
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleImage = async (e, color_id) => {
    e.preventDefault();
    e.persist();

    const item = e.target.files[0];

    const formData = new FormData();
    formData.append('file', item);

    try {
      setLoading(true);

      const image = await UploadService.commonImage(formData, slugify(pathName));
      if (image.status === 200 && image.data) {
        // const currentColor = selectedColors.find(color => color.id === color_id);
        // const another = selectedColors.filter(color => color.id !== color_id);
        const withImage = selectedColors.map(s => {
          if (s.id === color_id) {
            s.image = image.data[0];
          }
          return s;
        });

        setSelectedColors(withImage);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error, error.message);
    }
  };

  const renderColors = () => {
    function chunkArray(myArray, chunk_size) {
      let index = 0;
      let arrayLength = myArray.length;
      let tempArray = [];

      for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
      }

      return tempArray;
    }

    const chunk_size = Math.ceil(colorOptions.length / 3);
    const splitedArray = chunkArray(colorOptions, chunk_size);

    return splitedArray.map((color, i) => (
      <Col key={i} md={4} xs={6}>
        {color.map((color, j) => (
          <div key={j}>
            <CustomInput
              checked={selectedColors.map(s => s.id).includes(color.id)}
              id={`color-options-${color.id}`}
              onChange={e => {
                handleCheckboxInput(e);
              }}
              name="color_options"
              type="checkbox"
              value={color.id}
              label={color.name}
            />
            <div className="custom-file" style={{ maxWidth: '100%' }}>
              <input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                type="file"
                accept="image/*"
                multiple={false}
                disabled={!selectedColors.map(s => s.id).includes(color.id)}
                onChange={e => handleImage(e, color.id)}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                Pilih Gambar
              </label>
            </div>
            {selectedColors.find(s => s.id === color.id) && selectedColors.find(s => s.id === color.id).image !== '' && (
              <div>
                <img
                  src={selectedColors.find(s => s.id === color.id).image}
                  alt={color.name}
                  style={{ maxWidth: '50%', maxHeight: '75px', marginTop: 16 }}
                />
              </div>
            )}
            <br />
          </div>
        ))}
      </Col>
    ));
  };

  return (
    <>
      <SimpleHeader name="Tambah Produk" parentName="Produk" parentLink="/categories" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <Form className="needs-validation" noValidate onSubmit={e => onSubmit(e)}>
                    <Row>
                      <Col className="mb-3" md="12">
                        <label className="form-control-label" htmlFor="name">
                          Nama Produk
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          value={name}
                          innerRef={nameRef}
                          id="name"
                          placeholder="Nama"
                          type="text"
                          name="name"
                          onChange={e => {
                            e.target.classList.remove('is-invalid');
                            setName(e.target.value);
                          }}
                        />
                        <div className="invalid-feedback">Nama wajib diisi!</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3" md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="description">
                            Deskripsi
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            required
                            id="description"
                            type="textarea"
                            name="description"
                            value={description}
                            innerRef={descriptionRef}
                            rows="10"
                            placeholder="Deskripsi"
                            onChange={e => {
                              e.target.classList.remove('is-invalid');
                              setDescription(e.target.value);
                            }}
                          />
                          <div className="invalid-feedback">Deskripsi wajib diisi!</div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="validationTypeCar">
                            Jenis Produk
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            required
                            value={vehicle_type_id}
                            innerRef={vehicleTypeRef}
                            id="validationTypeCar"
                            type="select"
                            name="type_car_id"
                            placeholder="--Jenis Produk--"
                            onChange={e => {
                              e.target.classList.remove('is-invalid');
                              const value = e.target.value;
                              if ([TYPE_MERCHANDISE, TYPE_CS_AUTOLIFE].includes(value)) {
                                setReadyStock(true);
                              }
                              setVehicleType(value);
                              let v = vehicleTypeOptions.find(e => e.id === parseInt(value));
                              if (v) setPathName(v.name);
                            }}
                          >
                            <option value="">--Pilih Jenis Produk--</option>
                            {vehicleTypeOptions.map((typeCar, key) => (
                              <option value={typeCar.id} key={key}>
                                {typeCar.name}
                              </option>
                            ))}
                          </Input>
                          <div className="invalid-feedback">Jenis Produk wajib diisi!</div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="inputPreviousPrice">
                            Harga Coret (jika harga jual lebih murah)
                          </label>
                          <Input
                            value={previous_price}
                            innerRef={previousPriceRef}
                            id="inputPreviousPrice"
                            placeholder="1000"
                            type="number"
                            name="previous_price"
                            onChange={e => {
                              e.target.classList.remove('is-invalid');
                              setPreviousPrice(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {[TYPE_MERCHANDISE, TYPE_CS_AUTOLIFE].includes(vehicle_type_id) && (
                      <>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="inputWeight">
                                Berat (gr)
                                <sup>
                                  <span className="text-red">*</span>
                                </sup>
                              </label>
                              <Input
                                required
                                value={weight}
                                innerRef={weightRef}
                                id="inputWeight"
                                placeholder="1000"
                                type="text"
                                name="weight"
                                onChange={e => {
                                  e.target.classList.remove('is-invalid');
                                  setWeight(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="inputHpp">
                                Hpp
                                <sup>
                                  <span className="text-red">*</span>
                                </sup>
                              </label>
                              <Input
                                required
                                value={hpp}
                                innerRef={hppRef}
                                id="inputHpp"
                                placeholder="50000"
                                type="text"
                                name="hpp"
                                onChange={e => {
                                  e.target.classList.remove('is-invalid');
                                  setHpp(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor={'retail'}>
                                Harga Retail
                                <sup>
                                  <span className="text-red">*</span>
                                </sup>
                              </label>
                              <Input
                                required
                                value={retail_price}
                                innerRef={retailRef}
                                id={'retail'}
                                placeholder="50000"
                                type="text"
                                name="price"
                                onChange={e => {
                                  e.target.classList.remove('is-invalid');
                                  setRetailPrice(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor={'reseller'}>
                                Harga Reseller
                                <sup>
                                  <span className="text-red">*</span>
                                </sup>
                              </label>
                              <Input
                                required
                                value={reseller_price}
                                innerRef={resellerRef}
                                id={'reseller'}
                                placeholder="50000"
                                type="text"
                                name="price"
                                onChange={e => {
                                  e.target.classList.remove('is-invalid');
                                  setResellerPrice(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label className="form-control-label" htmlFor={'bigseller'}>
                                Harga Agen
                                <sup>
                                  <span className="text-red">*</span>
                                </sup>
                              </label>
                              <Input
                                required
                                value={big_seller_price}
                                innerRef={bigSellerRef}
                                id={'bigseller'}
                                placeholder="50000"
                                type="text"
                                name="price"
                                onChange={e => {
                                  e.target.classList.remove('is-invalid');
                                  setBigSellerPrice(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          {colorOptions.length > 0 && (
                            <Col lg={12}>
                              <>
                                <FormGroup>
                                  <label className="form-control-label">
                                    Pilihan Warna
                                    <sup>
                                      <span className="text-red">*</span>
                                    </sup>
                                  </label>
                                  <div
                                    className="invalid-feedback"
                                    style={{
                                      display: colorError ? 'block' : 'none'
                                    }}
                                  >
                                    Pilihan Warna wajib dipilih !
                                  </div>
                                  <Row>{renderColors()}</Row>
                                </FormGroup>
                              </>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col>
                            <sup>
                              <span className="text-red">*) Wajib diisi</span>
                            </sup>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationStatus">
                          Apakah Produk Ini Ready Stok?
                        </label>
                        <br />
                        <label className="custom-toggle">
                          <Input
                            checked={is_ready_stock}
                            type="checkbox"
                            name="is_ready_stock"
                            onChange={e => {
                              if (![TYPE_MERCHANDISE, TYPE_CS_AUTOLIFE].includes(vehicle_type_id)) {
                                setReadyStock(!is_ready_stock);
                              }
                            }}
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationStatus">
                          Tampilkan di Web
                        </label>
                        <br />
                        <label className="custom-toggle">
                          <Input
                            checked={is_published}
                            type="checkbox"
                            name="is_published"
                            onChange={e => setPublished(!is_published)}
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Button color="primary" disabled={loading} type="submit">
                      Simpan
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AddCategory;
