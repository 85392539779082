import React from "react";
import { Link } from "react-router-dom";

export default () => (
  <>
    <div className="text-center p-4">
      <img src={require("../../assets/img/404.jpg")} alt="Design by Freepik" style={{ maxWidth: '100%', width: 500, height: 'auto' }} />
      <h1>
        Halaman yang Anda cari tidak ada :(
      </h1>
      <Link to="/">Kembali Ke Halaman Utama</Link>
    </div>
  </>
);
