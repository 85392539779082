import { request } from "../config";

// versi function
function RolePermissionService() {

  // Show detail
  const findByRoleId = async (id) => {
    const response = await request.get(`/role-permissions/${id}`);
    return response.data;
  }

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/role-permissions/${id}`, data);
    return response.data;
  }

  return {
    findByRoleId,
    update,
  }
}

export default RolePermissionService()