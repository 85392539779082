import { request } from '../config';

class BankService {
  /**
   * Get bank lists
   */
  static async getLists({ params } = {}) {
    const response = await request.get('/banks/list', { params });
    return response;
  }

  static async getOnlyBanks() {
    const response = await request.get('/banks/only');
    return response;
  }

  static async getPaymentChannels() {
    const res = await request.get('/payment-channels/list');
    return res;
  }

  static async getBankProviders() {
    const { data: response } = await request.get('banks/providers');
    return response;
  }

  static async insert(data) {
    const { data: response } = await request.post('banks', data);
    return response;
  }

  static async detail(id) {
    const { data: response } = await request.get(`banks/${id}`);
    return response;
  }

  static async edit(id, data) {
    const { data: response } = await request.put(`banks/${id}`, data);
    return response;
  }

  static async remove(id) {
    const { data: response } = await request.delete(`banks/${id}`);
    return response;
  }

  static async getDatatable(page, limit, sort, order, filter) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/banks', {
      params: { page, limit, sort, order, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }
}

export default BankService;
