import { request } from '../config';

// versi function
function TypeCarService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/typecars', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;

    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // list all data
  const list = async categoryId => {
    let params = null;

    if (categoryId) {
      params = {
        category_id: categoryId
      };
    }

    const response = await request.get(`/typecars/list`, {
      params
    });
    return response.data;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/typecars/${id}`);
    return response.data;
  };

  const save = async data => {
    const response = await request.post(`/typecars`, data);
    return response.data;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/typecars/${id}`, data);
    return response.data;
  };
  const findByCategoryId = async categoryId => {
    const response = await request.get(`/vouchers/type_cars/`, {
      params: {
        category_id: categoryId
      }
    });

    return response;
  };

  const remove = async id => {
    const response = await request.delete(`/typecars/${id}`);
    return response.data;
  };

  const getVehicleTypes = async () => {
    const response = await request.get('/vehicle-types/list');
    return response;
  };

  return {
    getVehicleTypes,
    getDatatable,
    list,
    findById,
    findByCategoryId,
    save,
    update,
    remove
  };
}

export default TypeCarService();
