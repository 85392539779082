import * as Constants from '../../../../constants';

export const defaultState = {
  STATUS_DP: String(Constants.PAYMENT_STATUS_DP),
  STATUS_PAID: String(Constants.PAYMENT_STATUS_PAID),
  customer_placeholder: 'Nama Pelanggan',
  customerModal: false,
  addonModal: false,
  loading: false,
  productRow: null,
  addonModalComponent: null,
  currentVariant: {},
  alert: null,
  warning: null,
  provinceOptions: [],
  districtOptions: [],
  subdistrictOptions: [],
  addonOptions: [],
  courierOptions: [],
  courierServiceOptions: [],
  customerTypeOptions: [],
  paymentStatusOptions: [],
  platformOptions: [
    { value: '', label: '-- Pilih Platform --', invoice_required: false },
    { value: 'Regular', label: 'Regular', invoice_required: false },
    { value: 'Bukalapak', label: 'Bukalapak', invoice_required: true },
    { value: 'Tokopedia', label: 'Tokopedia', invoice_required: true },
    { value: 'Shopee', label: 'Shopee', invoice_required: true },
    { value: 'Lazada', label: 'Lazada', invoice_required: true },
    { value: 'Blibli', label: 'Blibli', invoice_required: true }
  ],
  shippingAddress: {
    id: '',
    name: '',
    phone: '',
    phone_alt: '',
    email: '',
    address: '',
    province_id: '',
    district_id: '',
    subdistrict_id: '',
    postal_code: '',
    customer_type_id: ''
  },
  supplier: {
    id: '',
    name: '',
    address: '',
    province_id: '',
    district_id: '',
    subdistrict_id: ''
  },
  courier: {
    code: '',
    name: '',
    service: '',
    description: '',
    cost: 0,
    insurance: {
      percent: 0,
      flat_fee: 0,
      minimum_fee: 0
    }
  },
  newCustomer: {
    name: '',
    phone: '',
    phone_alt: '',
    email: '',
    address: '',
    province_id: '',
    district_id: '',
    subdistrict_id: '',
    postal_code: '',
    customer_type_id: ''
  },
  productVariants: [],
  platform: '',
  order_source: '',
  invoice_required: false,
  invoice_number: '',
  discount: 0,
  color_id: 0,
  year: '',
  subtotal: 0,
  additional_cost: '0',
  additional_cost_note: '',
  order_description: '',
  payment_status_id: '',
  payment_date: '',
  order_date: '',
  payment_amount: '0',
  banks: [],
  bank_id: 0
};
