import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import CustomerService from '../../../../services/CustomerService';

function DepositVerificationModal(props) {
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    props.toggle('depositVerificationModalOpen');
  };

  const handleVerification = async () => {
    setLoading(true);
    try {
      const res = await CustomerService.verifyDeposit(props.row.id);

      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Deposit Berhasil Diverifikasi',
          timer: 5000
        }).then(() => toggle());

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return props.row ? (
    <>
      <Modal isOpen={props.isOpen} toggle={toggle}>
        <ModalHeader>Verifikasi Deposit</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <div style={{ textAlign: 'center' }}>
                <img
                  onClick={() => window.open(props.row.evidence, '_blank')}
                  alt={props.row.description}
                  style={{ maxWidth: '100%' }}
                  src={props.row.evidence}
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="success" onClick={handleVerification}>
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Verifikasi'
            )}
          </Button>
          {` `}
          <Button disabled={loading} onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    ''
  );
}

export default DepositVerificationModal;
