import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardHeader, Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from '../../../components/Loader/Loader';

import { request } from '../../../config';
import AffiliateService from '../../../services/AffiliateService';
import UpdateModal from './modal/UpdateModal';
import Can from '../../../config/Can';

const UpdateProduct = () => {
    const [data, setData] = useState([]);
    const [name, setName] = useState("")
    const [dataDetail, setDataDetail] = useState([])
    const [commission, setCommission] = useState("")
    const [is_active, setIs_active] = useState(false)
    const [detailModal, setDetailModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const source = Axios.CancelToken.source();
        getData();

        return () => {
            source.cancel();
        };
    }, [data]);

    const getDataAfterUpdate = () => {
        return data
    }

    const getData = async () => {
        try {
            setLoading(true);
            const { data } = await AffiliateService.getListProductsAffiliateUpdate();
            setData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const toggelModalWithDetail = async (row) => {
        try {
            const detail = await request.get('/affiliates/products/' + row.id)

            setDetailModal(true)
            setDataDetail(detail.data.data)
            setName(detail.data.data.name)
            setCommission(detail.data.data.commission)
            setIs_active(!!detail.data.data.is_active)
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            {loading && <Loading />}
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">Data Produk</h6>{' '}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="/" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-home" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem aria-current="page" className="active">
                                        Data Produk Partner
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>


                    </div>
                </Container>
            </div>

            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="border-bottom-0">
                                <h3 className="mb-0">Produk Partner</h3>
                            </CardHeader>

                            <ToolkitProvider
                                keyField="id"
                                data={data}
                                columns={[
                                    {
                                        dataField: 'image',
                                        text: 'Gambar Produk',
                                        sort: false,
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            return (
                                                <a target="_blank" rel="noopener noreferrer" href={cell}>
                                                    <img alt={`banner ${row.no}`} width="100" src={cell} />
                                                </a>
                                            );
                                        }
                                    },
                                    {
                                        dataField: 'name',
                                        text: 'Nama Produk',
                                        sort: false
                                    },
                                    {
                                        dataField: 'is_active',
                                        text: 'Status Aktif',
                                        sort: false,
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            const btnClassName = row.is_active ? 'fas fa-check' : 'fas fa-times';
                                            return (
                                                <Button size="sm">
                                                    <i className={btnClassName} />
                                                </Button>
                                            );
                                        }
                                    },
                                    {
                                        dataField: 'commission',
                                        text: 'Nilai Komisi',
                                        sort: false,
                                        formatter: cell => {
                                            return 'Rp. ' + new Intl.NumberFormat('id-ID').format(cell);
                                        }
                                    },
                                    {
                                        dataField: 'action',
                                        text: 'Aksi',
                                        sort: false,
                                        isDummyField: true,
                                        formatter: (cell, row, rowIndex, formatExtraData) => {
                                            // console.log(row);
                                            return (
                                                <>
                                                    <Can I="update" a="Commission">
                                                        <Button
                                                            color="primary"
                                                            type="button"
                                                            size="sm"
                                                            outline
                                                            onClick={() => toggelModalWithDetail(row, "updateModalOpen")}
                                                        >
                                                            <span className="btn-inner--text">Update Komisi</span>
                                                        </Button>
                                                    </Can>
                                                </>
                                            );
                                        }
                                    }
                                ]}
                                search
                            >
                                {props => (
                                    <div className="py-4 table-responsive">
                                        <BootstrapTable
                                            remote={{
                                                filter: true,
                                                pagination: true,
                                                sort: true
                                            }}
                                            loading={true}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={paginationFactory({

                                                showTotal: true,
                                                withFirstAndLast: true,
                                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                    <div className="dataTables_length" id="datatable-basic_length">
                                                        <label>
                                                            Show{' '}
                                                            {
                                                                <select
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                            }{' '}
                                                            entries.
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <UpdateModal
                name={name}
                setName={setName}
                commission={commission}
                setCommission={setCommission}
                setIs_active={setIs_active}
                is_active={is_active}
                isOpen={detailModal}
                toggle={toggelModalWithDetail}
                setDetailModal={setDetailModal}
                getDataAfterUpdate={getDataAfterUpdate}
                row={dataDetail}
            />
        </>
    )
}

export default UpdateProduct
