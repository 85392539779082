import React from 'react';
import { Button, Card, CardBody, Form, Input, Container, Row, Col } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import AddonService from '../../../services/AddonService';
import { useState } from 'react';
import CurrencyInput from '../../../helpers/CurrencyInput';
import Swal from 'sweetalert2';
import { useEffect } from 'react';

function EditAddon(props) {
  const [name, setName] = useState('');
  const [hpp, setHpp] = useState(0);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAddonById(props.match.params.id);
  }, []);

  const getAddonById = async id => {
    setLoading(true);
    try {
      const addon = await AddonService.findById(id);

      if (addon.status === 200) {
        setLoading(false);
        setName(addon.data.data.name);
        setHpp(addon.data.data.hpp);
        setPrice(addon.data.data.price);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const isValid = () => {
    function alert(field) {
      Swal.fire({
        icon: 'warning',
        title: `${field} wajib diisi`
      });
    }

    if (!name || name === '') {
      alert('Nama');
      return false;
    } else if (!hpp || hpp === 0) {
      alert('HPP');
      return false;
    }

    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);
    try {
      if (isValid()) {
        const data = {
          name,
          hpp,
          price
        };

        const res = await AddonService.update(props.match.params.id, data);

        if (res.status === 200) {
          setLoading(false);
          props.history.push('/addons');
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <SimpleHeader name="Edit Addon" parentName="Addon" parentLink="/addons" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <Form className="needs-validation" noValidate onSubmit={handleSubmit}>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationCustom01">
                          Nama Addon
                        </label>
                        <Input
                          placeholder="Nama"
                          type="text"
                          name="name"
                          value={name}
                          onChange={e => {
                            setName(e.target.value);
                          }}
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationHpp">
                          HPP
                        </label>
                        <CurrencyInput
                          type="text"
                          className="form-control"
                          name="hpp"
                          value={hpp.toString()}
                          onChange={e => {
                            const value = e.target.value;

                            setHpp(Number(value.slice(3).replace(/\./g, '')));
                          }}
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationPrice">
                          Harga Jual
                        </label>
                        <CurrencyInput
                          type="text"
                          className="form-control"
                          name="price"
                          value={price.toString()}
                          onChange={e => {
                            let value = e.target.value;

                            if (value === '') {
                              value = 'Rp 0';
                            }

                            setPrice(Number(value.slice(3).replace(/\./g, '')));
                          }}
                        />
                      </Col>
                    </div>
                    <Button disabled={loading} color="primary" type="submit">
                      Ubah
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default EditAddon;
