import { Chart } from 'chart.js';
import React from 'react';

import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import { chartOptions, parseOptions } from '../../../variables/charts';

import Loading from '../../../components/Loader/Loader';
import DashboardService from '../../../services/DashboardService';
import CardTable from './components/CardTable';
import CardToday from './components/CardToday';
import TotalOrder from './components/TotalOrder';
import TotalPoint from './components/TotalPoint';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import Can from '../../../config/Can';
// import ability from '../../../config/ability';
import CardComparation from './components/CardComparation';
import DashboardDealmaker from './DashboardDealmaker';

am4core.useTheme(am4themes_animated);

class DashboardAdmin extends React.Component {
  state = {
    summary: {
      today_orders: 0,
      today_customers: 0,
      today_points: 0,
      today_products: 0
    },
    comparation: null,
    charts: {
      points: {
        labels: [],
        datasets: []
      },
      total_orders: {
        labels: [],
        datasets: [
          {
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            data: []
          }
        ]
      }
    },
    covers: [],
    products: [],
    colors: [],
    cities: [],
    provinces: [],
    order_sources: [],
    platforms: [],
    activeNav: 1,
    loading: false
  };

  interval; // handle set interval cycle

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }

    if (this.chart) {
      this.chart.dispose();
    }
  }

  async componentDidMount() {
    this.getData();
    this.interval = setInterval(() => {
      this.getData();
    }, 1000 * 15 * 60); // 15 menit
  }

  getData = async () => {
    try {
      this.setState({ loading: true });
      const { data } = await DashboardService.getDashboard();
      if (data.products) {
        data.products.forEach(product => {
          product.name = product.product_name;
          delete product.product_name;
        });
      }

      this.setState({
        ...data,
        loading: false
      });

      // if (data.provinces && data.provinces.length > 0 && ability.can('read', 'Dashboard Province')) {
      //   this.amchartConfig();
      // }
    } catch (error) {
      this.setState({ loading: false });
      // console.log(error);
    }
  };

  amchartConfig = async () => {
    let chart = am4core.create('chartdiv', am4maps.MapChart);
    chart.geodataSource.url = 'https://www.amcharts.com/lib/4/geodata/json/indonesiaHigh.json';

    chart.padding(20, 20, 20, 20, 20);
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.data = this.state.provinces;

    //Set min/max fill color for each area
    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(-0.2)
    });

    // Make map load polygon data (state shapes and names) from GeoJSON
    polygonSeries.useGeodata = true;
    chart.zoomControl = new am4maps.ZoomControl();

    // Configure series tooltip
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = '{name}: {value}';
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create('hover');
    hs.properties.fill = chart.colors.getIndex(1).brighten(-0.5);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <>
        {this.state.loading && <Loading />}
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">Dashboard</h6>{' '}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="/" onClick={e => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      Dashboard
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>

              <Row>
                <Can I="read" a="Dashboard Order Today">
                  <Col md="6" xl="3">
                    <CardToday
                      name="Order Hari Ini"
                      icon="ni ni-cart"
                      value={this.state.summary.today_orders}
                      gradient="green"
                    />
                  </Col>
                </Can>
                <Can I="read" a="Dashboard New Customer Today">
                  <Col md="6" xl="3">
                    <CardToday
                      name="Customer Baru"
                      icon="fas fa-users"
                      value={this.state.summary.today_customers}
                      gradient="red"
                    />
                  </Col>
                </Can>
                <Can I="read" a="Dashboard Product Sold Today">
                  <Col md="6" xl="3">
                    <CardToday
                      name="Produk Terjual"
                      icon="fas fa-car"
                      value={this.state.summary.today_products}
                      gradient="primary"
                    />
                  </Col>
                </Can>
                <Can I="read" a="Dashboard Point Today">
                  <Col md="6" xl="3">
                    <CardToday
                      gradient="orange"
                      point
                      name="Omzet Hari ini"
                      icon="ni ni-money-coins"
                      value={this.state.summary.today_points}
                    />
                  </Col>
                </Can>
                {this.state.comparation && (
                  <>
                    <Can I="read" a="Dashboard Point Today">
                      <Col md="6" xl="3">
                        <CardComparation
                          gradient="primary"
                          point
                          name="Omzet hari ini"
                          icon="ni ni-money-coins"
                          {...this.state.comparation.today}
                        />
                      </Col>
                    </Can>
                    <Can I="read" a="Dashboard Point Today">
                      <Col md="6" xl="3">
                        <CardComparation
                          gradient="orange"
                          point
                          name="Omzet Kemarin"
                          icon="ni ni-money-coins"
                          {...this.state.comparation.yesterday}
                        />
                      </Col>
                    </Can>
                    <Can I="read" a="Dashboard Point Today">
                      <Col md="6" xl="3">
                        <CardComparation
                          gradient="red"
                          point
                          name="Omzet 7 hari terakhir"
                          icon="ni ni-money-coins"
                          {...this.state.comparation.seven_days}
                        />
                      </Col>
                    </Can>
                    <Can I="read" a="Dashboard Point Today">
                      <Col md="6" xl="3">
                        <CardComparation
                          gradient="green"
                          point
                          name="Omzet Bulan ini"
                          icon="ni ni-money-coins"
                          {...this.state.comparation.this_month}
                        />
                      </Col>
                    </Can>
                  </>
                )}
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--6" fluid>
          <Row>
            <Can I="read" a="Dashboard Point Monthly">
              <Col xl="6">
                <TotalPoint points={this.state.charts.points} />
              </Col>
            </Can>
            <Can I="read" a="Dashboard Total Order">
              <Col xl="6">
                <TotalOrder orders={this.state.charts.total_orders} />
              </Col>
            </Can>
          </Row>

          <Row>
            <Col xs="12">
              <Can I="read" a="Dashboard Dealmaker">
                <DashboardDealmaker data={this.state.dealmaker} />
              </Can>
            </Col>
          </Row>

          <Row>
            <Col md="4" xs="12">
              <Can I="read" a="Dashboard Cover">
                <CardTable name="Cover" items={this.state.covers} />
              </Can>
            </Col>
            <Col md="4" xs="12">
              <Can I="read" a="Dashboard Order Source">
                <CardTable name="Sumber Order" items={this.state.order_sources} />
              </Can>
            </Col>
            <Col md="4" xs="12">
              <Can I="read" a="Dashboard Order Source">
                <CardTable name="Platform" items={this.state.platforms} />
              </Can>
            </Col>
          </Row>
          <Row>
            <Col md="4" xs="12">
              <Can I="read" a="Dashboard Product">
                <CardTable name="Kendaraan" items={this.state.products} />
              </Can>
            </Col>
            <Col md="4" xs="12">
              <Can I="read" a="Dashboard Most City Order">
                <CardTable name="Kota Pemesan Terbanyak" items={this.state.cities} />
              </Can>
            </Col>
            <Col md="4" xs="12">
              <Can I="read" a="Dashboard Color">
                <CardTable name="Warna" items={this.state.colors} />
              </Can>
            </Col>
          </Row>
          {/* <Can I="read" a="Dashboard Province">
            <Row>
              <Col xs={{ size: 12 }}>
                <Card>
                  <CardHeader>Grafik Provinsi</CardHeader>
                  <CardBody>
                    <div id="chartdiv" style={{ width: '100%', height: '500px' }}></div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Can> */}
        </Container>
      </>
    );
  }
}

export default DashboardAdmin;
