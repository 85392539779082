import { request } from "../config";

// versi function
function PaymentStatusService() {

  // Show List
  const list = async () => {
    const response = await request
      .get(`/payment-statuses`);
    return response.data;
  }

  return { list }
}

export default PaymentStatusService()