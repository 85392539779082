import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import ShippingManagementService from '../../../../services/ShippingManagementService';

const CancelModal = props => {
  const { toggle, isOpen, row } = props;

  const [order_id, setOrderId] = useState(null);
  const [reason, setReason] = useState('');

  useEffect(() => {
    setOrderId(row.id);

    return () => {
      setOrderId(null);
      setReason('');
    };
  }, [isOpen]);

  const handleReason = e => {
    setReason(e.target.value);
  };

  const submitCancel = async () => {
    const data = {
      reason: reason
    };

    try {
      const response = await ShippingManagementService.cancelShipping(order_id, data);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Good!',
          text: 'Berhasil Cancel Pickup!'
        });
      }
      toggle();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} size="lg">
      <div className="modal-header">
        <h5 className="modal-title" id="detailModalLabel">
          Form Cancel Pickup Order #{order_id}
        </h5>
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        <Row>
          <Col md="6">
            <FormGroup>
              <label htmlFor="reason">Alasan</label>
              <Input value={reason} onChange={handleReason} placeholder="Isi alasan cancel" name="reason" />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="modal-footer">
        <Button disabled={!reason} color="primary" data-dismiss="modal" type="button" onClick={() => submitCancel()}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default CancelModal;
