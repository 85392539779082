import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'reactstrap';
import ShippingManagementService from '../../../../services/ShippingManagementService';

const TrackingModal = props => {
  const { toggle, isOpen, row } = props;
  const [shipping, setShipping] = useState({ awb: '', histories: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getData(row.id);
    } else {
      return () => {
        setShipping(null);
      };
    }
  }, [isOpen]);

  const getData = async order_id => {
    try {
      setLoading(true);
      const response = await ShippingManagementService.shippingDetail(order_id);
      if (response.status === 200) {
        setShipping(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggle} size="lg">
      <div className="modal-header">
        <h5 className="modal-title" id="detailModalLabel">
          Lacak Pengiriman : {shipping && shipping.awb}
        </h5>
        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={toggle}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        {/* {loading && <div className="loader"></div>} */}
        {loading && <Spinner color="primary" />}
        <Row>
          <Col md="6">
            <div className="timeline timeline-one-side" data-timeline-axis-style="dashed" data-timeline-content="axis">
              {shipping &&
                shipping.histories.map((item, index) => {
                  return (
                    <div className="timeline-block" key={index}>
                      <span className="timeline-step badge-success">
                        <i className="ni ni-delivery-fast" />
                      </span>
                      <div className="timeline-content">
                        <small className="text-muted font-weight-bold">
                          {moment(item.created_at).format('DD MMM YYYY HH:mm')}
                        </small>
                        {/* <h5 className="mt-3 mb-0">New message</h5> */}
                        <p className="text-sm mt-1 mb-0">{item.status}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </div>

      <div className="modal-footer">
        <Button color="primary" data-dismiss="modal" type="button" onClick={toggle}>
          Tutup
        </Button>
      </div>
    </Modal>
  );
};

export default TrackingModal;
