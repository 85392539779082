import { request } from '../config';

class ProductDescriptionService {
  static async uploadImage(data) {
    const response = await request.post(`/upload/product_description/image`, data);
    return response;
  }

  static async insert(data) {
    const { data: response } = await request.post('/category-details', data);
    return response;
  }

  static async changeStatus(id) {
    const { data: response } = await request.put(`/category-details/change-status/${id}`);
    return response;
  }

  static async detail(id) {
    const { data: response } = await request.get(`/category-details/${id}`);
    return response;
  }

  static async edit(id, data) {
    const { data: response } = await request.put(`/category-details/${id}`, data);
    return response;
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/category-details/${id}`);
    return response;
  }

  static async getDatatable(page, limit, sort, order, filter, category_id) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/category-details', {
      params: { page, limit, sort, order, filter, category_id }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }
}

export default ProductDescriptionService;
