import React, { useState, useEffect } from 'react';
import PagesManagementService from '../../../services/PagesManagementService';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Container, Row, Card, CardHeader, Col, Button, CardBody } from 'reactstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;

function TablePagesManagement(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  async function getData() {
    try {
      const response = await PagesManagementService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText);

      if (response.status === 200) {
        setData(response.data);
        setTotalSize(response.recordsFiltered);
        setPage(response.page);
      }
    } catch (error) {
      console.log(error.response);
    }
  }

  useEffect(() => {
    getData();
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  return (
    <>
      <SimpleHeader name="Manajemen Halaman Web" parentName="Master Data" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Manajemen Halaman Web</h3>
                  </Col>
                  {/* <Can I="create" a="Page Management">
                    <Col className="text-right" xs="6">
                      <Link to="/pages-management/add">
                        <Button className="btn-round btn-icon" color="primary" id="tooltip443412080" size="sm">
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">Tambah</span>
                        </Button>
                      </Link>
                    </Col>
                  </Can> */}
                </Row>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'no',
                      text: 'Nomor',
                      sort: false
                    },
                    {
                      dataField: 'title',
                      text: 'Halaman',
                      sort: false
                    },
                    {
                      dataField: 'action',
                      text: 'Aksi',
                      sort: false,
                      isDummyField: true,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        return (
                          <>
                            {/* <Can I="update" a="Voucher"> */}
                            <Button
                              color="warning"
                              type="button"
                              size="sm"
                              outline
                              onClick={() =>
                                props.history.push({
                                  pathname: `/page-management/${row.slug}`,
                                  state: {
                                    row
                                  }
                                })
                              }
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-edit" />
                              </span>
                              <span className="btn-inner--text"> Detail Halaman</span>
                            </Button>
                            {/* </Can> */}
                            {/* <Can I="delete" a="Voucher"> */}
                            <br />
                            {/* <Button
                              color="danger"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => {
                                Swal.fire({
                                  icon: 'warning',
                                  title: `Apakah anda yakin akan menghapus voucher ${row.code}?`,
                                  cancelButtonText: 'Batal',
                                  confirmButtonColor: 'red',
                                  cancelButtonColor: 'green',
                                  showConfirmButton: true,
                                  allowOutsideClick: false,
                                  allowEscapeKey: false,
                                  allowEnterKey: false,
                                  showCancelButton: true,
                                  showCloseButton: true
                                }).then(async confirm => {
                                  if (confirm.value) {
                                    try {
                                      const response = await PagesManagementService.remove(row.id);
                                      if (response.status === 200) {
                                        Swal.fire({
                                          icon: 'success',
                                          text: `Voucher ${row.code} berhasil dihapus`
                                        });
                                        getData();
                                      }
                                    } catch (error) {
                                      Swal.fire({
                                        icon: 'error',
                                        title: 'terjadi kesalahan',
                                        text: error.message
                                      });
                                    }
                                  }
                                });
                              }}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-trash" />
                              </span>
                              <span className="btn-inner--text"> Hapus</span>
                            </Button> */}
                            {/* </Can> */}
                          </>
                        );
                      }
                    }
                  ]}
                  search
                >
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar delay={500} className="form-control-sm" placeholder="" {...props.searchProps} />
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TablePagesManagement;
