import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import ManufacturerService from '../../../services/ManufacturerService';
import TypeCarService from '../../../services/TypeCarService';
import VoucherService from '../../../services/VoucherService';

function EditVoucher(props) {
  const [id] = useState(props.match.params.id);
  const [code, setCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [stock, setStock] = useState(0);
  const [pic_id, setPicId] = useState(0);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [voucher_type_id, setVoucherTypeId] = useState(undefined);

  const [terms, setTerms] = useState([]);

  const [csLists, setCsLists] = useState([]);
  const [voucherTypes, setVoucherTypes] = useState([]);
  const [listCategories, setListCategories] = useState([]);

  const codeRef = useRef();
  const discountRef = useRef();
  const stockRef = useRef();
  const picRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();
  const isFirstRender = useRef(true);

  useEffect(() => {
    getCategories();
    getVoucherType();
    getCSLists();
    getData();
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      if (terms.length > 0) {
        terms.forEach((term, i) => {
          if (term.category_id) {
            getTermTypeCars(term.category_id, i);
          }
        });
      }
    }
  }, [terms.length]);

  async function getData() {
    try {
      const res = await VoucherService.detail(id);

      if (res.status === 200) {
        setCode(res.data.code);
        setVoucherTypeId(res.data.voucher_type.id);
        setDiscount(res.data.discount);
        setStock(res.data.stock);
        setPicId(res.data.pic_id);
        setStartDate(res.data.start_date);
        setEndDate(res.data.end_date);

        setTerms(
          res.data.terms.map(term => {
            // mengubah yang nilainya null menjadi '' biar tidak error
            for (const key in term) {
              if (term.hasOwnProperty(key)) {
                if (term[key] === null) {
                  term[key] = '';
                }
              }
            }
            return term;
          })
        );
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.message,
        onClose: props.history.push('/vouchers')
      });
    }
  }

  async function getTermTypeCars(categoryId, i) {
    if (categoryId) {
      try {
        const response = await TypeCarService.findByCategoryId(categoryId);

        if (response.status === 200) {
          setTerms(
            terms.map((term, index) => {
              if (index === i) {
                term.type_cars = response.data.data;
              }
              return term;
            })
          );

          if (terms[i].type_car_id) {
            getTermManufacturers(terms[i].type_car_id, terms[i].category_id, i);
          }
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => props.history.push('/vouchers'),
          onCancel: () => props.history.push('/vouchers'),
          text: error.message
        });
      }
    } else {
      setTerms(
        terms.map((term, i) => {
          term.manufacturers = [];
          term.manufacturer_id = undefined;
          term.products = [];
          term.product_id = undefined;
          return term;
        })
      );
    }
  }

  async function getTermManufacturers(typeCarid, CategoryId, i) {
    try {
      const response = await ManufacturerService.findByTypeCarIdAndCategoryId(typeCarid, CategoryId);

      if (response.status === 200) {
        setTerms(
          terms.map((term, index) => {
            if (index === i) {
              term.manufacturers = response.data.data;
            }
            return term;
          })
        );

        if (terms[i].manufacturer_id) {
          getTermProducts(terms[i].category_id, terms[i].manufacturer_id, i);
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        onConfirm: () => props.history.push('/vouchers'),
        onCancel: () => props.history.push('/vouchers'),
        text: error.message
      });
    }
  }

  async function getTermProducts(categoryId, manufacturerId, i) {
    try {
      const response = await VoucherService.listProducts(categoryId, manufacturerId, terms[i].type_car_id);
      if (response.status === 200) {
        setTerms(
          terms.map((term, index) => {
            if (index === i) {
              term.products = response.data.data;
            }

            return term;
          })
        );
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        onConfirm: () => props.history.push('/vouchers'),
        onCancel: () => props.history.push('/vouchers'),
        text: error.message
      });
    }
  }

  async function getCSLists() {
    try {
      const response = await VoucherService.getCsLists();
      if (response.status === 200) {
        setCsLists(response.data);
      }
    } catch (error) {
      Swal.fire({
        title: 'terjadi kesalahan saat mengambil data cs',
        icon: 'error',
        text: error.response.message
      });
      console.log(error);
    }
  }

  async function getVoucherType() {
    try {
      const response = await VoucherService.getVoucherTypes();

      if (response.status === 200) {
        setVoucherTypes(response.data.data);
      }
    } catch (error) {
      Swal.fire({
        title: 'terjadi kesalahan saat mengambil data cs',
        icon: 'error',
        text: error.response.message
      });
      console.log(error);
    }
  }

  async function getCategories() {
    try {
      const response = await VoucherService.categoryLists();
      if (response.status === 200) {
        setListCategories(response.data.data);
      }
    } catch (error) {
      Swal.fire({
        title: 'terjadi kesalahan saat mengambil data cs',
        icon: 'error',
        text: error.response.message
      });
      console.log(error);
    }
  }

  function formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function isValid() {
    if (code === '' || code === 0) {
      const ref = codeRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (discount === '' || !discount || discount < 0) {
      const ref = discountRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (stock === '' || stock === 0) {
      const ref = stockRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (pic_id === '' || pic_id === 0) {
      const ref = picRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (start_date === '' || start_date === 0) {
      const ref = startDateRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (end_date === '' || end_date === 0) {
      const ref = endDateRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (terms.map(term => term.category_id).some(term => term === '' || !term)) {
      terms.forEach((term, i) => {
        if (term.category_id === '' || !term.category_id) {
          const elemen = document.getElementById(`category_id_${i}`);
          elemen.classList.add('is-invalid');
          elemen.focus();
        }
      });
      Swal.fire({
        title: 'Terjadi kesalahan',
        text: 'Kategori di ketentuan tidak boleh kosong',
        icon: 'error'
      });
      return false;
    }

    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    const data = {
      code,
      discount,
      stock,
      pic_id,
      start_date,
      end_date,
      voucher_type_id,
      terms: terms.map(term => {
        delete term.products;
        delete term.type_cars;
        delete term.manufacturers;
        return term;
      })
    };

    if (isValid()) {
      try {
        const bank = await VoucherService.edit(id, data);
        if (bank.status === 200 && bank.message === 'OK') {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push('/vouchers')
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => props.history.push('/vouchers'),
          onCancel: () => props.history.push('/vouchers'),
          text: error.message
        });
      }
    }
  }

  function addTerm() {
    const term = {
      category_id: undefined,
      type_car_id: undefined,
      manufacturer_id: undefined,
      product_id: undefined,
      products: [],
      type_cars: [],
      manufacturers: []
    };

    const newTerms = [...terms, term];

    setTerms(newTerms);
  }

  const deleteTerm = index => {
    const newTerm = terms.filter((term, i) => index !== i);
    setTerms(newTerm);
  };

  function handleTermChange(e, i) {
    setTerms(
      terms.map((term, index) => {
        if (index === i) {
          term[e.target.name] = e.target.value;
        }
        return term;
      })
    );
  }

  async function handleManufacturerChange(manufacturerId, index) {
    // jangan ditaruh di else
    setTerms(
      terms.map((term, i) => {
        if (i === index) {
          term.products = [];
          term.product_id = undefined;
        }
        return term;
      })
    );

    if (manufacturerId) {
      const categoryId = terms[index].category_id;
      const typeCarId = terms[index].type_car_id;
      try {
        const response = await VoucherService.listProducts(categoryId, manufacturerId, typeCarId);
        if (response.status === 200) {
          setTerms(
            terms.map((term, i) => {
              if (index === i) {
                term.products = response.data.data;
                term.product_id = undefined;
              }

              return term;
            })
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => props.history.push('/vouchers'),
          onCancel: () => props.history.push('/vouchers'),
          text: error.message
        });
      }
    }
  }

  async function handleTypeCarChange(vehicleTypeId, categoryId, index) {
    // jangan ditaruh di else
    setTerms(
      terms.map((term, i) => {
        if (i === index) {
          term.manufacturers = [];
          term.manufacturer_id = undefined;
          term.products = [];
          term.product_id = undefined;
        }
        return term;
      })
    );

    if (vehicleTypeId) {
      try {
        const response = await ManufacturerService.findByTypeCarIdAndCategoryId(vehicleTypeId, categoryId);
        if (response.status === 200) {
          setTerms(
            terms.map((term, i) => {
              if (index === i) {
                term.manufacturers = response.data.data;
                term.manufacturer_id = undefined;
                term.products = [];
                term.product_id = undefined;
              }
              return term;
            })
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan pada saat perubahan jenis kendaraan',
          text: error.message
        });
      }
    }
  }

  async function handleCategoryChange(categoryId, index) {
    // jangan ditaruh di else
    setTerms(
      terms.map((term, i) => {
        if (i === index) {
          term.type_cars = [];
          term.type_car_id = undefined;
          term.manufacturers = [];
          term.manufacturer_id = undefined;
          term.products = [];
          term.product_id = undefined;
        }
        return term;
      })
    );

    if (categoryId) {
      try {
        const response = await TypeCarService.findByCategoryId(categoryId);

        if (response.status === 200) {
          setTerms(
            terms.map((term, i) => {
              if (i === index) {
                term.type_cars = response.data.data;
                term.type_car_id = undefined;
                term.manufacturer_id = undefined;
                term.product_id = undefined;
                term.manufacturers = [];
                term.products = [];
              }
              return term;
            })
          );
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi kesalahan saat perubahan kategori',
          onConfirm: () => props.history.push('/vouchers'),
          onCancel: () => props.history.push('/vouchers'),
          text: error.message
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Edit Voucher" parentName="Tabel Voucher" parentLink="/vouchers" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={onSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="code">
                        Kode Voucher
                      </Label>
                      <Input
                        id="code"
                        placeholder="Kode Voucher"
                        name="code"
                        value={code}
                        innerRef={codeRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setCode(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="discount">
                        Nominal Diskon
                      </Label>
                      <Input
                        id="discount"
                        placeholder="Nominal Diskon"
                        min="0"
                        type="number"
                        innerRef={discountRef}
                        value={discount}
                        name="discount"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');

                          setDiscount(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="stock">
                        Jumlah Voucher
                      </Label>
                      <Input
                        id="stock"
                        placeholder="Stok Voucher"
                        min="0"
                        type="number"
                        innerRef={stockRef}
                        value={stock}
                        name="stock"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setStock(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="start_date">
                        Berlaku Dari
                      </Label>
                      <Input
                        id="start_date"
                        type="date"
                        name="start_date"
                        min={new Date().toISOString().split('T')[0]}
                        innerRef={startDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={start_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setStartDate(formatDate(e.target.value));
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="end_date">
                        Berlaku Sampai
                      </Label>
                      <Input
                        id="end_date"
                        type="date"
                        name="end_date"
                        innerRef={endDateRef}
                        min={new Date().toISOString().split('T')[0]}
                        onKeyDown={e => e.preventDefault()}
                        value={end_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setEndDate(formatDate(e.target.value));
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="pic_id">
                        PIC
                      </Label>
                      <Input
                        id="pic_id"
                        placeholder="PIC"
                        type="select"
                        value={pic_id}
                        name="pic_id"
                        innerRef={picRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setPicId(e.target.value);
                        }}
                      >
                        <option value="">--Pilih PIC--</option>
                        {csLists.map((bank, i) => (
                          <option key={i} value={bank.id}>
                            {bank.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup tag="fieldset">
                      <label htmlFor="voucher_type" className="form-control-label">
                        Tipe Voucher
                      </label>
                      {voucherTypes.map((type, i) => (
                        <FormGroup check key={i}>
                          <Label check>
                            <Input
                              type="radio"
                              name="voucher_type_id"
                              checked={type.id === voucher_type_id}
                              value={type.id}
                              onChange={e => {
                                e.persist();
                                setVoucherTypeId(Number(e.currentTarget.value));
                              }}
                            />{' '}
                            {type.name}
                          </Label>
                        </FormGroup>
                      ))}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                      <>Ketentuan</>
                      <Button type="button" color="success" className="btn btn-sm" onClick={addTerm}>
                        Tambah
                      </Button>
                    </div>
                    <div>
                      <Table responsive striped>
                        <thead>
                          <tr>
                            <th>Kategori</th>
                            <th>Tipe Kendaraan</th>
                            <th>Merek Kendaraan</th>
                            <th>Produk</th>
                          </tr>
                        </thead>
                        <tbody>
                          {terms.length > 0 ? (
                            terms.map((term, i) => (
                              <tr key={i}>
                                <td>
                                  <Input
                                    id={`category_id_${i}`}
                                    type="select"
                                    name="category_id"
                                    value={term.category_id}
                                    onChange={e => {
                                      e.persist();
                                      handleTermChange(e, i);
                                      e.target.classList.remove('is-invalid');
                                      handleCategoryChange(e.target.value, i);
                                    }}
                                  >
                                    <option value="">Pilih Semua</option>
                                    {listCategories.map(category => (
                                      <option key={category.id} value={category.id}>
                                        {category.name}
                                      </option>
                                    ))}
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    id={`type_car_id_${i}`}
                                    type="select"
                                    name="type_car_id"
                                    value={term.type_car_id}
                                    onChange={e => {
                                      e.persist();
                                      handleTermChange(e, i);
                                      handleTypeCarChange(e.target.value, term.category_id, i);
                                    }}
                                  >
                                    <option value="">Pilih Semua</option>
                                    {term.type_cars &&
                                      term.type_cars.length > 0 &&
                                      term.type_cars.map(type_car => (
                                        <option key={type_car.id} value={type_car.id}>
                                          {type_car.name}
                                        </option>
                                      ))}
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    id={`manufacturer_id_${i}`}
                                    type="select"
                                    name="manufacturer_id"
                                    value={term.manufacturer_id}
                                    onChange={e => {
                                      e.persist();
                                      handleTermChange(e, i);
                                      handleManufacturerChange(e.target.value, i);
                                    }}
                                  >
                                    <option value="">Pilih Semua</option>
                                    {term.manufacturers &&
                                      term.manufacturers.length > 0 &&
                                      term.manufacturers.map(manufacturer => (
                                        <option key={manufacturer.id} value={manufacturer.id}>
                                          {manufacturer.name}
                                        </option>
                                      ))}
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    id={`product_id_${i}`}
                                    type="select"
                                    name="product_id"
                                    value={term.product_id}
                                    onChange={e => {
                                      e.persist();
                                      handleTermChange(e, i);
                                    }}
                                  >
                                    <option value="">Pilih Semua</option>
                                    {term.products &&
                                      term.products.length > 0 &&
                                      term.products.map(product => (
                                        <option key={product.id} value={product.id}>
                                          {product.name}
                                        </option>
                                      ))}
                                  </Input>
                                </td>
                                <td>
                                  <Button
                                    color="danger"
                                    className="btn btn-sm"
                                    onClick={() => deleteTerm(i)}
                                    type="button"
                                  >
                                    Hapus
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4}>Tidak ada ketentuan</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button color="primary" className="btn" type="submit">
                    Simpan
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default EditVoucher;
