export const defaultRow = {
  id: null,
  first_chat: '',
  platform: '',
  dealmaker_name: '',
  dealmaker_phone: '',
  customer_phone: '',
  customer_name: '',
  customer_vehicle: '',
  contact_saved: false,
  can_be_contacted: false,
  purchased: false,
  reason: '',
  address: '',
  postal_code: '',
  province_id: null,
  district_id: null,
  subdistrict_id: null,
  subdistrict: {
    name: ''
  },
  district: {
    type: '',
    name: ''
  },
  province: {
    name: ''
  },
  treatments: [
    {
      treatment: '',
      treatment_date: null
    }
  ],
  no: 1
};
