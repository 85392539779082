import { request } from '../config';

class ComplaintCategoryService {
  static async getLists() {
    const { data: response } = await request.get('/banks/list');
    return response;
  }

  static async getBankProviders() {
    const { data: response } = await request.get('banks/providers');
    return response;
  }

  static async insert(data) {
    const response = await request.post('/complaint-categories', data);
    return response;
  }

  static async detail(id) {
    const response = await request.get(`/complaint-categories/${id}`);
    return response;
  }

  static async edit(id, data) {
    const response = await request.put(`/complaint-categories/${id}`, data);
    return response;
  }

  static async remove(id) {
    const response = await request.delete(`/complaint-categories/${id}`);
    return response;
  }

  static async getDatatable(page, limit, sort, order, filter) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/complaint-categories', {
      params: { page, limit, sort, order, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }
}

export default ComplaintCategoryService;
