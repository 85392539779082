import React from 'react';
import { Card, CardBody, CardHeader, Row, Table } from 'reactstrap';

function DashboardDealmaker(props) {
  return (
    <Card>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">Laporan Dealmaker</h3>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>No</th>
              <th>Nama CS</th>
              <th>Budget Ratio</th>
              <th>KPI Leads</th>
              <th>Leads</th>
              <th>Biaya Ads</th>
              <th>Closing</th>
              <th>Konversi</th>
              <th>Cost / Leads</th>
              <th>KPI Omzet</th>
              <th>Omzet</th>
              <th>Belum Tercapai</th>
              <th>Ketercapaian</th>
            </tr>
          </thead>
          <tbody>
            {props.data &&
              props.data.details.map((report, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td className="name-column">{report.name}</td>
                  <td>{(report.budget_ratio * 100).toFixed(2)} %</td>
                  <td>{report.kpi_leads}</td>
                  <td>{report.leads}</td>
                  <td>
                    {report.budget.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </td>
                  <td>{report.deals}</td>
                  <td>{(report.convertion * 100).toFixed(2)} %</td>
                  <td>
                    {report.cost_per_leads.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </td>
                  <td>
                    {report.kpi_omzet.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </td>
                  <td>
                    {report.omzet.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </td>
                  <td>
                    {report.omzet_not_achieved.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </td>
                  <td>{(report.omzet_ratio * 100).toFixed(2)} %</td>
                </tr>
              ))}
            {props.data && (
              <tr>
                <th colSpan="2" style={{ textTransform: 'uppercase' }}>
                  total
                </th>
                <th>{(props.data.total.budget_ratio * 100).toFixed(2)} %</th>
                <th>{props.data.total.kpi_leads}</th>
                <th>{props.data.total.leads}</th>
                <th>
                  {props.data.total.budget.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </th>
                <th>{props.data.total.deals}</th>
                <th>{(props.data.total.convertion * 100).toFixed(2)} %</th>
                <th>
                  {props.data.total.cost_per_leads.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </th>
                <th>
                  {props.data.total.kpi_omzet.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </th>
                <th>
                  {props.data.total.omzet.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </th>
                <th>
                  {props.data.total.omzet_not_achieved.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </th>
                <th>{(props.data.total.omzet_ratio * 100).toFixed(2)} %</th>
              </tr>
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

export default DashboardDealmaker;
