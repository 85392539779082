import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table
} from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';
import ReportService from '../../../services/ReportService';

import './AdvertiserReport.scss';

// import Can from '../../../config/Can';

import moment from 'moment';
import 'moment/locale/id';
import OrderService from '../../../services/OrderService';
moment.locale('id');

function BudgetRatioReport() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unitOptions, setUnitOptions] = useState([]);
  const [picOptions, setPicOptions] = useState([]);
  const [unitId, setUnitId] = useState(undefined);
  const [pic, setPic] = useState(undefined);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [period, setPeriod] = useState('');

  const [data, setData] = useState(null);

  useEffect(() => {
    getUnitOptions();
    getPeriodOptions();
  }, []);

  const getUnitOptions = () => {
    setLoading(true);
    OrderService.getUnitOptions()
      .then(({ data }) => setUnitOptions(data))
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeUnit = e => {
    const unit_id = e.target.value;
    setUnitId(unit_id);
    if (unit_id) {
      getPicOptions(unit_id);
    } else {
      setPicOptions([]);
      setPic('');
    }
  };

  const getPeriodOptions = async () => {
    try {
      const res = await ReportService.getAdvertiserPeriodOptions();
      if (res.status === 200) {
        const responseData = res.data.data;
        setPeriodOptions(responseData);
        setPeriod(responseData[0].value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPicOptions = unitId => {
    setLoading(true);
    ReportService.getCsList({ unit_id: unitId })
      .then(({ data: res }) => {
        setPicOptions(res.data);
        setPic('');
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = async (pic, period) => {
    setError('');

    setLoading(true);

    const data = {
      unit_id: unitId,
      user_id: pic,
      period
    };

    try {
      const response = await ReportService.getBudgetRatioReport(data);

      if (response.status === 200) {
        setLoading(false);
        setData(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleExportData = async () => {
    try {
      const data = {
        unit_id: unitId,
        user_id: pic,
        period
      };

      const res = await ReportService.exportBudgetRatioReport(data);

      if (res.status === 200) {
        window.open(res.data.data.url, '_blank');
      }
    } catch (error) {
      setLoading(false);
      console.log();
    }
  };

  const toRupiah = nominal => {
    return nominal.toLocaleString('id', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    });
  };

  const jijekDate = date => {
    const arrD = date.split('/');
    const hasil = `${arrD[1]}/${arrD[0]}/${arrD[2]}`;

    return new Date(hasil);
  };

  const emptyCol = () => {
    return [...new Array(data.content.length * (Object.keys(data.content[0].daily[0]).length + 1))]
      .slice(1)
      .map((a, i) => <td key={i}></td>);
  };

  return (
    <>
      {!!loading && <Loader />}
      <SimpleHeader name="Budget Ratio" parentName="Laporan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cari Data Laporan Budget Ratio</h3>
              </CardHeader>
              <CardBody>
                {error && (
                  <Alert color="danger">
                    <ul>
                      <li>{error}</li>
                    </ul>
                  </Alert>
                )}
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    setLoading(true);

                    handleSearch(pic, period);
                  }}
                >
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="unit_id">Unit</label>
                        <Input id="unit_id" type="select" name="unit_id" value={unitId} onChange={handleChangeUnit}>
                          <option value="">-- Semua Unit --</option>
                          {unitOptions.map((unit, key) => (
                            <option key={key} value={unit.value}>
                              {unit.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="pic">List PIC</label>
                        <Input
                          type="select"
                          id="pic"
                          value={pic}
                          onChange={e => {
                            setPic(e.target.value);
                          }}
                        >
                          <option value="">--- Semua PIC ---</option>
                          {picOptions.map((status, i) => (
                            <option key={i} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="period">Periode</label>
                        <Input
                          type="select"
                          id="period"
                          value={period}
                          onChange={e => {
                            e.persist();
                            setError('');
                            // setDate('');
                            setPeriod(e.target.value);
                          }}
                        >
                          {/* <option value="">--- Semua PIC ---</option> */}
                          {periodOptions.map((status, i) => (
                            <option key={i} value={status.value}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button
                          onClick={async () => {
                            setUnitId('');
                            setPic('');
                            setPeriod(periodOptions[0].value);
                            setData(null);
                          }}
                          size="md"
                          disabled={loading}
                        >
                          Reset
                        </Button>
                        <Button size="md" disabled={loading} color="primary">
                          Cari
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {!!data && (
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3 className="mb-0">Laporan Budget Ratio</h3>
                    <Button size="sm" color="success" onClick={handleExportData}>
                      Download
                    </Button>
                  </div>
                </CardHeader>

                <CardBody>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <td></td>
                        {data.content.map((ct, i) => {
                          return (
                            <td
                              key={i}
                              colSpan={Object.keys(data.content[0].daily[0]).length + 1}
                              style={{ textAlign: 'center' }}
                            >
                              <strong>{ct.name}</strong>
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <td className="label-column" style={{ textAlign: 'center' }}>
                          <strong>TANGGAL</strong>
                        </td>
                        {data.content.map((as, tro) => {
                          return (
                            <React.Fragment key={tro}>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>NO HP</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>Total Ads</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>META</strong>
                              </td>
                              {/* <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>IG</strong>
                              </td> */}
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>GOOGLE</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>MESSENGER</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>TIKTOK</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>YOUTUBE</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>DM</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>LAIN-LAIN</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>OMZET</strong>
                              </td>
                              <td className="label-column" style={{ textAlign: 'center' }}>
                                <strong>RASIO</strong>
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {data.days.map((d, i) => {
                        return (
                          <tr key={i}>
                            <td className="label-column">{moment(jijekDate(d)).format('LL')}</td>
                            {/* <td className="label-column">{moment(d).format('LL')}</td> */}
                            {data.content.map((b, u) => {
                              return (
                                <React.Fragment key={u}>
                                  <td>{b.phone}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].total_budget)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].budget_fb)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].budget_google)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].budget_messenger)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].budget_tiktok)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].budget_youtube)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].budget_dm)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].additional_budget)}</td>
                                  <td className="number-column">{toRupiah(b.daily[i].omzet)}</td>
                                  <td className="number-column label-column">{`${Number(
                                    b.daily[i].budget_ratio * 100
                                  ).toFixed(2)} %`}</td>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        );
                      })}

                      <tr>
                        <td></td>
                        {data.content.map((nt, i) => {
                          return (
                            <React.Fragment key={i}>
                              <td></td>
                              <td className="number-column">{toRupiah(nt.monthly.total_budget)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.budget_fb)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.budget_google)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.budget_messenger)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.budget_tiktok)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.budget_youtube)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.budget_dm)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.additional_budget)}</td>
                              <td className="number-column">{toRupiah(nt.monthly.omzet)}</td>
                              <td className="number-column">{`${Number(nt.monthly.budget_ratio * 100).toFixed(
                                2
                              )} %`}</td>
                            </React.Fragment>
                          );
                        })}
                      </tr>

                      <tr>
                        <td className="label-column">
                          <strong>TOTAL BUDGET ADS</strong>
                        </td>
                        <td className="number-column">
                          <strong>{toRupiah(data.total.total_budget)}</strong>
                        </td>
                        {emptyCol()}
                      </tr>
                      <tr>
                        <td className="label-column">
                          <strong>TOTAL OMZET</strong>
                        </td>
                        <td className="number-column">
                          <strong>{toRupiah(data.total.omzet)}</strong>
                        </td>
                        {emptyCol()}
                      </tr>
                      <tr>
                        <td className="label-column">
                          <strong>RASIO</strong>
                        </td>
                        <td className="number-column">
                          <strong>{`${Number(data.total.budget_ratio * 100).toFixed(2)} %`}</strong>
                        </td>
                        {emptyCol()}
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default BudgetRatioReport;
