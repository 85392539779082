import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Card, Container, Row, CardHeader, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import ProductionHistoryService from '../../../services/ProductionHistoryService';
import Swal from 'sweetalert2';
import Can from '../../../config/Can';
import SearchBar from '../../../components/Search';

class ProductionHistory extends React.Component {
  state = {
    data: [],
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortField: null,
    sortOrder: undefined,
    searchText: ''
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { page, sizePerPage: limit, searchText: filter } = this.state;
    console.log({ page, limit, filter });

    try {
      const response = await ProductionHistoryService.getDatatable({ page, limit, filter });
      if (response.status === 200) {
        response.data.forEach((d, i) => (d.key = i));
        this.setState({
          data: response.data,
          totalSize: response.recordsTotal,
          page: response.page
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Terjadi kesalahan',
        icon: 'warning',
        text: error.message
      });
    }
  };

  onTableChange = (type, newState) => {
    console.log(newState);
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;
    this.setState(
      {
        page,
        sizePerPage,
        sortField,
        sortOrder,
        searchText
      },
      () => {
        this.getData();
      }
    );
  };

  render() {
    return (
      <>
        <SimpleHeader name="Riwayat Produksi" />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Tabel Riwayat Produksi</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  search
                  data={this.state.data}
                  keyField="key"
                  columns={[
                    {
                      dataField: 'flow_id',
                      text: 'ID PRODUKSI',
                      sort: false
                    },
                    {
                      dataField: 'order_number',
                      text: 'Nomor Order',
                      sort: false,
                      style: {
                        width: '20%'
                      }
                    },
                    {
                      dataField: 'product_name',
                      text: 'Produk',
                      sort: false
                    },
                    {
                      dataField: 'name',
                      text: 'Status',
                      sort: false
                    },
                    {
                      dataField: 'created_at',
                      text: 'Tanggal',
                      sort: false,
                      formatter: row => (
                        <>
                          {new Date(row).toLocaleDateString('id-ID', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                          })}
                        </>
                      )
                    },
                    {
                      dataField: 'action',
                      text: 'Aksi',
                      sort: false,
                      isDummyField: true,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        return (
                          <>
                            {
                              <Can I="print" a="Shipping Label">
                                <Button
                                  color="primary"
                                  type="button"
                                  size="sm"
                                  className="mb-2"
                                  // outline
                                  onClick={() => window.open(`/label/${row.flow_id}`, '_blank')}
                                >
                                  <span className="btn-inner--icon">
                                    <i className="fas fa-file-invoice" />
                                  </span>
                                  <span className="btn-inner--text"> Cetak Ulang Resep</span>
                                </Button>
                                <br />
                              </Can>
                            }
                          </>
                        );
                      }
                    }
                  ]}
                >
                  {props => {
                    return (
                      <div className="py-4 table-responsive">
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder="ID Produksi / No Order"
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: false,
                            pagination: true,
                            sort: false
                          }}
                          rowStyle={(row, rowIndex) => {
                            return { backgroundColor: 'white' };
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: this.state.page,
                            sizePerPage: this.state.sizePerPage,
                            totalSize: this.state.totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={this.onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, {})(ProductionHistory);
