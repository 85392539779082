import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Can from '../../../config/Can';
import PricelistService from '../../../services/PricelistService';

const { SearchBar } = Search;

function TablePricelist(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const source = Axios.CancelToken.source();

    async function getData() {
      try {
        const response = await PricelistService.getDatatable(page, sizePerPage, sortField, sortOrder, searchText);

        if (response.status === 200) {
          setData(response.data);
          setTotalSize(response.recordsFiltered);
          setPage(response.page);
        }
      } catch (error) {
        console.log(error.response);
      }
    }

    getData();

    return () => {
      source.cancel();
    };
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  const formatIDR = number => {
    return number.toLocaleString('id', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 });
  };

  return (
    <>
      <SimpleHeader name="PriceList" parentName="Master Data" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Tabel Pricelist</h3>
                  </Col>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'no',
                    text: 'Nomor',
                    sort: false
                  },
                  {
                    dataField: 'name',
                    text: 'Category Cover',
                    sort: true
                  },
                  {
                    dataField: 'size',
                    text: 'Ukuran',
                    sort: true
                  },
                  {
                    dataField: 'weight',
                    text: 'Berat (gr)',
                    sort: true,
                    formatter: (cell, row) => cell.toLocaleString('id')
                  },
                  {
                    dataField: 'hpp',
                    text: 'HPP',
                    sort: true,
                    formatter: (cell, row) => formatIDR(cell)
                  },
                  {
                    dataField: 'retail',
                    text: 'Ecer/Retail',
                    sort: true,
                    formatter: (cell, row) => formatIDR(cell)
                  },
                  {
                    dataField: 'reseller',
                    text: 'Reseller',
                    sort: true,
                    formatter: (cell, row) => formatIDR(cell)
                  },
                  {
                    dataField: 'big_seller',
                    text: 'Agen',
                    sort: true,
                    formatter: (cell, row) => formatIDR(cell)
                  },
                  {
                    dataField: 'action',
                    text: 'Aksi',
                    sort: false,
                    isDummyField: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <>
                          <Can I="update" a="Pricelist">
                            <Button
                              color="warning"
                              type="button"
                              size="sm"
                              outline
                              onClick={() => props.history.push('/pricelists/edit/' + row.id)}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-edit" />
                              </span>
                              <span className="btn-inner--text"> Ubah</span>
                            </Button>
                          </Can>
                        </>
                      );
                    }
                  }
                ]}
                search
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                        <label>
                          Search:
                          <SearchBar delay={500} className="form-control-sm" placeholder="" {...props.searchProps} />
                        </label>
                      </div>
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default TablePricelist;
