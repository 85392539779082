import { Chart } from 'chart.js';
import React from 'react';

import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row
} from 'reactstrap';

import { chartOptions, parseOptions } from '../../../../variables/charts';

import Loading from '../../../../components/Loader/Loader';
import DashboardService from '../../../../services/DashboardService';

import Can from '../../../../config/Can';
import MultiChart from './MultiChart';

import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import moment from 'moment';
import 'moment/locale/id';
import Swal from 'sweetalert2';
import OrderService from '../../../../services/OrderService';
import ReportService from '../../../../services/ReportService';
import Platform from './Platform';
import ChartPlatform from './Platform/ChartPlatform';

moment.locale('id');
am4core.useTheme(am4themes_material);

class DashboardLeads extends React.Component {
  state = {
    month: '',
    year: '',
    leads: {
      total: {
        budget: 0,
        cpl: 0,
        leads: 0
      },
      meta: {
        budget: 0,
        cpl: 0,
        leads: 0
      },
      google: {
        budget: 0,
        cpl: 0,
        leads: 0
      },
      tiktok: {
        budget: 0,
        cpl: 0,
        leads: 0
      },
      dm: {
        budget: 0,
        cpl: 0,
        leads: 0
      },
      messenger: {
        budget: 0,
        cpl: 0,
        leads: 0
      },
      youtube: {
        budget: 0,
        cpl: 0,
        leads: 0
      },
      dm_tiktok: {
        budget: null,
        cpl: null,
        leads: 0
      },
      link_bio: {
        budget: null,
        cpl: null,
        leads: 0
      },
      share: {
        budget: null,
        cpl: null,
        leads: 0
      },
      organic: {
        budget: null,
        cpl: null,
        leads: 0
      }
    },
    charts: {
      leads_summary: {
        labels: ['META ADS', 'GOOGLE ADS', 'TIKTOK ADS', 'DM ADS', 'MESSENGER ADS', 'YOUTUBE ADS', 'LAIN-LAIN'],
        datasets: [
          {
            labels: 'Leads',
            data: [0, 0, 0, 0, 0, 0]
          }
        ]
      },
      leads_daily: {
        labels: [],
        datasets: [
          {
            data: []
          },
          {
            data: []
          }
        ]
      }
    },
    reasons: [],
    treatments: [],
    loading: false,
    error: null,
    unitOptions: [],
    picOptions: [],
    pic: '',
    unit_id: '',
    startDate: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    activeNav: 1
  };

  interval; // handle set interval cycle

  startDateRef = React.createRef();
  endDateRef = React.createRef();

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }

    if (this.chart) {
      this.chart.dispose();
    }
  }

  async componentDidMount() {
    this.getData();
    this.getUnitOptions();
    this.interval = setInterval(() => {
      this.getData();
    }, 1000 * 15 * 60); // 15 menit
  }

  getUnitOptions = () => {
    this.setState({ loading: true });
    OrderService.getUnitOptions()
      .then(({ data }) => this.setState({ unitOptions: data }))
      .catch(error => console.log(error))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleChangeUnit = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        error: ''
      },
      () => {
        if (this.state.unit_id) {
          this.getListPic();
        } else {
          this.setState({ pic: '', picOptions: [] });
        }
      }
    );
  };

  getListPic = () => {
    this.setState({ loading: true });
    ReportService.getCsList({ unit_id: this.state.unit_id })
      .then(({ data: res }) => this.setState({ pic: '', picOptions: res.data }))
      .catch(error => console.log(error))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getData = async () => {
    const { startDate, endDate, pic, unit_id } = this.state;

    try {
      this.setState({ loading: true });
      const { data } = await DashboardService.getDashboardLeads({
        start_date: startDate,
        end_date: endDate,
        user_id: pic,
        unit_id
      });

      this.amchartConfig(data);

      this.setState({
        ...data,
        loading: false
      });

      // if (data.provinces && data.provinces.length > 0 && ability.can('read', 'Dashboard Province')) {
      //   this.amchartConfig();
      // }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  createSeries = chart => {
    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.category = 'name';
    series.dataFields.value = 'value';
    series.labels.template.adapter.add('radius', function(radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return 0;
      }
      return radius;
    });

    series.labels.template.adapter.add('fill', function(color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return am4core.color('#000');
      }
      return color;
    });
  };

  amchartConfig = async data => {
    let reasonChart = am4core.create('reason-charts', am4charts.PieChart3D);
    const reasonsData = data.reasons.map(oke => ({
      name: oke.name,
      value: oke.qty
    }));

    reasonChart.data = reasonsData;
    const reasonChartTitle = reasonChart.titles.create();
    reasonChartTitle.text = 'Alasan Belum Beli';
    reasonChartTitle.fontSize = 18;
    this.createSeries(reasonChart);
    reasonChart.legend = new am4charts.Legend();

    let treatmentChart = am4core.create('treatment-charts', am4charts.PieChart3D);
    const treatmentsData = data.treatments.map(oke => ({
      name: oke.name,
      value: oke.qty
    }));

    treatmentChart.data = treatmentsData;
    const treatmentChartTitle = treatmentChart.titles.create();
    treatmentChartTitle.text = 'Treatment';
    treatmentChartTitle.fontSize = 18;
    this.createSeries(treatmentChart);
    treatmentChart.legend = new am4charts.Legend();
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getMinEndDate = () => {
    if (this.state.startDate) {
      return new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate()))
        .toISOString()
        .split('T')[0];
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  getMaxEndDate = () => {
    if (this.state.startDate) {
      const awal = new Date(this.state.startDate);
      const today = new Date();

      const diff = Math.abs(moment.duration(moment(today).diff(awal)).asDays()) + 1;

      if (diff > 31) {
        return new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 30))
          .toISOString()
          .split('T')[0];
      } else {
        return new Date().toISOString().split('T')[0];
      }
    } else {
      return new Date().toISOString().split('T')[0];
    }
  };

  getCsList = async () => {
    try {
      const response = await ReportService.getCsList();

      if (response.status === 200) {
        this.setState({
          picOptions: response.data.data
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Terjadi kesalahan',
        text: error.message
      });
    }
  };

  handleSearch = async e => {
    e.preventDefault();

    const { startDate, endDate } = this.state;

    if (moment(startDate).isAfter(endDate) || moment.duration(moment(endDate).diff(moment(startDate))).asDays() > 31) {
      Swal.fire({
        title: 'Gagal Mengambil Data',
        text: 'Rentang tanggal harus valid dan tidak lebih dari 31 hari',
        icon: 'error'
      });
    } else {
      await this.getData();
    }
  };

  render() {
    const {
      total,
      meta,
      google,
      tiktok,
      dm,
      messenger,
      youtube,
      dm_tiktok,
      link_bio,
      share,
      organic
    } = this.state.leads;
    return (
      <>
        {this.state.loading && <Loading />}
        <div className="header bg-info pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="h2 text-white d-inline-block mb-0">Dashboard Leads</h6>
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="/" onClick={e => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      Manajemen Leads
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
              <Can I="read" a="Filter Dashboard Leads">
                <Row>
                  <Col>
                    <Card>
                      <CardHeader className="border-0">
                        <h3 className="mb-0">Filter Data</h3>
                      </CardHeader>
                      <CardBody>
                        {this.state.error && (
                          <Alert color="danger">
                            <ul>
                              <li>{this.state.error}</li>
                            </ul>
                          </Alert>
                        )}
                        <Form onSubmit={this.handleSearch}>
                          <Row>
                            <Col md="3">
                              <FormGroup>
                                <label htmlFor="unit_id">Unit</label>
                                <Input
                                  id="unit_id"
                                  type="select"
                                  name="unit_id"
                                  value={this.state.unit_id}
                                  onChange={this.handleChangeUnit}
                                >
                                  <option value="">-- Semua Unit --</option>
                                  {this.state.unitOptions.map((unit, key) => (
                                    <option key={key} value={unit.value}>
                                      {unit.name}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <label htmlFor="pic">List PIC</label>
                                <Input
                                  type="select"
                                  id="pic"
                                  value={this.state.pic}
                                  onChange={e => {
                                    this.setState({ pic: e.target.value });
                                  }}
                                >
                                  <option value="">--- Semua PIC ---</option>
                                  {this.state.picOptions.map((status, i) => (
                                    <option key={i} value={status.id}>
                                      {status.name}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <label htmlFor="startDate">Dari</label>
                                <Input
                                  id="startDate"
                                  type="date"
                                  name="startDate"
                                  // min={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
                                  max={new Date().toISOString().split('T')[0]}
                                  innerRef={this.startDateRef}
                                  onKeyDown={e => e.preventDefault()}
                                  value={this.state.startDate}
                                  onChange={e => {
                                    e.persist();
                                    this.setState({
                                      startDate: e.target.value,
                                      endDate: ''
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <label htmlFor="endDate">Sampai</label>
                                <Input
                                  id="endDate"
                                  type="date"
                                  name="endDate"
                                  // min={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
                                  // max={new Date().toISOString().split('T')[0]}
                                  min={this.getMinEndDate()}
                                  max={this.getMaxEndDate()}
                                  innerRef={this.endDateRef}
                                  onKeyDown={e => e.preventDefault()}
                                  value={this.state.endDate}
                                  onChange={e => {
                                    e.persist();
                                    this.setState({
                                      endDate: e.target.value
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <div className="text-right">
                                <Button size="md" disabled={this.state.loading} color="primary">
                                  Filter
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Can>
            </div>
          </Container>
        </div>

        <Container className="mt--6" fluid></Container>

        <Can I="read" a="Manajemen Leads">
          <Container className="mt--6" fluid>
            <Row>
              <Col xl="6">
                <MultiChart leads={this.state.charts.leads_daily} />
              </Col>
              <Col xl="6">
                <ChartPlatform leads={this.state.charts.leads_summary} />
              </Col>
            </Row>

            <Row style={{ marginTop: 18 }}>
              <Col md={12}>
                <Platform
                  ads="TOTAL LEADS"
                  platform="(All Platform)"
                  dailyBudget={total.budget}
                  costLeads={total.cpl}
                  totalLeads={total.leads}
                />
              </Col>
              <Col md={4}>
                <Platform ads="META ADS" dailyBudget={meta.budget} costLeads={meta.cpl} totalLeads={meta.leads} />
              </Col>

              <Col md={4}>
                <Platform
                  ads="GOOGLE ADS"
                  dailyBudget={google.budget}
                  costLeads={google.cpl}
                  totalLeads={google.leads}
                />
              </Col>

              <Col md={4}>
                <Platform
                  ads="TIKTOK ADS"
                  dailyBudget={tiktok.budget}
                  costLeads={tiktok.cpl}
                  totalLeads={tiktok.leads}
                />
              </Col>

              <Col md={4}>
                <Platform ads="DM INSTAGRAM ADS" dailyBudget={dm.budget} costLeads={dm.cpl} totalLeads={dm.leads} />
              </Col>

              <Col md={4}>
                <Platform
                  ads="MESSENGER FACEBOOK"
                  dailyBudget={messenger.budget}
                  costLeads={messenger.cpl}
                  totalLeads={messenger.leads}
                />
              </Col>

              <Col md={4}>
                <Platform
                  ads="YOUTUBE ADS"
                  dailyBudget={youtube.budget}
                  costLeads={youtube.cpl}
                  totalLeads={youtube.leads}
                />
              </Col>

              <Col md={3}>
                <Platform ads="DM TikTok" onlyLeads totalLeads={dm_tiktok.leads} />
              </Col>
              <Col md={3}>
                <Platform ads="Link Bio" onlyLeads totalLeads={link_bio.leads} />
              </Col>
              <Col md={3}>
                <Platform ads="Share" onlyLeads totalLeads={share.leads} />
              </Col>
              <Col md={3}>
                <Platform ads="Organik" onlyLeads totalLeads={organic.leads} />
              </Col>
            </Row>

            <Row style={{ marginTop: 18 }}>
              <Col md={6}>
                <Card>
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h5 className="h3 mb-0">Belum Beli</h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div id="reason-charts" style={{ width: '100%', height: '500px' }}></div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h5 className="h3 mb-0">Sudah Beli</h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div id="treatment-charts" style={{ width: '100%', height: '500px' }}></div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Can>
      </>
    );
  }
}

export default DashboardLeads;
