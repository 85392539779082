import { request } from '../config';

// versi function
function ShippingManagementService() {
  const datatableShipping = async params => {
    const response = await request.get('/shippings', { params });
    return response.data;
  };

  const shippingDetail = async order_id => {
    const response = await request.get(`/shippings/${order_id}`);
    return response.data;
  };

  const statusShipping = async () => {
    const response = await request.get('/shippings/status-options');
    return response.data;
  };

  const filterOptions = async () => {
    const response = await request.get('/shippings/filter-options');
    return response.data;
  };

  const shippingSchedules = async () => {
    const response = await request.get('/shippings/schedules');
    return response.data;
  };

  const requestPickup = async data => {
    const response = await request.post('/shippings/request-pickup', data);
    return response.data;
  };

  const moveToShippingManagement = async data => {
    const response = await request.post('/shippings/move', data);
    return response.data;
  };

  const printAwb = async order_id => {
    const response = await request.put(`/shippings/print-awb/${order_id}`);
    return response.data;
  };

  const cancelShipping = async (order_id, data) => {
    const response = await request.post(`/shippings/cancel-pickup/${order_id}`, data);
    return response.data;
  };

  const deleteShipping = async order_id => {
    const response = await request.delete(`/shippings/${order_id}`);
    return response.data;
  };

  const searchDestination = async params => {
    const response = await request.get('/subdistricts/search', { params });
    return response.data;
  };

  const checkShipping = async data => {
    const response = await request.post(`/shippings/cost`, data);
    return response.data;
  };

  return {
    datatableShipping,
    shippingDetail,
    filterOptions,
    statusShipping,
    shippingSchedules,
    moveToShippingManagement,
    requestPickup,
    printAwb,
    cancelShipping,
    deleteShipping,
    searchDestination,
    checkShipping
  };
}

export default ShippingManagementService();
