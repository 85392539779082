import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import imgSrc from '../assets/img/brand/coversuper_icon.png';
import AdminFooter from '../components/Footers/AdminFooter';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import Sidebar from '../components/Sidebar/Sidebar';
import Profile from '../views/pages/auth/Profile';
import Dashboard from '../views/pages/dashboards';
import EditPermission from '../views/pages/permission/EditPermission';
import Report from '../views/pages/reports';
import ReportFinance from '../views/pages/reports/ReportFinance';
import AddUser from '../views/pages/user/AddUser';
// import EditUser from '../views/pages/user/EditUser';
import ability from '../config/ability';
import AddAddon from '../views/pages/addon/AddAddon';
import EditAddon from '../views/pages/addon/EditAddon';
import TableAddon from '../views/pages/addon/TableAddon';
import AddBank from '../views/pages/bank/AddBank';
import EditBank from '../views/pages/bank/EditBank';
import TableBank from '../views/pages/bank/TableBank';
import AddCategory from '../views/pages/category/AddCategory';
import EditCategory from '../views/pages/category/EditCategory';
import TableCategory from '../views/pages/category/TableCategory';
import AddColor from '../views/pages/color/AddColor';
import EditColor from '../views/pages/color/EditColor';
import TableColor from '../views/pages/color/TableColor';
import OrderDetailCustomer from '../views/pages/customers/OrderDetailCustomer';
import TableCustomer from '../views/pages/customers/TableCustomer';
import AddManufacturer from '../views/pages/manufacturer/AddManufacturer';
import EditManufacturer from '../views/pages/manufacturer/EditManufacturer';
import TableManufacturer from '../views/pages/manufacturer/TableManufacturer';
import AddMaterial from '../views/pages/material/AddMaterial';
import EditMaterial from '../views/pages/material/EditMaterial';
import TableMaterial from '../views/pages/material/TableMaterial';
import EditOrder from '../views/pages/order/EditOrder';
import FormOrder from '../views/pages/order/FormOrder';
// import InvoiceOrder from '../views/pages/order/InvoiceOrder';
import ScanOrder from '../views/pages/order/ScanOrder';
import TableOrder from '../views/pages/order/TableOrder';
import TablePricelist from '../views/pages/pricelist/TablePricelist';
import AddProduct from '../views/pages/product/AddProduct';
import EditProduct from '../views/pages/product/EditProduct';
import TableProduct from '../views/pages/product/TableProduct';
import ProductionHistory from '../views/pages/production_history/ProductionHistory';
import ReviewComponent from '../views/pages/review/ReviewComponent';
import TableRole from '../views/pages/role/TableRole';
import AddTypeCar from '../views/pages/typecar/AddTypeCar';
import EditTypeCar from '../views/pages/typecar/EditTypeCar';
import TableTypeCar from '../views/pages/typecar/TableTypeCar';
import TableUser from '../views/pages/user/TableUser';
import TableVoucher from '../views/pages/voucher/TableVoucher';

import axios from 'axios';
import { request } from '../config';
import routes from '../routes.js';
import AddBannerSlide from '../views/pages/banner_slide/AddBannerSlide';
import EditBannerSlide from '../views/pages/banner_slide/EditBannerSlide';
import TableBannerSlide from '../views/pages/banner_slide/TableBannerSlide';
import AddEmagazine from '../views/pages/emagazine/AddEmagazine';
import EditEmagazine from '../views/pages/emagazine/EditEmagazine';
import TableEmagazine from '../views/pages/emagazine/TableEmagazine';
import AddMediaPartner from '../views/pages/media-partner/AddMediaPartner';
import EditMediaPartner from '../views/pages/media-partner/EditMediaPartner';
import TableMediaPartner from '../views/pages/media-partner/TableMediaPartner';
import DetailPageManagement from '../views/pages/pages-management/DetailPageManagement';
import TablePagesManagement from '../views/pages/pages-management/TablePagesManagement';
import EditPricelist from '../views/pages/pricelist/EditPricelist';
import AddProductDescription from '../views/pages/product_description/AddProductDescription';
import EditProductDescription from '../views/pages/product_description/EditProductDescription';
import TableProductDescription from '../views/pages/product_description/TableProductDescription';
import RefundPolicy from '../views/pages/refund_policy/RefundPolicy';
import AddVoucher from '../views/pages/voucher/AddVoucher';
import EditVoucher from '../views/pages/voucher/EditVoucher';
import WebSetting from '../views/pages/web_management/web_setting/index';

import AddComplaintCategory from '../views/pages/complaint_category/AddComplaintCategory';
import EditComplaintCategory from '../views/pages/complaint_category/EditComplaintCategory';
import TableComplaintCategory from '../views/pages/complaint_category/TableComplaintCategory';
import TableDeposit from '../views/pages/customers/deposit/TableDeposit';
import EditCustomer from '../views/pages/customers/EditCustomer';
import TableCourier from '../views/pages/master/courier/TableCourier';
import CoverImage from '../views/pages/master/cover-image/CoverImage';
import TablePaymentChannel from '../views/pages/master/payment-channel/TablePaymentChannel';
import AddStpUser from '../views/pages/master/stp-user/AddStpUser';
import EditStpUser from '../views/pages/master/stp-user/EditStpUser';
import TableStpUser from '../views/pages/master/stp-user/TableStpUser';
import AdvertiserReport from '../views/pages/reports/AdvertiserReport';
import BudgetDailyReport from '../views/pages/reports/BudgetDailyReport';
import BudgetRatioReport from '../views/pages/reports/BudgetRatioReport';
import KpiOmzet from '../views/pages/reports/KpiOmzet';
import ReportDealmaker from '../views/pages/reports/ReportDealmaker';
import ReportHistoryOrder from '../views/pages/reports/ReportHistoryOrder';
import AddFaq from '../views/pages/web_management/faq/AddFaq';
import EditFaq from '../views/pages/web_management/faq/EditFaq';
import TableFaq from '../views/pages/web_management/faq/TableFaq';
import AddFaqCategory from '../views/pages/web_management/faq_category/AddFaqCategory';
import EditFaqCategory from '../views/pages/web_management/faq_category/EditFaqCategory';
import TableFaqCategory from '../views/pages/web_management/faq_category/TableFaqCategory';
import AddTestimonial from '../views/pages/web_management/testimonial/AddTestimonial';
import EditTestimonial from '../views/pages/web_management/testimonial/EditTestimonial';
import TableTestimonial from '../views/pages/web_management/testimonial/TableTestimonial';
import AddTips from '../views/pages/web_management/tips/AddTips';
import EditTips from '../views/pages/web_management/tips/EditTips';
import TableTips from '../views/pages/web_management/tips/TableTips';
// import TableFinance from '../views/pages/finance/TableFinance';
import DetailBilling from '../views/pages/customers/billing/DetailBilling';
import TableBilling from '../views/pages/customers/billing/TableBilling';
import FinanceBilling from '../views/pages/finance/billing/FinanceBilling';
import FinanceDeposit from '../views/pages/finance/deposit/FinanceDeposit';
import FinancePayout from '../views/pages/finance/payout/FinancePayout';
import EditUserNew from '../views/pages/user/EditUserNew';
import TableVoucherUsage from '../views/pages/voucher/VoucherUsage';

import Affiliate from '../views/pages/affiliate/Affiliate';
import TableAffiliate from '../views/pages/affiliate/TableAffiliate';
import TableAffiliateVoucher from '../views/pages/affiliate/voucher/TableAffiliateVoucher';
import Register from '../views/pages/auth/Register';
import Selling from '../views/pages/master/selling/Selling';
import Tracking from '../views/pages/master/tracking/Tracking';

import { ROLE_AFFILIATE } from '../constants';
import DashboardAffiliate from '../views/pages/affiliate/dashboard/DashboardAffiliate';
import DataReportAffiliasi from '../views/pages/affiliate/DataReportAffiliasi';
import UpdateProduct from '../views/pages/affiliate/UpdateProduct';
import AddAffiliateVoucher from '../views/pages/affiliate/voucher/AddAffiliateVoucher';
import TableAffiliateVoucherUsage from '../views/pages/affiliate/voucher/AffiliateVoucherUsage';
import EditAffiliateVoucher from '../views/pages/affiliate/voucher/EditAffiliateVoucher';
import TableTax from '../views/pages/finance/faktur-pajak/TableTax';
import DashboardLeads from '../views/pages/management-leads/dashboard';
import FormLeads from '../views/pages/management-leads/FormLeads';
import TableLeadsReason from '../views/pages/management-leads/leads-reason/TableLeadsReason';
import TableLeads from '../views/pages/management-leads/TableLeads';
import Payout from '../views/pages/master/payout/Payout';
import ShippingManagement from '../views/pages/shipping/ShippingManagement';
import CheckShipping from '../views/pages/shipping/CheckShipping';
import Invoice from '../views/pages/order/Invoice';

class Global extends React.Component {
  state = {
    sidenavOpen: true,
    reviewCategoryLists: []
  };

  axiosCancelSource = axios.CancelToken.source();

  mainContentRef = React.createRef();

  componentDidMount() {
    this.getListReviewCategory();
  }

  getListReviewCategory = async () => {
    try {
      const response = await request.get('/categories/list', {
        cancelToken: this.axiosCancelSource.token
      });
      if (response.status === 200) {
        this.setState({
          reviewCategoryLists: response.data.data
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.mainContentRef.current.scrollTop = 0;
    }
  }

  // CEK Permission Account Affiliate and Another Account
  checkPermission = () => {
    if (parseInt(localStorage.getItem('role')) === ROLE_AFFILIATE) {
      return <Route path="/dashboard" component={Affiliate} />;
    } else {
      return <Route path="/dashboard" component={Dashboard} />;
    }
  };

  getRoutes = routes => {
    const routeList = routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      if (prop.layout === '/admin' || prop.layout === '/global') {
        console.log(prop.path, prop.exact);
        return <Route path={prop.path} exact={prop.exact} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
    return routeList;
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };

  componentWillUnmount() {
    this.axiosCancelSource.cancel('Axios request canceled.');
  }

  render() {
    // const usr = localStorage.getItem('role');
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: '/',
            imgSrc: imgSrc,
            imgAlt: '...'
          }}
        />
        <div className="main-content" ref={this.mainContentRef} onClick={this.closeSidenav}>
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {/* {this.getRoutes(routes)} */}
            {ability.can('read', 'Menu Dashboard') ? this.checkPermission() : <Redirect to="/login" />}

            {/* REGISTER */}
            <Route path="/register" exact component={Register} />
            <Route path="/affiliate" exact component={Affiliate} />

            {ability.can('read', 'Profile') && <Route path="/profile" exact={false} component={Profile} />}
            {ability.can('read', 'Review') && <Route path={`/reviews`} component={ReviewComponent} />}

            {ability.can('read', 'Report Finance') && (
              <Route path="/reports/finance" exact={false} component={ReportFinance} />
            )}
            {ability.can('read', 'Report Platform') && <Route path="/reports/platform" component={Report} />}
            {ability.can('read', 'Report Brand') && <Route path="/reports/brand" component={Report} />}
            {ability.can('read', 'Report Category') && <Route path="/reports/cover" component={Report} />}
            {ability.can('read', 'Report Dealmaker') && <Route path="/reports/dealmaker" component={ReportDealmaker} />}
            {ability.can('read', 'Kpi Omzet') && <Route path="/reports/kpi-omzet" exact component={KpiOmzet} />}
            {ability.can('read', 'Report Color') && <Route path="/reports/color" component={Report} />}
            {ability.can('read', 'Report City') && <Route path="/reports/cities" component={Report} />}
            {ability.can('read', 'Report Order Source') && <Route path="/reports/order-source" component={Report} />}
            {ability.can('read', 'Report Product') && <Route path="/reports/product" component={Report} />}
            {ability.can('read', 'History Order') && (
              <Route path="/reports/history-order" exact component={ReportHistoryOrder} />
            )}
            {ability.can('read', 'Report Budget Daily') && (
              <Route path="/reports/budget-daily" exact component={BudgetDailyReport} />
            )}
            {ability.can('read', 'Report Advertiser') && (
              <Route path="/reports/advertiser" exact component={AdvertiserReport} />
            )}
            {ability.can('read', 'Report Budget Ratio') && (
              <Route path="/reports/budget-ratio" exact component={BudgetRatioReport} />
            )}

            {ability.can('read', 'Manajemen Leads') && (
              <Route path="/manajemen/leads/dashboard" exact component={DashboardLeads} />
            )}
            {ability.can('read', 'Manajemen Leads') && <Route path="/manajemen/leads" exact component={TableLeads} />}
            {ability.can('read', 'Leads Reason') && <Route path="/leads/reason" exact component={TableLeadsReason} />}
            {ability.can('create', 'Manajemen Leads') && <Route path="/leads/add" component={FormLeads} />}

            {ability.can('manage', 'Permission') && (
              <Route path="/role-permissions/:id" component={EditPermission} exact={false} />
            )}
            {ability.can('read', 'Role') && <Route path="/roles" exact={true} component={TableRole} />}
            {ability.can('read', 'User') && <Route path="/users" exact={true} component={TableUser} />}
            {ability.can('create', 'User') && <Route path="/users/add" component={AddUser} />}
            {ability.can('update', 'User') && <Route path="/users/edit/:id" component={EditUserNew} />}
            {ability.can('read', 'Category') && <Route path="/categories" exact={true} component={TableCategory} />}
            {ability.can('create', 'Category') && <Route path="/categories/add" component={AddCategory} />}
            {ability.can('update', 'Category') && <Route path="/categories/edit/:id" component={EditCategory} />}
            {ability.can('read', 'Color') && <Route path="/colors" exact={true} component={TableColor} />}
            {ability.can('create', 'Color') && <Route path="/colors/add" component={AddColor} />}
            {ability.can('update', 'Color') && <Route path="/colors/edit/:id" component={EditColor} />}
            {ability.can('read', 'Material') && <Route path="/materials" exact={true} component={TableMaterial} />}
            {ability.can('create', 'Material') && <Route path="/materials/add" component={AddMaterial} />}
            {ability.can('update', 'Material') && <Route path="/materials/edit/:id" component={EditMaterial} />}
            {ability.can('read', 'Bank') && <Route path="/banks" exact={true} component={TableBank} />}
            {ability.can('update', 'Bank') && <Route path="/banks/edit/:id" component={EditBank} />}
            {ability.can('create', 'Bank') && <Route path="/banks/add" component={AddBank} />}
            {ability.can('read', 'Payment Channel') && (
              <Route path="/payment-channels" exact={true} component={TablePaymentChannel} />
            )}
            {ability.can('read', 'Courier') && <Route path="/couriers" exact={true} component={TableCourier} />}
            {/* {ability.can('update', 'Payment Channel') && <Route path="/payment-channels/edit/:id" component={EditBank} />}
            {ability.can('create', 'Payment Channel') && <Route path="/payment-channels/add" component={AddBank} />} */}
            {ability.can('read', 'Manufacturer') && (
              <Route path="/manufacturers" exact={true} component={TableManufacturer} />
            )}
            {ability.can('update', 'Manufacturer') && (
              <Route path="/manufacturers/edit/:id" component={EditManufacturer} />
            )}
            {ability.can('create', 'Manufacturer') && <Route path="/manufacturers/add" component={AddManufacturer} />}
            {ability.can('read', 'Voucher') && <Route path="/vouchers" exact={true} component={TableVoucher} />}
            {ability.can('read', 'Voucher') && (
              <Route path="/vouchers/:id/orders" exact={true} component={TableVoucherUsage} />
            )}
            {ability.can('create', 'Voucher') && <Route path="/vouchers/add" component={AddVoucher} />}
            {ability.can('update', 'Voucher') && <Route path="/vouchers/edit/:id" component={EditVoucher} />}

            {ability.can('read', 'Affiliate') && (
              <Route path="/data-affiliate" exact={true} component={TableAffiliate} />
            )}

            {ability.can('update', 'Commission') && (
              <Route path="/affiliate/update-product" exact={true} component={UpdateProduct} />
            )}

            {ability.can('read', 'Slide') && <Route path="/banner-slides" exact={true} component={TableBannerSlide} />}
            {ability.can('read', 'Menu Web Management') && (
              <Route path="/websetting" exact={true} component={WebSetting} />
            )}
            {ability.can('create', 'Slide') && <Route path="/banner-slides/add" component={AddBannerSlide} />}
            {ability.can('update', 'Slide') && <Route path="/banner-slides/edit/:id" component={EditBannerSlide} />}
            {ability.can('read', 'Pricelist') && <Route path="/pricelists" exact={true} component={TablePricelist} />}
            {ability.can('update', 'Pricelist') && <Route path="/pricelists/edit/:id" component={EditPricelist} />}
            {ability.can('read', 'Product') && <Route path="/products" exact={true} component={TableProduct} />}
            {ability.can('create', 'Product') && <Route path="/products/add" component={AddProduct} />}
            {ability.can('update', 'Product') && <Route path="/products/edit/:id" component={EditProduct} />}
            {ability.can('read', 'Addon') && <Route path="/addons" exact={true} component={TableAddon} />}
            {ability.can('create', 'Addon') && <Route path="/addons/add" component={AddAddon} />}
            {ability.can('update', 'Addon') && <Route path="/addons/edit/:id" component={EditAddon} />}
            {ability.can('read', 'E-Magazine') && <Route path="/emagazine" exact={true} component={TableEmagazine} />}
            {ability.can('create', 'E-Magazine') && <Route path="/emagazine/add" component={AddEmagazine} />}
            {ability.can('update', 'E-Magazine') && <Route path="/emagazine/edit/:id" component={EditEmagazine} />}
            {ability.can('read', 'Category Detail') && (
              <Route path="/product_description/:category_id" exact={true} component={TableProductDescription} />
            )}
            {ability.can('create', 'Category Detail') && (
              <Route path="/product_description/:category_id/add" component={AddProductDescription} />
            )}
            {ability.can('update', 'Category Detail') && (
              <Route path="/product_description/:category_id/edit/:id" component={EditProductDescription} />
            )}
            {ability.can('read', 'Cover Image') && <Route path="/cover-images" component={CoverImage} />}
            {ability.can('read', 'Type Car') && <Route path="/typecars" exact={true} component={TableTypeCar} />}
            {ability.can('create', 'Type Car') && <Route path="/typecars/add" component={AddTypeCar} />}
            {ability.can('update', 'Type Car') && <Route path="/typecars/edit/:id" component={EditTypeCar} />}
            {ability.can('read', 'Order') && <Route path="/orders" exact={true} component={TableOrder} />}
            {ability.can('create', 'Order') && <Route path="/orders/add" exact={true} component={FormOrder} />}
            {ability.can('update', 'Order') && <Route path="/orders/edit/:id" exact={true} component={EditOrder} />}
            {ability.can('scan', 'Order') && <Route path="/scan" component={ScanOrder} />}
            {ability.can('read', 'Invoice') && <Route path="/invoice/:order_number" component={Invoice} />}
            {ability.can('read', 'Customer') && <Route path="/customers" exact={true} component={TableCustomer} />}
            {ability.can('manage', 'Shipping') && (
              <Route path="/shipping-managements" exact={true} component={ShippingManagement} />
            )}
            {ability.can('check', 'Shipping Cost') && (
              <Route path="/check-shipping" exact={true} component={CheckShipping} />
            )}

            {ability.can('read', 'Deposit') && (
              <Route path="/customers/:customer_id/deposit" exact={true} component={TableDeposit} />
            )}
            {ability.can('read', 'Billing') && (
              <Route path="/customers/:customer_id/billing" exact={true} component={TableBilling} />
            )}

            {ability.can('read', 'Billing') && (
              <Route path="/customers/:customer_id/billing/:billing_id" exact={true} component={DetailBilling} />
            )}

            {ability.can('manage', 'Tax Invoice') && (
              <Route path="/finance/tax-invoice" exact={true} component={TableTax} />
            )}

            {ability.can('update', 'Customer') && (
              <Route path="/customers/edit/:id" exact={true} component={EditCustomer} />
            )}
            {ability.can('read', 'Menu Production History') && (
              <Route path="/production-history" component={ProductionHistory} />
            )}
            {ability.can('read', 'Customer Detail') && (
              <Route path="/customers/orders/:id" component={OrderDetailCustomer} />
            )}
            {ability.can('read', 'Page Management') && (
              <Route path="/pages-management" component={TablePagesManagement} />
            )}
            {ability.can('read', 'Page Management') && (
              <Route path="/page-management/:slug" component={DetailPageManagement} />
            )}
            {ability.can('read', 'Media Partner') && (
              <Route path="/media-partners" exact component={TableMediaPartner} />
            )}
            {ability.can('create', 'Media Partner') && (
              <Route path="/media-partners/add" exact component={AddMediaPartner} />
            )}
            {ability.can('update', 'Media Partner') && (
              <Route path="/media-partners/:id" exact component={EditMediaPartner} />
            )}
            {ability.can('read', 'Testimonial') && <Route path="/testimonials" exact component={TableTestimonial} />}
            {ability.can('create', 'Testimonial') && (
              <Route path="/testimonials/add" exact component={AddTestimonial} />
            )}
            {ability.can('update', 'Testimonial') && (
              <Route path="/testimonials/:id" exact component={EditTestimonial} />
            )}

            {ability.can('read', 'Deposit') && <Route path="/finance/deposit" exact component={FinanceDeposit} />}
            {ability.can('read', 'Billing') && <Route path="/finance/billing" exact component={FinanceBilling} />}
            {ability.can('manage', 'Finance Affiliate Payout') && (
              <Route path="/finance/payout" exact component={FinancePayout} />
            )}

            {ability.can('read', 'Complaint Category') && (
              <Route path="/complaint-category" exact component={TableComplaintCategory} />
            )}
            {ability.can('create', 'Complaint Category') && (
              <Route path="/complaint-category/add" exact component={AddComplaintCategory} />
            )}
            {ability.can('update', 'Complaint Category') && (
              <Route path="/complaint-category/:id/edit" exact component={EditComplaintCategory} />
            )}

            {ability.can('read', 'Tips') && <Route path="/tips" exact component={TableTips} />}
            {ability.can('create', 'Tips') && <Route path="/tips/add" exact component={AddTips} />}
            {ability.can('update', 'Tips') && <Route path="/tips/:id" exact component={EditTips} />}
            {ability.can('read', 'Faq') && (
              <Route path="/faq-categories/:faq_category_id/faqs" exact component={TableFaq} />
            )}
            {ability.can('create', 'Faq') && (
              <Route path="/faq-categories/:faq_category_id/faqs/add" exact component={AddFaq} />
            )}
            {ability.can('update', 'Faq') && (
              <Route path="/faq-categories/:faq_category_id/faqs/:faq_id/edit" exact component={EditFaq} />
            )}
            {ability.can('read', 'Faq Category') && <Route path="/faq-categories" exact component={TableFaqCategory} />}
            {ability.can('create', 'Faq Category') && (
              <Route path="/faq-categories/add" exact component={AddFaqCategory} />
            )}
            {ability.can('update', 'Faq Category') && (
              <Route path="/faq-categories/:id" exact component={EditFaqCategory} />
            )}
            <Route path="/stp-users" exact component={TableStpUser} />
            <Route path="/stp-users/add" exact component={AddStpUser} />
            <Route path="/stp-users/edit/:id" exact component={EditStpUser} />
            <Route path="/refund-policy" component={RefundPolicy} />
            {/* <Route render={() => <Redirect to="/not-found" />} /> */}

            {/* AFFILIATE */}
            {ability.can('read', 'Affiliate Charts') && <Route path="/affiliate/tracking" exact component={Tracking} />}
            {ability.can('read', 'Affiliate Sales') && <Route path="/affiliate/selling" exact component={Selling} />}

            {/* AFFILIATE VOUCHER */}
            {ability.can('read', 'Affiliate Voucher') && (
              <Route path="/affiliate/vouchers" exact component={TableAffiliateVoucher} />
            )}
            {ability.can('read', 'Affiliate Voucher') && (
              <Route path="/affiliate/vouchers/:id/orders" exact={true} component={TableAffiliateVoucherUsage} />
            )}
            {ability.can('create', 'Affiliate Voucher') && (
              <Route path="/affiliate/vouchers/add" component={AddAffiliateVoucher} />
            )}
            {ability.can('update', 'Affiliate Voucher') && (
              <Route path="/affiliate/vouchers/edit/:id" component={EditAffiliateVoucher} />
            )}
            {/* END AFFILIATE VOUCHER */}

            {ability.can('read', 'Affiliate Payout') && <Route path="/affiliate/payout" exact component={Payout} />}

            {ability.can('read', 'Affiliasi Overview') && (
              <Route path="/affiliasi/overview" exact component={DashboardAffiliate} />
            )}
            {ability.can('read', 'Report Affiliator') && (
              <Route path="/report/affiliasi" exact component={DataReportAffiliasi} />
            )}
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? <div className="backdrop d-xl-none" onClick={this.toggleSidenav} /> : null}
      </>
    );
  }
}

export default Global;
