import React, { useState, useRef } from 'react';
import { Button, Card, CardBody, Form, Input, Container, Row, Col, Label, FormGroup } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Swal from 'sweetalert2';
import MediaPartnerService from '../../../services/MediaPartnerService';
import { withRouter } from 'react-router';
import { useEffect } from 'react';

function EditMediaPartner(props) {
  // const [image, setImage] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [link, setLink] = useState('');
  const [is_active, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const imageRef = useRef();

  useEffect(() => {
    async function getDetailData() {
      try {
        const response = await MediaPartnerService.detail(props.match.params.id);
        if (response.status === 200) {
          const images = [];
          images.push(response.data.image);
          setImageURL(images);

          // setImage(response.data.image);
          setLink(response.data.link);
          setIsActive(!!response.data.is_active);
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi kesalahan saat mengambil detail data'
        });
      }
    }

    getDetailData();
  }, []);

  async function handleImage(e) {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    const formData = new FormData();
    for (const file of e.target.files) {
      formData.append('file', file);
    }

    try {
      setLoading(true);
      const image = await MediaPartnerService.uploadImage(formData);

      if (image.message === 'OK') {
        setImageURL(image.data);
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error('Terjadi kesalahan oada saat upload bukti transfer.');
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        text: error.message
      });
    }
  }

  function setNotValid({ current }) {
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  function isValid() {
    if (imageURL === '' || imageURL.length <= 0) {
      return setNotValid(imageRef);
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      const data = {
        image: imageURL[0],
        link,
        is_active
      };

      setLoading(true);
      try {
        const response = await MediaPartnerService.edit(props.match.params.id, data);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push('/media-partners')
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: 'Terjadi kesalahan saat mengubah data',
          icon: 'error',
          text: error
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Edit Media Partner" parentName="Tabel Media Partner" parentLink="/banner-slides" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="customFileLang">
                        Gambar Media Partner
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </Label>
                      <div className="custom-file">
                        <Input
                          className="custom-file-input"
                          id="customFileLang"
                          lang="en"
                          innerRef={imageRef}
                          type="file"
                          onChange={handleImage}
                        />
                        <label className="custom-file-label" htmlFor="customFileLang">
                          Pilih file
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    {(imageURL !== '' || imageURL.length > 0) &&
                      imageURL.map((image, i) => (
                        <img key={i} src={image} alt="order evidence url" width="200" style={{ marginRight: 5 }} />
                      ))}
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Link tujuan (opsional)</Label>
                      <Input
                        type="text"
                        name="link"
                        placeholder="coversuper.com"
                        defaultValue={link}
                        onChange={e => {
                          setLink(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>Deskripsi</Label>
                      <Input
                        type="textarea"
                        name="description"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="validationStatus">
                        Status Aktif
                      </label>
                      <br />
                      <label className="custom-toggle">
                        <input
                          value={is_active}
                          checked={is_active}
                          type="checkbox"
                          name="is_active"
                          onChange={e => setIsActive(!is_active)}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Tidak"
                          data-label-on="Aktif"
                        />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Button disabled={loading} color="primary" className="btn btn-sm" type="submit">
                    Ubah
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default withRouter(EditMediaPartner);
