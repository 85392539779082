import React from 'react';
import { Card, CardBody, Row, CardTitle } from 'reactstrap';

const CardReport = props => (
  <Card className="card-stats">
    <CardBody className="text-center">
      <Row>
        <div className="col">
          <CardTitle tag="h5" className="text-uppercase text-muted mb-1">
            {props.name}
          </CardTitle>
          <span className="h2 font-weight-bold mb-0">
            {props.value ? <>{new Intl.NumberFormat('id-ID').format(props.value)}</> : <>{props.value}</>}
          </span>
        </div>
      </Row>
    </CardBody>
  </Card>
);

export default CardReport;
