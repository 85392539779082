import React from 'react';

import QrCode from 'qrcode.react';

import moment from 'moment';
import { Badge, Col, Row, Table } from 'reactstrap';
import Swal from 'sweetalert2';

import html2canvas from 'html2canvas';
import logo from '../../../assets/img/brand/coversuper_icon.png';
import logo_label from '../../../assets/img/brand/label_coversuper.png';
import alertIcon from '../../../assets/img/icons/alert-icon.png';
import * as Constant from '../../../constants';
import { GREAT_TEAM } from '../../../constants';
import OrderService from '../../../services/OrderService';
import './PrintLabel.css';

class PrintLabel extends React.Component {
  state = {
    flow_id: this.props.match.params.flow_id,
    data: {
      cover_type_id: 0,
      is_complaint: 0,
      estimation: '',
      complaint: {
        lead_time: '',
        category: {
          name: ''
        },
        complaint: '',
        solution: ''
      },
      product_name: '',
      transmission_type: '',
      addons: [{ addon_id: '' }],
      cover_type: {
        name: ''
      },
      color: {
        name: ''
      },
      logo: '',
      category: {
        id: null,
        name: '',
        vehicle_type_id: ''
      },
      order: {
        unit_id: null,
        refund: {
          approval_status_id: 0
        },
        invoice_number: '',
        grand_total: 0,
        payment_histories: [
          {
            evidences: [{ image: '' }]
          }
        ],
        payment_status_id: null,
        platform: '',
        shipping_address: {
          name: '',
          address: '',
          subdistrict: '',
          district: '',
          province: '',
          phone: '',
          phone_alt: '',
          postal_code: ''
        },
        payment_status: {
          name: ''
        },
        courier_code: '',
        service_code: '',
        service_name: '',
        customer: {
          name: ''
        },
        customer_service: {
          name: ''
        },
        customer_type: {
          id: 0,
          name: ''
        },
        supplier: {
          name: '',
          address: '',
          subdistrict: '',
          district: '',
          province: '',
          phone: ''
        },
        details: [
          {
            addons: [{ addon_id: '' }],
            note: '',
            qty: 0,
            category: {
              name: '',
              vehicle_type_id: null
            },
            type_car: {
              name: ''
            },
            manufacturer: {
              name: ''
            },
            logo: '',
            product: {
              name: '',
              year: 0,
              color_name: '',
              category: {
                name: ''
              }
            },
            variant: {
              material: {
                name: ''
              },
              color: {
                name: ''
              },
              year: 0,
              size: ''
            }
          }
        ]
      },
      histories: [],
      customer_service: {
        name: ''
      },
      courier_code: '',
      service_code: '',
      service_name: '',
      order_description: '',
      details: [
        {
          status_type_id: 3,
          created_at: '',
          description: '',
          status: {
            id: 6,
            name: ''
          },
          pic: {
            name: ''
          }
        }
      ],
      pic: {
        name: ''
      },
      product: {
        manufacturer: {
          name: ''
        }
      },
      note: '',
      invoice_image: '',
      variant: { size: '' }
    }
  };

  componentDidMount() {
    this.getOrder();
    window.onscroll = function() {
      // window.scrollTo(0, 0);
    };
  }

  getCanvas = async () => {
    setTimeout(() => {
      const content = document.getElementById('print-label-component');
      content.setAttribute('style', 'padding: 15px');
      const mywindow = window.open('', 'Print');
      html2canvas(content).then(canvas => {
        const border = document.createElement('div');
        if (border) {
          border.setAttribute('id', 'border');
          mywindow.document.body.appendChild(border);
          mywindow.document.getElementById('border').appendChild(canvas);
          mywindow.print();
          window.close();
        }
      });
    }, 1000);
  };

  getOrder = async () => {
    try {
      const order = await OrderService.findByOrderDetail(this.state.flow_id);
      this.setState(
        {
          data: order.data
        },
        () => {
          this.getCanvas();
        }
      );
    } catch (error) {
      console.log(error.message);
      Swal.fire({
        icon: 'error',
        title: 'terjadi kesalahan',
        text: error.message
      });
    }
  };

  renderText(detail) {
    let categoryName = '';
    let productName = detail.product_name;
    let year = '';
    let colorName = '';
    let coverType = '';
    let coverList = '';
    let size = '';
    let transmission_type = '';

    if (detail.category) {
      categoryName =
        detail.category.name +
        (detail.category.vehicle_type_id === Constant.TYPE_KARPET ? ' ' + detail.variant.size : '');
    }

    if ([Constant.CATEGORY_COVER_SETIR].includes(detail.category.id)) {
      year = detail.year;
    }

    if (detail.color) {
      colorName = detail.color.name;
    }

    if (detail.cover_type) {
      if (
        ![Constant.CATEGORY_COVER_MOTOR, Constant.CATEGORY_COVER_SEPEDA, Constant.CATEGORY_COVER_SETIR].includes(
          detail.category.id
        )
      ) {
        coverType = detail.cover_type.name;
      }
    }

    if (detail.cover_list) {
      coverList = `list ${detail.cover_list.name}`;
    }
    if (detail.transmission_type) {
      transmission_type = `Transmisi ${this.state.data.transmission_type}`;
    }

    if ([Constant.CATEGORY_COVER_MOTOR, Constant.CATEGORY_COVER_SEPEDA].includes(detail.category.id)) {
      size = `(SIZE ${detail.variant.size})`;
    }

    return [categoryName, productName, transmission_type, year, colorName, coverType, coverList, size].join(' ');
  }

  renderDetailPesanan = () => {
    let details;

    if (this.state.data.is_complaint) {
      details = this.state.data.order.details.filter(mentah => mentah.is_complaint === 1);
    } else {
      details = this.state.data.order.details;
    }

    return details.map((detail, key) => {
      let hasSablon = false;
      if (detail.addons.length > 0) {
        hasSablon =
          detail.addons.filter(addon =>
            [Constant.ADDON_SABLON, Constant.ADDON_SABLON2, Constant.ADDON_SABLON3].includes(addon.addon_id)
          ).length > 0;
      }

      return (
        <tr key={key} style={hasSablon ? { backgroundColor: 'yellow', color: '#fff' } : {}}>
          <td>
            <p>{this.renderText(detail)}</p>
          </td>
          <td style={{ background: '#ccc' }}>{detail.qty}</td>
          <td>{detail.note}</td>
        </tr>
      );
    });
  };

  render() {
    const {
      shipping_address,
      supplier,
      // courier_code,
      courier_name,
      service_code,
      service_name,
      order_description,
      details,
      customer,
      customer_type,
      unit_id
    } = this.state.data.order;

    const dropshipper = customer_type.id === Constant.CUSTOMER_BIG_SELLER;

    let withSablon = false;
    if (this.state.data.addons.length > 0) {
      withSablon =
        this.state.data.addons.filter(addon =>
          [Constant.ADDON_SABLON, Constant.ADDON_SABLON2, Constant.ADDON_SABLON3].includes(addon.addon_id)
        ).length > 0;
    }

    let stripGreen = false;
    if (
      [Constant.COVER_TYPE_FANTASY, Constant.COVER_TYPE_VIPER, Constant.COVER_TYPE_SPORTY].includes(
        this.state.data.cover_type_id
      )
    ) {
      stripGreen = true;
    }

    return (
      <>
        <div className="container">
          <div id="print-label-component">
            <Row>
              <Col md="12">
                <br />
                <Row>
                  {this.state.data.order.customer_type.id !== Constant.CUSTOMER_BIG_SELLER ? (
                    <>
                      <Col md="6" className="text-left">
                        <img className="text-left" src={logo} width="250px" alt="" />
                      </Col>
                      <Col md="6" className="text-right">
                        {moment().format('DD-MM-YYYY HH:mm')} <br />
                        <span style={{ fontWeight: 'bolder' }}>
                          {courier_name ? courier_name.toUpperCase() : ''} - {service_code} ({service_name})
                        </span>
                        <br />
                        {`${this.state.data.order.order_number} ${this.state.data.order.customer_service.name}`}
                        <br />
                        {!!this.state.data.is_complaint && (
                          <Badge
                            className="badge-md"
                            style={{
                              background: '#ffbb00',
                              color: '#000'
                            }}
                          >
                            Komplain
                          </Badge>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md="12" className="text-right">
                        {moment().format('DD-MM-YYYY HH:mm')} <br />
                        <span
                          style={{
                            fontWeight: 'bolder'
                          }}
                        >
                          {courier_name ? courier_name.toUpperCase() : ''} - {service_code}
                        </span>
                        <br />
                        {`${this.state.data.order.order_number} ${this.state.data.order.customer_service.name}`}
                        <br />
                        {!!this.state.data.is_complaint && (
                          <Badge
                            className="badge-md"
                            style={{
                              background: '#ffbb00',
                              color: '#000'
                            }}
                          >
                            Komplain
                          </Badge>
                        )}
                      </Col>
                    </>
                  )}
                </Row>
                <Row className="mt-4">
                  <Col xs="6">
                    <p style={{ fontSize: 19, fontWeight: 'bolder' }}>
                      <strong>Kepada</strong>
                      <br />
                      {shipping_address.name}
                      <br />
                      {shipping_address.address}, {shipping_address.subdistrict.name}, {shipping_address.district.type}{' '}
                      {shipping_address.district.name}, {shipping_address.province.name}, {shipping_address.postal_code}
                      <br />
                      Telp : {shipping_address.phone}{' '}
                      {shipping_address.phone_alt ? '/ ' + shipping_address.phone_alt : ''}
                    </p>
                  </Col>
                  <Col xs="6">
                    <p style={{ fontSize: 19 }}>
                      <strong>Pengirim</strong> <br />
                      {dropshipper ? (
                        <>
                          <strong>{customer.name}</strong> <br />
                          {customer.address}, {customer.subdistrict.name}, {customer.district.type}{' '}
                          {customer.district.name}, {customer.province.name}
                          <br />
                          Telp : {customer.phone}
                        </>
                      ) : (
                        <>
                          <strong>
                            {supplier.name} {unit_id === GREAT_TEAM ? ' Indonesia Global' : ''}
                          </strong>{' '}
                          <br />
                          {supplier.address}, {supplier.subdistrict.name}, {supplier.district.type}{' '}
                          {supplier.district.name}, {supplier.province.name}
                          <br />
                          Telp : {supplier.phone}
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
                <div>
                  <Table bordered>
                    <thead>
                      <tr style={{ background: unit_id === GREAT_TEAM ? '#E62131' : 'black', color: '#fff' }}>
                        <th style={{ color: '#fff' }} width="50%">
                          Pesanan
                        </th>
                        <th style={{ color: '#fff' }}>qty</th>
                        <th style={{ color: '#fff' }} width="50%">
                          Catatan
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderDetailPesanan()}
                      <tr>
                        <td>
                          <p>Jumlah Pesanan</p>
                        </td>
                        <td style={{ background: '#ccc', color: '#E62131', fontWeight: 'bolder' }}>
                          {details.map(a => a.qty).reduce((a, b) => a + b)}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                  <Row>
                    <Col
                      xs="10"
                      style={{
                        zIndex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>
                        {!this.state.data.is_complaint && (
                          <>
                            <span>
                              Status Pembayaran:{' '}
                              <Badge
                                className="badge-md"
                                style={
                                  this.state.data.order.payment_status.name === 'Lunas'
                                    ? {
                                        background: '#22e24f',
                                        color: '#000'
                                      }
                                    : {
                                        background: 'rgb(230, 33, 49)',
                                        color: '#fff'
                                      }
                                }
                              >
                                {this.state.data.order.payment_status.name}
                              </Badge>
                            </span>
                            <br />
                            Keterangan / Bonus: {order_description}
                          </>
                        )}
                      </div>
                      {/* {!dropshipper && ( */}
                      <Row>
                        <Col xs="1">
                          <p>
                            <img src={alertIcon} alt="" style={{ maxHeight: '50px' }} />
                          </p>
                        </Col>
                        <Col xs="11">
                          <p
                            style={{
                              whiteSpace: 'nowrap',
                              zIndex: '100',
                              fontWeight: 'bold',
                              color: '#E62131',
                              fontSize: '1.1rem'
                            }}
                          >
                            PENTING!!!
                            <br />
                            HARAP VIDEOKAN SAAT MEMBUKA PAKET UNTUK KLAIM BARANG JIKA ADA KEKURANGAN. TERIMA KASIH.
                          </p>
                        </Col>
                      </Row>
                      {/* )} */}
                    </Col>
                    <Col
                      xs="2"
                      style={{ zIndex: '-100', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                    >
                      <img src={require('../../../assets/img/graphic.png')} width="150" alt="clip" />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr style={{ marginTop: 0 }} />
            <Row>
              <Col md="6">
                <Table bordered striped style={{ height: '75%' }}>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Model Cover
                      </td>
                      <td>{this.state.data.cover_model}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Warna / Cover
                      </td>
                      <td>{`${this.state.data.color.name} / ${this.state.data.category.name} ${
                        this.state.data.category.vehicle_type_id === Constant.TYPE_KARPET
                          ? this.state.data.variant.size
                          : ''
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Flow Id
                      </td>
                      <td>{this.state.flow_id}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Nomor Order
                      </td>
                      <td>{this.state.data.order.order_number}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        CS
                      </td>
                      <td>{this.state.data.order.customer_service.name}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Tipe / Merk
                      </td>
                      <td>{`${this.state.data.product_name ? this.state.data.product_name : ''} ${
                        this.state.data.year ? this.state.data.year : ''
                      }${
                        this.state.data.transmission_type ? ` Transmisi ${this.state.data.transmission_type}` : ''
                      }`}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Pemesan
                      </td>
                      <td>{this.state.data.order.customer.name}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Penerima
                      </td>
                      <td>{this.state.data.order.shipping_address.name}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Panjang
                      </td>
                      <td>
                        {this.state.data.length} cm{' '}
                        {this.state.data.additional_length > 0 &&
                          ` + ${this.state.data.additional_length} cm = ${Number(this.state.data.additional_length) +
                            Number(this.state.data.length)} cm`}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Lebar
                      </td>
                      <td>{this.state.data.width} cm</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Tinggi
                      </td>
                      <td>{this.state.data.height} cm</td>
                    </tr>
                    <tr style={stripGreen ? { color: '#fff', background: 'green' } : {}}>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px',
                          color: stripGreen ? '#fff' : 'inherit'
                        }}
                      >
                        Tipe Cover
                      </td>
                      <td
                        style={{
                          color: stripGreen ? '#fff' : 'inherit'
                        }}
                      >
                        {this.state.data.cover_type ? this.state.data.cover_type.name : ''}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        List Cover
                      </td>
                      <td>{this.state.data.cover_list ? this.state.data.cover_list.name : ''}</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Logo
                      </td>
                      <td>{this.state.data.logo}</td>
                    </tr>

                    <tr style={withSablon ? { backgroundColor: 'yellow', color: '#fff' } : {}}>
                      <td
                        style={{
                          // whiteSpace: 'nowrap'
                          width: '130px'
                        }}
                      >
                        Tambahan
                      </td>
                      <td>{this.state.data.addons.map(addon => addon.name).join(', ')}</td>
                    </tr>
                    <tr>
                      <td style={{ width: '130px', fontWeight: 'bold', fontSize: '1.15rem' }}>Catatan CS</td>
                      <td style={{ fontWeight: 'bold', fontSize: '1.15rem' }}>{this.state.data.note}</td>
                    </tr>
                    {!!this.state.data.is_complaint && (
                      <>
                        <tr style={{ background: '#ffbb00', color: '#000' }}>
                          <td
                            style={{
                              // whiteSpace: 'nowrap'
                              width: '130px'
                            }}
                          >
                            Kategori Komplain
                          </td>
                          <td>{this.state.data.complaint.category.name}</td>
                        </tr>
                        <tr style={{ background: '#ffbb00', color: '#000' }}>
                          <td
                            style={{
                              // whiteSpace: 'nowrap'
                              width: '130px'
                            }}
                          >
                            Komplain
                          </td>
                          <td>{this.state.data.complaint.complaint}</td>
                        </tr>
                        <tr style={{ background: '#ffbb00', color: '#000' }}>
                          <td
                            style={{
                              // whiteSpace: 'nowrap'
                              width: '130px'
                            }}
                          >
                            Solusi
                          </td>
                          <td>{this.state.data.complaint.solution}</td>
                        </tr>
                        <tr style={{ background: '#ffbb00', color: '#000' }}>
                          <td
                            style={{
                              // whiteSpace: 'nowrap'
                              width: '130px'
                            }}
                          >
                            Lead Time
                          </td>
                          <td>
                            {new Date(this.state.data.complaint.lead_time).toLocaleDateString('id-ID', {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
                <p>
                  {!!this.state.data.is_complaint ? (
                    <Badge
                      className="badge-md"
                      style={{
                        background: '#ffbb00',
                        color: '#000'
                      }}
                    >
                      Komplain
                    </Badge>
                  ) : (
                    <span>
                      Status Pembayaran:{' '}
                      <Badge
                        className="badge-md"
                        style={
                          this.state.data.order.payment_status.name === 'Lunas'
                            ? {
                                background: '#22e24f',
                                color: '#000'
                              }
                            : {
                                background: 'rgb(230, 33, 49)',
                                color: '#fff'
                              }
                        }
                      >
                        {this.state.data.order.payment_status.name}
                      </Badge>
                    </span>
                  )}
                </p>
                <Table borderless>
                  <thead>
                    <tr>
                      <th style={{ width: '40%' }}>Tinggi Cover</th>
                      <th>Catatan Resep</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ width: '40%' }}>{this.state.data.cover_height} cm</td>
                      <td>
                        {(() => {
                          const historySik = this.state.data.histories.filter(
                            history => history.status.id === Constant.STATUS_RESEP
                          );

                          const history = historySik[historySik.length - 1];
                          return history
                            ? history.description
                              ? history.description.split('\n').length > 0
                                ? history.description.split('\n').map((item, i) => <p key={i}>{item}</p>)
                                : history.description
                              : ''
                            : '';
                        })()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>

              <Col md="6">
                <Row>
                  <Col md="6">
                    <div style={{ textAlign: 'left' }}>
                      <h1>
                        {unit_id === GREAT_TEAM ? 'Great' : unit_id === Constant.EXCELLENT_TEAM ? 'Excellent' : 'Force'}
                      </h1>
                    </div>
                  </Col>
                  <Col md="6">
                    {this.state.data.estimation && (
                      <div style={{ textAlign: 'right' }}>
                        {this.state.data.estimation ? (
                          <h4>
                            Estimasi: <br />
                            {moment(this.state.data.estimation).format('dddd, DD MMM YYYY')}
                          </h4>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </Col>
                </Row>

                <div className="d-flex justify-content-center mb-5">
                  <QrCode
                    imageSettings={{
                      src: logo_label,
                      x: 104,
                      y: 104,
                      height: 24,
                      width: 24,
                      excavate: true
                    }}
                    value={this.props.match.params.flow_id}
                  />
                  <div style={{ color: '#E62131', lineHeight: 8, marginLeft: '5px' }}>{this.state.flow_id}</div>
                </div>
                {[Constant.TYPE_MOBIL].includes(this.state.data.category.vehicle_type_id) && (
                  <div className="text-center" style={{ marginTop: '3rem' }}>
                    <img
                      width="500"
                      style={{ maxWidth: '100%' }}
                      src={require('../../../assets/img/kerangka.png')}
                      alt="kerangka"
                    />
                  </div>
                )}
                {this.state.data.order.invoice_number && (
                  <div
                    style={{
                      textAlign: 'center',
                      color: '#E62131',
                      border: '1px solid #E62131',
                      padding: 24,
                      marginTop: '50px'
                    }}
                  >
                    Order Ini dari Marketplace, mohon print invoicenya ya
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default PrintLabel;
