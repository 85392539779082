import React from 'react';
import ReactDOM from 'react-dom';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded

// prettier-ignore
import './assets/vendor/fullcalendar/dist/fullcalendar.min.css';
// prettier-ignore
import './assets/vendor/sweetalert2/dist/sweetalert2.min.css';
// prettier-ignore
import './assets/vendor/select2/dist/css/select2.min.css';
// prettier-ignore
import './assets/vendor/quill/dist/quill.core.css';
// prettier-ignore
import './assets/vendor/nucleo/css/nucleo.css';
// prettier-ignore
import './assets/vendor/@fortawesome/fontawesome-free/css/all.css';
// core styles
import './assets/scss/argon-dashboard-pro-react.scss?v1.0.0';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
