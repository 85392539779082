import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
  Table,
  Badge
} from 'reactstrap';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import Can from '../../../../config/Can';

import PayoutVerificationModal from './PayoutVerificationModal';

import moment from 'moment';
import 'moment/locale/id';
import Loader from '../../../../components/Loader/Loader';
import FinanceService from '../../../../services/FinanceService';
import TaxEvidenceModal from './TaxEvidenceModal';
moment.locale('id');

function FinancePayout(props) {
  // const [downloadButtonShow, setDownloadButtonShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    payoutVerificationModalOpen: false,
    taxVerificationModalOpen: false,
    evidenceModalOpen: false,
    taxEvidenceModalOpen: false
  });
  // const [evidence, setEvidence] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  // const [start_date, setStartDate] = useState('');
  // const [end_date, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const [totalSize, setTotalSize] = useState(undefined);

  // const startDateRef = useRef();
  // const endDateRef = useRef();

  useEffect(() => {
    getData({
      page,
      limit,
      filter
      //  start_date, end_date
    });
  }, []);

  const isValid = () => {
    // const setRef = ref => {
    //   ref.current.classList.add('is-invalid');
    // };

    // if (!start_date || start_date === '') {
    //   setRef(startDateRef);
    //   return false;
    // } else if (!end_date || end_date === '') {
    //   setRef(endDateRef);
    //   return false;
    // }
    return true;
  };

  const getData = async ({
    page,
    limit,
    sort = null,
    order = null,
    filter
    //  start_date, end_date
  }) => {
    setLoading(true);
    try {
      const res = await FinanceService.getPayoutDatatable(
        page,
        limit,
        sort,
        order,
        filter
        // , start_date, end_date
      );
      if (res.status === 200) {
        setLoading(false);
        setData(res.data.data);
        setPage(res.data.page);
        setLimit(res.data.limit);
        setTotalSize(res.data.recordsFiltered);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onTableChange = (type, newState) => {
    getData(props.match.params.customer_id, newState.page, newState.sizePerPage, newState.searchText);
  };

  const toggleModal = (name, row = null) => {
    setSelectedRow(row);
    if (modal[name]) {
      getData({
        page,
        limit,
        filter
        // , start_date, end_date
      });
      setModal({ ...modal, [name]: !modal[name] });
    } else {
      setModal({ ...modal, [name]: !modal[name] });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <SimpleHeader name="Tabel Payout" parentName="Finance" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="end_date">Filter</label>
                      <Input
                        id="filter"
                        type="text"
                        name="filter"
                        value={filter}
                        onChange={e => {
                          setFilter(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => {
                        if (isValid()) {
                          // setDownloadButtonShow(true);
                          getData({
                            page,
                            limit,
                            filter
                            //  start_date, end_date
                          });
                        }
                      }}
                    >
                      Cari
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              {/* {downloadButtonShow && (
                <CardHeader style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Button size="sm" color="success" onClick={handleDownload}>
                    Download
                  </Button>
                </CardHeader>
              )} */}
              <CardBody>
                <Row>
                  <ToolkitProvider
                    data={data}
                    keyField="id"
                    columns={[
                      // {
                      //   dataField: 'no',
                      //   text: 'Nomor',
                      //   sort: false
                      // }
                      {
                        dataField: 'id',
                        text: 'ID Payout',
                        sort: true
                      },
                      {
                        dataField: 'user_name',
                        text: 'Nama Affiliate',
                        sort: true
                      },
                      {
                        dataField: 'description',
                        text: 'Deskripsi',
                        sort: true
                      },
                      {
                        dataField: 'total',
                        text: 'Jumlah',
                        sort: true,
                        formatter: cell => {
                          return cell.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          });
                        }
                      },
                      {
                        dataField: 'payment_date',
                        text: 'Tanggal Transfer',
                        sort: true,
                        formatter: cell => {
                          return cell ? moment(cell).format('dddd, LL') : '';
                        }
                      },
                      {
                        dataField: 'status',
                        text: 'Status',
                        sort: true,
                        formatter: cell => {
                          return (
                            <>
                              <Badge color={cell === 'paid' ? 'success' : 'info'}>{cell}</Badge>
                            </>
                          );
                        }
                      },
                      {
                        dataField: 'action',
                        text: 'Aksi',
                        sort: false,
                        isDummyField: true,
                        formatter: (cell, row, rowIndex, formatExtraData) => {
                          return (
                            <>
                              {row.status === 'paid' && row.payment_evidence && (
                                <>
                                  <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    onClick={() => {
                                      window.open(row ? row.payment_evidence : '', '_blank');
                                    }}
                                  >
                                    Bukti Transfer
                                  </Button>
                                  <br />
                                  <br />
                                </>
                              )}
                              {row.status === 'paid' && row.tax_evidence && (
                                <>
                                  <Button
                                    color="info"
                                    type="button"
                                    size="sm"
                                    onClick={() => {
                                      window.open(row ? row.tax_evidence : '', '_blank');
                                    }}
                                  >
                                    Bukti Potong Pajak
                                  </Button>
                                  <br />
                                  <br />
                                </>
                              )}
                              {row.status === 'pending' && (
                                <Can I="manage" a="Finance Affiliate Payout">
                                  <Button
                                    color="success"
                                    type="button"
                                    size="sm"
                                    onClick={() => toggleModal('payoutVerificationModalOpen', row)}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-edit" />
                                    </span>
                                    <span className="btn-inner--text"> Transfer</span>
                                  </Button>
                                  <br />
                                  <br />
                                </Can>
                              )}
                              {row.status === 'paid' && row.tax_evidence === null && (
                                <Can I="manage" a="Finance Affiliate Payout">
                                  <Button
                                    color="danger"
                                    type="button"
                                    size="sm"
                                    onClick={() => toggleModal('taxVerificationModalOpen', row)}
                                  >
                                    <span className="btn-inner--icon">
                                      <i className="fas fa-edit" />
                                    </span>
                                    <span className="btn-inner--text"> Upload Bukti Potong Pajak</span>
                                  </Button>
                                </Can>
                              )}
                            </>
                          );
                        }
                      }
                    ]}
                  // search
                  >
                    {props => {
                      return (
                        <Table responsive>
                          <BootstrapTable
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: true
                            }}
                            loading={true}
                            {...props.baseProps}
                            bootstrap4={true}
                            pagination={paginationFactory({
                              page: page,
                              sizePerPage: limit,
                              totalSize: totalSize,
                              showTotal: true,
                              withFirstAndLast: true,
                              sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                <div className="dataTables_length" id="datatable-basic_length">
                                  <label>
                                    Show{' '}
                                    {
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm"
                                        onChange={e => onSizePerPageChange(e.target.value)}
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                    }{' '}
                                    entries.
                                  </label>
                                </div>
                              )
                            })}
                            bordered={false}
                            onTableChange={onTableChange}
                          />
                        </Table>
                      );
                    }}
                  </ToolkitProvider>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <PayoutVerificationModal row={selectedRow} toggle={toggleModal} isOpen={modal.payoutVerificationModalOpen} />
      <TaxEvidenceModal row={selectedRow} toggle={toggleModal} isOpen={modal.taxVerificationModalOpen} />
      <Modal
        isOpen={modal.evidenceModalOpen}
        toggle={() => {
          setLoading(false);
          toggleModal('evidenceModalOpen');
        }}
      >
        <ModalBody>
          <Card>
            <CardBody>
              <Row>
                <CardImg
                  width={300}
                  onClick={() => window.open(selectedRow ? selectedRow.payment_evidence : '', '_blank')}
                  alt="Gambar"
                  src={selectedRow ? selectedRow.payment_evidence : ''}
                />
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal.taxEvidenceModalOpen}
        toggle={() => {
          setLoading(false);
          toggleModal('taxEvidenceModalOpen');
        }}
      >
        <ModalBody>
          <Card>
            <CardBody>
              <Row>
                <CardImg
                  width={300}
                  onClick={() => window.open(selectedRow ? selectedRow.tax_evidence : '', '_blank')}
                  alt="Gambar"
                  src={selectedRow ? selectedRow.tax_evidence : ''}
                />
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
}

export default FinancePayout;
