import React from 'react';
import { Card, CardBody, Row, CardHeader } from 'reactstrap';
import { Bar } from 'react-chartjs-2';

const TotalOrder = props => (
  <Card>
    <CardHeader className="bg-transparent">
      <Row className="align-items-center">
        <div className="col">
          <h6 className="text-uppercase text-muted ls-1 mb-1">Performa</h6>
          <h5 className="h3 mb-0">Total Order</h5>
        </div>
      </Row>
    </CardHeader>
    <CardBody>
      {/* <div className="chart"> */}
      <Bar
        data={props.orders}
        options={{
          plugins: {
            legend: {
              display: false
            }
          }
        }}
        className="chart-canvas"
        id="chart-bars"
      />
      {/* </div> */}
    </CardBody>
  </Card>
);

export default TotalOrder;
