import React from 'react';
import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
// import './DetailBillingTable.css';
import '../../reports/AdvertiserReport.scss';

import moment from 'moment';
import 'moment/locale/id';
moment.locale('id');

function DetailBillingTable(props) {
  const spaningRow = (length, index, value) => {
    if (length > 1) {
      if (index === 0) {
        return (
          <td style={{ verticalAlign: 'middle' }} className="number-column label-column" rowSpan={length}>
            {value}
          </td>
        );
      }
    } else {
      return <td className="number-column label-column">{value}</td>;
    }
  };

  return (
    <Card id="section-to-print">
      <CardHeader>
        <Row>
          <Col md="6">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>ID Tagihan</strong>
                  </td>
                  <td>: {props.billing.id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Tanggal</strong>
                  </td>
                  <td>
                    : {moment(props.billing.start_date).format('LL')} - {moment(props.billing.end_date).format('LL')}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col md="6">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Nama</strong>
                  </td>
                  <td>: {props.billing.customer.name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Dibuat</strong>
                  </td>
                  <td>: {moment(props.billing.created_at).format('LL')}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Table responsive>
              <thead>
                <tr>
                  <th>Nomor Order</th>
                  <th>Barang</th>
                  <th>Qty</th>
                  <th>Harga</th>
                  <th>Subtotal</th>
                  <th>Ongkir</th>
                  <th>Lainnya</th>
                  <th>Diskon</th>
                  <th>Gross</th>
                  <th>PPN</th>
                  <th>Grand Total</th>
                </tr>
              </thead>
              <tbody>
                {props.billing.details.map((detail, key) => {
                  return detail.items.map((item, it) => {
                    return (
                      <tr key={it}>
                        {spaningRow(detail.items.length, it, detail.order_number)}
                        <td>{item.name}</td>
                        <td>{item.qty}</td>
                        <td className="number-column">
                          {item.price.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })}
                        </td>
                        <td className="number-column">
                          {item.subtotal.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })}
                        </td>
                        {spaningRow(
                          detail.items.length,
                          it,
                          detail.shipping_cost.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })
                        )}
                        {spaningRow(
                          detail.items.length,
                          it,
                          detail.additional_cost.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })
                        )}
                        {spaningRow(
                          detail.items.length,
                          it,
                          detail.discount.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })
                        )}
                        {spaningRow(
                          detail.items.length,
                          it,
                          detail.gross_amount.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })
                        )}
                        {spaningRow(
                          detail.items.length,
                          it,
                          detail.tax.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })
                        )}
                        {spaningRow(
                          detail.items.length,
                          it,
                          detail.grand_total.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          })
                        )}
                      </tr>
                    );
                  });
                })}
                <tr>
                  <td colSpan="9"></td>
                  <td style={{ fontSize: '1.5rem' }}>
                    <strong>Total</strong>
                  </td>
                  <td style={{ fontSize: '1.5rem' }}>
                    {props.billing.total.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default DetailBillingTable;
