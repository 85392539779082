import { request } from "../config";

// versi function
function PricelistService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0

    const response = await request.get('/pricelists', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;
    return ({
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    });
  };

  // Show detail
  const findById = async (id) => {
    const response = await request.get(`/pricelists/${id}`);
    return response.data;
  }

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/pricelists/${id}`, data);
    return response.data;
  }

  return {
    getDatatable,
    findById,
    update,
  }
}

export default PricelistService()