import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import LeadsService from '../../../services/LeadsService';

const FormUpdateLeads = ({ isOpen, toggle, row, setUpdateLeadsModal, getDataAfterUpdate }) => {
  const [loading, setLoading] = useState(false);

  const [customer_vehicle, setCustomerVehicle] = useState('');
  const [requiredPhone, setRequiredPhone] = useState(false);
  const [customer_phone, setCustomerPhone] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [brand, setBrand] = useState('');
  const [year, setYear] = useState('');
  const [reasonType, setReasonType] = useState([]);
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (row) {
      getDataReason();
      setReason(row.reason);
      if (row.customer_phone) {
        handleCustomerPhone(row.customer_phone);
        setRequiredPhone(false);
      } else {
        setRequiredPhone(true);
        setCustomerPhone('');
      }
    }

    return () => {
      console.log('bye');
      setCustomerVehicle('');
      setRequiredPhone(false);
      setCustomerPhone('');
      setManufacturer('');
      setBrand('');
      setYear('');
      setReasonType([]);
      setReason('');
    };
  }, [row]);

  const getDataReason = async () => {
    try {
      setLoading(true);
      const { data } = await LeadsService.getDataReasonManagementLeads();
      setReasonType(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const loadVehicleOptions = async inputValue => {
    if (inputValue.length >= 2) {
      const response = await LeadsService.searchVehicle(inputValue);
      let options = [];

      for (let row of response.data) {
        options.push({ manufacturer: row.manufacturer, brand: row.brand, label: row.vehicle_name });
      }
      return options;
    }
  };

  const handleInputVehicleChange = async inputValue => {
    setManufacturer(inputValue.manufacturer);
    setBrand(inputValue.brand);
  };

  const handleCustomerPhone = phone => {
    phone = phone
      .replace(/\D/g, '')
      .replace(/^0+/, '')
      .replace(/^62/g, '');
    setCustomerPhone(phone);
  };

  const onSubmit = e => {
    e.preventDefault();

    const data = {
      reason,
      customer_vehicle,
      manufacturer,
      brand,
      year
    };

    if (customer_phone) {
      data.customer_phone = customer_phone ? '+62' + customer_phone : '';
    }

    LeadsService.updateLeads(data, row.id)
      .then(response => {
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Leads Berhasil Diupdate',
            onClose: () => {
              getDataAfterUpdate();
              toggle();
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      {loading && <div className="loader"></div>}
      <Modal isOpen={isOpen} toggle={() => toggle()} size="md">
        <div className="modal-header">
          <h5 className="modal-title" id="detailModalLabel">
            Form Update Leads
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setUpdateLeadsModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody>
          <Form onSubmit={onSubmit}>
            {requiredPhone && (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="customer_phone">
                      No. WhatsApp Customer
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+62</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required={requiredPhone}
                        placeholder="888xxx"
                        name="customer_phone"
                        type="text"
                        value={customer_phone}
                        onChange={e => handleCustomerPhone(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="reason">
                    Alasan Belum Beli
                  </Label>
                  <Input required name="reason" type="select" value={reason} onChange={e => setReason(e.target.value)}>
                    <option value="">--Pilih Alasan--</option>
                    {reasonType.map(res => (
                      <option key={res} value={res}>
                        {res}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <FormGroup>
                  <Label>Catatan</Label>
                  <Input defaultValue={row.customer_vehicle} onChange={e => setCustomerVehicle(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="vehicleName">
                    Kendaraan Customer
                  </Label>
                  <Row>
                    <Col md="8">
                      <AsyncSelect
                        id="vehicleName"
                        name="vehicle_id"
                        defaultOptions
                        placeholder="Pilih Kendaraan"
                        // ref={props.customerSelectRef}
                        loadOptions={loadVehicleOptions}
                        onChange={handleInputVehicleChange}
                      />
                      <div id="vehicleValidFeedback" style={{ display: 'hidden' }} className="invalid-feedback">
                        Merek Kendaraan wajib diisi
                      </div>
                    </Col>

                    <Col md="4">
                      <Input
                        id="year"
                        type="select"
                        name="year"
                        value={year}
                        onChange={e => {
                          setYear(e.target.value);
                        }}
                        style={{ minWidth: '125px' }}
                      >
                        <option value="">--Tahun--</option>
                        {[...Array(parseInt(new Date().getFullYear()) - 1898).keys()]
                          .map(key => key + 1900)
                          .sort((a, b) => b - a)
                          .map(year => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>

            <div className="mt-4 d-flex float-right">
              <Button color="default" className="btn" onClick={() => toggle()}>
                Batal
              </Button>

              <Button color="info" className="btn" type="submit">
                Simpan
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FormUpdateLeads;
