import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row
} from 'reactstrap';
// import Swal from 'sweetalert2';
// import money from "../../../../assets/img/money.png"
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from '../../../../components/Loader/Loader';
import AffiliateService from '../../../../services/AffiliateService';
import CardTotalSelling from './component/CardTotalSelling';

import moment from 'moment';

const Selling = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({});

  const [start_date, setStartDate] = useState('');
  const [setEndDate] = useState('');

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  const startDateRef = useRef();

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getData();

    return () => {
      source.cancel();
    };
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  const getData = () => {
    setLoading(true);
    AffiliateService.getDataTableSelling(page, sizePerPage, sortField, sortOrder, searchText)
      .then(res => {
        // console.log(res.data);
        setData(res.data.data);
        setSummary(res.data.summary);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {/* <img src={money} style={{ width: '50px', height: '50px', marginBottom: '20px', marginRight: '10px' }} alt="" /> */}
                <h6 className="h2 text-white d-inline-block mb-0">Penjualan</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem aria-current="page" className="active">
                    Penjualan melalui Afiliasi
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6 mb-6" fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Input
                        id="start_date"
                        type="date"
                        name="start_date"
                        max={new Date().toISOString().split('T')[0]}
                        // min={new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0]}
                        innerRef={startDateRef}
                        onKeyDown={e => e.preventDefault()}
                        value={start_date}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setStartDate(e.target.value);
                          setEndDate('');
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Button color="primary">Filter Data</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom-0">
                <Row>
                  <Col md="4">
                    <CardTotalSelling
                      name="Total Penjualan"
                      icon="ni ni-money-coins"
                      gradient="red"
                      value={summary.total_sales_amount}
                    />
                  </Col>

                  <Col md="4">
                    <CardTotalSelling
                      name="Total Komisi"
                      icon="ni ni-money-coins"
                      gradient="green"
                      value={summary.total_commission}
                    />
                  </Col>
                </Row>
              </CardHeader>

              <ToolkitProvider
                keyField="id"
                data={data}
                columns={[
                  {
                    dataField: 'created_at',
                    text: 'Tanggal Pendapatan',
                    sort: true,
                    formatter: (cell, row) => {
                      return moment(cell).format('LL');
                    }
                  },
                  {
                    dataField: 'order_number',
                    text: 'Kode Penjualan',
                    sort: true
                  },
                  {
                    dataField: 'product_name',
                    text: 'Produk',
                    sort: true
                  },
                  {
                    dataField: 'brand',
                    text: 'Merek / Tipe',
                    sort: false
                  },
                  {
                    dataField: 'customer_name',
                    text: 'Pembeli',
                    sort: true
                  },
                  {
                    dataField: 'commission',
                    text: 'Komisi Afiliasi',
                    sort: true,
                    formatter: (cell, row) => {
                      return 'Rp. ' + new Intl.NumberFormat('id-ID').format(cell);
                    }
                  }
                ]}
                search
              >
                {props => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      remote={{
                        filter: true,
                        pagination: true,
                        sort: true
                      }}
                      loading={true}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        showTotal: true,
                        withFirstAndLast: true,
                        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                          <div className="dataTables_length" id="datatable-basic_length">
                            <label>
                              Show{' '}
                              {
                                <select
                                  name="datatable-basic_length"
                                  aria-controls="datatable-basic"
                                  className="form-control form-control-sm"
                                  onChange={e => onSizePerPageChange(e.target.value)}
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              }{' '}
                              entries.
                            </label>
                          </div>
                        )
                      })}
                      bordered={false}
                      onTableChange={onTableChange}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Selling;
