import { request } from '../config';

class PayoutService {
  static async getDetailPayout(id) {
    const response = await request.get('/affiliates/payout/' + id);

    return response;
  }

  static async verifyPayout(id, data) {
    const response = await request.put('/affiliates/payout/pay/' + id, data);

    return response;
  }

  static async uploadPaymentEvidence(file) {
    const response = await request.post(`/upload/payout/evidence`, file);
    return response;
  }

  static async verifyTaxEvidence(id, data) {
    const response = await request.put('/affiliates/payout/tax/' + id, data);

    return response;
  }

  static async uploadTaxEvidence(file) {
    const response = await request.post(`/upload/payout/tax`, file);
    return response;
  }
}

export default PayoutService;
