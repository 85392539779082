import CryptoJS from 'crypto-js';
import { AUTH_ERROR, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT_SUCCESS, USER_LOADED, USER_LOADING } from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  isLoading: false,
  user: JSON.parse(localStorage.getItem('user')),
  permissions: localStorage.getItem('permissions')
    ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('permissions'), 'ijulganteng').toString(CryptoJS.enc.Utf8))
    : []
};

export default function(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case USER_LOADING:
      return { ...state, isLoading: true };
    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: payload
      };

    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.meta.token);
      localStorage.setItem('refreshToken', payload.meta.refreshToken);
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user', JSON.stringify(payload.data));
      localStorage.setItem('role', payload.data.role.id);
      localStorage.setItem(
        'permissions',
        CryptoJS.AES.encrypt(JSON.stringify(payload.meta.permissions), 'ijulganteng')
      );
      return {
        ...state,
        token: payload.meta.token,
        refreshToken: payload.meta.refreshToken,
        isAuthenticated: true,
        isLoading: false,
        user: payload.data,
        permissions: payload.meta.permissions
      };

    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.clear();
      return {
        ...state,
        token: null,
        refreshToken: null,
        user: null,
        isLoading: false,
        isAuthenticated: false,
        permissions: []
      };
    default:
      return state;
  }
}
