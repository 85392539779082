import { debounce } from '@fullcalendar/core';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import CurrencyInput from '../../../../helpers/CurrencyInput';
import ComplaintService from '../../../../services/ComplaintService';

function ComplaintInputModal(props) {
  const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(false);

  const [complaint_category_id, setComplaintCategoryId] = useState(0);
  const [division, setDivision] = useState('');
  const [action_id, setActionId] = useState(0);
  const [complaint, setComplaint] = useState('');
  const [solution, setSolution] = useState('');
  const [lead_time, setLeadTime] = useState('');
  const [customer_charge, setCustomerCharge] = useState(0);
  const [customer_charge_tax, setCustomerChargeTax] = useState(0);
  const [customer_shipping_cost, setCustomerShippingCost] = useState(0);
  const [cs_charge, setCsCharge] = useState(0);
  const [cs_charge_tax, setCsChargeTax] = useState(0);
  const [cs_shipping_cost, setCsShippingCost] = useState(0);
  const [production_charge, setProductionCharge] = useState(0);
  const [production_charge_tax, setProductionTax] = useState(0);
  const [production_shipping_cost, setProductionShippingCost] = useState(0);
  const [images, setImages] = useState([]);

  const [actions, setActions] = useState([]);
  const [complaint_categories, setComplaintCategories] = useState([]);
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    getActions();
    getComplaintCategories();
    getDivisions();
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          console.log(props);
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (props.isOpen) {
              if (blob.size / 1000000 <= 15) {
                handleImagePaste(blob);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
                });
              }
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }, [props.isOpen]);

  const handleImagePaste = debounce(async file => {
    const formData = new FormData();
    formData.append('file', file);

    if (file) {
      try {
        setLoading(true);
        const resImage = await ComplaintService.uploadImage(formData);

        if (resImage.status === 200) {
          const newImages = [];
          for (let i = 0; i < resImage.data.data.length; i++) {
            const image = resImage.data.data[i];
            newImages.push({
              image
            });
          }
          setImages(newImages);

          setLoading(false);
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, 2000);

  const getActions = async () => {
    try {
      const res = await ComplaintService.getActions();

      if (res.status === 200) {
        setActions(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDivisions = async () => {
    try {
      const res = await ComplaintService.getDivisions();

      if (res.status === 200) {
        setDivisions(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getComplaintCategories = async () => {
    try {
      const res = await ComplaintService.getComplaintCategories();

      if (res.status === 200) {
        setComplaintCategories(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = () => {
    setAlert('');
    setLoading(false);

    setComplaintCategoryId(0);
    setDivision('');
    setActionId(0);
    setComplaint('');
    setSolution('');
    setLeadTime('');
    setCustomerCharge(0);
    setCustomerChargeTax(0);
    setCustomerShippingCost(0);
    setCsCharge(0);
    setCsChargeTax(0);
    setCsShippingCost(0);
    setProductionCharge(0);
    setProductionTax(0);
    setProductionShippingCost(0);
    setImages([]);

    props.toggle(props.row, 'isComplaintInputModalShow');
  };

  const isValid = () => {
    function alert(text) {
      Swal.fire({
        icon: 'warning',
        title: 'Terjadi Kesalahan',
        text
      });
    }

    if (!complaint_category_id || complaint_category_id === '' || complaint_category_id === 0) {
      alert('Silahkan pilih Kategori Komplain');
      return false;
    } else if (!division || division === '') {
      alert('Silahkan pilih Divisi');
      return false;
    } else if (!action_id || action_id === '' || action_id === 0) {
      alert('Silahkan pilih Aksi');
      return false;
    } else if (!complaint || complaint === '') {
      alert('Silahkan isi Detail Komplain');
      return false;
    } else if (!solution || solution === '') {
      alert('Silahkan isi Detail Solusi');
      return false;
    } else if (!lead_time || lead_time === '') {
      alert('Silakan Pilih Tanggal');
      return false;
    } else if (!images || images.length === 0) {
      alert('Silakan Unggah Bukti Transfer');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    try {
      if (isValid()) {
        const data = {
          order_detail_id: props.row.id,
          complaint_category_id,
          division,
          action_id,
          complaint,
          solution,
          lead_time,
          customer_charge,
          customer_charge_tax,
          customer_shipping_cost,
          cs_charge,
          cs_charge_tax,
          cs_shipping_cost,
          production_charge,
          production_charge_tax,
          production_shipping_cost,
          images
        };

        console.log(data);
        const res = await ComplaintService.post(data);
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Pengajuan Komplain Berhasil'
          }).then(() => {
            toggle();
          });
        }
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadImage = async e => {
    e.preventDefault();
    setAlert('');

    if (e.target.files.length > 0) {
      try {
        setLoading(true);

        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files[i];
          formData.append('file', file);
        }

        const image = await ComplaintService.uploadImage(formData);

        if (image.status === 200) {
          setImages(
            image.data.data.map(image => ({
              image
            }))
          );
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal isOpen={props.isOpen} fade={false} backdrop="static" toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Pengajuan Komplain</ModalHeader>
        <ModalBody>
          {alert !== '' && <Alert color="danger">{alert}</Alert>}

          <Form>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label for="length">Kategori Komplain</Label>
                  <Input
                    required
                    value={complaint_category_id}
                    type="select"
                    onChange={e => {
                      e.persist();

                      setComplaintCategoryId(e.target.value);
                    }}
                  >
                    <option value="">--Pilih Kategori Komplain--</option>
                    {complaint_categories.map((complaintCategory, key) => (
                      <option value={complaintCategory.id} key={key}>
                        {complaintCategory.complaint_code} - {complaintCategory.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="length">Divisi</Label>
                  <Input
                    required
                    value={division}
                    type="select"
                    onChange={e => {
                      e.persist();

                      setDivision(e.target.value);
                    }}
                  >
                    <option value="">--Pilih Divisi--</option>
                    {divisions.map((obj, key) => (
                      <option value={obj.name} key={key}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="length">Aksi</Label>
                  <Input
                    required
                    value={action_id}
                    type="select"
                    onChange={e => {
                      e.persist();

                      setActionId(e.target.value);
                    }}
                  >
                    <option value="">--Pilih Aksi--</option>
                    {actions.map((obj, key) => (
                      <option value={obj.id} key={key}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label for="length">Komplain</Label>
                  <Input
                    type="textarea"
                    // placeholder="45609xxx"
                    value={complaint}
                    onChange={e => {
                      e.persist();
                      const value = e.target.value;
                      setAlert('');

                      setComplaint(value);
                      // const value = e.target.value.replace(/[^0-9]+/gi, '');
                      // if (value.length <= 30) {
                      //   SetAccountNumber(value);
                      // }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label for="length">Solusi</Label>
                  <Input
                    type="textarea"
                    // placeholder="45609xxx"
                    value={solution}
                    onChange={e => {
                      e.persist();
                      setAlert('');
                      const value = e.target.value;

                      setSolution(value);
                      // const value = e.target.value.replace(/[^0-9]+/gi, '');
                      // if (value.length <= 30) {
                      //   SetAccountNumber(value);
                      // }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Label for="length">Waktu Lead</Label>
                  <Input
                    type="date"
                    min={new Date().toISOString().split('T')[0]}
                    name="lead_time"
                    onKeyDown={e => e.preventDefault()}
                    onWheel={e => false}
                    value={lead_time}
                    onChange={e => {
                      // remove class invalid
                      setAlert('');
                      const value = e.target.value;
                      setLeadTime(value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Tagihan Customer</Label>
                  <CurrencyInput
                    type="text"
                    className="form-control"
                    value={customer_charge}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setCustomerCharge(value);
                      // setRefundAmount(value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Pajak Tagihan Customer</Label>
                  <CurrencyInput
                    type="text"
                    className="form-control"
                    value={customer_charge_tax}
                    onChange={e => {
                      setAlert('');

                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setCustomerChargeTax(value);
                      // setRefundAmount(value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Tagihan Pengiriman Customer</Label>
                  <CurrencyInput
                    type="text"
                    className="form-control"
                    value={customer_shipping_cost}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));

                      setCustomerShippingCost(value);
                      // setRefundAmount(value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Tagihan CS</Label>
                  <CurrencyInput
                    type="text"
                    className="form-control"
                    value={cs_charge}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));

                      setCsCharge(value);
                      // setRefundAmount(value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Pajak Tagihan CS</Label>
                  <CurrencyInput
                    type="text"
                    className="form-control"
                    value={cs_charge_tax}
                    onChange={e => {
                      setAlert('');

                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setCsChargeTax(value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Tagihan Pengiriman CS</Label>
                  <CurrencyInput
                    type="text"
                    className="form-control"
                    value={cs_shipping_cost}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setCsShippingCost(value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Tagihan Produksi</Label>
                  <CurrencyInput
                    type="text"
                    className="form-control"
                    value={production_charge}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setProductionCharge(value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Pajak Tagihan Produksi</Label>
                  <CurrencyInput
                    type="text"
                    id="paymentAmount"
                    name="payment_amount"
                    className="form-control"
                    value={production_charge_tax}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setProductionTax(value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label for="length">Tagihan Pengiriman Produksi</Label>
                  <CurrencyInput
                    type="text"
                    id="paymentAmount"
                    name="payment_amount"
                    className="form-control"
                    value={production_shipping_cost}
                    onChange={e => {
                      setAlert('');
                      const value = Number(e.target.value.slice(3).replace(/\./g, ''));
                      setProductionShippingCost(value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="customFileLang">
                    Bukti Komplain
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </label>
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="customFileLang"
                      lang="en"
                      accept="image/*"
                      type="file"
                      multiple
                      onChange={handleUploadImage}
                    />
                    <label className="custom-file-label" htmlFor="customFileLang">
                      Pilih file
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            {images.length > 0 && (
              <Row>
                {images.map((image, i) => (
                  <Col key={i} xs="4">
                    <div>
                      <img
                        src={image.image}
                        alt="order evidence url"
                        width="180"
                        height="200"
                        // style={{ marginRight: 5 }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Submit'
            )}
          </Button>{' '}
          <Button color="secondary" disabled={loading} onClick={toggle}>
            Batalkan
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ComplaintInputModal;
