import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
} from 'chart.js';
import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';

import { Chart } from 'react-chartjs-2';

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip);

const ChartPlatform = props => {
  let { labels, datasets } = props.leads;

  const data = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'Leads',
        backgroundColor: 'darkblue',
        data: datasets[0].data,
        borderColor: 'white',
        borderWidth: 2
      }
    ]
  };

  return (
    <Card>
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h5 className="h3 mb-0">Total Leads Platform</h5>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <Chart type="bar" data={data} />
      </CardBody>
    </Card>
  );
};

export default ChartPlatform;
