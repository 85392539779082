import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Swal from 'sweetalert2';
import TaxService from '../../../../../services/TaxService';

const TaxModal = props => {
  const [loading, setLoading] = useState(false);
  const [fakturPajak, setFakturPajak] = useState('');
  const [urlFakturPajak, setUrlFakturPajak] = useState('');
  const [namePdf, setNamePdf] = useState('');

  const taxInvoiceRef = useRef();

  const toggle = () => {
    setLoading(false);
    props.toggle('taxModalOpen');
  };

  const resetState = () => {
    setFakturPajak('');
    setUrlFakturPajak('');
    setNamePdf('');
  };

  // VALIDATOR Faktur Pajak
  const handleImage = e => {
    const files = e.target.files;
    try {
      if (e.target.files.length > 5) throw new Error('Select 5 Images Only');

      for (var i = 0; i < files.length; i++) {
        if (Math.round(files[i].size / 1000) >= 15000) throw new Error('File size lebih dari ' + 15 + 'MB');
      }
      setFakturPajak(files);
      setUrlFakturPajak(URL.createObjectURL(files[0]));
      setNamePdf(files[0].name);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    const formData = new FormData();

    for (var i = 0; i < fakturPajak.length; i++) {
      formData.append('file', fakturPajak[i]);
    }

    try {
      setLoading(true);
      const res = await TaxService.uploadFakturPajak(props.row.order_number, formData);

      if (res.status === 200) {
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'Upload Faktur Pajak Berhasil',
          showConfirmButton: false,
          timer: 1500
        });
        toggle();
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error('Terjadi kesalahan pada saat upload faktur pajak.');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const taxInvoice =
    props.row && props.row.tax_invoice && props.row.order_number ? (
      <div style={{ textAlign: 'center' }}>
        <br />
        <img
          onClick={() => window.open(props.row.tax_invoice, '_blank')}
          alt=""
          style={{ maxWidth: '100%' }}
          src={props.row.tax_invoice}
        />
      </div>
    ) : (
      <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
        <Col md={12}>
          <FormGroup>
            <Label className="form-control-label" htmlFor="customFileLang">
              Upload Bukti Faktur Pajak
              <sup>
                <span className="text-red">*</span>
              </sup>
            </Label>
            <div className="custom-file">
              <Input
                className="custom-file-input"
                id="customFileLang"
                lang="en"
                innerRef={taxInvoiceRef}
                type="file"
                accept="image/pdf/*"
                onChange={handleImage}
              />
              <label className="custom-file-label" htmlFor="customFileLang">
                Pilih file
              </label>
            </div>
          </FormGroup>
        </Col>
        <Col md={12}>
          {urlFakturPajak !== '' && urlFakturPajak && (
            <img src={urlFakturPajak} alt="" width="200" style={{ marginRight: 5 }} />
          )}
          {namePdf !== '' && namePdf && <p>{namePdf}</p>}
        </Col>
      </Form>
    );

  return (
    <>
      <Modal isOpen={props.isOpen} toggle={toggle} size="lg" onOpened={resetState}>
        <ModalHeader>Faktur Pajak</ModalHeader>
        <ModalBody>
          <Row>{taxInvoice}</Row>
        </ModalBody>
        <ModalFooter>
          {props.row && props.row.tax_invoice === null && (
            <Button disabled={loading} color="primary" onClick={handleSubmit}>
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Upload Faktur'
              )}
            </Button>
          )}
          <Button disabled={loading} onClick={toggle}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TaxModal;
