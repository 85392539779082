import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import CustomerService from '../../../../services/CustomerService';
import DetailBillingTable from '../../customers/billing/DetailBillingTable';

const defaultBilling = {
  id: 0,
  bank_id: 0,
  total: 0,
  customer_id: 0,
  is_paid: 0,
  is_verified: 0,
  payment_date: '',
  evidence: '',
  description: '',
  start_date: '',
  end_date: '',
  user_id: 0,
  created_at: '',
  customer: {
    name: ''
  },
  details: [
    {
      order_number: '',
      shipping_cost: 0,
      additional_cost: 0,
      discount: 0,
      gross_amount: 0,
      tax: 0,
      grand_total: 0,
      items: [
        {
          qty: 1,
          name: '',
          price: 0,
          subtotal: 0
        }
      ]
    }
  ]
};

function BillingVerificationModal(props) {
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState(defaultBilling);

  useEffect(() => {
    if (props.row) {
      getBillingDetail(props.row.id);
    }
  }, [props.row]);

  const getBillingDetail = async id => {
    try {
      const res = await CustomerService.getDetailBilling(id);
      if (res.status === 200) {
        setBilling(res.data.data);
      }
    } catch (error) {
      console.log();
    }
  };

  const toggle = () => {
    setLoading(false);
    setBilling(defaultBilling);
    props.toggle('billingVerificationModalOpen');
  };

  const handleVerification = async () => {
    setLoading(true);
    try {
      const res = await CustomerService.verifyBilling(props.row.id);

      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Tagihan Berhasil Diverifikasi',
          timer: 5000
        }).then(() => toggle());

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return props.row ? (
    <>
      <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
        <ModalHeader>Verifikasi Tagihan {billing.id}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <DetailBillingTable billing={billing} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ textAlign: 'center' }}>
                <img
                  onClick={() => window.open(props.row.evidence, '_blank')}
                  alt={props.row.description}
                  style={{ maxWidth: '100%' }}
                  src={props.row.evidence}
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="success" onClick={handleVerification}>
            {loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Verifikasi'
            )}
          </Button>
          {` `}
          <Button disabled={loading} onClick={toggle}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    ''
  );
}

export default BillingVerificationModal;
