import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Modal, Row, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';
import ShippingManagementService from '../../../../services/ShippingManagementService';

const RequestModal = props => {
  const { toggle, isOpen, selectShipping, getData } = props;

  const [dataSchedule, setDataSchedule] = useState([]);
  const [schedule, setSchedule] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) getDataShcedule();
  }, [isOpen]);

  const getDataShcedule = async () => {
    try {
      setLoading(true);
      const response = await ShippingManagementService.shippingSchedules();

      if (response.status === 200) {
        setDataSchedule(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSchedule = e => {
    setSchedule(e.target.value);
  };

  const addRequestPickup = async () => {
    const data = {
      order_ids: selectShipping,
      schedule: schedule
    };

    try {
      setLoading(true);
      const response = await ShippingManagementService.requestPickup(data);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Good!',
          text: 'Berhasil Request pickup!'
        });
      }
      getData();
      toggle('isRequestModalOpen');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => toggle('isRequestModalOpen')} size="lg">
      <div className="modal-header">
        <h5 className="modal-title" id="detailModalLabel">
          Form Request Pickup
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle('isRequestModalOpen')}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        <Row>
          <Col md="6">
            <FormGroup>
              {loading && <div className="loader"></div>}
              <label htmlFor="schedule">Schedule</label>
              <Input id="schedule" type="select" name="schedule" onChange={handleChangeSchedule}>
                <option value="">Pilih Schedule</option>
                {dataSchedule.map((item, idx) => (
                  <option key={idx} value={item.schedule}>
                    {item.schedule} - {item.until} WIB
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="modal-footer">
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <Button
            disabled={!schedule}
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => addRequestPickup()}
          >
            Request
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default RequestModal;
