import { request } from '../config';

class VoucherService {
  /**
   * Get bank lists
   */
  static async getLists() {
    const { data: response } = await request.get('/vouchers/list');
    return response;
  }

  static async categoryLists() {
    const response = await request.get('/vouchers/categories');
    return response;
  }

  static async getCsLists() {
    const { data: response } = await request.get('/vouchers/list-cs');
    return response;
  }

  static async listProducts(category_id, manufacturer_id, type_car_id) {
    const response = await request.get('/vouchers/list-product', {
      params: {
        category_id,
        manufacturer_id,
        type_car_id
      }
    });

    return response;
  }

  static async getVoucherTypes() {
    const response = await request.get('/vouchers/list-voucher-type');
    return response;
  }

  static async getBankProviders() {
    const { data: response } = await request.get('/vouchers/providers');
    return response;
  }

  static async insert(data) {
    const response = await request.post('/vouchers', data);
    if (response) { return response.data }
  }

  static async detail(id) {
    const { data: response } = await request.get(`/vouchers/${id}`);
    return response;
  }

  static async edit(id, data) {
    const response = await request.put(`/vouchers/${id}`, data);
    if (response) { return response.data }
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/vouchers/${id}`);
    return response;
  }

  static async getDatatable(page, limit, sort, order, filter) {

    const response = await request.get('/vouchers', {
      params: { page, limit, sort, order, filter }
    });

    return response.data;
  }

  static async getVoucherUsage(id, page, limit, sort, order, filter) {

    const response = await request.get(`/vouchers/${id}/orders`, {
      params: { page, limit, sort, order, filter }
    });

    return response.data;
  }
}

export default VoucherService;