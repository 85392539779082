import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input } from 'reactstrap';
import {
  CUSTOMER_BIG_SELLER,
  CUSTOMER_RESELLER,
  PAYMENT_STATUS_UNPAID,
  PEMBAYARAN_DEPOSIT
} from '../../../../constants';

function PaymentOrderComponent(props) {
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    getBanks();
    // getPaymentChannels();
  }, [props.payment_status_id]);

  const getBanks = async () => {
    try {
      let data = props.banks;

      if ([CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER].includes(props.customer.customer_type_id)) {
        data.forEach(d => {
          if (d.id === PEMBAYARAN_DEPOSIT) {
            d.account_number = (props.customer.deposit.balance ? props.customer.deposit.balance : 0).toLocaleString(
              'id',
              {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0
              }
            );
          }
        });
      } else {
        data = data.filter(d => d.id !== PEMBAYARAN_DEPOSIT);
      }

      setBanks(data);
    } catch (error) {
      console.log(error);
    }
  };

  const isUnpaid = () => parseInt(props.payment_status_id) === parseInt(PAYMENT_STATUS_UNPAID);

  const isOptionDisabled = id => {
    if (id === PEMBAYARAN_DEPOSIT) {
      const cost = props.payment_status_id === props.STATUS_PAID ? props.countGrandTotal() : props.payment_amount;

      if (props.customer.deposit.balance < cost) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <Col md="4">
      <FormGroup>
        <label className="form-control-label" htmlFor="bank_id">
          {/* {isUnpaid() ? 'Pembayaran' : 'Bank'} */}
          Metode Pembayaran
          <sup>
            <span className="text-red">*</span>
          </sup>
        </label>
        {isUnpaid() ? (
          <Input
            id="bank_id"
            placeholder="Pilih Pembayaran"
            type="select"
            name="payment"
            innerRef={props.paymentRef}
            // value={props.bank_id}
            onChange={e => {
              e.target.classList.remove('is-invalid');
              if (e.target.value) {
                const value = JSON.parse(e.target.value);
                props.handleChangePayment(value.group.verification_type, value.channel.code);
              }
            }}
          >
            <option value="">--Pilih Pembayaran--</option>
            {props.channels.map((group, i) => (
              <optgroup label={group.payment_type} key={i}>
                {group.payment_channel.map((channel, j) => (
                  <option value={JSON.stringify({ channel, group })} key={j}>
                    {channel.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </Input>
        ) : (
          <Input
            id="bank_id"
            placeholder="Pilih Bank"
            type="select"
            name="bank_id"
            innerRef={props.bankRef}
            value={props.bank_id}
            onChange={e => {
              e.target.classList.remove('is-invalid');
              props.handleChange(e.target.name, e.target.value);
            }}
          >
            <option value="">--Pilih Bank--</option>
            {banks.map((bank, key) => (
              <option value={bank.id} key={key} disabled={isOptionDisabled(bank.id)}>
                {`${bank.provider.name} - ${bank.account_number} - ${bank.account_name}`}
              </option>
            ))}
          </Input>
        )}
      </FormGroup>
    </Col>
  );
}

export default PaymentOrderComponent;
