import React from 'react';
import { Card, CardBody, Row, CardHeader } from 'reactstrap';
import { Line } from 'react-chartjs-2';

const ChartAffiliasi = props => {
  return (
    <Card className="bg-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="h2 text-light text-uppercase ls-1 mb-1">Overview</h6>
            <h6 className="text-light text-uppercase ls-1 mb-1">Omzet</h6>
          </div>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart-tracking">
          <Line
            data={props.commission}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      let label = context.dataset.label || '';

                      if (label) {
                        label += ': ';
                      }
                      if (context.parsed.y !== null) {
                        label += new Intl.NumberFormat('id-ID', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0
                        }).format(context.parsed.y);
                      }
                      return label;
                    }
                  }
                }
              },
              scales: {
                y: {
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, values) {
                      return value.toLocaleString('id', {
                        style: 'currency',
                        currency: 'IDR',
                        minimumFractionDigits: 0
                        // maximumFractionDigits: 0
                      });
                    }
                  }
                }
              }
            }}
            id="chart-sales-dark"
            className="chart-canvas"
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartAffiliasi;
