// import debounce from '../../../../helpers/debounce';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import Swal from 'sweetalert2';
import { BANK_MANDIRI } from '../../../../constants';
import BankService from '../../../../services/BankService';
import OrderService from '../../../../services/OrderService';

function SetLunasBulkModal(props) {
  // const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(false);

  const [banks, setBanks] = useState([]);
  const [bankId, setBankid] = useState(undefined);
  const [paymentDate, setPaymentDate] = useState('');
  const [image, setImage] = useState(null);
  const [imageBlob, setImageBlob] = useState('');
  const [file, setFile] = useState(null);
  const [dataRes, setDataRes] = useState([]);

  const paymentDateRef = useRef();
  const bankRef = useRef();
  const imageRef = useRef();
  const fileRef = useRef();

  useEffect(() => {
    getBanks();
    // window.addEventListener('paste', async event => {
    //   const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    //   for (let index in items) {
    //     const item = items[index];
    //     if (item.kind === 'file') {
    //       const blob = item.getAsFile();
    //       const reader = new FileReader();
    //       reader.onload = event => {
    //         if (props.isOpen) {
    //           if (blob.size / 1000000 <= 15) {
    //             handleImagePaste(blob);
    //           } else {
    //             Swal.fire({
    //               icon: 'error',
    //               title: 'Terjadi Kesalahan',
    //               text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
    //             });
    //           }
    //         }
    //       }; // data url!
    //       reader.readAsArrayBuffer(blob);
    //     }
    //   }
    // });
  }, [props.isOpen, dataRes]);

  // const handleImagePaste = debounce(async file => {
  //   console.log('file', file);
  //   const formData = new FormData();
  //   formData.append('file', file);

  //   if (file) {
  //     try {
  //       setLoading(true);
  //       const image = await OrderService.uploadImage(formData);

  //       if (image.message === 'OK') {
  //         // setImageURL(image.data[0]);
  //         setLoading(false);
  //       } else {
  //         this.setState({ loading: false });
  //         throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   }
  // }, 2000);

  const getBanks = async () => {
    if (banks.length === 0) {
      try {
        const banks = await BankService.getLists();
        if (banks.status === 200) {
          setBanks(banks.data.data);
          setBankid(BANK_MANDIRI);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const setNotValid = dom => {
    dom.classList.add('is-invalid');
    dom.focus();
  };

  const isValid = () => {
    if (!paymentDate || paymentDate === '') {
      setNotValid(paymentDateRef.current);
      return false;
      // } else if (!imageURL || imageURL === '') {
      //   setNotValid(imageRef.current);
      //   return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (isValid()) {
      const data = new FormData();
      data.append('file', file);
      data.append('image', image);
      data.append('payment_date', paymentDate);
      data.append('bank_id', bankId);

      try {
        setLoading(true);
        const response = await OrderService.updateSetLunasBatch(data);

        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          Swal.fire({
            title: 'Order berhasil diset lunas',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          });

          setDataRes(response.data.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const closeModal = () => {
    setBanks([]);
    setBankid(undefined);
    setPaymentDate('');
    setLoading(false);
    setImage(null);
    setImageBlob('');
    setFile(null);
    setDataRes([]);

    props.toggle();
  };

  const handleFile = e => {
    e.preventDefault();

    setFile(e.target.files[0]);
  };

  const handleUploadImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    setImage(e.target.files[0]);

    const reader = new FileReader();
    reader.onload = event => {
      setImageBlob(event.target.result);
    }; // data url!
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleDownloadLinkTemplate = async () => {
    try {
      const res = await OrderService.getTemplateSetLunasBatch();

      if (res.status === 200) {
        window.open(res.data.data.url, '_blank');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {props.isOpen && (
        <Modal isOpen={props.isOpen} toggle={closeModal} centered style={{ minWidth: '70%' }}>
          <ModalHeader toggle={closeModal} charCode="x">
            Pelunasan Order
          </ModalHeader>
          <ModalBody>
            <Form>
              <Row className="mt-4">
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="payment_date">
                          Tanggal Bayar
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          id="paid_date"
                          type="date"
                          name="paid_date"
                          // min={new Date().toISOString().split('T')[0]}
                          value={paymentDate}
                          innerRef={paymentDateRef}
                          onKeyDown={e => e.preventDefault()}
                          onWheel={e => e.preventDefault()}
                          onChange={e => {
                            console.log();
                            e.persist();
                            e.target.classList.remove('is-invalid');
                            // this.handleChange('paid_date', e.target.value);
                            setPaymentDate(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="bank_id">
                          File{' '}
                          <span
                            style={{ color: '#b01d1d', cursor: 'pointer' }}
                            onClick={handleDownloadLinkTemplate}
                          >
                            (download template)
                          </span>
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            type="file"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx"
                            ref={fileRef}
                            id="customFileLang"
                            lang="en"
                            onChange={handleFile}
                          />
                          <label className="custom-file-label" htmlFor="customFileLang">
                            Pilih file
                          </label>
                        </div>
                        <p>{file && file.name}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="bank_id">
                          Bank
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          id="bank_id"
                          placeholder="Pilih Bank"
                          type="select"
                          name="bank_id"
                          innerRef={bankRef}
                          value={bankId}
                          onChange={e => {
                            e.target.classList.remove('is-invalid');
                            setBankid(e.target.value);
                          }}
                        >
                          {banks.map((bank, key) => (
                            <option value={bank.id} key={key}>
                              {`${bank.provider.name} - ${bank.account_number} - ${bank.account_name}`}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="customFileLang">
                          Bukti Transfer
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            type="file"
                            accept="image/*"
                            ref={imageRef}
                            id="customFileLang"
                            lang="en"
                            onChange={handleUploadImage}
                          />
                          <label className="custom-file-label" htmlFor="customFileLang">
                            Pilih file
                          </label>
                        </div>
                        <div>
                          {imageBlob !== '' && (
                            <img
                              src={imageBlob}
                              alt="order evidence url"
                              width="200"
                              height="200"
                              style={{ paddingTop: 5 }}
                            />
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
            {dataRes.length > 0 && (
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nomor Invoice</th>
                      <th>Platform</th>
                      <th>Nomor Order</th>
                      <th>Sukses</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataRes.map((d, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{d.invoice_number}</td>
                        <td>{d.platform}</td>
                        <td>{d.order_number}</td>
                        <td>
                          {d.success ? (
                            <span role="img" aria-label="success">
                              ✅
                            </span>
                          ) : (
                            <span role="img" aria-label="failed">
                              ❌
                            </span>
                          )}
                        </td>
                        <td>{d.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {dataRes.length > 0 ? (
              <Button disabled={loading} onClick={closeModal} color="primary">
                {loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    &nbsp; Loading...
                  </>
                ) : (
                  'Tutup'
                )}
              </Button>
            ) : (
              <Button disabled={loading} onClick={handleSubmit} color="primary">
                {loading ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    &nbsp; Loading...
                  </>
                ) : (
                  'Upload'
                )}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default SetLunasBulkModal;
