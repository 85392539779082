import React from 'react';

import QrReader from 'react-qr-reader';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Card, Container, Row, CardHeader, Col, Button, CardBody, FormGroup, Form, Label, Input } from 'reactstrap';
import FlowService from '../../../services/FlowService';

import Swal from 'sweetalert2';

class ScanOrder extends React.Component {
  state = {
    results: [],
    scan: false,
    flow_id: ''
  };

  success = new Audio('https://coversuper.sgp1.cdn.digitaloceanspaces.com/audio/success-sound-effect.mp3');
  failed = new Audio('https://coversuper.sgp1.cdn.digitaloceanspaces.com/audio/failed-sound-effect.ogg');

  handleScan = async result => {
    try {
      if (result) {
        this.setState({ flow_id: result });
        await this.submit();
      }
    } catch (error) {
      console.log(error);
      let message = error.response ? error.response.data.message : error.message;

      this.failed.play();

      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: message
      });
    }
  };

  handleError = e => {
    this.failed.play();
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: `Terjadi kesalahan saat scan qr code. ${e.message}`
    });
  };

  submit = async () => {
    const { flow_id } = this.state;
    const scan = await FlowService.scan(flow_id);

    if (scan && scan.status === 200) {
      this.setState({
        scan: false,
        flow_id: ''
      });

      this.success.play();

      Swal.fire({
        icon: 'success',
        title: 'Berhasil'
      });
    }
  };

  render() {
    return (
      <>
        <SimpleHeader name="Status Produksi" />

        <Container className="mt--4" fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Status Produksi</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="text-center">
                  <Button onClick={() => this.setState({ scan: !this.state.scan })}>
                    {this.state.scan ? 'stop' : 'Kamera'}
                  </Button>
                  {this.state.scan && (
                    <QrReader
                      delay={1500}
                      onError={this.handleError}
                      onScan={this.handleScan}
                      style={{ width: '100%' }}
                    />
                  )}
                  <Row style={{ marginTop: 24, justifyContent: 'center' }}>
                    <Col md={4}>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          this.submit();
                        }}
                      >
                        <FormGroup>
                          <Label>FLOW ID (ID PRODUKSI)</Label>
                          <Input
                            value={this.state.flow_id}
                            type="text"
                            onChange={e => {
                              this.setState({
                                flow_id: e.target.value.replace(/[^A-Za-z0-9]+/gi, '').toUpperCase()
                              });
                            }}
                          />
                        </FormGroup>
                        <Button color="primary">Submit</Button>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ScanOrder;
