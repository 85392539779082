import React, { useEffect, useState } from 'react';
import { Container, Row, Col, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import AffiliateService from '../../../../services/AffiliateService';
import TableChart from './components/TableChart';
import TablePenjualan from './components/TablePenjualan';
import Loading from '../../../../components/Loader/Loader';
// import { chartOptions, parseOptions } from '../../../../variables/charts';
// import SimpleHeader from '../../../../components/Headers/SimpleHeader';
// import Chart from 'chart.js';

const Tracking = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Komisi Afiliasi',
        data: [],
        borderColor: ['#11cdef']
      }
    ]
  });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [start_date, setStartDate] = useState('');
  // const [setEndDate] = useState('');

  // const startDateRef = useRef();

  const getDataCharts = () => {
    setLoading(true);
    AffiliateService.getDataChart()
      .then(res => {
        setChartData({
          labels: res.data.charts.commission.labels,
          datasets: [
            {
              label: 'Komisi Afiliasi',
              data: res.data.charts.commission.datasets[0].data,
              borderColor: ['#11cdef']
            }
          ]
        });
        setOrders(res.data.orders);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getDataCharts();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">Tracking</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem aria-current="page" className="active">
                    Tracking Produk Afiliasi
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <TableChart commission={chartData} />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <TablePenjualan data={orders} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Tracking;
