import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
// import CurrencyInput from '../../../../helpers/CurrencyInput';
import debounce from '../../../../helpers/debounce';
import CustomerService from '../../../../services/CustomerService';
import BankService from '../../../../services/BankService';

function PayingOffBillingModal(props) {
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [payment_date, setPaymentDate] = useState('');
  const [evidence, setEvidence] = useState('');
  const [bank_id, setBankId] = useState(undefined);

  const evidenceImageRef = useRef();
  const paymentDateRef = useRef();
  const bankRef = useRef();

  useEffect(() => {
    getBanks();
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (props.isOpen) {
              if (blob.size / 1000000 <= 15) {
                handleImagePaste(blob);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'File tidak boleh lebih dari 15 MB. Silakan coba menggunakan upload gambar biasa'
                });
              }
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }, [props.isOpen]);

  const handleImagePaste = debounce(async file => {
    const formData = new FormData();
    formData.append('file', file);

    if (file) {
      try {
        setLoading(true);
        const image = await CustomerService.uploadImageVerifyBilling(formData);

        if (image.status === 200) {
          setEvidence(image.data.data[0]);
          setLoading(false);
        } else {
          this.setState({ loading: false });
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, 2000);

  const getBanks = async () => {
    try {
      const res = await BankService.getOnlyBanks();

      if (res.status === 200) {
        setBanks(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setLoading(false);
    setBankId(undefined);
    setEvidence('');
    setPaymentDate('');
    props.toggle('verifyBillingModalOpen');
  };

  const isValid = () => {
    const invalidRef = (ref, apa) => {
      ref.classList.add('is-invalid');
      ref.focus();
    };

    if (!bank_id || bank_id === '') {
      invalidRef(bankRef.current);
      return false;
    } else if (!payment_date || payment_date === '') {
      invalidRef(paymentDateRef.current, 'tangl');
      return false;
    } else if (!evidence || evidence === '') {
      invalidRef(evidenceImageRef.current, 'bukti');
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (isValid()) {
        setLoading(true);
        const res = await CustomerService.payBilling(props.row.id, {
          bank_id,
          evidence,
          payment_date
        });

        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Berhasil',
            text: `Pelunasan untuk tagihan ${props.row.id} berhasil .`
          }).then(() => {
            handleClose();
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    if (e.target.files.length > 0) {
      const formData = new FormData();
      for (const file of e.target.files) {
        formData.append('file', file);
      }

      try {
        setLoading(true);
        const image = await CustomerService.uploadImageVerifyBilling(formData);

        if (image.status === 200) {
          setEvidence(image.data.data[0]);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
        }
      } catch (error) {
        setLoading(false);
        // this.props.errorAlert(error.message);
      }
    }
  };

  const formatDate = date => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  return (
    <>
      <Modal size="lg" centered toggle={handleClose} isOpen={props.isOpen}>
        <ModalHeader>Pelunasan Tagihan {props.row ? props.row.id : ''}</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>Pilih Bank</Label>
                  <Input
                    id="bank_id"
                    placeholder="Pilih Bank"
                    type="select"
                    name="bank_id"
                    innerRef={bankRef}
                    value={bank_id}
                    onChange={e => {
                      e.target.classList.remove('is-invalid');
                      setBankId(e.target.value);
                    }}
                  >
                    <option value="">--Pilih Bank--</option>
                    {banks.length > 0 &&
                      banks.map((bank, key) => (
                        <option value={bank.id} key={key}>
                          {`${bank.bank_provider_name} - ${bank.account_number} - ${bank.account_name}`}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>Tanggal Pembayaran</Label>
                  <Input
                    id="payment_date"
                    type="date"
                    name="payment_date"
                    // min={new Date(start_date ? start_date : new Date()).toISOString().split('T')[0]}
                    max={new Date().toISOString().split('T')[0]}
                    innerRef={paymentDateRef}
                    onKeyDown={e => e.preventDefault()}
                    value={payment_date}
                    onChange={e => {
                      e.target.classList.remove('is-invalid');
                      const date = formatDate(e.target.value);

                      setPaymentDate(date);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="customFileLang">
                    Bukti Transfer
                    <sup>
                      <span className="text-red">*</span>
                    </sup>
                  </label>
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="customFileLang"
                      lang="en"
                      accept="image/*"
                      ref={evidenceImageRef}
                      type="file"
                      multiple
                      onChange={handleImage}
                    />
                    <label className="custom-file-label" htmlFor="customFileLang">
                      Pilih file
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                {evidence && (
                  <div>
                    <img
                      src={evidence}
                      alt="order evidence url"
                      style={{ maxWidth: '100%', borderRadius: '3%' }}
                      onClick={() => window.open(evidence, '_blank')}
                    />
                  </div>
                )}
              </Col>
            </Row>

            <Button disabled={loading} color="success">
              {loading ? (
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  &nbsp; Loading...
                </>
              ) : (
                'Simpan'
              )}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default PayingOffBillingModal;
