import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import { STATUS_CANCEL, STATUS_UNVERIFIED } from '../../../../constants';
import OrderService from '../../../../services/OrderService';

const StatusModal = props => {
  const [loading, setLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [status_type_id, setStatusTypeId] = useState(undefined);

  useEffect(() => {
    if (statusList.length === 0) {
      getStatusList();
    }
  }, [statusList]);

  const toggle = () => {
    setLoading(false);
    setStatusTypeId(undefined);
    setStatusList([]);

    props.toggleModal(props.row, 'isStatusModalOpen');
  };

  const getStatusList = async () => {
    setLoading(true);
    try {
      const response = await OrderService.getStatusOptions();

      if (response.status === 200) {
        setStatusList(response.data.filter(stat => stat.value > STATUS_UNVERIFIED && stat.value < STATUS_CANCEL));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await OrderService.changeTypeStatus(status_type_id, props.row.id);

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Status berhasil diubah'
        });
        setLoading(false);
        toggle();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Modal isOpen={props.isOpen} fade={false} backdrop="static" toggle={toggle}>
      <ModalHeader toggle={toggle}>Ganti Status</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="length">Status Produksi</Label>
            <Input
              type="select"
              name="status_type_id"
              id="status_type_id"
              onChange={e => {
                setStatusTypeId(e.target.value);
              }}
              value={status_type_id}
            >
              <option value="">-- Pilih Status --</option>
              {statusList.map((stat, i) => (
                <option key={i} value={stat.value}>
                  {stat.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" disabled={loading} onClick={handleSubmit}>
          {loading ? (
            <>
              <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              &nbsp; Loading...
            </>
          ) : (
            'Simpan'
          )}
        </Button>
        <Button color="secondary" disabled={loading} onClick={toggle}>
          Batalkan
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StatusModal;
