import React, { useState, useEffect } from 'react';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Container, Row, Card, CardHeader, Col, Button, CardBody, FormGroup, Input } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Swal from 'sweetalert2';
import { request } from '../../../config';
import ReviewService from '../../../services/ReviewService';

const { SearchBar } = Search;

function ReviewComponent(props) {
  const [categoryId, setCategoryId] = useState(0);
  const [categoryLists, setCategoryLists] = useState([]);
  const [loading] = useState(false);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [isPublished, setIsPublished] = useState(1);

  useEffect(() => {
    getCategoryLists();
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  async function getCategoryLists() {
    try {
      const response = await request.get('/categories/active');

      if (response.status === 200) {
        setCategoryLists(response.data.data);
      }
    } catch (error) {
      console.log(error.response);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi kesalahan',
        text: error.response.message
      });
    }
  }

  async function getData() {
    try {
      const args = {
        page,
        limit: sizePerPage,
        filter: searchText
      };
      const response = await ReviewService.getDatatable(args, categoryId, isPublished);
      if (response.status === 200) {
        setData(response.data);
      }

      if (response.data.length <= 0) {
        Swal.fire({
          icon: 'info',
          title: 'Data tidak ditemukan',
          text: `${
            categoryLists.filter(list => list.id === parseInt(categoryId)).length > 0
              ? `Tidak ada data ulasan untuk kategori cover ${
                  categoryLists.filter(list => list.id === parseInt(categoryId))[0].name
                } berstatus ${parseInt(isPublished) === 1 ? 'Aktif' : 'Tidak Aktif'}`
              : parseInt(categoryId) === 0
              ? 'Silahkan pilih kategori'
              : ''
          }`
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: error.response
      });
    }
  }

  function onTableChange(type, newState) {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  }

  return (
    <>
      <SimpleHeader name="Ulasan" parentName="Ulasan Pelanggan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cari Ulasan Berdasarkan Kategori Cover</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Input
                        value={categoryId}
                        type="select"
                        name="reviewId"
                        onChange={e => {
                          setCategoryId(e.target.value);
                        }}
                      >
                        <option value="">-- Pilih Jenis Ulasan --</option>
                        {categoryLists.map((list, key) => (
                          <option value={list.id} key={key}>
                            {list.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Input
                        value={isPublished}
                        type="select"
                        name="reviewId"
                        onChange={e => {
                          setIsPublished(e.target.value);
                        }}
                      >
                        <option value="">-- Pilih Status Aktif --</option>
                        <option value="0">Tidak Aktif</option>
                        <option value="1">Aktif</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <Button onClick={getData} disabled={loading} color="primary">
                      Cari
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {data.length > 0 && (
          <Row>
            <Col>
              <Card>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'customer_name',
                      text: 'Nama Customer',
                      sort: false
                    },
                    {
                      dataField: 'content',
                      text: 'Konten Ulasan',
                      sort: false
                    },
                    {
                      dataField: 'rating',
                      text: 'Rating',
                      sort: false,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        return (
                          <>
                            {[...Array(row.rating)].map((rating, i) => (
                              <span key={i} className="btn-inner--icon">
                                <i className="fas fa-star" />
                              </span>
                            ))}{' '}
                            {` `} ({cell})
                          </>
                        );
                      }
                    },
                    {
                      dataField: 'is_published',
                      text: 'Status Aktif',
                      sort: false,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        const btnClassName = row.is_published ? 'fas fa-check' : 'fas fa-times';
                        return (
                          <Button
                            size="sm"
                            onClick={() => {
                              Swal.fire({
                                icon: 'question',
                                text: 'Apakah Anda yakin ingin mengganti status?',
                                showCancelButton: true
                              }).then(async value => {
                                if (value.isConfirmed) {
                                  try {
                                    const response = await ReviewService.changeStatus(row.id);
                                    if (response.status === 200) {
                                      Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil diubah',
                                        onClose: () => getData()
                                      });
                                    }
                                  } catch (error) {
                                    console.log(error.response);
                                    Swal.fire({
                                      icon: 'error',
                                      title: 'Terjadi kesalahan'
                                    });
                                  }
                                }
                              });
                            }}
                          >
                            <i className={btnClassName}></i>
                          </Button>
                        );
                      }
                    }
                  ]}
                  search
                >
                  {props => {
                    return (
                      <div className="py-4 table-responsive" style={{ overflow: 'hidden' }}>
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar delay={500} className="form-control-sm" placeholder="" {...props.searchProps} />
                          </label>
                        </div>
                        <BootstrapTable
                          remote={{
                            filter: true,
                            pagination: true,
                            sort: true
                          }}
                          loading={true}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={paginationFactory({
                            page: page,
                            sizePerPage: sizePerPage,
                            totalSize: totalSize,
                            showTotal: true,
                            withFirstAndLast: true,
                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                              <div className="dataTables_length" id="datatable-basic_length">
                                <label>
                                  Show{' '}
                                  {
                                    <select
                                      name="datatable-basic_length"
                                      aria-controls="datatable-basic"
                                      className="form-control form-control-sm"
                                      onChange={e => onSizePerPageChange(e.target.value)}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  }{' '}
                                  entries.
                                </label>
                              </div>
                            )
                          })}
                          bordered={false}
                          onTableChange={onTableChange}
                        />
                      </div>
                    );
                  }}
                </ToolkitProvider>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default withRouter(ReviewComponent);
