import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Table,
  UncontrolledAlert
} from 'reactstrap';

// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader';
// sweet alert
import Swal from 'react-bootstrap-sweetalert';

// service
import AddonService from '../../../services/AddonService';
import BankService from '../../../services/BankService';
import CourierService from '../../../services/CourierService';
import CoverListService from '../../../services/CoverListService';
import CoverTypeService from '../../../services/CoverTypeService';
import CustomerTypeService from '../../../services/CustomerTypeService';
import FlowService from '../../../services/FlowService';
import OrderService from '../../../services/OrderService';
import PaymentStatusService from '../../../services/PaymentStatusService';
import RegionalService from '../../../services/RegionalService';
import ShippingCostService from '../../../services/ShippingCost';
// import ColorService from '../../../services/ColorService';

import Can from '../../../config/Can';
import {
  CUSTOMER_BIG_SELLER,
  CUSTOMER_RESELLER,
  PAYMENT_STATUS_BILLING,
  PAYMENT_STATUS_COD_MP,
  PAYMENT_STATUS_MARKETPLACE,
  PAYMENT_STATUS_PAID,
  PEMBAYARAN_DEPOSIT,
  STATUS_ANTRI_PRODUKSI,
  TYPE_KARPET
} from '../../../constants';
import { CapitalizeFirstLetter } from '../../../helpers/CapitalizeFirstLetter';
import CurrencyInput from '../../../helpers/CurrencyInput';

import { connect } from 'react-redux';

import Swal2 from 'sweetalert2';
import './EditOrder.css';

class EditOrder extends React.Component {
  state = {
    user: this.props.user,
    STATUS_DP: '2',
    STATUS_PAID: '3',
    customerModal: false,
    paymentFormShow: false,
    checkInsurance: false,
    currentVariant: {},
    alert: null,
    warning: null,
    imageURL: '',
    loading: false,
    provinceOptions: [],
    districtOptions: [],
    subdistrictOptions: [],
    addonOptions: [],
    courierOptions: [],
    courierServiceOptions: [],
    shipping_type: 'manual',
    customer: { name: '', phone: '', phone_alt: '', customer_type_id: undefined },
    paymentStatusOptions: [],
    tax: 0,
    tax_rate: 0,
    gross_amount: 0,
    platformOptions: [
      { value: 'Regular', label: 'Regular', invoice_required: false },
      { value: 'Bukalapak', label: 'Bukalapak', invoice_required: true },
      { value: 'Tokopedia', label: 'Tokopedia', invoice_required: true },
      { value: 'Shopee', label: 'Shopee', invoice_required: true },
      { value: 'Lazada', label: 'Lazada', invoice_required: true }
    ],
    shipping_address: {
      id: '',
      name: '',
      phone: '',
      phone_alt: '',
      email: '',
      address: '',
      province_id: '',
      district_id: '',
      subdistrict_id: '',
      postal_code: '',
      customer_type_id: ''
    },
    supplier: {
      id: '',
      name: '',
      address: '',
      province_id: '',
      district_id: '',
      subdistrict_id: ''
    },
    courier: {
      code: '',
      name: '',
      service: '',
      description: '',
      cost: 0,
      insurance: {
        percent: 0,
        flat_fee: 0,
        minimum_fee: 0
      }
    },
    details: [
      {
        addons: [],
        color_id: 0,
        year: '',
        note: '',
        cover_type_id: 0,
        cover_list_id: 0,
        logo: ''
      }
    ],
    platform: '',
    resi: '',
    invoice_required: false,
    invoice_number: '',
    discount: 0,
    subtotal: 0,
    additional_cost: '0',
    insurance_fee: 0,
    additional_cost_note: '',
    order_description: '',
    banks: [],
    order_source: '',
    coverTypes: [],
    coverLists: [],
    logoOptions: ['TANPA LOGO', 'LOGO CUSTOM', 'LOGO COVERSUPER', 'LOGO MERK MOBIL'],
    payment_status_id: '',
    payment_channel: '',
    payment: null,
    payment_histories: [
      {
        id: null,
        bank: {
          id: null,
          account_name: '',
          account_number: 0,
          provider: {
            name: ''
          }
        },
        payment_date: '',
        evidences: [],
        payment_status: {
          id: null,
          name: ''
        },
        payment_amount: 0
      }
    ],
    payment_date: '',
    paid_date: '',
    payment_amount: '0',
    grand_total: 0,
    bank_id: '',
    evidenceImagePaste: '',
    evidenceImagePasteFile: null,
    created_at: null
  };

  // customer ref
  invoiceNumberRef = React.createRef();
  shippingAddressNameRef = React.createRef();
  shippingAddressPhoneRef = React.createRef();
  shippingAddressPhoneAltRef = React.createRef();
  shippingAddressAddressRef = React.createRef();
  shippingAddressProvinceRef = React.createRef();
  shippingAddressDistrictRef = React.createRef();
  shippingAddressSubdistrictRef = React.createRef();
  shippingAddressPotalCodeRef = React.createRef();

  // courier ref
  courierSelectRef = React.createRef();
  nameSelectRef = React.createRef();
  serviceSelectRef = React.createRef();
  ongkirCostRef = React.createRef();

  // payment ref
  paymentStatusRef = React.createRef();
  paidDateRef = React.createRef();
  paymentAmountRef = React.createRef();
  evidenceImageRef = React.createRef();
  bankRef = React.createRef();

  modalPreviewRef = React.createRef();
  ImgPreviewRef = React.createRef();
  closeModalRef = React.createRef();

  componentDidMount() {
    this.getProvince();
    this.getCustomerType();
    this.getCourier();
    this.getAddons();
    this.getPaymentStatus();
    this.getData();
    this.getCoverTypes();
    this.getCoverLists();
    this.getBanks();
    window.addEventListener('paste', async event => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = event => {
            if (blob.size / 1000000 <= 10) {
              if (this.state.paymentFormShow) {
                this.setState({
                  evidenceImagePaste: event.target.result,
                  evidenceImagePasteFile: blob
                });
              } else {
                Swal2.fire({
                  icon: 'warning',
                  text: 'Buka dulu'
                });
              }
            } else {
              Swal2.fire({
                icon: 'error',
                title: 'Terjadi kesalahan saat upload foto',
                text: 'File foto lebih besar dari 15 MB. Silakan coba menggunakan upload file manual.'
              });
            }
          }; // data url!
          reader.readAsArrayBuffer(blob);
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.evidenceImagePasteFile && prevState.evidenceImagePasteFile !== this.state.evidenceImagePasteFile) {
      this.handleImagePaste(this.state.evidenceImagePasteFile);
    }

    if (this.state.customer.customer_type_id !== prevState.customer.customer_type_id) {
      this.getBanks();
    }
  }

  handleImagePaste = async file => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      this.setState({ loading: true });
      const image = await OrderService.uploadImage(formData);

      if (image.message === 'OK') {
        this.setState({
          imageURL: image.data[0],
          loading: false
        });
        this.resetEvidenceImagePasteFile();
      } else {
        this.setState({ loading: false });
        throw new Error('Terjadi kesalahan pada saat upload bukti transfer.');
      }
    } catch (error) {
      this.setState({ loading: false });
      Swal2.fire({
        icon: 'error',
        title: error.message
      });
    }
  };

  resetEvidenceImagePasteFile = () => {
    this.setState({ evidenceImagePaste: '', evidenceImagePasteFile: null });
  };

  isChangeValid = (name, value) => {
    if (name === 'order_description' && value.length > 200) {
      return false;
    }

    return true;
  };

  handleChange = (name, value) => {
    const revertCurrencyFormat = ['payment_amount', 'additional_cost', 'discount', 'cost'];

    if (this.isChangeValid(name, value)) {
      if (revertCurrencyFormat.some(field => name === field)) {
        if (name === 'cost') {
          this.setState(
            {
              courier: {
                ...this.state.courier,
                [name]: Number(value.slice(3).replace(/\./g, ''))
              }
            },
            () => {
              this.setState({
                tax: this.countPPN()
              });
            }
          );
        } else if ('additional_cost') {
          this.setState(
            {
              [name]: Number(value.slice(3).replace(/\./g, ''))
            },
            () => {
              this.setState({
                tax: this.countPPN()
              });
            }
          ); // menghapus 'Rp ' dan titik
        } else {
          this.setState({ [name]: Number(value.slice(3).replace(/\./g, '')) }); // menghapus 'Rp ' dan titik
        }
      } else {
        this.setState({ [name]: value });
      }
    }
  };

  getCoverTypes = async () => {
    try {
      const coverTypes = await CoverTypeService.getLists();
      this.setState({
        coverTypes: coverTypes.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  getCoverLists = async () => {
    try {
      const coverLists = await CoverListService.list();
      this.setState({
        coverLists: coverLists.data
      });
    } catch (error) {
      console.log(error);
    }
  };

  getBanks = async () => {
    try {
      const banks = await BankService.getLists();
      if (banks.status === 200) {
        let data = banks.data.data;

        if ([CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER].includes(this.state.customer.customer_type_id)) {
          data.forEach(d => {
            if (d.id === PEMBAYARAN_DEPOSIT) {
              d.account_number = (this.state.customer.deposit.balance
                ? this.state.customer.deposit.balance
                : 0
              ).toLocaleString('id', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0
              });
            }
          });
        } else {
          data = data.filter(d => d.id !== PEMBAYARAN_DEPOSIT);
        }

        this.setState({ banks: data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChangePlatform = value => {
    let platform = this.state.platformOptions.find(element => element.value === value);

    this.setState({
      platform: value,
      invoice_required: platform ? platform.invoice_required : false
    });
  };

  changeDefaultRegional = () => {
    if (this.state.shipping_address.province.id) {
      this.getProvince();
      this.getDistrict(this.state.shipping_address.province_id);
      this.getSubdistrict(this.state.shipping_address.district_id);
    }
  };

  hideAlert = () => {
    this.setState({ alert: null }, () => {
      this.props.history.goBack();
    });
  };

  dangerAlert = () => {
    this.setState({
      alert: (
        <Swal
          danger
          title="Pesanan Bermasalah"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          Pesanan gagal disimpan, silakan cek kembali
        </Swal>
      )
    });
  };

  successAlert = () => {
    this.setState(
      {
        alert: (
          <Swal success title="Success" confirmBtnBsStyle="success" confirmBtnText="Ok" onConfirm={() => {}} btnSize="">
            Data berhasil diubah
          </Swal>
        )
      },
      () => {
        setTimeout(() => {
          this.hideAlert();
        }, 1500);
      }
    );
  };

  getProvince = async () => {
    const provinceOptions = await RegionalService.province();
    this.setState({ provinceOptions: provinceOptions.data });
  };

  getDistrict = async province_id => {
    const districtOptions = await RegionalService.districtByProvince(province_id);
    this.setState({ districtOptions: districtOptions.data });
  };

  getSubdistrict = async district_id => {
    const subdistrictOptions = await RegionalService.subdistrictByDistrict(district_id);
    this.setState({ subdistrictOptions: subdistrictOptions.data });
  };

  getCustomerType = async () => {
    let customerTypeOptions = await CustomerTypeService.list();
    this.setState({ customerTypeOptions: customerTypeOptions.data });
  };

  getCourier = async () => {
    let courierOptions = await CourierService.courierList();
    this.setState({ courierOptions: courierOptions.data });
  };

  getAddons = async () => {
    let addonOptions = await AddonService.list();
    this.setState({ addonOptions: addonOptions.data });
  };

  getPaymentStatus = async () => {
    let paymentStatusOptions = await PaymentStatusService.list();
    this.setState({ paymentStatusOptions: paymentStatusOptions.data });
  };

  setDefaultCourier = () => {
    this.setState({
      courier: {
        code: '',
        name: '',
        service: '',
        description: '',
        cost: 0,
        insurance: {
          percent: 0,
          flat_fee: 0,
          minimum_fee: 0
        }
      }
    });
  };

  handleUploadImage = async e => {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    if (e.target.files.length > 0) {
      try {
        this.setState({ loading: true });
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append('file', file);

        const image = await OrderService.uploadImage(formData);

        if (image.message === 'OK') {
          this.setState({
            imageURL: image.data[0],
            loading: false
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  getCourierService = async courier => {
    const weight = this.countWeight();
    const origin = this.state.supplier.subdistrict_id;
    const destination = this.state.shipping_address.subdistrict_id;

    // jika kurir belum dipilih
    if (!destination) {
      // jika alamat penerima masih kosong masih kosong
      this.setState(
        {
          warning: (
            <UncontrolledAlert color="warning" fade={false}>
              <span className="alert-inner--text">
                <strong>Peringatan!</strong> silahkan isi data Pengiriman !
              </span>
            </UncontrolledAlert>
          )
        },
        () => {
          this.setDefaultCourier();
          this.shippingAddressSubdistrictRef.current.focus();
        }
      );
    } else {
      const data = { courier, weight, origin, destination };
      let courierServiceOptions = await ShippingCostService.getOngkir(data);
      this.setState({
        courier: {
          ...this.state.courier,
          service: '',
          description: '',
          cost: 0,
          insurance: {
            ...this.state.courier.insurance
          }
        }
      });
      this.setState({ courierServiceOptions: courierServiceOptions.data });
    }
  };

  handleChangeCourier = e => {
    const { value: code } = e.target;

    e.target.classList.remove('is-invalid');

    if (code !== 'manual') {
      const index = e.nativeEvent.target.selectedIndex;
      const name = e.nativeEvent.target[index].text;

      this.getCourierService(code);
      this.setState({
        courier: {
          ...this.state.courier,
          code,
          name
        }
      });

      // this.ongkirCostRef.current.removeIsInvalid()
      // // console.log(this.ongkirCostRef.current)
    } else if (code === 'manual') {
      this.setState({
        courier: {
          code,
          name: this.state.courier_name,
          service: this.state.service_code,
          description: this.state.service_name,
          cost: this.state.shipping_cost
        }
      });
    }
  };

  handleChangeCourierName = e => {
    e.target.classList.remove('is-invalid');
    this.setState({
      courier: {
        ...this.state.courier,
        [e.target.name]: e.target.value,
        description: e.target.value
      }
    });
  };

  handleChangeCourierService = e => {
    const { value: service } = e.target;

    const courier = this.state.courierServiceOptions.find(courier => courier.service === service);
    e.target.classList.remove('is-invalid');

    this.setState({
      courier: {
        ...this.state.courier,
        ...courier
      }
    });
  };

  isNewCustomerValidated = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'name' && value.length > 100) {
      return false;
    } else if (name === 'address' && value.length > 200) {
      return false;
    } else if ((name === 'phone' || name === 'phone_alt') && value.length > 15) {
      return false;
    } else if (name === 'postal_code' && value.length > 5) {
      return false;
    } else if (name === 'email' && value.length > 50) {
      return false;
    }

    return true;
  };

  isDepositAgen = (paymentId = this.state.bank_id) => {
    return (
      [CUSTOMER_BIG_SELLER, CUSTOMER_RESELLER].includes(this.state.customer.customer_type_id) &&
      Number(paymentId) === PEMBAYARAN_DEPOSIT
    );
  };

  shippingAddressChange = e => {
    e.persist();

    if (this.isNewCustomerValidated(e)) {
      this.setState(prevState => {
        let shipping_address = prevState.shipping_address;

        if (e.target.name === 'phone' || e.target.name === 'phone_alt' || e.target.name === 'postal_code') {
          let value = e.target.value.replace(/[^0-9]+/gi, '');

          if (value[0] === '0') {
            value = value.slice(1);
          }

          if (value.slice(0, 2) === '62') {
            value = value.slice(2);
          }

          shipping_address[e.target.name] = value;
        } else {
          shipping_address[e.target.name] = e.target.value;
        }

        return { shipping_address };
      });
    }
  };

  handleChangeProvince = e => {
    this.shippingAddressChange(e);
    if (e.target.value) {
      this.getDistrict(e.target.value);
      this.setState({ subdistrictOptions: [] });
      this.setDefaultCourier();
    } else {
      this.setState({
        districtOptions: [],
        subdistrictOptions: []
      });
    }
  };

  handleChangeDistrict = e => {
    this.shippingAddressChange(e);
    if (e.target.value) {
      this.getSubdistrict(e.target.value);
      this.setDefaultCourier();
    } else {
      this.setState({ subdistrictOptions: [] });
    }
  };

  togglePaymentForm = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  isChangeProductVariantValid = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'note' && value.length > 200) {
      return false;
    }

    return true;
  };

  handleChangeProduct = (e, index) => {
    e.persist();

    if (this.isChangeProductVariantValid(e)) {
      this.setState(prevState => {
        const details = prevState.details;
        details[index][e.target.name] = e.target.value;

        return { details };
      });
    }
  };

  changeInsurance = () => {
    if (this.state.checkInsurance) {
      this.setState({
        checkInsurance: false
      });
    } else {
      this.setState({
        checkInsurance: true
      });
    }
  };

  countInsuranceFee = () => {
    if (this.state.checkInsurance) {
      const { percent, flat_fee, minimum_fee } = this.state.courier.insurance;
      const totalPriceWithoutShippingCost = this.countForInsuranceTotal() - parseInt(this.state.courier.cost);
      const insurance_fee = Math.ceil((totalPriceWithoutShippingCost * percent) / 100 / 100) * 100;
      const insuranceTotal = insurance_fee + flat_fee;
      let totalInsurance = Math.max(insuranceTotal, minimum_fee);

      return totalInsurance;
    } else {
      return 0;
    }
  };

  countInsuranceFee = () => {
    if (this.state.courier.insurance) {
      const { percent, flat_fee, minimum_fee } = this.state.courier.insurance;
      const totalPriceWithoutShippingCost = this.countForInsuranceTotal() - parseInt(this.state.courier.cost);
      const insurance_fee = Math.ceil((totalPriceWithoutShippingCost * percent) / 100 / 100) * 100;
      const insuranceTotal = insurance_fee + flat_fee;
      let totalInsurance = Math.max(insuranceTotal, minimum_fee);

      return totalInsurance ? totalInsurance : this.state.insurance_fee;
    } else {
      return this.state.insurance_fee;
    }
  };

  countForInsuranceTotal = () => {
    const priceForInsurance =
      this.countSubtotal() + parseInt(this.state.additional_cost) - parseInt(this.state.discount) || 0;

    return priceForInsurance;
  };

  countTotal = variant => {
    const { price, qty } = variant;
    const addonsPrice = variant.addons.reduce((accumulator, addon) => accumulator + addon.price, 0);
    return (price + addonsPrice) * qty;
  };

  countSubtotal = () => {
    const subtotal = this.state.details.reduce((accumulator, variant) => accumulator + this.countTotal(variant), 0);

    return subtotal;
  };

  countWeight = () => {
    return this.state.total_weight;
  };

  countGrossTotal = () => {
    const grossTotal =
      this.countSubtotal() +
        parseInt(this.state.courier.cost) +
        parseInt(this.state.additional_cost) -
        parseInt(this.state.discount) +
        this.countInsuranceFee() || 0;

    return grossTotal;
  };

  handleChangePaymentStatus = e => {
    const payment_status_id = e.target.value;
    const stateChanged = { payment_status_id, payment_date: '' };

    if (payment_status_id === this.state.STATUS_PAID) {
      stateChanged['payment_amount'] = this.countGrossTotal();
    } else {
      stateChanged['payment_amount'] = 0;
    }

    // remove class invalid
    e.target.classList.remove('is-invalid');
    if (this.paymentAmountRef.current) {
      this.paymentAmountRef.current.classList.remove('is-invalid');
    }

    this.setState(stateChanged);
  };

  handlePhoneFormat = number => {
    if (number[0] === '0') {
      return number.slice(1);
    }

    if (number.slice(0, 2) === '62') {
      return number.slice(2);
    }

    if (number.slice(0, 3) === '+62') {
      return number.slice(3);
    }
  };

  getData = async () => {
    const { id } = this.props.match.params;
    try {
      const { data } = await OrderService.findById(id);
      data.details.forEach(detail => {
        detail.cover_type_id = detail.cover_type_id ? detail.cover_type_id : 0;
        detail.cover_list_id = detail.cover_list_id ? detail.cover_list_id : 0;
      });

      this.setState(
        {
          ...data,
          checkInsurance: data.insurance_fee ? true : false,
          customer: {
            ...data.customer,
            phone: data.customer.phone,
            phone_alt: data.customer.phone_alt
          },
          resi: data.resi ? data.resi : '',
          additional_cost_note: data.additional_cost_note ? data.additional_cost_note : '',
          bank_id: data.bank_id ? data.bank_id : '',
          order_description: data.order_description ? data.order_description : '',
          shipping_address: {
            ...data.shipping_address,
            phone: data.shipping_address.phone ? this.handlePhoneFormat(data.shipping_address.phone) : '',
            phone_alt: data.shipping_address.phone_alt ? this.handlePhoneFormat(data.shipping_address.phone_alt) : '',
            email: data.shipping_address.email ? data.shipping_address.email : ''
          }
        },
        async () => {
          this.changeDefaultRegional();
          const isCourierCodeInList = this.state.courierOptions.some(
            courier => courier.courier_code === this.state.courier_code
          );

          if (isCourierCodeInList) {
            await this.getCourierService(this.state.courier_code);
            this.setState({
              courier: {
                ...this.state.courier,
                code: data.courier_code,
                name: data.courier_name,
                service: data.service_code,
                description: data.service_name,
                cost: data.shipping_cost
              }
            });
          } else {
            this.setState({
              courier: {
                ...this.state.courier,
                code: 'manual',
                name: data.courier_name,
                service: data.service_name,
                description: data.service_name,
                cost: data.shipping_cost
              }
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  previewImage = url => {
    this.modalPreviewRef.current.style.display = 'block';
    this.ImgPreviewRef.current.src = url;
  };

  handleVerification = e => {
    this.setState({
      alert: (
        <Swal
          warning
          title="Apakah anda yakin?"
          onCancel={this.hideAlert}
          onConfirm={async () => {
            try {
              const flow = this.state.details[0];
              const { status } = await FlowService.forward({
                order_detail_id: flow.id,
                status_type_id: STATUS_ANTRI_PRODUKSI
              });
              if (status === 200) {
                this.successAlert();
              }
            } catch (error) {
              console.log(error);
            }
          }}
          showCancel
          cancelBtnBsStyle="link"
          cancelBtnText="Batal"
          confirmBtnBsStyle="info"
          confirmBtnText="Ya"
          btnSize=""
          reverseButtons={false}
          showCloseButton={true}
          allowEscape={false}
          closeOnClickOutside={false}
        >
          Pastikan data telah sesuai
        </Swal>
      )
    });
  };

  countPPN = () => {
    let ppn = this.countGrossTotal() * this.state.tax_rate;
    return ppn;
  };

  handlePPN = () => {
    // if (
    //   [PAYMENT_STATUS_COD_MP, PAYMENT_STATUS_MARKETPLACE].includes(this.state.payment_histories[0].payment_status.id)
    // ) {
    //   return 0;
    // }

    return this.state.tax;
  };

  isValid = () => {
    const { shipping_address, courier, payment_amount, payment_date, payment_status_id } = this.state;

    if (shipping_address.name === '') {
      const ref = this.shippingAddressNameRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (shipping_address.phone === '') {
      const ref = this.shippingAddressPhoneRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (shipping_address.address === '') {
      const ref = this.shippingAddressAddressRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (shipping_address.province_id === '') {
      const ref = this.shippingAddressProvinceRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (shipping_address.district_id === '') {
      const ref = this.shippingAddressDistrictRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (shipping_address.subdistrict_id === '') {
      const ref = this.shippingAddressSubdistrictRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (shipping_address.postal_code === '') {
      const ref = this.shippingAddressPostalCodeRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    }

    // kurir dan product validation
    if (courier.code === '') {
      const ref = this.courierSelectRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (courier.code === 'manual' && courier.name === '') {
      this.nameSelectRef.current.classList.add('is-invalid');
      this.nameSelectRef.current.focus();

      return false;
    } else if (courier.service === '') {
      const ref = this.serviceSelectRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (courier.cost === '' || courier.cost === '0' || courier.cost === 0) {
      this.ongkirCostRef.current.focusTextInput();

      return false;
    }

    // pembayaran
    if (payment_status_id === '') {
      const ref = this.paymentStatusRef.current;
      ref.className = ref.className + ' is-invalid';
      ref.focus();
      return false;
    } else if (
      this.state.paymentFormShow &&
      payment_date === '' &&
      parseInt(payment_status_id) !== PAYMENT_STATUS_COD_MP
    ) {
      const ref = this.paymentDateRef.current;

      ref.className = ref.className + ' is-invalid';
      ref.focus();

      return false;
    } else if (
      this.state.paymentFormShow &&
      (payment_amount === '' || payment_amount === 0) &&
      ![PAYMENT_STATUS_COD_MP, PAYMENT_STATUS_MARKETPLACE].includes(parseInt(payment_status_id))
    ) {
      const ref = this.paymentAmountRef.current;

      ref.className = ref.className + ' is-invalid';
      ref.focus();

      return false;
    } else if (this.state.paymentFormShow && (this.state.paid_date === '' || !this.state.paid_date)) {
      const ref = this.paidDateRef.current;
      ref.classList.add('is-invalid');
      ref.focus();

      return false;
    } else if (this.state.paymentFormShow && this.state.bank_id === '') {
      const ref = this.bankRef.current;
      ref.classList.add('is-invalid');
      ref.focus();

      return false;
    } else if (this.state.paymentFormShow && this.state.imageURL === '' && !this.isDepositAgen()) {
      const ref = this.evidenceImageRef.current;
      ref.classList.add('is-invalid');
      ref.focus();

      return false;
    }

    return true;
  };

  handleSubmit = async e => {
    e.preventDefault();

    try {
      if (this.isValid()) {
        const { id } = this.state;
        const {
          resi,
          courier,
          shipping_address: shippingAddress,
          details: productVariants,
          discount,
          additional_cost,
          additional_cost_note,
          order_description
        } = this.state;

        const gross_amount = this.countGrossTotal() || 0;
        const tax = this.handlePPN() || 0;
        const grand_total = gross_amount + tax || 0;

        const shipping_address = {
          name: shippingAddress.name,
          email: shippingAddress.email,
          phone: shippingAddress.phone ? `+62${shippingAddress.phone}` : shippingAddress.phone,
          phone_alt: shippingAddress.phone_alt ? `+62${shippingAddress.phone_alt}` : shippingAddress.phone_alt,
          address: shippingAddress.address,
          subdistrict_id: shippingAddress.subdistrict_id,
          district_id: shippingAddress.district_id,
          province_id: shippingAddress.province_id,
          postal_code: shippingAddress.postal_code
        };

        let details = [];

        for (let variant of productVariants) {
          details.push({
            id: variant.id,
            note: variant.note,
            color_id: variant.color_id,
            year: variant.year,
            cover_type_id: variant.cover_type_id !== '' ? variant.cover_type_id : null,
            cover_list_id: variant.cover_list_id !== '' ? variant.cover_list_id : null,
            logo: variant.logo
          });
        }

        let orderData = {
          resi,
          shipping_address,

          discount,
          additional_cost,
          // insurance_fee: this.state.checkInsurance ? this.countInsuranceFee() : 0,
          additional_cost_note,
          grand_total,
          tax,
          gross_amount,
          details,
          order_description
        };

        // changeabel courier, then set courier cost
        // if (this.state.shipping_type === 'manual') {
        orderData = {
          ...orderData,
          courier_code: courier.code === 'manual' ? courier.name.toLowerCase().replace(/ /g, '_') : courier.code, // kalo manual code sama dengan nama
          courier_name: courier.code === 'manual' ? CapitalizeFirstLetter(courier.name) : courier.name,
          service_code: courier.code === 'manual' ? CapitalizeFirstLetter(courier.service) : courier.service,
          service_name: courier.code === 'manual' ? CapitalizeFirstLetter(courier.service) : courier.description,
          shipping_cost: courier.cost
        };
        // }

        let payment = null;

        if (this.state.paymentFormShow) {
          payment = {
            payment_status_id: PAYMENT_STATUS_PAID,
            evidence: this.state.imageURL,
            payment_date: this.state.paid_date,
            payment_amount: grand_total - this.state.payment_amount,
            bank_id: this.state.bank_id
          };

          orderData.payment = payment;
        }

        // insert order
        // console.log(orderData);
        OrderService.update(id, orderData).then(() => this.successAlert());
      }
    } catch (error) {
      console.log(error);
    }
  };

  isOptionDisabled = id => {
    if (id === PEMBAYARAN_DEPOSIT) {
      const cost = this.state.payment_histories.length !== 0 && this.state.grand_total - this.state.payment_amount;

      if (this.state.customer.deposit.balance < cost) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Edit Order" parentName="Transaksi" parentLink="/orders" />
        <Container className="mt--6" fluid>
          <Row>
            <Col lg="4">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0 text-danger">Data Pemesan</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="customerName">
                        Nama
                      </Label>
                      <Input disabled value={this.state.customer.name}></Input>
                    </FormGroup>
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="customerPhone">
                        Telepon
                      </Label>
                      <Input disabled value={this.state.customer.phone}></Input>
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="platformOptions">
                        Sumber Order
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </label>
                      <Input
                        type="text"
                        placeholder="LP Facebook"
                        name="order_source"
                        disabled
                        defaultValue={this.state.order_source}
                      />
                    </FormGroup>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="platformOptions">
                        Platform
                      </label>
                      <Input disabled defaultValue={this.state.platform}></Input>
                    </FormGroup>
                    {this.state.invoice_number && (
                      <FormGroup>
                        <label className="form-control-label" htmlFor="invoice_number">
                          No. Invoice Marketplace
                        </label>
                        <Input type="text" name="invoice_number" defaultValue={this.state.invoice_number} disabled />
                      </FormGroup>
                    )}
                    {this.state.resi && (
                      <FormGroup>
                        <label className="form-control-label" htmlFor="resi">
                          Resi
                        </label>
                        <Input
                          name="resi"
                          value={this.state.resi}
                          onChange={e => {
                            if (e.target.value.length < 100) {
                              this.setState({ resi: e.target.value });
                            }
                          }}
                        />
                      </FormGroup>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col lg="8">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0 text-danger">Data Penerima</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="namaPenerima">
                            Nama
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="namaPenerima"
                            placeholder="Nama Penerima"
                            type="text"
                            name="name"
                            innerRef={this.shippingAddressNameRef}
                            value={this.state.shipping_address.name}
                            onChange={this.shippingAddressChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="province">
                            Provinsi
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="province"
                            value={this.state.shipping_address.province_id}
                            type="select"
                            name="province_id"
                            onChange={this.handleChangeProvince}
                          >
                            <option value="">--Pilih Provinsi--</option>
                            {this.state.provinceOptions.map((province, key) => (
                              <option value={province.id} key={key}>
                                {province.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="shippingAddressPhone">
                            No. Telepon Utama
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+62</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="shippingAddressPhone"
                              placeholder="08123xxx"
                              type="text"
                              name="phone"
                              innerRef={this.shippingAddressPhoneRef}
                              value={this.state.shipping_address.phone}
                              onChange={this.shippingAddressChange}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="district">
                            Kota / Kabupaten
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="district"
                            value={this.state.shipping_address.district_id}
                            type="select"
                            name="district_id"
                            onChange={this.handleChangeDistrict}
                          >
                            <option value="">--Pilih Kota / Kabupaten--</option>
                            {this.state.districtOptions.map((district, key) => (
                              <option value={district.id} key={key}>
                                {district.type === 'Kabupaten' ? 'Kab.' : district.type} {district.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="shippingAddressPhoneAlt">
                            No. Telepon Alternatif
                          </label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+62</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="shippingAddressPhoneAlt"
                              placeholder="8123xxx"
                              type="text"
                              name="phone_alt"
                              innerRef={this.shippingAddressPhoneAltRef}
                              value={this.state.shipping_address.phone_alt || ''}
                              onChange={this.shippingAddressChange}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="subdistrict">
                            Kecamatan
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="subdistrict"
                            value={this.state.shipping_address.subdistrict_id}
                            type="select"
                            name="subdistrict_id"
                            onChange={this.shippingAddressChange}
                          >
                            <option value="">--Pilih Kecamatan--</option>
                            {this.state.subdistrictOptions.map((subdistrict, key) => (
                              <option value={subdistrict.id} key={key}>
                                {subdistrict.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">
                            Email
                          </label>
                          <Input
                            id="email"
                            placeholder="email@email.com"
                            type="email"
                            name="email"
                            value={this.state.shipping_address.email}
                            onChange={this.shippingAddressChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="postalcode">
                            Kode Pos
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="postalcode"
                            placeholder="12345"
                            type="text"
                            name="postal_code"
                            value={this.state.shipping_address.postal_code}
                            onChange={this.shippingAddressChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="shippingAddressAdress">
                            Alamat Pengiriman
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          </label>
                          <Input
                            id="shippingAddressAdress"
                            placeholder="Alamat"
                            type="textarea"
                            rows="2"
                            name="address"
                            value={this.state.shipping_address.address}
                            onChange={this.shippingAddressChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0 text-danger">Produk</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Table className="align-items-center table-flush" responsive striped>
                        <thead className="thead-light">
                          <tr>
                            <th scope="column" width="15%">
                              Produk
                            </th>
                            <th scope="column">Warna</th>
                            <th scope="column">Tahun</th>
                            <th scope="column">Tipe</th>
                            <th scope="column">List</th>
                            <th scope="column">Logo</th>
                            <th scope="column">Harga (Rp)</th>
                            <th scope="column" width="20%">
                              Tambahan (RP)
                            </th>
                            <th scope="column">Kuantitas</th>
                            <th scope="column" width="20%">
                              Catatan
                            </th>
                            <th scope="column" className="text-right">
                              Total (Rp)
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list">
                          {this.state.details.map((variant, key) => {
                            const getProductName = item => {
                              let arr = [
                                item.category ? item.category.name : null,
                                // item.product.type_car ? item.product.type_car.name : null,
                                // item.manufacturer ? item.manufacturer.name : null,
                                item ? item.product_name : null
                              ];

                              if (item.category && item.category.vehicle_type_id === TYPE_KARPET) {
                                arr.push(item.variant.size);
                              }

                              return arr.filter(e => e).join(' ');
                            };

                            return (
                              <tr key={key}>
                                <td style={{ whiteSpace: 'unset' }}>{getProductName(variant)}</td>
                                <td>
                                  <Input
                                    type="select"
                                    name="color_id"
                                    value={variant.color_id}
                                    onChange={e => {
                                      this.handleChangeProduct(e, key);
                                    }}
                                    style={{ minWidth: '125px' }}
                                  >
                                    {variant.available_colors &&
                                      variant.available_colors.map(color => (
                                        <option key={color.id} value={color.id}>
                                          {color.name}
                                        </option>
                                      ))}
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    type="select"
                                    name="year"
                                    value={variant.year}
                                    onChange={e => {
                                      this.handleChangeProduct(e, key);
                                    }}
                                    style={{ minWidth: '125px' }}
                                  >
                                    <option value="">--Tahun--</option>
                                    {[...Array(parseInt(new Date().getFullYear()) - 1898).keys()]
                                      .map(key => key + 1900)
                                      .sort((a, b) => b - a)
                                      .map(year => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    id={`cover_type-${key}`}
                                    type="select"
                                    name="cover_type_id"
                                    value={variant.cover_type_id}
                                    onChange={e => {
                                      this.handleChangeProduct(e, key);
                                    }}
                                    style={{ minWidth: '125px' }}
                                  >
                                    <option value="">--Tipe Cover--</option>
                                    {this.state.coverTypes.map(type => (
                                      <option key={type.id} value={type.id}>
                                        {type.name}
                                      </option>
                                    ))}
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    id={`cover_list-${key}`}
                                    type="select"
                                    name="cover_list_id"
                                    value={variant.cover_list_id}
                                    onChange={e => {
                                      this.handleChangeProduct(e, key);
                                    }}
                                    style={{ minWidth: '125px' }}
                                  >
                                    <option value="">--Cover List--</option>
                                    {this.state.coverLists.map(list => (
                                      <option key={list.id} value={list.id}>
                                        {list.name}
                                      </option>
                                    ))}
                                  </Input>
                                </td>
                                <td>
                                  <Input
                                    id={`logo-${key}`}
                                    type="select"
                                    name="logo"
                                    value={variant.logo}
                                    onChange={e => {
                                      this.handleChangeProduct(e, key);
                                    }}
                                    style={{ minWidth: '125px' }}
                                  >
                                    <option value="">--Logo--</option>
                                    {this.state.logoOptions.map((list, key) => (
                                      <option key={key} value={list}>
                                        {list}
                                      </option>
                                    ))}
                                  </Input>
                                </td>

                                <td className="text-right">{new Intl.NumberFormat('id-ID').format(variant.price)}</td>
                                <td>
                                  {variant.addons.map((addon, key) => (
                                    <React.Fragment key={key}>
                                      - {addon.name} ({new Intl.NumberFormat('id-ID').format(addon.price)}
                                      ) <br />
                                    </React.Fragment>
                                  ))}
                                </td>
                                <td className="text-center">{variant.qty}</td>
                                <td>
                                  <Input
                                    type="textarea"
                                    style={{ minWidth: '200px' }}
                                    name="note"
                                    onChange={e => this.handleChangeProduct(e, key)}
                                    value={variant.note || ''}
                                  />
                                </td>
                                <td className="text-right">
                                  <strong>{new Intl.NumberFormat('id-ID').format(this.countTotal(variant))}</strong>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Row>
                    <hr />
                    <Row>
                      <Table className="align-items-center table-flush" responsive striped>
                        <tbody>
                          <tr>
                            <th width="50%" colSpan="5" className="text-red">
                              Subtotal
                            </th>
                            <th className="text-right">Rp</th>
                            <th width="20%" className="text-right" style={{ fontSize: '1rem' }}>
                              {new Intl.NumberFormat('id-ID').format(this.countSubtotal())}
                            </th>
                          </tr>
                          <tr>
                            <th width="50%" colSpan="5" className="text-red">
                              Total Berat
                            </th>
                            <th className="text-right"></th>
                            <th width="20%" className="text-right" style={{ fontSize: '1rem' }}>
                              {new Intl.NumberFormat('id-ID').format(this.countWeight() / 1000)} Kg
                            </th>
                          </tr>
                          <tr>
                            <td
                              width="50%"
                              colSpan={this.state.courier.code === 'manual' ? '3' : '4'}
                              className="text-red"
                            >
                              Ongkir
                            </td>
                            <td>
                              <Input
                                disabled={this.state.shipping_type === 'auto'}
                                innerRef={this.courierSelectRef}
                                type="select"
                                name="courier"
                                value={this.state.courier.code}
                                onChange={this.handleChangeCourier}
                                style={{ minWidth: '125px' }}
                              >
                                <option value="">--Kurir--</option>
                                <option value="manual">Input Manual</option>
                                {this.state.courierOptions.map((courier, key) => (
                                  <option key={key} value={courier.courier_code}>
                                    {courier.name}
                                  </option>
                                ))}
                              </Input>
                            </td>
                            {this.state.courier.code === 'manual' && (
                              <>
                                <td>
                                  <Input
                                    type="text"
                                    name="name"
                                    placeholder="Nama Kurir"
                                    innerRef={this.nameSelectRef}
                                    value={this.state.courier.name}
                                    onChange={this.handleChangeCourierName}
                                    style={{ minWidth: '125px' }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="text"
                                    name="service"
                                    placeholder="Nama Service"
                                    innerRef={this.serviceSelectRef}
                                    value={this.state.courier.service}
                                    onChange={this.handleChangeCourierName}
                                    style={{ minWidth: '125px' }}
                                  />
                                </td>
                              </>
                            )}
                            {this.state.courier.code !== 'manual' && (
                              <td>
                                <Input
                                  disabled={this.state.shipping_type === 'auto'}
                                  type="select"
                                  name="service"
                                  innerRef={this.serviceSelectRef}
                                  value={this.state.courier.service}
                                  onChange={this.handleChangeCourierService}
                                  style={{ minWidth: '125px' }}
                                >
                                  <option value="">--Layanan--</option>
                                  {this.state.courierServiceOptions.map((service, key) => (
                                    <option key={key} value={service.service}>
                                      {service.service}
                                    </option>
                                  ))}
                                </Input>
                              </td>
                            )}
                            <td>
                              <CurrencyInput
                                type="text"
                                className="form-control"
                                name="discount"
                                ref={this.ongkirCostRef}
                                disabled={this.state.courier.code !== 'manual'}
                                value={this.state.courier.cost.toString()}
                                style={{ minWidth: '125px' }}
                                onChange={e => {
                                  // remove class invalid
                                  e.target.classList.remove('is-invalid');
                                  this.handleChange('cost', e.target.value);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td width="50%" colSpan="4" className="text-red">
                              Asuransi
                            </td>
                            <td>
                              <div className="custom-control custom-checkbox">
                                <Input
                                  // innerRef={this.props.insuranceFeeRef}
                                  className="custom-control-input"
                                  id={'customCheckInsurance'}
                                  type="checkbox"
                                  disabled
                                  // disabled={this.state.shipping_type === 'auto'}
                                  // disabled={!this.state.courier.service}
                                  checked={this.state.checkInsurance}
                                  value={this.state.checkInsurance}
                                  onChange={e => {
                                    this.changeInsurance(e.target.value);
                                  }}
                                />
                                <label className="custom-control-label" htmlFor={'customCheckInsurance'}>
                                  +Asuransi
                                </label>
                              </div>
                            </td>
                            <td className="text-right">Rp</td>
                            <td width="20%" className="text-right" style={{ fontSize: '1rem' }}>
                              {new Intl.NumberFormat('id-ID').format(
                                this.state.checkInsurance ? this.countInsuranceFee() : 0
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th width="50%" colSpan="5" className="text-red">
                              Diskon Order
                            </th>
                            <th className="text-right">Rp</th>
                            <th>
                              <CurrencyInput
                                type="text"
                                className="form-control"
                                name="discount"
                                value={this.state.discount.toString()}
                                onChange={e => this.handleChange('discount', e.target.value)}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th width="50%" colSpan="3" className="text-red">
                              Biaya Lain-lain
                            </th>
                            <th colSpan="3">
                              <Input
                                type="text"
                                placeholder="Catatan Biaya"
                                value={this.state.additional_cost_note}
                                onChange={e => this.handleChange('additional_cost_note', e.target.value)}
                              />
                            </th>
                            <th>
                              <CurrencyInput
                                type="text"
                                className="form-control"
                                name="additional_cost"
                                value={this.state.additional_cost.toString()}
                                onChange={e => this.handleChange('additional_cost', e.target.value)}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th width="50%" colSpan="5" className="text-red">
                              Keterangan / Bonus
                            </th>
                            <th className="text-right"></th>
                            <th>
                              <Input
                                type="textarea"
                                placeholder="Bonus Payung dll"
                                name="order_description"
                                rows={3}
                                value={this.state.order_description}
                                onChange={e => this.handleChange('order_description', e.target.value)}
                              />
                            </th>
                          </tr>
                          <tr>
                            <th width="50%" colSpan="5" className="text-red">
                              Gross Total
                            </th>
                            <th className="text-right">Rp</th>
                            <th className="text-right" style={{ fontSize: '1rem' }}>
                              {new Intl.NumberFormat('id-ID').format(this.countGrossTotal())}
                            </th>
                          </tr>
                          <tr>
                            <th width="50%" colSpan="5" className="text-red">
                              PPN {this.state.tax_rate * 100}%
                            </th>
                            <th className="text-right">Rp</th>
                            <th className="text-right" style={{ fontSize: '1rem' }}>
                              {new Intl.NumberFormat('id-ID').format(this.handlePPN())}
                            </th>
                          </tr>
                          <tr>
                            <th width="50%" colSpan="5" className="text-red">
                              Grand Total
                            </th>
                            <th className="text-right">Rp</th>
                            <th className="text-right" style={{ fontSize: '2rem' }}>
                              {new Intl.NumberFormat('id-ID').format(this.countGrossTotal() + this.handlePPN())}
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0 text-danger">History Pembayaran</h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th>Tanggal</th>
                            <th>Status</th>
                            <th>Bukti Pembayaran</th>
                            <th>Total</th>
                            <th>Bank</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.payment_histories.map((py, i) => {
                            let payment = py;

                            if (
                              [PAYMENT_STATUS_MARKETPLACE, PAYMENT_STATUS_BILLING, PAYMENT_STATUS_COD_MP].includes(
                                py.payment_status.id
                              )
                            ) {
                              payment.bank = {
                                id: null,
                                provider: {
                                  name: ''
                                }
                              };
                            }

                            return (
                              <tr key={i}>
                                <td>
                                  {payment.payment_date
                                    ? new Date(payment.payment_date).toLocaleDateString('id-ID', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric'
                                      })
                                    : new Date(this.state.created_at).toLocaleDateString('id-ID', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                      })}
                                </td>
                                <td>
                                  <span
                                    className={
                                      'badge badge-' + (payment.payment_status.name === 'Lunas' ? 'success' : 'warning')
                                    }
                                  >
                                    {payment.payment_status.name}
                                  </span>
                                </td>
                                {payment.bank &&
                                (this.isDepositAgen(payment.bank.id) ||
                                  [PAYMENT_STATUS_BILLING].includes(payment.payment_status.id)) ? (
                                  <td>-</td>
                                ) : (
                                  <td>
                                    {payment.evidences &&
                                      payment.evidences.map((evidence, i) => (
                                        <img
                                          style={{ marginRight: 2 }}
                                          key={i}
                                          alt="evidence"
                                          width="100"
                                          onClick={() => this.previewImage(evidence.image)}
                                          height="100"
                                          src={evidence.image}
                                        />
                                      ))}
                                  </td>
                                )}
                                <td style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                                  {payment.payment_amount.toLocaleString('id', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    minimumFractionDigits: 0
                                  })}
                                </td>
                                <td>
                                  {payment.bank &&
                                  ![PAYMENT_STATUS_MARKETPLACE, PAYMENT_STATUS_BILLING, PAYMENT_STATUS_COD_MP].includes(
                                    payment.payment_status.id
                                  )
                                    ? `${payment.bank.provider.name} ${
                                        this.isDepositAgen(payment.bank.id) ? '' : `- ${payment.bank.account_number}`
                                      } - ${payment.bank.account_name}`
                                    : ''}
                                  {!payment.bank && <>{this.state.payment_channel}</>}
                                </td>
                                <td>
                                  {![PAYMENT_STATUS_PAID, PAYMENT_STATUS_BILLING].includes(
                                    this.state.payment_status_id
                                  ) && (
                                    <Can I="update" a="Set Lunas">
                                      <Button
                                        className="btn btn-success btn-sm"
                                        onClick={() => this.togglePaymentForm('paymentFormShow')}
                                        style={{ margin: '0 auto' }}
                                      >
                                        Set Lunas
                                      </Button>
                                    </Can>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Row>
                    {this.state.paymentFormShow && (
                      <Row className="mt-4">
                        <Col md="8">
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="payment_date">
                                  Tanggal Bayar
                                  <sup>
                                    <span className="text-red">*</span>
                                  </sup>
                                </label>
                                <Input
                                  id="paid_date"
                                  type="date"
                                  name="paid_date"
                                  value={this.state.paid_date}
                                  innerRef={this.paidDateRef}
                                  min={
                                    new Date(
                                      new Date(this.state.created_at).setHours(
                                        new Date(this.state.created_at).getHours() + 7
                                      )
                                    )
                                      .toISOString()
                                      .split('T')[0]
                                  }
                                  max={new Date().toISOString().split('T')[0]}
                                  onKeyDown={e => e.preventDefault()}
                                  onWheel={e => e.preventDefault()}
                                  onChange={e => {
                                    e.target.classList.remove('is-invalid');
                                    this.handleChange('paid_date', e.target.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="paymentAmount">
                                  Nominal Bayar
                                  <sup>
                                    <span className="text-red">*</span>
                                  </sup>
                                </label>
                                <CurrencyInput
                                  type="text"
                                  className="form-control"
                                  name="payment_amount"
                                  disabled
                                  value={
                                    this.state.payment_histories.length !== 0 &&
                                    this.state.grand_total - this.state.payment_amount
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <label className="form-control-label" htmlFor="bank_id">
                                  Bank
                                  <sup>
                                    <span className="text-red">*</span>
                                  </sup>
                                </label>
                                <Input
                                  id="bank_id"
                                  placeholder="Pilih Bank"
                                  type="select"
                                  name="bank_id"
                                  innerRef={this.bankRef}
                                  value={this.state.bank_id}
                                  onChange={e => {
                                    e.target.classList.remove('is-invalid');
                                    this.handleChange(e.target.name, e.target.value);
                                  }}
                                >
                                  <option value="">--Pilih Bank--</option>
                                  {this.state.banks.map((bank, key) => (
                                    <option value={bank.id} key={key} disabled={this.isOptionDisabled(bank.id)}>
                                      {`${bank.provider.name} - ${bank.account_number} - ${bank.account_name}`}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            {!this.isDepositAgen() && (
                              <Col md="6">
                                <FormGroup>
                                  <label className="form-control-label" htmlFor="customFileLang">
                                    Bukti Transfer
                                    <sup>
                                      <span className="text-red">*</span>
                                    </sup>
                                  </label>
                                  <div className="custom-file">
                                    <input
                                      className="custom-file-input"
                                      type="file"
                                      accept="image/*"
                                      id="customFileLang"
                                      lang="en"
                                      ref={this.evidenceImageRef}
                                      onChange={this.handleUploadImage}
                                    />
                                    <label className="custom-file-label" htmlFor="customFileLang">
                                      Pilih file
                                    </label>
                                  </div>
                                  <div>
                                    {this.state.imageURL !== '' && (
                                      <img
                                        src={this.state.imageURL}
                                        alt="order evidence url"
                                        width="200"
                                        height="200"
                                        style={{ paddingTop: 5 }}
                                      />
                                    )}
                                  </div>
                                </FormGroup>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>

          <Row style={{ marginBottom: 8 }}>
            <Col md="12">
              <div className="card-wrapper">
                <Button color="primary" size="md" disabled={this.state.loading} onClick={this.handleSubmit}>
                  Simpan
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        <div ref={this.modalPreviewRef} className="modal-image-preview-edit-order">
          <span
            className="close-image-preview-edit-order"
            onClick={() => {
              this.modalPreviewRef.current.style.display = 'none';
            }}
          >
            &times;
          </span>
          <img className="modal-content-image-preview-edit-order" alt="preview" ref={this.ImgPreviewRef} />
          <div id="caption"></div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, {})(EditOrder);
