import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import Swal from 'sweetalert2';
import CustomerService from '../../../../services/CustomerService';
import Can from '../../../../config/Can';

import AddBillingModal from './AddBillingModal';

import moment from 'moment';
import 'moment/locale/id';
import Loader from '../../../../components/Loader/Loader';
import CardToday from '../../dashboards/components/CardToday';
import PayingOffBillingModal from './PayingOffBillingModal';
moment.locale('id');

function TableBilling(props) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    billingModalOpen: false,
    verifyBillingModalOpen: false
  });
  const [customer, setCustomer] = useState({
    id: props.match.params.customer_id,
    name: '',
    phone: '',
    billing: {
      unbilled: 0,
      unpaid: 0
    }
  });
  const [selectedRow, setSelectedRow] = useState(null);
  // const [downloadButtonShow, setDownloadButtonShow] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  const [totalSize, setTotalSize] = useState(undefined);

  // const startDateRef = useRef();
  // const endDateRef = useRef();

  useEffect(() => {
    getData(props.match.params.customer_id, page, limit, filter);
    // getCustomer(props.match.params.customer_id);
  }, []);

  // const getCustomer = async id => {
  //   const response = await CustomerService.findById(id);

  //   if (response.status === 200) {
  //     setCustomer({
  //       name: response.data.name,
  //       balance: response.data.deposit.balance,
  //       id
  //     });
  //   }
  // };

  const isValid = () => {
    // const setRef = ref => {
    //   ref.current.classList.add('is-invalid');
    // };

    // if (!start_date || start_date === '') {
    //   setRef(startDateRef);
    //   return false;
    // } else if (!end_date || end_date === '') {
    //   setRef(endDateRef);
    //   return false;
    // }
    return true;
  };

  const getData = async (customer_id, page, limit, filter) => {
    try {
      setLoading(true);
      const res = await CustomerService.getBillingDatatable(customer_id, {
        page,
        limit,
        filter
      });

      if (res.status === 200) {
        setLoading(false);

        // if (res.data.data.length === 0) {
        //   Swal.fire({
        //     icon: 'error',
        //     title: 'Tidak ada data',
        //     text: `Data ${filter ? `dengan filter ${filter}` : ''} tidak ditemukan di rentang waktu tersebut`
        //   });
        // }

        setData(res.data.data);
        setPage(res.data.page);
        setLimit(res.data.limit);
        setTotalSize(res.data.recordsFiltered);
        setCustomer(res.data.customer);
        // getCustomer(props.match.params.customer_id);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const toggleModal = (name, row = null) => {
    setSelectedRow(row);
    if (modal[name]) {
      getData(props.match.params.customer_id, page, limit, filter);
      setModal({ ...modal, [name]: !modal[name] });
    } else {
      setModal({ ...modal, [name]: !modal[name] });
    }
  };

  const deleteBill = async billing_id => {
    setLoading(true);
    try {
      const res = await CustomerService.removeBilling(billing_id);
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: `Tagihan ${billing_id} berhasil dihapus`,
          timer: 2000
        }).then(() => {
          getData(props.match.params.customer_id, page, limit, filter);
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onTableChange = (type, newState) => {
    getData(props.match.params.customer_id, newState.page, newState.sizePerPage, newState.searchText);
  };

  return (
    <>
      {loading && <Loader />}
      <SimpleHeader name={`Tabel Tagihan ${customer.name}`} parentName="Customer" parentLink="/customers" />
      <Container className="mt--6" fluid>
        <div className="header-body">
          <Row className="align-items-center">
            <Col md="6" xl="4">
              <CardToday
                name="Belum Tertagih"
                icon="ni ni-money-coins"
                value={customer.billing.unbilled.toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0
                })}
                gradient="green"
              />
            </Col>
            <Col md="6" xl="4">
              <CardToday
                name="Belum Terbayar"
                icon="ni ni-money-coins"
                value={customer.billing.unpaid.toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0
                })}
                gradient="red"
              />
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Can I="create" a="Billing">
                  <Row className="my-3">
                    <Col xs={{ size: 4, offset: 8 }}>
                      <div style={{ float: 'right' }}>
                        <Button size="sm" color="success" onClick={() => toggleModal('billingModalOpen')}>
                          Buat Tagihan
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Can>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label htmlFor="filter">Filter</label>
                      <Input
                        id="filter"
                        type="text"
                        placeholder="Masukkan filter"
                        name="filter"
                        value={filter}
                        onChange={e => {
                          setFilter(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => {
                        if (isValid()) {
                          // setDownloadButtonShow(true);
                          getData(props.match.params.customer_id, page, limit, filter);
                        }
                      }}
                    >
                      Cari
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {data.length > 0 && (
              <Card>
                {/* {downloadButtonShow && (
                  <CardHeader
                    className="border-0"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <h3 className="mb-0">Laporan Deposit {customer.name}</h3>
                    <Button size="sm" color="success" onClick={handleDownload}>
                      Download
                    </Button>
                  </CardHeader>
                )} */}
                <Row>
                  <CardBody>
                    <ToolkitProvider
                      data={data}
                      keyField="id"
                      columns={[
                        {
                          dataField: 'no',
                          text: 'Nomor',
                          sort: false
                        },
                        {
                          dataField: 'id',
                          text: 'ID Tagihan',
                          sort: true
                        },
                        {
                          dataField: 'description',
                          text: 'Deskripsi',
                          sort: true
                        },
                        {
                          dataField: 'total',
                          text: 'Jumlah',
                          sort: true,
                          formatter: cell => {
                            return cell.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                              minimumFractionDigits: 0
                            });
                          }
                        },
                        {
                          dataField: 'is_paid',
                          text: 'Terbayar',
                          sort: true,
                          formatter: cell => {
                            return cell ? '✅' : '❌';
                          }
                        },
                        {
                          dataField: 'is_verified',
                          text: 'Terverifikasi',
                          sort: true,
                          formatter: cell => {
                            return cell ? '✅' : '❌';
                          }
                        },
                        {
                          dataField: 'action',
                          text: 'Aksi',
                          sort: false,
                          isDummyField: true,
                          formatter: (cell, row, rowIndex, formatExtraData) => {
                            return (
                              <>
                                <Button
                                  color="info"
                                  type="button"
                                  size="sm"
                                  onClick={() => {
                                    props.history.push(`/customers/${customer.id}/billing/${row.id}`);
                                  }}
                                >
                                  Detail
                                </Button>
                                {!!!row.is_paid && !!!row.is_verified && (
                                  <Button
                                    color="success"
                                    type="button"
                                    size="sm"
                                    onClick={() => {
                                      toggleModal('verifyBillingModalOpen', row);
                                    }}
                                  >
                                    <span className="btn-inner--text"> Pelunasan</span>
                                  </Button>
                                )}
                                {!!!row.is_paid && (
                                  <Can I="delete" a="Billing">
                                    <Button
                                      color="danger"
                                      type="button"
                                      size="sm"
                                      onClick={() => {
                                        Swal.fire({
                                          icon: 'warning',
                                          title: `Apakah Anda yakin akan menghapus tagihan ${row.id}?`,
                                          showConfirmButton: true,
                                          showCancelButton: true,
                                          focusCancel: true,
                                          cancelButtonText: 'Batal',
                                          confirmButtonText: 'Hapus',
                                          confirmButtonColor: 'red'
                                        }).then(result => {
                                          if (result.value) {
                                            deleteBill(row.id);
                                          }
                                        });
                                      }}
                                    >
                                      <span className="btn-inner--text"> Hapus</span>
                                    </Button>
                                  </Can>
                                )}
                              </>
                            );
                          }
                        }
                      ]}
                    >
                      {props => {
                        return (
                          <div className="table-responsive">
                            <BootstrapTable
                              remote={{
                                filter: true,
                                pagination: true,
                                sort: true
                              }}
                              loading={true}
                              {...props.baseProps}
                              bootstrap4={true}
                              pagination={paginationFactory({
                                page: page,
                                sizePerPage: limit,
                                totalSize: totalSize,
                                showTotal: true,
                                withFirstAndLast: true,
                                sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                  <div className="dataTables_length" id="datatable-basic_length">
                                    <label>
                                      Show{' '}
                                      {
                                        <select
                                          name="datatable-basic_length"
                                          aria-controls="datatable-basic"
                                          className="form-control form-control-sm"
                                          onChange={e => onSizePerPageChange(e.target.value)}
                                        >
                                          <option value="10">10</option>
                                          <option value="25">25</option>
                                          <option value="50">50</option>
                                          <option value="100">100</option>
                                        </select>
                                      }{' '}
                                      entries.
                                    </label>
                                  </div>
                                )
                              })}
                              bordered={false}
                              onTableChange={onTableChange}
                            />
                          </div>
                        );
                      }}
                    </ToolkitProvider>
                  </CardBody>
                </Row>
              </Card>
            )}
          </div>
        </Row>
      </Container>
      <AddBillingModal isOpen={modal.billingModalOpen} toggle={toggleModal} customer={customer} />
      <PayingOffBillingModal
        isOpen={modal.verifyBillingModalOpen}
        toggle={toggleModal}
        customer={customer}
        row={selectedRow}
      />
    </>
  );
}

export default TableBilling;
