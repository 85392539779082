import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import { Card, Col, Container, Progress, Row } from 'reactstrap';
import NewDashboardTable from './NewDashboardTable';

import 'moment/locale/id';
import nominalBilangan from 'nominal-bilangan';

const StandingTable = props => {
  const { detail, unit, unitName, summary, year, handleSelectUnit, unitOptions, updateData } = props;

  const { today, yesterday, monthly } = summary;
  const diffOmzetToday = today.omzet - yesterday.omzet;
  const signDiffOmzet = diffOmzetToday > 0 ? '+' : diffOmzetToday < 0 ? '-' : '';
  const diffOmzetTodayPercentage = yesterday.omzet !== 0 ? _.ceil((diffOmzetToday / yesterday.omzet) * 100, 2) : 0;

  const { target, omzet, month } = monthly;
  const achievedMonthly = target !== 0 ? _.ceil((omzet / target) * 100, 2) : 0;

  const goBack = () => {
    handleSelectUnit('');
  };

  const renderTopThree = () => {
    let candidates = [];
    detail.map(item => {
      candidates.push(...item.monthly);
      return item;
    });

    let topThree = candidates.sort((a, b) => b.omzet - a.omzet).slice(0, 3);
    const isTopThree = topThree.length === 3;

    if (isTopThree) {
      // Urutan kustom yang diinginkan
      const customOrder = [1, 0, 2];
      topThree = customOrder.map(index => ({ ...topThree[index], position: index + 1 }));
    }

    return (
      <Row>
        {topThree.map((dealmaker, i) => {
          return (
            <Col md="4">
              <div className="d-flex justify-content-around">
                <div className="text-center" key={i}>
                  <img
                    className="rounded-circle"
                    style={dealmaker.position === 1 ? { marginTop: '-1.5rem' } : {}}
                    width={dealmaker.position === 1 ? 125 : 100}
                    alt="..."
                    src={`https://api.dicebear.com/7.x/initials/svg?seed=${dealmaker.name}&backgroundColor=3949ab,3499ab,00897b&backgroundType=gradientLinear`}
                  />
                  <br />
                  <img
                    decoding="async"
                    style={{ marginTop: '-1.5rem' }}
                    width={dealmaker.position === 1 ? 125 : 100}
                    src={`https://coversuper.sgp1.digitaloceanspaces.com/assets/scoreboard/badge-${dealmaker.position}.png`}
                    alt=""
                  />
                  <h3 className="text-white">{dealmaker.name}</h3>
                  <h2 className="text-white">
                    {dealmaker.omzet.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </h2>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <div className="vw-100 vh-full" style={{ background: '#000' }}>
      <Row className="p-3">
        {/* <img src="../../../assets/img/brand/coversuper_icon.png" alt="" /> */}
        <Col className="text-center text-white">
          <i
            className="fas fa-long-arrow-left"
            aria-hidden="true"
            style={{ fontWeight: 'bolder', color: 'white', fontSize: '2rem' }}
            onClick={goBack}
          />
        </Col>

        <Col lg="6">
          <h2 className="text-white text-center">{unitName}</h2>
        </Col>

        <Col style={{ color: 'black' }}>
          <Select
            placeholder={unitName}
            id="selectedUnit"
            type="select"
            name="selectedUnit"
            defaultValue={unitOptions.filter(option => option.value === unit)}
            onChange={select => {
              handleSelectUnit(select.value);
            }}
            options={unitOptions}
          />
        </Col>
      </Row>

      <Container fluid className="p-3">
        <Row>
          <Col>
            <Card
              className="p-3"
              style={{
                background: '#1a253f',
                color: 'white',
                listStyle: 'none'
              }}
            >
              <h2 className="text-white mb-1">Total Omzet vs Target Omzet</h2>
              <p className="text-white" style={{ fontSize: '12px' }}>
                {month} {year}
              </p>

              <div
                className="d-flex justify-content-between mb-1"
                style={{
                  fontSize: '20px',
                  fontWeight: '600'
                }}
              >
                {omzet.toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0
                })}
              </div>
              <Progress
                animated
                color={achievedMonthly >= 100 ? 'success' : 'danger'}
                style={{
                  width: '100%',
                  height: '1rem',
                  marginBottom: '1px'
                }}
                value={achievedMonthly}
              >
                {achievedMonthly}%
              </Progress>
              <div
                className="d-flex justify-content-between mb-1 mt-1"
                style={{
                  fontSize: '12px'
                }}
              >
                {target.toLocaleString('id', {
                  style: 'currency',
                  currency: 'IDR',
                  minimumFractionDigits: 0
                })}
              </div>
            </Card>

            <Card
              className="p-3"
              style={{
                background: '#1a253f',
                color: 'white',
                listStyle: 'none'
              }}
            >
              <li>
                <h2 className="text-white mb-1">Omzet Today</h2>
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}> {moment().format('D MMMM YYYY')}</span>
              </li>

              <br />

              <li style={{ fontSize: '20px', fontWeight: 'normal' }}>
                <h2 className="text-white">
                  {today.omzet.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </h2>
              </li>
              <li>
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {signDiffOmzet}Rp {nominalBilangan(Math.abs(diffOmzetToday))} ({signDiffOmzet}
                  {Math.abs(diffOmzetTodayPercentage)}%)
                </span>
              </li>
            </Card>

            <Card
              className="p-3"
              style={{
                background: '#1a253f',
                color: 'white',
                listStyle: 'none'
              }}
            >
              <li>
                <strong>
                  Omzet Yesterday <br />
                </strong>
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {moment()
                    .subtract(1, 'day')
                    .format('D MMMM YYYY')}
                </span>
              </li>

              <br />

              <li style={{ fontSize: '20px', fontWeight: 'normal' }}>
                <strong>
                  {yesterday.omzet.toLocaleString('id', {
                    style: 'currency',
                    currency: 'IDR',
                    minimumFractionDigits: 0
                  })}
                </strong>
              </li>
            </Card>
          </Col>

          <Col lg="6">
            <Card
              className="p-3"
              style={{
                background: '#19243d',
                color: 'white',
                listStyle: 'none'
              }}
            >
              <li>
                <h2 className="text-white mb-1">Top Dealmaker by Omzet</h2>
                <p className="text-white" style={{ fontSize: '12px' }}>
                  {month} {year}
                </p>
              </li>

              <br />

              {renderTopThree()}
            </Card>

            <NewDashboardTable
              updateData={updateData}
              detail={detail}
              periodicity="daily"
              unit={unit}
              name={`Daily Rank Dealmaker`}
            />

            <NewDashboardTable
              updateData={updateData}
              detail={detail}
              periodicity="monthly"
              unit={unit}
              name={`Monthly Rank Dealmaker`}
            />
          </Col>

          <Col>
            <Row>
              <Col lg="6">
                <Card
                  className="px-3 py-2"
                  style={{
                    background: '#1a253f',
                    color: 'white',
                    listStyle: 'none'
                  }}
                >
                  <h2 className="text-white mb-1">Leads Today</h2>
                  <p className="text-white" style={{ fontSize: '12px' }}>
                    {moment().format('D MMMM YYYY')}
                  </p>

                  <h2 className="text-white">{today.leads}</h2>
                </Card>
              </Col>

              <Col lg="6">
                <Card
                  className="px-3 py-2"
                  style={{
                    background: '#1a253f',
                    color: 'white',
                    listStyle: 'none'
                  }}
                >
                  <h2 className="text-white mb-1">Deal Today</h2>
                  <p className="text-white" style={{ fontSize: '12px' }}>
                    {moment().format('D MMMM YYYY')}
                  </p>

                  <h2 className="text-white">{today.deals}</h2>
                </Card>
              </Col>

              <Col lg="6">
                <Card
                  className="px-3 py-2"
                  style={{
                    background: '#1a253f',
                    color: 'white',
                    listStyle: 'none'
                  }}
                >
                  <h2 className="text-white mb-1">Rasio Ads</h2>
                  <p className="text-white" style={{ fontSize: '12px' }}>
                    {month} {year}
                  </p>

                  <h2 className="text-white">{(monthly.budget_ratio * 100).toFixed(2)}%</h2>
                </Card>
              </Col>

              <Col lg="6">
                <Card
                  className="px-3 py-2"
                  style={{
                    background: '#1a253f',
                    color: 'white',
                    listStyle: 'none'
                  }}
                >
                  <h2 className="text-white mb-1">Konversi</h2>
                  <p className="text-white" style={{ fontSize: '12px' }}>
                    {month} {year}
                  </p>

                  <h2 className="text-white">
                    {(() => {
                      const deals = monthly.deals;
                      const leads = monthly.leads;

                      const conversion = (deals / leads) * 100;
                      const isConversionNan = isNaN(conversion);

                      if (isConversionNan || conversion === Infinity) {
                        return `0%`;
                      } else {
                        return `${conversion > 0 ? conversion.toFixed(2) : conversion}%`;
                      }
                    })()}
                  </h2>
                </Card>
              </Col>

              <Col lg="6">
                <Card
                  className="px-3 py-2"
                  style={{
                    background: '#1a253f',
                    color: 'white',
                    listStyle: 'none'
                  }}
                >
                  <h2 className="text-white mb-1">Total Leads</h2>
                  <p className="text-white" style={{ fontSize: '12px' }}>
                    {month} {year}
                  </p>

                  <h2 className="text-white">{monthly.leads.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</h2>
                </Card>
              </Col>

              <Col lg="6">
                <Card
                  className="px-3 py-2"
                  style={{
                    background: '#1a253f',
                    color: 'white',
                    listStyle: 'none'
                  }}
                >
                  <h2 className="text-white mb-1">Cost Per Leads</h2>
                  <p className="text-white" style={{ fontSize: '12px' }}>
                    {month} {year}
                  </p>

                  <h2 className="text-white">
                    {monthly.cost_per_lead.toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0
                    })}
                  </h2>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StandingTable;
