import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../../actions/authActions';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert
} from 'reactstrap';
import AuthHeader from '../../../components/Headers/AuthHeader';
import iconSrc from '../../../assets/img/brand/coversuper_icon.png';
import useFormInput from '../../../helpers/useFormInput';
import { ROLE_CS_CMI, ROLE_CS_EMIRATE, ROLE_CS_FORCE } from '../../../constants';

function Login(props) {
  const [focusedEmail, setFocusedEmail] = useState(false);
  const [focusedPassword, setFocusedPassword] = useState(false);
  const email = useFormInput('');
  const password = useFormInput('');
  // const message = useFormInput('')

  function onSubmit(e) {
    e.preventDefault();

    props.login(email.value, password.value);
  }

  function showErrors() {
    if (props.error.message) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--text">{props.error.message}</span>
        </UncontrolledAlert>
      );
    }
  }

  function checkPermission() {
    if (props.isAuthenticated && props.token) {
      if ([ROLE_CS_FORCE, ROLE_CS_EMIRATE, ROLE_CS_CMI].includes(props.user.role_id)) {
        window.location.href = '/scoreboard';
      } else {
        window.location.href = '/dashboard';
      }
    }
  }

  useEffect(() => {
    showErrors();
    checkPermission();
  }, [props.isAuthenticated, props.token]);

  return (
    <>
      <AuthHeader />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent">
                <div className="text-muted text-center">
                  <br />
                  <img width="50%" src={iconSrc} alt="..." />
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                {showErrors()}
                <Form role="form" onSubmit={onSubmit}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: focusedEmail
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        {...email}
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="current-password"
                        {...password}
                        onFocus={() => setFocusedPassword(true)}
                        onBlur={() => setFocusedPassword(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  token: PropTypes.string,
  error: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    user: state.auth.user,
    error: state.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (email, password) => dispatch(login(email, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
