import React from 'react';
import { Button } from 'reactstrap';
import Can from '../../../../config/Can';

function RefundComplaintButton(props) {
  return (
    <Can I={props.verb} a={props.subject}>
      <Button
        onClick={() => {
          props.toggleModal(props.row, props.modalName);
        }}
        color={props.type === 'complaint' ? 'warning' : 'success'}
        size="sm"
        className="mb-2"
      >
        {props.children}
      </Button>
      <br />
    </Can>
  );
}

export default RefundComplaintButton;
