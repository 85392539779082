import { request } from '../config';

// versi function
function WebSettingService() {
  // Show detail
  const get = async () => {
    const response = await request.get(`/websettings`);
    return response.data;
  };

  // update Data
  const update = async (data) => {
    const response = await request.put(`/websettings`, data);
    return response.data;
  };

  return {
    get,
    update,
  };
}

export default WebSettingService();