import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import AffiliateService from '../../../../services/AffiliateService';
import LeaderBoard from './LeaderBoard';
import ReportAffiliator from './ReportAffiliator';
import CardAffiliate from './table/CardAffiliate';
import CardOmzet from './table/CardOmzet';
import ChartAffiliasi from './table/ChartAffiliasi';
import Loading from '../../../../components/Loader/Loader';
import CardReport from './table/CardReport';

const DashboardAffiliate = () => {
  const [data, setData] = useState({
    covers: [],
    colors: [],
    cities: []
  });
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({
    omzet_last_seven_days: 0,
    omzet_this_month: 0,
    omzet_today: 0,
    omzet_yesterday: 0,
    product_sold: 0,
    total_order_today: 0,
    total_order_yesterday: 0
  });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Omzet Partner',
        data: [],
        borderColor: '#5b6fdd'
        // backgroundColor: 'blue',
      }
    ]
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await AffiliateService.affiliateOverview();
      setData(data);
      setSummary(data);
      setChartData({
        labels: data.chart.omzet.labels,
        datasets: [
          {
            label: 'Omzet Partner',
            data: data.chart.omzet.datasets[0].data,
            borderColor: '#5b6fdd'
            // backgroundColor: 'blue',
          }
        ]
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">Partner Overview</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="/" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    Partner Overview
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3">
                <CardReport name="Order Hari Ini" value={summary.total_order_today} />
              </Col>
              <Col md="6" xl="3">
                <CardReport name="Order Kemarin" value={summary.total_order_yesterday} />
              </Col>
              <Col md="6" xl="3">
                <CardReport name="Produk Terjual" value={summary.product_sold} />
              </Col>
              <Col md="6" xl="3">
                <CardOmzet point name="Omzet Affiliasi Hari ini" value={summary.omzet_today} />
              </Col>

              <Col md="6" xl="3">
                <CardOmzet point name="Omzet Hari ini" value={summary.omzet_today} />
              </Col>
              <Col md="6" xl="3">
                <CardOmzet point name="Omzet Kemarin" value={summary.omzet_yesterday} />
              </Col>
              <Col md="6" xl="3">
                <CardOmzet point name="Omzet 7 Hari Terakhir" value={summary.omzet_last_seven_days} />
              </Col>
              <Col md="6" xl="3">
                <CardOmzet point name="Omzet Bulan ini" value={summary.omzet_this_month} />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col xs="12">
            <ChartAffiliasi commission={chartData} />
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <ReportAffiliator data={data} />
          </Col>
          <Col xs="6">
            <LeaderBoard data={data} />
          </Col>
        </Row>
        <Row>
          <Col xs="4">
            <CardAffiliate name="Cover" items={data.covers} />
          </Col>
          <Col xs="4">
            <CardAffiliate name="Warna" items={data.colors} />
          </Col>
          <Col xs="4">
            <CardAffiliate name="Kota Pemesan Terbanyak" items={data.cities} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardAffiliate;
