import React, { useEffect, useState } from 'react';
import AffiliateService from '../../../../services/AffiliateService';
import Axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { BreadcrumbItem, Breadcrumb, Button, Card, CardBody, Col, Container, FormGroup, Input, Row } from 'reactstrap';
// import Swal from 'sweetalert2';
import CardToday from '../../dashboards/components/CardToday';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import AddPayoutModal from './AddPayoutModal';
import UpdateAccountModal from './UpdateAccountModal';

const Payout = () => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({
    accountModalOpen: false
  });
  // const [selectedRow, setSelectedRow] = useState(null);

  const [dataAffiliate, setDataAffiliate] = useState({});
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getData();

    return () => {
      source.cancel();
    };
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const getDataAffiliate = () => {
    return dataAffiliate;
  };

  const getData = () => {
    AffiliateService.getDataHistoryPayout(page, sizePerPage, sortField, sortOrder, searchText).then(res => {
      setData(res.data.data);
      setDataAffiliate(res.data.affiliate);
    });
  };

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  const toggleModal = (name, row = null) => {
    // setSelectedRow(row);
    if (modal[name]) {
      getData(page, sizePerPage, sortField, sortOrder, searchText);
      setModal({ ...modal, [name]: !modal[name] });
    } else {
      setModal({ ...modal, [name]: !modal[name] });
    }
  };

  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {/* <img src={money} style={{ width: '50px', height: '50px', marginBottom: '20px', marginRight: '10px' }} alt="" /> */}
                <h6 className="h2 text-white d-inline-block mb-0">Pencairan Komisi</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem aria-current="page" className="active">
                    Tabel Pencairan Komisi
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6 mb-6" fluid>
        <div className="header-body">
          <Row className="align-items-center">
            <Col md="6" xl="4">
              <CardToday
                name="Saldo Komisi Tersedia"
                icon="ni ni-money-coins"
                value={
                  'Rp. ' + (dataAffiliate.balance ? new Intl.NumberFormat('id-ID').format(dataAffiliate.balance) : 0)
                }
                gradient="green"
              />
            </Col>
          </Row>
        </div>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Row>
                  <Col md="6">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Nama Bank</td>
                          <td>:</td>
                          <td>
                            <strong>{dataAffiliate.bank_name}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Nomor Rekening</td>
                          <td>:</td>
                          <td>
                            <strong>{dataAffiliate.account_number}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Nama Pemilik Rekening</td>
                          <td>:</td>
                          <td>
                            <strong>{dataAffiliate.account_name}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={{ size: 4, offset: 2 }}>
                    <div style={{ float: 'left' }}>
                      <Button size="sm" color="info" onClick={() => toggleModal('accountModalOpen')}>
                        Update Akun Bank
                      </Button>
                    </div>
                  </Col>
                </Row> */}
              </CardBody>
            </Card>

            <Card>
              {/* {downloadButtonShow && (
                  <CardHeader
                    className="border-0"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <h3 className="mb-0">Laporan Deposit {customer.name}</h3>
                    <Button size="sm" color="success" onClick={handleDownload}>
                      Download
                    </Button>
                  </CardHeader>
                )} */}
              <Row>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="searchText">Filter</label>
                        <Input
                          id="searchText"
                          type="text"
                          placeholder="Masukkan ID Payout"
                          name="searchText"
                          value={searchText}
                          onChange={e => {
                            setSearchText(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <br />
                  <ToolkitProvider
                    data={data}
                    keyField="id"
                    columns={[
                      {
                        dataField: 'no',
                        text: 'Nomor',
                        sort: false
                      },
                      {
                        dataField: 'id',
                        text: 'ID Payout',
                        sort: true
                      },
                      {
                        dataField: 'description',
                        text: 'Deskripsi',
                        sort: true
                      },
                      {
                        dataField: 'total',
                        text: 'Jumlah',
                        sort: true,
                        formatter: cell => {
                          return cell.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0
                          });
                        }
                      },
                      {
                        dataField: 'status',
                        text: 'Status Transfer',
                        sort: true,
                        formatter: cell => {
                          return cell === 'paid' ? '✅' : '❌';
                        }
                      },
                      {
                        dataField: 'action',
                        text: 'Aksi',
                        sort: false,
                        isDummyField: true,
                        formatter: (cell, row, rowIndex, formatExtraData) => {
                          return (
                            <>
                              {row.status === 'paid' && row.payment_evidence && (
                                <>
                                  <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    onClick={() => {
                                      window.open(row ? row.payment_evidence : '', '_blank');
                                    }}
                                  >
                                    Bukti Transfer
                                  </Button>
                                  <br />
                                  <br />
                                </>
                              )}
                              {row.tax_evidence !== null && (
                                <>
                                  <Button
                                    color="info"
                                    type="button"
                                    size="sm"
                                    onClick={() => {
                                      window.open(row ? row.tax_evidence : '', '_blank');
                                    }}
                                  >
                                    Bukti Potong Pajak
                                  </Button>
                                  <br />
                                  <br />
                                </>
                              )}
                            </>
                          );
                        }
                      }
                    ]}
                  >
                    {props => {
                      return (
                        <div className="table-responsive">
                          <BootstrapTable
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: true
                            }}
                            loading={true}
                            {...props.baseProps}
                            bootstrap4={true}
                            pagination={paginationFactory({
                              page,
                              sizePerPage,
                              totalSize,
                              showTotal: true,
                              withFirstAndLast: true,
                              sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                <div className="dataTables_length" id="datatable-basic_length">
                                  <label>
                                    Show{' '}
                                    {
                                      <select
                                        name="datatable-basic_length"
                                        aria-controls="datatable-basic"
                                        className="form-control form-control-sm"
                                        onChange={e => onSizePerPageChange(e.target.value)}
                                      >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                      </select>
                                    }{' '}
                                    entries.
                                  </label>
                                </div>
                              )
                            })}
                            bordered={false}
                            onTableChange={onTableChange}
                          />
                        </div>
                      );
                    }}
                  </ToolkitProvider>
                </CardBody>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
      <UpdateAccountModal isOpen={modal.accountModalOpen} toggle={toggleModal} getDataAffiliate={getDataAffiliate} />
    </>
  );
};
export default Payout;
