export const isLinkValid = link => {
  const linkArr = link.split('');

  if (linkArr[linkArr.length - 1] !== '/') {
    linkArr.push('/');
  }

  const newLink = linkArr.join('');

  return newLink;
};
