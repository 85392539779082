import React from 'react';
import { Button, Card, CardHeader, CardBody, Form, FormGroup, Label, Input, Container, Row, Col } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Swal from 'sweetalert2';
import RoleService from '../../../services/RoleService';
import RolePermissionService from '../../../services/RolePermissionService';

class EditPermission extends React.Component {
  state = {
    role: {
      id: null,
      name: ''
    },
    permissions: []
  };

  getData = () => {
    const { id } = this.props.match.params;

    RoleService.findById(id).then(({ data }) => {
      this.setState({ role: data });
    });

    RolePermissionService.findByRoleId(id)
      .then(({ data }) => {
        this.setState({
          role: data.role,
          permissions: data.permissions
        });
      })
      .catch(error => {
        Swal.fire({
          title: 'Terjadi kesalahan',
          icon: 'error',
          text: error.message
        });
      });
  };

  handleChange = e => {
    let permissions = this.state.permissions;
    permissions.forEach(permission => {
      if (permission.id === parseInt(e.target.value)) permission.is_active = e.target.checked;
    });

    this.setState({ permissions });
  };

  handleAllChecked = e => {
    let permissions = this.state.permissions;
    permissions.forEach(permission => (permission.is_active = e.target.checked));
    this.setState({ permissions });
  };

  onSubmit = e => {
    e.preventDefault();

    const { role, permissions } = this.state;

    const data = {
      role_id: role.id,
      permissions
    };

    RolePermissionService.update(role.id, data).then(({ data }) => {
      Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        onClose: e => this.props.history.push('/roles')
      });
    });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { permissions, role } = this.state;

    return (
      <>
        <SimpleHeader name="Manage Permissions" parentName="Role" parentLink="/roles" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Permission untuk {role.name}</h3>
                  </CardHeader>
                  <CardBody>
                    <Container>
                      <Form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                        <Input type="checkbox" onClick={this.handleAllChecked} value="checkedall" /> Check / Uncheck All
                        <Row>
                          {permissions.map(permission => (
                            <Col sm="6" style={{ marginTop: '5px', marginBottom: '5px' }} key={permission.id}>
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    id={permission.id}
                                    checked={permission.is_active}
                                    name="permissions"
                                    type="checkbox"
                                    value={permission.id}
                                    onChange={this.handleChange}
                                  />{' '}
                                  {permission.description} - {permission.action}
                                </Label>
                              </FormGroup>
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          <Button size="md" color="primary" type="submit">
                            Update
                          </Button>
                        </Row>
                      </Form>
                    </Container>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditPermission;
