import classnames from 'classnames';
import { PropTypes } from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, NavLink as NavLinkRRD } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand } from 'reactstrap';
import ability from '../../config/ability';

import { connect } from 'react-redux';
import { request } from '../../config';
import { EXCELLENT_TEAM, FLOW_VERSION } from '../../constants';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      ...this.getCollapseStates(props.routes),
      reviewCategoryLists: []
    };
  }

  componentDidMount() {
    // this.getListReviewCategory();
  }

  getListReviewCategory = async () => {
    try {
      const response = await request.get('/categories/list');
      if (response.status === 200) {
        this.setState({
          reviewCategoryLists: response.data.data
        });
      }
    } catch (error) {
      // console.error(error.message);
    }
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  };
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };

  render() {
    const { logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank'
      };
    }

    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          {logo ? (
            <NavbarBrand {...navbarBrandProps}>
              <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
            </NavbarBrand>
          ) : null}
          <div className="ml-auto">
            <div
              className={classnames('sidenav-toggler d-none d-xl-block', {
                active: this.props.sidenavOpen
              })}
              onClick={this.props.toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen={true}>
            <Nav navbar>
              {/* {this.createLinks(routes)} */}
              {ability.can('read', 'Menu Dashboard') && (
                <NavItem className={this.activeRoute('/dashboard')}>
                  <NavLink to={'/dashboard'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="ni ni-shop text-primary" />
                    <span className="nav-link-text">Dashboard</span>
                  </NavLink>
                </NavItem>
              )}
              {/* {ability.can('read', 'Scoreboard') && (
                <NavItem className={this.activeRoute('/scoreboard')}>
                  <NavLink to={'/scoreboard'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-chart-simple text-primary" />
                    <span className="nav-link-text">Scoreboard</span>
                  </NavLink>
                </NavItem>
              )} */}

              {ability.can('read', 'Scoreboard') && (
                <NavItem className={this.activeRoute('/scoreboard')}>
                  <NavLink to={'/scoreboard'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-chart-simple text-primary" />
                    <span className="nav-link-text">Scoreboard</span>
                  </NavLink>
                </NavItem>
              )}

              {/* AFFILIATE */}
              {/* {ability.can('read', 'Affiliate') && (
                <NavItem className={this.activeRoute('/affiliate')}>
                  <NavLink to={'/affiliate'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fa fa-list text-primary" />
                    <span className="nav-link-text">Affiliate</span>
                  </NavLink>
                </NavItem>
              )} */}

              {ability.can('read', 'Menu Report') && (
                <NavItem>
                  <NavLink
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={this.state['ReportCollapse']}
                    className={classnames({
                      active: false
                    })}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        ReportCollapse: !this.state.ReportCollapse
                      });
                    }}
                  >
                    <i className="fas fa-chart-line text-primary" />
                    <span className="nav-link-text">Laporan</span>
                  </NavLink>
                  <Collapse isOpen={this.state['ReportCollapse']}>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'Report Finance') && (
                        <NavItem className={this.activeRoute('/reports/finance')}>
                          <NavLink
                            to="/reports/finance"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-money-bill text-primary" />
                            <span className="nav-link-text">Keuangan</span>
                          </NavLink>
                        </NavItem>
                      )}

                      {ability.can('read', 'Report Platform') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/platform')}>
                            <NavLink
                              to="/reports/platform"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-list text-primary" />
                              <span className="nav-link-text">Platform</span>
                            </NavLink>
                          </NavItem>
                        )}

                      {ability.can('read', 'Report Brand') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/brand')}>
                            <NavLink
                              to="/reports/brand"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-car text-primary" />
                              <span className="nav-link-text">Merek Mobil</span>
                            </NavLink>
                          </NavItem>
                        )}

                      {ability.can('read', 'Report Category') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/cover')}>
                            <NavLink
                              to="/reports/cover"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-list text-primary" />
                              <span className="nav-link-text">Produk</span>
                            </NavLink>
                          </NavItem>
                        )}

                      {ability.can('read', 'Report Product') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/product')}>
                            <NavLink
                              to="/reports/product"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-car text-primary" />
                              <span className="nav-link-text">Merek / Tipe</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Report Color') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/color')}>
                            <NavLink
                              to="/reports/color"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-palette text-primary" />
                              <span className="nav-link-text">Warna</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Report City') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/cities')}>
                            <NavLink
                              to="/reports/cities"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-city text-primary" />
                              <span className="nav-link-text">Kota Customer</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Report Order Source') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/order-source')}>
                            <NavLink
                              to="/reports/order-source"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-arrow-down text-primary" />
                              <span className="nav-link-text">Sumber Order</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Report Dealmaker') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/dealmaker')}>
                            <NavLink
                              to="/reports/dealmaker"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-handshake text-primary" />
                              <span className="nav-link-text">Dealmaker</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Kpi Omzet') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/kpi-omzet')}>
                            <NavLink
                              to="/reports/kpi-omzet"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-money-bill text-primary" />
                              <span className="nav-link-text">Kpi Omzet</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'History Order') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/history-order')}>
                            <NavLink
                              to="/reports/history-order"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-bars-progress text-primary" />
                              <span className="nav-link-text">Riwayat Pesanan</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Report Budget Daily') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/budget-daily')}>
                            <NavLink
                              to="/reports/budget-daily"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-money-bill-trend-up text-primary" />
                              <span className="nav-link-text">Budget Daily</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Report Advertiser') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/advertiser')}>
                            <NavLink
                              to="/reports/advertiser"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-ad text-primary" />
                              <span className="nav-link-text">Advertiser</span>
                            </NavLink>
                          </NavItem>
                        )}
                      {ability.can('read', 'Report Budget Ratio') &&
                        this.props.user &&
                        this.props.user.unit_id !== EXCELLENT_TEAM && (
                          <NavItem className={this.activeRoute('/reports/budget-ratio')}>
                            <NavLink
                              to="/reports/budget-ratio"
                              activeClassName=""
                              onClick={this.closeSidenav}
                              tag={NavLinkRRD}
                            >
                              <i className="fas fa-money-bill-1 text-primary" />
                              <span className="nav-link-text">Budget Ratio</span>
                            </NavLink>
                          </NavItem>
                        )}
                    </Nav>
                  </Collapse>
                </NavItem>
              )}

              {ability.can('read', 'Menu Master') && (
                <NavItem>
                  <NavLink
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={this.state['MasterCollapse']}
                    className={classnames({
                      active: false
                    })}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        MasterCollapse: !this.state.MasterCollapse
                      });
                    }}
                  >
                    <i className="fas fa-database text-primary" />
                    <span className="nav-link-text">Master Data</span>
                  </NavLink>
                  <Collapse isOpen={this.state['MasterCollapse']}>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'User') && (
                        <NavItem className={this.activeRoute('/users')}>
                          <NavLink to="/users" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-user text-primary" />
                            <span className="nav-link-text">Pengguna</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Role') && (
                        <NavItem className={this.activeRoute('/roles')}>
                          <NavLink to="/roles" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-user-tag text-primary" />
                            <span className="nav-link-text">Role Permissions</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Category') && (
                        <NavItem className={this.activeRoute('/categories')}>
                          <NavLink to="/categories" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-list text-primary" />
                            <span className="nav-link-text">Produk</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Color') && (
                        <NavItem className={this.activeRoute('/colors')}>
                          <NavLink to="/colors" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-palette text-primary" />
                            <span className="nav-link-text">Warna</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Material') && (
                        <NavItem className={this.activeRoute('/materials')}>
                          <NavLink to="/materials" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-layer-group text-primary" />
                            <span className="nav-link-text">Bahan</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Bank') && (
                        <NavItem className={this.activeRoute('/banks')}>
                          <NavLink to="/banks" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-building-user text-primary" />
                            <span className="nav-link-text">Bank</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Manufacturer') && (
                        <NavItem className={this.activeRoute('/manufacturers')}>
                          <NavLink to="/manufacturers" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-industry text-primary" />
                            <span className="nav-link-text">Pabrikan</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Type Car') && (
                        <NavItem className={this.activeRoute('/typecars')}>
                          <NavLink to="/typecars" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-truck-pickup text-primary" />
                            <span className="nav-link-text">Jenis Kendaraan</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Product') && (
                        <NavItem className={this.activeRoute('/products')}>
                          <NavLink to="/products" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-car text-primary" />
                            <span className="nav-link-text">Produk - Merek</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Cover Image') && (
                        <NavItem className={this.activeRoute('/cover-images')}>
                          <NavLink to="/cover-images" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-images text-primary" />
                            <span className="nav-link-text">Upload Gambar/Warna</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Addon') && (
                        <NavItem className={this.activeRoute('/addons')}>
                          <NavLink to="/addons" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-puzzle-piece text-primary" />
                            <span className="nav-link-text">Addon</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Pricelist') && (
                        <NavItem className={this.activeRoute('/pricelists')}>
                          <NavLink to="/pricelists" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-money-bill-alt text-primary" />
                            <span className="nav-link-text">Update Pricelist</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Payment Channel') && (
                        <NavItem className={this.activeRoute('/payment-channels')}>
                          <NavLink
                            to="/payment-channels"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-credit-card text-primary" />
                            <span className="nav-link-text">Channel Pembayaran</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Courier') && (
                        <NavItem className={this.activeRoute('/couriers')}>
                          <NavLink to="/couriers" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-truck-moving text-primary" />
                            <span className="nav-link-text">Kurir</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Stp User') && (
                        <NavItem className={this.activeRoute('/stp-users')}>
                          <NavLink to="/stp-users" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-users text-primary" />
                            <span className="nav-link-text">STP User</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </Collapse>
                </NavItem>
              )}

              {ability.can('read', 'Promotional Content') && (
                <NavItem
                  className={this.activeRoute(
                    'https://drive.google.com/drive/u/1/folders/1T4S7uafZlO4rvlo3ArBXwJJR09ZKLIFY'
                  )}
                >
                  <NavLink
                    to=""
                    onClick={() =>
                      window.open('https://drive.google.com/drive/u/1/folders/1T4S7uafZlO4rvlo3ArBXwJJR09ZKLIFY')
                    }
                    activeClassName=""
                    tag={NavLinkRRD}
                  >
                    <i className="fas fa-link text-primary" />
                    <span className="nav-link-text">Konten Promosi Partner</span>
                  </NavLink>
                </NavItem>
              )}
              {ability.can('read', 'Affiliate Charts') && (
                <NavItem className={this.activeRoute('/affiliate/tracking')}>
                  <NavLink to="/affiliate/tracking" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-chart-bar text-primary" />
                    <span className="nav-link-text">Tracking Partner</span>
                  </NavLink>
                </NavItem>
              )}
              {ability.can('read', 'Affiliate Sales') && this.props.user && this.props.user.unit_id !== EXCELLENT_TEAM && (
                <NavItem className={this.activeRoute('/affiliate/selling')}>
                  <NavLink to="/affiliate/selling" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-shopping-cart text-primary" />
                    <span className="nav-link-text">Penjualan Partner</span>
                  </NavLink>
                </NavItem>
              )}
              {ability.can('read', 'Affiliate Voucher') && (
                <NavItem className={this.activeRoute('/affiliate/vouchers')}>
                  <NavLink to="/affiliate/vouchers" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-percent text-primary" />
                    <span className="nav-link-text">Voucher</span>
                  </NavLink>
                </NavItem>
              )}
              {ability.can('read', 'Affiliate Payout') &&
                this.props.user &&
                this.props.user.unit_id !== EXCELLENT_TEAM && (
                  <NavItem className={this.activeRoute('/affiliate/payout')}>
                    <NavLink to="/affiliate/payout" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                      <i className="ni ni-money-coins text-primary" />
                      <span className="nav-link-text">Pencairan Komisi</span>
                    </NavLink>
                  </NavItem>
                )}

              {ability.can('read', 'Menu Web Management') && (
                <NavItem>
                  <NavLink
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={this.state['WebSetting']}
                    className={classnames({
                      active: false
                    })}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        WebSetting: !this.state.WebSetting
                      });
                    }}
                  >
                    <i className="fas fa-globe text-primary" />
                    <span className="nav-link-text">Pengaturan Web</span>
                  </NavLink>
                  <Collapse isOpen={this.state['WebSetting']}>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'Menu Web Management') && (
                        <NavItem className={this.activeRoute('/websetting')}>
                          <NavLink to="/websetting" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-globe text-primary" />
                            <span className="nav-link-text">Website</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Voucher') && (
                        <NavItem className={this.activeRoute('/vouchers')}>
                          <NavLink to="/vouchers" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-sticky-note text-primary" />
                            <span className="nav-link-text">Voucher</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Slide') && (
                        <NavItem className={this.activeRoute('/banner-slides')}>
                          <NavLink to="/banner-slides" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-image text-primary" />
                            <span className="nav-link-text">Banner Slides</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'E-Magazine') && (
                        <NavItem className={this.activeRoute('/emagazine')}>
                          <NavLink to="/emagazine" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-newspaper text-primary" />
                            <span className="nav-link-text">E-Magazine</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Review') && (
                        <NavItem className={this.activeRoute('/reviews')}>
                          <NavLink to={'/reviews'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fa fa-star text-primary" />
                            <span className="nav-link-text">Ulasan Pelanggan</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Page Management') && (
                        <NavItem className={this.activeRoute('/pages-management')}>
                          <NavLink
                            to={'/pages-management'}
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fa fa-list-ul text-primary" />
                            <span className="nav-link-text">Manajemen Halaman</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Media Partner') && (
                        <NavItem className={this.activeRoute('/media-partners')}>
                          <NavLink
                            to={'/media-partners'}
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fa fa-handshake text-primary" />
                            <span className="nav-link-text">Media Partner</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Testimonial') && (
                        <NavItem className={this.activeRoute('/testimonials')}>
                          <NavLink to={'/testimonials'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fa fa-file-archive text-primary" />
                            <span className="nav-link-text">Testimoni</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Tips') && (
                        <NavItem className={this.activeRoute('/tips')}>
                          <NavLink to={'/tips'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fa fa-tag text-primary" />
                            <span className="nav-link-text">Tips</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Faq Category') && (
                        <NavItem className={this.activeRoute('/faq-categories')}>
                          <NavLink
                            to={'/faq-categories'}
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fa fa-question text-primary" />
                            <span className="nav-link-text">FAQ</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </Collapse>
                </NavItem>
              )}

              {ability.can('read', 'Order') && (
                <NavItem className={this.activeRoute('/orders')}>
                  <NavLink to={'/orders'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-tasks text-primary" />
                    <span className="nav-link-text">Order</span>
                  </NavLink>
                </NavItem>
              )}

              {ability.can('manage', 'Shipping') && (
                <NavItem className={this.activeRoute('/shipping-managements')}>
                  <NavLink to={'/shipping-managements'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fa-solid fa-truck-fast text-primary" />
                    <span className="nav-link-text">Shipping Management</span>
                  </NavLink>
                </NavItem>
              )}

              {ability.can('check', 'Shipping Cost') && (
                <NavItem className={this.activeRoute('/check-shipping')}>
                  <NavLink to={'/check-shipping'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fa-solid fa-dolly text-primary" />
                    <span className="nav-link-text">Cek Ongkir</span>
                  </NavLink>
                </NavItem>
              )}

              {ability.can('create', 'Order') && (
                <NavItem className={this.activeRoute('/orders/add')}>
                  <NavLink to={'/orders/add'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-file-pen text-primary" />
                    <span className="nav-link-text">Form Order</span>
                  </NavLink>
                </NavItem>
              )}

              {ability.can('read', 'Customer') && (
                <NavItem className={this.activeRoute('/customers')}>
                  <NavLink to={'/customers'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-address-card text-primary" />
                    <span className="nav-link-text">Customer</span>
                  </NavLink>
                </NavItem>
              )}

              {ability.can('read', 'Menu Affiliate') && this.props.user && this.props.user.unit_id !== EXCELLENT_TEAM && (
                <NavItem>
                  <NavLink
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={this.state['AffiliateCollapse']}
                    className={classnames({
                      active: false
                    })}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        AffiliateCollapse: !this.state.AffiliateCollapse
                      });
                    }}
                  >
                    <i className="fa fa-handshake-angle text-primary" />
                    <span className="nav-link-text">Partner</span>
                  </NavLink>
                  <Collapse isOpen={this.state['AffiliateCollapse']}>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'Affiliasi Overview') && (
                        <NavItem className={this.activeRoute('/affiliasi/overview')}>
                          <NavLink
                            to="/affiliasi/overview"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-home text-primary" />
                            <span className="nav-link-text">Dashboard Partner</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Report Affiliator') && (
                        <NavItem className={this.activeRoute('/report/affiliasi')}>
                          <NavLink
                            to="/report/affiliasi"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-book text-primary" />
                            <span className="nav-link-text">Laporan Partner</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('update', 'Commission') && (
                        <NavItem className={this.activeRoute('/affiliate/update-product')}>
                          <NavLink
                            to="/affiliate/update-product"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-images text-primary" />
                            <span className="nav-link-text">Produk</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Affiliate') && (
                        <NavItem className={this.activeRoute('/data-affiliate')}>
                          <NavLink to="/data-affiliate" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-user text-primary" />
                            <span className="nav-link-text">User</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </Collapse>
                </NavItem>
              )}

              {ability.can('read', 'Menu Finance') && this.props.user && this.props.user.unit_id !== EXCELLENT_TEAM && (
                <NavItem>
                  <NavLink
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={this.state['FinanceCollapse']}
                    className={classnames({
                      active: false
                    })}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        FinanceCollapse: !this.state.FinanceCollapse
                      });
                    }}
                  >
                    <i className="fas fa-money-bill-wave-alt text-primary" />
                    <span className="nav-link-text">Finance</span>
                  </NavLink>
                  <Collapse isOpen={this.state['FinanceCollapse']}>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'Deposit') && (
                        <NavItem className={this.activeRoute('/finance/deposit')}>
                          <NavLink
                            to="/finance/deposit"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-money-bill-alt text-primary" />
                            <span className="nav-link-text">Deposit</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('read', 'Billing') && (
                        <NavItem className={this.activeRoute('/finance/billing')}>
                          <NavLink
                            to="/finance/billing"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-money-bill-alt text-primary" />
                            <span className="nav-link-text">Tagihan</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('manage', 'Tax Invoice') && (
                        <NavItem className={this.activeRoute('/finance/tax-invoice')}>
                          <NavLink
                            to="/finance/tax-invoice"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-money-bill-alt text-primary" />
                            <span className="nav-link-text">Faktur Pajak</span>
                          </NavLink>
                        </NavItem>
                      )}
                      {ability.can('manage', 'Finance Affiliate Payout') && (
                        <NavItem className={this.activeRoute('/finance/payout')}>
                          <NavLink to="/finance/payout" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-money-bill-alt text-primary" />
                            <span className="nav-link-text">Affiliate Payout</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </Collapse>
                </NavItem>
              )}
              {ability.can('read', 'Manajemen Leads') && (
                <NavItem>
                  <NavLink
                    href="#"
                    data-toggle="collapse"
                    aria-expanded={this.state['LeadsCollapse']}
                    className={classnames({
                      active: false
                    })}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        LeadsCollapse: !this.state.LeadsCollapse
                      });
                    }}
                  >
                    <i className="fas fa-ad text-primary" />
                    <span className="nav-link-text">Manajemen Leads</span>
                  </NavLink>
                  <Collapse isOpen={this.state['LeadsCollapse']}>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'Manajemen Leads') && (
                        <NavItem className={this.activeRoute('/manajemen/leads/dashboard')}>
                          <NavLink
                            to="/manajemen/leads/dashboard"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-chart-line text-primary" />
                            <span className="nav-link-text">Dashboard Leads</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'Manajemen Leads') && (
                        <NavItem className={this.activeRoute('/manajemen/leads')}>
                          <NavLink
                            to="/manajemen/leads"
                            activeClassName=""
                            onClick={this.closeSidenav}
                            tag={NavLinkRRD}
                          >
                            <i className="fas fa-tasks text-primary" />
                            <span className="nav-link-text">Table Leads</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <Nav className="nav-sm flex-column">
                      {ability.can('read', 'Leads Reason') && (
                        <NavItem className={this.activeRoute('/leads/reason')}>
                          <NavLink to="/leads/reason" activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                            <i className="fas fa-list text-primary" />
                            <span className="nav-link-text">Alasan Belum Beli</span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </Collapse>
                </NavItem>
              )}

              {ability.can('read', 'Complaint Category') && (
                <NavItem className={this.activeRoute('/complaint-category')}>
                  <NavLink to={'/complaint-category'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-users text-primary" />
                    <span className="nav-link-text">Kategori Komplain</span>
                  </NavLink>
                </NavItem>
              )}
              {ability.can('read', 'Menu Production History') && (
                <NavItem className={this.activeRoute('/production-history')}>
                  <NavLink to={'/production-history'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-tasks text-primary" />
                    <span className="nav-link-text">Riwayat Produksi</span>
                  </NavLink>
                </NavItem>
              )}
              {ability.can('scan', 'Order') && (
                <NavItem className={this.activeRoute('/scan')}>
                  <NavLink to={'/scan'} activeClassName="" onClick={this.closeSidenav} tag={NavLinkRRD}>
                    <i className="fas fa-camera text-primary" />
                    <span className="nav-link-text">Status Produksi</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </div>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 24 }}>
          Versi {FLOW_VERSION}
        </span>
      </div>
    );

    return (
      <Navbar
        className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf('Win') > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired
  })
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, {})(Sidebar);
