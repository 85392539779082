import Dropzone from 'dropzone';
import React from 'react';
import Swal from 'react-bootstrap-sweetalert';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { API_URL } from '../../../config';
import CategoryService from '../../../services/CategoryService';
import ColorService from '../../../services/ColorService';
import CustomerTypeService from '../../../services/CustomerTypeService';
import ManufacturerService from '../../../services/ManufacturerService';
import MaterialService from '../../../services/MaterialService';
import ProductService from '../../../services/ProductService';
import TypeCarService from '../../../services/TypeCarService';

import {
  CATEGORY_COVER_MOBIL_COVERSUPER_RS,
  CATEGORY_COVER_MOBIL_SUPERIOR_RS,
  CATEGORY_COVER_MOTOR,
  CATEGORY_COVER_SEPEDA,
  CUSTOMER_BIG_SELLER,
  CUSTOMER_RESELLER,
  CUSTOMER_RETAIL
} from '../../../constants';

const UploadURL = API_URL + '/api/v1/upload/product/image';
Dropzone.autoDiscover = false;

class AddProduct extends React.Component {
  state = {
    alert: null,
    manufacturerOptions: [],
    typeCarOptions: [],
    categoryOptions: [],
    customerTypeOptions: [],
    colorOptions: [],
    materialOptions: [],
    sizeOptions: [],
    name: '',
    category_id: '',
    manufacturer_id: '',
    supplier_id: '',
    type_car_id: '',
    description: '',
    images: [],
    variant: {
      material_id: '',
      discount: 0,
      size: '',
      prices: [
        { customer_type_id: 1, price: '' },
        { customer_type_id: 2, price: '' },
        { customer_type_id: 3, price: '' }
      ],
      hpp: '',
      weight: ''
    }
  };

  hideAlert = (isRedirect = true) => {
    this.setState({ alert: null }, () => {
      if (isRedirect) {
        this.props.history.push('/products');
      }
    });
  };

  errorAlert = (message, isRedirect = true) => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Terjadi Kesalahan"
          onConfirm={() => this.hideAlert(isRedirect)}
          onCancel={() => this.hideAlert(isRedirect)}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </Swal>
      )
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil ditambahkan
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  async getManufacturers() {
    let manufacturerOptions = await ManufacturerService.list();
    this.setState({ manufacturerOptions: manufacturerOptions.data });
  }

  async getTypeCars(categoryId) {
    let typeCarOptions = await TypeCarService.list(categoryId);
    this.setState({ typeCarOptions: typeCarOptions.data });
  }

  async getCategories() {
    let categoryOptions = await CategoryService.needVehicle();
    this.setState({ categoryOptions: categoryOptions.data });
  }

  async getCustomerTypes() {
    let customerTypeOptions = await CustomerTypeService.list();
    this.setState({ customerTypeOptions: customerTypeOptions.data });
  }

  async getMaterials() {
    let materialOptions = await MaterialService.list();
    this.setState({ materialOptions: materialOptions.data });
  }

  async getColors() {
    let colorOptions = await ColorService.list();
    this.setState({ colorOptions: colorOptions.data });
  }

  async getSize() {
    let sizeOptions = await ProductService.sizeProduct();
    this.setState({ sizeOptions: sizeOptions.data });
  }

  async getDefaultPriceAndWeight() {
    const { customerTypeOptions, category_id, variant } = this.state;
    if (customerTypeOptions.length > 0 && category_id && variant && variant.size) {
      try {
        const { data: defaultPrice } = await ProductService.getDefaultPrice({ category_id, size: variant.size });
        if (defaultPrice) {
          let prices = [];
          prices.push({ customer_type_id: CUSTOMER_RETAIL, price: defaultPrice.retail });
          prices.push({ customer_type_id: CUSTOMER_RESELLER, price: defaultPrice.reseller });
          prices.push({ customer_type_id: CUSTOMER_BIG_SELLER, price: defaultPrice.big_seller });

          const { hpp, weight } = defaultPrice;

          let variantData = { ...variant, hpp, weight, prices };

          this.setState({ variant: variantData });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  initDropzone = () => {
    // this variable is to delete the previous image from the dropzone state
    // it is just to make the HTML DOM a bit better, and keep it light
    let currentMultipleFile = [];
    // multiple dropzone file - accepts any type of file
    new Dropzone(document.getElementById('dropzone-multiple'), {
      url: UploadURL,
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName('dz-preview-multiple')[0],
      previewTemplate: document.getElementsByClassName('dz-preview-multiple')[0].innerHTML,
      maxFiles: 10,
      acceptedFiles: 'image/*',
      init: function() {
        this.on('addedfile', function(file) {
          currentMultipleFile.push(file);
        });
        this.on('removedfile', file => {
          console.log(file);
        });
      },
      success: (file, response) => {
        this.setState(prevState => {
          let images = prevState.images;
          let url = response.data[0];
          images.push({ url });
          return { images };
        });
      }
    });
    document.getElementsByClassName('dz-preview-multiple')[0].innerHTML = '';
  };

  async componentDidMount() {
    await this.getManufacturers();
    await this.getCategories();
    await this.getCustomerTypes();
    await this.getMaterials();
    await this.getColors();
    await this.getSize();
    // init dropzone
    // this.initDropzone();

    // default variants
    this.setState({ variant: { ...this.state.variant, price: [] } });
  }

  handleChange = e => {
    e.persist();

    this.setState(
      {
        [e.target.name]: e.target.value
      },
      async () => {
        console.log('name', e.target.name);
        if (e.target.name === 'category_id') {
          this.setState({ type_car_id: '' });
          this.getTypeCars(e.target.value);
          await this.getDefaultPriceAndWeight();
        }
      }
    );
  };

  handleChangeVariant = e => {
    e.persist();

    this.setState(
      prevState => {
        let variant = prevState.variant;
        variant[e.target.name] = e.target.value;

        return { variant };
      },
      async () => {
        if (e.target.name === 'size') {
          await this.getDefaultPriceAndWeight();
        }
      }
    );
  };

  handleChangeVariantPrice = (price_key, customer_type_id, price) => {
    this.setState(prevState => {
      let variant = prevState.variant;
      variant.prices[price_key].customer_type_id = customer_type_id;
      variant.prices[price_key].price = price;

      return { variant };
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    const data = {
      name: this.state.name,
      category_id: this.state.category_id,
      manufacturer_id: this.state.manufacturer_id,
      type_car_id: this.state.type_car_id,
      description: this.state.description,
      // images: this.state.images,
      variant: this.state.variant
    };

    try {
      await ProductService.save(data);
      this.successAlert();
    } catch (error) {
      if (error.response && error.response.data) {
        this.errorAlert(error.response.data.message, false);
      } else {
        this.errorAlert(error.message, true);
      }
    }
  };

  render() {
    const isReadyStock = [
      CATEGORY_COVER_MOBIL_SUPERIOR_RS,
      CATEGORY_COVER_MOBIL_COVERSUPER_RS,
      CATEGORY_COVER_MOTOR,
      CATEGORY_COVER_SEPEDA
    ].includes(parseInt(this.state.category_id));

    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Tambah Merek / Tipe" parentName="Produk - Merek" parentLink="/products" />
        <Container className="mt--6" fluid>
          <div className="card-wrapper">
            <Card>
              <Form className="needs-validation" onSubmit={this.onSubmit}>
                <CardHeader>
                  <h3 className="mb-0">Merek / Tipe</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationCategory">
                          Produk
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          defaultValue=""
                          id="validationCategory"
                          type="select"
                          name="category_id"
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih Produk--</option>
                          {this.state.categoryOptions.map((category, key) => (
                            <option value={category.id} key={key}>
                              {category.name}
                            </option>
                          ))}
                        </Input>

                        <div className="invalid-feedback">Produk tidak boleh kosong!</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationManufacturer">
                          Pabrikan
                          {isReadyStock ? null : (
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          )}
                        </label>
                        <Input
                          required={!isReadyStock}
                          defaultValue=""
                          id="validationManufacturer"
                          type="select"
                          name="manufacturer_id"
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih Pabrikan--</option>
                          {this.state.manufacturerOptions.map((manufacturer, key) => (
                            <option value={manufacturer.id} key={key}>
                              {manufacturer.name}
                            </option>
                          ))}
                        </Input>

                        <div className="invalid-feedback">Pabrikan tidak boleh kosong!</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationName">
                          Merek/Tipe
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          defaultValue=""
                          id="validationName"
                          placeholder="ALPHARD / VARIO / SIZE XXL / SPORTY / POLOS / LAINNYA"
                          type="text"
                          name="name"
                          onChange={this.handleChange}
                        />
                        <div className="invalid-feedback">Merek tidak boleh kosong!</div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="validationTypeCar">
                          Jenis
                          {isReadyStock ? null : (
                            <sup>
                              <span className="text-red">*</span>
                            </sup>
                          )}
                        </label>
                        <Input
                          required={!isReadyStock}
                          defaultValue=""
                          id="validationTypeCar"
                          type="select"
                          name="type_car_id"
                          placeholder="--Jenis Kendaraan--"
                          onChange={this.handleChange}
                        >
                          <option value="">--Pilih Jenis Kendaraan--</option>
                          {this.state.typeCarOptions.map((typeCar, key) => (
                            <option value={typeCar.id} key={key}>
                              {typeCar.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* Variants */}
                </CardBody>
                {/* <CardHeader>
                  <h3 className="mb-0">
                    Variasi{' '}
                    <Button size="sm" color="default" onClick={this.setDefaultVariant}>
                      <span className="fas fa-plus"></span> Tambah
                    </Button>
                  </h3>
                </CardHeader> */}
                <CardBody>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor={'validationMaterial'}>
                          Bahan
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          value={this.state.variant.material_id}
                          id={'validationMaterial'}
                          type="select"
                          name="material_id"
                          onChange={this.handleChangeVariant}
                        >
                          <option value="">--Pilih Bahan--</option>
                          {this.state.materialOptions.map((material, key) => (
                            <option value={material.id} key={key}>
                              {material.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor={'validationColor'}>
                          Ukuran
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          value={this.state.variant.size}
                          id={'validationColor'}
                          type="select"
                          name="size"
                          onChange={this.handleChangeVariant}
                        >
                          <option value="">--Pilih Ukuran--</option>
                          {this.state.sizeOptions.map((size, key) => (
                            <option value={size.name} key={key}>
                              {size.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="inputWeight">
                          Berat (gr)
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          value={this.state.variant.weight}
                          id="inputWeight"
                          placeholder="1000"
                          type="text"
                          name="weight"
                          onChange={this.handleChangeVariant}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="inputHpp">
                          Hpp
                          <sup>
                            <span className="text-red">*</span>
                          </sup>
                        </label>
                        <Input
                          required
                          value={this.state.variant.hpp}
                          id="inputHpp"
                          placeholder="50000"
                          type="text"
                          name="hpp"
                          onChange={this.handleChangeVariant}
                        />
                      </FormGroup>
                    </Col>
                    {/* Harga Varian */}
                    {this.state.customerTypeOptions.map((customer_type, price_key) => {
                      return (
                        <Col md="3" key={price_key}>
                          <FormGroup>
                            <label className="form-control-label" htmlFor={'example2cols2Input' + price_key}>
                              Harga {customer_type.name}
                              <sup>
                                <span className="text-red">*</span>
                              </sup>
                            </label>
                            <Input
                              required
                              value={this.state.variant.prices[price_key].price}
                              id={'example2cols2Input' + price_key}
                              placeholder="50000"
                              type="text"
                              name="price"
                              onChange={e => this.handleChangeVariantPrice(price_key, customer_type.id, e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col>
                      <sup>
                        <span className="text-red">*) Wajib diisi</span>
                      </sup>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody>
                  <Button color="primary" type="submit">
                    Simpan
                  </Button>
                </CardBody>
              </Form>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

export default AddProduct;
