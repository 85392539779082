const config = {
  excellent: {
    backgroundColor: '#d23d3d',
    lineColor: '#d23d3d',
    textColor: '#fff'
  },
  great: {
    backgroundColor: '#fc8384',
    lineColor: '#fc8384',
    textColor: '#000'
  },
  stp: {
    backgroundColor: '#6596df',
    lineColor: '#6596df',
    textColor: '#fff'
  },
  carmat: {
    backgroundColor: '#20409a',
    lineColor: '#20409a',
    textColor: '#efad22'
  },
  seatcover: {
    backgroundColor: '#120202',
    lineColor: '#120202',
    textColor: '#fff'
  },
  force: {
    backgroundColor: '#ef64f2',
    lineColor: '#ef64f2',
    textColor: '#000'
  }
};
export { config };
