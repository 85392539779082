import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// router
import { Link } from "react-router-dom";
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap";

class SimpleHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="12" xs="12">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {this.props.name}
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <Link to="/">
                        <i className="fas fa-home" />
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={this.props.parentLink}>
                        {this.props.parentName}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {this.props.name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

SimpleHeader.defaultProps = {
  parentLink: "#"
};

SimpleHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
  parentLink: PropTypes.string
};

export default SimpleHeader;
