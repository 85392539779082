import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table
} from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from '../../../components/Loader/Loader';
import ReportService from '../../../services/ReportService';

// import Can from '../../../config/Can';
import './AdvertiserReport.scss';

import moment from 'moment';
import 'moment/locale/id';
import CurrencyInput from '../../../helpers/CurrencyInput';
import OrderService from '../../../services/OrderService';
moment.locale('id');

function BudgetDailyReport() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unitOptions, setUnitOptions] = useState([]);
  const [picOptions, setPicOptions] = useState([]);
  const [unitId, setUnitId] = useState(undefined);
  const [pic, setPic] = useState(undefined);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [period, setPeriod] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const [detailBudgetDaily, setDetailBudgetDaily] = useState({
    user: { name: '' },
    id: null,
    user_id: null,
    budget_google: 0,
    budget_messenger: 0,
    budget_tiktok: 0,
    budget_youtube: 0,
    budget_dm: 0,
    budget_fb: 0,
    additional_budget: 0,
    // leads_google: 0,
    // leads_ig: 0,
    // leads_fb: 0,
    // additional_leads: 0,
    additional_deals: 0,
    additional_omzet: 0
  });

  const [data, setData] = useState(null);

  useEffect(() => {
    getUnitOptions();
    getPeriodOptions();
  }, []);

  const getUnitOptions = () => {
    setLoading(true);
    OrderService.getUnitOptions()
      .then(({ data }) => setUnitOptions(data))
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeUnit = e => {
    const unit_id = e.target.value;
    setUnitId(unit_id);
    if (unit_id) {
      getPicOptions(unit_id);
    } else {
      setPicOptions([]);
      setPic('');
    }
  };

  const getPeriodOptions = async () => {
    try {
      const res = await ReportService.getPeriodOptions();
      if (res.status === 200) {
        const responseData = res.data.data;
        setPeriodOptions(responseData);
        setPeriod(responseData[0].value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPicOptions = unitId => {
    setLoading(true);
    ReportService.getCsList({ unit_id: unitId })
      .then(({ data: res }) => {
        setPicOptions(res.data);
        setPic('');
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const getDataBudget = async (date, user_id) => {
    try {
      setLoading(true);
      const res = await ReportService.getDetailBudgetDaily(date, user_id);
      if (res.status === 200) {
        setLoading(false);
        setDetailBudgetDaily({ date, user_id, ...res.data.data });
        setIsOpen(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  };

  const handleSearch = async (pic, period) => {
    setError('');

    // if (searchValid(pic, period)) {
    setLoading(true);

    const data = {
      unit_id: unitId,
      user_id: pic,
      period
    };

    try {
      const response = await ReportService.getDailyBudgetReport(data);

      if (response.status === 200) {
        setLoading(false);
        setData(response.data.data);
        // setRecordsTotal(response.data.recordsTotal);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // }
  };

  const handleExportData = async () => {
    try {
      const data = {
        unit_id: unitId,
        user_id: pic,
        period
      };

      const res = await ReportService.exportDailyBudget(data);

      if (res.status === 200) {
        window.open(res.data.data.url, '_blank');
      }
    } catch (error) {
      setLoading(false);
      console.log();
    }
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await ReportService.updateBudget(detailBudgetDaily);

      if (response.status === 200) {
        setLoading(false);
        setIsOpen(false);
        handleSearch(pic, period);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const renderColumn = (text, column, user_id = null, total = false) => {
    if (typeof text === 'number') {
      let date = moment(data.header[column + 1], 'DD-MM-YYYY').format('YYYY-MM-DD');
      // let today = moment().format('YYYY-MM-DD');
      const clickable = column >= 2 && !total;

      let style = {};
      if (clickable) {
        style = { ...style, cursor: 'pointer' };
      }

      return (
        <td
          key={column}
          className="label-column number-column"
          style={style}
          onClick={() => {
            if (clickable) {
              getDataBudget(date, user_id);
            }
          }}
        >
          {total ? (
            <strong>
              {text.toLocaleString('id', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0
              })}
            </strong>
          ) : (
            <>
              {text.toLocaleString('id', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0
              })}
            </>
          )}
        </td>
      );
    } else if (typeof text === 'string') {
      return (
        <td
          key={column}
          className="label-column"
          style={column === 0 ? { position: 'sticky', left: 0, backgroundColor: 'white' } : {}}
        >
          {total ? <strong>{text}</strong> : text}
        </td>
      );
    }
  };

  return (
    <>
      {!!loading && <Loader />}
      <SimpleHeader name="Budget Daily" parentName="Laporan" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Cari Data Laporan Budget Daily</h3>
              </CardHeader>
              <CardBody>
                {error && (
                  <Alert color="danger">
                    <ul>
                      <li>{error}</li>
                    </ul>
                  </Alert>
                )}
                <Form
                  onSubmit={e => {
                    e.preventDefault();
                    setLoading(true);

                    handleSearch(pic, period);
                  }}
                >
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="unit_id">Unit</label>
                        <Input id="unit_id" type="select" name="unit_id" value={unitId} onChange={handleChangeUnit}>
                          <option value="">-- Semua Unit --</option>
                          {unitOptions.map((unit, key) => (
                            <option key={key} value={unit.value}>
                              {unit.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="pic">List PIC</label>
                        <Input
                          type="select"
                          id="pic"
                          value={pic}
                          onChange={e => {
                            setPic(e.target.value);
                          }}
                        >
                          <option value="">--- Semua PIC ---</option>
                          {picOptions.map((status, i) => (
                            <option key={i} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="period">Periode</label>
                        <Input
                          type="select"
                          id="period"
                          value={period}
                          onChange={e => {
                            e.persist();
                            setError('');
                            // setDate('');
                            setPeriod(e.target.value);
                          }}
                        >
                          {/* <option value="">--- Semua PIC ---</option> */}
                          {periodOptions.map((status, i) => (
                            <option key={i} value={status.value}>
                              {status.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-right">
                        <Button
                          onClick={async () => {
                            setUnitId('');
                            setPic('');
                            setPeriod(periodOptions[0].value);
                            setData(null);
                          }}
                          size="md"
                          disabled={loading}
                        >
                          Reset
                        </Button>
                        <Button size="md" disabled={loading} color="primary">
                          Cari
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {!!data && (
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3 className="mb-0">Laporan Budget Daily</h3>
                    <Button size="sm" color="success" onClick={handleExportData}>
                      Download
                    </Button>
                  </div>
                </CardHeader>

                <CardBody>
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        {data.header
                          .filter((v, i) => i > 0)
                          .map((h, i) => {
                            return (
                              <th
                                style={i === 0 ? { position: 'sticky', left: 0, backgroundColor: 'white' } : {}}
                                key={i}
                              >
                                <strong>{h}</strong>
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody>
                      {data.content.map((c, i) => {
                        let id = c[0];
                        return <tr key={i}>{c.filter((v, i) => i > 0).map((coo, j) => renderColumn(coo, j, id))}</tr>;
                      })}
                      <tr>{data.footer.filter((v, i) => i > 0).map((f, i) => renderColumn(f, i, null, true))}</tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <Modal centered toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        <ModalHeader>
          Budget {moment(detailBudgetDaily.date).format('DD/MM/YYYY')} {detailBudgetDaily.user.name}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            <span aria-hidden={true}>x</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} style={{ marginTop: 8 }}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Budget Meta Ads</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="budget_fb"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.budget_fb}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, budget_fb: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Budget Google Ads</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="budget_google"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.budget_google}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, budget_google: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Budget Tiktok Ads</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="budget_tiktok"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.budget_tiktok}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, budget_tiktok: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Budget DM Ads</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="budget_dm"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.budget_dm}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, budget_dm: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Budget Messenger Ads</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="budget_messenger"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.budget_messenger}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, budget_messenger: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Budget Youtube Ads</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="budget_youtube"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.budget_youtube}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, budget_youtube: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Budget Lainnya</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="additional_budget"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.additional_budget}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, additional_budget: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Omzet Tambahan</Label>
                  <CurrencyInput
                    required
                    type="text"
                    name="additional_omzet"
                    className="form-control-sm form-control"
                    value={detailBudgetDaily.additional_omzet}
                    onChange={async e => {
                      e.persist();
                      const value =
                        e.target.value.slice(3).replace(/\./g, '') === ''
                          ? e.target.value.slice(3).replace(/\./g, '')
                          : e.target.value.slice(3).replace(/\./g, '');

                      if (value.toString().length <= 10) {
                        setDetailBudgetDaily({ ...detailBudgetDaily, additional_omzet: value });
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Deal Tambahan</Label>
                  <Input
                    required
                    bsSize="sm"
                    type="number"
                    min="0"
                    name="additional_deals"
                    value={detailBudgetDaily.additional_deals}
                    onChange={e => {
                      setDetailBudgetDaily({ ...detailBudgetDaily, additional_deals: e.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Button disabled={loading} color="primary">
              Simpan
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default BudgetDailyReport;
