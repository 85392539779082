import React from 'react';

import {
  Modal,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import Swal from 'sweetalert2';
import { getPhoneNoZero } from '../../../../helpers/getPhoneNoZero';
import CustomerService from '../../../../services/CustomerService';

const CustomerModal = props => {
  const checkPhoneExist = async e => {
    e.persist();

    try {
      const response = await CustomerService.checkCustomerExist(`+62${e.target.value}`);

      if (response.status === 200) {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error.response);
      Swal.fire({
        icon: 'warning',
        title: 'Peringatan',
        html: `${error.response.data.message}. <strong>Apakah ingin menggunakan customer ini?</strong>`,
        showCancelButton: true,
        cancelButtonText: 'Input customer lain',
        confirmButtonText: 'Lanjutkan dengan customer ini'
      }).then(oke => {
        if (oke.value) {
          const customer = error.response.data.data.customer;

          const shippingAddress = {
            ...customer,
            phone: customer.phone ? getPhoneNoZero(customer.phone) : '',
            phone_alt: customer.phone_alt ? getPhoneNoZero(customer.phone_alt) : ''
          };

          props.setShippingAddress(shippingAddress);
        } else {
          props.resetNewCustomer();
        }
      });
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={props.customerModal}
      toggle={() => {
        if (!props.loading) {
          props.toggleModal('customerModal');
        }
      }}
    >
      <Form onSubmit={props.handleAddCustomer}>
        <div className="modal-header">
          <h5 className="modal-title" id="customerModalLabel">
            Tambah Pelanggan
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => props.toggleModal('customerModal')}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="customerName">
                  Nama
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <Input
                  required
                  id="customerName"
                  placeholder="Nama"
                  type="text"
                  name="name"
                  value={props.newCustomer.name}
                  onChange={props.newCustomerChange}
                />
                <small className="form-text text-muted">Nama maksimal 100 karakter</small>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="newCustomerAdress">
                  Alamat Pengiriman
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <Input
                  required
                  id="newCustomerAdress"
                  placeholder="Alamat"
                  type="textarea"
                  rows="2"
                  name="address"
                  value={props.newCustomer.address}
                  onChange={props.newCustomerChange}
                />
                <small className="form-text text-muted">Alamat Pengiriman maksimal 200 karakter</small>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="customerEmail">
                  Email
                </label>
                <Input
                  id="customerEmail"
                  placeholder="email@email.com"
                  type="email"
                  name="email"
                  value={props.newCustomer.email}
                  onChange={props.newCustomerChange}
                />
                <small className="form-text text-muted">Email maksimal 50 karakter</small>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="province">
                  Provinsi
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <Input
                  required
                  id="province"
                  value={props.newCustomer.province_id}
                  type="select"
                  name="province_id"
                  onChange={props.handleChangeProvinceCustomer}
                >
                  <option value="">--Pilih Provinsi--</option>
                  {props.provinceOptions.map((province, key) => (
                    <option value={province.id} key={key}>
                      {province.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="newCustomerPhone">
                  No. Telepon Utama
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>+62</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    id="newCustomerPhone"
                    placeholder="81234567890"
                    type="text"
                    name="phone"
                    onBlur={checkPhoneExist}
                    value={props.newCustomer.phone}
                    onChange={props.newCustomerChange}
                  />
                </InputGroup>
                <small className="form-text text-muted">No telepon maksimal 14 karakter</small>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="district">
                  Kota / Kabupaten
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <Input
                  required
                  id="district"
                  value={props.newCustomer.district_id}
                  type="select"
                  name="district_id"
                  onChange={props.handleChangeDistrictCustomer}
                >
                  <option value="">--Pilih Kota / Kabupaten--</option>
                  {props.districtOptions.map((district, key) => (
                    <option value={district.id} key={key}>
                      {district.type === 'Kabupaten' ? 'Kab.' : district.type} {district.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="newCustomerPhoneAlt">
                  No. Telepon Alternatif
                </label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>+62</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    id="newCustomerPhoneAlt"
                    placeholder="81234567890"
                    type="text"
                    name="phone_alt"
                    value={props.newCustomer.phone_alt}
                    onChange={props.newCustomerChange}
                  />
                </InputGroup>
                <small className="form-text text-muted">(optional) No telepon alternatif maksimal 14 karakter</small>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="subdistrict">
                  Kecamatan
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <Input
                  required
                  id="subdistrict"
                  value={props.newCustomer.subdistrict_id}
                  type="select"
                  name="subdistrict_id"
                  onChange={props.newCustomerChange}
                >
                  <option value="">--Pilih Kecamatan--</option>
                  {props.subdistrictOptions.map((subdistrict, key) => (
                    <option value={subdistrict.id} key={key}>
                      {subdistrict.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="customer_type_id">
                  Jenis Pelanggan
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <Input
                  required
                  id="customer_type_id"
                  value={props.newCustomer.customer_type_id}
                  type="select"
                  name="customer_type_id"
                  onChange={props.newCustomerChange}
                >
                  <option value="">--Pilih Jenis Pelanggan--</option>
                  {props.customerTypeOptions.map((customer_type, key) => (
                    <option value={customer_type.id} key={key}>
                      {customer_type.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="postalcode">
                  Kode Pos
                  <sup>
                    <span className="text-red">*</span>
                  </sup>
                </label>
                <Input
                  required
                  id="postalcode"
                  placeholder="12345"
                  type="text"
                  name="postal_code"
                  value={props.newCustomer.postal_code}
                  onChange={props.newCustomerChange}
                />
                <small className="form-text text-muted">Kode pos maksimal 5 karakter</small>
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            disabled={props.loading}
            onClick={() => props.toggleModal('customerModal')}
          >
            Tutup
          </Button>
          <Button color="primary" disabled={props.loading} type="submit">
            {props.loading ? (
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                &nbsp; Loading...
              </>
            ) : (
              'Simpan'
            )}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CustomerModal;
