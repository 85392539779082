import React, { useState, useEffect } from 'react';
import { Container, Row, Card, CardHeader, Col, Button } from 'reactstrap';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LeadsService from '../../../../services/LeadsService';
import SimpleHeader from '../../../../components/Headers/SimpleHeader';
import Swal from 'sweetalert2';
import SearchBar from '../../../../components/Search';
import FormLeadsReason from './FormLeadsReason';

const TableLeadsReason = () => {
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [detailModal, setDetailModal] = useState(false);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  // eslint-disable-next-line
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getData();
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await LeadsService.getDataLeadsReason(page, sizePerPage, sortField, sortOrder, searchText);
      // console.log(data);
      setData(res.data);
      setTotalSize(res.recordsFiltered);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDataAfterUpdate = () => {
    return data;
  };

  const deleteLeads = id => {
    LeadsService.removeLeadsReason(id).then(res => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Data Alasan Belum Beli Berhasil Dihapus'
        });
      }
      getData();
    });
  };

  const toggelModalAddLeadsReason = async () => {
    try {
      setDetailModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  return (
    <>
      <SimpleHeader name="Alasan Belum Beli" parentName="Manajemen Leads" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Data Alasan Belum Beli</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      id="tooltip443412080"
                      size="sm"
                      onClick={() => toggelModalAddLeadsReason('detailModal')}
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Tambah</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'no',
                    text: 'No',
                    sort: true
                  },
                  {
                    dataField: 'name',
                    text: 'Alasan Belum Beli',
                    sort: true
                  },
                  {
                    dataField: 'action',
                    text: 'Aksi',
                    sort: false,
                    isDummyField: true,
                    csvExport: false,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <>
                          <Button color="danger" type="button" size="sm" outline onClick={() => deleteLeads(row.id)}>
                            <span className="btn-inner--text">Hapus</span>
                          </Button>
                        </>
                      );
                    }
                  }
                ]}
                exportCSV
                search
              >
                {props => {
                  return (
                    <div className="py-4 table-responsive">
                      <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                        <label>
                          Search:
                          <SearchBar className="form-control-sm" {...props.searchProps} placeholder="Keyword" min={3} />
                        </label>
                      </div>
                      {/* <ExportCSVButton {...props.csvProps} /> */}
                      <BootstrapTable
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true
                        }}
                        loading={true}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={paginationFactory({
                          page,
                          sizePerPage,
                          totalSize,
                          showTotal: true,
                          withFirstAndLast: true,
                          sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                            <div className="dataTables_length" id="datatable-basic_length">
                              <label>
                                Show{' '}
                                {
                                  <select
                                    name="datatable-basic_length"
                                    aria-controls="datatable-basic"
                                    className="form-control form-control-sm"
                                    onChange={e => onSizePerPageChange(e.target.value)}
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                }{' '}
                                entries.
                              </label>
                            </div>
                          )
                        })}
                        bordered={false}
                        onTableChange={onTableChange}
                      />
                    </div>
                  );
                }}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>

      <FormLeadsReason
        getDataAfterUpdate={getDataAfterUpdate}
        toggle={toggelModalAddLeadsReason}
        isOpen={detailModal}
        setDetailModal={setDetailModal}
      />
    </>
  );
};

export default TableLeadsReason;
