import { request } from '../config';

// versi function
function ManufacturerService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/manufacturers', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;
    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // list all data
  const list = async () => {
    const response = await request.get(`/manufacturers/list`);
    return response.data;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/manufacturers/${id}`);
    return response.data;
  };

  const save = async data => {
    const response = await request.post(`/manufacturers`, data);
    return response.data;
  };

  const findByTypeCarIdAndCategoryId = async (typeCarId, CategoryId) => {
    const response = await request.get(`/vouchers/manufacturers`, {
      params: {
        type_car_id: typeCarId,
        category_id: CategoryId
      }
    });
    return response;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/manufacturers/${id}`, data);
    return response.data;
  };

  const remove = async id => {
    const response = await request.delete(`/manufacturers/${id}`);
    return response.data;
  };

  return {
    getDatatable,
    list,
    findById,
    findByTypeCarIdAndCategoryId,
    save,
    update,
    remove
  };
}

export default ManufacturerService();
