import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Input, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { ROLE_CS } from '../../../constants';
import RoleService from '../../../services/RoleService';
import UserService from '../../../services/UserService';

function EditUserNew(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    password: '',
    name: 'Nama',
    email: 'email@coversuper.com',
    role_id: 2,
    cs_level_id: 1,
    is_active: '',
    team_id: 1
  });
  const [csLevelList, setCsLevelList] = useState([]);
  const [teamList, setTeamList] = useState([]);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getData();
    getRoles();
    getCsLevel();
    getTeam();
  }, []);

  const getRoles = async () => {
    setLoading(true);
    try {
      const res = await RoleService.list();
      if (res.status === 200) {
        setLoading(false);
        setRoles(res.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const user = await UserService.findById(props.match.params.id);

      if (user.status === 200) {
        setLoading(false);
        if (!user.data.data.cs_level_id) {
          user.data.data.cs_level_id = undefined;
        }
        user.data.data.password = '';
        setUser(user.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getCsLevel = async () => {
    setLoading(true);
    try {
      setLoading(false);
      const response = await UserService.getCsLevel();

      if (response.status === 200) {
        setCsLevelList(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTeam = async () => {
    setLoading(true);
    try {
      setLoading(false);
      const response = await UserService.teamList();

      if (response.status === 200) {
        setTeamList(response.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const handleToggleStatus = () => {
    setUser({
      ...user,
      is_active: !user.is_active
    });
  };

  const validateUserForm = () => {
    const alert = field => {
      Swal.fire({
        title: `${field} tidak boleh kosong`
      });
    };

    if (user.name === '' || !user.name) {
      alert('Nama');
      return false;
    } else if (user.email === '' || !user.email) {
      alert('email');
      return false;
    } else if (user.password && user.password.length < 4) {
      alert('Password');
      return false;
    } else if (user.role_id === '' || !user.role_id) {
      alert('Role');
      return false;
    }

    return true;
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      if (validateUserForm()) {
        const data = {
          name: user.name,
          email: user.email,
          password: user.password,
          role_id: user.role_id,
          is_active: user.is_active
        };
        data.cs_level_id = parseInt(user.role_id) === ROLE_CS ? user.cs_level_id : null;
        data.team_id = parseInt(user.role_id) === ROLE_CS ? user.team_id : null;

        const res = await UserService.update(props.match.params.id, data);

        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah'
          }).then(() => {
            props.history.push('/users');
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <SimpleHeader name="Ubah Pengguna" parentName="Pengguna" parentLink="/user" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    // noValidate
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationCustom01">
                          Nama
                        </label>
                        <Input
                          required
                          value={user.name || ''}
                          id="validationCustom01"
                          placeholder="Nama"
                          type="text"
                          name="name"
                          valid={!!user.name}
                          invalid={user.name === ''}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">Nama tidak boleh kosong!</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationEmail">
                          Email
                        </label>
                        <Input
                          required
                          readOnly
                          value={user.email || ''}
                          id="validationEmail"
                          placeholder="Email"
                          type="email"
                          name="email"
                          valid={!!user.email}
                          invalid={user.email === ''}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">Email tidak boleh kosong!</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationEmail">
                          Password <i className="text-danger">(Kosongkan jika tidak diubah)</i>
                        </label>

                        <Input
                          defaultValue=""
                          id="validationPassword"
                          placeholder="Password"
                          type="password"
                          name="password"
                          valid={user.password === '' || user.password.length >= 4}
                          invalid={!!user.password && user.password.length < 4}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">Password minimal 4 karakter!</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationRole">
                          Sebagai
                        </label>
                        <Input
                          value={user.role_id}
                          required
                          id="validationRole"
                          placeholder=""
                          type="select"
                          name="role_id"
                          valid={!!user.role_id}
                          invalid={user.role_id === ''}
                          onChange={handleChange}
                        >
                          <option value=""> -- Pilih --</option>
                          {roles.map((role, key) => (
                            <option key={key} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </Input>
                        <div className="invalid-feedback">Data tidak boleh kosong!</div>
                      </Col>
                    </Row>
                    {parseInt(user.role_id) === ROLE_CS && (
                      <>
                        <Row>
                          <Col className="mb-3" md="4">
                            <label className="form-control-label" htmlFor="validationRole">
                              CS Level
                            </label>
                            <Input
                              value={user.cs_level_id}
                              required
                              id="validationRole"
                              placeholder=""
                              type="select"
                              name="cs_level_id"
                              valid={!!user.cs_level_id}
                              invalid={user.cs_level_id === ''}
                              onChange={handleChange}
                            >
                              <option value=""> -- Pilih --</option>
                              {csLevelList.map((role, key) => (
                                <option key={key} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                            </Input>
                            <div className="invalid-feedback">Data tidak boleh kosong!</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3" md="4">
                            <label className="form-control-label" htmlFor="validationTeam">
                              Team
                            </label>
                            <Input
                              value={user.team_id}
                              required
                              id="validationTeam"
                              placeholder=""
                              type="select"
                              name="team_id"
                              valid={!!user.team_id}
                              invalid={user.team_id === ''}
                              onChange={handleChange}
                            >
                              <option value=""> -- Pilih --</option>
                              {teamList.map((team, key) => (
                                <option key={key} value={team.id}>
                                  {team.name}
                                </option>
                              ))}
                            </Input>
                            <div className="invalid-feedback">Data tidak boleh kosong!</div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col className="mb-3" md="4">
                        <label className="form-control-label" htmlFor="validationStatus">
                          Status Aktif
                        </label>
                        <br />
                        <label className="custom-toggle">
                          <Input
                            checked={user.is_active}
                            type="checkbox"
                            name="is_active"
                            onChange={handleToggleStatus}
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                          />
                        </label>
                      </Col>
                    </Row>

                    <Button disabled={loading} color="primary" type="submit">
                      Simpan
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default EditUserNew;
