/* eslint-disable */
import React from 'react';

import Report from './Report';
import ReportOrderSource from './ReportOrderSource';

class index extends React.Component {
  render() {
    const { pathname } = this.props.location;
    return (
      <>
        {pathname === '/reports/platform' && (
          <Report
            title="Platform"
            url="platforms"
            columns={[
              {
                dataField: 'no',
                text: 'NOMOR',
                sort: false
              },
              {
                dataField: 'platform',
                text: 'Merek Mobil',
                sort: false
              },
              {
                dataField: 'total',
                text: 'TOTAL',
                sort: false
              }
            ]}
          />
        )}
        {pathname === '/reports/brand' && (
          <Report
            title="Merek Mobil"
            url="brands"
            columns={[
              {
                dataField: 'no',
                text: 'NOMOR',
                sort: false
              },
              {
                dataField: 'product_name',
                text: 'Merek Mobil',
                sort: false
              },
              {
                dataField: 'total',
                text: 'TOTAL',
                sort: false
              }
            ]}
          />
        )}
        {pathname === '/reports/cover' && (
          <Report
            title="Kategori Cover"
            url="covers"
            columns={[
              {
                dataField: 'no',
                text: 'NOMOR',
                sort: false
              },
              {
                dataField: 'name',
                text: 'KATEGORI',
                sort: false
              },
              {
                dataField: 'total',
                text: 'TOTAL',
                sort: false
              }
            ]}
          />
        )}
        {pathname === '/reports/cities' && (
          <Report
            title="Kota Customer"
            url="cities"
            columns={[
              {
                dataField: 'no',
                text: 'NOMOR',
                sort: false
              },
              {
                dataField: 'name',
                text: 'KOTA',
                sort: false
              },
              {
                dataField: 'total',
                text: 'TOTAL',
                sort: false
              }
            ]}
          />
        )}
        {pathname === '/reports/order-source' && <ReportOrderSource title="Sumber Order" url="order-sources" />}
        {pathname === '/reports/product' && (
          <Report
            title="Produk"
            url="products"
            columns={[
              {
                dataField: 'no',
                text: 'NOMOR',
                sort: false
              },
              {
                dataField: 'product_name',
                text: 'NAMA PRODUK',
                sort: false
              },
              {
                dataField: 'year',
                text: 'TAHUN',
                sort: false
              },
              {
                dataField: 'total',
                text: 'TOTAL',
                sort: false
              }
            ]}
          />
        )}
        {pathname === '/reports/color' && (
          <Report
            title="Warna"
            url="colors"
            columns={[
              {
                dataField: 'no',
                text: 'NOMOR',
                sort: false
              },
              {
                dataField: 'category_name',
                text: 'COVER',
                sort: false
              },
              {
                dataField: 'color_name',
                text: 'WARNA',
                sort: false
              },
              {
                dataField: 'total',
                text: 'TOTAL',
                sort: false
              }
            ]}
          />
        )}
      </>
    );
  }
}

export default index;
