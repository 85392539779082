import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import moment from 'moment';

const TablePenjualan = props => {
  const { data } = props;

  var totalSales = data.reduce((total, arr) => {
    return total + arr.sales;
  }, 0);

  var totalJumlahPenjualan = data.reduce((total, arr) => {
    return total + arr.sales_amount;
  }, 0);

  var totalKomisi = data.reduce((total, arr) => {
    return total + arr.total_commission;
  }, 0);

  return (
    <>
      <Card>
        <CardBody>
          <Table bordered responsive>
            <thead>
              <tr className="text-center">
                <th>Periode Penjualan</th>
                <th>Jumlah Penjualan</th>
                <th>Total Nilai Penjualan</th>
                <th>Total Komisi Afiliasi</th>
              </tr>
            </thead>
            <tbody>
              {data.map(sale => (
                <tr className="text-right">
                  <td className="text-center">{moment(sale.date).format('ll')}</td>
                  <td>{new Intl.NumberFormat('id-ID').format(sale.sales)}</td>
                  <td>
                    Rp
                    {new Intl.NumberFormat('id-ID').format(sale.sales_amount)}
                  </td>
                  <td>
                    Rp
                    {new Intl.NumberFormat('id-ID').format(sale.total_commission)}
                  </td>
                </tr>
              ))}
              <tr className="text-right">
                <th className="text-center">
                  <b>Total</b>
                </th>
                <th>{new Intl.NumberFormat('id-ID').format(totalSales)}</th>
                <th>Rp {new Intl.NumberFormat('id-ID').format(totalJumlahPenjualan)}</th>
                <th>Rp {new Intl.NumberFormat('id-ID').format(totalKomisi)}</th>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default TablePenjualan;
