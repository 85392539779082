import { request } from '../config';

// versi function
function ProductService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/products', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;

    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // search product
  const searchProduct = async filter => {
    const response = await request.post('products/search', filter);
    return response.data;
  };

  // search product
  const sizeProduct = async () => {
    const response = await request.get('products/size');
    return response.data;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/products/${id}`);
    return response.data;
  };

  // find by variant id
  const findByVariantId = async id => {
    const response = await request.get(`/products/variant/${id}`);
    return response.data;
  };

  // insert data
  const save = async data => {
    const response = await request.post(`/products`, data);
    return response.data;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/products/${id}`, data);
    return response.data;
  };

  // delete data
  const remove = async id => {
    const response = await request.delete(`/products/${id}`);
    return response.data;
  };

  const getDefaultPrice = async params => {
    const response = await request.get(`/pricelists/default`, { params });
    return response.data;
  };

  const exportData = async params => {
    const response = await request.get(`/products/export`, { params });
    return response;
  };

  return {
    getDatatable,
    searchProduct,
    sizeProduct,
    findById,
    findByVariantId,
    save,
    update,
    remove,
    getDefaultPrice,
    exportData
  };
}

export default ProductService();
