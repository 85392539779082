import React, { useState } from 'react';
import { Badge, Button, CardGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { CUSTOMER_BIG_SELLER, STATUS_ANTRI_PRODUKSI } from '../../../../constants';
import FlowService from '../../../../services/FlowService';

function PaymentVerificationModal(props) {
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    props.toggle(props.row, 'paymentVerificationModal');
  };

  const handleVerification = async () => {
    setLoading(true);
    try {
      const res = await FlowService.forward({
        order_detail_id: props.row.id,
        status_type_id: STATUS_ANTRI_PRODUKSI
      });

      toggle();
      if (res.status === 200) {
        setLoading(false);
        Swal.fire({
          title: 'Berhasil',
          text: `Pesanan dengan nomor order ${props.row.order.order_number} berhasil diverifikasi.`
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return props.row ? (
    <>
      <Modal isOpen={props.isOpen} toggle={toggle} style={{ minWidth: '90%' }}>
        <ModalHeader>Verifikasi Pembayaran {props.row.order.order_number}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="6" xs="12">
              {props.row.order.payment_histories.length === 0 &&
                props.row &&
                props.row.order &&
                props.row.order.verification_type === 'auto' && <h4>Pembayaran menggunakan verifikasi otomatis</h4>}
              <CardGroup>
                {props.row.order.payment_histories.map((data, key) => (
                  <div key={key} style={{ maxWidth: '100%' }}>
                    {data.evidences.map((evidence, i) => (
                      <img
                        key={i}
                        style={{ maxWidth: '100%' }}
                        onClick={() => window.open(evidence.image, '_blank')}
                        alt="Gambar"
                        src={evidence.image}
                      />
                    ))}
                  </div>
                ))}
              </CardGroup>
            </Col>
            <Col md="6" xs="12">
              <div className="card-header">
                No : <strong>{props.row.order.order_number}</strong>
                <span className="float-right">
                  {' '}
                  <strong>Status Bayar: </strong>{' '}
                  <Badge
                    className="badge-md"
                    color={props.row.order.payment_status.name === 'Lunas' ? 'success' : 'warning'}
                  >
                    {props.row.order.payment_status.name}
                  </Badge>
                </span>
              </div>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-sm-6">
                    <h6 className="mb-3">Dari:</h6>
                    {props.row.order.customer_type.id === CUSTOMER_BIG_SELLER ? (
                      <>
                        <div>
                          <strong>{props.row.order.customer.name}</strong>
                        </div>
                        <div>{props.row.order.customer.address}</div>
                        <div>{props.row.order.customer.subdistrict.name}</div>
                        <div>
                          {props.row.order.customer.district.type + ' ' + props.row.order.customer.district.name}
                        </div>
                        <div>{props.row.order.customer.province.name}</div>
                        <div>Telp: {props.row.order.customer.phone}</div>
                      </>
                    ) : (
                      <>
                        <div>
                          <strong>{props.row.order.supplier.name}</strong>
                        </div>
                        <div>{props.row.order.supplier.address}</div>
                        <div>{props.row.order.supplier.subdistrict.name}</div>
                        <div>
                          {props.row.order.supplier.district.type + ' ' + props.row.order.supplier.district.name}
                        </div>
                        <div>{props.row.order.supplier.province.name}</div>
                        <div>Telp: {props.row.order.supplier.phone}</div>
                      </>
                    )}
                  </div>

                  <div className="col-sm-6">
                    <h6 className="mb-3">Kepada:</h6>
                    <div>
                      <strong>{props.row.order.shipping_address.name}</strong>
                    </div>
                    <div>
                      {props.row.order.shipping_address.address +
                        ' ' +
                        props.row.order.shipping_address.subdistrict.name +
                        ' ' +
                        props.row.order.shipping_address.district.type +
                        ' ' +
                        props.row.order.shipping_address.district.name}
                    </div>
                    <div>{props.row.order.shipping_address.province.name}</div>
                    {props.row.order.shipping_address.email ? (
                      <div>Email: {props.row.order.shipping_address.email}</div>
                    ) : (
                      ''
                    )}
                    <div>
                      Telp: {props.row.order.shipping_address.phone}
                      {props.row.order.shipping_address.phone_alt
                        ? ' / ' + props.row.order.shipping_address.phone_alt
                        : ''}
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th className="text-center">Qty</th>
                        <th>Produk</th>
                        <th>Tahun</th>
                        <th>Warna</th>
                        <th>Cover Tipe</th>
                        <th>Cover List</th>
                        <th>Tambahan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.row.order &&
                        props.row.order.details &&
                        props.row.order.details.length > 0 &&
                        props.row.order.details.map((detail, key) => (
                          <tr key={key}>
                            <td className="text-center">{detail.qty}</td>
                            <td className="text-left strong" style={{ whiteSpace: 'unset' }}>
                              {`${detail.category ? detail.category.name : ''} ${detail.product_name}`}
                            </td>
                            <td>{detail.year}</td>
                            <td>{detail.color && detail.color.name}</td>
                            <td>{detail.cover_type && detail.cover_type.name}</td>
                            <td>{detail.cover_list && detail.cover_list.name}</td>
                            <td>
                              {detail &&
                                detail.addons &&
                                detail.addons.length > 0 &&
                                detail.addons.map((addon, key) => <li key={key}>{addon.name}</li>)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="left">
                            <strong>DPP</strong>
                          </td>
                          <td className="text-right">
                            <strong>Rp {new Intl.NumberFormat('id-ID').format(props.row.order.gross_amount)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="left">
                            <strong>PPN</strong>
                          </td>
                          <td className="text-right">
                            <strong>Rp {new Intl.NumberFormat('id-ID').format(props.row.order.tax)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="left">
                            <strong>Grand Total</strong>
                          </td>
                          <td className="text-right" style={{ fontSize: '1.5rem' }}>
                            <strong>Rp {new Intl.NumberFormat('id-ID').format(props.row.order.grand_total)}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td className="left">
                            <strong>Total Bayar</strong>
                          </td>
                          <td className="text-right">
                            <strong>Rp {new Intl.NumberFormat('id-ID').format(props.row.order.payment_amount)}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} color="success" onClick={handleVerification}>
            Verifikasi
          </Button>
          {` `}
          <Button onClick={toggle}>Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    ''
  );
}

export default PaymentVerificationModal;
