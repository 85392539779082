import { request } from '../config';

class FinanceService {
  static async getDepositDatatable(page, limit, sort, order, filter, start_date, end_date) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/customers/deposit', {
      params: { page, limit, sort, order, filter, start_date, end_date }
    });
    if (response)
      return {
        ...response,
        data: {
          ...response.data,
          page: response.data.page + 1
        }
      };
  }

  static async getBillingDatatable(page, limit, sort, order, filter, start_date, end_date) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/customers/billing', {
      params: { page, limit, sort, order, filter, start_date, end_date }
    });

    return {
      ...response,
      data: {
        ...response.data,
        page: response.data.page + 1
      }
    };
  }

  static async getPayoutDatatable(page, limit, sort, order, filter, start_date, end_date) {
    const response = await request.get('/affiliates/payout', {
      params: { page, limit, sort, order, filter, start_date, end_date }
    });

    return response;
  }

  static async downloadDeposit({ start_date, end_date, filter }) {
    const response = await request.get(`/customers/deposit/export`, {
      params: {
        start_date,
        end_date,
        filter
      }
    });

    return response;
  }
}

export default FinanceService;
