import React, { useEffect, useState } from 'react';
// import Chart from 'chart.js';
import Axios from 'axios';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem, Card, CardHeader, Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from '../../../components/Loader/Loader';

import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import CardAffiliate from './components/CardAffiliate';
// import ScoreboardService from '../../../services/ScoreboardService';
import Swal from 'sweetalert2';
import AffiliateService from '../../../services/AffiliateService';
// import { useSelector } from 'react-redux';
import ButtonSosmed from './components/ButtonSosmed';

// import SimpleHeader from '../../../components/Headers/SimpleHeader';
// import DashboardService from '../../../services/DashboardService';
// import ability from '../../../config/ability';
// const { SearchBar } = Search;

am4core.useTheme(am4themes_animated);

const Affiliate = props => {
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({
    impression: 0,
    conversion: 0,
    commission: 0
  });
  const [loading, setLoading] = useState(false);
  // const [impresi, setImpresi] = useState(null);
  // const [konversi, setKonversi] = useState(null);
  // const [komisi, setKomisi] = useState(null);

  // const user = useSelector(state => state.auth.user);

  // const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?myNewUrlQuery=1';
  // window.history.pushState({ path: newUrl }, '', newUrl);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getData();

    return () => {
      source.cancel();
    };
  }, [page, sizePerPage, sortField, sortOrder, searchText]);

  const onTableChange = (type, newState) => {
    const { page, sizePerPage, sortField, sortOrder, searchText } = newState;

    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
    setSearchText(searchText);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await AffiliateService.getListProductsAffiliate();
      const { data: summaryData } = await AffiliateService.getSummary();
      setData(data);
      setSummary(summaryData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const shareButtonSupported = navigator.share !== undefined;

  return (
    <>
      {loading && <Loading />}
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">Dashboard Partner</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="/" onClick={e => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    Ringkasan Data Partner
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col md="4" xl="4">
                {/* <Can I="read" a="Dashboard Order Today"> */}
                <CardAffiliate
                  name="Impresi"
                  value={new Intl.NumberFormat('id-ID').format(summary.impression)}
                  icon="fas fa-users"
                  gradient="green"
                />
                {/* </Can> */}
              </Col>
              <Col md="4" xl="4">
                {/* <Can I="read" a="Dashboard New Customer Today"> */}
                <CardAffiliate
                  name="Konversi"
                  icon="ni ni-cart"
                  value={new Intl.NumberFormat('id-ID').format(summary.conversion)}
                  gradient="red"
                />
                {/* </Can> */}
              </Col>
              <Col md="4" xl="4">
                {/* <Can I="read" a="Dashboard Product Sold Today"> */}
                <CardAffiliate
                  name="Komisi Partner"
                  icon="ni ni-money-coins"
                  value={'Rp. ' + new Intl.NumberFormat('id-ID').format(summary.commission)}
                  gradient="primary"
                />
                {/* </Can> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="border-bottom-0">
                <h3 className="mb-0">Produk Partner</h3>
              </CardHeader>

              <ToolkitProvider
                keyField="id"
                data={data}
                columns={[
                  {
                    dataField: 'image',
                    text: 'Gambar Produk',
                    sort: false,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      return (
                        <a target="_blank" rel="noopener noreferrer" href={cell}>
                          <img alt={`banner ${row.no}`} width="100" src={cell} />
                        </a>
                      );
                    }
                  },
                  {
                    dataField: 'name',
                    text: 'Nama Produk',
                    sort: false
                  },
                  {
                    dataField: 'sales',
                    text: 'Total Penjualan',
                    sort: false,
                    formatter: cell => {
                      return new Intl.NumberFormat('id-ID').format(cell);
                    }
                  },
                  {
                    dataField: 'commission',
                    text: 'Komisi Per Produk',
                    sort: false,
                    formatter: cell => {
                      return 'Rp. ' + new Intl.NumberFormat('id-ID').format(cell);
                    }
                  },
                  {
                    dataField: 'total_commission',
                    text: 'Perkiraan Komisi (tidak termasuk diskon)',
                    sort: false,
                    formatter: cell => {
                      return 'Rp. ' + new Intl.NumberFormat('id-ID').format(cell);
                    }
                  },
                  {
                    dataField: 'action',
                    text: 'Share',
                    sort: false,
                    isDummyField: true,
                    formatter: (cell, row, rowIndex, formatExtraData) => {
                      // console.log(row);
                      return (
                        <>
                          {/* <Can I="update" a="Stp User"> */}
                          <Button
                            color="primary"
                            type="button"
                            size="sm"
                            outline
                            onClick={() => {
                              if (navigator.clipboard.writeText(row.url)) {
                                Swal.fire({
                                  position: 'top-center',
                                  icon: 'success',
                                  title: 'Copied!',
                                  showConfirmButton: false,
                                  timer: 1000
                                });
                              } else {
                                return false;
                              }
                            }}
                          >
                            <span className="btn-inner--text">Salin Partner URL Halaman Produk</span>
                          </Button>

                          {shareButtonSupported ? (
                            <Button
                              color="success"
                              type="button"
                              size="sm"
                              outline
                              className="mb-2 mt-2"
                              // outline
                              onClick={async () => {
                                try {
                                  await navigator.share({ url: row.url });
                                } catch (err) {
                                  Swal.fire(err.message);
                                }
                              }}
                            >
                              <span className="btn-inner--icon">
                                <i className="fas fa-share" />
                              </span>
                              <span className="btn-inner--text"> Share</span>
                            </Button>
                          ) : (
                            <>
                              <br />
                              <Button
                                color="success"
                                type="button"
                                size="sm"
                                className="mb-2 mt-2"
                                // outline
                                onClick={() => {
                                  let text = `${row.url}`;

                                  let url = `https://wa.me/?text=${encodeURIComponent(text)}`;

                                  window.open(url, '_blank');
                                }}
                              >
                                <span className="btn-inner--icon">
                                  <i className="fab fa-whatsapp" />
                                </span>
                                <span className="btn-inner--text"> Whatsapp</span>
                              </Button>

                              <ButtonSosmed
                                name=" Instagram"
                                iconSosmed="fab fa-instagram"
                                background="linear-gradient(45DEG, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D)"
                                color="white"
                                onClick={async () => {
                                  try {
                                    await navigator.clipboard.writeText(row.url);
                                    Swal.fire({
                                      position: 'top-center',
                                      icon: 'success',
                                      title: 'Copied!',
                                      showConfirmButton: false,
                                      timer: 1000
                                    });

                                    let url = `https://www.instagram.com/direct/inbox/`;

                                    window.open(url, '_blank');
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />

                              <ButtonSosmed
                                name=" Tiktok"
                                iconSosmed="fa-brands fa-tiktok"
                                background="#000000"
                                color="white"
                                onClick={async () => {
                                  try {
                                    await navigator.clipboard.writeText(row.url);
                                    Swal.fire({
                                      position: 'top-center',
                                      icon: 'success',
                                      title: 'Copied!',
                                      showConfirmButton: false,
                                      timer: 1000
                                    });

                                    let url = `https://www.tiktok.com/id-ID/`;

                                    window.open(url, '_blank');
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />

                              <ButtonSosmed
                                name=" Twitter"
                                iconSosmed="fab fa-twitter"
                                background="#1DA1F2"
                                color="white"
                                onClick={async () => {
                                  try {
                                    await navigator.clipboard.writeText(row.url);
                                    Swal.fire({
                                      position: 'top-center',
                                      icon: 'success',
                                      title: 'Copied!',
                                      showConfirmButton: false,
                                      timer: 1000
                                    });

                                    let url = `https://twitter.com/messages`;

                                    window.open(url, '_blank');
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />

                              <ButtonSosmed
                                name=" Line"
                                iconSosmed="fab fa-line"
                                background="#00B900"
                                color="white"
                                onClick={async () => {
                                  try {
                                    await navigator.clipboard.writeText(row.url);
                                    Swal.fire({
                                      position: 'top-center',
                                      icon: 'success',
                                      title: 'Copied!',
                                      showConfirmButton: false,
                                      timer: 1000
                                    });

                                    let url = `https://www.instagram.com/direct/inbox/`;

                                    window.open(url, '_blank');
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />

                              <ButtonSosmed
                                name=" Telegram"
                                iconSosmed="fab fa-telegram"
                                background="#229ED9"
                                color="white"
                                onClick={async () => {
                                  try {
                                    await navigator.clipboard.writeText(row.url);
                                    Swal.fire({
                                      position: 'top-center',
                                      icon: 'success',
                                      title: 'Copied!',
                                      showConfirmButton: false,
                                      timer: 1000
                                    });

                                    let url = `https://web.telegram.org/k/`;

                                    window.open(url, '_blank');
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />

                              <ButtonSosmed
                                name=" Facebook"
                                iconSosmed="fab fa-facebook"
                                background="#3b5998"
                                color="white"
                                onClick={async () => {
                                  try {
                                    await navigator.clipboard.writeText(row.url);
                                    Swal.fire({
                                      position: 'top-center',
                                      icon: 'success',
                                      title: 'Copied!',
                                      showConfirmButton: false,
                                      timer: 1000
                                    });

                                    let url = `https://www.facebook.com/messages/t/`;

                                    window.open(url, '_blank');
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }}
                              />
                            </>
                          )}
                        </>
                      );
                    }
                  }
                ]}
                search
              >
                {props => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      remote={{
                        filter: true,
                        pagination: true,
                        sort: true
                      }}
                      loading={true}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={paginationFactory({
                        page,
                        sizePerPage,
                        totalSize,
                        showTotal: true,
                        withFirstAndLast: true,
                        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                          <div className="dataTables_length" id="datatable-basic_length">
                            <label>
                              Show{' '}
                              {
                                <select
                                  name="datatable-basic_length"
                                  aria-controls="datatable-basic"
                                  className="form-control form-control-sm"
                                  onChange={e => onSizePerPageChange(e.target.value)}
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              }{' '}
                              entries.
                            </label>
                          </div>
                        )
                      })}
                      bordered={false}
                      onTableChange={onTableChange}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Affiliate;
