import React from 'react';
// nodejs library that concatenates classes
// import classnames from "classnames";
// reactstrap components
import { Button, Card, CardBody, Form, Input, Container, Row, Col, FormGroup } from 'reactstrap';
// core components
import SimpleHeader from '../../../components/Headers/SimpleHeader';
// sweet alert
import Swal from 'react-bootstrap-sweetalert';
// service
import TypeCarService from '../../../services/TypeCarService';

class EditTypeCar extends React.Component {
  state = {
    category: {
      id: this.props.match.params.id,
      name: '',
      vehicle_type_id: ''
    },
    customStyles: {
      name: '',
      nameState: null,
      vehicle_type_id: '',
      vehicle_type_idState: null
    },
    vehicleTypes: [],
    alert: null
  };

  getData = () => {
    const { id } = this.props.match.params;

    TypeCarService.findById(id)
      .then(response => {
        this.setState({ category: response.data }, () => {
          this.setState(prevState => {
            let customStyles = {
              ...prevState.customStyles,
              name: response.data.name,
              vehicle_type_id: response.data.vehicle_type_id
            };
            return { customStyles };
          });
        });
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push('/login');
        }
        this.errorAlert(error.message);
      });
  };

  updateData = () => {
    const { id } = this.state.category;
    const data = {
      name: this.state.customStyles.name,
      vehicle_type_id: this.state.customStyles.vehicle_type_id
    };

    TypeCarService.update(id, data)
      .then(() => {
        this.successAlert();
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.history.push('/login');
        }
        this.errorAlert(error.message);
      });
  };

  hideAlert = () => {
    this.setState({ alert: null });
    this.props.history.push('/typecars');
  };

  errorAlert = message => {
    this.setState({
      alert: (
        <Swal
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Terjadi Kesalahan"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </Swal>
      )
    });
  };

  successAlert = () => {
    this.setState({
      alert: (
        <Swal
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Success"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          Data berhasil diubah
        </Swal>
      )
    });
    setTimeout(this.hideAlert, 1500);
  };

  validateCustomStylesForm = () => {
    let newState = this.state.customStyles;
    newState.nameState = newState.name ? (newState.name === '' ? 'invalid' : 'valid') : 'invalid';
    newState.vehicle_type_idState = newState.vehicle_type_id
      ? newState.vehicle_type_id === ''
        ? 'invalid'
        : 'valid'
      : 'invalid';

    this.setState({ customStyles: newState });
    if (newState.nameState !== 'valid') {
      return false;
    } else if (newState.vehicle_type_idState !== 'valid') {
      return false;
    }
    return true;
  };

  customStylesForm = (e, stateName) => {
    let newState = this.state.customStyles;
    newState[stateName] = e.target.value;
    newState[stateName + 'State'] = e.target.value === '' ? 'invalid' : 'valid';

    this.setState({ customStyles: newState });
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.validateCustomStylesForm()) {
      this.updateData();
    }
  };

  componentDidMount() {
    this.getVehicleTypes();
  }

  getVehicleTypes = async () => {
    try {
      const response = await TypeCarService.getVehicleTypes();
      if (response.status === 200) {
        this.getData();
        this.setState({
          vehicleTypes: response.data.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader name="Ubah Jenis Kendaraan" parentName="Jenis Kendaraan" parentLink="/typecars" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                      <Row>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label" htmlFor="validationCustom01">
                            Nama Jenis Kendaraan
                          </label>
                          <Input
                            value={this.state.customStyles.name}
                            id="name"
                            placeholder="Nama Jenis Kendaraan"
                            type="text"
                            name="name"
                            valid={this.state.customStyles.nameState === 'valid'}
                            invalid={this.state.customStyles.nameState === 'invalid'}
                            onChange={e => this.customStylesForm(e, 'name')}
                          />
                          <div className="invalid-feedback">Nama tidak boleh kosong!</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="validationTypeCar">
                              Jenis Kendaraan
                            </label>
                            <Input
                              required
                              id="validationTypeCar"
                              type="select"
                              name="type_car_id"
                              value={this.state.customStyles.vehicle_type_id}
                              valid={this.state.customStyles.vehicle_type_idState === 'valid'}
                              invalid={this.state.customStyles.vehicle_type_idState === 'invalid'}
                              placeholder="--Jenis Kendaraan--"
                              onChange={e => this.customStylesForm(e, 'vehicle_type_id')}
                            >
                              <option value="">--Pilih Jenis Kendaraan--</option>
                              {this.state.vehicleTypes.map((typeCar, key) => (
                                <option value={typeCar.id} key={key}>
                                  {typeCar.name}
                                </option>
                              ))}
                            </Input>
                            <div className="invalid-feedback">Jenis kendaraan wajib diisi!</div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button color="primary" type="submit">
                        Ubah
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditTypeCar;
