import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Container, Card, CardBody, Form, Row, Col, Label, Input, Button, FormGroup } from 'reactstrap';
import EmagazineService from '../../../services/EmagazineService';
import { isLinkValid } from '../../../helpers/isLinkValid';

function EditEmagazine(props) {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [is_published, setIsPublished] = useState(false);

  const titleRef = useRef();
  const linkRef = useRef();

  useEffect(() => {
    async function getDetailData() {
      try {
        const response = await EmagazineService.detail(props.match.params.id);
        if (response.status === 200) {
          setTitle(response.data.title);
          setLink(response.data.link);
          setIsPublished(!!response.data.is_published);
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi kesalahan saat mengambil detail data'
        });
      }
    }

    getDetailData();
  }, []);

  function isValid() {
    if (title === '' || title === 0) {
      const ref = titleRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    } else if (link === '' || !link || link < 0) {
      const ref = linkRef.current;
      ref.classList.add('is-invalid');
      ref.focus();
      return false;
    }

    return true;
  }

  async function onSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      try {
        const emagz = await EmagazineService.edit(props.match.params.id, {
          title,
          link: isLinkValid(link),
          is_published: is_published ? 1 : 0
        });

        if (emagz.status === 200 && emagz.message === 'OK') {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil diubah',
            onClose: props.history.push('/emagazine')
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          onConfirm: () => props.history.push('/emagazine'),
          onCancel: () => props.history.push('/emagazine'),
          text: error.message
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader name="Tambah E-Magazine" parentName="Tabel E-Magazine" parentLink="/emagazine" />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={onSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="title">
                        Judul
                      </Label>
                      <Input
                        id="title"
                        placeholder="Judul E-Magazine"
                        name="title"
                        value={title}
                        innerRef={titleRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setTitle(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="link">
                        URL Link
                      </Label>
                      <Input
                        id="link"
                        placeholder="URL Link"
                        type="text"
                        innerRef={linkRef}
                        value={link}
                        name="link"
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setLink(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="validationStatus">
                        Status Aktif
                      </label>
                      <br />
                      <label className="custom-toggle">
                        <input
                          checked={is_published}
                          value={is_published}
                          type="checkbox"
                          name="is_published"
                          onChange={e => setIsPublished(!is_published)}
                        />
                        <span
                          className="custom-toggle-slider rounded-circle"
                          data-label-off="Tidak"
                          data-label-on="Aktif"
                        />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  <Button color="primary" className="btn btn-sm" type="submit">
                    Simpan
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default EditEmagazine;
