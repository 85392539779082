import { request } from '../config';

// versi function
function CourierService() {
  const changeStatus = async id => {
    const { data: response } = await request.put(`/couriers/change-status/${id}`);
    return response;
  };

  const getDatatable = async (page, limit, sort, order, filter) => {
    // page dimulai dari 1, maka kita konversi ke basis index 0
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/couriers', {
      params: { page, limit, sort, order, filter }
    });
    const response_1 = response.data;
    return {
      ...response_1,
      page: response_1.page + 1 // karena defaultnya index = 0
    };
  };

  // Show List
  const courierList = async () => {
    const response = await request.get(`/couriers/list`);
    return response.data;
  };

  const courierServiceList = async code => {
    const response = await request.get(`/couriers/list/${code}`);
    return response.data;
  };

  return {
    courierList,
    courierServiceList,
    getDatatable,
    changeStatus
  };
}

export default CourierService();
