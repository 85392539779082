import React from 'react';
import { Card, CardBody, Row, CardTitle } from 'reactstrap';
// import nominalBilangan from 'nominal-bilangan';

const CardOmzet = props => {
  return (
    <Card className="card-stats">
      <CardBody className="text-center">
        <Row>
          <div className="col">
            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
              {props.name}
            </CardTitle>
          </div>
        </Row>
        <div className="h2 font-weight-bold mb-0">
          {props.value.toLocaleString('id', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0
          })}
        </div>
        {/* <div>
          <span className="warning">
            <h3 className="mt-2 mb-0" style={{ fontSize: '1rem' }}>
              <strong>
                Rp {props.value}
              </strong>
            </h3>
          </span>
        </div> */}
      </CardBody>
    </Card>
  );
};

export default CardOmzet;
