import React from 'react';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import Can from '../../../config/Can';
import {
  EXCELLENT_TEAM,
  PAYMENT_STATUS_BILLING,
  PAYMENT_STATUS_COD_MP,
  PAYMENT_STATUS_DP,
  PAYMENT_STATUS_MARKETPLACE,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_UNPAID,
  STATUS_ANTRI_JAHIT,
  STATUS_ANTRI_POTONG,
  STATUS_ANTRI_PRODUKSI,
  STATUS_ANTRI_QC,
  STATUS_ANTRI_RESEP,
  STATUS_COMPLAINT_DONE,
  STATUS_COMPLAINT_PROCESS,
  STATUS_PACKING,
  STATUS_PENDING,
  STATUS_QC,
  STATUS_REFUND_DONE,
  STATUS_SENDING,
  STATUS_SENT,
  STATUS_UNVERIFIED
} from '../../../constants';

import RefundComplaintButton from './components/RefundComplaintButton';

import { useState } from 'react';
import { API_URL } from '../../../config';
import ShippingManagementService from '../../../services/ShippingManagementService';
import MoveToGreatModal from './modal/MoveToGreatModal';
import PaymentLinkModal from './modal/PaymentLinkModal';

function NextStatusButton(props) {
  const [isPaymentLinkModalShow, setIsPaymentLinkModalShow] = useState(false);
  const [isMoveToGreatModalShow, setIsMoveToGreatModalShow] = useState(false);

  let { status } = props.row;
  let labelNextStatus = status.pos_target ? status.action : null;
  const orderCancelable =
    [PAYMENT_STATUS_COD_MP, PAYMENT_STATUS_MARKETPLACE].includes(props.row.order.payment_status.id) &&
    [
      STATUS_UNVERIFIED,
      STATUS_ANTRI_PRODUKSI,
      STATUS_ANTRI_JAHIT,
      STATUS_ANTRI_POTONG,
      STATUS_ANTRI_QC,
      STATUS_QC,
      STATUS_PACKING
    ].includes(status.id);

  const labelBackStatus = status.neg_target ? status.neg_target.name : null;

  const paymentReuploadable = status.id === STATUS_UNVERIFIED;

  const pendingable = props.row.status.id > STATUS_ANTRI_PRODUKSI && props.row.status.id < STATUS_SENDING;

  const resumable = status.id === STATUS_PENDING;

  const payable = props.row.order.payment_status.id === PAYMENT_STATUS_UNPAID;

  let movable = false;
  const { order } = props.row;
  const { payment_status } = order;
  if (
    [PAYMENT_STATUS_PAID, PAYMENT_STATUS_DP].includes(payment_status.id) &&
    order.payment_type === 'Bank Transfer' &&
    order.unit.id === EXCELLENT_TEAM
  ) {
    movable = true;
  }

  // ke shipping management, jika sudah proses packing, dan hanya lunas dan tagihan, bukan dari marketplace, dan disupport oleh kiriminAja
  const shippingable =
    props.row.order.shipping_type === 'auto' &&
    labelNextStatus &&
    !!!props.row.in_shipping_management &&
    status.id === STATUS_PACKING &&
    [PAYMENT_STATUS_PAID, PAYMENT_STATUS_BILLING].includes(props.row.order.payment_status.id);

  let baseURL;

  if (API_URL === 'https://staging.coversuper.com') {
    baseURL = 'https://test.coversuper.com';
  } else if (API_URL === 'https://api.coversuper.com') {
    baseURL = 'https://coversuper.com';
  } else {
    baseURL = 'http://localhost:3030';
  }
  const linkPayment = `${baseURL}/payment-link?order_number=${props.row.order.order_number}&transaction_token=${props.row.order.transaction_token}`;

  const addToShippingManagement = async order_id => {
    try {
      const response = await ShippingManagementService.moveToShippingManagement({ order_id });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Order berhasil masuk ke Shipping Management',
          showConfirmButton: false,
          timer: 1000
        });
        setTimeout(() => {
          props.hideAlert();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {labelNextStatus && (
        <Can I="update" a="Flow">
          <Button
            onClick={() => {
              // 3 = order baru, next is input estimation
              if (status.is_with_description && status.id === STATUS_ANTRI_PRODUKSI) {
                props.showInputEstimationAlert(props.row.id, status.pos_target.id, false);
              } else if (status.id === STATUS_ANTRI_RESEP) {
                props.toggelModalWithDetail(props.row, 'isInputRecipeModalShow');

                // 18 = packing
              } else if (status.id === STATUS_PACKING && !!!props.row.in_shipping_management) {
                // props.showInputActionAlert(row.id, status.pos_target.id);
                props.toggleModal(props.row, 'isResiModalShow');
              } else if (status.id === STATUS_PACKING && !!props.row.in_shipping_management) {
                return;
              } else if (status.id === STATUS_UNVERIFIED) {
                props.toggelModalWithDetail(props.row, 'paymentVerificationModal');
              } else {
                props.showConfirmActionAlert(props.row.id, status.pos_target.id);
              }
            }}
            color="success"
            size="sm"
            className="mb-2"
          >
            {status.id === STATUS_PACKING && !!!props.row.in_shipping_management
              ? status.action
              : status.id === STATUS_PACKING && !!props.row.in_shipping_management
              ? 'TELAH MASUK KE SHIPPING MANAGEMENT'
              : status.action}
          </Button>
          <br />
          <Can I="manage" a="Shipping">
            {shippingable && (
              <>
                <Button
                  onClick={() => addToShippingManagement(props.row.order.id)}
                  style={{
                    backgroundColor: '#9b52e0',
                    color: '#fff'
                  }}
                  size="sm"
                  className="mb-2"
                >
                  Ke Shipping Management
                </Button>
                <br />
              </>
            )}
          </Can>
        </Can>
      )}

      {payable && (
        <>
          <Button color="success" size="sm" className="mb-2" onClick={() => setIsPaymentLinkModalShow(true)}>
            Link Pembayaran
          </Button>
          <PaymentLinkModal
            isOpen={isPaymentLinkModalShow}
            title="Link Pembayaran"
            toggle={() => {
              setIsPaymentLinkModalShow(!isPaymentLinkModalShow);
            }}
            link={linkPayment}
          />
          <br />
        </>
      )}

      {[STATUS_SENT, STATUS_COMPLAINT_DONE].includes(status.id) && (
        <>
          {!props.row.order.refund &&
            !props.row.order.details.map(detail => detail.status.id).includes(STATUS_COMPLAINT_PROCESS) && (
              <RefundComplaintButton
                toggleModal={props.toggleModal}
                verb="create"
                subject="Refund"
                modalName="isInputRefundModalShow"
                row={props.row}
              >
                Pengajuan Refund
              </RefundComplaintButton>
            )}

          <RefundComplaintButton
            verb="create"
            subject="Complaint"
            toggleModal={props.toggleModal}
            modalName="isComplaintInputModalShow"
            row={props.row}
            type="complaint"
          >
            Ajukan Komplain
          </RefundComplaintButton>
        </>
      )}

      {props.row.order && props.row.order.refund && !!!props.row.order.refund.approval_status_id && (
        <>
          <RefundComplaintButton
            verb="accept"
            subject="Refund"
            toggleModal={props.toggleModal}
            modalName="isInputProcessRefundModal"
            row={props.row}
          >
            Proses Refund
          </RefundComplaintButton>
        </>
      )}

      {[STATUS_COMPLAINT_PROCESS].includes(status.id) && (
        <>
          <RefundComplaintButton
            verb="accept"
            subject="Complaint"
            toggleModal={props.toggleModal}
            modalName="isComplaintProcessModalShow"
            row={props.row}
            type="complaint"
          >
            Proses Complaint
          </RefundComplaintButton>
        </>
      )}

      {[STATUS_REFUND_DONE].includes(status.id) && (
        <RefundComplaintButton
          verb="create"
          subject="Complaint"
          toggleModal={props.toggleModal}
          modalName="isComplaintInputModalShow"
          row={props.row}
          type="complaint"
        >
          Ajukan Komplain
        </RefundComplaintButton>
      )}

      {orderCancelable && (
        <Can I="cancel" an="Order">
          <Button
            color="warning"
            size="sm"
            onClick={() => {
              Swal.fire({
                title: 'Alasan',
                input: 'text'
              })
                .then(result => {
                  if (result.value && result.value !== '') {
                    props.handleCancel(props.row.order_id, result.value);
                  } else {
                    Swal.fire({
                      title: 'Terjadi Kesalahan',
                      text: 'alasan harus diisi.'
                    });
                  }
                })
                .catch(e => {
                  Swal.fire({
                    title: 'Terjadi kesalahan',
                    text: JSON.stringify(e)
                  });
                });
            }}
            className="mb-2"
          >
            Batalkan Order
          </Button>
          <br />
        </Can>
      )}

      {paymentReuploadable && (
        <Can I="update" an="Reupload Evidence">
          <Button
            color="info"
            size="sm"
            onClick={async () => {
              const { value: accept } = await Swal.fire({
                title: 'Hapus Bukti Transfer saat ini ?',
                text: 'Aksi ini akan menghapus bukti transfer saat ini, dan statusnya jadi belum bayar.',
                icon: 'warning',
                input: 'checkbox',
                inputValue: 0,
                inputPlaceholder: 'Saya setuju',
                confirmButtonText: 'Lanjutkan',
                inputValidator: result => {
                  return !result && 'Kamu harus menyetujuinya';
                }
              });

              if (accept) {
                let timerInterval;
                Swal.fire({
                  title: 'Tunggu Sebentar!',
                  html: 'Kamu akan diarahkan ke halaman upload bukti transfer.',
                  timer: 1500,
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector('b');
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  }
                }).then(() => {
                  props.handleReupload(props.row);
                });
              }
            }}
            className="mb-2"
          >
            Salah Upload Bukti Transfer ?
          </Button>
          <br />
        </Can>
      )}

      {pendingable && (
        <Can I="update" an="Pending">
          <Button
            color="warning"
            size="sm"
            onClick={() => {
              Swal.fire({
                title: 'Alasan',
                input: 'text'
              })
                .then(result => {
                  if (result.value && result.value !== '') {
                    props.handlePending(props.row.id, result.value, props.row.status.id);
                  } else {
                    Swal.fire({
                      title: 'Terjadi Kesalahan',
                      text: 'alasan harus diisi.'
                    });
                  }
                })
                .catch(e => {
                  Swal.fire({
                    title: 'Terjadi kesalahan',
                    text: JSON.stringify(e)
                  });
                });
            }}
            className="mb-2"
          >
            Pending Produksi
          </Button>
          <br />
        </Can>
      )}

      {resumable && (
        <Can I="update" an="Pending">
          <Button
            color="success"
            size="sm"
            onClick={() => {
              Swal.fire({
                title: 'Apakah anda yakin?',
                text: 'Data tidak dapat dipulihkan!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                reverseButtons: true,
                cancelButtonText: `Batal`
              })
                .then(result => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    props.handleResume(props.row.id);
                  }
                })
                .catch(e => {
                  Swal.fire({
                    title: 'Terjadi kesalahan',
                    text: JSON.stringify(e)
                  });
                });
            }}
            className="mb-2"
          >
            Lanjutkan Produksi
          </Button>
          <br />
        </Can>
      )}

      {labelBackStatus && (
        <>
          <Button
            onClick={() => props.showInputActionAlert(props.row.id, status.neg_target.id)}
            color="danger"
            size="sm"
            className="mb-2"
          >
            {status.neg_target.name}
          </Button>
          <br />
        </>
      )}

      {movable && (
        <>
          <Can I="move" an="Order Excellent to Great">
            <Button color="danger" size="sm" className="mb-2" onClick={() => setIsMoveToGreatModalShow(true)}>
              Pindah Pesanan ke Team Great ?
            </Button>
            <MoveToGreatModal
              {...props}
              isOpen={isMoveToGreatModalShow}
              toggle={() => {
                if (isMoveToGreatModalShow) {
                  props.hideAlert();
                }
                setIsMoveToGreatModalShow(!isMoveToGreatModalShow);
              }}
              link={linkPayment}
            />
            <br />
          </Can>
        </>
      )}
    </div>
  );
}

export default NextStatusButton;
