import { request } from '../config';

// versi function
function UserService() {
  // Get Data for Datatable
  const getDatatable = async (page, limit, sort, order, filter) => {
    const response = await request.get('/users', {
      params: { page, limit, sort, order, filter }
    });
    return response.data;
  };

  const changeStatus = async id => {
    const response = await request.put(`/users/change-status/${id}`);
    return response.data;
  };

  const changeProfile = async data => {
    const response = await request.put(`/users/profile`, data);
    return response.data;
  };

  const getProfile = async () => {
    const response = await request.get(`/users/profile`);
    return response.data;
  };

  // Show List
  const list = async () => {
    const response = await request.get(`/users/list`);
    return response.data;
  };

  // Show detail
  const findById = async id => {
    const response = await request.get(`/users/${id}`);
    return response;
  };

  const save = async data => {
    const response = await request.post(`/users`, data);
    return response.data;
  };

  // update Data
  const update = async (id, data) => {
    const response = await request.put(`/users/${id}`, data);
    return response;
  };

  const remove = async id => {
    const response = await request.delete(`/users/${id}`);
    return response.data;
  };

  const getCsLevel = async () => {
    const response = await request.get(`/users/cs-levels`);
    return response;
  };

  const teamList = async () => {
    const response = await request.get(`/users/team`);
    return response;
  };

  return {
    getDatatable,
    changeStatus,
    getProfile,
    changeProfile,
    list,
    findById,
    save,
    update,
    remove,
    getCsLevel,
    teamList
  };
}

export default UserService();
