import React from 'react';

const SearchBar = props => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <>
      <input
        ref={n => (input = n)}
        type="text"
        className={`form-control ${props.className ? props.className : ''}`}
        placeholder={props.placeholder || 'Search'}
        onKeyDown={e => {
          if (e.key === 'Enter') handleClick();
        }}
      />
      &nbsp;
      <button className="btn btn-sm btn-primary" onClick={handleClick}>
        Cari
      </button>
    </>
  );
};

export default SearchBar;
