import { request } from '../config';

class UploadService {
  /**
   * Get bank lists
   */

  static async commonImage(image, path = 'merchandise') {
    const { data: response } = await request.post(`/upload/products/${path}`, image, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    return response;
  }
}

export default UploadService;