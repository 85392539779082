import React, { useState, useRef } from 'react';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import { Container, Card, CardBody, Form, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import Swal from 'sweetalert2';
import ProductDescriptionService from '../../../services/ProductDescriptionService';

function AddProductDescription(props) {
  const [imageURL, setImageURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category_id] = useState(props.match.params.category_id);

  const imageRef = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();

  async function handleImage(e) {
    e.preventDefault();
    e.target.classList.remove('is-invalid');

    const formData = new FormData();
    for (const file of e.target.files) {
      formData.append('file', file);
    }

    try {
      setLoading(true);
      const image = await ProductDescriptionService.uploadImage(formData);

      setImageURL(image.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function setNotValid({ current }) {
    current.classList.add('is-invalid');
    current.focus();
    return false;
  }

  function isValid() {
    if (imageURL === '' || imageURL.length <= 0) {
      return setNotValid(imageRef);
    } else if (title === '' || !title) {
      return setNotValid(titleRef);
    } else if (description === '' || !description) {
      return setNotValid(descriptionRef);
    }

    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (isValid()) {
      const data = {
        image: imageURL[0],
        title,
        description,
        category_id
      };

      setLoading(true);
      try {
        const response = await ProductDescriptionService.insert(data);
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Data berhasil ditambahkan',
            onClose: props.history.push(`/product_description/${category_id}`)
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: 'Terjadi kesalahan',
          icon: 'error',
          text: error
        });
      }
    }
  }

  return (
    <>
      <SimpleHeader
        name="Tambah Deskripsi Produk Kategori"
        parentName="Tabel Deskripsi Produk Kategori"
        parentLink={`/product_description/${category_id}`}
      />
      <Container className="mt--6" fluid>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="customFileLang">
                        Gambar
                        <sup>
                          <span className="text-red">*</span>
                        </sup>
                      </Label>
                      <div className="custom-file">
                        <Input
                          className="custom-file-input"
                          id="customFileLang"
                          lang="en"
                          innerRef={imageRef}
                          type="file"
                          onChange={handleImage}
                        />
                        <label className="custom-file-label" htmlFor="customFileLang">
                          Pilih file
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    {(imageURL !== '' || imageURL.length > 0) &&
                      imageURL.map((image, i) => (
                        <img key={i} src={image} alt="order evidence url" width="200" style={{ marginRight: 5 }} />
                      ))}
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="title">
                        Judul
                      </Label>
                      <Input
                        id="title"
                        placeholder="Judul"
                        name="title"
                        value={title}
                        innerRef={titleRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setTitle(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label className="form-control-label" htmlFor="description">
                        Deskripsi
                      </Label>
                      <Input
                        id="description"
                        placeholder="Tulis deskripsi di sini"
                        type="textarea"
                        rows="3"
                        name="description"
                        value={description}
                        innerRef={descriptionRef}
                        onChange={e => {
                          e.target.classList.remove('is-invalid');
                          setDescription(e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  <Button disabled={loading} color="primary" className="btn btn-sm">
                    Simpan
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default AddProductDescription;
