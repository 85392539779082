import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import arrowDownIcon from '../../../../assets/img/icons/arrow-down.png';
import arrowUpIcon from '../../../../assets/img/icons/arrow-up.png';
import ScoreboardService from '../../../../services/ScoreboardService';
// import equalsIcon from '../../../../assets/img/icons/equals.png';
import moment from 'moment';
import 'moment/locale/id';
import './ScoreboardCsgroup.scss';
moment.locale('id');

function ScoreboardCsGroupTeam() {
  const [data, setData] = useState({
    day: '',
    today_omzet: 0,
    total_omzet: 0,
    dealmaker: [
      {
        id: null,
        name: '',
        group_name: '',
        omzet_daily: 0,
        omzet_monthly: 0,
        kpi_omzet_monthly: 0,
        prev_omzet: 0,
        kpi_omzet_daily: 0,
        current_rank: 1,
        prev_rank: 1,
        change: 0
      }
    ]
  });

  useEffect(() => {
    getData();

    let interval = setInterval(() => {
      getData();
    }, 1000 * 15 * 60); // 15 menit

    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const getData = async () => {
    try {
      const response = await ScoreboardService.getScoreboardCsGroup();

      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toRP = n => {
    return n.toLocaleString('id', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    });
  };

  const getBackgroundColor = group => {
    let color = 'white';
    if (group === 'excellent') {
      color = '#d23d3d';
    } else if (group === 'jok') {
      color = '#000000';
    } else if (group === 'karpet') {
      color = '#9ed9ab';
    }

    return color;
  };

  return (
    <Container fluid style={{ padding: 0 }}>
      <div className="card-group">
        <Card>
          <CardBody style={{ background: 'rgb(164, 194, 244)' }}>
            <Row>
              <Col style={{ textAlign: 'center' }}>
                <h1>SCORE TEAM COVERSUPER GROUP</h1>
                <h4>{data.day}</h4>
                <h2>OMZET HARI INI</h2>
                <h1 style={{ fontSize: '2rem' }}>{toRP(data.today_omzet)}</h1>
                <h2>TOTAL OMZET BULAN INI</h2>
                <h1 style={{ fontSize: '2rem' }}>{toRP(data.total_omzet)}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table responsive>
                  <thead>
                    <tr style={{ fontWeight: 'bold', backgroundColor: '#DCDCDC' }}>
                      <th className="dealmaker">Rank</th>
                      <th className="dealmaker">Nama</th>
                      <th className="dealmaker number-column">Omzet Hari Ini</th>
                      <th className="dealmaker number-column">KPI Hari Ini</th>
                      <th className="dealmaker number-column">Omzet Bulan Ini</th>
                      <th className="dealmaker number-column">KPI Bulan Ini</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.dealmaker.map((user, k) => {
                      return (
                        <tr key={k} style={{ color: ["karpet"].includes(user.group_name) ? 'black' : 'white', background: getBackgroundColor(user.group_name) }}>
                          <td className="dealmaker">
                            {user.current_rank}{' '}
                            {user.change < 0 ? (
                              <img className="arrow-down" alt="" src={arrowDownIcon} width="25px" height="25px" />
                            ) : user.change > 0 ? (
                              <img className="arrow-up" alt="" src={arrowUpIcon} width="25px" height="25px" />
                            ) : (
                              <span className="text-yellow">
                                <i className="fa fa-arrows-alt-h"></i>
                              </span>
                            )}
                          </td>
                          <td className="dealmaker">{user.name}</td>
                          <td className="dealmaker number-column">{toRP(user.omzet_daily)}</td>
                          <td className="dealmaker number-column">{toRP(user.kpi_omzet_daily)}</td>
                          <td className="dealmaker number-column">{toRP(user.omzet_monthly)}</td>
                          <td className="dealmaker number-column">{toRP(user.kpi_omzet_monthly)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}

export default ScoreboardCsGroupTeam;
