import { request } from '../config';

class VoucherService {

  static async getProducts() {
    const response = await request.get('/affiliates/vouchers/products');
    return response;
  }

  static async insert(data) {
    const response = await request.post('/affiliates/vouchers', data);
    if (response) { return response.data }
  }

  static async detail(id) {
    const { data: response } = await request.get(`/affiliates/vouchers/${id}`);
    return response;
  }

  static async edit(id, data) {
    const response = await request.put(`/affiliates/vouchers/${id}`, data);
    if (response) { return response.data }
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/affiliates/vouchers/${id}`);
    return response;
  }

  static async getDatatable(page, limit, sort, order, filter) {

    const response = await request.get('/affiliates/vouchers', {
      params: { page, limit, sort, order, filter }
    });

    return response.data;
  }

  static async getVoucherUsage(id, page, limit, sort, order, filter) {

    const response = await request.get(`/affiliates/vouchers/${id}/orders`, {
      params: { page, limit, sort, order, filter }
    });

    return response.data;
  }
}

export default VoucherService;