import React, { useEffect } from 'react';
import { Card, Table, Row, Button, Col, Modal, CardGroup, CardImg } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/id';

moment.locale('id');

const DetailAffiliate = ({ row, toggle, isOpen, setDetailModal, verifikasiAkun }) => {
  useEffect(() => {
    const imgModal = document.getElementById('modal-image-preview');
    window.onClick = function(event) {
      if (event.target === imgModal) {
        imgModal.style.display = 'none';
      }
    };
  }, []);

  return (
    <>
      {row ? (
        <>
          <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => toggle(row, 'detailModal')} size="lg">
            <div className="modal-header">
              <h5 className="modal-title" id="detailModalLabel">
                Detail User
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setDetailModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col md="5">
                  <Table responsive borderless size="sm">
                    <tbody>
                      <tr>
                        <td width="20%">Nama</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.name}</td>
                      </tr>
                      <tr>
                        <td width="20%">Email</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.email}</td>
                      </tr>
                      <tr>
                        <td width="20%">Telepon</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.phone}</td>
                      </tr>
                      <tr>
                        <td width="20%">Alamat</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.address}</td>
                      </tr>
                      <tr>
                        <td width="20%">Provinsi</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.province.name}</td>
                      </tr>
                      <tr>
                        <td width="20%">Kota/Kab</td>
                        <td width="5%">:</td>
                        <td className="text-left">
                          {row.affiliate.district.type} {row.affiliate.district.name}
                        </td>
                      </tr>
                      <tr>
                        <td width="20%">Kecamatan</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.subdistrict.name}</td>
                      </tr>
                      <tr>
                        <td width="20%">Kode Pos</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.postal_code}</td>
                      </tr>
                      <tr>
                        <td width="20%">NPWP</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.npwp}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>

                <Col md="7">
                  <Table responsive borderless size="sm">
                    <tbody>
                      <tr>
                        <td width="20%">Bank</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.bank_name}</td>
                      </tr>
                      <tr>
                        <td width="20%">Nama Rekening</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.account_name}</td>
                      </tr>
                      <tr>
                        <td width="20%">Nomor Rekening</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.account_number}</td>
                      </tr>
                      <tr>
                        <td width="20%">NIK</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.identity_number}</td>
                      </tr>

                      <tr>
                        <td width="20%">Tipe Affiliate</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.affiliate_type}</td>
                      </tr>
                      <tr>
                        <td width="20%">Channel Radio</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.frequency_channel}</td>
                      </tr>
                      <tr>
                        <td width="20%">Blogger</td>
                        <td width="5%">:</td>
                        <td className="text-left">{row.affiliate.blog_url}</td>
                      </tr>
                      <tr>
                        <td width="20%">Social Media</td>
                        <td width="5%">:</td>
                        <td className="text-left">
                          {row.affiliate.social_media.map(result => (
                            <div> {result.url}</div>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>

            <Row>
              <Col md="6">
                <div className="modal-header">
                  <strong>Foto Buku Tabungan</strong>
                </div>
                <div className="modal-body">
                  <CardGroup>
                    <Card>
                      <CardImg alt="Gambar" src={row.affiliate.account_image} style={{ height: 'auto' }} />
                    </Card>
                  </CardGroup>
                </div>

                <div className="modal-header">
                  <strong>Foto NPWP</strong>
                </div>
                {row.affiliate.npwp_image === null ? (
                  <div className="modal-body">
                    <p>empty...</p>
                  </div>
                ) : (
                  <div className="modal-body">
                    <CardGroup>
                      <Card>
                        <CardImg alt="Gambar" src={row.affiliate.npwp_image} style={{ height: 'auto' }} />
                      </Card>
                    </CardGroup>
                  </div>
                )}
              </Col>
              <Col md="6">
                <div className="modal-header">
                  <strong>Foto KTP</strong>
                </div>
                <div className="modal-body">
                  <CardGroup>
                    <Card>
                      <CardImg alt="Gambar" src={row.affiliate.identity_image} style={{ height: 'auto' }} />
                    </Card>
                  </CardGroup>
                </div>
                {row.affiliate.community_image && (
                  <>
                    <div className="modal-header">
                      <strong>Foto Kartu Anggota Komunitas</strong>
                    </div>
                    <div className="modal-body">
                      <CardGroup>
                        <Card>
                          <CardImg alt="Gambar" src={row.affiliate.community_image} style={{ height: 'auto' }} />
                        </Card>
                      </CardGroup>
                    </div>
                  </>
                )}
              </Col>
            </Row>

            <div className="modal-footer">
              {row.is_verified === 0 ? (
                <Button color="success" data-dismiss="modal" type="button" onClick={() => verifikasiAkun(row.id)}>
                  Verifikasi Akun
                </Button>
              ) : (
                <Button color="secondary" data-dismiss="modal" type="button" onClick={() => setDetailModal(false)}>
                  Close
                </Button>
              )}
            </div>
          </Modal>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default DetailAffiliate;
