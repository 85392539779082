import React from 'react'

import {
  Card,
  CardHeader,
  Row,
  Table,
} from "reactstrap";

const CardTable = props => (
  <Card>
    <CardHeader className="border-0">
      <Row className="align-items-center">
        <div className="col">
          <h3 className="mb-0">{props.name}</h3>
        </div>
        {/* <div className="col text-right">
          <Button
            color="primary"
            href="#pablo"
            onClick={e => e.preventDefault()}
            size="sm"
          >
            See all
          </Button>
        </div> */}
      </Row>
    </CardHeader>
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">{`Nama ${props.name}`}</th>
          <th scope="col">Total</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map((item, i) => (
          <tr key={i}>
            <td><strong>{item.name}</strong></td>
            <td>{item.total}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Card>
)

export default CardTable