import { request } from '../config';

class EmagazineService {
  /**
   * Get bank lists
   */
  static async getLists() {
    const { data: response } = await request.get('/e-magazines/list');
    return response;
  }

  static async getCsLists() {
    const { data: response } = await request.get('/e-magazines/list-cs');
    return response;
  }

  static async getBankProviders() {
    const { data: response } = await request.get('/e-magazines/providers');
    return response;
  }

  static async insert(data) {
    const { data: response } = await request.post('/e-magazines', data);
    return response;
  }

  static async changeStatus(id) {
    const { data: response } = await request.put(`/e-magazines/change-status/${id}`);
    return response;
  }

  static async detail(id) {
    const { data: response } = await request.get(`/e-magazines/${id}`);
    return response;
  }

  static async edit(id, data) {
    const { data: response } = await request.put(`/e-magazines/${id}`, data);
    return response;
  }

  static async remove(id) {
    const { data: response } = await request.delete(`/e-magazines/${id}`);
    return response;
  }

  static async getDatatable(page, limit, sort, order, filter) {
    page = page > 0 ? page - 1 : 0;

    const response = await request.get('/e-magazines', {
      params: { page, limit, sort, order, filter }
    });

    const res = response.data;
    return {
      ...res,
      page: res.page + 1
    };
  }
}

export default EmagazineService;
